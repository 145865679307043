import React, { useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { HrTextInput, HrTextInputProps } from "../../../atoms/HrTextInput";

type KickoffPacketListInputProps = {
    handleSubmit: (item: string) => void
} & HrTextInputProps;

export const KickoffPacketListInput: React.FC<KickoffPacketListInputProps> = ({ handleSubmit }: KickoffPacketListInputProps) => {

    const [newItem, setNewItem] = useState<string>('');
    const handleClick = (e: any) => {
        e.preventDefault();
        handleSubmit(newItem);
        setNewItem('');
    }
    return(
        <HrTextInput
            label="Add Item"
            name="newItem"
            value={newItem}
            onChange={(e: any) => setNewItem(e.target.value)}
            InputProps={{ 
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={(e: any) => handleClick(e)} 
                        >
                            <AddCircleIcon />
                        </IconButton>
                    </InputAdornment>
                )
            }}
            fullWidth
            sx={{
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'rgba(0, 0, 0, 0.12)'
                    }
                }
            }}
            highlightOnFocus={false}
        />
    ) 
}