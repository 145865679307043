import { gql, useMutation, useQuery } from "@apollo/client";
import { IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { KickoffPacketSection } from "./KickoffPacketSection";
import { ProjectScopeForm } from "./ProjectScopeForm";
import CloseIcon from '@mui/icons-material/Close';
import * as uuid from 'uuid';


interface ProjectScopeProps {
    id?: string;
}

const GET_KICKOFF_PACKET_PROJECT_SCOPE_QUERY = gql`
    query GetKickoffPacketProjectScopeQuery($KickoffPacketId: uniqueidentifier = "") {
        KickoffPacketProjectScope(where: {KickoffPacketId: {_eq: $KickoffPacketId}}, order_by: {Ordinal: asc}) {
            Id
            KickoffPacketId
            Ordinal
            Scope
        }
    }
`

const INSERT_KICKOFF_PACKET_SCOPE_ITEM = gql`
    mutation InsertKickoffPacketProjectScopeItem($object: KickoffPacketProjectScope_insert_input = {}) {
        insert_KickoffPacketProjectScope_one(object: $object) {
            Id
        }
    }
`

const DELETE_KICKOFF_PACKET_SCOPE_ITEM = gql`
mutation DeleteKickoffPacketProjectScopeItem($Id: uniqueidentifier = "", $KickoffId: uniqueidentifier = "", $Ordinal: Int = 0) {
    delete_KickoffPacketProjectScope_by_pk(Id: $Id) {
        Id
    }
    update_KickoffPacketProjectScope(where: {Ordinal: {_gt: $Ordinal}, KickoffPacketId: {_eq: $KickoffId} }, _inc: {Ordinal: -1}) {
        affected_rows
        returning {
            Id
            Ordinal
        }
    }
}  
`

export const ProjectScope: React.FC<ProjectScopeProps> = ({ id }: ProjectScopeProps) => {
    const { data, error } = useQuery(GET_KICKOFF_PACKET_PROJECT_SCOPE_QUERY, {
        variables: {
            KickoffPacketId: id
        }
    });

    if (error) console.log(error);
    
    const [addScopeItem] = useMutation(INSERT_KICKOFF_PACKET_SCOPE_ITEM, {
        refetchQueries: [GET_KICKOFF_PACKET_PROJECT_SCOPE_QUERY]
    });

    const [deleteScopeItem] = useMutation(DELETE_KICKOFF_PACKET_SCOPE_ITEM, {
        refetchQueries: [GET_KICKOFF_PACKET_PROJECT_SCOPE_QUERY]
    });

    const scopeItems = data?.KickoffPacketProjectScope ?? [];

    const addItem = (value: string) => {
        addScopeItem({
            variables: {
                object: {
                    Id: uuid.v4(),
                    KickoffPacketId: id,
                    Scope: value,
                    Ordinal: scopeItems.length
                }
            }
        })
    }

    const deleteItem = (scopeId: string, ordinal: number) => {
        deleteScopeItem({
            variables: {
                Id: scopeId,
                KickoffId: id,
                Ordinal: ordinal
            }
        })
    }

    return (
        <KickoffPacketSection heading="Project Scope">
            <ProjectScopeForm addItem={addItem}/>
            <List>
                {
                    scopeItems.map((item: any) => {
                        return (
                            <ListItem
                                divider
                                dense
                                disableGutters
                                key={item.Id}
                            >
                                <ListItemButton>
                                    <ListItemText primary={item.Scope} />
                                    <ListItemIcon>
                                        <IconButton onClick={() => deleteItem(item.Id, item.Ordinal)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </ListItemIcon>
                                </ListItemButton>
                            </ListItem>
                        )
                    })
                }
            </List>
        </KickoffPacketSection>
    )
}