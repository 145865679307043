import { Box, IconButton } from "@mui/material";
import { GridColDef } from '@mui/x-data-grid-pro';
import { HrDataGrid } from "../../molecules/HrDataGrid";
import { CloudDownload } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../../../stores/StoreContext";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { gql, useMutation } from "@apollo/client";
import { GET_FILES_QUERY } from "../../../api/graphqlqueries/getquotefilesquery";
import { FileUpload } from "../../molecules/FileUpload";
import { Colors } from "../../../constants/colors";
interface QuoteDataGridProps {
    data?: any;
}

const DELETE_QUOTE_ATTACHMENT = gql`
mutation DeleteAttachment($id: uniqueidentifier = "") {
    update_QuoteAttachments(where: {Id: {_eq: $id}}, _set: {IsActive: false}) {
      returning {
        Id
      }
    }
  } 
`

export const QuoteFileGrid: React.FC<QuoteDataGridProps> = observer(
    ({ data }) => {
        const { userStore } = useStore();
        const { agent } = userStore;

        const [deleteAttachment, { loading: deleting }] = useMutation(DELETE_QUOTE_ATTACHMENT);

        const handleFileDownload = (id: string, fileName: string) => () => {
            agent.Attachments.get(id, fileName);
        }

        const handleSharepointFileDownload = (filePath: string, fileName: string, id: string) => () => {

            axios({
                url: "/dynamics/SharePointFile", //your url
                method: 'POST',
                responseType: 'blob', // important
                data: {
                    FilePath: filePath,
                    FileName: fileName,
                    Id: id
                }
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
                if (link.parentNode) {
                    link.parentNode.removeChild(link);
                }
            })
        }

        const deleteFile = (id: string) => {
            if (!deleting) {
                if (window.confirm("Are you sure you want to delete this attachment?")) {
                    deleteAttachment({
                        variables: {
                            id: id
                        },
                        refetchQueries: [GET_FILES_QUERY]
                    })
                }
            }
        }

        const columns: GridColDef[] = [
            {
                field: 'Download',
                headerName: 'Download',
                sortable: false,
                width: 100,
                disableColumnMenu: true,
                renderCell: (params) => {
                    return (<IconButton size="small" onClick={params.row.SharepointDocument ? handleSharepointFileDownload(params.row.FilePath, params.row.Filename, params.row.Id) : handleFileDownload(params.id as string, params.row.Filename)}>
                        <CloudDownload />
                    </IconButton>
                    )
                },
                align: 'center',
                headerAlign: 'center'
            },
            {
                field: "Filename",
                headerName: "File Name",
                width: 250,
                headerClassName: "billables-grid-header",
                editable: false,
            },
            {
                field: "Size",
                headerName: "Size",
                width: 250,
                valueGetter: (params) => `${(params.row.Size / 1000).toFixed(2)} KB`,
                editable: false,
                headerClassName: "billables-grid-header",
            },
            {
                field: "CreateDate",
                headerName: "Date Created",
                width: 175,
                editable: false,
                headerClassName: "billables-grid-header",
                valueFormatter: (params) => new Date(params.value as string).toLocaleDateString()
            },
            {
                field: "createdBy",
                headerName: "Created By",
                width: 200,
                headerClassName: "billables-grid-header",
                valueGetter: (params) => params.row?.CreateUser?.FirstName ? `${params.row?.CreateUser?.FirstName} ${params.row?.CreateUser?.LastName}` : `${params.row?.CreatedBy}`
            },
            {
                field: 'Delete',
                headerName: 'Delete',
                sortable: false,
                width: 100,
                disableColumnMenu: true,
                renderCell: (params) => {
                    return (<IconButton size="small" onClick={() => deleteFile(params.row.Id)}>
                        <DeleteIcon />
                    </IconButton>
                    )
                },
                align: 'center',
                headerAlign: 'center'
            }
        ];

        return (
            <Box sx={styles.container}>
                <HrDataGrid
                    rows={data}
                    columns={columns}
                    loading={!data}
                    getRowId={(row) => row.Id}
                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    disableRowSelectionOnClick
                />
            </Box>
        );
    }
);




const styles = {
    container: {
        height: "60vh",
        padding: "0",
        margin: "10px 0px",
        border: "none",
        borderRadius: "0 0 5px 5px",
    }
};

