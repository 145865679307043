import { gql } from "@apollo/client/core";
import * as uuid from 'uuid';

function getDateString(date: Date) {
    if (!date) return null;
    const dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
        .toISOString()
        .split("T")[0];

    return dateString;
}

export const getVariables = (formikValues: any, userId: string, defaults: any) => {
    const quoteId = uuid.v4();


    return {
        quoteObject: {
            Id: quoteId,
            BidBookUserId: formikValues.bidBookId || userId,
            Budgetary: formikValues.budgetary,
            BusinessLeadUserId: formikValues.businessLeadId || null,
            CoverLetter: formikValues.coverLetter,
            CustomerContactId: formikValues.customerRepId || null,
            DocumentReceivedDate: getDateString(formikValues.docReceivedDate),
            DueDate: getDateString(formikValues.dueDate),
            ReceivedDate: getDateString(formikValues.receivedDate),
            CreateUserId: userId,
            UpdateUserId: userId,
            CustomerId: formikValues.customerId,
            JobCity: formikValues.jobCity,
            JobState: formikValues.jobState,
            Zip: formikValues.zip,
            BidDescription: formikValues.jobName,
            BidQuoteAnalystUserId: formikValues.analystId || userId,
            PreparedByUserId: formikValues.preparedByUserId || userId,
            PreparedByTitle: formikValues.preparedByTitle,
            PerDiemRate: Number(defaults.PerDiemRate),
            MileageRate: Number(defaults.MileageRate),
            OfficeHours: Number(defaults.OfficeHours),
            FieldHours: Number(defaults.CTIFieldHours),
            SurveyFieldHours: Number(defaults.SurveyFieldHours),
            DaysValidAfterSubmit: Number(defaults.DaysValidAfterSubmit),
            PerDiemCost: Number(defaults.PerDiemCost),
            TimecardMileageRate: Number(defaults.TimecardMileageRate),
            SurveyMileage: Number(defaults.SurveyMileage), 
            KmzReview: formikValues.kmzReview,
            TimeAndMaterials: formikValues.timeAndMaterials,
            GpsPointsReview: formikValues.gpsPointsReview,
            ProjectAreaReview: formikValues.projectAreaReview,
            SpecialEquipmentNeeded: formikValues.specialEquipmentNeeded,
            ReportDisplay: "Detail",
            ReportUnits: "Actual",
            ReportIncludePerDiem: false,
            ReportIncludeMileage: false,
            ReportIncludeMobilization: true,
            ReportIncludeRateSheet: false,
            ReportIncludeMaterials: true,
            ClientScopeOfWorkReviewed: false,
            ReviewedByDirectorOfOperations: false,
            ReviewedByVPOfOperations: false,
            CoverLetterReportingPlan: true,
            CoverLetterPaymentTerms: true,
            CoverLetterPurposeSection: true,
            CoverLetterProjectDescriptionSection: true,
            CoverLetterExecutionPlanSection: true,
            CoverLetterProjectBreakdownSection: true,
            CoverLetterProjectScheduleSection: true,
            CoverLetterProjectAssumptionsSection: true,
            CoverLetterCoverPageSection: true,
            ExpectedQuarter: formikValues.expectedQuarter,
            ExpectedYear: formikValues.expectedYear
        },
        statusObject: {
            Id: uuid.v4(),
            BidQuoteId: quoteId,
            Status: "Draft",
            UserId: userId,
        },
        BidQuoteId: quoteId,
    }
}

export const CREATE_QUOTE_MUTATION = gql`
  mutation CreateQuote($quoteObject: BidQuotes_insert_input = {}, $statusObject: BidQuoteStatus_insert_input = {}, $BidQuoteId: String = "") {
    insert_BidQuotes_one(object: $quoteObject) {
      __typename
      BidBookUserId
      Budgetary
      BusinessLeadUserId
      BidQuoteAnalystUserId
      CoverLetter
      KmzReview
      GpsPointsReview
      ProjectAreaReview
      SpecialEquipmentNeeded
      CreateDate
      CustomerContactId
      CustomerId
      DocumentReceivedDate
      DueDate
      Id
      JobId
      JobNumber
      ReceivedDate
      JobCity
      JobState
      Zip
      PerDiemRate
      MileageRate
      OfficeHours
      FieldHours
      SurveyFieldHours
      PreparedByTitle
      PreparedByUserId
      ReportUnits
      ReportDisplay
      ReportIncludeMileage
      ReportIncludePerDiem
      ReportIncludeMobilization
      SurveyMileage
    }
    insert_BidQuoteStatus_one(object: $statusObject) {
      Id
      Status
      CreateDate
    }
    CopyBidQuoteRates(BidQuoteId: $BidQuoteId)
  }
`;

export const UPDATE_BID_QUOTE_MUTATION = gql`
  mutation UpdateBidQuote($Id: uniqueidentifier = "", $object: BidQuotes_set_input = {}) {
    update_BidQuotes_by_pk(pk_columns: {Id: $Id}, _set: $object) {
      __typename
      BidBookUserId
      BidDescription
      BidQuoteAnalystUserId
      Budgetary
      BusinessLeadUserId
      ClientScopeOfWorkReviewed
      CoverLetter
      KmzReview
      GpsPointsReview
      ProjectAreaReview
      SpecialEquipmentNeeded
      CreateDate
      CreateUserId
      CustomerContactId
      CustomerId
      DocumentReceivedDate
      Id
      DueDate
      JobCity
      JobNumber
      JobState
      Zip
      ReceivedDate
      SpecialInstructions
      UpdateDate
      UpdateUserId
      PerDiemRate
      MileageRate
      OfficeHours
      FieldHours
      SurveyFieldHours
      PreparedByTitle
      PreparedByUserId
      ReviewedByDirectorOfOperations
      ReviewedByVPOfOperations
      TimeAndMaterials
      ExpectedQuarter
      ExpectedYear
      SurveyMileage
    }
  }
`;

export const getVariablesEdit = (formikValues: any, userId: string) => {
    return {
        BidBookUserId: formikValues.BidBookId || null,
        Budgetary: formikValues.Budgetary,
        BusinessLeadUserId: formikValues.BusinessLeadId || null,
        ClientScopeOfWorkReviewed: formikValues.ClientScopeOfWorkReviewed,
        CoverLetter: formikValues.CoverLetter,
        CustomerContactId: formikValues.CustomerRepId || null,
        DocumentReceivedDate: getDateString(formikValues.DocReceivedDate),
        DueDate: getDateString(formikValues.DueDate),
        ReceivedDate: getDateString(formikValues.ReceivedDate),
        UpdateUserId: userId,
        CustomerId: formikValues.CustomerId,
        JobCity: formikValues.JobCity,
        JobState: formikValues.JobState,
        Zip: formikValues.Zip,
        BidDescription: formikValues.BidDescription,
        BidQuoteAnalystUserId: formikValues.AnalystId || null,
        PreparedByUserId: formikValues.PreparedByUserId || null,
        PreparedByTitle: formikValues.PreparedByTitle,
        PerDiemRate: formikValues.PerDiemRate,
        MileageRate: formikValues.MileageRate,
        OfficeHours: formikValues.OfficeHours,
        FieldHours: formikValues.FieldHours,
        SurveyFieldHours: formikValues.SurveyFieldHours,
        DaysValidAfterSubmit: formikValues.DaysValidAfterSubmit,
        KmzReview: formikValues.KmzReview,
        TimeAndMaterials: formikValues.TimeAndMaterials,
        GpsPointsReview: formikValues.GpsPointsReview,
        ProjectAreaReview: formikValues.ProjectAreaReview,
        SpecialEquipmentNeeded: formikValues.SpecialEquipmentNeeded,
        ReviewedByDirectorOfOperations: formikValues.ReviewedByDirectorOfOperations,
        ReviewedByVPOfOperations: formikValues.ReviewedByVPOfOperations,
        ProjectManagerUserId: formikValues.ProjectManagerUserId,
        ExpectedQuarter: formikValues.ExpectedQuarter,
        ExpectedYear: formikValues.ExpectedYear,
        SurveyMileage: formikValues.SurveyMileage
    }
}