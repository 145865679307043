import { Box } from "@mui/material";
import React from "react";
import { TableauViz } from "./TableauViz";

interface BidTrackerProps {
}

export const BidTracker: React.FC<BidTrackerProps> = (props) => {
    return (
        <Box sx={{
            height:"100vh",
            width:"100%"
        }}>
            <TableauViz 
                src={"https://prod-useast-a.online.tableau.com/#/site/highridge/views/BIDTRACKERUPDATED/Dashboard3FINALPROD"} 
                />
        </Box>
        
    )
};