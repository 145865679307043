import { Box, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "../../App.css";
import { Colors } from "../../constants/colors";

interface TypeBoxProps {
    type : string
}

const theme = createTheme({
    typography: {
      fontFamily: "Montserrat, Arial",
      fontSize:10
    },
  });

export const TypeBox = (props : TypeBoxProps) => {
    const { type } = props;
    const borderStyle="1px dashed #347171;"
    return(
        <ThemeProvider theme={theme}>
            <Box sx={{borderLeft:borderStyle, borderBottom:borderStyle, position:"relative", height:"12px",width:"12%", maxWidth:"86px", marginBottom:1, marginTop:1, marginRight:1}}>
                <Box sx={{ backgroundColor: "#FFF", transform:"translateX(-50%)", position:"absolute", left:"50%", bottom:"-9px", padding:"0px 3px"}}>
                    <Typography color={Colors.deepSeaGreen}>
                        {type}
                    </Typography>
                </Box>
            </Box>
        </ThemeProvider>
    )
}