import { alpha, styled } from '@mui/material/styles';
import { DataGridPro, DataGridProProps, gridClasses } from '@mui/x-data-grid-pro';
import { Colors } from '../../constants/colors';


export const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
    width: "100%",
    margin: "0 auto",
    padding: "0",
    boxShadow: "2px 2px 11px rgba(0,0,0,0.1)",
    fontFamily: "Montserrat",
    height: "100%",
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.grey[200],
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.primary.main, 0.2)
        }
    },
    "& .MuiDataGrid-columnHeaders": {
        backgroundColor: "rgba(33,77,77,1)",
        color: "white",
        borderRadius: "5px 5px 0 0",
    },
    "& .MuiDataGrid-sortIcon": {
        color: "white",
    },
    "& .MuiDataGrid-columnsContainer .MuiIconButton-label": {
        color: "white",
    },
    ".MuiDataGrid-menuIcon .MuiSvgIcon-root": {
        color: "white",
    },
    "& .MuiDataGrid-row": {
        cursor: "pointer"
    },
    "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus": {
        outline: "none",
    },
    "& .MuiDataGrid-columnSeparator": {
        visibility: "visible"
    },
    "& .MuiDataGrid-pinnedColumnHeaders": {
        backgroundColor: Colors.blueSlate
    }
}));

export const HrDataGrid = (props: DataGridProProps) => {
    return (
        <StyledDataGrid
            loading={!props.rows}
            getRowId={(row) => row.Id}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            disableRowSelectionOnClick
            {...props}
        />
    )
}
