import { useQuery } from "@apollo/client";
import { HrTextInput } from "../../../atoms/HrTextInput";
import { HrCurrencyInput } from "../../../atoms/HrCurrencyInput";
import { QuoteLineItemFormGrid } from "../../../atoms/QuoteLineItemFormGrid";
import { GET_QUOTE_ITEMS_QUERY, GetQuoteItemsQueryResult } from "../../../../api/graphqlqueries/getquoteitems";


export interface PricingTotals {
    days: number;
    miles: number;
    milesPerDay: number;
    price: number;
    pricePerMile: number;
    pricePerDay: number;
    adders: number;
    discountPrice: number;
    discPricePerMile: number;
    discPricePerDay: number;
    grandTotal: number;
    totalsWoMaterials: number;
}

interface QuotePricingTotalsProps {
    id?: string
}

export const GetQuotePricingTotals = (id?: string) => {
    const { data } = useQuery<GetQuoteItemsQueryResult>(GET_QUOTE_ITEMS_QUERY, {
        variables: {
            BidQuoteId: id,
            Type: "Survey"
        },
        fetchPolicy: 'cache-and-network',
    });
    const totals: PricingTotals = data?.BidQuoteItems?.reduce((totals: any, item) => {
        const itemSurvey = Number(item.AverageMiles) !== 0 ? (item.Miles / item.AverageMiles) : 0
        const rate = item.OverrideRate ? item.Rate : item.BidQuoteRate?.Rate ?? 0;
        const itemPrice = (rate * (itemSurvey + item.Mob + item.Setup + item.Boat));
        const itemAdder = item.BidQuoteMaterials?.reduce((pv: any, cv) => {
            const adderRate = cv.OverrideRate ? cv.Rate : cv.BidQuoteBillable?.Cost ?? 0;
            const total = cv.Quantity * adderRate * (1 + cv.Markup) * cv.Duration;
            return pv + total;
        }, 0)
        totals.miles += item.Miles ?? 0;
        totals.survey += itemSurvey;
        totals.mob += item.Mob ?? 0;
        totals.setup += item.Setup ?? 0;
        totals.boat += item.Boat ?? 0;
        totals.averageMiles += item.AverageMiles ?? 0;
        totals.days = totals.survey + totals.mob + totals.setup + totals.boat;
        totals.milesPerDay = totals.days !== 0 ? totals.miles / totals.days : 0;
        totals.price += itemPrice;
        totals.pricePerMile = totals.miles !== 0 ? totals.price / totals.miles : 0;
        totals.pricePerDay = totals.days !== 0 ? totals.price / totals.days : 0;
        totals.discountPrice += (itemPrice * (1 - (item.Discount ?? 0)));
        totals.discPricePerMile = totals.miles !== 0 ? totals.discountPrice / totals.miles : 0;
        totals.discPricePerDay = totals.days !== 0 ? totals.discountPrice / totals.days : 0;
        totals.adders += itemAdder;
        return totals;
    }, {
        miles: 0,
        survey: 0,
        mob: 0,
        setup: 0,
        boat: 0,
        averageMiles: 0,
        days: 0,
        milesPerDay: 0,
        price: 0,
        pricePerMile: 0,
        pricePerDay: 0,
        adders: 0,
        discountPrice: 0,
        discPricePerMile: 0,
        discPricePerDay: 0
    });
    if (totals)
        totals.grandTotal = totals.discountPrice + totals.adders;
        totals.totalsWoMaterials = totals.discountPrice;
    return totals;
}

export const QuotePricingTotals = (props: QuotePricingTotalsProps) => {
    const { id } = props;
    const totals = GetQuotePricingTotals(id);
    const width = {
        small: "8ch",
        medium: "12ch",
        large: "18ch",
        xl: "24ch",
    }
    return (
        <QuoteLineItemFormGrid sx={{ marginTop: .5, marginBottom: .5 }}>
            <HrTextInput
                label='Total Days'
                width={width.medium}
                value={totals.days.toFixed(2)}
                disabled
            />
            <HrTextInput
                label='Total Miles'
                width={width.medium}
                value={totals.miles}
                disabled
            />
            <HrTextInput
                label='Miles Per Day'
                width={width.medium}
                value={totals.milesPerDay.toFixed(2)}
                disabled
            />
            <HrCurrencyInput
                label='Price'
                width={width.medium}
                value={totals.price.toFixed(2).toString()}
                disabled
            />
            <HrCurrencyInput
                label='Price Per Mile'
                width={width.medium}
                value={totals.pricePerMile.toFixed(2).toString()}
                disabled
            />
            <HrCurrencyInput
                label='Price Per Day'
                width={width.medium}
                value={totals.pricePerDay.toFixed(2).toString()}
                disabled
            />
            <HrCurrencyInput
                label='Adders'
                width={width.medium}
                value={totals.adders.toFixed(2).toString()}
                disabled
            />
            <HrCurrencyInput
                label='Discount Price'
                width={width.medium}
                value={totals.discountPrice.toFixed(2).toString()}
                disabled
            />
            <HrCurrencyInput
                label='Disc Price Per Mile'
                width={width.medium}
                value={totals.discPricePerMile.toFixed(2).toString()}
                disabled
            />
            <HrCurrencyInput
                label='Disc Price Per Day'
                width={width.medium}
                value={totals.discPricePerDay.toFixed(2).toString()}
                disabled
            />
        </QuoteLineItemFormGrid>
    )
}