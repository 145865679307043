import { Delete } from '@mui/icons-material';
import { IconButton, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, useCallback } from 'react';
import { v4 } from 'uuid';
import * as yup from 'yup';
import debounce from '../../../../utils/debounce';
import { HrCurrencyInput } from '../../../atoms/HrCurrencyInput';
import { HrPercentInput } from '../../../atoms/HrPercentInput';
import HrTextInput from "../../../atoms/HrTextInput";
import { QuoteLineItemFormGrid } from '../../../atoms/QuoteLineItemFormGrid';
import { getAdderCalculations, BidQuoteMaterial, MaterialCalculations } from "../../../../utils/calculations/adders";
import { DeleteWarning } from "../../../molecules/DeleteWarning";
import { useReactiveVar } from '@apollo/client';
import { disableForStatus } from '../../../../stores/ReactiveVariables';
import HrCheckBox from '../../../atoms/HrCheckBox';

interface QuoteAdderItem {
    Id?: string;
    Description?: string;
    Quantity?: number;
    Type?: string;
    Unit?: string;
    Markup?: number;
    Duration?: number;
    Rate?: number;
    Tax?: number;
    GroupWith?: string;
    AdderHasCost?: boolean;
}
interface QuoteMaterialItemFormProps {
    item?: QuoteAdderItem;
    onSave: (item: QuoteAdderItem) => void;
    onDelete: (itemId: string) => void;
}

const quoteAdderSchema = yup.object().shape({
    Quantity: yup.number(),
    Rate: yup.number(),
    Unit: yup.string(),
    Markup: yup.number(),
    Duration: yup.number(),
    Description: yup.string(),
    Type: yup.string(),
    Tax: yup.number(),
    AdderHasCost: yup.boolean(),
    GroupWidth: yup.string(),
});

const width = {
    small: "10ch",
    medium: "14ch",
    large: "20ch",
    xLarge: "26ch"
}

const styles = {
    menuItem: {
        display: "flex!important"
    }
}

export const QuoteAdderForm = ({ item: quoteItem, onSave, onDelete }: QuoteMaterialItemFormProps) => {
    const Id = quoteItem?.Id ?? v4();
    const disabledForStatus = useReactiveVar(disableForStatus);
    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: quoteAdderSchema,
        initialValues: {
            Quantity: quoteItem?.Quantity ?? 0,
            AdderHasCost: quoteItem?.AdderHasCost ?? false,
            Rate: quoteItem?.Rate ?? 0,
            Unit: quoteItem?.Unit ?? '',
            Markup: quoteItem?.Markup ?? 0,
            Duration: quoteItem?.Duration ?? 0,
            Description: quoteItem?.Description ?? '',
            Type: quoteItem?.Type ?? '',
            Tax: quoteItem?.Tax ?? 0,
            GroupWith: quoteItem?.GroupWith ?? '',
        },
        onSubmit: (values) => {
            const data = quoteAdderSchema.cast(values);
            onSave({ Id, ...data });
            formik.setSubmitting(false);
        }
    });



    const handleDelete = () => {
        onDelete(Id);
    }

    const updateData = (data: any) => {
        onSave({ Id, ...data });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        try {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value };
            const values = quoteAdderSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.handleChange(e);
    }

    const handlePercentChange = (e: ChangeEvent<HTMLInputElement>) => {
        const cleanNumberString = e.target.value.replace('%', '');
        const numberValue = parseFloat(cleanNumberString);
        if (isNaN(numberValue)) return;
        try {
            const updatedValues = { ...formik.values, [e.target.name]: numberValue / 100 };
            const values = quoteAdderSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.setFieldValue(e.target.name, numberValue / 100);
    }

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        try {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.checked };
            const values = quoteAdderSchema.cast(updatedValues);
            debouncer(values);
            formik.setFieldValue(e.target.name, e.target.checked);
        } catch (error) {
            console.error(error);
        }
    }


    // eslint-disable-next-line
    const debouncer = useCallback(debounce((values: any) => updateData(values), 1000), []);

    const addersCalculations: MaterialCalculations = getAdderCalculations(quoteItem as BidQuoteMaterial);

    const setUnit = (value: string) => {
        formik.setFieldValue("Unit", value);
        if (value === "Each") {
            formik.setFieldValue("Duration", 1);
        }
        if (formik.isValid) {
            const values = quoteAdderSchema.cast(formik.values);
            const updatedValues = { ...values, Unit: value, Duration: value === "Each" ? 1 : values.Duration };
            debouncer(updatedValues);
        }
    }

    return (
        <QuoteLineItemFormGrid>
            <HrTextInput
                select
                name='Type'
                label='Type'
                width={width.large}
                onChange={handleChange}
                value={formik.values.Type}
                errors={formik.errors.Type}
            >
                <MenuItem sx={styles.menuItem} value=""></MenuItem>
                <MenuItem sx={styles.menuItem} value="Equipment">Equipment</MenuItem>
                <MenuItem sx={styles.menuItem} value="Material">Materials</MenuItem>
                <MenuItem sx={styles.menuItem} value="Service">Service</MenuItem>
            </HrTextInput>
            <HrTextInput
                name='Description'
                label='Adder Detail'
                width={width.xLarge}
                onChange={handleChange}
                value={formik.values.Description}
                errors={formik.errors.Description}
            />
            <HrTextInput
                select
                name='GroupWith'
                label='Group With'
                width={width.large}
                onChange={handleChange}
                value={formik.values.GroupWith}
                errors={formik.errors.GroupWith}
            >
                <MenuItem sx={styles.menuItem} value=""></MenuItem>
                <MenuItem sx={styles.menuItem} value="Construction">Construction</MenuItem>
                <MenuItem sx={styles.menuItem} value="Inspection">Inspection</MenuItem>
                <MenuItem sx={styles.menuItem} value="Quote">Quote</MenuItem>
                <MenuItem sx={styles.menuItem} value="Survey">Survey</MenuItem>
                <MenuItem sx={styles.menuItem} value="Technician">Technician</MenuItem>
                <MenuItem sx={styles.menuItem} value="Lump Sum">Lump Sum</MenuItem>
            </HrTextInput>
            <HrTextInput
                name='Quantity'
                label='Quantity'
                width={width.small}
                onChange={handleChange}
                value={formik.values.Quantity}
                errors={formik.errors.Quantity}
            />

            <HrTextInput
                name='Rate'
                label='Rate'
                width={width.medium}
                onChange={handleChange}
                value={formik.values.Rate}
                errors={formik.errors.Rate}
            />
            <HrTextInput
                select
                name='Unit'
                label='PER'
                width={width.small}
                onChange={(e) => setUnit(e.target.value)}
                value={formik.values.Unit}
                errors={formik.errors.Unit}
            >
                <MenuItem sx={styles.menuItem} value="" />
                <MenuItem sx={styles.menuItem} value="Day">Day</MenuItem>
                <MenuItem sx={styles.menuItem} value="Hours">Hours</MenuItem>
                <MenuItem sx={styles.menuItem} value="Miles">Miles</MenuItem>
                <MenuItem sx={styles.menuItem} value="Each">Each</MenuItem>
                <MenuItem sx={styles.menuItem} value="Feet">Feet</MenuItem>
                <MenuItem sx={styles.menuItem} value="Sets">Sets</MenuItem>
                <MenuItem sx={styles.menuItem} value="Quote">Quote</MenuItem>
            </HrTextInput>
            <HrTextInput
                name='Duration'
                label='Duration'
                width={width.small}
                onChange={handleChange}
                value={formik.values.Duration}
                errors={formik.errors.Duration}
            />
            <HrPercentInput
                label='Markup'
                name='Markup'
                width={width.small}
                onChange={handlePercentChange}
                value={formik.values.Markup}
                errors={formik.errors.Markup}
            />
            <HrPercentInput
                label='Tax'
                name='Tax'
                width={width.small}
                onChange={handlePercentChange}
                value={formik.values.Tax}
                errors={formik.errors.Tax}
            />
            <HrCurrencyInput
                name='Total'
                label='Total'
                width={width.medium}
                value={addersCalculations.TotalPrice.toFixed(2)}
                disabled
            />
            <HrCurrencyInput
                label='Markup Tax Total'
                width={width.medium}
                value={addersCalculations.TotalMarkupPriceSalesTax?.toString()}
                disabled
            />
            <HrCheckBox
                name='AdderHasCost'
                label='Has Cost'
                onChange={handleCheckboxChange}
                checked={formik.values.AdderHasCost}
            />
            {/* <IconButton
                color="error"
                onClick={handleDelete}
            >
                <Delete />
            </IconButton> */}
            <DeleteWarning
                onClick={handleDelete}
                heading={"Delete Adder"}
                disabled={disabledForStatus}
            >
                <IconButton
                    color="error"
                //disabled={deleting}
                >
                    <Delete />
                </IconButton>
            </DeleteWarning>
        </QuoteLineItemFormGrid>
    );
}