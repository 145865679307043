// noinspection RedundantConditionalExpressionJS

import {
    Button, Grid,
    TextField
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useStore } from "../../stores/StoreContext";
import { StyledHeader } from "../atoms/StyledHeader";

interface ISignUpForm {
    password: string;
    email: string;
}


const LoginForm: React.FunctionComponent = observer(() => {

    const { userStore, notificationStore, commonStore } = useStore();
    const navigate = useNavigate();
    const loginUser = async (data: ISignUpForm, resetForm: Function) => {
        const { displayNotification } = notificationStore;
        const { setLoading } = commonStore;
        setLoading(true);
        try {
            // API call integration will be here. Handle success / error response accordingly.

            let transformedData = {
                email: data.email,
                password: data.password,
            };
            const newLocation = await userStore.login(transformedData);
            if (newLocation) navigate(newLocation);
            // if (data) {
            //     setFormStatus(formStatusProps.success);
            //     resetForm({});
            // }
        } catch (error: any) {
            const response = error.response;
            if (response && response.status) {
                if (response.data?.message) {
                    displayNotification(response.data.message, "error");
                }
                else {
                    displayNotification("Error logging in, contact an administrator.", "error");
                }
            }
        } finally {
            setLoading(false)
        }
    };



    return (
        <div style={{
            maxWidth: "450px",
            display: "block",
            margin: "0 auto",
        }}>
            <Formik
                initialValues={{
                    password: "",
                    email: "",
                }}
                onSubmit={(values: ISignUpForm, actions) => {
                    loginUser(values, actions.resetForm)
                    setTimeout(() => {
                        actions.setSubmitting(false);
                    }, 500)
                }}

                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .email()
                        .required("Enter valid email-id"),
                    password: Yup.string().required(
                        "Please enter your password."
                    ),
                })}
            >
                {(props: FormikProps<ISignUpForm>) => {
                    const {
                        values,
                        touched,
                        errors,
                        handleBlur,
                        handleChange,
                        isSubmitting,
                    } = props;
                    return (
                        <Form>
                            <StyledHeader sx={{textAlign:"center", marginBottom:1}}>Sign In </StyledHeader>
                            <Grid
                                container
                                justifyContent="space-around"
                                direction="row"
                                spacing={"8px"}
                            >
                                <Grid
                                    item
                                    lg={10}
                                    md={10}
                                    sm={10}
                                    xs={10}
                                    sx={{
                                        "& > *": {
                                            width: "100%",
                                        }
                                    }}
                                >
                                    <TextField
                                        name="email"
                                        id="email"
                                        label="Email-id"
                                        value={values.email}
                                        type="email"
                                        // helperText={
                                        //     errors.email && touched.email
                                        //         ? errors.email
                                        //         : "Enter email-id"
                                        // }
                                        error={
                                            errors.email && touched.email
                                                ? true
                                                : false
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={10}
                                    md={10}
                                    sm={10}
                                    xs={10}
                                    sx={{
                                        "& > *": {
                                            width: "100%",
                                        }
                                    }}
                                >
                                    <TextField
                                        name="password"
                                        id="password"
                                        label="Password"
                                        value={values.password}
                                        type="password"
                                        helperText={
                                            errors.password && touched.password
                                                ? "Please enter your password"
                                                : ""
                                        }
                                        error={
                                            errors.password && touched.password
                                                ? true
                                                : false
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={10}
                                    md={10}
                                    sm={10}
                                    xs={10}
                                    sx={{ mt: '24px'}}
                                >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        disabled={isSubmitting}
                                    >
                                        Submit
                                    </Button>

                                    <NavLink
                                        to='/register'
                                        style={{ marginLeft: '6em', color: 'black' }}
                                    >
                                        Need an account?
                                    </NavLink>
                                    {/* {displayFormStatus && (
                                        <div className="formStatus">
                                            {formStatus.type === "error" ? (
                                                <p
                                                    className={
                                                        classes.errorMessage
                                                    }
                                                >
                                                    {formStatus.message}
                                                </p>
                                            ) : formStatus.type ===
                                              "success" ? (
                                                <p
                                                    className={
                                                        classes.successMessage
                                                    }
                                                >
                                                    {formStatus.message}
                                                </p>
                                            ) : null}
                                        </div>
                                    )} */}
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
});

export default LoginForm;
