import { useQuery } from "@apollo/client";
import { TabContext, TabPanel } from '@mui/lab';
import { Box, CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { GetFilesQueryResult, GET_FILES_QUERY } from '../../../api/graphqlqueries/getquotefilesquery';
import { BidQuoteName, GET_QUOTE_NAME_QUERY } from "../../../api/graphqlqueries/getquotename";
import { Colors } from '../../../constants/colors';
import { useStore } from "../../../stores/StoreContext";
import { HrSubTab, HrSubTabs } from '../../atoms/HrSubTabs';
import { StyledHeader } from '../../atoms/StyledHeader';
import { QuoteAdderLineItems } from './Adders/BidbookQuoteAddersLineItems';
import { BidbookBidReport } from './BidbookBidReport';
import { QuoteComments } from './BidBookQuoteComments';
import { QuoteFileGrid } from './BidbookQuoteFileGrid';
import { QuoteLineItems } from './BidbookQuoteLineItems';
import { QuoteReportComments } from './BidBookQuoteReportComments';
import { QuoteSummary } from './BidbookQuoteSummary';
import { QuoteReportSettings } from './BidbookReportSettings';
import { QuotePricingItems } from "./Pricing/BidbookQuotePricingItems";
import { BidbookRateSheet } from './RateSheet/BidbookRateSheet';
import { BidbookTerms } from './Terms/BidbookTerms';
import { BidbookCoverLetter } from "./CoverLetter/BidbookCoverLetter";
import { disableForStatus } from "../../../stores/ReactiveVariables";
import { FileUpload } from "../../molecules/FileUpload";
import axios from "axios";
import { DynamicsBidNotes } from "./DynamicsBidNotes";
import { QuoteJobs } from "./BidbookQuoteJobs";

interface FileUpload {
    Filename: string;
    Size?: number;
    CreateDate?: Date;
    CreatedBy?: string;
    FilePath?: string;
    Id: string;
    SharepointDocument?: boolean
}

export const QuoteDetails: React.FC = observer(() => {
    const { userStore } = useStore();
    const agent = userStore.agent;
    const [value, setValue] = useState('0');
    const [uploadingFile, setUploadingFile] = useState(false);
    const [fileList, setFileList] = useState<FileUpload[]>([]);
    const params = useParams<{ id: string }>();

    const { data, refetch } = useQuery<GetFilesQueryResult>(GET_FILES_QUERY, {
        variables: {
            BidQuoteId: params.id,
        },
        onCompleted: (data) => {
            setFileList([...(data?.QuoteAttachments ?? [])])
        },
        fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        axios.get(`/dynamics/${params.id}/SharePointFiles`).then((response) => {
            const fileData: FileUpload[] = response?.data?.value?.map((item: any) => {
                return {
                    Filename: item.fileName,
                    Size: item.size,
                    CreateDate: item.dateCreated,
                    CreatedBy: "Dynamics 365",
                    FilePath: item.filePath,
                    Id: item.id,
                    SharepointDocument: true
                }
            });
            setFileList((prevFileList) => ([...prevFileList, ...fileData]))
        }).catch((error) => {

        });
    }, []);

    const { loading: nameLoading, data: nameData } = useQuery<{ BidQuotes_by_pk: BidQuoteName }>(GET_QUOTE_NAME_QUERY, {
        variables: {
            BidQuoteId: params.id,
        },
        fetchPolicy: 'cache-and-network'
    });

    const name = nameData?.BidQuotes_by_pk;
    const jobName = `${name?.Customer?.ShortCode ? name?.Customer?.ShortCode : name?.Customer?.Name}_${name?.JobNumber}_${name?.BidDescription}_Rev_${name?.BidQuoteExternalRevisions[0]?.ExternalRevision ?? 0}`
    const jobStatus = name?.BidQuoteStatus[0]?.Status;

    useEffect(() => {
        if (jobStatus !== "Draft") {
            disableForStatus(true);
        }
        return () => {
            disableForStatus(false);
        }
    }, [jobStatus]);

    const handleTabChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        setUploadingFile(true);
        const formData = new FormData();
        for (const file of event.target.files) {
            formData.append('files', file);
        }
        const response = await agent.Attachments.post('bidquote', params.id!, formData);
        if (response.status === 200) {
            refetch();
            alert("Files Saved.");
        }
        setUploadingFile(false);
    }

    const handleFileDrop = async (e: React.DragEvent<HTMLElement>) => {
        setUploadingFile(true);
        const formData = new FormData();
        for (const file of e?.dataTransfer?.files ?? []) {
            formData.append('files', file);
        }
        if ((e?.dataTransfer?.files ?? []).length > 0) {
            const response = await agent.Attachments.post('bidquote', params.id!, formData);
            if (response.status === 200) {
                refetch();
                alert("Files Saved.");
            }
        }
        setUploadingFile(false);
    }

    return (
        <Box sx={styles.container}>
            <Grid container justifyContent="space-between" sx={{ p: "10px", backgroundColor: Colors.unselectedGray, position: "sticky", top: "0px", zIndex: 3 }}>
                <Grid item>
                    {nameLoading ? <CircularProgress {...circularProgressProps} /> : <NameHeader>Job Status: {jobStatus}</NameHeader>}
                </Grid>

                <Grid item>
                    {nameLoading ? <CircularProgress {...circularProgressProps} /> : <NameHeader>Job: {jobName}</NameHeader>}
                </Grid>
            </Grid>
            <TabContext value={value}>
                <HrSubTabs value={value} onChange={handleTabChange} aria-label="Bidbook Tabs" variant="scrollable">
                    <HrSubTab label="Summary" value="0" />
                    <HrSubTab label="Survey" value="2" />
                    <HrSubTab label="Construction" value="3" />
                    <HrSubTab label="Technician" value="4" />
                    <HrSubTab label="Inspection" value="5" />
                    <HrSubTab label="Adders" value="6" />
                    <HrSubTab label="Pricing" value="1" />
                    <HrSubTab label="Attachments" value="7" />
                    <HrSubTab label="Terms" value="9" />
                    <HrSubTab label="Cover Letter" value="11" />
                    <HrSubTab label="Report" value="8" />
                    <HrSubTab label="Rates" value="10" />
                </HrSubTabs>
                {/* Summary  */}
                <TabPanel value="0">
                    <Grid container direction="row" spacing={1}>
                        <Grid item container direction="column" xs={6}>
                            <QuoteSummary id={params.id!} />
                        </Grid>
                        <Grid item xs={6}>
                            <DynamicsBidNotes id={params.id!} rows={3} />
                            <QuoteComments id={params.id!} />
                            {
                                (jobStatus === "Awarded" || jobStatus === "Scheduled")  && (
                                    <QuoteJobs id={params.id!} />
                                )
                            }
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value="1">
                    <Grid container>
                        <QuotePricingItems id={params.id!} />
                    </Grid>
                </TabPanel>
                <TabPanel value="2" sx={{ p: 1 }}>
                    <Grid container>
                        <QuoteLineItems id={params.id!} type="Survey" />
                    </Grid>
                </TabPanel>
                <TabPanel value="3" sx={{ p: 1 }}>
                    <Grid container>
                        <QuoteLineItems id={params.id!} type="Construction" />
                    </Grid>
                </TabPanel>
                <TabPanel value="4" sx={{ p: 1 }}>
                    <Grid container>
                        <QuoteLineItems id={params.id!} type="Technician" />
                    </Grid>
                </TabPanel>
                <TabPanel value="5" sx={{ p: 1 }}>
                    <Grid container>
                        <QuoteLineItems id={params.id!} type="Inspection" />
                    </Grid>
                </TabPanel>
                <TabPanel value="6" sx={{ p: 1 }}>
                    <Grid container>
                        <QuoteAdderLineItems id={params.id!} />
                    </Grid>
                </TabPanel>
                <TabPanel value="7">
                    <Grid container>
                        <Grid item xs={12}>
                            <FileUpload
                                onChange={handleFileUpload}
                                accept="*"
                                onDrop={handleFileDrop}
                                backgroundColor={Colors.deepSeaGreen}
                                color={Colors.white}
                                width="280px"
                                height="64px"
                                labelFontSize="16px"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <QuoteFileGrid
                                data={fileList}
                            />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value="8">
                    <QuoteReportSettings id={params.id!} />
                    <QuoteReportComments id={params.id!} type="SpecialInstructions" title="Report Comments/Special Instructions" status={jobStatus} />
                    <BidbookBidReport id={params.id!} jobName={jobName} />
                </TabPanel>
                <TabPanel value="9">
                    <BidbookTerms />
                </TabPanel>
                <TabPanel value="10">
                    <BidbookRateSheet id={params.id!} />
                </TabPanel>
                <TabPanel value="11">
                    <BidbookCoverLetter id={params.id!} />
                </TabPanel>
                <TabPanel value="12">
                    <QuoteJobs id={params.id!} />
                </TabPanel>
            </TabContext>
        </Box>
    );
});

const circularProgressProps = {
    size: 20,
    thickness: 4.7
}

const NameHeader = styled(StyledHeader)({
    fontSize: "16px",
    margin: "0"
})


const styles = {
    container: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "left",
        alignItems: "left",
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "left",
        alignItems: "left",
    },
    errorLabels: {
        color: Colors.errorRed
    },
    headerStyle: {
        fontSize: '2em',
        fontWeight: 'bold',
        color: Colors.deepSeaGreen
    },
    autoComplete: {
        marginTop: ".3em",
        marginBottom: ".3em",
        width: 340,
        color: Colors.deepSeaGreen,
        display: "flex",
        flex: 1,
    },
    button: {
        color: "#fff",
        backgroundColor: Colors.deepSeaGreen,
        fontFamily: "Montserrat",
        textTransform: "uppercase",
        fontWeight: "400",
        "&:hover": {
            backgroundColor: Colors.darkSeaGreen
        }
    }
};
