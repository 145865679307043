import { gql, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { CircularProgress, Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router";
import { v4 } from "uuid";
import { BID_QUOTE_LIST_QUERY } from "../../../api/graphqlqueries/bidbooklistquery";
import { GET_LAST_MODIFIED } from "../../../api/graphqlqueries/getlastmodified";
import { GET_QUOTE_NAME_QUERY } from "../../../api/graphqlqueries/getquotename";
import { GET_QUOTE_REVISION_DETAILS_QUERY } from "../../../api/graphqlqueries/getquoterevisiondetails";
import { addMessage } from "../../../stores/ReactiveVariables";
import { appUserVar } from "../../../stores/ReactiveVariables";
import HrButton from "../../atoms/HrButton";
import HrTextArea from "../../atoms/HrTextArea";
import { StyledHeader } from "../../atoms/StyledHeader";
import { CreateJobsModal } from "./BidBookCreateJobsModal";
import _ from "lodash";
import { useStaticRendering } from "mobx-react-lite";

interface ButtonOptions {
    buttonText: string;
    newStatus: string;
    approval: boolean;
}

const CREATE_REVISION_MUTATION = gql`
  mutation CreateRevision($BidQuoteId: String = "") {
    CreateRevision(BidQuoteId: $BidQuoteId)
  }
`;

const COPY_BID_QUOTE_MUTATION = gql`
  mutation CopyBidQuote($BidQuoteId: String = "") {
    CopyBidQuote(BidQuoteId: $BidQuoteId)
  }
`;

export const CREATE_STATUS_MUTATION = gql`
  mutation CreateStatus($object: BidQuoteStatus_insert_input = {}) {
    insert_BidQuoteStatus_one(object: $object) {
      __typename
      BidQuoteId
      CreateDate
      Id
      Notes
      Status
      UserId
    }
  }
`;

interface GetBidStatusQueryResult {
    BidQuoteStatus: BidQuoteStatus[];
    BidQuotes: BidQuote[];
}

interface BidQuoteStatus {
    BidQuoteId: string;
    CreateDate: Date;
    Id: string;
    Notes: string;
    Status: "Draft" | "Pending" | "Approved" | "Awarded" | "Scheduled" | "Active" | "DNR" | "Deleted";
    UserId: string;
}

interface BidQuote {
    Id: string;
    JobId: string;
    BidDescription: string;
    JobCity: string;
    JobState: string;
    Zip: string;
    CustomerId: string;
    TimeAndMaterials: boolean;
    JobNumber: number;
}

export const GET_BID_STATUS_QUERY = gql`
query GetBidStatus($BidQuoteId: uniqueidentifier = "") {
  BidQuoteStatus(order_by: {CreateDate: desc}, where: {BidQuoteId: {_eq: $BidQuoteId}}) {
    BidQuoteId
    CreateDate
    Id
    Notes
    Status
    UserId
  }
  BidQuotes(where: {Id: {_eq: $BidQuoteId}}) {
    Id
    JobId
    JobNumber
    BidDescription
    JobCity
    JobState
    Zip
    CustomerId
    TimeAndMaterials
  }
}
`;




export const BidbookQuoteStatus: React.FC<{}> = (props) => {
    const params = useParams<{ id: string }>();
    const user = useReactiveVar(appUserVar);
    const [copying, setCopying] = useState(false);
    const [statusLoading, setStatusLoading] = useState(false);
    const [note, setNote] = useState('');
    const [newStatusOption, setNewStatusOption] = useState({ buttonText: '', newStatus: '' });
    const [open, setOpen] = useState(false);
    const [jobModalOpen, setJobModalOpen] = useState(false);
    const { data } = useQuery<GetBidStatusQueryResult>(GET_BID_STATUS_QUERY, {
        variables: {
            BidQuoteId: params.id!,
        },
        fetchPolicy: 'cache-and-network'
    });
    const [createRevision] = useMutation(CREATE_REVISION_MUTATION);
    const [createCopy] = useMutation(COPY_BID_QUOTE_MUTATION);
    const [updateStatus] = useMutation(CREATE_STATUS_MUTATION);


    if (!data) return <CircularProgress />
    const currentStatus = data?.BidQuoteStatus[0] ?? undefined;
    const currentBid = data?.BidQuotes[0] ?? undefined;
    // Current Status to new status options

    const buttons = {
        Draft: [
            {
                buttonText: 'Submit For Approval',
                newStatus: 'Pending'
            },
            {
                buttonText: 'Delete Bid',
                newStatus: 'Deleted',
            }
        ] as ButtonOptions[],
        Pending: [
            {
                buttonText: 'Approve Bid',
                newStatus: 'Approved',
                approval: true
            },
            {
                buttonText: 'Return for Revision',
                newStatus: 'Draft',
            },
            {
                buttonText: 'Delete Bid',
                newStatus: 'Deleted',
            }
        ] as ButtonOptions[],
        Approved: [
            {
                buttonText: 'Submit to Customer',
                newStatus: 'Submitted',
            },
            {
                buttonText: 'Return for Revision',
                newStatus: 'Draft',
            },
            {
                buttonText: 'Delete Bid',
                newStatus: 'Deleted',
            }
        ] as ButtonOptions[],
        Submitted: [
            {
                buttonText: 'Customer Needs Modifications',
                newStatus: 'Draft',
            },
            {
                buttonText: 'Quote Awarded',
                newStatus: 'Awarded',
            },
            {
                buttonText: 'Did not Receive',
                newStatus: 'DNR',
            },
            {
                buttonText: 'Delete Quote',
                newStatus: 'Deleted',
            }
        ] as ButtonOptions[],
        Awarded: [
            {
                buttonText: 'Schedule Bid',
                newStatus: 'Scheduled',
            },
            {
                buttonText: 'Archive Bid',
                newStatus: 'Archived',
            },
            {
                buttonText: 'Return to Draft',
                newStatus: 'Draft',
            },
        ] as ButtonOptions[],
        Scheduled: [
            {
                buttonText: 'Archive Bid',
                newStatus: 'Archived',
            },
            {
                buttonText: 'Return to Awarded',
                newStatus: 'Awarded',
            },
        ] as ButtonOptions[],
        Active: [

        ] as ButtonOptions[],
        DNR: [
            {
                buttonText: 'Return to Draft',
                newStatus: 'Draft',
            },
            {
                buttonText: 'Archive Bid',
                newStatus: 'Archived',
            }
        ] as ButtonOptions[],
        Deleted: [
            {
                buttonText: 'Return to Draft',
                newStatus: 'Draft',
            },
        ] as ButtonOptions[],
        Archived: [
            {
                buttonText: 'Return to Awarded',
                newStatus: 'Awarded',
            },
        ] as ButtonOptions[],
    };

    const buttonsTimeAndMaterials = {
        Draft: [
            {
                buttonText: 'Quote Awarded',
                newStatus: 'Awarded'
            },
            {
                buttonText: 'Delete Bid',
                newStatus: 'Deleted',
            }
        ] as ButtonOptions[],
        Awarded: [
            {
                buttonText: 'Schedule Bid',
                newStatus: 'Scheduled',
            },
            {
                buttonText: 'Archive Bid',
                newStatus: 'Archived',
            },
            {
                buttonText: 'Return to Draft',
                newStatus: 'Draft',
            },
        ] as ButtonOptions[],
        Scheduled: [
            {
                buttonText: 'Archive Bid',
                newStatus: 'Archived',
            },
            {
                buttonText: 'Return to Awarded',
                newStatus: 'Awarded',
            },
        ] as ButtonOptions[],
        Active: [

        ] as ButtonOptions[],
        DNR: [

        ] as ButtonOptions[],
        Deleted: [
        ] as ButtonOptions[],
        Archived: [
            {
                buttonText: 'Return to Awarded',
                newStatus: 'Awarded',
            },
        ]  as ButtonOptions[],
        
    };

    const updateStatusHandler = (newStatus: string) => () => {
        let areYouSure = true;
        if (note === '' && newStatus === 'Draft') {
            areYouSure = window.confirm("Update status without a note?");
        }
        setStatusLoading(true);
        if (areYouSure) {
            updateStatus({
                variables: {
                    object: {
                        BidQuoteId: params.id!,
                        UserId: user.id,
                        Status: newStatus,
                        Notes: note ?? null,
                        Id: v4(),
                    }
                },
                refetchQueries: [
                    GET_BID_STATUS_QUERY,
                    { query: BID_QUOTE_LIST_QUERY },
                    GET_QUOTE_NAME_QUERY,
                ],
                onCompleted: () => {
                    createRevision({
                        variables: {
                            BidQuoteId: params.id!
                        },
                        onCompleted: () => {
                            setStatusLoading(false);
                            closeModal();
                            addMessage("Quote Status Updated", { severity: 'success' });
                            axios.post(`/Bidbook/${params.id}/SendStatusEmail`).then().catch((error: any) => {
                                addMessage("Error Sending Email Notification", { severity: 'error' });
                            });
                            axios.put(`/Bidbook/${params.id}/UpdateDynamicsStatus/${newStatus}`);
                        },
                        refetchQueries: [
                            GET_QUOTE_REVISION_DETAILS_QUERY,
                            GET_LAST_MODIFIED
                        ]
                    })
                    if (newStatus === "Awarded") {
                        setJobModalOpen(true)
                    }
                }
            });
        } else {
            setStatusLoading(false);
        }
    }

    const createBidCopy = () => {
        setCopying(true);
        createCopy({
            variables: {
                BidQuoteId: params.id!,
            },
            refetchQueries: [{ query: BID_QUOTE_LIST_QUERY }],
            onCompleted: (data) => {
                setCopying(false);
            },
        });
    }

    const closeModal = () => {
        setNote('');
        setOpen(false);
    }

    const handleOpenModal = (newStatus: string) => () => {
        const currButtons = currentBid?.TimeAndMaterials ? buttonsTimeAndMaterials : buttons;
        const option = currButtons[currentStatus.Status as keyof typeof currButtons]?.find(s => s.newStatus === newStatus);
        setNewStatusOption(option ?? { buttonText: '', newStatus: '' });
        setOpen(true);
    }

    const getStatusButtons = () => {
        const currButtons = currentBid?.TimeAndMaterials ? buttonsTimeAndMaterials : buttons;
        let statusButtonOptions = currButtons[currentStatus.Status as keyof typeof currButtons];

        // Remove approval button if user is not a bid approval admin
        var approvedPermission = _.findIndex(user.roles, function (role) { return role.name === "Bid Approval Admin" });
        if (approvedPermission === -1 && !currentBid?.TimeAndMaterials) {
            statusButtonOptions = statusButtonOptions.filter(obj => obj.approval !== true);
        }

        return statusButtonOptions?.map(options =>
            <Grid key={options.newStatus} item>
                <HrButton onClick={handleOpenModal(options.newStatus)}>{options.buttonText}</HrButton>
            </Grid>
        ) ?? null;
    }

    const toggleJobModal = () => setJobModalOpen(t => !t);
    const getApprovalButton = () => {
        if ((currentStatus.Status === 'Awarded' || currentStatus.Status === 'Scheduled') && !currentBid.JobId) {
            return <Grid item>
                <HrButton disabled={jobModalOpen} onClick={toggleJobModal}>Create Job</HrButton>
            </Grid>
        }
    }

    return (
        <Grid item container direction="row" spacing={1} columns={12}>
            <Grid item xs={12}>
                <StyledHeader>
                    Bid Status: {currentStatus?.Status}
                </StyledHeader>
            </Grid>
            <Grid item xs={12}>
                <HrButton onClick={createBidCopy} disabled={copying}>{copying ? <CircularProgress size={10} /> : 'Copy Bid'}</HrButton>
            </Grid>

            <Grid item xs={12} container spacing="4px">
                {
                    getStatusButtons()
                }
            </Grid>
            <Grid item xs={12} container spacing="4px">

                {
                    getApprovalButton()
                }
            </Grid>
            <Dialog
                open={open}
                onClose={closeModal}
                maxWidth="lg"
                fullWidth
            >
                <DialogContent>
                    <StyledHeader>Note for Status Change:</StyledHeader>
                    <HrTextArea
                        label="Note"
                        fullWidth
                        multiline
                        minRows={3}
                        value={note}
                        overrideStatusDisable={true}
                        onChange={e => setNote(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <HrButton
                        onClick={updateStatusHandler(newStatusOption.newStatus)}
                        disabled={statusLoading}
                    >
                        {statusLoading ? <CircularProgress size={15} /> : newStatusOption.buttonText}
                    </HrButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={jobModalOpen}
                onClose={toggleJobModal}
            >
                <DialogContent>
                    <CreateJobsModal bidQuote={currentBid} handleClose={toggleJobModal} id={params.id!} />
                </DialogContent>
            </Dialog>
        </Grid>
    )

}