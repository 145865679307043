import { gql, useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CircularProgress, IconButton } from "@mui/material";
import { v4 } from "uuid";
import { GET_MATERIAL_SERVICE_QUERY } from "../../../../api/graphqlqueries/getmaterialservicequery";
import { GET_QUOTE_ITEMS_QUERY } from "../../../../api/graphqlqueries/getquoteitems";
import { appUserVar, disableForStatus } from "../../../../stores/ReactiveVariables";

interface ICopyProps {
  id: string;
}

const GET_SURVEY_ITEM_QUERY = gql`
  query GetSurveyItem($Id: uniqueidentifier = "") {
    BidQuoteItems_by_pk(Id: $Id) {
      BidQuoteMaterials {
        Id
        BidQuoteItemId
        BidQuoteId
        Description
        Rate
        OverrideRate
        Markup
        Quantity
        Unit
        Type
        Duration
        BidQuoteBillableId
        LineBreakAfter
        AdderHasCost
        Ordinal
      }
      BidQuoteId
      Type
      Area
      AverageMiles
      Boat
      CrewType
      CrewQuantity
      Discount
      Id
      Miles
      Mob
      MobWoPD
      Rate
      Segment
      Setup
      Ordinal
      OverrideRate
      BidQuoteRateId
      PerDay
      LineBreakAfter
      SurveyGrouping
      ParentBidQuoteItemId
    }
  }
`;

const COPY_SURVEY_LINE_ITEM_MUTATION = gql`
  mutation CopySurveyLineItem($Item: BidQuoteItems_insert_input = {}, $Materials: [BidQuoteMaterials_insert_input!] = {}, $OrdinalStart: Int = "", $Type: String = "", $BidQuoteId: uniqueidentifier = "") {
    update_BidQuoteItems(where: {Ordinal: {_gt: $OrdinalStart}, Type: {_eq: $Type}, BidQuoteId: {_eq: $BidQuoteId}}, _inc: {Ordinal: 1}) {
        returning {
            Ordinal
            Id
        }
    }
    insert_BidQuoteItems_one(object: $Item) {
      __typename
      Area
      AverageMiles
      BidQuoteId
      Boat
      CreateDate
      CreateUserId
      CrewQuantity
      CrewType
      Discount
      Id
      Location
      Miles
      Mob
      MobWoPD
      BidQuoteMaterials {
        __typename
        BidQuoteId
        Description
        Duration
        GroupWith
        Id
        Markup
        Quantity
        Rate
        OverrideRate
        BidQuoteBillableId
        Tax
        Type
        Unit
        LineBreakAfter
        AdderHasCost
        Ordinal
      }
      Rate
      OverrideRate
      BidQuoteRateId
      Segment
      Setup
      Type
      Ordinal
      PerDay
      LineBreakAfter
      SurveyGrouping
      ParentBidQuoteItemId
    }
    insert_BidQuoteMaterials(objects: $Materials) {
      returning {
        __typename
        BidQuoteItemId
        BidQuoteId
        Description
        Duration
        GroupWith
        Id
        Markup
        Quantity
        Rate
        OverrideRate
        BidQuoteBillableId
        Type
        Tax
        Unit
        LineBreakAfter
        AdderHasCost
        Ordinal
      }
    }
  }
`;

const COPY_SURVEY_LINE_ITEM_NO_MATERIALS_MUTATION = gql`
  mutation CopySurveyLineItem($Item: BidQuoteItems_insert_input = {}, $OrdinalStart: Int = "", $Type: String = "", $BidQuoteId: uniqueidentifier = "") {
    update_BidQuoteItems(where: {Ordinal: {_gt: $OrdinalStart}, Type: {_eq: $Type}, BidQuoteId: {_eq: $BidQuoteId}}, _inc: {Ordinal: 1}) {
        returning {
            Ordinal
            Id
        }
    }
    insert_BidQuoteItems_one(object: $Item) {
      __typename
      Area
      AverageMiles
      BidQuoteId
      Boat
      CreateDate
      CreateUserId
      CrewQuantity
      CrewType
      Discount
      Id
      Location
      Miles
      Mob
      MobWoPD
      Ordinal
      BidQuoteMaterials {
        __typename
        BidQuoteId
        Description
        Duration
        GroupWith
        Id
        Markup
        Quantity
        Rate
        OverrideRate
        BidQuoteBillableId
        Tax
        Type
        Unit
        LineBreakAfter
        AdderHasCost
        Ordinal
      }
      Rate
      OverrideRate
      BidQuoteRateId
      Segment
      Setup
      Type
      PerDay
      LineBreakAfter
      SurveyGrouping
      ParentBidQuoteItemId
    }
  }
`;
export const SurveyItemCopyButton: React.FC<ICopyProps> = ({ id }) => {
  const user = useReactiveVar(appUserVar);
  const disabledForStatus = useReactiveVar(disableForStatus);
  const [fetchItem] = useLazyQuery(GET_SURVEY_ITEM_QUERY, {
    variables: {
      Id: id,
    }
  });
  const [copyData, { loading: matLoading, reset: matReset }] = useMutation(COPY_SURVEY_LINE_ITEM_MUTATION);
  const [copyDataNoMaterials, { loading: noMaterialLoading, reset: noMatReset }] = useMutation(COPY_SURVEY_LINE_ITEM_NO_MATERIALS_MUTATION);
  const loading = matLoading || noMaterialLoading;
  const copyItem = () => {
    fetchItem().then(result => {
      copyItemWithData(result.data);
    });
  }
  const copyItemWithData = (data: any) => {
    const item = data?.BidQuoteItems_by_pk;
    if (!item) return;
    const { BidQuoteMaterials, __typename: typeName, ...survey } = item;
    survey.Id = v4().toUpperCase();
    survey.CreateUserId = user.id;
    survey.Segment = "";
    survey.Ordinal += 1;
    const quoteMaterials = BidQuoteMaterials.map((bqm: any) => {
      const { __typename, ...material } = bqm;
      material.BidQuoteItemId = survey.Id;
      return { ...material, Id: v4() };
    });
    if (quoteMaterials.length > 0) {
      copyData({
        variables: {
          Item: survey,
          Materials: quoteMaterials,
          BidQuoteId: survey.BidQuoteId,
          Type: survey.Type,
          OrdinalStart: survey.Ordinal - 1,
        },
        update: (cache, { data }) => {
          data.insert_BidQuoteMaterials.returning.forEach((bqm: any) => {
            cache.writeQuery({
              query: GET_MATERIAL_SERVICE_QUERY,
              variables: { Id: bqm.Id },
              data: { BidQuoteMaterials: [bqm] }
            });
          })
          cache.writeQuery({
            query: GET_SURVEY_ITEM_QUERY,
            variables: { Id: survey.Id },
            data: {
              BidQuoteItems_by_pk: { ...survey, __typename: typeName, BidQuoteMaterials: quoteMaterials }
            }
          });
        },
        refetchQueries: [GET_QUOTE_ITEMS_QUERY],
        onCompleted: () => matReset()
      });
    } else {
      copyDataNoMaterials({
        variables: {
          Item: survey,
          BidQuoteId: survey.BidQuoteId,
          Type: survey.Type,
          OrdinalStart: survey.Ordinal - 1,
        },
        update: (cache, { data }) => {
          cache.writeQuery({
            query: GET_SURVEY_ITEM_QUERY,
            variables: { Id: survey.Id },
            data: {
              BidQuoteItems_by_pk: { ...survey, BidQuoteMaterials: quoteMaterials }
            }
          });
        },
        onCompleted: () => noMatReset(),
        refetchQueries: [GET_QUOTE_ITEMS_QUERY]
      });
    }
  }

  return (
    <IconButton
      color="success"
      onClick={copyItem}
      disabled={disabledForStatus}
    >
      {loading ? <CircularProgress /> : <ContentCopyIcon />}
    </IconButton>
  )
}
