import { gql, useQuery } from "@apollo/client";
import { Box, Button, ButtonProps } from "@mui/material";
import { DataGridProProps, GridRenderCellParams, gridFilteredDescendantCountLookupSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid-pro";
import { HrDataGrid } from "../../molecules/HrDataGrid";
import { useState } from "react";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import { useNavigate } from "react-router";

interface QuoteJobsProps {
  id: string;
}

const GET_QUOTE_JOB_ITEMS_QUERY = gql`
query GetBidQuoteJobItems($Id: uniqueidentifier = "") {
  BidQuoteItems(where: {BidQuoteId: {_eq: $Id}}) {
    Id
    JobSegment {
      Id
      Job {
        Id
        CreatedAt
        Name
        Status
      }
      BidQuoteItem {
        Id
        Segment
        CreateDate
        Type
      }
    }
  }
}
`;

export const QuoteJobs = ({ id }: QuoteJobsProps) => {
  const [jobItems, setJobItems] = useState<any[]>([]);
  const { loading, data, error } = useQuery(GET_QUOTE_JOB_ITEMS_QUERY, {
    variables: {
      Id: id
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {

      const assignedItems = data?.BidQuoteItems?.filter((bqi: any) => bqi.JobSegment != null);

      const quoteJobItems: any[] = assignedItems?.filter((bqi: any, index: number, item: any[]) => {
        return item.findIndex((i: any) => i.JobSegment?.Job?.Id === bqi.JobSegment?.Job?.Id) === index;
      }).map((bqi: any) => {
        return {
          Id: bqi.JobSegment.Job.Id,
          detail: bqi.JobSegment.Job.Status,
          createDate: bqi.JobSegment.Job.CreatedAt,
          name: bqi.JobSegment.Job.Name,
          hierarchy: [bqi.JobSegment.Job.Id]
        }
      }) ?? [];

      const quoteJobSegments: any[] = assignedItems?.map((bqi: any) => {
        return {
          Id: bqi.JobSegment.BidQuoteItem.Id,
          detail: bqi.JobSegment.BidQuoteItem.Type,
          createDate: "",
          name: bqi.JobSegment.BidQuoteItem.Segment,
          hierarchy: [bqi.JobSegment.Job.Id, bqi.JobSegment.BidQuoteItem.Id]
        }
      }) ?? [];
      setJobItems([...quoteJobItems, ...quoteJobSegments]);
    }
  });

  const navigate = useNavigate();

  if (error) {
    console.error(error);
  }

  const columns = [{
    field: 'detail',
    headerName: 'Status/Division',
    width: 130,
    editable: false
  }, {
    field: 'name',
    headerName: 'Job Name/Segment',
    width: 250,
    editable: false,
  }, {
    field: 'createDate',
    headerName: 'Created On',
    width: 150,
    editable: false,
    valueFormatter: (params: any) => params.value !== "" ? new Date(params.value as string).toLocaleDateString() : ""
  }];

  function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
    const { id, field, rowNode } = props;
    const apiRef = useGridApiContext();
    const [expanded, setExpanded] = useState(false);
    const filteredDescendantCountLookup = useGridSelector(
      apiRef,
      gridFilteredDescendantCountLookupSelector,
    );
    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

    const handleClick: ButtonProps['onClick'] = (event: any) => {
      if (rowNode.type !== 'group') {
        return;
      }

      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
      apiRef.current.setCellFocus(id, field);
      setExpanded(!expanded);
      event.stopPropagation();
    };

    return (
      <Box sx={{ ml: rowNode.depth * 4 }}>
        <div>
          {filteredDescendantCount > 0 ? (
            <Button onClick={handleClick} tabIndex={-1} size="small">
              {expanded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
            </Button>
          ) : (
            <span />
          )}
        </div>
      </Box>
    );
  }

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: '',
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
    width: 90
  };

  const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.hierarchy;

  return (
    <Box sx={{ mt: 2 }}>
      <HrDataGrid
        treeData
        getTreeDataPath={getTreeDataPath}
        columns={columns ?? []}
        rows={jobItems ?? []}
        groupingColDef={groupingColDef}
        onRowDoubleClick={(row) => {
          navigate(`/Jobs/${row.row.hierarchy[0]}`)
        }}
      />
    </Box>
  )
}