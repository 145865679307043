
import React from "react";
import { GetQuoteSurveyTotals } from "./Totals/BidbookQuoteSurveyTotals";
import { GetQuoteItemTotals } from "./Totals/BidbookQuoteItemTotals";
import { GetQuoteAddersTotals } from "./Totals/BidbookQuoteAddersTotals";

interface BidbookPriceColumnProps {
    Id: string;
}

export const BidbookPriceColumn = ({ Id }: BidbookPriceColumnProps) => {

    const { GrandTotal: surveyTotals } = GetQuoteSurveyTotals(Id) ?? { GrandTotal: 0 };
    const { TotalPrice: constructionTotals } = GetQuoteItemTotals(Id, "Construction") ?? { TotalPrice: 0 };
    const { TotalPrice: technicianTotals } = GetQuoteItemTotals(Id, "Technician") ?? { TotalPrice: 0 };
    const { TotalPrice: inspectionTotals } = GetQuoteItemTotals(Id, "Inspection") ?? { TotalPrice: 0 };

    const adderCalcs = {
        survey: GetQuoteAddersTotals(Id, 'Survey'),
        construction: GetQuoteAddersTotals(Id, 'Construction'),
        technician: GetQuoteAddersTotals(Id, 'Technician'),
        inspection: GetQuoteAddersTotals(Id, 'Inspection'),
        lumpSum: GetQuoteAddersTotals(Id, 'Lump Sum'),
        ungrouped: GetQuoteAddersTotals(Id)
    };

    const addersTotalsByType = {
        survey: adderCalcs.survey?.markupTotalWithTax ?? 0,
        construction: adderCalcs.construction?.markupTotalWithTax ?? 0,
        technician: adderCalcs.technician?.markupTotalWithTax ?? 0,
        inspection: adderCalcs.inspection?.markupTotalWithTax ?? 0,
        lumpSum: adderCalcs.lumpSum?.markupTotalWithTax ?? 0
    };
    const ungroupedAddersTotals = adderCalcs.ungrouped?.markupTotalWithTax ?? 0;

    const addersTotals = ungroupedAddersTotals + addersTotalsByType.survey + addersTotalsByType.construction + addersTotalsByType.technician + addersTotalsByType.inspection + addersTotalsByType.lumpSum;

    const quoteTotals: number = (surveyTotals + constructionTotals + technicianTotals + inspectionTotals + addersTotals);

    return <p>{quoteTotals.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    })}</p>
}