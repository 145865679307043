import { BidQuoteMaterial as BQM, BidQuoteService as BQS } from "../../api/graphqlqueries/getquotereportquery";

export type BidQuoteMaterial = BQM;
export type BidQuoteService = BQS;

export interface QuoteFactors {
    OfficeHours: number;
    FieldHours: number;
    SurveyFieldHours: number;
    MileageRate: number;
    PerDiemRate: number;
    PerDiemCost?: number;
    TimecardMileageRate?: number;
}

export interface MaterialCalculations {
    TotalPrice: number;
    TotalMarkupPrice?: number;
    TotalPriceSalesTax?: number;
    TotalMarkupPriceSalesTax?: number;
}

export interface ServiceCalculations {
    TotalDays: number;
    TotalPerDiem: number;
    TotalPrice: number;
    UnitPrice: number;
    Locations?: number;
    TotalDiscountPrice?: number;
    PricePerLocation?: number;
    TotalCost?: number;
}


export function getMaterialCalculations(bidQuoteMaterial: BidQuoteMaterial): MaterialCalculations {
    const { Quantity, Rate, OverrideRate, BidQuoteBillable, Duration, Markup, Tax } = bidQuoteMaterial;
    const materialRate = OverrideRate ? Rate : BidQuoteBillable?.Cost ?? 0;
    const total = Number(Quantity) * Number(materialRate) * Number(Duration);
    const markupTotal = total * (1 + Number(Markup));
    const taxTotal = total + (total * (Tax ?? 0));
    return {
        TotalPrice: total,
        TotalMarkupPrice: markupTotal,
        TotalPriceSalesTax: taxTotal
    }
}

export function getAdderCalculations(bidQuoteMaterial: BidQuoteMaterial): MaterialCalculations {
    const { Quantity, Rate, Duration, Markup, Tax } = bidQuoteMaterial;
    const total = Number(Quantity) * Number(Rate) * Number(Duration);
    const markupTotal = total * (1 + Number(Markup));
    const taxTotal = total + (total * (Tax ?? 0));
    const markupTaxTotal = markupTotal + (markupTotal * (Tax ?? 0));
    return {
        TotalPrice: total,
        TotalMarkupPrice: markupTotal,
        TotalPriceSalesTax: taxTotal,
        TotalMarkupPriceSalesTax: markupTaxTotal
    }
}

export function sumMaterialCalculations(bidQuoteMaterials: BidQuoteMaterial[] | undefined): MaterialCalculations {
    const startVal = { TotalPrice: 0, TotalMarkupPrice: 0, TotalPriceSalesTax: 0 };
    if (!bidQuoteMaterials) return startVal;
    return bidQuoteMaterials.reduce((pv, cv) => {
        const cvTotals = getMaterialCalculations(cv);
        pv.TotalPrice += cvTotals.TotalPrice ?? 0;
        pv.TotalMarkupPrice += cvTotals.TotalMarkupPrice ?? 0;
        pv.TotalPriceSalesTax += cvTotals.TotalPriceSalesTax ?? 0;
        return pv;
    }, startVal);
}


export function sumMaterialCosts(bidQuoteMaterials: BidQuoteMaterial[] | undefined): number {
    const startVal = 0;
    if (!bidQuoteMaterials) return startVal;
    return bidQuoteMaterials.filter(bqm => bqm.Type !== 'Equipment').reduce((pv, cv) => {
        const cvTotals = getMaterialCalculations(cv);
        pv += cvTotals.TotalPrice ?? 0;
        return pv;
    }, startVal);
}

export function calculateService(bidQuoteService: BidQuoteService, quoteFactors: QuoteFactors, area: string): ServiceCalculations {
    const { Locations, Rate, OverrideRate, Days, Mob, MobWoPD, Setup, Rest, Unit, Personnel, Equipment, Mileage, Discount, PerDiem, BidQuoteRate } = bidQuoteService;
    const { OfficeHours, FieldHours, MileageRate, PerDiemRate, PerDiemCost, TimecardMileageRate } = quoteFactors ?? {};
    const totalDays = Number(Days) + Number(Mob) + Number(Setup);
    const calculatedPerDiem = Math.ceil(totalDays + Number(Rest) - Number(MobWoPD)) * PerDiemRate * Number(Personnel);
    const hours = bidQuoteService.BidQuoteRate?.ServiceType !== 'Office' ? FieldHours : OfficeHours;
    const timeUnit = totalDays * hours;
    const serviceRate = OverrideRate ? Rate : BidQuoteRate?.Rate ?? 0;
    const totalPrice = serviceRate * timeUnit + (PerDiem ? calculatedPerDiem : 0) + Number(Equipment) + Number(Mileage) * MileageRate;
    const unitPrice = Locations !== 0 ? totalPrice / Locations : 0;
    const totalDiscountPrice = totalPrice - (totalPrice * (Discount ?? 0)); 
    const hourlyCost = BidQuoteRate?.Cost ?? 0;
    const totalCost = (hourlyCost * 1.225  * totalDays * FieldHours) + (Number(PerDiemCost ?? 0) * Number(Personnel) * (totalDays + Number(Rest))) + (Mileage * (TimecardMileageRate ?? 0));
    return {
        TotalDays: totalDays + Number(Rest),
        TotalPerDiem: calculatedPerDiem,
        TotalPrice: totalPrice,
        UnitPrice: unitPrice,
        Locations: Locations,
        TotalDiscountPrice: totalDiscountPrice,
        TotalCost: totalCost
    }
}

export function getServiceTotals(bidQuoteService: BidQuoteService, quoteFactors: QuoteFactors, area: string): ServiceCalculations {
    const { Locations, Rate, OverrideRate, Days, Mob, MobWoPD, Setup, Rest, Unit, Personnel, Equipment, Mileage, Discount, PerDiem, BidQuoteRate } = bidQuoteService;
    const { OfficeHours, FieldHours, MileageRate, PerDiemRate, PerDiemCost, TimecardMileageRate } = quoteFactors ?? {};
    const totalDays = Number(Days) + Number(Mob) + Number(Setup);
    const calculatedPerDiem = Math.ceil(totalDays + Number(Rest) - Number(MobWoPD)) * PerDiemRate * Number(Personnel);
    const hours = bidQuoteService.BidQuoteRate?.ServiceType !== 'Office' ? FieldHours : OfficeHours;
    const timeUnit = totalDays * hours;
    const serviceRate = OverrideRate ? Rate : BidQuoteRate?.Rate ?? 0;
    const totalPrice = serviceRate * timeUnit + (PerDiem ? calculatedPerDiem : 0) + Number(Equipment) + Number(Mileage) * MileageRate;
    const unitPrice = Locations !== 0 ? totalPrice / Locations : 0;
    const totalDiscountPrice = totalPrice - (totalPrice * (Discount ?? 0)); 
    const hourlyCost = BidQuoteRate?.Cost ?? 0;
    const totalCost = (hourlyCost * 1.225  * totalDays * FieldHours) + (Number(PerDiemCost ?? 0) * Number(Personnel) * (totalDays + Number(Rest))) + (Mileage * (TimecardMileageRate ?? 0));
    return {
        TotalDays: totalDays + Number(Rest),
        TotalPerDiem: calculatedPerDiem,
        TotalPrice: totalPrice,
        UnitPrice: unitPrice,
        Locations: Locations,
        TotalDiscountPrice: totalDiscountPrice,
        TotalCost: totalCost
    }
}
