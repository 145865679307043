import { gql } from "@apollo/client";
import { CustomerContact } from "../../../../../api/graphqlqueries/getclientrepsquery";

export interface KickoffPacketContactsQueryResults {
    KickoffPacket_by_pk: KickoffPacketContacts;
}

export interface KickoffPacketContacts {
    Customer: {
        Id: string;
        Name: string;
    },
    HighridgeDataAnalystId: string;
    HighridgeFieldPointOfContactId: string;
    HighridgeInventorySupervisorId: string;
    HighridgeProjectManagerId: string;
    HighridgeSafetyLeadId: string;
    Id: string;
    KickoffPacketCustomerContacts: KickoffPacketCustomerContact[],
    ProjectManagerId: string;
    CustomerId: string;
}

export interface KickoffPacketCustomerContact {
    CustomerContactId: string;
    Id: string;
    Ordinal: number;
    Title: string;
    CustomerContact: CustomerContact | null;
}

export const KICKOFF_PACKET_CONTACTS_QUERY = gql`
  query KickoffPacketContacts($Id: uniqueidentifier = "") {
    KickoffPacket_by_pk(Id: $Id) {
      Customer {
        Id
        Name
      }
      HighridgeDataAnalystId
      HighridgeFieldPointOfContactId
      HighridgeInventorySupervisorId
      HighridgeProjectManagerId
      HighridgeSafetyLeadId
      Id
      KickoffPacketCustomerContacts(order_by: {Ordinal: asc}) {
        CustomerContactId
        Id
        Ordinal
        Title
      }
      ProjectManagerId
      CustomerId
    }
  }
`;

export const UPDATE_KICKOFF_CONTACTS_MUTATION = gql`
  mutation UpdateKickoffContacts($Id: uniqueidentifier = "", $_set: KickoffPacket_set_input = {}) {
    update_KickoffPacket_by_pk(pk_columns: {Id: $Id}, _set: $_set) {
      Id
      HighridgeDataAnalystId
      HighridgeFieldPointOfContactId
      HighridgeInventorySupervisorId
      HighridgeProjectManagerId
      HighridgeSafetyLeadId
      ProjectManagerId
      __typename
    }
  }
`;

export const UPDATE_KICKOFF_CUSTOMER_CONTACTS_MUTATION = gql`
  mutation UpdateKickoffCustomerContacts($objects: [KickoffPacketCustomerContact_insert_input!] = {}) {
    insert_KickoffPacketCustomerContact(objects: $objects, if_matched: {match_columns: Id, update_columns: [CustomerContactId, Title, Ordinal]}) {
      returning {
        CustomerContactId
        Id
        KickoffPacketId
        Ordinal
        Title
      }
    }
  }
`;