import { Grid, Divider, debounce } from "@mui/material";
import HrTextArea from "../../../../atoms/HrTextArea";
import { HrRadioGroup } from "../../../../atoms/HrRadioGroup";
import { HrRadioButton } from "../../../../atoms/HrRadioButton";
import { GeneralSurveyQuestions, UPDATE_GENERAL_SURVEY_QUESTIONS_MUTATION } from "./GeneralSurveyQuestionsQueries";
import { useFormik } from "formik";
import * as yup from "yup";
import { useCallback } from "react";
import { useMutation } from "@apollo/client";

interface GeneralSurveyQuestionsFormProps {
    generalSurveyQuestions: GeneralSurveyQuestions
}

const generalSurveyValidationSchema = yup.object().shape({
    ClientCommunication: yup.boolean().nullable(),
    ClientCommunicationNotes: yup.string().nullable(),
    ForeignCrossings: yup.boolean().nullable(),
    ForeignCrossingsNotes: yup.string().nullable(),
    KnownBrokenTs: yup.boolean().nullable(),
    KnownBrokenTsNotes: yup.string().nullable(),
    KnownProblemAreas: yup.boolean().nullable(),
    KnownProblemAreasNotes: yup.string().nullable(),
    OverallPipelineCondition: yup.string().nullable(),
    ProblemLandowners: yup.boolean().nullable(),
    ProblemLandownersNotes: yup.string().nullable(),
    RowConditions: yup.string().nullable()
});

export const GeneralSurveyQuestionsForm: React.FC<GeneralSurveyQuestionsFormProps> = ({ generalSurveyQuestions }: GeneralSurveyQuestionsFormProps) => {
    
    const [updateKickoffGeneralSurveyQuestions] = useMutation(UPDATE_GENERAL_SURVEY_QUESTIONS_MUTATION);
    
    const formik = useFormik({
        initialValues: {
            ClientCommunication: generalSurveyQuestions.ClientCommunication,
            ClientCommunicationNotes: generalSurveyQuestions.ClientCommunicationNotes,
            ForeignCrossings: generalSurveyQuestions.ForeignCrossings,
            ForeignCrossingsNotes: generalSurveyQuestions.ForeignCrossingsNotes,
            KnownBrokenTs: generalSurveyQuestions.KnownBrokenTs,
            KnownBrokenTsNotes: generalSurveyQuestions.KnownBrokenTsNotes,
            KnownProblemAreas: generalSurveyQuestions.KnownProblemAreas,
            KnownProblemAreasNotes: generalSurveyQuestions.KnownProblemAreasNotes,
            OverallPipelineCondition: generalSurveyQuestions.OverallPipelineCondition,
            ProblemLandowners: generalSurveyQuestions.ProblemLandowners,
            ProblemLandownersNotes: generalSurveyQuestions.ProblemLandownersNotes,
            RowConditions: generalSurveyQuestions.RowConditions
        },
        validationSchema: generalSurveyValidationSchema,
        onSubmit: (values) => {

        }
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const set = {
            ...formik.values,
            [event.target.name]: event.target.value
        };
        const castSet = generalSurveyValidationSchema.cast(set);
        formik.setValues(castSet as any);
        debouncedUpdateData(castSet);
    };
    const updateData = (set: any) => {
        updateKickoffGeneralSurveyQuestions({
            variables: {
                Id: generalSurveyQuestions.Id,
                _set: set
            }
        });
    };

    const debouncedUpdateData = useCallback(debounce((values:any) => updateData(values), 500), []);

    const { ClientCommunication, ClientCommunicationNotes, ForeignCrossings, ForeignCrossingsNotes, KnownBrokenTs, KnownBrokenTsNotes, KnownProblemAreas, KnownProblemAreasNotes, OverallPipelineCondition, ProblemLandowners, ProblemLandownersNotes, RowConditions } = formik.values;
    return (
        <form>
            <Grid container spacing={1}>
                <Grid container item xs={12} alignItems="center">
                    <Grid item xs={5}>
                        <HrRadioGroup
                            label="Client Communication"
                            name="ClientCommunication"
                            labelGridItemWidth={8}
                            radioGroupGridItemWidth={4}
                            value={ClientCommunication}
                            onChange={handleChange}
                        >
                            <HrRadioButton value={true} label="Y" />
                            <HrRadioButton value={false} label="N" />
                        </HrRadioGroup>
                    </Grid>
                    <Grid item xs={7}>
                        <HrTextArea
                            name="ClientCommunicationNotes"
                            label="Client Communication Notes"
                            value={ClientCommunicationNotes}
                            onChange={handleChange}
                            disabled={ClientCommunication !== true}
                            fullWidth
                            multiline
                        />
                    </Grid>
                </Grid>
                <Divider style={{ width: '100%', marginTop: "6px" }} />
                <Grid container item xs={12} alignItems="center">
                    <HrRadioGroup
                        label="Overall Pipeline Condition"
                        name="OverallPipelineCondition"
                        labelGridItemWidth={4}
                        radioGroupGridItemWidth={8}
                        value={OverallPipelineCondition}
                        onChange={handleChange}
                    >
                        <HrRadioButton value="Poor" label="Poor" />
                        <HrRadioButton value="Decent" label="Decent" />
                        <HrRadioButton value="Great" label="Great" />
                        <HrRadioButton value="Unknown" label="Unknown" />
                    </HrRadioGroup>
                </Grid>
                <Divider style={{ width: '100%', marginTop: "6px" }} />
                <Grid container item xs={12} alignItems="center">
                    <Grid item xs={5}>
                        <HrRadioGroup
                            label="Known Problem Areas"
                            name="KnownProblemAreas"
                            labelGridItemWidth={8}
                            radioGroupGridItemWidth={4}
                            value={KnownProblemAreas}
                            onChange={handleChange}
                        >
                            <HrRadioButton value={true} label="Y" />
                            <HrRadioButton value={false} label="N" />
                        </HrRadioGroup>
                    </Grid>
                    <Grid item xs={7}>
                        <HrTextArea
                            name="KnownProblemAreasNotes"
                            label="Known Problem Areas Notes"
                            value={KnownProblemAreasNotes}
                            onChange={handleChange}
                            disabled={KnownProblemAreas !== true}
                            fullWidth
                            multiline
                        />
                    </Grid>
                </Grid>
                <Divider style={{ width: '100%', marginTop: "8px" }} />
                <Grid container item xs={12} alignItems="center">
                    <Grid item xs={5}>
                        <HrRadioGroup
                            name="KnownBrokenTs"
                            label="Known Broken TS"
                            labelGridItemWidth={8}
                            radioGroupGridItemWidth={4}
                            value={KnownBrokenTs}
                            onChange={handleChange}
                        >
                            <HrRadioButton value={true} label="Y" />
                            <HrRadioButton value={false} label="N" />
                        </HrRadioGroup>
                    </Grid>
                    <Grid item xs={7}>
                        <HrTextArea
                            name="KnownBrokenTsNotes"
                            label="Known Broken TS Notes"
                            value={KnownBrokenTsNotes}
                            onChange={handleChange}
                            disabled={KnownBrokenTs !== true}
                            fullWidth
                            multiline
                        />
                    </Grid>
                </Grid>
                <Divider style={{ width: '100%', marginTop: "6px" }} />
                <Grid container item xs={12} alignItems="center">
                    <Grid item xs={5}>
                        <HrRadioGroup
                            name="ForeignCrossings"
                            label="Foreign Crossings"
                            labelGridItemWidth={8}
                            radioGroupGridItemWidth={4}
                            value={ForeignCrossings}
                            onChange={handleChange}
                        >
                            <HrRadioButton value={true} label="Y" />
                            <HrRadioButton value={false} label="N" />
                        </HrRadioGroup>
                    </Grid>
                    <Grid item xs={7}>
                        <HrTextArea
                            name="ForeignCrossingsNotes"
                            label="Foreign Crossings Notes"
                            value={ForeignCrossingsNotes}
                            onChange={handleChange}
                            disabled={ForeignCrossings !== true}
                            fullWidth
                            multiline
                        />
                    </Grid>
                </Grid>
                <Divider style={{ width: '100%', marginTop: "6px" }} />
                <Grid container item xs={12} alignItems="center">
                    <HrRadioGroup
                        name="RowConditions"
                        label="ROW Conditions"
                        labelGridItemWidth={4}
                        radioGroupGridItemWidth={8}
                        value={RowConditions}
                        onChange={handleChange}
                    >
                        <HrRadioButton value="Poor" label="Poor" />
                        <HrRadioButton value="Decent" label="Decent" />
                        <HrRadioButton value="Great" label="Great" />
                        <HrRadioButton value="Unknown" label="Unknown" />
                    </HrRadioGroup>
                </Grid>
                <Divider style={{ width: '100%', marginTop: "6px" }} />
                <Grid container item xs={12} alignItems="center">
                    <Grid item xs={5}>
                        <HrRadioGroup
                            name="ProblemLandowners"
                            label="Problem Landowners"
                            labelGridItemWidth={8}
                            radioGroupGridItemWidth={4}
                            value={ProblemLandowners}
                            onChange={handleChange}
                        >
                            <HrRadioButton value={true} label="Y" />
                            <HrRadioButton value={false} label="N" />
                        </HrRadioGroup>
                    </Grid>
                    <Grid item xs={7}>
                        <HrTextArea
                            name="ProblemLandownersNotes"
                            label="Problem Landowners Notes"
                            value={ProblemLandownersNotes}
                            onChange={handleChange}
                            disabled={ProblemLandowners !== true}
                            fullWidth
                            multiline
                        />
                    </Grid>
                </Grid>
                {/* <Grid item xs={6}>
                    <HrTextInput 
                        label="Project Length" 
                        value="460 miles" 
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <HrTextInput 
                        label="Descriptor" 
                        value="" 
                        fullWidth
                    />
                </Grid> */}
            </Grid>
        </form>
    )
}