import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from "../../../stores/StoreContext";
import HrButton from '../../atoms/HrButton';
import HrTextInput from '../../atoms/HrTextInput';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';
import { Box, MenuItem } from '@mui/material';
import { FormGrid } from '../../atoms/FormGrid';
import { StyledHeader } from '../../atoms/StyledHeader';
interface Props {
    props?: any;
}

const validationSchema = yup.object().shape({
    name: yup.string().required('Billable name is required'),
    cost: yup.number().required('Billable cost is requried'),
});

export const BillablesForm: React.FC<Props> = observer((prop: Props) => {
    const { billablesStore, modalStore, userStore, commonStore, notificationStore } = useStore();
    const { setLoading } = commonStore;
    const { displayNotification } = notificationStore;
    const { selectedBillable, updateBillable,
        createBillable, setSelectedBillable,
        editOrCreateMode, setEditOrCreateMode } = billablesStore;
    const { closeBillablesModal, billablesModalType } = modalStore;

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            id: selectedBillable?.id,
            name: selectedBillable?.name,
            cost: selectedBillable?.cost,
            type: billablesModalType ? billablesModalType : selectedBillable?.type,
        },
        onSubmit: (values: any) => {
            setLoading(true);
            if (values.id === undefined) {
                const newValues = {
                    ...values,
                    id: uuid(),
					type: parseInt(values.type)
                }
                createBillable(newValues)
                    .then(() => {
                        displayNotification("Billable Created", "success");
                    })
                    .catch(error => {
                        displayNotification("Error Creating Billable", "error");
                    })
                    .finally(() => {
                        setLoading(false);
                        closeBillablesModal();
                    })

                setSelectedBillable(undefined);
            }
            else {
                updateBillable(values)
                    .then(() => {
                        displayNotification("Billable Updated", "success");
                    })
                    .catch(error => {
                        displayNotification("Error Updating Billable", "error");
                    })
                    .finally(() => {
                        setLoading(false);
                        closeBillablesModal();
                    })
                closeBillablesModal();
                setSelectedBillable(undefined);
            }

        }
    })
    return (
        <Box sx={{ width: '100%' }}>
            <StyledHeader sx={{textAlign:"center"}}>{selectedBillable ? 'Edit' : 'New'} Billable</StyledHeader>
            <form onSubmit={formik.handleSubmit}>
                <FormGrid>
                    <HrTextInput
                        name='name'
                        label='Name'
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        errors={formik.errors.name}
                    />
                    <HrTextInput
                        select
                        name='type'
                        label='Type'
                        onChange={formik.handleChange}
                        value={billablesModalType ? billablesModalType : formik.values.type}
                        errors={formik.errors.type}
                    >
                        <MenuItem value="1">Material</MenuItem>
                        <MenuItem value="0">Equipment</MenuItem>
                    </HrTextInput>
                    <HrTextInput
                        name='cost'
                        label='Cost'
                        onChange={formik.handleChange}
                        value={formik.values.cost}
                        errors={formik.errors.cost}
                    />
                    {userStore?.appUser?.roles?.some(e => e.name === "BidBook Admin") &&
                        <HrButton
                            type='submit'
                            title='Submit'
                        />
                    }
                    <HrButton
                        title='Close'
                        onClick={() => {
                            setSelectedBillable(undefined)
                            closeBillablesModal();
                        }}
                    />
                </FormGrid>
            </form>
        </Box>
    );
});
