import { gql } from "@apollo/client/core";

export interface BidQuoteStatus{
    Id: string
    Status: string;
}

interface BidQuoteExternalRevision {
    Id: string;
    ExternalRevision: number;
}

interface Customer{
    ShortCode : string;
    Name: string;
}

export interface BidQuoteName {
    Id : string;
    BidDescription : string;
    BidQuoteStatus : BidQuoteStatus[];
    BidQuoteExternalRevisions: BidQuoteExternalRevision[];
    Customer : Customer;
    JobNumber : number;
}


export const GET_QUOTE_NAME_QUERY = gql`
    query GetQuoteItem($BidQuoteId: uniqueidentifier = "") {
        BidQuotes_by_pk(Id: $BidQuoteId) {
            Id
            JobNumber
            Customer {
                Name
                ShortCode
            }
            BidQuoteStatus(limit: 1, order_by: {CreateDate: desc}) {
                Id
                Status
            }
            BidQuoteExternalRevisions(limit: 1, order_by: {CreateDate: desc}) {
                ExternalRevision
                Id
                CreateDate
                CreateUserId
            }
            BidDescription
        }
    }
`;