import HrTextInput, { HrTextInputProps } from "./HrTextInput";


export const HrTextArea = (props: HrTextInputProps) => 
<HrTextInput
    multiline={props.multiline}
    maxRows={props.maxRows}
    minRows={1}
    {...props}
/>;

export default HrTextArea;