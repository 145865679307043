import { gql, useMutation, useQuery } from "@apollo/client";
import { ArrowDownward, ArrowUpward, Delete } from "@mui/icons-material";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Autocomplete, CircularProgress, Grid, Tooltip } from "@mui/material";
import { useEffect, useState, useCallback, useRef } from "react";
import debounce from '../../../../utils/debounce';
import { useParams } from "react-router";
import { v4 } from "uuid";
import { GET_DEFAULT_TERMS_QUERY } from "../../../../api/graphqlqueries/defaultterms";
import HrTextArea from "../../../atoms/HrTextArea";
import HrTextInput from "../../../atoms/HrTextInput";
import { StyledIconButton } from "../../../atoms/StyledIconButton";
import { VerticalButtonGroup } from "../../../atoms/VerticalButtonGroup";
import { addMessage, resetMessage } from "../../../../stores/ReactiveVariables";

const GET_BID_TERMS_QUERY = gql`
  query GetBidTerms($BidQuoteId: uniqueidentifier = "") {
    BidQuoteTerms(where: {BidQuoteId: {_eq: $BidQuoteId}}) {
      BidQuoteId
      Id
      Ordinal
      Term
      Type
    }
  }
`;

 const DELETE_BIDQUOTE_TERMS_MUTATION = gql`
  mutation DeleteBidquoteTerms($Id: uniqueidentifier = "", $BidQuoteId: uniqueidentifier = "", $Ordinal: Int = 0) {
    delete_BidQuoteTerms_by_pk(Id: $Id) {
      Id
      Ordinal
    }
    update_BidQuoteTerms(where: {BidQuoteId: {_eq: $BidQuoteId}, Ordinal: {_gt: $Ordinal}}, _inc: {Ordinal: -1}) {
      returning {
        Ordinal
        Id
      }
    }
  }
`;

const UPDATE_BIDQUOTE_TERMS_MUTATION = gql`
  mutation UpdateBidquoteTerms($objects: [BidQuoteTerms_insert_input!] = {}, $deleted: [uniqueidentifier!] = []) {
    insert_BidQuoteTerms(objects: $objects, if_matched: {match_columns: Id, update_columns: [Ordinal, Term, Type]}) {
      returning {
        Type
        Term
        Ordinal
        BidQuoteId
        Id
      }
    }
    delete_BidQuoteTerms(where: {Id: {_in: $deleted}}) {
      affected_rows
    }
  }
`;

const UPDATE_BIDQUOTE_TERMS_MUTATION_NO_DELETE = gql`
  mutation UpdateBidquoteTerms($objects: [BidQuoteTerms_insert_input!] = {}) {
    insert_BidQuoteTerms(objects: $objects, if_matched: {match_columns: Id, update_columns: [Ordinal, Term, Type]}) {
      returning {
        Type
        Term
        Ordinal
        BidQuoteId
        Id
      }
    }
  }
`;

interface BidQuoteTerm {
    Id: string;
    Type: string | null;
    Term: string;
    BidQuoteId: string;
    Ordinal: number;
}

interface DefaultTerm {
    Id: string;
    Name: string;
    Term: string;
}

export const BidbookTerms: React.FC<{}> = (props) => {
    const uneditedTerms = useRef(true);
    const params = useParams<{ id: string }>();
    const [terms, setTerms] = useState([] as BidQuoteTerm[]);
    const [deleteTerm] = useMutation(DELETE_BIDQUOTE_TERMS_MUTATION);
    const [selectedTerm, setSelectedTerm] = useState(null as DefaultTerm | null)
    const { data } = useQuery<{ BidQuoteTerms: BidQuoteTerm[] }>(GET_BID_TERMS_QUERY, {
        variables: {
            BidQuoteId: params.id!,
        }
    });

    const { data: defaultData } = useQuery<{ DefaultTerms: DefaultTerm[] }>(GET_DEFAULT_TERMS_QUERY);
    const [updateTermsNoDelete] = useMutation(UPDATE_BIDQUOTE_TERMS_MUTATION_NO_DELETE);

    useEffect(() => {
        setTerms(data?.BidQuoteTerms.map(bqt => ({
            Id: bqt.Id,
            Term: bqt.Term,
            Ordinal: bqt.Ordinal,
            Type: bqt.Type,
            BidQuoteId: bqt.BidQuoteId
        })) ?? []);
    }, [data, setTerms])

    const debouncer = useCallback(debounce((terms: BidQuoteTerm[]) => saveTerms(terms), 1000), []);

    useEffect(() => {
        if(uneditedTerms.current === false){
            debouncer(terms);
        }
    }, [terms, debouncer])

    const handleTermSelect = (e: any, value: DefaultTerm | null) => {
        setSelectedTerm(value);
    }

    const handleAddTerm = () => {
        const newTerm: BidQuoteTerm = {
            Id: v4(),
            Ordinal: terms.length,
            Term: selectedTerm?.Term ?? '',
            BidQuoteId: params.id!,
            Type: null,
        }
        uneditedTerms.current = false;
        setTerms(term => [...term, newTerm]);
        setSelectedTerm(null);
    }

    const handleChange = (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        resetMessage();
        uneditedTerms.current = false;
        const changedTerm = terms.find(t => t.Id === id);
        if (changedTerm) {
            changedTerm.Term = event.target.value;
            setTerms(terms => terms.map(t => t.Id === id ? changedTerm : t));
        }
    }

    const handleMoveUp = (id: string) => (e: any) => {
        const movedTerm = terms.find(t => t.Id === id)!;
        if (movedTerm.Ordinal > 0) {
            setTerms(t => {
                const currOrdinal = movedTerm?.Ordinal!;
                const switchedTerm = t.find(t => t.Ordinal === currOrdinal - 1)!;
                movedTerm.Ordinal -= 1;
                switchedTerm.Ordinal += 1;
                return [...t];
            });
        }
    }

    const handleMoveDown = (id: string) => (e: any) => {
        const movedTerm = terms.find(t => t.Id === id)!;
        if (movedTerm.Ordinal < terms.length - 1) {
            setTerms(t => {
                const currOrdinal = movedTerm?.Ordinal!;
                const switchedTerm = t.find(t => t.Ordinal === currOrdinal + 1)!;
                movedTerm.Ordinal += 1;
                switchedTerm.Ordinal -= 1;
                return [...t];
            });
        }
    }

    const handleDelete = (term: BidQuoteTerm) => (e: any) => {
        deleteTerm({
            variables: {Id: term.Id, BidQuoteId: term.BidQuoteId, Ordinal: term.Ordinal},
            refetchQueries: [GET_BID_TERMS_QUERY],
            onCompleted: (data) => {
                uneditedTerms.current = true;
                if (data) 
                    addMessage('Terms successfully saved', { severity: 'success' });
            }
        })
    }
    
    const saveTerms = (terms: BidQuoteTerm[]) => {
            updateTermsNoDelete({
                variables: {
                    objects: terms,
                },
                refetchQueries: [GET_BID_TERMS_QUERY],
                onCompleted: (data) => {
                    uneditedTerms.current = true;
                    if (data)
                        addMessage('Terms successfully saved', { severity: 'success' });
                }
            });
    }

    if (!data) return <CircularProgress />
    return <Grid item container direction="row" spacing={1}>
        <Grid item container xs={12} spacing={1} direction="row">
            <Grid item>
                <VerticalButtonGroup orientation="vertical">
                    <Tooltip describeChild title="Add Term" placement="right">
                        <StyledIconButton
                            onClick={handleAddTerm}
                        >
                            <NoteAddIcon />
                        </StyledIconButton>
                    </Tooltip>
                    {/* <Tooltip describeChild title="Save All Terms" placement="right">
                        <StyledIconButton
                            onClick={saveTerms}
                            disabled={updateTermsLoading}
                        >
                            { updateTermsLoading || updateTermsNoDeleteLoading ? <CircularProgress color="inherit" size={24} thickness={2.4}/> : <SaveIcon />}
                            
                        </StyledIconButton>
                    </Tooltip> */}
                </VerticalButtonGroup>
            </Grid>
            <Grid item xs={8}>
                <HrTextArea
                    label="Default Term"
                    value={selectedTerm?.Term ?? ''}
                    fullWidth
                    disabled
                    multiline
                    minRows={5}
                />
            </Grid>
            <Grid item xs={3}>
                <Autocomplete
                    options={defaultData?.DefaultTerms ? defaultData?.DefaultTerms.slice().sort((a: any, b: any) => a.Name?.localeCompare(b.Name)) : []}
                    value={selectedTerm ?? null}
                    renderInput={(params: any) =>
                        <HrTextInput
                            {...params}
                            name="Term"
                            label="Choose Term Template"
                            fullWidth
                        />
                    }
                    onChange={handleTermSelect}
                    getOptionLabel={(option: any) => option.Name}
                />
            </Grid>
        </Grid>
        {/* <Grid item xs={12}>
            <HrButton
                fullWidth
                onClick={saveTerms}
                disabled={updateTermsLoading}
            >
                { updateTermsLoading || updateTermsNoDeleteLoading ? <CircularProgress /> : 'Save Terms' }
            </HrButton>
        </Grid> */}
        {
            terms.slice().sort((a, b) => a.Ordinal - b.Ordinal).map((bqt, index) =>
                <Grid key={bqt.Id} item container xs={12} spacing={1}>
                    <Grid item>
                        <VerticalButtonGroup orientation="vertical">

                            <Tooltip describeChild title="Move Up" placement="right">
                                <StyledIconButton
                                    onClick={handleMoveUp(bqt.Id)}
                                ><ArrowUpward /></StyledIconButton>
                            </Tooltip>
                            <Tooltip describeChild title="Move Down" placement="right">
                                <StyledIconButton
                                    onClick={handleMoveDown(bqt.Id)}
                                ><ArrowDownward /></StyledIconButton>
                            </Tooltip>
                            <Tooltip describeChild title="Delete" placement="right">
                                <StyledIconButton
                                    onClick={handleDelete(bqt)}

                                >
                                    <Delete />
                                </StyledIconButton>
                            </Tooltip>
                        </VerticalButtonGroup>
                    </Grid>
                    <Grid item xs={8}>
                        <HrTextArea
                            label="Term"
                            value={bqt.Term}
                            onChange={handleChange(bqt.Id)}
                            fullWidth
                            minRows={5}
                            multiline
                        />

                    </Grid>
                </Grid>

            )
        }
    </Grid>
}
