import { gql } from "@apollo/client/core";

export interface GetBidbookUsersQueryResult {
    AspNetUsers: AppUser[];
}

export interface AppUser {
    FirstName: string;
    Id: string;
    LastName: string;
    UserRole: UserRole[];
}

interface UserRole {
    RolesId: number;
    UsersId: string;
    UserHighridgeRole: UserHighridgeRole;
}

interface UserHighridgeRole {
    Name: string;
}

export const GET_BIDBOOK_USERS_QUERY = gql`
query GetBidbookUsers {
    AspNetUsers {
      FirstName
      Id
      LastName
      UserRole(where: {UserHighridgeRole: {AppSection: {_eq: 7}}}) {
        RolesId
        UsersId
        UserHighridgeRole {
          Id
          Name
        }
      }
    }
  }   
`;