import { useQuery } from "@apollo/client";
import { GetQuoteItemsQueryResult, GET_QUOTE_ITEMS_QUERY } from "../../../../api/graphqlqueries/getquoteitems";
import { BidQuoteItem } from "../../../../utils/calculations/segments";
import { getSurveyPricingCalculation, SurveyPricingCalculations } from "../../../../utils/calculations/divisions";
import { HrCurrencyInput } from "../../../atoms/HrCurrencyInput";
import { QuoteLineItemFormGrid } from "../../../atoms/QuoteLineItemFormGrid";
import { Colors } from "../../../../constants/colors";
import { GET_QUOTE_FACTORS_QUERY } from "../../../../api/graphqlqueries/quotefactorsquery";


export const GetQuoteSurveyPricingTotals = (id: string) => {
    const { data, error } = useQuery<GetQuoteItemsQueryResult>(GET_QUOTE_ITEMS_QUERY, {
        variables: {
            BidQuoteId: id,
            Type: "Survey"
        },
        fetchPolicy: 'cache-and-network',
    });
    if (error)
        console.error(error);

    const { data: factorData } = useQuery(GET_QUOTE_FACTORS_QUERY, {
        variables: {
            Id: id
        }
    });

    const quoteFactors = factorData?.BidQuotes_by_pk;

    if (!data) return undefined;
    const totals = getSurveyPricingCalculation(data?.BidQuoteItems, quoteFactors);
    return totals;
}

export const QuoteSurveyPricingTotals = (props: any) => {
    const { bidQuoteItems, quoteFactors } = props;
    const width = {
        small: "9ch",
        medium: "12ch",
        large: "18ch",
        xl: "24ch",
    }
    const inputProps = {
        sx: {
            fontSize: "14px"
        }
    }
    const surveyCalculations: SurveyPricingCalculations = getSurveyPricingCalculation(bidQuoteItems as BidQuoteItem[], quoteFactors);
    return (
        <QuoteLineItemFormGrid sx={{ flexWrap: "nowrap" }}>
            <HrCurrencyInput
                label='Total Price'
                width={width.medium}
                value={surveyCalculations?.GrandTotal}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                disabled
            />
            <HrCurrencyInput
                label='Avg Price'
                width={width.small}
                value={surveyCalculations?.AveragePricePerMile}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                disabled
            />
            <HrCurrencyInput
                label='Price/Day'
                width={width.small}
                value={surveyCalculations?.AveragePricePerDay}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                disabled
            />
            <HrCurrencyInput
                label='Total Price (Discounted)'
                width={width.medium}
                value={surveyCalculations?.DiscountGrandTotal}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                sx={{
                    "& .MuiInputBase-root": {
                        backgroundColor: surveyCalculations?.TotalDiscountPrice !== 0 ? `${Colors.discountYellow}!important` : "inherit"
                    }
                }}
                disabled
            />
            <HrCurrencyInput
                label='Avg Price (Discounted)'
                width={width.small}
                value={surveyCalculations?.DiscountedAveragePricePerMile}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                sx={{
                    "& .MuiInputBase-root": {
                        backgroundColor: surveyCalculations?.DiscountedAveragePricePerMile !== 0 ? `${Colors.discountYellow}!important` : "inherit"
                    }
                }}
                disabled
            />
            <HrCurrencyInput
                label='Price/Day (Discounted)'
                width={width.small}
                value={surveyCalculations?.DiscountPricePerDay}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                disabled
            />
        </QuoteLineItemFormGrid>
    )
}