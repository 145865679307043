import { gql, useApolloClient, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import React, { useState } from "react";
import { appUserVar } from "../../../stores/ReactiveVariables";
import { GridColDef, GridToolbarContainer } from "@mui/x-data-grid-pro";
import RemoveButton from "../../atoms/RemoveButton";
import { HrDataGrid } from "../../molecules/HrDataGrid";
import { Button, Dialog, DialogContent } from "@mui/material";
import { Add } from "@mui/icons-material";
import { FormGrid } from "../../atoms/FormGrid";
import { SafetyTopicForm } from "./SafetyTopicForm";


const GET_SAFETY_TOPICS_QUERY = gql`
    query GeneralSafetyTopics {
        AdminGeneralSafetyTopics {
            Id
            Topic
        }
    }
`;

const DELETE_SAFETY_TOPIC_MUTATION = gql`
    mutation DeleteGeneralSafetyTopic($Id: uniqueidentifier = "") {
        delete_AdminGeneralSafetyTopics_by_pk(Id: $Id) {
            Id
        }
    }
`;

const UPDATE_SAFETY_TOPIC_MUTATION = gql`
    mutation UpdateGeneralSafetyTopic($Id: uniqueidentifier = "", $object: AdminGeneralSafetyTopics_set_input = {Id: "", Topic: ""}) {
        update_AdminGeneralSafetyTopics_by_pk(pk_columns: {Id: $Id}, _set: $object) {
            __typename
            Id
            Topic
        }
    }
`;

const CREATE_SAFETY_TOPIC_MUTATION = gql`
    mutation InsertGeneralSafetyTopic($object: AdminGeneralSafetyTopics_insert_input = {Id: "", Topic: ""}) {
        insert_AdminGeneralSafetyTopics_one(object: $object) {
            __typename
            Id
            Topic
        }
    }
`;

export const SafetyDataGrid: React.FC<{}> = (props) => {
    const user = useReactiveVar(appUserVar);
    const [selectedSafetyTopic, setSelectedSafetyTopic] = useState('');
    const { data, loading, error } = useQuery(GET_SAFETY_TOPICS_QUERY);
    const [createSafetyTopic] = useMutation(CREATE_SAFETY_TOPIC_MUTATION);
    const [updateSafetyTopic] = useMutation(UPDATE_SAFETY_TOPIC_MUTATION);
    const [deleteSafetyTopic] = useMutation(DELETE_SAFETY_TOPIC_MUTATION);
    const client = useApolloClient();

    const deleteSafetyTopicHandler = (Id: string) => {
        deleteSafetyTopic({
            variables: {
                Id
            },
            optimisticResponse: {
                delete_AdminGeneralSafetyTopics_by_pk: {
                    Id,
                    __typename: "AdminGeneralSafetyTopics"
                }
            },
            update(cache, { data }) {
                cache.evict({
                    id: `AdminGeneralSafetyTopics:${Id}`
                });
                cache.gc();
            },
            onError(error) {
                console.error(error);
                client.refetchQueries({
                    include: "active"
                });
            }
        });
    }

    const handleSave = (object: any) => {
        if (selectedSafetyTopic === 'new') {
            createSafetyTopic({
                variables: {
                    object
                },
                optimisticResponse: {
                    insert_AdminGeneralSafetyTopics_one: {
                        __typename: "AdminGeneralSafetyTopics",
                        ...object
                    }
                },
                update: (cache, { data }) => {
                    cache.updateQuery({
                        query: GET_SAFETY_TOPICS_QUERY
                    },
                        (cacheData: any) => ({
                            AdminGeneralSafetyTopics: [...cacheData.AdminGeneralSafetyTopics, data.insert_AdminGeneralSafetyTopics_one]
                        }))
                }
            })
        } else {
            updateSafetyTopic({
                variables: {
                    Id: selectedSafetyTopic,
                    object
                },
                optimisticResponse: {
                    update_AdminGeneralSafetyTopics_by_pk: {
                        __typename: "AdminGeneralSafetyTopics",
                        ...object
                    }
                },
            })
        }
        setSelectedSafetyTopic('');
    }

    const rows = data?.AdminGeneralSafetyTopics ?? [];
    const selectedSafetyTopicObject = rows.find((item: any) => item.Id === selectedSafetyTopic);
    const columns: GridColDef[] = [
        {
            field: 'removeServiceCategory',
            headerName: ' ',
            width: 50,
            renderCell: (rows) => {
                const safetyTopicId = rows.id;
                return (
                    <RemoveButton
                        title="Remove Safety Topic"
                        onClick={() => deleteSafetyTopicHandler(safetyTopicId as string)} />
                )
            },
            headerClassName: 'billables-grid-header'
        },
        {
            field: 'Topic',
            headerName: 'Safety Topic',
            width: 300,
            editable: true,
            headerClassName: 'billables-grid-header'
        }
    ]

    if (error) {
        console.error(error);
    }
    return (
        <>
            <HrDataGrid
                loading={loading}
                rows={rows}
                columns={columns}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                disableRowSelectionOnClick
                onRowDoubleClick={(model, details) => {
                    setSelectedSafetyTopic(model.id as string);
                }}
                components={{
                    Toolbar: () => <GridToolbarContainer>
                        <Button color="primary" startIcon={<Add />} onClick={() => setSelectedSafetyTopic('new')}>
                            Add Safety Topic
                        </Button>
                    </GridToolbarContainer>
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'Topic', sort: 'asc' }]
                    },
                    columns: {
                        columnVisibilityModel: {
                            removeSafetyTopic: user?.roles?.some(e => e.name === "BidBook Admin") ?? false
                        }
                    }
                }}
            />
            <Dialog
                open={selectedSafetyTopic !== ''}
                onClose={() => setSelectedSafetyTopic('')}
            >
            <DialogContent>

                <Button
                    onClick={() => {
                        setSelectedSafetyTopic('');
                    }}
                    variant="contained"
                    color="primary"
                    className="modal-exit"
                >
                    x
                </Button>
                <FormGrid>
                    <SafetyTopicForm item={selectedSafetyTopicObject} onSave={handleSave} onCancel={() => setSelectedSafetyTopic('')} />
                </FormGrid>
            </DialogContent>
        </Dialog >
        </>
    );
}
