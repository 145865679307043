export const Colors = {
    deepSeaGreen: "#214D4D",
    gold: "#D4AF37",
    blueSlate: "#759B9B",
    black: "#343434",
    white: "#FBFBFB",
    unselectedGray: "#DCDCDC",
    goldLighter: "#E9C246",
    errorRed: "#E23E3E",
    deleteRed: "#EB7F7F",
    emphasisRed: "#FFCCBB",
    darkSeaGreen: "#1c4141",
    lightSeaGreen: "#295A5A",
    discountYellow: "#fafad2",
    lightWarningRed: "#FF8886",
    lightCautionYellow: "#FFFCC9",
    lightSuccessGreen: "#90EE90",
    disabledGrayDarker: "#AEAEAE"
};
