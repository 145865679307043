import { gql } from "@apollo/client/core";
import { BidQuoteBillable } from "./bidquotebillables";
import { BidQuoteRate } from "./bidquoterates";

export interface GetSurveyItemQueryReturn {
  BidQuoteItems_by_pk: BidQuoteItem;
}

export interface BidQuoteItem {
  BidQuoteMaterials: BidQuoteMaterial[];
  Area: string;
  AverageMiles: number;
  Boat: number;
  CrewType: string;
  CrewQuantity: number;
  Discount: number;
  Id: string;
  Miles: number;
  Mob: number;
  MobWoPD: number;
  Rate: number;
  OverrideRate: boolean;
  BidQuoteRate: BidQuoteRate;
  BidQuoteRateId: string;
  Segment: string;
  Setup: number;
  Ordinal: number;
  PerDay: boolean;
  LineBreakAfter: boolean;
  ParentBidQuoteItemId: string | null;
  SurveyGrouping: string | null;
}

export interface BidQuoteMaterial {
  Id: string;
  BidQuoteItemId: string;
  BidQuoteId: string;
  Description: string;
  Rate: number;
  OverrideRate: boolean;
  BidQuoteBillable: BidQuoteBillable;
  BidQuoteBillableId: string;
  Markup: number;
  Quantity: number;
  Unit: string;
  Type: string;
  Duration: number;
  Ordinal: number;
  LineBreakAfter: boolean;
}

export const GET_SURVEY_ITEM_QUERY = gql`
  query GetSurveyItem($Id: uniqueidentifier = "") {
    BidQuoteItems_by_pk(Id: $Id) {
      BidQuoteMaterials( order_by: { Ordinal: asc }) {
        Id
        BidQuoteItemId
        BidQuoteId
        Description
        Rate
        OverrideRate
        BidQuoteBillable {
          Id
          Name
          Cost
        }
        BidQuoteBillableId
        Markup
        Quantity
        Unit
        Type
        Duration
        Ordinal
        LineBreakAfter
      }
      Area
      AverageMiles
      Boat
      CrewType
      CrewQuantity
      Discount
      Id
      Miles
      Mob
      MobWoPD
      Rate
      OverrideRate
      BidQuoteRate {
        Id
        Name
        Rate
        ServiceType
      }
      BidQuoteRateId
      Segment
      Setup
      Ordinal
      PerDay
      LineBreakAfter
      ParentBidQuoteItemId
      SurveyGrouping
    }
  }
`;