import CommonStore from "./CommonStore";
import UserStore from "./UserStore";
import CrewStore from "./CrewStore";
import ModalStore from "./ModalStore";
import BillablesStore from "./BillablesStore";
import ClientStore from "./ClientStore";
import EmployeeStore from "./EmployeeStore";
import JobStore from "./jobStore";
import NotificationStore from "./NotificationStore";
import { Agent } from "../api/agent";

interface IStore {
    commonStore: CommonStore;
    userStore: UserStore;
    crewStore: CrewStore;
    modalStore: ModalStore;
    billablesStore: BillablesStore;
    clientStore: ClientStore;
    employeeStore: EmployeeStore;
    jobStore: JobStore;
    notificationStore : NotificationStore;
}

export class Store implements IStore {
    public commonStore: CommonStore;
    public userStore: UserStore;
    public crewStore: CrewStore;
    public modalStore: ModalStore;
    public billablesStore: BillablesStore;
    public clientStore: ClientStore;
    public employeeStore: EmployeeStore;
    public jobStore: JobStore;
    public notificationStore: NotificationStore;

    constructor(agent: Agent) {
        this.commonStore = new CommonStore();
        this.userStore = new UserStore(agent);
        this.crewStore = new CrewStore(agent);
        this.modalStore = new ModalStore();
        this.billablesStore = new BillablesStore(agent);
        this.clientStore = new ClientStore(agent);
        this.employeeStore = new EmployeeStore(agent);
        this.jobStore = new JobStore(agent);
        this.notificationStore = new NotificationStore();
    }
};

