import { gql } from "@apollo/client/core";

export interface GetQuoteAddersItemsQueryResult {
    BidQuoteMaterials: BidQuoteMaterial[];
}

interface BidQuoteMaterial {
    Id: string;
    BidQuoteItemId: string;
    BidQuoteId: string;
    Description: string;
    Rate: number;
    Markup: number;
    Quantity: number;
    Unit: string;
    Type: string;
    Duration: number;
    Tax: number;
    GroupWith: string;
    AdderHasCost: boolean;
}

export const GET_QUOTE_ADDERS_ITEMS = gql`
    query GetQuoteAdders($BidQuoteId: uniqueidentifier = "") {
        BidQuoteMaterials(where: {BidQuoteId: {_eq: $BidQuoteId}}) {
            Id
            BidQuoteItemId
            BidQuoteId
            Description
            Rate
            Markup
            Quantity
            Unit
            Type
            Duration
            Tax
            GroupWith
            AdderHasCost
        }
    }
`;