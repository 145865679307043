import { useMutation, useQuery } from "@apollo/client";
import CommentIcon from '@mui/icons-material/Comment';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { CircularProgress, Grid, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from 'yup';
import { CREATE_COMMENT_MUTATION, getVariables } from "../../../api/graphqlqueries/createcommentmutation";
import { BidQuoteEvent, BidTimeLineEvent, GET_BID_TIMELINE_EVENTS } from "../../../api/graphqlqueries/getbidtimelineevents";
import { Colors } from "../../../constants/colors";
import { useStore } from "../../../stores/StoreContext";
import HrButton from "../../atoms/HrButton";
import HrTextInput from "../../atoms/HrTextInput";
import { StyledHeader } from '../../atoms/StyledHeader';


interface QuoteCommentProps {
    id: string;
}

interface TimelineEvent {
    Id: string;
    Type : "status" | "comment";
    CreateDate : Date;
    Comment : string;
    User : string;
    Status? : string;
}

const quoteCommentSchema = yup.object().shape({
    comment: yup.string().required()
});

export const QuoteComments = ({ id }: QuoteCommentProps) => {
    const { userStore } = useStore();

    const { loading, data, error, refetch } = useQuery<BidTimeLineEvent>(GET_BID_TIMELINE_EVENTS, {
        variables: {
            BidQuoteId: id
        },
        fetchPolicy: 'cache-and-network',
    });
    const [addComment] = useMutation(CREATE_COMMENT_MUTATION);

    const formik = useFormik({
        validationSchema: quoteCommentSchema,
        initialValues: { comment: '' },
        onSubmit: (values) => {
            addComment({
                variables: getVariables(id, values.comment, userStore.appUser?.id ?? ''),
                onCompleted: () => refetch({BidQuoteId: id})
            });
            formik.resetForm();
        }
    });

    if (error) {
        console.error(error);
    }
    if (loading && !data)
    {
        return (<CircularProgress />)
    }

    const commentEvents : TimelineEvent[] | undefined = data?.BidComment?.map((comment : BidQuoteEvent) => {
        const timelineEvent : TimelineEvent = {
            Id: comment.Id,
            Type : "comment",
            CreateDate : new Date(comment.CreateDate + 'Z'),
            Comment : comment.Comment,
            User : `${comment.User.FirstName} ${comment.User.LastName}`
        };
        return timelineEvent;
    })

    const statusEvents : TimelineEvent[] | undefined = data?.BidQuoteStatus?.map((status : BidQuoteEvent) => {
        const timelineEvent : TimelineEvent = {
            Id: status.Id,
            Type : "status",
            CreateDate : new Date(status.CreateDate + 'Z'),
            Comment : status.Comment,
            User : `${status.User.FirstName} ${status.User.LastName}`,
            Status : status.Status
        };
        return timelineEvent;
    })

    const timeLineEvents : TimelineEvent[] | undefined = commentEvents?.concat(statusEvents ?? []).sort((a,b) => b.CreateDate.getTime() - a.CreateDate.getTime());

    return (
        <Grid container spacing={1} direction="column">
            <Grid item>           
                <StyledHeader>Comments</StyledHeader>
            </Grid>
            <Grid item>
                <form onSubmit={formik.handleSubmit}>
                    <HrTextInput
                        id="standard-multiline-static"
                        name="comment"
                        label="Add Comment"
                        fullWidth
                        multiline
                        minRows={4}
                        placeholder="Add Comment"
                        value={formik.values.comment}
                        overrideStatusDisable={true}
                        onChange={formik.handleChange}
                    />
                    <HrButton
                        sx={{ mt: 1, mb: 1 }}
                        type='submit'
                        title='Add Comment'
                    />
                </form>
            </Grid>
            <Grid item>           
                <StyledHeader>Timeline</StyledHeader>
            </Grid>
            <Grid item sx={{maxHeight:"400px", overflowY :"scroll"}}>
                <Timeline sx={{margin:0, padding:0}}>
                    { timeLineEvents?.map((item : any, i : number, row : any) => {
                        const lastItem = i + 1 !== row.length;
                        return(
                            <HrTimeLineEvent key={item.Id} item={item} lastItem={lastItem}/>
                        )
                    })}
                </Timeline>                             
            </Grid>
        </Grid>
    )
}

const HrTimeLineEvent = (props: { item : TimelineEvent, lastItem : boolean}) => {
    const { item, lastItem } = props;
    const { Type, CreateDate, Comment, User, Status } = item;
    const characterLimit = 50;
    const [isOpen, toggleOpen] = useState(false);
    const characterLimitExceeded = item?.Comment?.length > characterLimit ? true : false ;
    const truncatedComment = item?.Comment?.substring(0,characterLimit) + "...";
    return(
        <TimelineItem>
            <TimelineOppositeContent color="text.secondary" sx={styles.oppositeContent} >
                {CreateDate?.toLocaleDateString('en-us', {year:"numeric", month:"short", day:"numeric"}) }<br/>
                {CreateDate?.toLocaleTimeString('en-us') }
            </TimelineOppositeContent>
            <TimelineSeparator>
            <TimelineDot color={Type==="status" ? "secondary" : "primary"}>
            { Type==="status" ? <SyncAltIcon sx={styles.timelineIcon}/> : <CommentIcon sx={styles.timelineIcon}/>}
            </TimelineDot>
            { lastItem ? <TimelineConnector /> : null}                              
            </TimelineSeparator>
            <TimelineContent sx={styles.contentBox}>
                <Grid container>
                    <Grid item xs={11}>
                        { Type === "status" && (
                            <Typography paragraph sx={styles.statusContent}>
                                Status Changed To {Status}
                            </Typography>
                        )}
                        <Typography paragraph sx={styles.content}>
                            {isOpen || !characterLimitExceeded ? Comment : truncatedComment }
                        </Typography>
                        <Typography sx={styles.contentByUser}>By {`${User}`}</Typography>  
                    </Grid>
                    <Grid item xs={1}>
                    {characterLimitExceeded && <IconButton sx={styles.iconButton} onClick={()=> toggleOpen(!isOpen)}>{isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>} 
                    </Grid>
                </Grid>       
            </TimelineContent>
        </TimelineItem>
    )
}

const styles = {
    oppositeContent : {
        fontSize:"13px", 
        p:"8px"
    },
    contentBox : {
        flexGrow:"4"
    },
    contentByUser : {
        fontSize:"13px", 
        color: Colors.darkSeaGreen, 
        fontWeight:"600"
    },
    content : {
        margin:0, 
        fontSize:"14px", 
        lineHeight:"1.1em"
    },
    iconButton : {
        marginTop : 0
    },
    statusContent : {
        fontSize:"13px",
        color: Colors.gold, 
        fontWeight:"600",
        margin : 0
    },
    timelineIcon : {
        fontSize : "14px"
    }
}