import { gql } from "@apollo/client/core";

export const ADD_QUOTE_ITEM_MUTATION = gql`
mutation AddQuoteItem($object: BidQuoteItems_insert_input = {}) {
  insert_BidQuoteItems_one(object: $object) {
    Id
    Boat
    CreateDate
    CrewType
    Location
    Discount
    Location
    Miles
    Mob
    MobWoPD
    Rate
    OverrideRate
    BidQuoteRate {
      Id
      Name
      Rate
      ServiceType
    }
    BidQuoteRateId
    Segment
    Setup
    Type
    PerDay
    LineBreakAfter
    Ordinal
    ParentBidQuoteItemId
    SurveyGrouping
  }
}
`;


export const UPDATE_QUOTE_ITEM_MUTATION = gql`
mutation UpdateQuoteItem($Id: uniqueidentifier = "", $Item: BidQuoteItems_set_input = {}) {
  update_BidQuoteItems_by_pk(pk_columns: {Id: $Id}, _set: $Item) {
    Id
    Area
    AverageMiles
    Boat
    CreateDate
    CrewQuantity
    CrewType
    Discount
    Location
    Miles
    Mob
    MobWoPD
    Rate
    OverrideRate
    BidQuoteRate {
      Id
      Name
      Rate
      ServiceType
    }
    BidQuoteRateId
    Segment
    Setup
    Type
    PerDay
    LineBreakAfter
    Ordinal
    ParentBidQuoteItemId
    SurveyGrouping
  }
}
`;

export const UPDATE_ORDINALS_MUTATION = gql`
  mutation UpdateOrdinals($objects: [BidQuoteItems_insert_input!] = {}) {
    insert_BidQuoteItems(objects: $objects, if_matched: {match_columns: Id, update_columns: Ordinal}) {
      returning {
        Id
        Ordinal
      }
    }
  }
`;

export const DELETE_QUOTE_ITEM_MUTATION = gql`
  mutation DeleteQuoteItem($Id: uniqueidentifier = "", $BidQuoteId: uniqueidentifier = "", $Ordinal: Int = 0) {
    delete_BidQuoteItems_by_pk(Id: $Id) {
      Id
    }
    update_BidQuoteItems(where: {Ordinal: {_gt: $Ordinal}, BidQuoteId: { _eq: $BidQuoteId}}, _inc: {Ordinal: -1}) {
      returning {
        Id
        Ordinal
      }
    }
  }
`;