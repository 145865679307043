import React from 'react';
import * as yup from 'yup';
import { useFormik } from "formik";
import { v4 } from 'uuid';
import { HighRidgeColorButton } from "../../atoms/HighRidgeColorButton"; 
import { Box, Button, Grid } from "@mui/material";
import HrTextInput from "../../atoms/HrTextInput";

interface SafetyItem {
    Id: string;
    Topic?: string;
}
interface SafetyTopicFormProps {
    item?: SafetyItem;
    onSave: (item: SafetyItem) => void;
    onCancel?: () => void;
    bidQuoteId?: string;
}


const SafetyItemSchema = yup.object().shape({
    Topic: yup.string().required()
});


export const SafetyTopicForm: React.FC<SafetyTopicFormProps> = ({ item, onSave, onCancel, bidQuoteId }) => {
    const Id = item?.Id ?? v4();
    const formik = useFormik({
        validationSchema: SafetyItemSchema,
        initialValues: {
            Topic: item?.Topic ?? '',
        },
        onSubmit: (unCastValues) => {
            const values = SafetyItemSchema.cast(unCastValues);
            const { Topic } = values;
            onSave({ Id, Topic: Topic });
            formik.setSubmitting(false);
        }
    });
    return (<React.Fragment>
        <Box>
            <HrTextInput
                name='Topic'
                label='Topic'
                onChange={formik.handleChange}
                value={formik.values.Topic}
                errors={formik.errors.Topic}
                fullWidth
            />
        </Box>
        <Grid container justifyContent="space-between">
            <HighRidgeColorButton
                type="button"
                onClick={formik.submitForm}
            >Save Item</HighRidgeColorButton>
            {
                onCancel &&
                <Button
                    color="secondary"
                    type="button"
                    onClick={onCancel}
                >
                    Cancel
                </Button>

            }
        </Grid>
    </React.Fragment>);
}