import { observer } from "mobx-react-lite";
import React from "react";
import { Box } from "@mui/material";
import { NavLink } from "react-router-dom";
import { Colors } from "../../constants/colors";
import { useStore } from "../../stores/StoreContext";

interface Props {
    title: string;
    onClick? : React.MouseEventHandler<HTMLDivElement>;
}

interface INavButtonProtectedProps {
    roleLevel: number;
    title: string;
}

export const NavButtonProtected: React.FC<INavButtonProtectedProps> = observer(({ title }) => {
    const { userStore } = useStore();
    const admin = userStore?.appUser?.roles?.some(e => e.name === "BidBook Admin");
    if (admin === true)
    {
        return <NavButton
            title={title}
        />;
    }
    else
    {
        return <></>;
    }
});

const NavButton: React.FC<Props> = (props: Props) => {
    const path = `/${props.title.replace(' ', '')}`;
    return (
        <Box 
            sx={{ width: "100%",marginTop: 0 }} 
            onClick={props.onClick}>
            <NavLink to={path} style={({ isActive }) => ({
                    textDecoration : "none",
                    width: "100%",
                    lineHeight: 1,
                    borderWidth: 0,
                    fontSize: 14,
                    fontFamily: "Montserrat",
                    justifyContent: "flex-start",
                    padding:"14px 24px",
                    boxSizing:"border-box",
                    textAlign:"left",
                    backgroundColor: isActive ? Colors.darkSeaGreen : Colors.deepSeaGreen,
                    color: isActive ? Colors.goldLighter : Colors.white,
                    display:"inline-block",
                })}
            >
                {props.title}{" "}
            </NavLink>
        </Box>
    );
};

export default NavButton;