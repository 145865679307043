import { gql } from "@apollo/client/core";

export interface GetQuoteCommentsQueryResult {
  BidComment: BidComment[];
}

interface BidComment {
  Id: string;
  Comment: string;
  CreateDate: Date;
  CreateUser: AppUser;
}

interface AppUser {
  Id: string;
  FirstName: string;
  LastName: string;
}

export const GET_QUOTE_COMMENTS_QUERY = gql`
  query GetQuoteComments($Id: uniqueidentifier = "") {
    BidComment(where: {BidQuoteId: {_eq: $Id}}) {
      Id
      Comment
      CreateDate
      CreateUser {
          Id
          FirstName
          LastName
      }
    }
  }
`;

export interface GetQuoteReportCommentsQueryResult {
  BidQuotes_by_pk: BidQuote;
}

interface BidQuote {
  Id: string;
  SpecialInstructions: string;
  SurveyComments: string;
  InspectionComments: string;
  TechnicianComments: string;
  ConstructionComments: string;
}
export const GET_QUOTE_REPORT_COMMENTS_QUERY = gql`
  query GetQuoteHeaderComments($Id: uniqueidentifier = "") {
    BidQuotes_by_pk(Id: $Id) {
      Id
      SpecialInstructions
      SurveyComments
      InspectionComments
      TechnicianComments
      ConstructionComments
    }
  }
`;