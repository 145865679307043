import { Grid } from "@mui/material";
import { KickoffPacketListInput } from "./KickoffPacketListInput";

interface SpecificSafetyFormProps{
    id?: string;
    handleSubmit: (newItem: string) => void;
}

export const SpecificSafetyForm: React.FC<SpecificSafetyFormProps> = ({ handleSubmit }: SpecificSafetyFormProps) => {
    return(
        <Grid item xs={12}>
            <KickoffPacketListInput handleSubmit={handleSubmit}/>
        </Grid>
    ) 
}