import { useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import { useParams } from "react-router";
import { BidTimeLineEvent } from "../../../api/graphqlqueries/getbidtimelineevents";
import { GET_LAST_MODIFIED } from "../../../api/graphqlqueries/getlastmodified";
import { GET_QUOTE_REVISION_DETAILS_QUERY, QuoteRevisionDetails } from "../../../api/graphqlqueries/getquoterevisiondetails";
import HrTextInput from "../../atoms/HrTextInput";
import { StyledHeader } from "../../atoms/StyledHeader";


export const QuoteSummaryUpdateDetails: React.FC = () => {
    const params = useParams<{ id: string }>();
    const { data: revisionData, error: revisionError } = useQuery<QuoteRevisionDetails>(GET_QUOTE_REVISION_DETAILS_QUERY, {
        variables: {
            BidQuoteId: params.id
        },
        fetchPolicy: 'cache-and-network',
    });
    const { data: lastModifiedData, error: lastModifiedError } = useQuery<BidTimeLineEvent>(GET_LAST_MODIFIED, {
        variables: {
            BidQuoteId: params.id
        },
        fetchPolicy: 'cache-and-network',
    });

    const internalRevision = revisionData?.BidQuoteInternalRevisions[0]?.InternalRevision ?? 0;
    const externalRevision = revisionData?.BidQuoteExternalRevisions[0]?.ExternalRevision ?? 0;
    const lastStatusTime = lastModifiedData?.BidQuoteStatus[0]?.CreateDate ? new Date(lastModifiedData?.BidQuoteStatus[0]?.CreateDate ?? "").getTime() : 0;
    const lastCommentTime = lastModifiedData?.BidComment[0]?.CreateDate ? new Date(lastModifiedData?.BidComment[0]?.CreateDate ?? "").getTime() : 0;
    const lastModified = lastStatusTime > lastCommentTime ? lastModifiedData?.BidQuoteStatus[0] : lastModifiedData?.BidComment[0];
    if (revisionError) {
        console.error(revisionError);
    }

    if (lastModifiedError) {
        console.error(lastModifiedError)
    }
    return (
        <Grid container direction="row" spacing={1}>
            <Grid container item xs={6} spacing={1}>
                <Grid item xs={12}>
                    <StyledHeader>Revisions</StyledHeader>
                </Grid>
                <Grid item xs={6}>
                    <HrTextInput
                        label='Bid Revision'
                        value={internalRevision}
                        InputLabelProps={{ shrink: true }}
                        disabled
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <HrTextInput
                        label='Quote Revision'
                        value={externalRevision}
                        InputLabelProps={{ shrink: true }}
                        disabled
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid container item xs={6} spacing={1}>
                <Grid item xs={12}>
                    <StyledHeader>Last Modified</StyledHeader>
                </Grid>
                <Grid item xs={6}>
                    <HrTextInput
                        label='Date'
                        value={new Date(lastModified?.CreateDate ?? "").toLocaleDateString("en-us")}
                        InputLabelProps={{ shrink: true }}
                        disabled
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <HrTextInput
                        label='By'
                        value={`${lastModified?.User?.FirstName} ${lastModified?.User?.LastName}`}
                        InputLabelProps={{ shrink: true }}
                        disabled
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}