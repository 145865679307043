import { ApolloClient, InMemoryCache, createHttpLink, defaultDataIdFromObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

export const createApolloClient = () => {
  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      dataIdFromObject(resp) {
        if (resp.__typename?.includes('aggregate') || resp.__typename?.includes('sum') || resp.__typename?.includes('mutation_response') || resp.__typename?.includes("AppUserJobs") || resp.__typename?.includes("AppUserRole")) {
          return defaultDataIdFromObject(resp);
        }
        return `${resp.__typename}:${resp.Id}`
      }
    }),
  });
};

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('jwt');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
})