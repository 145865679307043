import { Grid, Divider } from "@mui/material"
import HrTextArea from "../../../../atoms/HrTextArea";
import { HrRadioGroup } from "../../../../atoms/HrRadioGroup";
import { HrRadioButton } from "../../../../atoms/HrRadioButton";
import { useFormik } from "formik";
import { gql, useMutation } from "@apollo/client";
import * as yup from "yup";
import { useCallback } from "react";
import debounce from "../../../../../utils/debounce";

interface CompletionReportingFormProps {
    completionReporting: CompletionReportingSection;
}

interface CompletionReportingSection {
    Id: string;
    CleanSVYFiles: boolean;
    CurrentSourceLog: boolean;
    SurveyReport: boolean;
    WaveformReport: boolean;
    WorkPermits: boolean;
    CompletionReportingNotes: string;
}

const UPDATE_KICKOFF_COMPLETION_REPORTING_MUTATION = gql`
    mutation UpdateKickoffCompletionReporting($Id: uniqueidentifier = "", $_set: KickoffPacket_set_input = {}) {
        update_KickoffPacket_by_pk(pk_columns: {Id: $Id}, _set: $_set) {
            Id
            CleanSVYFiles
            CurrentSourceLog
            SurveyReport
            WaveformReport
            WorkPermits
            CompletionReportingNotes
            __typename
        }
    }
`;

const kickoffCompletionReportingSchema = yup.object({
    CleanSVYFiles: yup.boolean().nullable(),
    CurrentSourceLog: yup.boolean().nullable(),
    SurveyReport: yup.boolean().nullable(),
    WaveformReport: yup.boolean().nullable(),
    WorkPermits: yup.boolean().nullable(),
    CompletionReportingNotes: yup.string().nullable()
});


export const CompletionReportingForm: React.FC<CompletionReportingFormProps> = ({ completionReporting }: CompletionReportingFormProps) => {

    const [updateKickoffCompletionReporting] = useMutation(UPDATE_KICKOFF_COMPLETION_REPORTING_MUTATION);
    const formik = useFormik({
        initialValues: {
            CleanSVYFiles: completionReporting.CleanSVYFiles,
            CurrentSourceLog: completionReporting.CurrentSourceLog,
            SurveyReport: completionReporting.SurveyReport,
            WaveformReport: completionReporting.WaveformReport,
            WorkPermits: completionReporting.WorkPermits,
            CompletionReportingNotes: completionReporting.CompletionReportingNotes
        },
        validationSchema: kickoffCompletionReportingSchema,
        onSubmit: (values) => {

        }
    });

    const updateData = (set: any) => {
        const castSet = kickoffCompletionReportingSchema.cast(set);
        updateKickoffCompletionReporting({
            variables: {
                Id: completionReporting.Id,
                _set: castSet
            }
        });
    };

    const debouncedUpdateData = useCallback(debounce((values:any) => updateData(values), 500), []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const set = {
            ...formik.values,
            [event.target.name]: event.target.value
        };
        debouncedUpdateData(set);
        formik.handleChange(event);
    };

    const { CleanSVYFiles, CurrentSourceLog, SurveyReport, WaveformReport, WorkPermits, CompletionReportingNotes } = formik.values;
    return (
        <Grid item xs={12} container alignItems="center">

            <HrRadioGroup
                name="CleanSVYFiles"
                value={CleanSVYFiles}
                onChange={handleChange}
                label="Clean SVY Files"
            >
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Divider sx={{ width: "100%" }} />

            <HrRadioGroup
                name="CurrentSourceLog"
                value={CurrentSourceLog}
                onChange={handleChange}
                label="Current Source Log"
            >
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Divider sx={{ width: "100%" }} />

            <HrRadioGroup name="SurveyReport" label="Survey Report" value={SurveyReport} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Divider sx={{ width: "100%" }} />

            <HrRadioGroup name="WaveformReport" label="Waveform Report" value={WaveformReport} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Divider sx={{ width: "100%" }} />

            <HrRadioGroup name="WorkPermits" label="Work Permits" value={WorkPermits} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Grid item xs={12} sx={{ mt: 1 }}>
                <HrTextArea
                    label="Notes"
                    name="CompletionReportingNotes"
                    value={CompletionReportingNotes}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    minRows={3}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'rgba(0, 0, 0, 0.12)'
                            }
                        }
                    }}
                />
            </Grid>
        </Grid>
    )
}