import { useMutation, useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import { useParams } from 'react-router';
import { GetMaterialServiceQueryResults, GET_MATERIAL_SERVICE_QUERY } from "../../../../api/graphqlqueries/getmaterialservicequery";
import { GetQuoteStatus, GET_QUOTE_STATUS_QUERY } from "../../../../api/graphqlqueries/getstatus";
import { UPDATE_MATERIAL_ITEM_MUTATION } from "../../../../api/graphqlqueries/materialserviceitemmutations";
import LineItemLoading from '../../../molecules/LineItemLoading';
import { TypeBox } from "../../../molecules/TypeBox";
import { QuoteMaterialRateForm } from "./BidbookQuoteMaterialRateForm";

interface MaterialItemProps {
    bidItemId?: string;
    id?: string;
    type: string;
    onSave?: () => void;
}


export const QuoteMaterialRate = ({ id, bidItemId, onSave, type }: MaterialItemProps) => {


    const { data, error } = useQuery<GetMaterialServiceQueryResults>(GET_MATERIAL_SERVICE_QUERY, {
        variables: {
            Id: id
        }
    });
    const [updateMaterialItem] = useMutation(UPDATE_MATERIAL_ITEM_MUTATION);
    const updateNewMaterialItem = (values: any) => {
        
        updateMaterialItem({ variables: { Id: values.Id, object: values}});
    }
    const params = useParams<{ id: string }>();

    const { data: statusData, error: status } = useQuery<GetQuoteStatus>(GET_QUOTE_STATUS_QUERY, {
        variables: {
            BidQuoteId: params.id!
        },
        fetchPolicy: 'cache-and-network'
    });

    if (error) {
        console.error(error);
    }
    if (!data) return <LineItemLoading sx={{backgroundColor:"rgba(0,0,0,0.2)"}}/>;
    const quoteItem = data.BidQuoteMaterials[0];
    const styles = {
        materialItemGrid : {
            paddingLeft:"1.5em",
            flexWrap:"nowrap !important", 
            marginTop:"2px", 
        }
    }
    return (
        <Grid item container sx={styles.materialItemGrid}>
            <TypeBox type={type}/>
            <QuoteMaterialRateForm type={type} item={quoteItem} onSave={updateNewMaterialItem} status={statusData?.BidQuoteStatus[0].Status ?? ""} />
        </Grid>     
    )
    
};