import { useQuery, gql, useMutation } from "@apollo/client";
import { KickoffPacketSection } from "./KickoffPacketSection";
import { ProjectExecutionForm } from "./ProjectExecutionForm";
import { addMessage } from "../../../../stores/ReactiveVariables";

interface ProjectExecutionProps{
    id?: string;
}

const GET_KICKOFF_PACKET_SEGMENT_DATA_QUERY = gql`
    query GetKickoffPacketSegmentData($KickoffPacketId: uniqueidentifier = "") {
        JobSegments(where: {KickoffPacketId: {_eq: $KickoffPacketId}}) {
            Id
            BidQuoteItem {
                Id
                Mob
                MobWoPD
                Setup
                Miles
                AverageMiles
                BidQuoteConstructionServices {
                    Id
                    BidQuoteItemId
                    Days
                    Mob
                    MobWoPD
                    Setup
                }
            }
        }
    }
`
const GET_KICKOFF_DAILY_PRODUCTION_QUERY = gql`
query GetKickoffPacketExecutionData($Id: uniqueidentifier = "") {
    KickoffPacket(where: {Id: {_eq: $Id}}) {
      Id
      Mob
      Setup
      ExpectedDailyProduction
      WorkDays
    }
  }
`;

const UPDATE_KICKOFF_PROJECT_EXECUTION_MUTATION = gql`
mutation UpdateKickoffProjectExecution($Id: uniqueidentifier = "", $object: KickoffPacket_set_input = {}) {
    update_KickoffPacket(where: {Id: {_eq: $Id}}, _set: $object) {
      affected_rows
    }
  }  
`;

export const ProjectExecution: React.FC<ProjectExecutionProps> = ({ id }: ProjectExecutionProps) => {

    const { data: segmentData, error } = useQuery(GET_KICKOFF_PACKET_SEGMENT_DATA_QUERY, {
        variables: {
            KickoffPacketId: id
        }
    });

    const { data: kickoffExecutionData } = useQuery(GET_KICKOFF_DAILY_PRODUCTION_QUERY, {
        variables: {
            Id: id
        }
    });

    const [updateKickoffProjectExecution] = useMutation(UPDATE_KICKOFF_PROJECT_EXECUTION_MUTATION);

    const handleSave = (object: any) => {
        updateKickoffProjectExecution({
            variables: {
                Id: id,
                object: object
            },
            onCompleted: () => {
                addMessage("Project Execution Saved", { severity: "success" });
            }
        });
    }

    if (error) console.log(error);

    return(
        <KickoffPacketSection heading="Project Execution">
            <ProjectExecutionForm
                id={id}
                formData={kickoffExecutionData?.KickoffPacket[0] ?? []}
                segmentData={segmentData?.JobSegments ?? []}
                onSave={handleSave}
            />
        </KickoffPacketSection>
    ) 
}