import { useQuery } from '@apollo/client';
import { useFormik } from "formik";
import { useParams } from 'react-router';
import { v4 } from 'uuid';
import * as yup from 'yup';
import { GET_QUOTE_FACTORS_QUERY } from '../../../../api/graphqlqueries/quotefactorsquery';
import { QuoteFactors } from "../../../../utils/calculations/adders";
import { BidQuoteItem, getSegmentCalculations, SegmentCalculations } from "../../../../utils/calculations/segments";
import { DivisionCalculations } from "../../../../utils/calculations/divisions";
import HrTextInput from "../../../atoms/HrTextInput";
import { QuoteLineItemFormGrid } from "../../../atoms/QuoteLineItemFormGrid";

interface QuoteRateItem {
    Id?: string;
    Type: string;
    Segment?: string;
    Location?: number;
    Area?: string;
    BidQuoteConstructionServices?: any;
}

interface QuoteRateFormProps {
    item?: QuoteRateItem;
    onSave: (item: QuoteRateItem) => void,
    total?: DivisionCalculations,
    type: string
}

const quoteRateItemSchema = yup.object().shape({
    Segment: yup.string().required(),
    Location: yup.number(),
    Area: yup.string(),
});

export const QuoteRateForm = ({ item: quoteItem, onSave, type, total }: QuoteRateFormProps) => {
    const Id = quoteItem?.Id ?? v4();
    const params = useParams<{ id: string }>();
    const formik = useFormik({
        validationSchema: quoteRateItemSchema,
        initialValues: {
            Segment: quoteItem?.Segment ?? '',
            Location: quoteItem?.Location ?? 0,
            Area: quoteItem?.Area ?? '',
        },
        onSubmit: (values) => {
            const data = quoteRateItemSchema.cast(values);
            onSave({ Id, Type: 'Construction', ...data });
            formik.setSubmitting(false);
        }
    });

    // eslint-disable-next-line
    const { data: factorData } = useQuery(GET_QUOTE_FACTORS_QUERY, {
        variables: {
            Id: params.id
        }
    });

    const quoteFactors = factorData?.BidQuotes_by_pk;
    const segmentCalculations: SegmentCalculations = getSegmentCalculations(quoteItem as BidQuoteItem, quoteFactors as QuoteFactors);

    const width = {
        small: "8ch",
        medium: "12ch",
        large: "18ch",
        xl: "24ch",
    }

    return (
        <QuoteLineItemFormGrid>
            <HrTextInput
                name='Segment'
                label='Segment'
                errors={formik.errors.Segment}
                value={formik.values.Segment}
                width={width.large}
                disabled={true}
            />

            <HrTextInput
                name='Area'
                label='Area'
                width={width.medium}
                errors={formik.errors.Area}
                value={formik.values.Area}
                disabled={true}
            />
            <HrTextInput
                name='Location'
                label='Locations'
                width={width.small}
                errors={formik.errors.Location}
                value={segmentCalculations.Locations}
                disabled={true}
            />
        </QuoteLineItemFormGrid>
    );
}