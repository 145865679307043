import { Punch } from './punch';
import { Customer } from './customer';
import { Segment } from './segment';

export interface Crew {
    id: string;
    name: string;
    appUsers: AppUser[];
}

export interface Job {
    id: string;
    name: string;
    location: string;
    reqDistanceCompleted: boolean;
    reqUnitsCompleted: boolean;
    distanceCompleted: number;
    unitsCompleted: number;
    reqPerDiem: boolean;
    reqSegments: boolean;
    customer: Customer;
    projectManagerId: string;
    segments: Segment[];
    crewIds?: string[];
    appUsers?: AppUser[] | undefined;
    city: string;
    state: string;
    status: string;
    zip: string;
    isMileageBillable: boolean;
    expectedMiles: number;
    expectedUnits: number;
    quoteId: string;
    projectTypes: any[];
}

export const JobStatuses = [
    "Active",
    "Complete",
    "Archived",
    "Planning"
];

export interface AppUser {
    id?: string;
    firstName?: string;
    lastName?: string;
    userName?: string;
    email?: string;
    crews?: Crew[];
    insuranceExpDate?: Date;
    driversLicense?: string;
    driversLicenseExpDate?: Date;
    jobs?: Job[];
    token?: string;
    isActive?: boolean;
    punches?: Punch[];
    quickbooksName?: string;
    hourlyPayRate?: number;
    perDiemRate?: number;
    roles?: Role[];
}

interface Role {
    id: string;
    name: string;
    accessLevel: number;
}

export interface UserFormValues {
    firstName: string | undefined;
    lastName: string | undefined;
    password: string | undefined;
    email: string | undefined;
    quickbooksName: string | undefined;
}
export interface UpdateUserFormValues {
    firstName: string | undefined;
    lastName: string | undefined;
    email: string | undefined;
    quickbooksName: string | undefined;
}
export interface UserLoginFormValues {
    email: string;
    password: string;
}
