import axios, { AxiosResponse } from 'axios';
import {
    AppUser,
    UpdateUserFormValues,
    UserFormValues,
    UserLoginFormValues,
} from '../models/appUser';
import { Billable } from '../models/billable';
import { CrewDto } from '../Dtos/crewDto';
import { Customer } from '../models/customer';
import { Job } from '../models/job';
import { ResetPassword } from '../models/resetPassword';
import { Media } from '../models/media';
// Production
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// Local Dev
//axios.defaults.baseURL = 'http://localhost:5000/api';

const responseBody = <T>(response: AxiosResponse<T>) => response.data; //comment
const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) =>
        axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) =>
        axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Crews = {
    list: () => requests.get<any[]>('Crew/crews'),
    details: (id: string) => requests.get<CrewDto>(`/Crew/${id}`),
    create: (crew: CrewDto) => requests.post('/Crew', crew),
    update: (crew: CrewDto, id: string) => requests.put(`/Crew/${id}`, crew),
    delete: (id: string) => requests.del(`/Crew/${id}`),
};

const Customers = {
    list: () => requests.get<Customer[]>('Customers/customers'),
    details: (id: string) => requests.get<Customer>(`/Customer/${id}`),
    create: (customer: Customer) =>
        requests.post<Customer>('Customers', customer),
    update: (customer: Customer) =>
        requests.put(`/Customers/${customer.id}`, customer),
    delete: (id: string) => requests.del(`/Customers/${id}`),
};

const Jobs = {
    list: () => requests.get<Job[]>('Jobs/all'),
    details: (id: string) => requests.get<Job>(`/Jobs/${id}`),
    create: (job: Job) => requests.post('/Jobs/Create', job),
    update: (job: Job) => requests.put(`/Jobs/update/${job.id}`, job),
    delete: (id: string) => requests.del(`/Jobs/${id}`),
};

const Billables = {
    list: () => requests.get<Billable[]>('Billables/billables'),
    details: (id: string) => requests.get<Billable>(`/Billables/${id}`),
    create: (billable: Billable) => requests.post('/Billables/new', billable),
    update: (billable: Billable) =>
        requests.put(`/Billables/${billable.id}`, billable),
    delete: (id: string) => requests.del(`/Billables/${id}`),
};

const AppUsers = {
    list: () => requests.get<AppUser[]>('/account/accounts'),
    current: () => requests.get<AppUser>('/account'),
    login: (user: UserLoginFormValues) =>
        requests.post<AppUser>('/account/login', user),
    register: (user: UserFormValues) =>
        requests.post<AppUser>('/account/bidbook-register', user),
    update: (user: UpdateUserFormValues, id: string) =>
        requests.put<AppUser>(`/account/${id}`, user),
    changeIsActive: (id: string) =>
        requests.post<AppUser>(`/account/toggleactive/${id}`, {}),
    resetPassword: (id: string, resetPasswordObj: ResetPassword) =>
        requests.post(`/account/resetPassword/${id}`, resetPasswordObj),
};

const Attachments = {
    post: (type: string, itemId: string, files: FormData) => axios({
        method: 'POST',
        url: `/attachment/${type}/${itemId}`,
        data: files,
        headers: { "Content-Type": "multipart/form-data" }
    }),
    get: (id: string, fileName: string) => axios({
        url: `/attachment/${id}`, //your url
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        if (link.parentNode) {
            link.parentNode.removeChild(link);
        }
    })
}

const Logo = {
    post: (companyId: string, files: FormData) => axios({
        method: 'POST',
        url: `/logo/${companyId}`,
        data: files,
        headers: { "Content-Type": "multipart/form-data" }
    }),
    get: (companyId: string) => axios({
        url: `/logo/${companyId}`, //your url
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        return response.data;
    })
}

const Reports = {
    jobcost: (startDate: string, endDate: string, jobId: string) =>
        requests.get(`/report/jobcost?startdate=${encodeURI(startDate)}&enddate=${encodeURI(endDate)}&jobid=${jobId}`)
}


export class Agent {
    public AppUsers = AppUsers;
    public Attachments = Attachments;
    public Crews = Crews;
    public Customers = Customers;
    public Jobs = Jobs;
    public Billables = Billables;
    public Reports = Reports;
    public Logo = Logo;
    public setStore = (store: any) => {
        axios.interceptors.request.use((config: any) => {
            const token = store.commonStore.token;
            if (token) config.headers.Authorization = `Bearer ${token}`;
            return config;
        });
    }
}

export default Agent;
