import { gql, useApolloClient, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { Add } from "@mui/icons-material";
import { Box, Button, CircularProgress, Dialog, DialogContent } from "@mui/material";
import { GridColDef, GridToolbarContainer } from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import { CREATE_BIDQUOTE_RATE_MUTATION, DELETE_BIDQUOTE_RATE_MUTATION, GET_BIDQUOTE_RATES_QUERY, UPDATE_BIDQUOTE_RATE_MUTATION } from "../../../../api/graphqlqueries/bidquoterates";
import { appUserVar, resetMessage } from "../../../../stores/ReactiveVariables";
import dollarUS from '../../../../utils/CurrencyFormat';
import { FormGrid } from "../../../atoms/FormGrid";
import RemoveButton from "../../../atoms/RemoveButton";
import { HrDataGrid } from "../../../molecules/HrDataGrid";
import { ServicesForm } from "../../Billables/ServicesForm";
import { addMessage } from "../../../../stores/ReactiveVariables";
import HrCheckBox from "../../../atoms/HrCheckBox";

const UPDATE_SERVICE_RATE_ADDITIONAL_ITEM = gql`
mutation UpdateBidQuoteRatesAdditionalItem($Id: uniqueidentifier = "", $ReportAdditionalItem: Boolean = false) {
    update_BidQuoteRates_by_pk(pk_columns: {Id: $Id}, _set: {ReportAdditionalItem: $ReportAdditionalItem}) {
        Id
    }
}  
`;

export const BidbookServiceRates: React.FC<{ id: string}> = ({ id }) => {
    const user = useReactiveVar(appUserVar);
    const [selectedService, setSelectedService] = useState('');
    const [checkboxLoading, setCheckboxLoading] = useState<string[]>([]);
    const { data, loading, error } = useQuery(GET_BIDQUOTE_RATES_QUERY, { variables: { BidQuoteId: id }});
    const [createService] = useMutation(CREATE_BIDQUOTE_RATE_MUTATION);
    const [updateService] = useMutation(UPDATE_BIDQUOTE_RATE_MUTATION);
    const [deleteService] = useMutation(DELETE_BIDQUOTE_RATE_MUTATION);
    const [updateAdditionalItem] = useMutation(UPDATE_SERVICE_RATE_ADDITIONAL_ITEM);
    const client = useApolloClient();
    const deleteServiceHandler = (Id: string) => {
        deleteService({
            variables: {
                Id
            },
            optimisticResponse: {
                delete_BidQuoteRates_by_pk: {
                    Id,
                    __typename: "BidQuoteRates"
                }
            },
            update(cache, { data }) {
                cache.evict({
                    id: `BidQuoteRates:${Id}`
                });
                cache.gc();
            },
            onError(error) {
                console.error(error);
                client.refetchQueries({
                    include: "active"
                });
                addMessage('Error Deleting Service', { severity: 'success' });
            },
            onCompleted: (data) => {
                if (data)
                    addMessage('Service Deleted', { severity: 'success' });
            }
        });
    }

    const handleSave = (object: any) => {
        object.BidQuoteId = id;
        object.LastUpdated = new Date();
        object.ReportAdditionalItem = false;
        if (selectedService === 'new') {
            createService({
                variables: {
                    object
                },
                optimisticResponse: {
                    insert_BidQuoteRates_one: {
                        __typename: 'BidQuoteRates',
                        ...object
                    }
                },
                update: (cache, { data }) => {
                    cache.updateQuery({
                        query: GET_BIDQUOTE_RATES_QUERY,
                        variables: { BidQuoteId: id }
                    },
                    (cacheData: any) => ({
                        BidQuoteRates: [...cacheData.BidQuoteRates, data.insert_BidQuoteRates_one]
                    }))
                },
                onCompleted: (data) => {
                    if (data)
                        addMessage('Service Created', { severity: 'success' });
                }
            })
        } else {
            updateService({
                variables: {
                    Id: object.Id,
                    object
                },
                optimisticResponse: {
                    update_BidQuoteRates_by_pk: {
                        __typename: 'BidQuoteRates',
                        ...object
                    }
                },
                onCompleted: (data) => {
                    if (data)
                        addMessage('Service Updated', { severity: 'success' });
                }
            });
        }
        setSelectedService('');
    }

    const handleAdditionalItemCheckbox = (e: any) => {
        const rowId = e.target.name;
        resetMessage();
        setCheckboxLoading([...checkboxLoading, rowId]);
        updateAdditionalItem({
            variables: {
                Id: rowId.toLowerCase(),
                ReportAdditionalItem: e.target.checked
            },
            refetchQueries: [GET_BIDQUOTE_RATES_QUERY],
            awaitRefetchQueries: true,
            onCompleted: () => {
                setCheckboxLoading(checkboxLoading.filter(id => rowId === id));
                addMessage("Service Item Added to Additional Items", { severity: 'success' });
            }
        })
    }

    const rows = data?.BidQuoteRates ?? [];
    const selectedServiceObject = rows.find((i: any) => i.Id === selectedService);
    const columns: GridColDef[] = [
        {
            field: "removeService",
            headerName: " ",
            width: 50,
            renderCell: (rows) => {
                const billableId = rows.id;
                return (
                    <RemoveButton
                        title="Billables"
                        onClick={() => deleteServiceHandler(billableId as string)}
                    />
                );
            },
            headerClassName: "billables-grid-header",
        },
        // {
        //     field: "id",
        //     headerName: "ID",
        //     width: 100,
        //     headerClassName: "billables-grid-header",
        // },
        {
            field: "Name",
            headerName: "Service Item",
            width: 250,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "ReportAdditionalItem",
            headerName: "Add'l Item",
            width: 120,
            editable: false,
            headerClassName: "billables-grid-header",
            headerAlign: 'center',
            align: 'center',
            description: 'Include Item in Report Additional Rates Section', 
            renderCell: (rows) => {
                if(checkboxLoading.includes(rows.id as string)){
                    return <CircularProgress size={25}/>;
                } else {
                    return <HrCheckBox
                                name={`${rows.id}`}
                                checked={rows.value}
                                onChange={handleAdditionalItemCheckbox}
                                sx={{
                                    margin:0
                                }}
                            />
                }
            },
        },
        {
            field: "ServiceType",
            headerName: "Service Type",
            width: 120,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "DailyHours",
            headerName: "Hours per Day",
            width: 150,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "Rate",
            headerName: "Rate",
            width: 150,
            editable: false,
            headerClassName: "billables-grid-header",
            valueFormatter: (params) => dollarUS.format(params.value)
        }, 
        {
            field: "Cost",
            headerName: "Cost",
            width: 150,
            editable: false,
            headerClassName: "billables-grid-header",
            valueFormatter: (params) => dollarUS.format(params.value)
        }, 
    ] 

    if (error) {
        console.error(error);
    }
    return (
        <Box sx={{ height: '70vh' }}>
            <HrDataGrid
                loading={loading}
                rows={rows}
                columns={columns}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                disableRowSelectionOnClick
                onRowDoubleClick={(model, details) => {
                    setSelectedService(model.id as string);
                }}
                components={{
                    Toolbar: () => <GridToolbarContainer>
                    <Button color="primary" startIcon={<Add />} onClick={() => setSelectedService('new')}>
                      Add Service
                    </Button>
                  </GridToolbarContainer>
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            removeService: user?.roles?.some(e => e.name === "BidBook Admin") ?? false
                        }
                    }
                }}
            />
                <Dialog
                    open={selectedService !== ''}
                    onClose={() => setSelectedService('')}
                >
                    <DialogContent>

                        <Button
                            onClick={() => {
                                setSelectedService('');
                            }}
                            variant="contained"
                            color="primary"
                            className="modal-exit"
                        >
                            x
                        </Button>
                        <FormGrid>
                            <ServicesForm item={selectedServiceObject} bidQuoteId={id} onSave={handleSave} onCancel={() => setSelectedService('')} forBidService />
                        </FormGrid>
                    </DialogContent>
                </Dialog>
        </Box>
    );
}