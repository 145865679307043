import { gql } from "@apollo/client/core";
import { BidQuoteBillable } from "./bidquotebillables";
import { BidQuoteRate } from "./bidquoterates";

export interface GetConstructionItemQueryResult {
  BidQuoteItems_by_pk: BidQuoteItem;
}

interface BidQuoteItem {
  Area: string;
  Id:string;
  BidQuoteConstructionServices: BidQuoteConstructionService[];
  BidQuoteMaterials: BidQuoteMaterial[];
  Location: number;
  Type: string;
  Segment: string;
  Ordinal: number;
  LineBreakAfter: boolean;
}

interface BidQuoteConstructionService {
  Average: number;
  Days: number;
  Discount: number;
  BidQuoteItemId: string;
  BidQuoteRate: BidQuoteRate;
  BidQuoteRateId: string;
  Equipment: number;
  Locations: number;
  Id: string;
  Mileage: number;
  Mob: number;
  MobWoPD: number;
  PerDiem: boolean;
  Personnel: number;
  Rate: number;
  OverrideRate: boolean;
  Rest: number;
  Setup: number;
  Unit: string;
  Ordinal: number;
  LineBreakAfter: boolean;
}

interface BidQuoteMaterial {
  Id: string;
  BidQuoteItemId: string;
  BidQuoteId: string;
  BidQuoteBillable: BidQuoteBillable;
  BidQuoteBillableId: string;
  Description: string;
  Rate: number;
  OverrideRate: boolean;
  Markup: number;
  Quantity: number;
  Unit: string;
  Type: string;
  Duration: number;
  Ordinal: number;
  LineBreakAfter: boolean;
}

export const GET_CONSTRUCTION_ITEM_QUERY = gql`
  query GetConstructionItem($Id: uniqueidentifier = "") {
    BidQuoteItems_by_pk(Id: $Id) {
      Area
      Id
      BidQuoteConstructionServices( order_by: { Ordinal: asc }) {
        Average
        Days
        Discount
        BidQuoteItemId
        BidQuoteRate {
          Id
          Name
          Rate
          ServiceType
        }
        BidQuoteRateId
        Equipment
        Locations
        Id
        Mileage
        Mob
        MobWoPD
        PerDiem
        Personnel
        Rate
        OverrideRate
        Rest
        Setup
        Unit
        Ordinal
        LineBreakAfter
      }
      BidQuoteMaterials( order_by: { Ordinal: asc }) {
        Id
        BidQuoteItemId
        BidQuoteId
        BidQuoteBillable {
          Id
          Name
          Cost
        }
        Description
        Rate
        OverrideRate
        Markup
        Quantity
        Unit
        Type
        Duration
        Ordinal
        LineBreakAfter
      }
      Location
      Type
      Segment
      Ordinal
      LineBreakAfter
      ParentBidQuoteItemId
    }
  }
`;