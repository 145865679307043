import { IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { GeneralSafetyForm } from "./GeneralSafetyForm";
import { KickoffPacketSection } from "./KickoffPacketSection";
import { gql, useMutation, useQuery } from "@apollo/client";
import LineItemLoading from "../../../molecules/LineItemLoading";
import CloseIcon from '@mui/icons-material/Close';
import { v4 } from "uuid";

interface GeneralSafetyProps{
    id?: string;
}


interface GeneralSafetyTopic {
    GeneralSafetyTopics: string,
    Id: string,
    Ordinal: number,
    KickoffPacketId: string,
}

interface GetKickoffGeneralSafetyTopicsData {
    KickoffPacketGeneralSafetyTopics: GeneralSafetyTopic[];
}

interface AdminGeneralSafetyTopic {
    Id: string,
    Topic: string,
}

interface GetAdminGeneralSafetyTopicsData {
    AdminGeneralSafetyTopics: AdminGeneralSafetyTopic[];
}

const GET_KICKOFF_GENERAL_SAFETY_TOPICS_QUERY = gql`
  query GetKickoffGeneralSafetyTopics($KickoffId: uniqueidentifier = "") {
    KickoffPacketGeneralSafetyTopics(where: {KickoffPacketId: {_eq: $KickoffId}}, order_by: {Ordinal: asc}) {
      GeneralSafetyTopics
      Id
      KickoffPacketId
      Ordinal
    }
  }
`;

const GET_ADMIN_GENERAL_SAFETY_TOPICS_QUERY = gql`
  query GetAdminGeneralSafetyTopics {
    AdminGeneralSafetyTopics {
      Id
      Topic
    }
  }
`;

const ADD_KICKOFF_GENERAL_SAFETY_TOPICS_MUTATION = gql`
  mutation AddKickoffGeneralSafetyTopics($object: KickoffPacketGeneralSafetyTopics_insert_input = {}) {
    insert_KickoffPacketGeneralSafetyTopics_one(object: $object, if_matched: {match_columns: Id, update_columns: [GeneralSafetyTopics, Ordinal]}) {
      GeneralSafetyTopics
      Id
      KickoffPacketId
      Ordinal
    }
  }
`;

const DELETE_GENERAL_SAFETY_ITEM_MUTATION = gql`
  mutation DeleteGeneralSafetyItem($Id: uniqueidentifier = "", $KickoffId: uniqueidentifier = "", $Ordinal: Int = 0) {
    delete_KickoffPacketGeneralSafetyTopics_by_pk(Id: $Id) {
      Id
    }
    update_KickoffPacketGeneralSafetyTopics(where: {KickoffPacketId: {_eq: $KickoffId}, Ordinal: {_gt: $Ordinal}}, _inc: {Ordinal: -1}) {
      affected_rows
      returning {
        GeneralSafetyTopics
        Id
        KickoffPacketId
        Ordinal
      }
    }
  }
`;
    

export const GeneralSafety: React.FC<GeneralSafetyProps> = ({ id }: GeneralSafetyProps) => {

    const { data, error } = useQuery<GetKickoffGeneralSafetyTopicsData>(GET_KICKOFF_GENERAL_SAFETY_TOPICS_QUERY, { variables: { KickoffId: id } });
    const { data: adminData, error: adminError } = useQuery<GetAdminGeneralSafetyTopicsData>(GET_ADMIN_GENERAL_SAFETY_TOPICS_QUERY);
    const [addKickoffGeneralSafetyTopic] = useMutation(ADD_KICKOFF_GENERAL_SAFETY_TOPICS_MUTATION);
    const [deleteGeneralSafetyItem] = useMutation(DELETE_GENERAL_SAFETY_ITEM_MUTATION);
    
    if (error) console.log(error);
    if (adminError) console.log(adminError);

    if (!data || !adminData) return <KickoffPacketSection heading="General Safety Topics">
        <LineItemLoading />
    </KickoffPacketSection>;

    const handleSaveItem = (newItem: string) => {
        if (!newItem) return;
        const newId = v4();
        const newOrdinal = data?.KickoffPacketGeneralSafetyTopics.length || 0;
        addKickoffGeneralSafetyTopic({
            variables: {
                object: { KickoffPacketId: id, Ordinal: newOrdinal, GeneralSafetyTopics: newItem, Id: newId }
            },
            refetchQueries: [{ query: GET_KICKOFF_GENERAL_SAFETY_TOPICS_QUERY, variables: { KickoffId: id }}]
        });
    }

    const handleDeleteItem = (itemId: string, ordinal: number) => () => {
        deleteGeneralSafetyItem({
            variables: {
                Id: itemId,
                KickoffId: id,
                Ordinal: ordinal
            },
            refetchQueries: [{ query: GET_KICKOFF_GENERAL_SAFETY_TOPICS_QUERY, variables: { KickoffId: id }}]
        });
    }

    const generalSafetyTopics = data?.KickoffPacketGeneralSafetyTopics || [];
    const adminGeneralSafetyTopics = adminData?.AdminGeneralSafetyTopics || [];
    const filteredList = adminGeneralSafetyTopics.filter((item) => !generalSafetyTopics.find((topic) => topic.GeneralSafetyTopics === item.Topic))
        .map((item) => item.Topic);

    return(
        <KickoffPacketSection heading="General Safety Topics">
            <GeneralSafetyForm generalSafetyAdminList={filteredList} onAccept={handleSaveItem} />
            <List disablePadding>
                {
                    generalSafetyTopics.map((item) => {
                        return (
                            <ListItem
                                divider
                                dense
                                disableGutters
                                key={item.Id}
                            >
                                <ListItemButton>
                                    <ListItemText primary={item.GeneralSafetyTopics} />
                                    <ListItemIcon>
                                        <IconButton onClick={handleDeleteItem(item.Id, item.Ordinal)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </ListItemIcon>
                                </ListItemButton>
                            </ListItem>
                        )
                    })
                }
            </List>
        </KickoffPacketSection>
    ) 
}