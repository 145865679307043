import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/StoreContext";


export const Loading: React.FunctionComponent = observer(()=>{
    const { commonStore } = useStore();
    const { loading } = commonStore;
    return (
        <div>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      );
})