import { Autocomplete, Box, Modal } from '@mui/material';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';
import { useStore } from "../../../stores/StoreContext";
import { FormGrid } from '../../atoms/FormGrid';
import HrButton from '../../atoms/HrButton';
import HrTextInput from '../../atoms/HrTextInput';
import { StyledHeader } from "../../atoms/StyledHeader";
import { ClientPPE } from './ClientPPE';

const statesList = [

  {
    name: "Alabama",
    abbreviation: "AL"
  },
  {
    name: "Alaska",
    abbreviation: "AK"
  },
  {
    name: "American Samoa",
    abbreviation: "AS"
  },
  {
    name: "Arizona",
    abbreviation: "AZ"
  },
  {
    name: "Arkansas",
    abbreviation: "AR"
  },
  {
    name: "California",
    abbreviation: "CA"
  },
  {
    name: "Colorado",
    abbreviation: "CO"
  },
  {
    name: "Connecticut",
    abbreviation: "CT"
  },
  {
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC"
  },
  {
    name: "Federated States Of Micronesia",
    abbreviation: "FM"
  },
  {
    name: "Florida",
    abbreviation: "FL"
  },
  {
    name: "Georgia",
    abbreviation: "GA"
  },
  {
    name: "Guam",
    abbreviation: "GU"
  },
  {
    name: "Hawaii",
    abbreviation: "HI"
  },
  {
    name: "Idaho",
    abbreviation: "ID"
  },
  {
    name: "Illinois",
    abbreviation: "IL"
  },
  {
    name: "Indiana",
    abbreviation: "IN"
  },
  {
    name: "Iowa",
    abbreviation: "IA"
  },
  {
    name: "Kansas",
    abbreviation: "KS"
  },
  {
    name: "Kentucky",
    abbreviation: "KY"
  },
  {
    name: "Louisiana",
    abbreviation: "LA"
  },
  {
    name: "Maine",
    abbreviation: "ME"
  },
  {
    name: "Marshall Islands",
    abbreviation: "MH"
  },
  {
    name: "Maryland",
    abbreviation: "MD"
  },
  {
    name: "Massachusetts",
    abbreviation: "MA"
  },
  {
    name: "Michigan",
    abbreviation: "MI"
  },
  {
    name: "Minnesota",
    abbreviation: "MN"
  },
  {
    name: "Mississippi",
    abbreviation: "MS"
  },
  {
    name: "Missouri",
    abbreviation: "MO"
  },
  {
    name: "Montana",
    abbreviation: "MT"
  },
  {
    name: "Nebraska",
    abbreviation: "NE"
  },
  {
    name: "Nevada",
    abbreviation: "NV"
  },
  {
    name: "New Hampshire",
    abbreviation: "NH"
  },
  {
    name: "New Jersey",
    abbreviation: "NJ"
  },
  {
    name: "New Mexico",
    abbreviation: "NM"
  },
  {
    name: "New York",
    abbreviation: "NY"
  },
  {
    name: "North Carolina",
    abbreviation: "NC"
  },
  {
    name: "North Dakota",
    abbreviation: "ND"
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP"
  },
  {
    name: "Ohio",
    abbreviation: "OH"
  },
  {
    name: "Oklahoma",
    abbreviation: "OK"
  },
  {
    name: "Oregon",
    abbreviation: "OR"
  },
  {
    name: "Palau",
    abbreviation: "PW"
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA"
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR"
  },
  {
    name: "Rhode Island",
    abbreviation: "RI"
  },
  {
    name: "South Carolina",
    abbreviation: "SC"
  },
  {
    name: "South Dakota",
    abbreviation: "SD"
  },
  {
    name: "Tennessee",
    abbreviation: "TN"
  },
  {
    name: "Texas",
    abbreviation: "TX"
  },
  {
    name: "Utah",
    abbreviation: "UT"
  },
  {
    name: "Vermont",
    abbreviation: "VT"
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI"
  },
  {
    name: "Virginia",
    abbreviation: "VA"
  },
  {
    name: "Washington",
    abbreviation: "WA"
  },
  {
    name: "West Virginia",
    abbreviation: "WV"
  },
  {
    name: "Wisconsin",
    abbreviation: "WI"
  },
  {
    name: "Wyoming",
    abbreviation: "WY"
  }
]


interface Props {
  props?: any,
}


const validationSchema = yup.object().shape({
  name: yup.string().required('Client name is required'),
  address: yup.string().required('Client address is required'),
  city: yup.string().required('Client city is required'),
  state: yup.string().required('Client state is required'),
  zip: yup.number().required('Client zip is required'),
  phone: yup.number().required('Client phone number is required'),
  email: yup.string().email('Client email must be a valid email address').required('Client email is required'),
  primaryContact: yup.string().required('Client POC is required'),
  shortCode: yup.string().required('Client Short Code is required'),
})

export const ClientsForm: React.FC<Props> = observer((prop: Props, getPPE?) => {

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  
  function PPEModal() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <React.Fragment>
        <HrButton sx={{width: '100%'}} type='submit' onClick={handleOpen}>Manage Client PPE</HrButton>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style }}>
              <ClientPPE id={selectedClient?.id ?? ''} />
            {/* <HrButton sx={{ mb: '1rem', position: 'absolute', bottom: '0'}} onClick={handleClose}>Close PPE Modal</HrButton> */}
          </Box>
        </Modal>
      </React.Fragment>
    );
  }

  const { clientStore, modalStore, commonStore, notificationStore } = useStore();
  const { selectedClient, updateClient,
    createClient, setSelectedClient } = clientStore;
  const { closeClientsModal } = modalStore;
  const { displayNotification } = notificationStore;
  const { setLoading } = commonStore;

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      id: selectedClient?.id,
      name: selectedClient?.name,
      shortCode: selectedClient?.shortCode ?? "",
      address: selectedClient?.address,
      city: selectedClient?.city,
      state: selectedClient?.state,
      zip: selectedClient?.zip,
      phone: selectedClient?.phone,
      email: selectedClient?.email,
      primaryContact: selectedClient?.primaryContact,
	  isDeleted: false
    },
    onSubmit: (values: any) => {
      setLoading(true);
      if (values.id === undefined) {
        const newValues = {
          ...values,
          id: uuid()
        }
        createClient(newValues).then(() => {
          displayNotification("Client Created", "success");
          closeClientsModal();
        }).catch(err => {
          displayNotification("Error Creating Client", "error");
          closeClientsModal();
        }).finally(() => {
          setLoading(false)
        })
        setSelectedClient(undefined);
      } else {
        updateClient(values).then(() => {
          displayNotification("Client Updated", "success");
          closeClientsModal();
        }).catch(err => {
          displayNotification("Error Updating Client", "error");
          closeClientsModal();
        }).finally(() => {
          setLoading(false)
        })
        setSelectedClient(undefined);
      }
    }
  })
  return (
    <Box sx={{ width: '100%' }}>
      <StyledHeader sx={{textAlign:"center"}}>Edit Client</StyledHeader>
      <form className='ui form' onSubmit={formik.handleSubmit} >
        <FormGrid>
          <HrTextInput
            name='name'
            label='Name'
            onChange={formik.handleChange}
            value={formik.values.name}
            errors={formik.errors.name}
          />
          <HrTextInput
            name='shortCode'
            label='Short Code'
            onChange={formik.handleChange}
            value={formik.values.shortCode}
            errors={formik.errors.shortCode}
          />
          <HrTextInput
            name='address'
            label='Address'
            onChange={formik.handleChange}
            value={formik.values.address}
            errors={formik.errors.address}
          />
          <HrTextInput
            name='city'
            label='City'
            onChange={formik.handleChange}
            value={formik.values.city}
            errors={formik.errors.city}
          />
          <Autocomplete
            id='state'
            options={statesList}
            value={statesList.find(s => s.abbreviation === selectedClient?.state)}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
              formik.setFieldValue('state', value?.abbreviation)
            }}
            renderInput={(params) => <HrTextInput {...params} errors={formik.errors.state} label='State' variant="outlined" />}
          />
          <HrTextInput
            name='zip'
            label='Zip'
            onChange={formik.handleChange}
            value={formik.values.zip}
            errors={formik.errors.zip}
          />
          <HrTextInput
            name='phone'
            label='Phone Number'
            onChange={formik.handleChange}
            value={formik.values.phone}
            errors={formik.errors.phone}
          />
          <HrTextInput
            name='email'
            label='Email'
            onChange={formik.handleChange}
            value={formik.values.email}
            errors={formik.errors.email}
          />
          <HrTextInput
            name='primaryContact'
            label='Primary Contact'
            onChange={formik.handleChange}
            value={formik.values.primaryContact}
            errors={formik.errors.primaryContact}
          />
          <HrButton sx={{ mb: '.5rem' }}
            type='submit'
            title='Submit'
          />
        </FormGrid>
      </form>
      <PPEModal />
    </Box>
  )
});