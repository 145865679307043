import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import { FormikErrors } from 'formik';
import { disableForStatus } from '../../stores/ReactiveVariables';
import { useReactiveVar } from '@apollo/client';
import { Colors } from '../../constants/colors';
import styled, { StyledComponent } from '@emotion/styled';

export type HrSelectProps = {
    width?: string | number;
    errors?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
    highlightOnFocus?: boolean;
    overrideStatusDisable?: boolean;
} & SelectProps;

const UnstyledSelect = ({ variant, width, errors, size = 'small', highlightOnFocus, overrideStatusDisable, ...otherProps }: HrSelectProps) => {
    const shouldDisableForStatus = useReactiveVar(disableForStatus);

    let myError = '';

    if (errors && typeof errors === 'string') {
        myError = errors;
    }

    return (
        <FormControl fullWidth variant={variant ?? "outlined"}>
            <InputLabel id="demo-simple-select-label" sx={{
                color: Colors.black,
                '.MuiInputBase-root': {
                    fontWeight:500,
                    ".MuiInputBase-input":{
                        fontSize:"1rem!important",
                    },

                },

                '&.MuiInputLabel-shrink ':{
                    transform:"translate(14px, -11px) scale(.75)!important",
                    fontSize:"1.3rem",
                    //fontWeight:500,
                }
            }} 
            size={size != 'small' ? 'normal' : 'small'}>{otherProps.label}</InputLabel>
            <Select
                {...otherProps}
                size={size ?? 'small'}
                error={otherProps.error || Boolean(myError)}
                onFocus={event => {
                    if (highlightOnFocus === true) {
                        event.target.select();
                    }
                }}
                labelId="demo-simple-select-label"
                disabled={(shouldDisableForStatus && !overrideStatusDisable) || otherProps.disabled}
                label={otherProps.label}
                sx={{
                    fontWeight:500,
                    '.MuiOutlinedInput-notchedOutline > legend': {
                        fontSize:".95rem"
                    },
                }}
            >
                {otherProps.children}
            </Select>
        </FormControl>
    );
}

export const HrSelect : StyledComponent<HrSelectProps> = styled(UnstyledSelect)(({ width }: any) => ({
    '.MuiTextField-root label' : {
        color: Colors.deepSeaGreen
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: Colors.deepSeaGreen,
    },
    width: width
}));
