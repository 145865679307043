import { useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import { GetQuoteAddersQueryResult, GET_QUOTE_ADDERS } from "../../../../api/graphqlqueries/getquoteadders";
import dollarUS from "../../../../utils/CurrencyFormat";
import { StyledHeader } from "../../../atoms/StyledHeader";
import { RateHeading } from "../../../molecules/BidbookRateHeading";
import LineItemLoading from "../../../molecules/LineItemLoading";
import { QuoteAddersRate } from "./BidbookQuoteAddersRate";
interface QuoteLineItemsProps {
    bidQuoteId: string;
    total : number; 
    type? : string;
}

export const QuoteAddersRates = ({ bidQuoteId, total, type }: QuoteLineItemsProps) => {
    const { loading, data, error, refetch } = useQuery<GetQuoteAddersQueryResult>(GET_QUOTE_ADDERS, {
        variables: {
            BidQuoteId: bidQuoteId,
        },
        fetchPolicy: 'cache-and-network',
    });

    if (error) {
        console.error(error);
    }
    if (loading && !data) return <LineItemLoading />

    const quoteItems = data?.BidQuoteMaterials?.filter((item : any) => type ? item?.GroupWith === type : (item?.GroupWith === "" || item?.GroupWith === "Quote")); 

    if(quoteItems?.length === 0) return null;

    return (
        <Grid item container direction="column">
            <RateHeading>
                <Grid item xs={6}>
                    <StyledHeader>{type} Adders</StyledHeader>
                </Grid>
                <Grid item xs={6} sx={{textAlign:"right"}}>
                    <StyledHeader>{dollarUS.format(total)}</StyledHeader>
                </Grid>
            </RateHeading>
            <Grid item container direction="column">
                { quoteItems?.sort((a, b) => a.Ordinal - b.Ordinal).map((si: any) => <QuoteAddersRate bidQuoteId={bidQuoteId} key={si.Id  ?? 'new'} type={type} id={si.Id} onSave={refetch} total={total}/>)}
            </Grid>
        </Grid>
    )
}