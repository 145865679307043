import { useQuery, useReactiveVar, gql } from "@apollo/client";
import { Delete } from "@mui/icons-material";
import { Autocomplete, FormControl, FormControlLabel, IconButton, MenuItem, Radio, RadioGroup } from "@mui/material";
import { useFormik } from "formik";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { v4 } from 'uuid';
import * as yup from 'yup';
import { BidQuoteRate, GetBidQuoteRatesQueryResult, GET_BIDQUOTE_RATES_QUERY } from "../../../../api/graphqlqueries/bidquoterates";
import { BidQuoteMaterial } from "../../../../api/graphqlqueries/getsurveyquoteitem";
import { GetQuoteFactorsQueryResult, GET_QUOTE_FACTORS_QUERY } from "../../../../api/graphqlqueries/quotefactorsquery";
import debounce from '../../../../utils/debounce';
import { HrCurrencyInput } from "../../../atoms/HrCurrencyInput";
import HrTextInput from "../../../atoms/HrTextInput";
import { QuoteLineItemFormGrid } from '../../../atoms/QuoteLineItemFormGrid';
import { SurveyItemCopyButton } from './SurveyItemCopyButton';
import { getSurveySegmentCalculations, SurveySegmentCalculations, BidQuoteItem } from "../../../../utils/calculations/segments";
import { DeleteWarning } from "../../../molecules/DeleteWarning";
import { disableForStatus } from "../../../../stores/ReactiveVariables";
import HrCheckBox from "../../../atoms/HrCheckBox";
import { GET_BIDQUOTE_ITEMS_BY_TYPE } from "../../../../api/graphqlqueries/getparentbidbookitems";

interface QuoteSurveyItem {
    Id?: string;
    Segment?: string;
    Area?: string;
    Miles?: number;
    AverageMiles?: number;
    Mob?: number;
    MobWoPD?: number;
    Setup?: number;
    Boat?: number;
    CrewType?: string,
    CrewQuantity?: number,
    Rate?: number;
    OverrideRate?: boolean;
    BidQuoteRateId?: string | null;
    Discount?: number;
    Type?: string;
    BidQuoteMaterials?: BidQuoteMaterial[];
    PerDay?: boolean;
    Ordinal?: number;
    LineBreakAfter?: boolean;
    ParentBidQuoteItemId?: string | null;
    SurveyGrouping?: string | null;
}

interface QuoteSurveyItemFormProps {
    item?: QuoteSurveyItem;
    onSave: (item: QuoteSurveyItem) => void
    onDelete: (id: string) => void
    Item?: (values: any) => void
}

const quoteSurveyItemSchema = yup.object().shape({
    Segment: yup.string().required(),
    Area: yup.string(),
    Miles: yup.number(),
    AverageMiles: yup.number(),
    Mob: yup.number(),
    MobWoPD: yup.number(),
    Setup: yup.number(),
    Boat: yup.number(),
    CrewType: yup.string(),
    CrewQuantity: yup.number(),
    Rate: yup.number(),
    Discount: yup.number(),
    PerDay: yup.bool(),
    LineBreakAfter: yup.bool(),
    ParentBidQuoteItemId: yup.string().nullable(),
    SurveyGrouping: yup.string().nullable()
});


export const BidbookQuoteSurveyItemForm = ({ item: quoteItem, onSave, onDelete }: QuoteSurveyItemFormProps) => {
    const Id = quoteItem?.Id ?? v4();
    const params = useParams<{ id: string }>();
    const disabledForStatus = useReactiveVar(disableForStatus);
    const [selectedService, setSelectedService] = useState(null as BidQuoteRate | null);
    const { data } = useQuery<GetBidQuoteRatesQueryResult>(GET_BIDQUOTE_RATES_QUERY, {
        variables: {
            BidQuoteId: params.id,
        }
    });
    const { data: factorData } = useQuery<GetQuoteFactorsQueryResult>(GET_QUOTE_FACTORS_QUERY, {
        variables: {
            Id: params.id
        }
    });

    const { data: quoteItemData } = useQuery(GET_BIDQUOTE_ITEMS_BY_TYPE, {
        variables: {
            BidQuoteId: params.id,
            Type: "Survey"
        }
    });

    const parentItems = quoteItemData?.BidQuoteItems?.filter((bqi: any)=> bqi?.ParentBidQuoteItemId === null) ?? [];

    const { SurveyFieldHours } = factorData?.BidQuotes_by_pk ?? {};

    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        setSelectedService(s => data?.BidQuoteRates.find(r => r.Id === quoteItem?.BidQuoteRateId) ?? null);
    }, [data, setSelectedService, quoteItem]);


    const formik = useFormik({
        validationSchema: quoteSurveyItemSchema,
        initialValues: {
            Segment: quoteItem?.Segment ?? '',
            Area: quoteItem?.Area ?? '',
            Miles: quoteItem?.Miles ?? 0,
            AverageMiles: quoteItem?.AverageMiles ?? 0,
            Mob: quoteItem?.Mob ?? 0,
            MobWoPD: quoteItem?.MobWoPD ?? 0,
            Setup: quoteItem?.Setup ?? 0, 
            Boat: quoteItem?.Boat ?? 0, 
            CrewType: quoteItem?.CrewType ?? '',
            CrewQuantity: quoteItem?.CrewQuantity ?? 0,
            Rate: quoteItem?.Rate ?? 0,
            Discount: quoteItem?.Discount ?? 0,
            PerDay: quoteItem?.PerDay ?? false,
            LineBreakAfter: quoteItem?.LineBreakAfter ?? false,
            ParentBidQuoteItemId: quoteItem?.ParentBidQuoteItemId?.toLowerCase() ?? null,
            SurveyGrouping: quoteItem?.SurveyGrouping ?? null
        },
        onSubmit: (values) => {
            const data = quoteSurveyItemSchema.cast(values);
            onSave({ Id, Type: 'Survey', ...data });
            formik.setSubmitting(false);
        },
        enableReinitialize: true
    });
    const updateData = (data: any) => {
        onSave({ Id, Type: 'Survey', ...data });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        try {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value };
            const values = quoteSurveyItemSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.handleChange(e);
    }

    const handleCheckChange = (e: ChangeEvent<HTMLInputElement>) => {
        try {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.checked};
            const values = quoteSurveyItemSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.setFieldValue(e.target.name, e.target.checked);
    }

    // eslint-disable-next-line
    const debouncer = useCallback(debounce((values: any) => updateData(values), 1000), []);

    const handleDelete = () => {
        if (!deleting) {
            setDeleting(true)
            onDelete(Id);
        }
    }

    const handleTypeSelect = (event: React.ChangeEvent<{}>, value: BidQuoteRate | null) => {
        setSelectedService(value);
        formik.setFieldValue('BidQuoteRateId', value?.Id ?? null);
        try {
            const updatedValues = { ...formik.values, BidQuoteRateId: value?.Id ?? null };
            const values = quoteSurveyItemSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
    };

    const handleParentSegmentChange = (event: any, value: any) => {
        formik.setFieldValue('ParentBidQuoteItemId', value?.Id ?? null);
        try {
            const updatedValues = { ...formik.values, ParentBidQuoteItemId: value?.Id ?? null };
            const values = quoteSurveyItemSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSurveyGroupingChange = (event: any, value: any) => {
      formik.setFieldValue('SurveyGrouping', value ?? null);
      try {
          const updatedValues = { ...formik.values, SurveyGrouping: value ?? null };
          const values = quoteSurveyItemSchema.cast(updatedValues);
          debouncer(values);
      } catch (error) {
          console.error(error);
      }
  };

    const width = {
        small: "10ch",
        medium: "14ch",
        large: "18ch",
        xl: "22ch",
        xxl: "36ch"
    }
    const formValues = { ...formik.values, BidQuoteRate: selectedService, BidQuoteMaterials: quoteItem?.BidQuoteMaterials ?? [] };
    const segmentCalculations: SurveySegmentCalculations = getSurveySegmentCalculations(formValues as BidQuoteItem, factorData?.BidQuotes_by_pk);
    const filteredData = data?.BidQuoteRates.filter(r => r.DailyHours === null || r.DailyHours === SurveyFieldHours || r.ServiceType === "Office");
    return (
        <QuoteLineItemFormGrid sx={{ minWidth: "1040px" }}>
            <HrTextInput
                name='Segment'
                label='Segment'
                onChange={handleChange}
                value={formik.values.Segment}
                width={width.xxl}
                errors={formik.errors.Segment}
            />
            <HrTextInput
                name='Area'
                label='Area'
                onChange={handleChange}
                value={formik.values.Area}
                width={width.large}
                errors={formik.errors.Area}
            />
            <Autocomplete
                options={["CIS","Specialty","Ramp"]}
                value={formik.values.SurveyGrouping}
                disabled={disabledForStatus}
                renderInput={(params: any) =>
                    <HrTextInput
                        {...params}
                        name="SurveyGrouping"
                        label="Grouping"
                        width={width.large}
                        errors={formik.errors.SurveyGrouping}
                        InputProps={{ ...params.InputProps }}
                    />
                }
                onChange={handleSurveyGroupingChange}
            />
            <HrTextInput
                name='Miles'
                label='Miles'
                onChange={handleChange}
                value={formik.values.Miles}
                width={width.small}
                errors={formik.errors.Miles}
            />
            <HrTextInput
                name='AverageMiles'
                label='Average'
                onChange={handleChange}
                value={formik.values.AverageMiles}
                width={width.small}
                errors={formik.errors.AverageMiles}
            />
            <HrTextInput
                name='Survey'
                label='Survey'
                value={segmentCalculations.Survey.toFixed(2)}
                width={width.small}
                disabled
            />
            <HrTextInput
                name='Mob'
                label='Mob'
                onChange={handleChange}
                value={formik.values.Mob}
                width={width.small}
                errors={formik.errors.Mob}
            />
            <HrTextInput
                name='MobWoPD'
                label='Less Per Diem'
                onChange={handleChange}
                value={formik.values.MobWoPD}
                width={width.medium}
                errors={formik.errors.MobWoPD}
            />
            <HrTextInput
                name='Setup'
                label='Setup'
                onChange={handleChange}
                value={formik.values.Setup}
                width={width.small}
                errors={formik.errors.Setup}
            />
            <HrTextInput
                name='Boat'
                label='Boat'
                onChange={handleChange}
                value={formik.values.Boat}
                width={width.small}
                errors={formik.errors.Boat}
            />
            <Autocomplete
                options={filteredData ? filteredData?.sort((a: any, b: any) => a.Name?.localeCompare(b.Name)) : []}
                value={selectedService ?? null}
                disabled={disabledForStatus}
                renderInput={(params: any) =>
                    <HrTextInput
                        {...params}
                        name="Type"
                        label="Type"
                        width={width.xxl}
                        errors={formik.errors.CrewType}
                        InputProps={{ ...params.InputProps }}
                    />
                }
                onChange={handleTypeSelect}
                getOptionLabel={(option: any) => option.Name}
            />
            <HrCurrencyInput
                name='crewPrice'
                label='Crew Std Price'
                value={selectedService?.Rate ?? 0}
                width={width.medium}
                disabled
            />
            <FormControl>
                <RadioGroup
                    name="PerDay"
                    value={formik.values.PerDay}
                    onChange={handleChange}
                    row
                >
                    <FormControlLabel value={true} control={<Radio disabled={disabledForStatus} />} label="Per Day" />
                    <FormControlLabel value={false} control={<Radio disabled={disabledForStatus} />} label="Per Mile" />
                </RadioGroup>
            </FormControl>
            <HrTextInput
                name='CrewQuantity'
                label='Personnel'
                onChange={handleChange}
                value={formik.values.CrewQuantity}
                width={width.small}
                errors={formik.errors.CrewQuantity}
            />
            <HrCheckBox
                name="LineBreakAfter"
                label="Line Break After"
                checked={formik.values.LineBreakAfter}
                onChange={handleCheckChange}
            />
            <Autocomplete
                options={parentItems.filter((bqi: any) => bqi.Id !== quoteItem?.Id) ?? []}
                renderInput={(params: any) =>
                    <HrTextInput
                        {...params}
                        label="Parent Segment"
                        width={width.xl}
                        errors={formik.errors.ParentBidQuoteItemId}
                    />
                }
                onChange={handleParentSegmentChange}
                getOptionLabel={(option: any) => option.Segment}
                value={quoteItemData?.BidQuoteItems?.find((bqi: any) => bqi.Id.toLowerCase() === formik.values.ParentBidQuoteItemId) ?? null}
                disabled={quoteItemData?.BidQuoteItems?.find((bqi: any) =>  bqi?.ParentBidQuoteItemId === Id)}
            />
            <SurveyItemCopyButton id={Id} />
            <DeleteWarning
                onClick={handleDelete}
                heading={"Delete Survey Item"}
                disabled={deleting || disabledForStatus}
            >
                <IconButton
                    color="error"
                >
                    <Delete />
                </IconButton>
            </DeleteWarning>
        </QuoteLineItemFormGrid>
    );
}