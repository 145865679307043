import { Button, Dialog, DialogContent } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormGrid } from '../../atoms/FormGrid';
import { ContentHeader } from '../ContentHeader';
import { CustomerContactDataGrid } from './CustomerContactDataGrid';
import { CustomerContactItem } from './CustomerContactItem';


export const ClientsContactPageContent: React.FC = () => {
    const params = useParams();
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedContactId, setSelectedContactId] = useState('');
    const handleEdit = (editId: string) => {
        setSelectedContactId(editId);
        setModalOpen(true);
    }
    const closeModal = () => setModalOpen(false);
    return (
        <div style={{
            height: '80vh',
            width: '100%',
            alignItems: 'center'
        }}>
            <ContentHeader
                title='Client Contacts'
                onClick={() => {
                    setSelectedContactId('');
                    setModalOpen(true);
                }}
            />
            <CustomerContactDataGrid customerId={params.customerId} editSelect={handleEdit} />
            <div>
                <Dialog
                    open={modalOpen}
                    onClose={closeModal}
                >
                    <DialogContent>

                        <Button
                            onClick={() => {
                                setSelectedContactId('');
                                setModalOpen(false);
                            }}
                            variant="contained"
                            color="primary"
                            className="modal-exit"
                        >
                            x
                        </Button>
                        <FormGrid>
                            <CustomerContactItem
                                customerId={params.customerId}
                                id={selectedContactId}
                                onCancel={closeModal}
                                onSave={closeModal}
                            />
                        </FormGrid>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    )
}