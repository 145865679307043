import React from 'react';
import { Box, CircularProgress } from '@mui/material';

interface LineItemLoadingProps { 
    sx? : object; 
}


const LineItemLoading = (props: LineItemLoadingProps) => {
    return(
        <Box sx={{
            width:"100%", 
            textAlign:"center", 
            backgroundColor: '#F0F0F0',
            borderRadius:2, 
            padding: "4px", 
            marginBottom:1,
            boxSizing:"border-box",
            ...props.sx 
        }}
            >
            <CircularProgress size={30}/>
        </Box>
    )
}

export default LineItemLoading;