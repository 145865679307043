import { gql } from "@apollo/client/core";
import { BidQuoteBillable } from "./bidquotebillables";
import { BidQuoteRate } from "./bidquoterates";

export interface GetQuoteItemsQueryResult {
  BidQuoteItems: BidQuoteItem[];
}

interface BidQuoteItem {
  Id: string;
  Ordinal: number;
  BidQuoteMaterials: BidQuoteMaterial[];
  BidQuoteId: string;
  Type: string;
  CreateDate: string;
  Miles: number;
  AverageMiles: number;
  Mob: number;
  MobWoPD: number;
  Setup: number;
  Boat: number;
  Rate: number;
  OverrideRate: boolean;
  BidQuoteRate: BidQuoteRate;
  BidQuoteRateId: string;
  Discount: number;
  PerDay: boolean;
  LineBreakAfter: boolean;
}

interface BidQuoteMaterial {
  Id: string;
  Ordinal: number;
  Rate: number;
  OverrideRate: boolean;
  BidQuoteBillable: BidQuoteBillable;
  BidQuoteBillableId: string;
  Type: string;
  Markup: number;
  Quantity: number;
  Duration: number;
  Unit: string;
  LineBreakAfter: boolean;
}
export const GET_QUOTE_ITEMS_QUERY = gql`
  query GetQuoteItems($BidQuoteId: uniqueidentifier = "", $Type: String = "") {
    BidQuoteItems(where: {BidQuoteId: {_eq: $BidQuoteId}, Type: {_eq: $Type}}, order_by: {Ordinal: desc}) {
        Id
        Ordinal
        BidQuoteMaterials{
          Id
          Rate
          OverrideRate
          BidQuoteBillable {
            Id
            Name
            Cost
          }
          BidQuoteBillableId
          Type
          Markup
          Quantity
          Duration
          Unit
          Ordinal
          LineBreakAfter
        }
        BidQuoteId
        Type
        CreateDate
        Miles
        AverageMiles
        Mob
        MobWoPD
        CrewQuantity
        Setup
        Boat
        Rate
        OverrideRate
        BidQuoteRate {
          Id
          Name
          Rate
          ServiceType
          Cost
        }
        BidQuoteRateId
        Discount
        PerDay
        LineBreakAfter
    }
  }
`;