import { useMutation, useQuery } from "@apollo/client";
import { CircularProgress, Grid } from "@mui/material";
import { useFormik } from "formik";
import * as yup from 'yup';
import { GetQuoteReportCommentsQueryResult, GET_QUOTE_REPORT_COMMENTS_QUERY } from "../../../api/graphqlqueries/getquotecomments";
import { UPDATE_BID_QUOTE_INSTRUCTIONS_MUTATION } from "../../../api/graphqlqueries/updatebidquoteinstructionsmutation";
import { addMessage } from "../../../stores/ReactiveVariables";
import HrTextInput from "../../atoms/HrTextInput";
import { StyledHeader } from '../../atoms/StyledHeader';
import { GET_REPORT_SETTINGS_QUERY } from "../../../api/graphqlqueries/getreportsettings";

interface QuoteCommentProps {
    id: string;
    type: string;
    title: string;
    rows?: number;
    status?: string;
}

const quoteCommentSchema = yup.object().shape({
    comment: yup.string()
});

export const QuoteReportComments = ({ id, type, title, rows, status }: QuoteCommentProps) => {
    const { loading, data, error } = useQuery<GetQuoteReportCommentsQueryResult>(GET_QUOTE_REPORT_COMMENTS_QUERY, {
        variables: {
            Id: id
        },
        fetchPolicy: 'cache-and-network',
    });
    const [addComment] = useMutation(UPDATE_BID_QUOTE_INSTRUCTIONS_MUTATION);

    const bidQuotes = data?.BidQuotes_by_pk;

    const comment = bidQuotes ? bidQuotes[type as keyof typeof bidQuotes] : null;
    const formik = useFormik({
        validationSchema: quoteCommentSchema,
        initialValues: { comment: comment ?? '' },
        onSubmit: (values) => {
            addComment({
                variables: {
                    Id: id,
                    Quote: {
                        [type]: values.comment
                    },
                },
                onCompleted: (data) => {
                    if (data) {
                        addMessage(`${title} saved successfully`, { severity: 'success' });
                    }
                },
                onError: () => {
                    addMessage(`${title} saved failed`, { severity: 'error' });
                },
                refetchQueries: [{ query: GET_REPORT_SETTINGS_QUERY, variables: { Id: id } }]
            });
        },
        enableReinitialize: true,
    });

    const handleBlur = () => {
        formik.handleSubmit();
    }

    if (error) {
        console.error(error);
    }
    if (loading && !data) {
        return (<CircularProgress />)
    }

    return (
        <Grid container spacing={1} mb={.5} direction="column">
            <Grid item>
                <StyledHeader>{title}</StyledHeader>
            </Grid>
            <Grid item>
                <form onSubmit={formik.handleSubmit}>
                    <HrTextInput
                        id="standard-multiline-static"
                        name="comment"
                        label="Add Comment"
                        fullWidth
                        multiline
                        minRows={rows ?? 4}
                        placeholder="Add Comment"
                        value={formik.values.comment}
                        onChange={formik.handleChange}
                        onBlur={handleBlur}
                        overrideStatusDisable={true}
                    />
                    {/* <HrButton
                        sx={{ mt: 1, mb: 1 }}
                        type='submit'
                        title='Add Comment'
                    /> */}
                </form>
            </Grid>
        </Grid>
    )
}