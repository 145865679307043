import { gql } from "@apollo/client/core";

export const UPDATE_CUSTOMER_CONTACT_MUTATION = gql`
  mutation UpdateCustomerContact($Id: uniqueidentifier = "", $object: CustomerContacts_set_input = {}) {
    update_CustomerContacts_by_pk(pk_columns: {Id: $Id}, _set: $object) {
      __typename
      CustomerId
      Email
      FirstName
      Id
      IsActive
      LastName
      Phone
      Title
    }
  }
`;