import { gql } from "@apollo/client/core";
import { BidQuoteBillable } from "./bidquotebillables";
import { BidQuoteRate } from "./bidquoterates";


export interface GetConstructionItemsQueryResult {
  BidQuoteItems: BidQuoteItem[];
}

interface BidQuoteItem {
  Area: string;
  Id: string;
  Type: string;
  Segment: string;
  BidQuoteConstructionServices: BidQuoteConstructionService[];
  BidQuoteMaterials: BidQuoteMaterial[];
  PerDay?: boolean;
}

interface BidQuoteConstructionService {
  Average: number;
  Days: number;
  Discount: number;
  BidQuoteItemId: string;
  BidQuoteRate: BidQuoteRate;
  BidQuoteRateId: string;
  Equipment: number;
  Locations: number;
  Id: string;
  Mileage: number;
  Mob: number;
  MobWoPD: number;
  PerDiem: boolean;
  Personnel: number;
  Rate: number;
  OverrideRate: boolean;
  Rest: number;
  Setup: number;
  Unit: string;
  Ordinal: number;
  LineBreakAfter: boolean;
}
interface BidQuoteMaterial {
  Id: string;
  Ordinal: number;
  BidQuoteBillable: BidQuoteBillable;
  BidQuoteBillableId: string;
  Rate: number;
  OverrideRate: boolean;
  Type: string;
  Markup: number;
  Quantity: number;
  Duration: number;
  Unit: string;
  LineBreakAfter: boolean;
}

export const GET_CONSTRUCTION_ITEMS_QUERY = gql`
  query GetConstructionItems($BidQuoteId: uniqueidentifier = "", $Type: String = "") {
    BidQuoteItems(where: {BidQuoteId: {_eq: $BidQuoteId}, Type: {_eq: $Type}}, order_by: {CreateDate: asc})  {
      Area
      Id
      BidQuoteConstructionServices {
        Average
        Days
        Discount
        BidQuoteItemId
        BidQuoteRate {
          Id
          Name
          Rate
          ServiceType
          Cost
        }
        BidQuoteRateId
        Equipment
        Locations
        Id
        Mileage
        Mob
        MobWoPD
        PerDiem
        Personnel
        Rate
        OverrideRate
        Rest
        Setup
        Unit
        Ordinal
        LineBreakAfter
      }
      BidQuoteMaterials {
        Id
        BidQuoteItemId
        BidQuoteId
        BidQuoteBillable {
          Id
          Name
          Cost
        }
        Description
        Rate
        OverrideRate
        Markup
        Quantity
        Unit
        Type
        Duration
        Ordinal
        LineBreakAfter
      }
      Location
      Type
      Segment
      LineBreakAfter
      Ordinal
    }
  }
`;