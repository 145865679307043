//SECTION CHECKBOX

import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import * as yup from 'yup';
import { addMessage, resetMessage } from "../../../../../stores/ReactiveVariables";
import { AccordionCheckbox } from "../AccordionCheckbox";

const GET_COVER_LETTER_COVER_PAGE_SECTION = gql`
    query GetCoverLetterCoverPage($Id: uniqueidentifier = "") {
        BidQuotes_by_pk(Id: $Id) {
        CoverLetterCoverPageSection
        }
    }  
`;

const UPDATE_COVER_LETTER_COVER_PAGE_SECTION = gql`
    mutation UpdateCoverLetterCoverPageSection($Id: uniqueidentifier = "", $CoverLetterCoverPageSection: Boolean = false) {
        update_BidQuotes_by_pk(pk_columns: {Id: $Id}, _set: {CoverLetterCoverPageSection: $CoverLetterCoverPageSection}) {
            __typename
            Id
        }
    }
`;

interface FrontPageCheckboxProps {
    id: string;
    getCoverLetter: () => void;
}

const validationSchema = yup.object().shape({
    CoverLetterCoverPageSection: yup.boolean(),
});

export const FrontPageCheckbox: React.FC<FrontPageCheckboxProps> = ({ id, getCoverLetter }: FrontPageCheckboxProps) => {
    const { data, loading, error } = useQuery(GET_COVER_LETTER_COVER_PAGE_SECTION, { variables: { Id: id } });
    const [updateCoverLetterExecutionPlan] = useMutation(UPDATE_COVER_LETTER_COVER_PAGE_SECTION);

    const formData = data?.BidQuotes_by_pk;

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            CoverLetterCoverPageSection: formData?.CoverLetterCoverPageSection ?? false,
        },
        onSubmit: (values: any) => {
            updateCoverLetterExecutionPlan({
                variables: { Id: id, CoverLetterCoverPageSection: values.CoverLetterCoverPageSection },
                onCompleted: (data: any) => {
                    resetMessage();
                    const confirmMessage = values.CoverLetterCoverPageSection === true ? "Cover Page Added To Cover Letter" : "Cover Page Removed From Cover Letter";
                    addMessage(confirmMessage, { severity: 'success' });
                    getCoverLetter();
                },
                onError: () => {
                    addMessage('Error Saving/Removing Execution Plan Section', { severity: 'error' });
                }
            });
        }
    });
    return (
        <AccordionCheckbox
            getCoverLetter={getCoverLetter}
            name="CoverLetterCoverPageSection"
            checked={formik.values.CoverLetterCoverPageSection}
            onCheckboxClick={() => formik.setFieldValue('CoverLetterCoverPageSection', !formik.values.CoverLetterCoverPageSection)}
            onSave={formik.submitForm}
            loading={loading}
        />
    )
}
