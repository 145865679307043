import { IconButton, IconButtonProps } from "@mui/material";
import { DeleteForeverOutlined } from "@mui/icons-material";
import React from "react";

const RemoveButton = (props: IconButtonProps) => {
    return (
        <IconButton
            onClick={props.onClick}
            size="small"
            sx={{ backgroundColor: "#EB7F7F !important", borderRadius: "5px" }}
        >
            <DeleteForeverOutlined style={{ color: "#B72A2A" }} />
        </IconButton>

    );
};

export default RemoveButton;
