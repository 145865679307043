import { HrTextInputProps } from "../atoms/HrTextInput";
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { styled, TextField, TextFieldProps, FormControl } from "@mui/material";
import { Colors } from "../../constants/colors";
import { useReactiveVar } from "@apollo/client";
import { disableForStatus } from "../../stores/ReactiveVariables";


type DecimalInputProps ={
  decimalScale?: number;
} & NumericFormatProps & TextFieldProps & HrTextInputProps;

const DecimalInput = (props: DecimalInputProps) => {
  const disabledForStatus = useReactiveVar(disableForStatus);
  return(
    <FormControl>
        <NumericFormat
          {...props}
          value={props.value} 
          allowLeadingZeros 
          thousandSeparator="," 
          decimalScale={props.decimalScale ?? 2} 
          style={props?.style}
          disabled={props?.disabled || disabledForStatus}
          size={props.size ?? 'small'} 
          onBlur={props?.onBlur}
          onChange={props.onChange}
          width={props?.width}
          customInput={TextField}
          valueIsNumericString
        />
    </FormControl>
  )
}

export const HrDecimalInput = styled(DecimalInput)(({ width }) => ({
    '.MuiInputBase-root': {
        fontWeight:500,
        'legend': {
            fontSize:".95rem"
        }
    },
    '.MuiInputLabel-shrink ':{
        transform:"translate(14px, -11px) scale(.75)!important",
        fontSize:"1.3rem!important",
        //fontWeight:500,
    },
  "& label.Mui-focused": {
      color: Colors.darkSeaGreen,
  },
  "& label": {
      color: Colors.darkSeaGreen,
      fontFamily: "Montserrat"
  },
  "& .MuiInput-underline:after": {
      borderBottomColor: Colors.darkSeaGreen,
  },
  "& .MuiOutlinedInput-root": {
      fontFamily: "Montserrat",
      "& fieldset": {
          borderColor: Colors.darkSeaGreen,
      },
      "&:hover fieldset": {
          borderColor: Colors.darkSeaGreen,
      },
      "&.Mui-focused fieldset": {
          borderColor: Colors.darkSeaGreen,
      },
      "&.Mui-error fieldset": {
          borderColor: Colors.errorRed,
      }
  },
  "& .MuiInputBase-root.Mui-disabled": {
      borderColor: Colors.darkSeaGreen,
      backgroundColor:Colors.unselectedGray,
      color: Colors.black,
      webkitTextFillColor: Colors.black,
      "&.Mui-error":{
          "&:hover fieldset": {
              borderColor: Colors.errorRed,
          },
          "& fieldset": {
              borderColor: Colors.errorRed,
          },  
      },
      "& fieldset": {
          borderColor: Colors.darkSeaGreen,
      },
      "&:hover fieldset": {
          borderColor: Colors.darkSeaGreen,
      },
      "&.Mui-focused fieldset": {
          borderColor: Colors.darkSeaGreen,
      },

  },
  "& label.Mui-disabled": {
      color: "black",
  },
  width: width
}));