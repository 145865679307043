import { gql, useMutation, useQuery } from "@apollo/client";
import { Box } from "@mui/material"
import { addMessage } from "../../../../stores/ReactiveVariables";
import { FrontPageForm, FrontPageFormData } from "./FrontPageForm"

interface FrontPageProps{
    id: string;
    getCoverLetter: () => void;
}

interface FrontPageQuery{
    BidQuotes_by_pk : FrontPageFormData;
}

const GET_COVER_LETTER_FRONT_PAGE_QUERY = gql`
    query CoverLetterFrontPage($Id: uniqueidentifier = "") {
        BidQuotes_by_pk(Id: $Id) {
            CoverLetterClientRepTitle
            CoverLetterReference
            CoverLetterFrontPageServices
        }
    }  
`;

const UPDATE_COVER_LETTER_FRONT_PAGE = gql`
    mutation UpdateCoverLetterFrontPage($Id: uniqueidentifier = "", $object: BidQuotes_set_input = {CoverLetterClientRepTitle: "", CoverLetterReference: "", CoverLetterFrontPageServices: ""}) {
        update_BidQuotes_by_pk(pk_columns: {Id: $Id}, _set: $object) {
            CoverLetterClientRepTitle
            CoverLetterReference
            CoverLetterFrontPageServices
        }
    }
`

export const FrontPage: React.FC<FrontPageProps> = (props: FrontPageProps) => {
    const { id, getCoverLetter } = props;

    const { data, loading, error } = useQuery<FrontPageQuery>(GET_COVER_LETTER_FRONT_PAGE_QUERY, { variables: { Id: id } });

    const [updateCoverLetterFrontPage] = useMutation(UPDATE_COVER_LETTER_FRONT_PAGE);

    const formData : FrontPageFormData = data?.BidQuotes_by_pk as FrontPageFormData;

    const onSave = (values: FrontPageFormData) => {
        updateCoverLetterFrontPage({ 
            variables: { Id: id, object: values },
            onCompleted: () => {
                addMessage('Cover Page Updated', { severity: 'success' });
                getCoverLetter();
            },
            onError: () => {
                addMessage('Error Updating Cover Page', { severity: 'error' });
            }
        });
    }

    return(
        <Box sx={{ width: '100%' }}>
            <FrontPageForm id={id} data={formData} onSave={onSave}/>
        </Box>
    )
}