import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { ContentHeader } from "../ContentHeader";
import { Tabs } from "@mui/material";
import TabNavButton, { BlankNavButton } from "../../atoms/TabNavButton";
import { BidBookDataGrid } from "./BidbookDataGrid";
import { Route, Routes, useNavigate } from "react-router";
import { CreateQuoteForm } from "./BidbookCreateQuoteForm";
import { QuoteDetails } from "./BidbookQuoteDetails";
import { styled } from "@mui/system";
import { useLocation } from "react-router";

interface BidbookPageProps {
    props?: any;
}

function a11yProps(page: string) {
    return {
        value: page,
        id: `nav-tab-${page}`,
        'aria-controls': `nav-tabpanel-${page}`,
    };
}

export const BidbookPageContent: React.FC<BidbookPageProps> = observer((props) => {
    const location = useLocation();
    const navigation = useNavigate();
    useEffect(() => {
        if (location.pathname === '/BidBook') {
            navigation('/Bidbook/list/Draft');
        }
    }, [location, navigation]);
    return (
        <BidBookWrapper>
            <ContentHeader title="Bid Book" onClick={() => navigation("/Bidbook/details/new")} />
            <TabsBar value={location.pathname.includes('/Bidbook/list') ? location.pathname : false} aria-label="simple tabs example">
                <TabNavButton label="Drafts" to="/Bidbook/list/Draft" {...a11yProps('/Bidbook/list/Draft')} />
                <TabNavButton label="Pending" to="/Bidbook/list/Pending" {...a11yProps('/Bidbook/list/Pending')} />
                <TabNavButton label="Approved" to="/Bidbook/list/Approved" {...a11yProps('/Bidbook/list/Approved')} />
                <TabNavButton label="Submitted" to="/Bidbook/list/Submitted" {...a11yProps('/Bidbook/list/Submitted')} />
                <BlankNavButton label="" to="" {...a11yProps('')} disabled />
                <TabNavButton label="Awarded" to="/Bidbook/list/Awarded" {...a11yProps('/Bidbook/list/Awarded')} />
                <TabNavButton label="Scheduled" to="/Bidbook/list/Scheduled" {...a11yProps('/Bidbook/list/Scheduled')} />
                <TabNavButton label="Archived" to="/Bidbook/list/Archived" {...a11yProps('/Bidbook/list/Archived')} />
                <BlankNavButton label="" to="" {...a11yProps('')} disabled />
                <TabNavButton label="DNR" to="/Bidbook/list/DNR" {...a11yProps('/Bidbook/list/DNR')} />
                <TabNavButton label="Deleted" to="/Bidbook/list/Deleted" {...a11yProps('/Bidbook/list/Deleted')} />
            </TabsBar>
            <Routes>
                <Route path={"/list/:status"} element={<BidBookDataGrid />} />
                <Route path={"/details/new"} element={<CreateQuoteForm />} />
                <Route path={"/details/:id"} element={<QuoteDetails />} />
            </Routes>
        </BidBookWrapper>
    );
}
);


const BidBookWrapper = styled('div')({
    height: "100vh",
    width: "100%",
    alignItems: "center",
});

const TabsBar = styled(Tabs)({
    borderBottom: '1px solid #E9C246',
    minHeight: '20px !important',
});