import { Autocomplete } from "@mui/material";
import HrTextInput from "./HrTextInput";

interface OptionProps {
    firstName: string
    lastName: string
}

export default function ComboBox(props: any) {
    return (
        <Autocomplete
            options={props.options}
            // inputValue={props.inputValue}
            getOptionLabel={(option: OptionProps) => {
                return option.firstName + " " + option.lastName;
            }}
            onChange={props.onChange}
            defaultValue={props.defaultValue}
            value={props.value}
            getOptionSelected={props.getOptionSelected}
            renderInput={(params) => (
                <HrTextInput {...params} fullWidth={props.fullWidth} label={props.label} variant="outlined" />
            )}
            {...props}
        />
    );
}
