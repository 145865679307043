import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import { Colors } from "../../constants/colors";

export default function Footer() {

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
        }}>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                width: 300,
            }}>
                <FooterLink to="/">
                    {" "}
                    Privacy Policy
                </FooterLink>
                <FooterLink to="/">
                    Terms of Service
                </FooterLink>
            </div>
            <div style={{ color: Colors.deepSeaGreen, }}>
                © Highridge. All rights reserved.
            </div>
        </div>
    );
}

const FooterLink = styled(Link)({
    color: Colors.gold,
    marginLeft: 0,
});