import { GridColDef, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-pro";
import { observer } from "mobx-react-lite";
import React from "react";
import { HrDataGrid } from "../../molecules/HrDataGrid";
import DollarUS from '../../../utils/CurrencyFormat';
import PercentUS from '../../../utils/PercentFormat';

export interface JobsDataGridProps {
    data: any[];
}

export const JobCostsDataGrid: React.FC<JobsDataGridProps> = observer(({ data }) => {
    const columns: GridColDef[] = [
        {
            field: "empName",
            headerName: "Employee Name",
            type: "string",
            width: 225,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "empRevenue",
            headerName: "Revenue",
            width: 100,
            editable: false,
            disableColumnMenu: true,
            headerClassName: "billables-grid-header",
            valueFormatter: (params) => DollarUS.format(params.value),
        },
        {
            field: "totalHours",
            headerName: "Total Hours",
            width: 125,
            editable: false,
            disableColumnMenu: true,
            headerClassName: "billables-grid-header",
        },
        {
            field: "totalMileageCost",
            headerName: "Mileage Cost",
            width: 125,
            editable: false,
            disableColumnMenu: true,
            headerClassName: "billables-grid-header",
            valueFormatter: (params) => DollarUS.format(params.value),
        },
        {
            field: "laborCost",
            headerName: "Labor Cost",
            width: 100,
            editable: false,
            disableColumnMenu: true,
            headerClassName: "billables-grid-header",
            valueFormatter: (params) => DollarUS.format(params.value),
        },
        {
            field: 'perDiemCost',
            headerName: "Per Diem Cost",
            width: 125,
            editable: false,
            disableColumnMenu: true,
            headerClassName: "billables-grid-header",
            valueFormatter: (params) => DollarUS.format(params.value),
        },
        {
            field: 'expenseCost',
            headerName: 'Expense Cost',
            width: 125,
            editable: false,
            disableColumnMenu: true,
            headerClassName: "billables-grid-header",
            valueFormatter: (params) => DollarUS.format(params.value),
        },
        {
            field: 'profit',
            headerName: 'Profit',
            width: 100,
            editable: false,
            disableColumnMenu: true,
            headerClassName: "billables-grid-header",
            valueFormatter: (params) => DollarUS.format(params.value),
        },
        {
            field: 'profitPct',
            headerName: 'Profit %',
            type: 'number',
            width: 100,
            editable: false,
            disableColumnMenu: true,
            headerClassName: "billables-grid-header",
            valueFormatter: (params) => PercentUS.format(params.value),
        }


    ];

    return (
        <HrDataGrid
            rows={data}
            getRowId={undefined}
            columns={columns}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            components={{
                Toolbar: jobsToolBar
            }}
        />
    );
});


const jobsToolBar = () => <GridToolbarContainer>
    <GridToolbarExport />
</GridToolbarContainer>;