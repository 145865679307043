import { gql } from "@apollo/client";

export interface GetGeneralSurveyQuestionsQueryResults {
    KickoffPacket_by_pk: GeneralSurveyQuestions;
}

export interface GeneralSurveyQuestions {
    Id: string;
    ClientCommunication: boolean;
    ClientCommunicationNotes: string;
    ForeignCrossings: boolean;
    ForeignCrossingsNotes: string;
    KnownBrokenTs: boolean;
    KnownBrokenTsNotes: string;
    KnownProblemAreas: boolean;
    KnownProblemAreasNotes: string;
    OverallPipelineCondition: string;
    ProblemLandowners: boolean;
    ProblemLandownersNotes: string;
    RowConditions: string;
}


export const GET_GENERAL_SURVEY_QUESTIONS_QUERY = gql`
  query GetGeneralSurveyQuestions($Id: uniqueidentifier = "") {
    KickoffPacket_by_pk(Id: $Id) {
      Id
      ClientCommunication
      ClientCommunicationNotes
      ForeignCrossings
      ForeignCrossingsNotes
      KnownBrokenTs
      KnownBrokenTsNotes
      KnownProblemAreas
      KnownProblemAreasNotes
      OverallPipelineCondition
      ProblemLandowners
      ProblemLandownersNotes
      RowConditions
    }
  }
`;

export const UPDATE_GENERAL_SURVEY_QUESTIONS_MUTATION = gql`
mutation UpdateGeneralSurveyQuestions($Id: uniqueidentifier = "", $_set: KickoffPacket_set_input = {}) {
  update_KickoffPacket_by_pk(pk_columns: {Id: $Id}, _set: $_set) {
    ClientCommunication
    ClientCommunicationNotes
    ForeignCrossings
    ForeignCrossingsNotes
    KnownBrokenTs
    KnownBrokenTsNotes
    KnownProblemAreas
    KnownProblemAreasNotes
    OverallPipelineCondition
    ProblemLandowners
    ProblemLandownersNotes
    RowConditions
    __typename
  }
}
`;