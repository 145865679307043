import { gql } from "@apollo/client/core";
import { BidQuoteBillable } from "./bidquotebillables";

export interface GetMaterialServiceQueryResults {
  BidQuoteMaterials: BidQuoteMaterial[];
}

interface BidQuoteMaterial {
  Id: string;
  BidQuoteItemId: string;
  BidQuoteId: string;
  BidQuoteBillable: BidQuoteBillable;
  BidQuoteBillableId: string;
  Description: string;
  Rate: number;
  OverrideRate: boolean;
  Markup: number;
  Quantity: number;
  Unit: string;
  Type: string;
  Duration: number;
  Tax: number;
  GroupWith: string;
  Ordinal: number;
}

export const GET_MATERIAL_SERVICE_QUERY = gql`
  query GetMaterialService($Id: uniqueidentifier = "") {
    BidQuoteMaterials(where: {Id: {_eq: $Id}}) {
      Id
      BidQuoteItemId
      BidQuoteId
      BidQuoteBillable {
        Id
        Name
        Cost
      }
      BidQuoteBillableId
      Description
      Rate
      OverrideRate
      Markup
      Quantity
      Unit
      Type
      Duration
      Tax
      GroupWith
      Ordinal
      AdderHasCost
      LineBreakAfter
    }
  }
`;