import { gql } from "@apollo/client";

export const GET_QUOTE_NAMES = gql`
  query GetQuoteNames {
    BidQuotes(where: {JobId: {_is_null: true}}) {
      Id
      JobNumber
      BidDescription
      Customer {
        Id
        ShortCode
        Name
      }
      BidQuoteExternalRevisions {
        ExternalRevision
        Id
      }
      BidQuoteStatus(limit: 1, order_by: {CreateDate: desc}) {
        Id
        Status
      }
    }
  }`;

export const GET_JOB_QUOTE = gql`
query GetJobQuote($Id: uniqueidentifier = "") {
  Jobs(where: {Id: {_eq: $Id}}) {
    Id
    Quote {
      Id
      JobNumber
      BidDescription
      Customer {
        Id
        ShortCode
        Name
      }
      BidQuoteExternalRevisions {
        ExternalRevision
        Id
      }
    }
    KickoffPacket {
      KickoffPacketProjectTypes {
        ServiceCategory {
          Name
        }
      }
    }
  }
}`;