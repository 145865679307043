// noinspection ES6PreferShortImport

import { makeAutoObservable, runInAction } from 'mobx';
import {
    AppUser,
    UpdateUserFormValues,
    UserFormValues,
    UserLoginFormValues,
} from '../models/appUser';
import Agent from '../api/agent';
import { appUserVar } from './ReactiveVariables';

export default class UserStore {
    appUser: AppUser | null = null;
    appUsers: AppUser[] | undefined = undefined;
    isLoading: boolean = true;
    userIsLoggedIn: boolean = false;
    store: any;
    agent: Agent;
    history: any;
    constructor(myAgent: Agent) {
        makeAutoObservable(this);
        this.agent = myAgent;
    }

    setStoreReference(store: any) {
        this.store = store;
    }

    setHistoryReference(history: any) {
        this.history = history;
    }

    get isLoggedIn() {
        return !!this.appUser;
    }

    login = async (creds: UserLoginFormValues) => {
        
        try {
        
            const user = await this.agent.AppUsers.login(creds);
            
            if (user?.roles?.some(e => e.name === "BidBook Admin") === false) {
                alert(
                    'You must be an BidBook Admin to gain access to this site!'
                );
                return;
            }

            this.store.commonStore.setToken(user.token!);

            runInAction(() => {
                this.appUser = user;
                appUserVar(user);
            });

            return ('/Bidbook/list/Draft');
        } catch (error) {
            throw error;
        }
    };

    logout = () => {
        this.store.commonStore.setToken(null);
        window.localStorage.removeItem('jwt');
        this.appUser = null;
        return ('/');
    };

    getUser = async () => {
        try {
            const user = await this.agent.AppUsers.current();
            if(user.isActive === true)
            {
                this.store.commonStore.setToken(user.token!)
                runInAction(() => {
                    this.appUser = user;
                    appUserVar(user);
                    this.userIsLoggedIn = true;
                    this.isLoading = true;
                });
            }
            else {
                this.logout();
                alert("Please login before continuing")
            }
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => (this.isLoading = false)); 
        }
    };

    getUsers = async () => {
        try {
            const users = await this.agent.AppUsers.list();
 
            if (!users) return;

            runInAction(() => {
                this.appUsers = users;
            });
        } catch (e) {
            console.log('error is', e);
        }
    };

    register = async (creds: UserFormValues, isAddingEmployee: boolean) => {
        try {
            const user = await this.agent.AppUsers.register(creds);
            this.store.commonStore.setToken(user.token!);
            runInAction(() => (this.appUser = user));
            if (!isAddingEmployee) {
                return ('/');
            }
        } catch (error) {
            throw error;
        }
    };

    create = async (creds: UserFormValues) => {
        try {
            return await this.agent.AppUsers.register(creds);
        } catch (error) {
            throw error;
        }
    };

    update = async (creds: UpdateUserFormValues, id: string) => {
        try {
            await this.agent.AppUsers.update(creds, id);
        } catch (error) {
            throw error;
        }
    };

    setIsLoaded = () => {
        runInAction(() => (this.isLoading = false));
    };
}
