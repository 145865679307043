import { Button, ButtonProps, styled } from '@mui/material'
import { Colors } from "../../constants/colors";

const ColorButton = styled(Button)((props) => ({
    color: 'white',
    backgroundColor: Colors.deepSeaGreen,
    "&:hover": {
        backgroundColor: Colors.lightSeaGreen,
    },
}));

type HrButtonProps = {
    title?: string;
    component?: string;
} & ButtonProps
export default function HrButton({ title, children, ...otherProps }: HrButtonProps) {

    return (
        <ColorButton
            variant="contained"
            color="primary"
            {...otherProps}
        >
            {title || children}
        </ColorButton>
    );
}
