import { useFormik } from "formik";
import { ChangeEvent, useCallback } from 'react';
import { v4 } from 'uuid';
import * as yup from 'yup';
import { BidQuoteFactors } from '../../../../api/graphqlqueries/quotefactorsquery';
import debounce from '../../../../utils/debounce';
import { HrCurrencyInput } from "../../../atoms/HrCurrencyInput";
import { HrPercentInput } from "../../../atoms/HrPercentInput";
import HrTextInput from "../../../atoms/HrTextInput";
import { QuoteLineItemFormGrid } from "../../../atoms/QuoteLineItemFormGrid";
import { calculateService, QuoteFactors, BidQuoteService, ServiceCalculations } from "../../../../utils/calculations/adders";
import { Colors } from "../../../../constants/colors";
import { BidQuoteRate } from "../../../../api/graphqlqueries/bidquoterates";
import { BidQuoteStatus } from "../../../../api/graphqlqueries/getstatus";
import { InputAdornment } from "@mui/material";
import HrCheckBox from "../../../atoms/HrCheckBox";

interface QuoteService {
    Average?: number;
    BidQuoteItemId?: string;
    Days?: number;
    Discount?: number;
    Equipment?: number;
    Id?: string;
    Locations?: number;
    Mileage?: number;
    Mob?: number;
    MobWoPD?: number;
    PerDiem?: boolean;
    Rate?: number;
    OverrideRate?: boolean;
    BidQuoteRateId?: string | null;
    BidQuoteRate?: BidQuoteRate |null;
    Personnel?: number;
    Rest?: number;
    Setup?: number;
    Type?: string;
    Unit?: string;
    status?: BidQuoteStatus | null;
}

interface QuoteServiceRateFormProps {
    item?: QuoteService;
    area: string;
    onSave: (item: QuoteService) => void;
    factorData: BidQuoteFactors;
    status: string;
}

const QuoteServiceRateSchema = yup.object().shape({
    Average: yup.number(),
    Days: yup.number(),
    Discount: yup.number(),
    Equipment: yup.number(),
    Locations: yup.number(),
    Mileage: yup.number(),
    Mob: yup.number(),
    MobWoPD: yup.number(),
    PerDiem: yup.boolean(),
    Rate: yup.number(),
    OverrideRate: yup.boolean(),
    Personnel: yup.number(),
    Rest: yup.number(),
    Setup: yup.number(),
    Unit: yup.string(),
});


const width = {
    small: "8ch",
    medium: "12ch",
    large: "18ch",
    xLarge: "24ch"
}

export const QuoteServiceRateForm = ({ item: quoteItem, onSave, area, factorData, status }: QuoteServiceRateFormProps) => {
    const Id = quoteItem?.Id ?? v4();
    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: QuoteServiceRateSchema,
        initialValues: {
            Average: quoteItem?.Average ?? 0,
            Days: quoteItem?.Days ?? 0,
            Equipment: quoteItem?.Equipment ?? 0,
            Locations: quoteItem?.Locations ?? 0,
            Mileage: quoteItem?.Mileage ?? 0,
            Mob: quoteItem?.Mob ?? 0,
            MobWoPD: quoteItem?.MobWoPD ?? 0,
            PerDiem: quoteItem?.PerDiem ?? true,
            Rate: quoteItem?.Rate ?? 0,
            Personnel: quoteItem?.Personnel ?? 0,
            Rest: quoteItem?.Rest ?? 0,
            Setup: quoteItem?.Setup ?? 0,
            Unit: quoteItem?.Unit ?? '',
            Discount: quoteItem?.Discount ?? 0,
            OverrideRate: quoteItem?.OverrideRate ?? false,
            BidQuoteRateId: quoteItem?.BidQuoteRateId ?? null,
        },
        onSubmit: (values) => {
            const data = QuoteServiceRateSchema.cast(values);
            onSave({ Id, ...data });
            formik.setSubmitting(false);
        }
    });

    const updateData = (data: any) => {
        onSave({ Id, ...data });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (formik.values[e.target.name as keyof typeof formik.values]?.toString() === e.target.value) return;
        try {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value};
            const values = QuoteServiceRateSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.handleChange(e);
    }

    const handlePercentChange = (e: ChangeEvent<HTMLInputElement>) => {
        const cleanNumberString = e.target.value.replace('%', '');
        const numberValue = parseFloat(cleanNumberString);
        if (isNaN(numberValue)) return;
        try {
            const updatedValues = { ...formik.values, [e.target.name]: numberValue / 100 };
            const values = QuoteServiceRateSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.setFieldValue(e.target.name, numberValue / 100);
    }

    // eslint-disable-next-line
    const debouncer = useCallback(debounce((values: any) => updateData(values), 1000), []);

    const inputProps = {
        sx: {
            fontSize: "14px"
        }
    }

    const handleOverrideChange = () => {
      if(status === 'Draft' || 'Pending')
      {
        try {
            const updatedValues = { ...formik.values, OverrideRate: !formik.values.OverrideRate};
            const values = QuoteServiceRateSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.setFieldValue('OverrideRate', !formik.values.OverrideRate);
      }
    }

    const serviceCalculations: ServiceCalculations = calculateService(quoteItem as BidQuoteService, factorData as QuoteFactors, area);
    return (
        <QuoteLineItemFormGrid>
            <HrTextInput
                name='Type'
                label='Type'
                width={width.xLarge}
                value={quoteItem?.BidQuoteRate?.Name ?? ''}
                disabled
            />
            <HrTextInput
                name='Total'
                label='Total Days'
                width={width.small}
                disabled
                value={serviceCalculations.TotalDays}
            />
            <HrCurrencyInput
                label='Total Price'
                width={width.medium}
                value={serviceCalculations.TotalPrice.toString()}
                disabled
            />
            <HrCurrencyInput
                name='Rate'
                label='Rate'
                onChange={quoteItem?.BidQuoteRate ? handleChange : undefined}
                value={formik.values.OverrideRate ? formik.values.Rate : quoteItem?.BidQuoteRate?.Rate ?? ''}
                width={width.medium}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={ status === "Draft" ? {
                    ...inputProps,
                    endAdornment: 
                    (
                        <InputAdornment position="end">
                            <HrCheckBox
                                name="OverrideRate"
                                checked={formik.values.OverrideRate}
                                onChange={handleOverrideChange}
                                sx={{
                                    m: "0 -8px 0 0",
                                    "& .MuiCheckbox-root": {
                                        p: 0
                                    }
                                }}
                            />
                        </InputAdornment>
                    )
                } : undefined}
                errors={formik.errors.Rate}
                disabled={!formik.values.OverrideRate}
            />
            <HrPercentInput
                name='Discount'
                label='Discount'
                width={width.small}
                onChange={handlePercentChange}
                value={formik.values.Discount}
                errors={formik.errors.Discount}
                disabled={status !== "Draft" && status !== "Pending"}
            />
            <HrCurrencyInput
                label='Total w/ Discount'
                width={width.medium}
                value={serviceCalculations.TotalDiscountPrice?.toString()}
                sx={{
                    "& .MuiInputBase-root": {
                        backgroundColor: quoteItem?.Discount !== 0 ? `${Colors.discountYellow}!important` : "inherit"
                    }
                }}
                disabled
            />
            <HrCurrencyInput
                label='Price/Location'
                width={width.medium}
                value={serviceCalculations.UnitPrice?.toString()}
                disabled
            />
        </QuoteLineItemFormGrid>
    );
};