import React, { useEffect, useState } from "react";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material";
import { useStore } from "./stores/StoreContext";
import { Outlet, Route, Routes } from "react-router";
import RegisterPage from "./components/pages/RegisterPage";
import LoginPage from "./components/pages/LoginPage";
import { observer } from "mobx-react-lite";
import PrivacyPolicyPage from "./components/pages/PrivacyPolicyPage";
import { createApolloClient } from "./api/apolloclient";
import { ApolloProvider } from "@apollo/client";
import { Loading } from './components/organisms/Loading';
import { Notification } from './components/organisms/Notification';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, CircularProgress } from "@mui/material";
import { AppPage } from "./components/pages/AppPage";
import { LicenseInfo } from '@mui/x-license-pro';

function App() {
    const { commonStore, userStore } = useStore();
    const [client] = useState(createApolloClient());
    useEffect(() => {
        if (commonStore.token) {
            userStore.getUser().finally(() => commonStore.setAppLoaded());
        } else {
            commonStore.setAppLoaded();
        }
    }, [commonStore, userStore]);
    LicenseInfo.setLicenseKey('1afe7fab71d6fa867d11dce1b91072ddTz03MjA1NixFPTE3MjI3MTEzODgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
    if (!commonStore.appLoaded) {
        return (
            <Box style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
                height: "100vh",
                background: 'no-repeat bottom/100% url("/images/mountain-logo-large.png")',
            }}>
                <Box>
                    <h3>Loading...</h3>
                    <CircularProgress size={200}/>
                </Box>
            </Box>
        );
    }
    return (
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
                        <Routes>
                            <Route path={"/register"} element={<RegisterPage />} />
                            <Route path={"/login"} element={<LoginPage />} />
                            <Route path={"/*"} element={<Outlet />}>
                                <Route index element={<PrivacyPolicyPage />} />
                                <Route path={"*"} element={<AppPage />} />
                            </Route>
                        </Routes>
                        <Loading />
                        <Notification />
                    </Box>
                </LocalizationProvider>
            </ThemeProvider>
        </ApolloProvider>
    );
}

declare module '@mui/material/styles' {
    interface TypographyVariants {
        hrSubheading: React.CSSProperties;
    }
    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        hrSubheading?: React.CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        hrSubheading: true;
    }
}

const theme = createTheme({
    palette: {
        common: {
            black: "#000",
            white: "#fff",
        },
        background: {
            paper: "#fff",
            default: "#fafafa",
        },
        primary: {
            light: "rgba(62, 115, 115, 1)",
            main: "rgba(33, 77, 77, 1)",
            dark: "rgba(18, 59, 59, 1)",
            contrastText: "#fff",
        },
        secondary: {
            light: "rgba(233, 201, 93, 1)",
            main: "rgba(212, 175, 55, 1)",
            dark: "rgba(179, 143, 29, 1)",
            contrastText: "#fff",
        },
        error: {
            light: "rgba(235, 127, 127, 1)",
            main: "rgba(226, 62, 62, 1)",
            dark: "rgba(199, 26, 26, 1)",
            contrastText: "#fff",
        },
        text: {
            primary: "rgba(0, 0, 0, 0.87)",
            secondary: "rgba(0, 0, 0, 0.54)",
            disabled: "rgba(0, 0, 0, 0.87)",
        },
    },
    typography: {
        fontFamily : ["Montserrat", "sans-serif"].join(","),
        hrSubheading : {
            color: "rgba(33,77,77,1)",
            padding: "0",
            margin: "0px",
            fontFamily: "Montserrat, Arial",
            fontSize: "24px",
            fontWeight: "700"
        }
    },
});

export default observer(App);
