import { Tab, TabProps } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import { Colors } from "../../constants/colors";
type Props = {
    to: string;
} & TabProps;

const TabNavButtonUnstyled: React.FC<Props> = (props: Props) => {
    return (
        <Tab
            component={Link}
            {...props as any}
        />
    );
};

const TabNavButton = styled(TabNavButtonUnstyled)(({ theme }) => ({
    minWidth: 80,
    minHeight: (theme.typography as any).pxToRem(18),
    textTransform: 'none',
    backgroundColor: Colors.deepSeaGreen,
    color: Colors.unselectedGray,
    fontWeight: (theme.typography as any).fontWeightRegular,
    fontSize: (theme.typography as any).pxToRem(15),
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    opacity: 1,
    '&.Mui-selected': {
        backgroundColor: Colors.gold,
        color: Colors.black
    },
}));

export const BlankNavButton = styled(TabNavButtonUnstyled)(({ theme }) => ({
    minWidth: 80,
    minHeight: (theme.typography as any).pxToRem(18),
    opacity: 0,
}));
export default TabNavButton;
