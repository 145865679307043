import { gql } from "@apollo/client/core";

export interface GetClientRepsQueryResult {
  CustomerContacts: CustomerContact[];
}

export interface CustomerContact {
  Email: string;
  FirstName: string;
  Id: string;
  LastName: string;
  Title: string;
  Phone: string;
}

export const GET_CLIENT_REPS_QUERY = gql`
  query GetClientReps($CustomerId: uniqueidentifier = "") {
    CustomerContacts(where: {CustomerId: {_eq: $CustomerId}, IsActive: {_eq: true}}) {
      Email
      FirstName
      Id
      LastName
      Title
      Phone
    }
  }
`;