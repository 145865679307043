import React from "react";
import { Box } from "@mui/material";
import { ClientPPEForm } from "./ClientPPEForm";
import { gql, useMutation, useQuery } from "@apollo/client";
import { v4 } from "uuid";
import { addMessage } from "../../../stores/ReactiveVariables";


interface CleintPPEProps {
    id: string;
}

interface PPEItem {
    PPE: string,
    Id: string,
    Ordinal: number,
}

const GET_PPE_ITEM = gql`
  query GetPPEItem($Id: uniqueidentifier = "") {
    CustomerPPE(where: {CustomerId: {_eq: $Id}}, order_by: {Ordinal: asc}) {
        PPE
        Id
        Ordinal
    }
}
  `;

const INSERT_PPE_ITEM = gql`
mutation insertPPE($object: CustomerPPE_insert_input = {}) {
  insert_CustomerPPE_one(object: $object) {
    CustomerId
    Id
    Ordinal
    PPE
  }
}

`;

const DELETE_PPE_ITEM = gql`
    mutation DeletePPEItem($Id: uniqueidentifier = "", $CustomerId: uniqueidentifier = "", $Ordinal: Int = 0) {
        delete_CustomerPPE_by_pk(Id: $Id) {
            Id
        }
        update_CustomerPPE(where: {CustomerId: {_eq: $CustomerId}, Ordinal: {_gt: $Ordinal}}, _inc: {Ordinal: -1}) {
            returning {
                Id
                Ordinal
            }
        }
    }
`;

export const ClientPPE: React.FC<CleintPPEProps> = (props: CleintPPEProps) => {
    const { id } = props;

    const { data, error } = useQuery(GET_PPE_ITEM, { variables: { Id: id } });

    const [insertPPEItem] = useMutation(INSERT_PPE_ITEM);

    const [deletePPEItem] = useMutation(DELETE_PPE_ITEM);

    const formData = data?.CustomerPPE ?? [];
    if (error) console.log(error);

    const onSave = async (values: any) => {
        insertPPEItem({
            variables: {
                object: {
                    Id: v4(),
                    CustomerId: id,
                    PPE: values.PPE,
                    Ordinal: formData.length + 1
                }
            },
            refetchQueries: [GET_PPE_ITEM],
            onCompleted: () => {
                addMessage("PPE Item Added", { severity: "success" });
            }
        })
    }

    const onDelete = (item: PPEItem) => {
        deletePPEItem({
            variables: {
                Id: item.Id,
                CustomerId: id,
                Ordinal: item.Ordinal
            },
            refetchQueries: [GET_PPE_ITEM],
            onCompleted: () => {
                addMessage("PPE Item Deleted", { severity: "success" });
            }
        })
    }

    return (
        <Box>
            <ClientPPEForm id={id} data={formData} onSave={onSave} onDelete={(item: PPEItem) => onDelete(item)} />
        </Box>
    )
}