import { Box } from "@mui/material";
import { BoxProps } from "@mui/system";
import { Colors } from '../../constants/colors';
import { styled }from '@mui/system';

type QuoteLineItemBoxProps = { 
    children : React.ReactNode,
    sx? : Object
} & BoxProps;

const LineItemBox = styled(Box)({
    paddingBottom: "3px", 
    marginBottom:"3px",
    width:"100%",
    borderBottom:`1px dashed ${Colors.goldLighter}`
});

export const QuoteLineItemBox = (props : QuoteLineItemBoxProps) => {
    return(
        <LineItemBox 
            {...props}
        >
            {props.children}
        </LineItemBox>
    )
}