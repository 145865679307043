import { Autocomplete, Box, Button, Divider, Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useReactToPrint } from 'react-to-print';
import * as yup from "yup";
import { Job } from "../../../models/job";
import { useStore } from "../../../stores/StoreContext";
import { addDays, getDateString } from "../../../utils/getDateString";
import { HrCurrencyInput } from "../../atoms/HrCurrencyInput";
import { HrPercentInput } from "../../atoms/HrPercentInput";
import HrTextInput from "../../atoms/HrTextInput";
import { StyledHeader } from "../../atoms/StyledHeader";
import { ContentHeader } from "../ContentHeader";
import { JobCostsDataGrid } from "./JobCostsDataGrid";

interface Props {
    props?: any;
}

const validationSchema = yup.object({
    startDate: yup.date().required(),
    endDate: yup.date().required().min(yup.ref('startDate'), "End date can't be before start date."),
    selectedJob: yup.object({ id: yup.string() }).nullable().required("Job Selection Required"),
});

const getPageMargins = () => {
    return `@page { margin: 10px 10px 10px 10px !important; }`;
};

export const JobCostsPageContent: React.FC<Props> = observer(() => {
    const componentRef = useRef(null);
    const [gridData, setGridData] = useState([] as any[]);
    const [revenue, setRevenue] = useState<string | number>(0);
    const [perDiemRevenue, setPerDiemRevenue] = useState<string | number>(0);
    const [mileageRevenue, setMileageRevenue] = useState<string | number>(0);
    const { jobStore } = useStore();
    const { agent } = jobStore;
    const { jobRegistry, loadJobs } = jobStore;
    const currDate = new Date();

    useEffect(() => {
        if (jobRegistry.length === 0) {
            loadJobs();
        }
    });

    const updateRevenueCalcs: any = useCallback((totalRevenue: any, oldDataGrid: any) => updateRevenueCalcsFunc(totalRevenue, oldDataGrid ?? []), []);
    
    useEffect(() => {
        const currTotalRev = Number(revenue) + Number(mileageRevenue) + Number(perDiemRevenue);
        setGridData(oldData => updateRevenueCalcs(currTotalRev, oldData));
    }, [revenue, perDiemRevenue, mileageRevenue, updateRevenueCalcs]);

    const formik = useFormik({
        validationSchema,
        initialValues: {
            startDate: new Date(),
            endDate: new Date(),
            selectedJob: null as unknown as Job,
        },
        onSubmit: (values, { setSubmitting }) => {
            const castValues = validationSchema.cast(values);
            const endDateValue = addDays(castValues.endDate, 1);
            const startDate = getDateString(castValues.startDate);
            const endDate = getDateString(endDateValue);
            const jobId = castValues?.selectedJob?.id;
            if (startDate && endDate && jobId) {
                agent.Reports.jobcost(startDate, endDate, jobId).then((data: any) => {
                    setGridData(() => updateRevenueCalcs(Number(revenue) + Number(mileageRevenue) + Number(perDiemRevenue), data?.value ?? []));
                    setSubmitting(false);
                })
            } else {
                setSubmitting(false);
            }
        }
    })

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `${currDate.getMonth() + 1}${currDate.getDate()}_${currDate.getFullYear()}_${formik?.values?.selectedJob?.name ?? 'NoJob'}`
    });

    const totals = gridData.reduce((pv, cv: any) => {
        pv.laborCost = pv.laborCost + cv.laborCost;
        pv.perDiemCost = pv.perDiemCost + cv.perDiemCost;
        pv.totalMileageCost = pv.totalMileageCost + cv.totalMileageCost;
        pv.expenseCost = pv.expenseCost + cv.expenseCost
        return pv;
    }, { laborCost: 0, perDiemCost: 0, totalMileageCost: 0, expenseCost: 0});
    const mileageCost = totals.totalMileageCost;
    const additionalCostTotal = totals.perDiemCost + mileageCost;
    const subTotalCost = totals.laborCost + additionalCostTotal;
    const totalCost = totals.expenseCost + subTotalCost;
    const additionalRevenue = Number(perDiemRevenue) + Number(mileageRevenue);
    const totalRevenue = Number(revenue) + additionalRevenue;
    const grossProfit = totalRevenue - totalCost;
    const grossProfitPct = totalRevenue === 0 ? 0 : grossProfit / totalRevenue;
    function updateRevenueCalcsFunc(totalRevenueVal: number, oldGridData: any[]) {
        const totalHours = oldGridData?.reduce((pv, cv: any) => pv + cv.totalHours, 0);
        const newGridData = oldGridData?.map(gd => {
            const empRevenue = totalRevenueVal * gd.totalHours / totalHours;
            const profit = empRevenue - (gd.laborCost + gd.perDiemCost + gd.totalMileageCost);
            const profitPct = profit / empRevenue;
            return {
                ...gd,
                empRevenue,
                profit,
                profitPct
            };
        });
        return newGridData;
    }
    return (
        <Box ref={componentRef}>
            <style>{getPageMargins()}</style>
            <ContentHeader
                title="Gross Margin Report"
                addButtonText="Print"
                onClick={handlePrint}
            />
            <Grid container direction="row" spacing={2}>
                <Grid item xs={3}>
                    <DatePicker
                        renderInput={(params) => <HrTextInput {...params} fullWidth errors={formik.errors.startDate as any} />}
                        onChange={(value: any) =>{
                            return formik.setFieldValue("startDate", value);
						}}
                        value={formik.values.startDate}
                        label="Start Date"
                    />
                </Grid>
                <Grid item xs={3}>
                    <DatePicker
                        renderInput={(params) => <HrTextInput {...params} fullWidth errors={formik.errors.endDate as any} />}
                        onChange={(value: any) =>{
                            return formik.setFieldValue("endDate", value)
						}}
                        value={formik.values.endDate}
                        label="End Date"
                    />
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        id="job"
                        options={jobRegistry}
                        value={formik.values.selectedJob}
                        getOptionLabel={option => option.name}
                        renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                        onChange={(event, value) => {
                            formik.setFieldValue("selectedJob", value);
                        }}
                        //style={{width: 340, marginBottom: '.4em', marginTop: '.2em'}}
                        renderInput={(params) => (
                            <HrTextInput {...params} fullWidth label="Job" variant="outlined" />
                        )}
                    />
                    {formik.errors.selectedJob && (
                        <label style={{ color: 'red' }}>{formik.errors.selectedJob as string}</label>
                    )}
                </Grid>
                <Grid item xs={3}>
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        onClick={() => formik.handleSubmit()}
                    >
                        {!formik.isSubmitting ? "Run Costs Report" : "Loading..."}
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                <Divider style={{ margin: '10px 10px', backgroundColor: 'black' }} />
                <StyledHeader>Job Revenue</StyledHeader>
            </Grid>
            <Grid item container spacing={1}>
                <Grid item xs={4}>
                    <HrCurrencyInput value={revenue} onChange={e => setRevenue(e.target.value)} fullWidth label="Revenue" />
                </Grid>
                <Grid container item xs={4} spacing={1}>
                    <Grid item xs={12}>
                        <HrCurrencyInput value={additionalRevenue.toString()} fullWidth label="Additional Revenue" disabled />
                    </Grid>
                    <Grid item xs={12}>
                        <HrCurrencyInput value={perDiemRevenue} onChange={e => setPerDiemRevenue(e.target.value)} fullWidth label="Per Diem Revenue" />
                    </Grid>
                    <Grid item xs={12}>
                        <HrCurrencyInput value={mileageRevenue} onChange={e => setMileageRevenue(e.target.value)} fullWidth label="Mileage Revenue" />
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <HrCurrencyInput value={totalRevenue.toString()} fullWidth label="Total Revenue" disabled />
                </Grid>
            </Grid>
            <Grid item>
                <Divider style={{ margin: '10px 10px', backgroundColor: 'black' }} />
                <StyledHeader>Job Costs</StyledHeader>
            </Grid>
            <Grid item container spacing={1}>
                <Grid item xs={4}>
                    <HrCurrencyInput value={totals.laborCost.toString()} fullWidth label="Labor Cost" disabled />
                </Grid>
                <Grid container item xs={4} spacing={1}>
                    <Grid item xs={12}>
                        <HrCurrencyInput value={additionalCostTotal.toString()} fullWidth label="Additional Cost" disabled />
                    </Grid>
                    <Grid item xs={12}>
                        <HrCurrencyInput value={totals.perDiemCost.toString()} fullWidth label="Per Diem Cost" disabled />
                    </Grid>
                    <Grid item xs={12}>
                        <HrCurrencyInput value={mileageCost.toString()} fullWidth label="Mileage Cost" disabled />
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <HrCurrencyInput value={subTotalCost.toString()} fullWidth label="Sub Total Cost" disabled />
                </Grid>
            </Grid>
            <Grid container item spacing={1}>
                <Grid item xs={4} />
                <Grid item container xs={8} spacing={1}>
                    <Grid item xs={12}>
                        <Divider style={{ margin: '10px 10px', backgroundColor: 'black' }} />
                    </Grid>
                    <Grid item container xs={12} spacing={1}>
                        <Grid item xs={6}>
                            <HrCurrencyInput value={totals.expenseCost.toString()} fullWidth label="Expenses Cost" disabled />
                        </Grid>
                        <Grid item xs={6}>
                            <HrCurrencyInput value={totalCost.toString()} fullWidth label="Total Cost" disabled />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider style={{ margin: '10px 10px', backgroundColor: 'black' }} />
                    </Grid>
                    <Grid item container xs={12} spacing={1}>
                        <Grid item xs={6} />
                        <Grid item xs={6}>
                            <HrCurrencyInput value={grossProfit.toString()} fullWidth label="Gross Profit" disabled />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={1}>
                        <Grid item xs={6} />
                        <Grid item xs={6}>
                            <HrPercentInput value={grossProfitPct} fullWidth label="Profit Percent" disabled />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Divider style={{ margin: '10px 10px', backgroundColor: 'black' }} />
            </Grid>
            <Box sx={{ height: '30vh' }}>
                <JobCostsDataGrid data={gridData} />
            </Box>
        </Box>
    );
});