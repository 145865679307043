import { List, ListItem, Typography } from "@mui/material";
import { ClientPpeForm } from "./ClientPpeForm";
import { KickoffPacketSection } from "./KickoffPacketSection";
import { gql, useQuery } from "@apollo/client";

interface ClientPpeProps{
    customerId?: string;
}

interface ClientPpeItem {
    CustomerId: string,
    PPE: string,
    Id: string,
    Ordinal: number,
}

interface GetClientPpeData {
    CustomerPPE: ClientPpeItem[];
}

const GET_CLIENT_P_P_E_QUERY = gql`
  query GetClientPPE($CustomerId: uniqueidentifier = "") {
    CustomerPPE(where: {CustomerId: {_eq: $CustomerId}}, order_by: {Ordinal: asc}) {
      CustomerId
      Ordinal
      Id
      PPE
    }
  }
`;

export const ClientPpe: React.FC<ClientPpeProps> = ({ customerId }: ClientPpeProps) => {

    const { data, error } = useQuery<GetClientPpeData>(GET_CLIENT_P_P_E_QUERY, { variables: { CustomerId: customerId } });

    if (error) console.log(error);

    if (!data) return null;

    return(
        <KickoffPacketSection heading="Client Required PPE">
            <List disablePadding>
                {
                    data.CustomerPPE.map((item, index) => (
                        <ListItem divider key={item.Id}>
                            <Typography variant="body1">{item.PPE}</Typography>
                        </ListItem>
                    ))

                }
            </List>
        </KickoffPacketSection>
    ) 
}