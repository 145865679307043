import { HrTextInputProps } from "../atoms/HrTextInput";
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { styled, TextField, TextFieldProps, FormControl } from "@mui/material";
import { Colors } from "../../constants/colors";
import { useReactiveVar } from "@apollo/client";
import { disableForStatus } from "../../stores/ReactiveVariables";


type PercentInputProps = {
    decimalScale?: number;
} & NumericFormatProps & TextFieldProps & HrTextInputProps;

const PercentInput = (props: PercentInputProps) => {
    let displayValue = props.value === '' || isNaN(Number(props.value)) ? props.value : (Number(props.value) * 100);
    const disabledForStatus = useReactiveVar(disableForStatus);
    return (
        <FormControl>
            <NumericFormat
                {...props}
                value={displayValue}
                allowLeadingZeros
                thousandSeparator=","
                decimalScale={props.decimalScale ?? 2}
                suffix={'%'}
                style={props?.style}
                disabled={props?.disabled || disabledForStatus}
                size={props.size ?? 'small'}
                onBlur={props?.onBlur}
                onChange={props.onChange}
                width={props?.width}
                customInput={TextField}
            />
        </FormControl>
    )
}

export const HrPercentInput = styled(PercentInput)(({ width }) => ({
    '.MuiInputBase-root': {
        fontWeight: 500,
        'legend': {
            fontSize: ".95rem"
        }
    },
    "& label.Mui-focused": {
        color: Colors.darkSeaGreen,
    },
    "& label": {
        color: Colors.darkSeaGreen,
        fontFamily: "Montserrat"
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: Colors.darkSeaGreen,
    },
    '.MuiInputLabel-shrink ': {
        transform: "translate(14px, -11px) scale(.75)!important",
        fontSize: "1.3rem",
        //fontWeight:500,
    },
    "& .MuiOutlinedInput-root": {
        fontFamily: "Montserrat",
        "& fieldset": {
            borderColor: Colors.darkSeaGreen,
        },
        "&:hover fieldset": {
            borderColor: Colors.darkSeaGreen,
        },
        "&.Mui-focused fieldset": {
            borderColor: Colors.darkSeaGreen,
        },
        "&.Mui-error fieldset": {
            borderColor: Colors.errorRed,
        }
    },
    "& .MuiInputBase-root.Mui-disabled": {
        borderColor: Colors.darkSeaGreen,
        backgroundColor: Colors.unselectedGray,
        color: Colors.black,
        webkitTextFillColor: Colors.black,
        "&.Mui-error": {
            "&:hover fieldset": {
                borderColor: Colors.errorRed,
            },
            "& fieldset": {
                borderColor: Colors.errorRed,
            },
        },
        "& fieldset": {
            borderColor: Colors.darkSeaGreen,
        },
        "&:hover fieldset": {
            borderColor: Colors.darkSeaGreen,
        },
        "&.Mui-focused fieldset": {
            borderColor: Colors.darkSeaGreen,
        },

    },
    "& label.Mui-disabled": {
        color: "black",
    },
    width: width
}));