import { gql } from "@apollo/client/core";
import * as uuid from 'uuid';

export const getVariables = (BidQuoteId: string, Comment: string, CreateUserId: string) =>
{
    const Id = uuid.v4();
    return {
        BidQuoteId,
        Comment,
        Id,
        CreateUserId
    };
}

export const CREATE_COMMENT_MUTATION = gql`
  mutation CreateComment($BidQuoteId: uniqueidentifier = "", $Comment: String = "", $CreateUserId: String = "", $Id: uniqueidentifier = "") {
    insert_BidComment_one(object: {BidQuoteId: $BidQuoteId, Comment: $Comment, CreateUserId: $CreateUserId, Id: $Id, UpdateUserId: $CreateUserId}) {
      __typename
      BidQuoteId
      Id
      Comment
      CreateUser {
        Id
        FirstName
        LastName
      }
    }
  }
`;