import { Typography, Box } from "@mui/material"

interface JobPlanSubSectionProps{
    subheading: string;
    subsectionNote?: string;
    children: React.ReactNode;
}

export const JobPlanSubSection: React.FC<JobPlanSubSectionProps> = ({subheading, subsectionNote, children}: JobPlanSubSectionProps) => {
    return(
        <Box sx={{ mb: 1, mt: 2 }}>
            <Typography 
                variant="h6" 
                sx={{
                    display: "inline-block",
                    mr: 1.5,
                    fontSize:"15px",
                    color:"#444"
                }}
            >
                {subheading}
            </Typography>
            <Typography variant="caption" sx={{ fontStyle: 'italic' }}>{subsectionNote}</Typography>
            { children }
        </Box>
    )
}