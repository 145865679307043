import { GridColDef } from "@mui/x-data-grid-pro";
import { NavLink, useNavigate } from "react-router-dom";
import React from "react";
import RemoveButton from "../../atoms/RemoveButton";
import { HrDataGrid } from "../../molecules/HrDataGrid";
import { gql, useQuery } from "@apollo/client";
import ArticleIcon from '@mui/icons-material/Article';
import moment from "moment";

export interface KickoffPacketDataGridProps {
    props?: any;
}

const GET_KICKOFF_PACKETS_QUERY = gql`
    query GetKickoffPacketsQuery {
        KickoffPacket {
            Id
            JobId
            BidQuoteId
            ProjectKickoffDate
            KickoffLocation
            ProjectName
            Customer{
                Id
                Name
            }
        }
    }  
`;

export const KickoffPacketDataGrid: React.FC<KickoffPacketDataGridProps> = () => {
    const { data } = useQuery(GET_KICKOFF_PACKETS_QUERY, { fetchPolicy: 'cache-and-network' });

    const rows : any[] = data?.KickoffPacket ?? [];

    const navigate = useNavigate();

    const columns: GridColDef[] = [
        {
            field: "deleteKickoffPacket",
            headerName: " ",
            width: 50,
            renderCell: (rows) => {
                const jobId = rows.id;
                return (
                    <RemoveButton
                        title="Job"
                        onClick={() => {
                            //deleteJob(jobId as string);
                        }}
                    />
                );
            },
            headerClassName: "billables-grid-header",
        },
        {
            field: "ProjectName",
            headerName: "Project Name",
            width: 300,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "Customer",
            headerName: "Customer Name",
            width: 300,
            editable: false,
            headerClassName: "billables-grid-header",
            valueFormatter: (params) => params?.value?.Name
        },
        {
            field: "JobId",
            headerName: "Job",
            type: "string",
            width: 80,
            headerClassName: "billables-grid-header",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                if (params.row?.JobId) { 
                    return <NavLink to={`/Jobs/${params.row?.JobId.toLowerCase()}`}><ArticleIcon color="primary" /></NavLink>;
                } else {
                    return null
                }
            }
        },
        {
            field: "BidQuoteId",
            headerName: "Quote",
            type: "string",
            width: 80,
            headerClassName: "billables-grid-header",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                if (params.row?.BidQuoteId) {
                    return <NavLink to={`/Bidbook/details/${params.row?.BidQuoteId}`}><ArticleIcon color="primary" /></NavLink>;
                } else {
                    return null
                }
            }
        },
        {
            field: "KickoffLocation",
            headerName: "Kickoff Location",
            width: 250,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "ProjectKickoffDate",
            headerName: "Kickoff Date",
            width: 250,
            editable: false,
            headerClassName: "billables-grid-header",
            type: "datetime",
            valueFormatter: (params) => params.value ? moment(params.value).format("MMMM Do YYYY") : null
        },
    ];
    return (
        <HrDataGrid
            rows={rows}
            getRowId={(row) => row.JobId}
            columns={columns}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            onRowDoubleClick={(row) => navigate(`/Jobs/${row.id.toString().toLowerCase()}/Kickoff`)}
            initialState={{
                columns: {
                    columnVisibilityModel: {

                    }
                }
            }}
        />
    );
};
