import { Box } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { v4 } from 'uuid';
import * as yup from 'yup';
import { FormGrid } from '../../../atoms/FormGrid';
import HrButton from '../../../atoms/HrButton';
import HrTextInput from '../../../atoms/HrTextInput';
import { StyledHeader } from '../../../atoms/StyledHeader';

interface ServiceCategoryItem {
    Id: string;
    Name: string;
}
interface ServiceCategoryFormProps {
    item?: ServiceCategoryItem;
    onSave: (item: ServiceCategoryItem) => void;
}

const validationSchema = yup.object().shape({
    Name: yup.string().required('Service Category Name is required')
});

export const ServiceCategoryForm: React.FC<ServiceCategoryFormProps> = ({ item, onSave }) => {
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            Name: item?.Name ?? '',
        },
        onSubmit: (values: any) => {
            const castValues = validationSchema.cast(values);
            onSave({ Id: item?.Id ?? v4(), Name: castValues.Name! })
        }
    })
    return (
        <Box sx={{ width: '100%' }}>
            <StyledHeader sx={{textAlign:"center"}}>Edit Service Category</StyledHeader>
            <form onSubmit={formik.handleSubmit}>
                <FormGrid>
                    <HrTextInput
                        name='Name'
                        label='Name'
                        onChange={formik.handleChange}
                        value={formik.values.Name}
                        errors={formik.errors.Name}
                    />
                    <HrButton
                        type='submit'
                        title='Submit'
                    />
                    {/* <HrButton
                        title='Close'
                        onClick={onCancel}
                    /> */}
                </FormGrid>
            </form>
        </Box>
    );
};
