import React from 'react';
import { StyledHeader } from '../../atoms/StyledHeader';
import { DataGridPro } from '@mui/x-data-grid-pro';

interface JobSegment {
    Id: string;
    Segment: string;
    Ordinal: number;
    Type: string;
}
interface CreateJobSegmentsListProps {
    segments: JobSegment[];
}

export const CreateJobSegmentsList: React.FC<CreateJobSegmentsListProps> = ({ segments }: CreateJobSegmentsListProps) => {
    const columns = [
        {
            field: "Segment",
            headerName: "Segment",
            width: 250,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "Type",
            headerName: "Segment Type",
            width: 120,
            editable: false,
            headerClassName: "billables-grid-header",
        }
    ];
    if(segments.length == 0) return null;
    return (
        <>
            <StyledHeader sx={{ textAlign: 'Center' }}>Quote Segments</StyledHeader>
            <DataGridPro
                autoHeight
                rows={segments}
                initialState={{
                    pagination: { paginationModel: { pageSize: 5 } },
                }}
                columns={columns}
                getRowId={(row: any) => row.Id}
                density='compact'
            />
        </>
    )
};