import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { CustomerContactItemForm } from "./CustomerContactForm";
import { CREATE_CUSTOMER_CONTACT_MUTATION } from "../../../api/graphqlqueries/createcustomercontactmutation";
import { GetSingleContactQueryResult, GET_SINGLE_CONTACT_QUERY } from "../../../api/graphqlqueries/getsinglecustomercontactquery";
import { GET_CUSTOMER_CONTACTS_QUERY } from "../../../api/graphqlqueries/getcustomercontactsquery";
import { UPDATE_CUSTOMER_CONTACT_MUTATION } from "../../../api/graphqlqueries/updatecustomercontactsmutation";
import { GET_CLIENT_REPS_QUERY } from "../../../api/graphqlqueries/getclientrepsquery";

interface CustomerContactItemProps {
    customerId?: string;
    id?: string;
    onSave?: (values: any) => void;
    onCancel?: () => void;
}

export const CustomerContactItem = ({ id, customerId, onSave, onCancel }: CustomerContactItemProps) => {
    const [addCustomerContactItem] = useMutation(CREATE_CUSTOMER_CONTACT_MUTATION);
    const [getItem, { data, error }] = useLazyQuery<GetSingleContactQueryResult>(GET_SINGLE_CONTACT_QUERY, {
        variables: {
            Id: id
        },
        fetchPolicy: 'cache-and-network'
    });
    const saveNewCustomerContactItem = (values: any) => {
        const addInfo = { CustomerId: customerId, ...values };
        addCustomerContactItem({
            variables: { object: addInfo },
            refetchQueries: [
                {
                    query: GET_CUSTOMER_CONTACTS_QUERY,
                    variables: { CustomerId: customerId }
                },
                {
                    query: GET_CLIENT_REPS_QUERY,
                    variables: { CustomerId: customerId }
                }
            ]
        });
        if (onSave) onSave(values);
    };
    const [updateCustomerContactItem] = useMutation(UPDATE_CUSTOMER_CONTACT_MUTATION);
    const updateNewCustomerContactItem = (values: any) => {
        updateCustomerContactItem({ variables: { Id: values.Id, object: values } });
        if (onSave) onSave(values);
    }
    useEffect(() => {
        if (id) getItem();
    }, [id, getItem]);

    if (error) {
        console.error(error);
    }
    if (!id) return <CustomerContactItemForm onSave={saveNewCustomerContactItem} onCancel={onCancel} />
    if (!data) return <CircularProgress />;
    const contact = data.CustomerContacts_by_pk;
    return (<CustomerContactItemForm item={contact} onSave={updateNewCustomerContactItem} onCancel={onCancel} />);
}