import { useQuery, gql, useMutation } from "@apollo/client";
import { KickoffPacketSection } from "./KickoffPacketSection";
import { StaffingDetailsForm } from "./StaffingDetailsForm";
import * as uuid from 'uuid';
import { addMessage } from "../../../../stores/ReactiveVariables";

interface StaffingDetailsProps{
    id?: string;
}

const GET_KICKOFF_CREW_MEMBERS_QUERY = gql`
    query GetKickoffPacketCrewMembers($KickoffPacketId: uniqueidentifier = "") {
        KickoffPacketCrewMember(where: {KickoffPacketId: {_eq: $KickoffPacketId}}, order_by: {Ordinal: asc}) {
            Ordinal
            KickoffPacketId
            Id
            CrewMemberId
            AspNetUser {
                Id
                FirstName
                LastName
                PhoneNumber
                Email
            }
        }
    }
`

const GET_KICKOFF_STAFFING_DETAILS_QUERY = gql`
query GetKickoffPacketStaffingDetails($Id: uniqueidentifier = "") {
    KickoffPacket(where: {Id: {_eq: $Id}}) {
      CrewNotes
      KickoffPacketCrewMembers {
        Ordinal
        KickoffPacketId
        Id
        CrewMemberId
        AspNetUser {
          Id
          FirstName
          LastName
          PhoneNumber
          Email
        }
      }
    }
  }
`;

const INSERT_KICKOFF_CREW_MEMBER = gql`
    mutation InsertKickoffCrewMember($object: KickoffPacketCrewMember_insert_input = {}) {
        insert_KickoffPacketCrewMember_one(object: $object) {
            Id
        }
    }  
`

const UPDATE_KICKOFF_STAFFING_DETAILS = gql`
mutation UpdateStaffingDetails($Id: uniqueidentifier = "", $object: KickoffPacket_set_input = {}) {
    update_KickoffPacket_by_pk(pk_columns: {Id: $Id}, _set: $object) {
      Id
    }
  }  
`

const DELETE_KICKOFF_CREW_MEMBER = gql`
    mutation DeleteKickoffPacketCrewMember($Id: uniqueidentifier = "", $KickoffPacketId: uniqueidentifier = "", $Ordinal: Int = 10) {
        delete_KickoffPacketCrewMember_by_pk(Id: $Id) {
            Id
        }
        update_KickoffPacketCrewMember(where: {KickoffPacketId: {_eq: $KickoffPacketId}, Ordinal: {_gt: $Ordinal}}, _inc: {Ordinal: -1}) {
            returning {
                Id
                Ordinal
            }
        }
    }  
`

export const StaffingDetails: React.FC<StaffingDetailsProps> = ({ id }: StaffingDetailsProps) => {
    const { data : crewMemberData, error : crewMemberDataError } = useQuery(GET_KICKOFF_CREW_MEMBERS_QUERY, {
        variables: {
            KickoffPacketId: id
        }
    });

    const { data } = useQuery(GET_KICKOFF_STAFFING_DETAILS_QUERY, {
        variables: {
            Id: id
        },
        onError: (error) => {
            addMessage('Error Getting Staffing Details', { severity: 'error' });
        }
    });

    if (crewMemberDataError) console.log(crewMemberDataError);

    const crewMembers = crewMemberData?.KickoffPacketCrewMember ?? [];

    const [addCrewMemberItem] = useMutation(INSERT_KICKOFF_CREW_MEMBER, {
        refetchQueries: [GET_KICKOFF_CREW_MEMBERS_QUERY]
    });

    const [updateStaffingDetails] = useMutation(UPDATE_KICKOFF_STAFFING_DETAILS, {
        refetchQueries: [GET_KICKOFF_CREW_MEMBERS_QUERY]
    });

    const [deleteCrewMemberItem] = useMutation(DELETE_KICKOFF_CREW_MEMBER, {
        refetchQueries: [GET_KICKOFF_CREW_MEMBERS_QUERY]
    });

    const addCrewMember = (employeeId: string) => {
        addCrewMemberItem({
            variables: {
                object: {
                    Id: uuid.v4(),
                    KickoffPacketId: id,
                    CrewMemberId: employeeId,
                    Ordinal: crewMembers?.length
                }
            }
        })
    }

    const updateItemHandler = (values: any) => {
        updateStaffingDetails({ 
            variables: { Id: id, object: values},
        });
    }

    const deleteCrewMember = (values: { CrewMemberId: string, Ordinal: number }) => {
        deleteCrewMemberItem({
            variables: {
                KickoffPacketId: id, 
                Id: values.CrewMemberId,
                Ordinal: values.Ordinal
            }
        })
    }

    return(
        <KickoffPacketSection heading="Staffing Details">
            <StaffingDetailsForm 
                crewMembers={crewMembers} 
                addCrewMember={addCrewMember} 
                deleteCrewMember={deleteCrewMember} 
                onSave={updateItemHandler}
                data={data?.KickoffPacket[0] ?? {}}
            />
        </KickoffPacketSection>
    ) 
}