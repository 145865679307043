import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { Delete } from "@mui/icons-material";
import { Autocomplete, IconButton } from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, useCallback, useState } from "react";
import { useParams } from 'react-router';
import { v4 } from 'uuid';
import * as yup from 'yup';
import { GET_QUOTE_FACTORS_QUERY } from '../../../../api/graphqlqueries/quotefactorsquery';
import debounce from '../../../../utils/debounce';
import { HrCurrencyInput } from "../../../atoms/HrCurrencyInput";
import HrTextInput from "../../../atoms/HrTextInput";
import { QuoteLineItemFormGrid } from "../../../atoms/QuoteLineItemFormGrid";
import { ConstructionItemCopyButton } from "./ConstructionItemCopyButton";
import { QuoteFactors } from "../../../../utils/calculations/adders";
import { BidQuoteItem, getSegmentCalculations, SegmentCalculations } from "../../../../utils/calculations/segments";
import { DeleteWarning } from "../../../molecules/DeleteWarning";
import { disableForStatus } from '../../../../stores/ReactiveVariables';
import HrCheckBox from '../../../atoms/HrCheckBox';
import { GET_BIDQUOTE_ITEMS_BY_TYPE } from '../../../../api/graphqlqueries/getparentbidbookitems';

interface QuoteConstructionItem {
    Id?: string;
    Type: string;
    Segment?: string;
    Location?: number;
    Area?: string;
    LineBreakAfter?: boolean;
    BidQuoteConstructionServices?: any;
    BidQuoteMaterials?: any;
    ParentBidQuoteItemId?: string | null;
}

interface QuoteConstructionItemFormProps {
    item?: QuoteConstructionItem;
    type: string;
    onSave: (item: QuoteConstructionItem) => void,
    onDelete: (id: string) => void;
}


const quoteConstructionItemSchema = yup.object().shape({
    Segment: yup.string().required(),
    Location: yup.number(),
    Area: yup.string(),
    LineBreakAfter: yup.boolean(),
    ParentBidQuoteItemId: yup.string().nullable()
});


export const QuoteConstructionItemForm = ({ item: quoteItem, type, onSave, onDelete }: QuoteConstructionItemFormProps) => {
    const Id = quoteItem?.Id ?? v4();
    const disabledForStatus = useReactiveVar(disableForStatus);
    const [deleting, setDeleting] = useState(false);
    const params = useParams<{ id: string }>();
    const formik = useFormik({
        validationSchema: quoteConstructionItemSchema,
        initialValues: {
            Segment: quoteItem?.Segment ?? '',
            Location: quoteItem?.Location ?? 0,
            Area: quoteItem?.Area ?? '',
            LineBreakAfter: quoteItem?.LineBreakAfter ?? false,
            ParentBidQuoteItemId: quoteItem?.ParentBidQuoteItemId?.toLowerCase() ?? null
        },
        onSubmit: (values) => {
            const data = quoteConstructionItemSchema.cast(values);
            onSave({ Id, Type: type, ...data });
            formik.setSubmitting(false);
        },
        enableReinitialize: true
    });

    const { data: quoteItemData } = useQuery(GET_BIDQUOTE_ITEMS_BY_TYPE, {
        variables: {
            BidQuoteId: params.id,
            Type: type
        }
    });

    const parentItems = quoteItemData?.BidQuoteItems?.filter((bqi: any)=> bqi?.ParentBidQuoteItemId === null) ?? [];

    const updateData = (data: any) => {
        onSave({ Id, Type: type, ...data });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        try {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value };
            const values = quoteConstructionItemSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.handleChange(e);
    }

    const handleCheckChange = (e: ChangeEvent<HTMLInputElement>) => {
        const updatedValues = { ...formik.values, [e.target.name]: e.target.checked };
        const values = quoteConstructionItemSchema.cast(updatedValues);
        debouncer(values);
        formik.setFieldValue(e.target.name, e.target.checked);
    }

    // eslint-disable-next-line
    const debouncer = useCallback(debounce((values: any) => updateData(values), 1000), []);

    const handleDelete = () => {
        if (!deleting) {
            setDeleting(true)
            onDelete(Id);
        }
    }

    const handleParentSegmentChange = (event: any, value: any) => {
        formik.setFieldValue('ParentBidQuoteItemId', value?.Id ?? null);
        try {
            const updatedValues = { ...formik.values, ParentBidQuoteItemId: value?.Id ?? null };
            const values = quoteConstructionItemSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
    };

    const { data: factorData } = useQuery(GET_QUOTE_FACTORS_QUERY, {
        variables: {
            Id: params.id
        }
    });

    const quoteFactors = factorData?.BidQuotes_by_pk;
    const segmentCalculations: SegmentCalculations = getSegmentCalculations(quoteItem as BidQuoteItem, quoteFactors as QuoteFactors);

    const width = {
        small: "10ch",
        medium: "15ch",
        large: "20ch",
        xLarge: "35ch"
    }
    return (
        <QuoteLineItemFormGrid>
            <HrTextInput
                name='Segment'
                label='Segment'
                onChange={handleChange}
                errors={formik.errors.Segment}
                value={formik.values.Segment}
                width={width.xLarge}
            />

            <HrTextInput
                name='Area'
                label='Area'
                width={width.large}
                onChange={handleChange}
                errors={formik.errors.Area}
                value={formik.values.Area}
            />
            <HrTextInput
                name='Location'
                label='Locations'
                width={width.small}
                value={segmentCalculations.Locations}
                InputLabelProps={{ shrink: true }}
                disabled
            />
            <HrCheckBox
                name="LineBreakAfter"
                label="Line Break After"
                checked={formik.values.LineBreakAfter}
                onChange={handleCheckChange}
            />
            <HrCurrencyInput
                name='segmentTotal'
                label='Segment Total'
                InputLabelProps={{ shrink: true }}
                width={width.medium}
                value={segmentCalculations.SegmentTotal.toString()}
                disabled
            />
            <HrCurrencyInput
                name='segmentMarkupTotal'
                label='Markup Total'
                InputLabelProps={{ shrink: true }}
                width={width.medium}
                value={segmentCalculations.SegmentMarkupTotal?.toString()}
                disabled
            />
            <Autocomplete
                options={parentItems.filter((bqi: any) => bqi.Id !== quoteItem?.Id) ?? []}
                renderInput={(params: any) =>
                    <HrTextInput
                        {...params}
                        label="Parent Segment"
                        width="32ch"
                        errors={formik.errors.ParentBidQuoteItemId}
                    />
                }
                onChange={handleParentSegmentChange}
                getOptionLabel={(option: any) => option.Segment}
                value={quoteItemData?.BidQuoteItems?.find((bqi: any) => bqi.Id?.toLowerCase() === formik.values.ParentBidQuoteItemId) ?? null}
                disabled={quoteItemData?.BidQuoteItems?.find((bqi: any) => bqi?.ParentBidQuoteItemId === Id)}
            />
            <ConstructionItemCopyButton id={Id} />
            <DeleteWarning
                onClick={handleDelete}
                heading={`Delete ${type} Item`}
                disabled={deleting || disabledForStatus}
            >
                <IconButton
                    color="error"
                >
                    <Delete />
                </IconButton>
            </DeleteWarning>
            {/* <BidBookButton
                    type="button"
                    onClick={formik.submitForm}
                    size="large"
                    sx={{margin:"2px!important"}}
                >Save Item</BidBookButton>   */}
        </QuoteLineItemFormGrid>
    );
}