import { Grid } from "@mui/material";
import { GridProps } from "@mui/system";

type QuoteLineItemFormGridProps = { 
    children : React.ReactNode,
    sx? : Object
} & GridProps;

export const QuoteLineItemFormGrid = (props : QuoteLineItemFormGridProps) => {
    return(
        <Grid 
            item
            container 
            direction="row" 
            component="form" 
            autoComplete="off"
            {...props}
            sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap:"6px",
                ...props.sx,
                marginTop: "6px"
            }}>
            {props.children}
        </Grid>
    )
}