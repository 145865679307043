import React from 'react';
import * as yup from 'yup';
import { useFormik } from "formik";
import { v4 } from 'uuid';
import { HighRidgeColorButton } from "../../atoms/HighRidgeColorButton"; 
import { Box, Button, Grid } from "@mui/material";
import HrTextInput from "../../atoms/HrTextInput";

interface ServicesItem {
    Id: string;
    Name?: string;
}
interface ServicesFormProps {
    item?: ServicesItem;
    onSave: (item: ServicesItem) => void;
    onCancel?: () => void;
    bidQuoteId?: string;
}


const ServicesItemSchema = yup.object().shape({
    Name: yup.string().required()
});


export const ServiceCategoryForm: React.FC<ServicesFormProps> = ({ item, onSave, onCancel, bidQuoteId }) => {
    const Id = item?.Id ?? v4();
    const formik = useFormik({
        validationSchema: ServicesItemSchema,
        initialValues: {
            Name: item?.Name ?? '',
        },
        onSubmit: (unCastValues) => {
            const values = ServicesItemSchema.cast(unCastValues);
            const { Name } = values;
            onSave({ Id, Name: Name });
            formik.setSubmitting(false);
        }
    });
    return (<React.Fragment>
        <Box>
            <HrTextInput
                name='Name'
                label='Name'
                onChange={formik.handleChange}
                value={formik.values.Name}
                errors={formik.errors.Name}
                fullWidth
            />
        </Box>
        <Grid container justifyContent="space-between">
            <HighRidgeColorButton
                type="button"
                onClick={formik.submitForm}
            >Save Item</HighRidgeColorButton>
            {
                onCancel &&
                <Button
                    color="secondary"
                    type="button"
                    onClick={onCancel}
                >
                    Cancel
                </Button>

            }
        </Grid>
    </React.Fragment>);
}