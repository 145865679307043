import { gql, useMutation, useQuery } from "@apollo/client";
import { Box } from "@mui/material"
import { AnyARecord } from "dns";
import { v4 } from "uuid";
import { addMessage } from "../../../../stores/ReactiveVariables";
import { PurposeForm } from "./PurposeForm";

interface PurposeProps{
    id: string;
    getCoverLetter: () => void;
}

interface PurposeItem{ 
    Description: string, 
    Id: string, 
    Miles: number, 
    Ordinal: number
}

const GET_COVER_LETTER_PURPOSE_ITEMS = gql`
query GetCoverLetterPurposeItems($Id: uniqueidentifier = "") {
    CoverLetterPurposeItems(where: {BidQuoteId: {_eq: $Id}}, order_by: {Ordinal: asc}) {
      Description
      Id
      Miles
      Ordinal
    }
  } 
`;

const INSERT_COVER_LETTER_PURPOSE_ITEM = gql`
mutation InsertCoverLetterPurposeItem($object: CoverLetterPurposeItems_insert_input = {}) {
    insert_CoverLetterPurposeItems_one(object: $object) {
      Id
      BidQuoteId
      Description
      Miles
      Ordinal
    }
  }
  
`

const DELETE_COVER_LETTER_WORK_ITEM = gql`
mutation DeleteCoverLetterPurposeItem($Id: uniqueidentifier = "", $BidQuoteId: uniqueidentifier = "", $Ordinal: Int = 0) {
    delete_CoverLetterPurposeItems_by_pk(Id: $Id) {
        Id
    }
    update_CoverLetterPurposeItems(where: {BidQuoteId: {_eq: $BidQuoteId}, Ordinal: {_gt: $Ordinal}}, _inc: {Ordinal: -1}) {
        returning {
            Id
            Ordinal
        }
    }
}  
`



export const Purpose: React.FC<PurposeProps> = (props: PurposeProps) => {
    const { id, getCoverLetter } = props;

    const { data, loading, error } = useQuery(GET_COVER_LETTER_PURPOSE_ITEMS, { variables: { Id: id } });

    const [insertCoverLetterWorkItem] = useMutation(INSERT_COVER_LETTER_PURPOSE_ITEM);

    const [deleteCoverLetterWorkItem] = useMutation(DELETE_COVER_LETTER_WORK_ITEM);

    const formData = data?.CoverLetterPurposeItems ?? [];

    const onSave = (values: any) => {
        insertCoverLetterWorkItem({ 
            variables: { 
                object: { 
                    Id: v4(), 
                    BidQuoteId: id, 
                    Description: values.Description,
                    Miles: values.Miles,
                    Ordinal: formData.length
                } 
            },
            refetchQueries: [GET_COVER_LETTER_PURPOSE_ITEMS],
            onCompleted: () => {
                getCoverLetter();
                addMessage('Purpose Item Added', { severity: 'success' });
            }
        });
    }

    const onDelete = (item: PurposeItem) => {
        deleteCoverLetterWorkItem({ 
            variables: { Id: item.Id.toLowerCase(), BidQuoteId: id, Ordinal: item.Ordinal },
            refetchQueries: [GET_COVER_LETTER_PURPOSE_ITEMS],
            onCompleted: () => {
                getCoverLetter();
                addMessage('Purpose Item Deleted', { severity: 'success' });
            }
        });
    }

    return(
        <Box sx={{ width: '100%' }}>
            <PurposeForm id={id} data={formData} onSave={onSave} onDelete={(item: PurposeItem) => onDelete(item)}/>
        </Box>
    )
}