import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStore } from "../../../stores/StoreContext";
import HrButton from "../../atoms/HrButton";
import { v4 as uuid } from "uuid";
import HrTextInput from "../../atoms/HrTextInput";
import { toJS } from "mobx";
import HrCheckBox from "../../atoms/HrCheckBox";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { SegmentItems } from "../Bidbook/SegmentItems";
import { CreateJobSegmentsList } from "../Bidbook/BidbookCreateJobsSegmentsList";
import {
  Autocomplete,
  FormControlLabel,
  Box,
  Radio,
  RadioGroup,
  FormControl,
  Grid,
  Modal
} from "@mui/material";
import * as Yup from "yup";
import { Customer } from "../../../models/customer";
import { Colors } from "../../../constants/colors";
import { AppUser } from "../../../models/appUser";
import { JobStatuses } from "../../../models/job";
import { FormGrid } from "../../atoms/FormGrid";
import { StyledHeader } from "../../atoms/StyledHeader";
import { GET_QUOTE_NAMES, GET_JOB_QUOTE } from '../../../api/graphqlqueries/getjobquote';
import { UPDATE_QUOTE_JOB_MUTATION, UPDATE_QUOTE_JOB_TRANSFER_MUTATION } from "../../../api/graphqlqueries/updatequotejobmutation";
import { GET_JOB_PUNCHES_QUERY } from "../../../api/graphqlqueries/jobpunchesquery";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  height: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: '4px',
  pt: 2,
  px: 3,
  pb: 15,
};


interface Props {
  props?: any;
}

interface BidQuoteExternalRevision {
  Id: string;
  ExternalRevision: number;
}

interface QuoteCustomer {
  Id: string;
  Name: string;
  ShortCode: string;
}

interface QuoteProjectManager {
  Id: string;
  Name: string;
  ShortCode: string;
}

interface BidQuoteStatus {
  Status: string;
}

interface Quote {
  Id: string;
  BidDescription: string;
  BidQuoteExternalRevisions: BidQuoteExternalRevision[];
  Customer: QuoteCustomer;
  ProjectManager: QuoteProjectManager;
  JobNumber: number;
  BidQuoteStatus: BidQuoteStatus[];
}

interface JobQuote {
  Id: string;
  Quote: Quote;
}

interface JobsQuoteQuery {
  Jobs: JobQuote[]
}

export const JobsForm: React.FC<Props> = observer(() => {
  const { jobStore, modalStore, notificationStore, commonStore } = useStore();
  const {
    selectedJob,
    editCreateOrView,
    updateJob,
    setEditOrCreateMode,
    createJob,
    setSelectedJob,
    customerComboOptions,
    employeeComboOptions
  } = jobStore;
  const { displayNotification } = notificationStore;
  const { closeJobsModal } = modalStore;
  const { setLoading } = commonStore;

  const [open, setOpen] = React.useState(false);
  const [segments, setSegments] = React.useState<{ Id: string, Segment: string, Ordinal: number, Type: string }[]>([]);
  const toggleOpen = () => {
    setOpen(t => !t);
  }

  const handleSegments = (segments: any[]) => {
    setSegments(segments);
    setOpen(false);
    formik.setFieldValue("segments", segments);
  }

  const { data: nameData, error: getQuoteError } = useQuery(GET_QUOTE_NAMES, { fetchPolicy: 'cache-and-network' });

  const [fetchJob, { data: jobQuoteData, error: getJobError }] = useLazyQuery<JobsQuoteQuery>(GET_JOB_QUOTE, {
    variables: {
      Id: selectedJob?.id
    },
    fetchPolicy: "cache-and-network"
  });

  const [updateQuoteJobTransfer] = useMutation(UPDATE_QUOTE_JOB_TRANSFER_MUTATION);
  const [updateQuoteJob, { error: quoteSavingError }] = useMutation(UPDATE_QUOTE_JOB_MUTATION);

  useEffect(() => {
    if (selectedJob?.id) {
      fetchJob({
        variables: {
          Id: selectedJob?.id
        }
      });
    }
  }, [selectedJob, fetchJob]);
  useEffect(() => {
    if (getQuoteError) displayNotification("Error Getting Quote Names", "error");
  }, [getQuoteError, displayNotification]);

  useEffect(() => {
    if (getJobError) displayNotification("Error Getting Job Quote", "error");
  }, [getJobError, displayNotification]);

  useEffect(() => {
    if (quoteSavingError) displayNotification("Error Saving Job Quote", "error");
  }, [quoteSavingError, displayNotification]);

  const currentQuoteId = jobQuoteData?.Jobs[0].Quote?.Id;

  const quoteId = jobQuoteData?.Jobs[0]?.Quote?.Id.toLowerCase();
  const awardedAndActiveQuoteList: Quote[] = nameData?.BidQuotes?.filter((bq: Quote) => {
    return (bq?.BidQuoteStatus[0]?.Status === "Active" || bq?.BidQuoteStatus[0]?.Status === "Awarded" || bq?.BidQuoteStatus[0]?.Status === "Scheduled");
  });

  let quoteList = [] as Quote[];
  if (awardedAndActiveQuoteList)
    quoteList = jobQuoteData?.Jobs[0].Quote ? [...awardedAndActiveQuoteList, jobQuoteData?.Jobs[0].Quote] : awardedAndActiveQuoteList;

  const handleQuoteSave = (values: any) => {
    const refetchQueries = [
      GET_QUOTE_NAMES,
      {
        query: GET_JOB_QUOTE,
        variables: {
          Id: selectedJob?.id
        }
      },
      GET_JOB_PUNCHES_QUERY
    ];
    if (values.quoteId) {
      if (values.currentQuoteId) {
        return updateQuoteJobTransfer({
          variables: {
            Id: values.quoteId,
            JobId: selectedJob?.id,
            PrevQuoteId: values.currentQuoteId,
          },
          refetchQueries
        });
      } else {
        return updateQuoteJob({
          variables: {
            Id: values.quoteId,
            JobId: selectedJob?.id,
          },
          refetchQueries,
        });
      }
    }
    else if (values.currentQuoteId) {
      return updateQuoteJob({
        variables: {
          Id: values.currentQuoteId,
          JobId: null,
          refetchQueries,
        }
      });
    }

  }

  const validationSchema = Yup.object({
    name: Yup.string().required("The job name is required"),
    customer: Yup.object().required("A valid customer is required"),
    city: Yup.string().required("A valid city is required"),
    state: Yup.string().required("A valid state is required"),
    zip: Yup.string().required("A valid ZIP Code is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: selectedJob?.id,
      name: selectedJob?.name,
      reqDistanceCompleted: selectedJob?.reqDistanceCompleted,
      reqUnitsCompleted: selectedJob?.reqUnitsCompleted,
      reqPerDiem: selectedJob?.reqPerDiem ?? false,
      reqSegments: selectedJob?.reqSegments ?? false,
      customer: selectedJob?.customer,
      projectManagerId: selectedJob?.projectManagerId,
      segments: selectedJob?.segments,
      appUsers: selectedJob?.appUsers,
      city: selectedJob?.city,
      state: selectedJob?.state,
      status: selectedJob?.status,
      zip: selectedJob?.zip,
      isMileageBillable: selectedJob?.isMileageBillable ?? false,
      expectedUnits: selectedJob?.expectedUnits ?? 0,
      expectedMiles: selectedJob?.expectedMiles ?? 0,
      quoteId: quoteId,
      currentQuoteId: currentQuoteId,
    },
    onSubmit: (values: any) => {
      if (editCreateOrView === "create") {
        const newValues = { ...values, id: uuid() };
        setLoading(true)
        createJob(newValues).then(data => {
          displayNotification("Job Updated", "success");
          return handleQuoteSave(values);
        })
          .catch(err => {
            displayNotification("Error Updating Job", "error");
          }).finally(() => {
            closeJobsModal();
            setLoading(false)
            setSelectedJob(undefined);
          })
        setEditOrCreateMode("view")
      } else {
        setLoading(true)
        updateJob(values).then(data => {
          displayNotification("Job Updated", "success");
          return handleQuoteSave(values);
        }).catch(err => {
          console.error(err);
          displayNotification("Error Updating Job", "error");
        }).finally(() => {
          closeJobsModal();
          setLoading(false)
          setSelectedJob(undefined);
        })
        setEditOrCreateMode("view");
      }
    },
    validationSchema: validationSchema,
  });
  return (
    <Box sx={{ width: "100%" }}>
      {editCreateOrView === "edit" && <StyledHeader sx={{ textAlign: "Center" }}>Edit Job</StyledHeader>}
      {editCreateOrView === "view" && <StyledHeader sx={{ textAlign: "Center" }}>View Job</StyledHeader>}
      {editCreateOrView === "create" && <StyledHeader sx={{ textAlign: "Center" }}>Create Job</StyledHeader>}
      <form className="ui form" onSubmit={formik.handleSubmit}>
        <FormGrid>
          <HrTextInput
            id="name"
            name="name"
            label="Project Alias"
            onChange={formik.handleChange}
            value={formik.values.name}
            errors={formik.errors.name}
          />
          <HrTextInput
            id="city"
            name="city"
            label="City"
            onChange={formik.handleChange}
            value={formik.values.city}
            errors={formik.errors.city}
          />
          <Grid container spacing={.5}>
            <Grid item xs={6}>
              <HrTextInput
                id="state"
                name="state"
                label="State"
                onChange={formik.handleChange}
                value={formik.values.state}
                errors={formik.errors.state}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <HrTextInput
                id="zip"
                name="zip"
                label="ZIP Code"
                onChange={formik.handleChange}
                value={formik.values.zip}
                errors={formik.errors.zip}
                fullWidth
              />
            </Grid>
          </Grid>
          <FormControl>
            <RadioGroup>
              <FormControlLabel
                control={
                  <Radio
                    onChange={() => {
                      formik.setFieldValue("reqDistanceCompleted", false);
                      formik.setFieldValue("reqUnitsCompleted", true);
                    }}
                    style={{
                      color: Colors.deepSeaGreen,
                    }}
                    checked={
                      formik.values.reqUnitsCompleted
                        ? formik.values.reqUnitsCompleted
                        : false
                    }
                  />
                }
                label="Require Units Complete"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={() => {
                      formik.setFieldValue("reqDistanceCompleted", true);
                      formik.setFieldValue("reqUnitsCompleted", false);
                    }}
                    style={{
                      color: Colors.deepSeaGreen,
                    }}
                    checked={
                      formik.values.reqDistanceCompleted
                        ? formik.values.reqDistanceCompleted
                        : false
                    }
                  />
                }
                label="Require Production Miles"
              />
            </RadioGroup>
          </FormControl>
          <Grid container spacing={.5}>
            <Grid item xs={6}>
              <HrTextInput
                id="expectedMiles"
                name="expectedMiles"
                label="Expected Miles"
                onChange={formik.handleChange}
                value={formik.values.expectedMiles}
                errors={formik.errors.expectedMiles}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <HrTextInput
                id="expectedUnits"
                name="expectedUnits"
                label="Expected Units"
                onChange={formik.handleChange}
                value={formik.values.expectedUnits}
                errors={formik.errors.expectedUnits}
                fullWidth
              />
            </Grid>
          </Grid>
          {formik.values.reqDistanceCompleted && (
            <HrCheckBox
              id="reqSegments"
              onChange={formik.handleChange}
              name="reqSegments"
              label="Require Segments"
              checked={formik.values.reqSegments}
            />
          )}
          <HrCheckBox
            id="reqPerDiem"
            onChange={formik.handleChange}
            name="reqPerDiem"
            color="primary"
            label="Require PerDiem"
            checked={formik.values.reqPerDiem}
          />
          <HrCheckBox
            id="isMileageBillable"
            onChange={formik.handleChange}
            name="isMileageBillable"
            color="primary"
            label="Is Mileage Billable For This Job? "
            checked={formik.values.isMileageBillable}
          />

          {editCreateOrView === "edit" && (
            <Autocomplete
              id="customer"
              value={customerComboOptions.find(
                (c) => c.id === selectedJob?.customer.id
              )}
              options={customerComboOptions}
              getOptionLabel={(option: Customer) => option.name}
              onChange={(event, value) => {
                formik.setFieldValue("customer", toJS(value));
              }}
              renderInput={(params) => (
                <HrTextInput {...params} errors={formik.errors.customer} label="Customer" variant="outlined" />
              )}
            />
          )}
          {editCreateOrView === "create" && (
            <Autocomplete
              id="customer"
              options={customerComboOptions}
              getOptionLabel={(option: Customer) => option.name}
              onChange={(event, value) => {
                formik.setFieldValue("customer", toJS(value));
              }}
              renderInput={(params) => (
                <HrTextInput {...params} errors={formik.errors.customer} label="Customer" variant="outlined" />
              )}
            />
          )}
          {editCreateOrView === "view" && (
            <HrTextInput
              id="customer"
              name="customer"
              label="Customer"
              disabled={true}
              value={formik.values.customer?.name}
            />
          )}
          <Autocomplete
            id="projectManagerId"
            options={employeeComboOptions}
            getOptionLabel={(option: AppUser) =>
              option.firstName + " " + option.lastName
            }
            onChange={(event, value) => {
              formik.setFieldValue("projectManagerId", toJS(value?.id));
            }}
            renderInput={(params) => (
              <HrTextInput {...params} label="Project Manager" variant="outlined" />
            )}
          />
          <Autocomplete
            id="appUsers"
            multiple
            filterSelectedOptions
            defaultValue={selectedJob?.appUsers}
            options={employeeComboOptions}
            getOptionLabel={(option: AppUser) =>
              option.firstName + " " + option.lastName
            }
            onChange={(event, value) => {
              formik.setFieldValue("appUsers", value);
            }}
            renderInput={(params) => (
              <HrTextInput {...params} label="Employees" variant="outlined" />
            )}
          />
          <Autocomplete
            id="quoteId"
            //defaultValue={quoteList?.find((quote: any) => quote.Id.toLowerCase() === quoteId)}
            value={quoteList?.find((quote: any) => quote.Id.toLowerCase() === formik.values.quoteId) ?? null}
            options={quoteList ?? []}
            getOptionLabel={(option: any) => {
              return `${option?.Customer?.ShortCode ? option?.Customer?.ShortCode : option?.Customer?.Name}_${option?.JobNumber}_${option?.BidDescription}_Rev_${option.BidQuoteExternalRevisions[0]?.ExternalRevision ?? 0}`;
            }}
            onChange={(event, value: any) => {
              formik.setFieldValue("quoteId", value?.Id.toLowerCase() ?? null);
              setSegments([]);
            }}
            renderInput={(params) => (
              <HrTextInput {...params} errors={formik.errors.status} label="Quote" variant="outlined" />
            )}
          />
          {formik.values?.quoteId &&
            <>
              <HrButton onClick={toggleOpen}>Choose Segments</HrButton>
              <CreateJobSegmentsList segments={segments} />
            </>
          }
          <Autocomplete
            id="status"
            value={selectedJob?.status}
            options={JobStatuses}
            onChange={(event, value) => {
              formik.setFieldValue("status", value);
            }}
            renderInput={(params) => (
              <HrTextInput {...params} errors={formik.errors.status} label="Job Status" variant="outlined" />
            )}
          />
          <HrButton type="submit" title="Submit" />
        </FormGrid>
      </form>

      <Modal
        open={open}
        onClose={toggleOpen}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
          {formik.values?.quoteId &&
            <SegmentItems id={formik.values?.quoteId} onSave={handleSegments} />
          }
          <HrButton onClick={toggleOpen}>Cancel</HrButton>
        </Box>
      </Modal>

    </Box>
  );
});