import { Grid } from "@mui/material";
import { Colors } from "../../../../constants/colors";
import { StyledHeader } from '../../../atoms/StyledHeader';
import { QuoteItemTotals } from "./BidbookQuoteItemTotals";
import { QuoteSurveyTotals } from "./BidbookQuoteSurveyTotals";

export const QuoteLineItemTotals = (props: any) => {
    const { id, type } = props;

    const getLineItemTotals = (type: string) => {

        switch (type) {
            case 'Survey':
                return <QuoteSurveyTotals id={id} />;
            case 'Construction':
                return <QuoteItemTotals id={id} type={type} />;
            case 'Inspection':
                return <QuoteItemTotals id={id} type={type} />;
            case 'Technician':
                return <QuoteItemTotals id={id} type={type} />;
        }
    }

    const styles = {
        header: {
            width: type === "Survey" ? "558px" : "auto",
            mr: "12px"
        }
    }

    return (
        <Grid item container wrap="nowrap"
            sx={{
                borderBottom: `2px solid ${Colors.gold}`,
                //borderTop:`2px solid ${Colors.gold}`,
                padding: "4px 0 6px",
                margin: "0",
                alignItems: "center"
            }}
        >
            <Grid item>
                <StyledHeader sx={styles.header}>{type} Totals</StyledHeader>
            </Grid>
            <Grid item>
                {getLineItemTotals(type)}
            </Grid>
        </Grid>
    )
}