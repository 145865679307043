import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Autocomplete, Box, Button, Dialog, DialogContent, Divider, Grid, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { CompanyListQueryResult, COMPANY_LIST_QUERY } from '../../../api/graphqlqueries/companylistquery';
import { CREATE_QUOTE_MUTATION, getVariables } from '../../../api/graphqlqueries/createquotemutation';
import { GetBidbookUsersQueryResult, GET_BIDBOOK_USERS_QUERY } from '../../../api/graphqlqueries/getbidbookusersquery';
import { GetClientRepsQueryResult, GET_CLIENT_REPS_QUERY } from '../../../api/graphqlqueries/getclientrepsquery';
import { useStore } from "../../../stores/StoreContext";
import { FormGrid } from '../../atoms/FormGrid';
import { HighRidgeColorButton } from '../../atoms/HighRidgeColorButton';
import HrCheckBox from '../../atoms/HrCheckBox';
import HrTextInput from '../../atoms/HrTextInput';
import { StyledHeader } from '../../atoms/StyledHeader';
import { CustomerContactItem } from '../Clients/CustomerContactItem';
import { stateList } from "../../../utils/stateList";
import CloseIcon from '@mui/icons-material/Close';
import { HrSelect } from "../../atoms/HrSelect";

const validationSchema = yup.object().shape({
    customerId: yup.string().required('Client Name Required'),
    customerRepId: yup.string(),
    jobName: yup.string().required('Project Alias Required'),
    jobCity: yup.string(),
    jobState: yup.string(),
    zip: yup.string(),
    description: yup.string(),
    receivedDate: yup.date(),
    docReceivedDate: yup.date().nullable(),
    dueDate: yup.date(),
    coverLetter: yup.bool(),
    budgetary: yup.bool(),
    kmzReview: yup.bool(),
    gpsPointsReview: yup.bool(),
    projectAreaReview: yup.bool(),
    specialEquipmentNeeded: yup.bool(),
    businessLeadId: yup.string().required('Business Lead Required'),
    analystId: yup.string(),
    bidBookId: yup.string(),
    preparedByUserId: yup.string()
});

const GET_DEFAULT_VALUES = gql`
  query GetDefaultValues {
    BidQuoteDefaults {
      Id
      Key
      Value
    }
  }
`;

export const CreateQuoteForm: React.FC = observer(() => {
    const navigation = useNavigate();
    const { userStore } = useStore();
    const [companySelected, setCompanySelected] = useState(null);
    const [companyRepSelected, setCompanyRepSelected] = useState(null);
    const { data: companyData } = useQuery<CompanyListQueryResult>(COMPANY_LIST_QUERY);
    const { data: defaultData } = useQuery(GET_DEFAULT_VALUES);
    const { data: userData } = useQuery<GetBidbookUsersQueryResult>(GET_BIDBOOK_USERS_QUERY);
    const [getClientRep, { data: clientRepData }] = useLazyQuery<GetClientRepsQueryResult>(GET_CLIENT_REPS_QUERY, {
        fetchPolicy: 'cache-and-network'
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [addQuote, { loading: saveLoading }] = useMutation(CREATE_QUOTE_MUTATION);

    const [yearOptions, setYearOptions] = useState<Array<number>>([]);

    useEffect(() => {
        const year = new Date().getFullYear();
        const years = [];
        for (let i = year; i < year + 10; i++) {
            years.push(i);
        }
        setYearOptions(years);
    }, []);

    const redirectToList = (data: any) => {
        const quoteId = data?.insert_BidQuotes_one?.Id;
        if (quoteId)
            navigation(`/Bidbook/details/${quoteId}`);
        else
            navigation('/Bidbook/list/Draft');
    }

    const closeModal = () => setModalOpen(false);
    const closeModalAndRefreshList = () => {
        setModalOpen(false)
    }

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            customerId: '',
            customerRepId: '',
            jobName: '',
            jobCity: '',
            jobState: '',
            zip: '',
            description: '',
            receivedDate: new Date(),
            docReceivedDate: null,
            dueDate: new Date(),
            coverLetter: false,
            budgetary: false,
            kmzReview: false,
            timeAndMaterials: false,
            gpsPointsReview: false,
            projectAreaReview: false,
            specialEquipmentNeeded: false,
            businessLeadId: '',
            analystId: '',
            bidBookId: '',
            preparedByUserId: '',
            preparedByTitle: '',
            expectedYear: null,
            expectedQuarter: ''
        },
        onSubmit: (values) => {
            const defaults = {} as any;
            defaultData.BidQuoteDefaults.forEach((def: any) => defaults[def.Key] = def.Value);
            const vars = getVariables(validationSchema.cast(values), userStore.appUser?.id ?? '', defaults);
            addQuote({
                variables: vars,
                onCompleted: redirectToList
            });
        }
    });

    const handleClientSelect = (event: React.ChangeEvent<{}>, value: any) => {
        formik.setFieldValue("customerId", value?.Id ?? '');
        setCompanySelected(value);
        if (value?.Id) {
            getClientRep({
                variables: {
                    CustomerId: value?.Id,
                }
            });
        }
        if (formik.values.customerRepId) {
            setCompanyRepSelected(null);
            formik.setFieldValue("customerRepId", '');
        }
    };

    const handleStateSelect = (event: React.ChangeEvent<{}>, value: string | null) => {
        formik.setFieldValue("jobState", value ?? "");
    };

    const handleClientRepSelect = (event: React.ChangeEvent<{}>, value: any) => {
        setCompanyRepSelected(value);
        formik.setFieldValue("customerRepId", value?.Id ?? '');
    };

    const lastNameSortFunc = (a: any, b: any) => {
        const nameA = `${a?.LastName}, ${a?.FirstName}`;
        const nameB = `${b?.LastName}, ${b?.FirstName}`;
        return nameA.localeCompare(nameB);
    }

    const sortedCustomerContactList = clientRepData?.CustomerContacts ? clientRepData?.CustomerContacts?.slice().sort(lastNameSortFunc) : [];
    const sortedUserList = userData?.AspNetUsers ? [...userData?.AspNetUsers].sort(lastNameSortFunc) : [];

    const bidBookAnalystList = sortedUserList.filter((user) => user.UserRole.some((role: any) => role.UserHighridgeRole.Name === "BidBook Analyst"));
    const bidBookLeadList = sortedUserList.filter((user) => user.UserRole.some((role: any) => role.UserHighridgeRole.Name === "BidBook Lead"));
    const businessDevelopmentUserList = sortedUserList.filter((user) => user.UserRole.some((role: any) => role.UserHighridgeRole.Name === "Business Development Viewer"));

    return (
        <>
            <Box sx={{ width: '100%', marginTop: "12px" }}>
                <StyledHeader>Create Quote</StyledHeader>
                <Divider />
                <form className='ui form' onSubmit={formik.handleSubmit}>
                    <Grid container direction="column" spacing={1}>
                        <Grid container item xs={12} spacing={1}>
                            <Grid item xs>
                                <Autocomplete
                                    id="customer"
                                    options={companyData?.Customers ? [...companyData?.Customers].sort((a: any, b: any) => a.Name?.localeCompare(b.Name)) : []}
                                    value={companySelected}
                                    getOptionLabel={(option: any) => option.Name}
                                    onChange={handleClientSelect}
                                    fullWidth
                                    renderInput={(params) => (
                                        <HrTextInput {...params} label="Client" errors={formik.errors.customerId} />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    id="customerRep"
                                    options={formik.values.customerId ? (sortedCustomerContactList ?? []) : []}
                                    value={companyRepSelected}
                                    getOptionLabel={(option: any) => `${option.LastName}, ${option.FirstName} - ${option.Email} - ${option.Phone}`}
                                    onChange={handleClientRepSelect}
                                    fullWidth
                                    renderInput={(params) => (
                                        <HrTextInput {...params} label="Client Rep" />
                                    )}
                                />
                            </Grid>
                            {formik.values.customerId &&
                                <Grid item xs>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={() => setModalOpen(true)}
                                    >
                                        Add Contact
                                    </Button>
                                </Grid>
                            }

                        </Grid>
                        <Grid item container xs={12}>
                            <HrTextInput
                                name='jobName'
                                label='Project Alias'
                                onChange={formik.handleChange}
                                value={formik.values.jobName}
                                errors={formik.errors.jobName}
                                fullWidth
                            />
                        </Grid>
                        <Grid item container xs={12} spacing={1}>
                            <Grid item xs>
                                <HrTextInput
                                    name='jobCity'
                                    label='Job City'
                                    onChange={formik.handleChange}
                                    value={formik.values.jobCity}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs>
                                <Autocomplete
                                    id="jobState"
                                    options={stateList}
                                    value={formik.values.jobState}
                                    //getOptionLabel={(option: any) => option.Name}
                                    onChange={handleStateSelect}
                                    fullWidth
                                    renderInput={(params) => (
                                        <HrTextInput {...params} label="Job State" errors={formik.errors.jobState} />
                                    )}
                                />
                            </Grid>
                            <Grid item xs>
                                <HrTextInput
                                    name='zip'
                                    label='Zip Code'
                                    onChange={formik.handleChange}
                                    value={formik.values.zip}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} spacing={1}>
                            <Grid item xs>
                                <DatePicker
                                    value={formik.values.dueDate}
                                    onChange={(data) => formik.setFieldValue('dueDate', data)}
                                    renderInput={(props) => <HrTextInput {...props} name="dueDate" label="Quote Due Date" fullWidth />}
                                />
                            </Grid>
                            <Grid item xs>
                                <DatePicker
                                    value={formik.values.receivedDate}
                                    onChange={(data) => formik.setFieldValue('receivedDate', data)}
                                    renderInput={(props) => <HrTextInput {...props} name="receivedDate" label="Quote Received Date" fullWidth />}
                                />
                            </Grid>
                            <Grid item xs>
                                <DatePicker
                                    value={formik.values.docReceivedDate}
                                    onChange={(data) => formik.setFieldValue('docReceivedDate', data)}
                                    renderInput={(props) => <HrTextInput {...props} name="docReceivedDate" label="Documents Received Date" fullWidth />}
                                />
                            </Grid>
                            <Grid item xs>
                                <HrSelect
                                    label="Exp Year Completed"
                                    name="expectedYear"
                                    value={formik.values.expectedYear}
                                    onChange={formik.handleChange}
                                    variant="standard"
                                >
                                    {
                                        yearOptions.map((year) => {
                                            return <MenuItem value={year}>{year}</MenuItem>
                                        })
                                    }
                                </HrSelect>
                            </Grid>
                            <Grid item xs>
                                <HrSelect
                                    label="Exp Qtr Completed"
                                    name="expectedQuarter"
                                    value={formik.values.expectedQuarter}
                                    onChange={formik.handleChange}
                                    variant="standard"
                                >
                                    {
                                        ["Q1", "Q2", "Q3", "Q4"].map((year) => {
                                            return <MenuItem value={year}>{year}</MenuItem>
                                        })
                                    }
                                </HrSelect>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                            <Grid item>
                                <HrCheckBox
                                    name="budgetary"
                                    label="Needed For Budgetary"
                                    checked={formik.values.budgetary}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item>
                                <HrCheckBox
                                    name="coverLetter"
                                    label="Cover Letter"
                                    checked={formik.values.coverLetter}
                                    onChange={formik.handleChange}
                                />
                            </Grid>

                            <Grid item>
                                <HrCheckBox
                                    name="kmzReview"
                                    label="KMZ Reviewed"
                                    checked={formik.values.kmzReview}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item>
                                <HrCheckBox
                                    name="timeAndMaterials"
                                    label="Time and Materials"
                                    checked={formik.values.timeAndMaterials}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item>
                                <HrCheckBox
                                    name="gpsPointsReview"
                                    label="GPS Points Reviewed"
                                    checked={formik.values.gpsPointsReview}
                                    onChange={formik.handleChange}
                                />
                            </Grid>

                            <Grid item>
                                <HrCheckBox
                                    name="projectAreaReview"
                                    label="Project Area Reviewed (Standard Lodging Prices Concerned)"
                                    checked={formik.values.projectAreaReview}
                                    onChange={formik.handleChange}
                                />
                            </Grid>

                            <Grid item>
                                <HrCheckBox
                                    name="specialEquipmentNeeded"
                                    label="Special Equipment Needed"
                                    checked={formik.values.specialEquipmentNeeded}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                id="analystUser"
                                options={bidBookAnalystList}
                                getOptionLabel={(option: any) => `${option.LastName}, ${option.FirstName}`}
                                isOptionEqualToValue={(option: any) => option.Id === formik.values.analystId}
                                onChange={(event, value) => formik.setFieldValue("analystId", value?.Id ?? '')}
                                renderInput={(params) => (
                                    <HrTextInput {...params} label="Bid Book Analyst" variant="outlined" fullWidth />
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                id="businessUser"
                                options={businessDevelopmentUserList}
                                disableClearable
                                getOptionLabel={(option: any) => `${option.LastName}, ${option.FirstName}`}
                                isOptionEqualToValue={(option: any) => option.Id === formik.values.businessLeadId}
                                onChange={(event, value) => formik.setFieldValue("businessLeadId", value?.Id ?? '')}
                                renderInput={(params) => (
                                    <HrTextInput {...params} label="Business Development Lead" variant="outlined" fullWidth errors={formik.errors.businessLeadId} />
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                id="bidbookUser"
                                options={bidBookLeadList}
                                getOptionLabel={(option: any) => `${option.LastName}, ${option.FirstName}`}
                                isOptionEqualToValue={(option: any) => option.Id === formik.values.bidBookId}
                                onChange={(event, value) => formik.setFieldValue("bidBookId", value?.Id ?? '')}
                                renderInput={(params) => (
                                    <HrTextInput {...params} label="Bid Book Lead" variant="outlined" fullWidth />
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                id="preparedByUserId"
                                options={sortedUserList}
                                getOptionLabel={(option: any) => `${option.LastName}, ${option.FirstName}`}
                                isOptionEqualToValue={(option: any) => option.Id === formik.values.preparedByUserId}
                                onChange={(event, value) => formik.setFieldValue("preparedByUserId", value?.Id ?? '')}
                                renderInput={(params) => (
                                    <HrTextInput {...params} label="Prepared By User" variant="outlined" fullWidth />
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <HrTextInput
                                name='preparedByTitle'
                                label='Prepared By Title'
                                onChange={formik.handleChange}
                                value={formik.values.preparedByTitle}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <HighRidgeColorButton
                                type='submit'
                                disabled={saveLoading || !formik.isValid || !formik.dirty}
                            >
                                {!saveLoading ? 'Save Quote' : 'Saving...'}
                            </HighRidgeColorButton>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            <Dialog
                open={modalOpen}
                onClose={closeModal}
            >
                <DialogContent>
                    <Button
                        onClick={() => {
                            setModalOpen(false);
                        }}
                        variant="contained"
                        color="primary"
                        className="modal-exit"
                    >
                        <CloseIcon />
                    </Button>
                    <FormGrid>
                        <CustomerContactItem
                            customerId={formik.values.customerId}
                            onCancel={closeModal}
                            onSave={closeModalAndRefreshList}
                        />
                    </FormGrid>
                </DialogContent>
            </Dialog>
        </>
    );
});