import { List, ListItem, Typography } from "@mui/material";
import { KickoffPacketSection } from "./KickoffPacketSection";
import { SpecificSafetyForm } from "./SpecificSafetyForm";
import { gql, useQuery, useMutation } from "@apollo/client";
import { v4 } from "uuid";

interface SpecificSafetyConcernsProps{
    id?: string;
}

interface SpecificSafetyConcernsItem {
    SafetyConcerns: string,
    Id: string,
    Ordinal: number,
}

interface SpecificSafetyConcernsData {
    KickoffPacketProjectSafetyConcerns: SpecificSafetyConcernsItem[];
}

const PROJECT_SAFETY_CONCERNS_QUERY = gql`
  query ProjectSafetyConcerns($Id: uniqueidentifier = "") {
    KickoffPacketProjectSafetyConcerns(where: {KickoffPacketId: {_eq: $Id}}, order_by: {Ordinal: asc}) {
      Id
      Ordinal
      SafetyConcerns
    }
  }
`;

const ADD_SPECIFIC_SAFETY_CONCERN_MUTATION = gql`
  mutation AddSpecificSafetyConcern($object: KickoffPacketProjectSafetyConcerns_insert_input = {}) {
    insert_KickoffPacketProjectSafetyConcerns_one(object: $object) {
      KickoffPacketId
      Ordinal
      SafetyConcerns
      Id
    }
  }
`;

export const SpecificSafety: React.FC<SpecificSafetyConcernsProps> = ({ id }: SpecificSafetyConcernsProps) => {
    
    const { data, error } = useQuery<SpecificSafetyConcernsData>(PROJECT_SAFETY_CONCERNS_QUERY, { variables: { Id: id } });
    const [addSpecificSafetyConcern] = useMutation(ADD_SPECIFIC_SAFETY_CONCERN_MUTATION);
    
    if (error) console.log(error);
    if (!data) return null;

    const handleSubmit = (newItem: string) => {
        if (!newItem) return;
        const newId = v4();
        const newOrdinal = data?.KickoffPacketProjectSafetyConcerns.length || 0;
        addSpecificSafetyConcern({
            variables: {
                object: { KickoffPacketId: id, Ordinal: newOrdinal, SafetyConcerns: newItem, Id: newId }
            },
            refetchQueries: [{ query: PROJECT_SAFETY_CONCERNS_QUERY, variables: { Id: id }}]
        });
    }


    return (
        <KickoffPacketSection heading="Specific Safety Concerns">
            <SpecificSafetyForm handleSubmit={handleSubmit} />
            <List disablePadding>
            {
                data?.KickoffPacketProjectSafetyConcerns.map((item: any) => (
                <ListItem divider key={item.Id}>
                    <Typography variant="body1">{ item.SafetyConcerns }</Typography>
                </ListItem>))
            }
            </List>
        </KickoffPacketSection>
    ) 
}