import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { HrTextInput } from './HrTextInput';
import ClockIcon from '@mui/icons-material/AccessTime'; 

const HrDateTimePicker = (props: any) =>{ 
    return(
        <MobileDateTimePicker              
            renderInput={(params) => <HrTextInput {...params} fullWidth={props.fullWidth} errors={props.errors} />}
            views={['hours','minutes','day']}
            orientation="portrait"
            components={{
                OpenPickerIcon: ClockIcon
            }}
            {...props}
        />
    )
}

export default HrDateTimePicker;