import { gql, useQuery } from "@apollo/client";
import { Dialog, DialogContent, Button, List, ListItem, IconButton, ListItemButton, ListItemIcon, Checkbox, ListItemText, DialogActions, DialogTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from "react";
import axios from "axios";
import { addMessage } from "../../../stores/ReactiveVariables";

export interface KickoffNotificationCrewModalProps {
    modalOpen: boolean;
    closeModal: () => void;
    id: string;
}

const GET_KICKOFF_CREW_MEMBERS_QUERY = gql`
    query GetKickoffPacketCrewMembers($KickoffPacketId: uniqueidentifier = "") {
        KickoffPacketCrewMember(where: {KickoffPacketId: {_eq: $KickoffPacketId}}, order_by: {Ordinal: asc}) {
            Ordinal
            KickoffPacketId
            Id
            CrewMemberId
            AspNetUser {
                Id
                FirstName
                LastName
            }
        }
    }
`


export const KickoffNotificationCrewModal: React.FC<KickoffNotificationCrewModalProps> = ({ modalOpen, closeModal, id }) => {
    const [crewMemberList, setCrewMemberList] = useState<Array<any>>([]);

    const { data } = useQuery(GET_KICKOFF_CREW_MEMBERS_QUERY, {
        variables: {
            KickoffPacketId: id
        },
        onCompleted: (data: any) => {
            setCrewMemberList(data?.KickoffPacketCrewMember.map((crewMember:any) => crewMember?.AspNetUser?.Id) ?? [])
        }
    });

    const notifyCrewMembers = () => {
        axios.post(`/report/${id}/kickoffpacket/notifycrew`,crewMemberList)
        .then((response) => {
            addMessage("Crew Members Notified", { severity: 'success' });
        }).catch((error) => {
            addMessage("Error Notifying Members", { severity: 'error' });
        }).finally(()=>{
            closeModal();
        })
    }

    const crewMembers = data?.KickoffPacketCrewMember;
    return (
        <Dialog
            open={modalOpen}
            onClose={closeModal}
        >
            <DialogContent>
                <DialogActions>
                    <Button
                        onClick={closeModal}
                        variant="contained"
                        color="primary"
                        className="modal-exit"
                    >
                        <CloseIcon />
                    </Button>
                </DialogActions>
                <DialogTitle>Select crew members to notify</DialogTitle>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {crewMembers?.map((crewMember: any, i: any) => {
                        const labelId = `checkbox-list-label-${i}`;
                        const user = crewMember?.AspNetUser;
                        return (
                            <ListItem
                                key={user.Id}
                                disablePadding
                            >
                                <ListItemButton onClick={() => {
                                    if(crewMemberList?.includes(user?.Id)){
                                        setCrewMemberList(crewMemberList?.filter((cm: any)=> cm !== user?.Id));
                                    } else {
                                        setCrewMemberList([...crewMemberList, user?.Id]);
                                    }
                                }} dense>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={crewMemberList?.includes(user?.Id)}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={`${user.FirstName} ${user.LastName}`} />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={notifyCrewMembers}>Notify Users</Button>
            </DialogActions>
        </Dialog>
    );
};
