import { gql } from "@apollo/client/core";

export const UPDATE_QUOTE_JOB_TRANSFER_MUTATION = gql`
	mutation UpdateQuoteJobMutation($Id: uniqueidentifier = "", $PrevQuoteId: uniqueidentifier = "", $JobId: uniqueidentifier = "") {
		removePrevQuoteId: update_BidQuotes_by_pk(pk_columns: {Id: $PrevQuoteId}, _set: {JobId: null}) {
			Id
			JobId
		}
		update_BidQuotes_by_pk(pk_columns: {Id: $Id}, _set: {JobId: $JobId}) {
			Id
			JobId
		}
	} 
`;

export const UPDATE_QUOTE_JOB_MUTATION = gql`
	mutation UpdateQuoteJobMutation($Id: uniqueidentifier = "", $JobId: uniqueidentifier = "") {
		update_BidQuotes_by_pk(pk_columns: {Id: $Id}, _set: {JobId: $JobId}) {
			Id
			JobId
		}
	} 
`;