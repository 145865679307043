//SECTION CHECKBOX

import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import * as yup from 'yup';
import { addMessage, resetMessage } from "../../../../../stores/ReactiveVariables";
import { AccordionCheckbox } from "../AccordionCheckbox";

const GET_COVER_LETTER_EXECUTION_PLAN_SECTION = gql`
query GetCoverLetterExecutionPlan($Id: uniqueidentifier = "") {
    BidQuotes_by_pk(Id: $Id) {
        CoverLetterExecutionPlanSection
    }
}  
`

const UPDATE_COVER_LETTER_EXECUTION_PLAN_SECTION = gql`
    mutation UpdateCoverLetterExecutionPlanSection($Id: uniqueidentifier = "", $CoverLetterExecutionPlanSection: Boolean = false) {
        update_BidQuotes_by_pk(pk_columns: {Id: $Id}, _set: {CoverLetterExecutionPlanSection: $CoverLetterExecutionPlanSection}) {
            __typename
            Id
        }
    }
`

interface ExecutionPlanCheckboxProps {
    id: string;
    getCoverLetter: () => void;
}

const validationSchema = yup.object().shape({
    CoverLetterExecutionPlanSection: yup.boolean(),
});

export const ExecutionPlanCheckbox: React.FC<ExecutionPlanCheckboxProps> = ({ id, getCoverLetter }: ExecutionPlanCheckboxProps) => {
    const { data, loading, error } = useQuery(GET_COVER_LETTER_EXECUTION_PLAN_SECTION, { variables: { Id: id } });
    const [updateCoverLetterExecutionPlan] = useMutation(UPDATE_COVER_LETTER_EXECUTION_PLAN_SECTION);

    const formData = data?.BidQuotes_by_pk;

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            CoverLetterExecutionPlanSection: formData?.CoverLetterExecutionPlanSection ?? false,
        },
        onSubmit: (values: any) => {
            updateCoverLetterExecutionPlan({
                variables: { Id: id, CoverLetterExecutionPlanSection: values.CoverLetterExecutionPlanSection },
                onCompleted: (data: any) => {
                    resetMessage();
                    const confirmMessage = values.CoverLetterExecutionPlanSection === true ? "Execution Plan Added To Cover Letter" : "Execution Plan Removed From Cover Letter";
                    addMessage(confirmMessage, { severity: 'success' });
                    getCoverLetter();
                },
                onError: () => {
                    addMessage('Error Saving/Removing Execution Plan Section', { severity: 'error' });
                }
            });
        }
    });
    return (
        <AccordionCheckbox
            getCoverLetter={getCoverLetter}
            name="CoverLetterExecutionPlanSection"
            checked={formik.values.CoverLetterExecutionPlanSection}
            onCheckboxClick={() => formik.setFieldValue('CoverLetterExecutionPlanSection', !formik.values.CoverLetterExecutionPlanSection)}
            onSave={formik.submitForm}
            loading={loading}
        />
    )
}
