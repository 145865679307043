import { gql, useApolloClient, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { Add } from "@mui/icons-material";
import { Button, Dialog, DialogContent } from "@mui/material";
import { GridColDef, GridToolbarContainer } from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import { appUserVar } from "../../../stores/ReactiveVariables";
import { FormGrid } from "../../atoms/FormGrid";
import RemoveButton from "../../atoms/RemoveButton";
import { HrDataGrid } from "../../molecules/HrDataGrid";
import { ServiceCategoryForm } from "./ServiceCategoryForm";

const GET_SERVICE_CATEGORIES_QUERY = gql`
    query ServiceCategories {
        ServiceCategories {
            Id
            Name
        }
    }
`;

const DELETE_SERVICE_CATEGORY_MUTATION = gql`
    mutation DeleteServiceCategory($Id: uniqueidentifier = "") {
        delete_ServiceCategories_by_pk(Id: $Id) {
            Id
        }
    }  
`;

const UPDATE_SERVICE_CATEGORY_MUTATION = gql`
    mutation UpdateServiceCategory($Id: uniqueidentifier = "", $object: ServiceCategories_set_input = {Id: "", Name: ""}) {
        update_ServiceCategories_by_pk(pk_columns: {Id: $Id}, _set: $object) {
            __typename
            Id
            Name
        }
    } 
`

const CREATE_SERVICE_CATEGORY_MUTATION = gql`
    mutation InsertServiceCategory($object: ServiceCategories_insert_input = {Id: "", Name: ""}) {
        insert_ServiceCategories_one(object: $object) {
            __typename
            Id
            Name
        }
    }
`

export const ServiceCategoriesDataGrid: React.FC<{}> = (props) => {
    const user = useReactiveVar(appUserVar);
    const [selectedServiceCategory, setSelectedServiceCategory] = useState('');
    const { data, loading, error } = useQuery(GET_SERVICE_CATEGORIES_QUERY);
    const [createServiceCategory] = useMutation(CREATE_SERVICE_CATEGORY_MUTATION);
    const [updateServiceCategory] = useMutation(UPDATE_SERVICE_CATEGORY_MUTATION);
    const [deleteServiceCategory] = useMutation(DELETE_SERVICE_CATEGORY_MUTATION);
    const client = useApolloClient();
    const deleteServiceCategoryHandler = (Id: string) => {
        deleteServiceCategory({
            variables: {
                Id
            },
            optimisticResponse: {
                delete_ServiceRates_by_pk: {
                    Id,
                    __typename: "ServiceCategories"
                }
            },
            update(cache, { data }) {
                cache.evict({
                    id: `ServiceCategories:${Id}`
                });
                cache.gc();
            },
            onError(error) {
                console.error(error);
                client.refetchQueries({
                    include: "active"
                });
            }
        });
    }

    const handleSave = (object: any) => {
        if (selectedServiceCategory === 'new') {
            createServiceCategory({
                variables: {
                    object
                },
                optimisticResponse: {
                    insert_ServiceRates_one: {
                        __typename: 'ServiceCategories',
                        ...object
                    }
                },
                update: (cache, { data }) => {
                    cache.updateQuery({
                        query: GET_SERVICE_CATEGORIES_QUERY
                    },
                    (cacheData: any) => ({
                        ServiceCategories: [...cacheData.ServiceCategories, data.insert_ServiceCategories_one]
                    }))
                }
            })
        } else {
            updateServiceCategory({
                variables: {
                    Id: object.Id,
                    object
                },
                optimisticResponse: {
                    update_ServiceRates_by_pk: {
                        __typename: 'ServiceCategories',
                        ...object
                    }
                }
            });
        }
        setSelectedServiceCategory('');
    }
    const rows = data?.ServiceCategories ?? [];
    const selectedServiceCategoryObject = rows.find((i: any) => i.Id === selectedServiceCategory);
    const columns: GridColDef[] = [
        {
            field: "removeServiceCategory",
            headerName: " ",
            width: 50,
            renderCell: (rows) => {
                const serviceCategoryId = rows.id;
                return (
                    <RemoveButton
                        title="Service Category"
                        onClick={() => deleteServiceCategoryHandler(serviceCategoryId as string)}
                    />
                );
            },
            headerClassName: "billables-grid-header",
        },
        // {
        //     field: "id",
        //     headerName: "ID",
        //     width: 100,
        //     headerClassName: "billables-grid-header",
        // },
        {
            field: "Name",
            headerName: "Service Item",
            width: 250,
            editable: false,
            headerClassName: "billables-grid-header",
        }
    ]

    if (error) {
        console.error(error);
    }
    return (
        <>
            <HrDataGrid
                loading={loading}
                rows={rows}
                columns={columns}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                disableRowSelectionOnClick
                onRowDoubleClick={(model, details) => {
                    setSelectedServiceCategory(model.id as string);
                }}
                components={{
                    Toolbar: () => <GridToolbarContainer>
                        <Button color="primary" startIcon={<Add />} onClick={() => setSelectedServiceCategory('new')}>
                            Add Service Category
                        </Button>
                    </GridToolbarContainer>
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'Name', sort: 'asc' }],
                    },
                    columns: {
                        columnVisibilityModel: {
                            removeServiceCategory: user?.roles?.some(e => e.name === "BidBook Admin") ?? false
                        }
                    }
                }}
            />
            <Dialog
                open={selectedServiceCategory !== ''}
                onClose={() => setSelectedServiceCategory('')}
            >
                <DialogContent>

                    <Button
                        onClick={() => {
                            setSelectedServiceCategory('');
                        }}
                        variant="contained"
                        color="primary"
                        className="modal-exit"
                    >
                        x
                    </Button>
                    <FormGrid>
                        <ServiceCategoryForm item={selectedServiceCategoryObject} onSave={handleSave} onCancel={() => setSelectedServiceCategory('')} />
                    </FormGrid>
                </DialogContent>
            </Dialog>
        </>
    );
}