import { BidQuoteFactors } from "../../api/graphqlqueries/quotefactorsquery";
import { BidQuoteService, MaterialCalculations, QuoteFactors, getServiceTotals, sumMaterialCalculations, sumMaterialCosts } from "./adders";
import { BidQuoteItem, calculateSurveySegments, getSurveySegmentCalculations, SurveySegmentCalculations } from "./segments";

export interface SurveyCalculations {
    Survey: number;
    Miles: number;
    Mob: number;
    MobWoPD: number;
    Setup: number;
    Boat: number;
    AverageMiles: number;
    Days: number;
    TotalAddersPrice: number;
    TotalMarkupPrice: number;
    TotalDiscountedPrice: number;
    TotalPrice: number;
    TotalPriceWoMaterials: number;
    GrandTotal: number;
    TotalCost: number;
    TotalCostWoMaterials: number;
}

export interface SurveyPricingCalculations {
    TotalDays: number;
    TotalMiles: number;
    TotalPrice: number;
    TotalMilesForAverage: number;
    TotalMilesPriceForAverage: number;
    TotalDiscountedMilesPriceForAverage: number;
    TotalDaysForAverage: number;
    TotalDaysPriceForAverage: number;
    TotalDiscountedDaysPriceForAverage: number;
    AveragePricePerMile: number;
    AveragePricePerDay: number;
    DiscountedAveragePricePerMile: number;
    DiscountedAveragePricePerDay: number;
    PricePerDay: number;
    TotalDiscountPrice: number;
    DiscountAveragePrice: number;
    DiscountPricePerDay: number;
    GrandTotal: number;
    DiscountGrandTotal: number;
}

export interface DivisionCalculations {
    Days: number;
    Mob: number;
    MobWoPD: number;
    Setup: number;
    Rest: number;
    TotalDays: number;
    AverageRate: number;
    MaterialItemsTotal: number;
    TotalMiles: number;
    TotalPrice: number;
    TotalPriceWoMaterials: number;
    TotalCost: number;
    TotalCostWoMaterials: number;
    Equipment: number;
    Mileage: number;
    Locations: number;
    TotalDiscountPrice: number;
}

export function getSurveyDivisionCalculation(bidQuoteItems: BidQuoteItem[], bidQuoteFactors: BidQuoteFactors): SurveyCalculations {
    const totals: SurveyCalculations = bidQuoteItems?.reduce((pv: SurveyCalculations, cv: BidQuoteItem) => {
        const addersTotals: MaterialCalculations = sumMaterialCalculations(cv.BidQuoteMaterials);
        const addersCost = sumMaterialCosts(cv.BidQuoteMaterials);
        const segTotals = getSurveySegmentCalculations(cv, bidQuoteFactors);
        pv.Miles += Number(cv.Miles);
        pv.Survey += Number(cv.AverageMiles) !== 0 ? (Number(cv.Miles) / Number(cv.AverageMiles)) : 0;
        pv.Mob += Number(cv.Mob);
        pv.MobWoPD += Number(cv.MobWoPD);
        pv.Setup += Number(cv.Setup);  
        pv.Boat += Number(cv.Boat);
        pv.AverageMiles += Number(cv.AverageMiles);
        pv.Days = Number(pv.Mob) + Number(pv.Setup) + Number(pv.Boat) + Number(pv.Survey);

        pv.TotalAddersPrice += addersTotals?.TotalPrice ?? 0;
        pv.TotalMarkupPrice += addersTotals?.TotalMarkupPrice ?? 0;
        pv.TotalDiscountedPrice += segTotals.TotalDiscountPrice;
        pv.TotalPrice += segTotals.TotalPrice;
        pv.TotalPriceWoMaterials += segTotals.TotalPriceWoMaterials ?? 0;
        pv.GrandTotal += segTotals.GrandTotal ?? 0;
        pv.TotalCost += addersCost + Number(segTotals.TotalCost ?? 0);
        pv.TotalCostWoMaterials += Number(segTotals.TotalCost ?? 0);
        return pv;
    }, {
        Survey: 0,
        Miles: 0,
        Mob: 0,
        MobWoPD: 0,
        Setup: 0,
        Boat: 0,
        AverageMiles: 0,
        Days: 0,
        TotalAddersPrice: 0,
        TotalMarkupPrice: 0,
        TotalDiscountedPrice: 0,
        TotalPrice: 0,
        TotalPriceWoMaterials: 0,
        GrandTotal: 0,
        TotalCost: 0,
        TotalCostWoMaterials: 0
    });
    if (bidQuoteItems.length > 0)
        totals.AverageMiles = totals.AverageMiles / bidQuoteItems.length;
    return totals;
}

export function getSurveyPricingCalculation(bidQuoteItems: BidQuoteItem[], bidQuoteFactors: BidQuoteFactors): SurveyPricingCalculations {
    const totals: SurveyPricingCalculations = bidQuoteItems?.reduce((pv: SurveyPricingCalculations, cv: BidQuoteItem) => {
        const addersTotals: MaterialCalculations = sumMaterialCalculations(cv.BidQuoteMaterials);
        const itemTotal: SurveySegmentCalculations = calculateSurveySegments(cv, bidQuoteFactors);
        pv.TotalDays += Number(itemTotal?.TotalDays ?? 0);
        pv.TotalMiles += Number(cv?.Miles ?? 0);
        pv.TotalPrice += Number(itemTotal?.TotalPrice ?? 0);
        if (cv.PerDay) {
            pv.TotalDaysForAverage += Number(itemTotal?.TotalDays ?? 0);
            pv.TotalDaysPriceForAverage += Number(itemTotal?.TotalPrice ?? 0);
            pv.TotalDiscountedDaysPriceForAverage += Number(itemTotal?.TotalDiscountPrice ?? 0);
        } else {
            pv.TotalMilesForAverage += Number(cv?.Miles ?? 0);
            pv.TotalMilesPriceForAverage += Number(itemTotal?.TotalPrice ?? 0);
            pv.TotalDiscountedMilesPriceForAverage += Number(itemTotal?.TotalDiscountPrice ?? 0);
        }
        pv.AveragePricePerDay = pv.TotalDaysForAverage !== 0 ? pv.TotalDaysPriceForAverage / pv.TotalDaysForAverage : 0;
        pv.AveragePricePerMile = pv.TotalMilesForAverage !== 0 ? pv.TotalMilesPriceForAverage / pv.TotalMilesForAverage : 0;
        pv.DiscountedAveragePricePerDay = pv.TotalDaysForAverage !== 0 ? pv.TotalDiscountedDaysPriceForAverage / pv.TotalDaysForAverage : 0;
        pv.DiscountedAveragePricePerMile = pv.TotalMilesForAverage !== 0 ? pv.TotalDiscountedMilesPriceForAverage / pv.TotalMilesForAverage : 0;
        pv.PricePerDay = pv.TotalDays !== 0 ? pv.TotalPrice / pv.TotalDays : 0;
        pv.TotalDiscountPrice += Number(itemTotal?.TotalDiscountPrice ?? 0);
        pv.DiscountAveragePrice = pv.TotalMiles !== 0 ? pv.TotalDiscountPrice / pv.TotalMiles : 0;
        pv.DiscountPricePerDay = pv.TotalDays !== 0 ? pv.TotalDiscountPrice / pv.TotalDays : 0;
        pv.GrandTotal += Number(itemTotal?.TotalPrice ?? 0) + Number(addersTotals?.TotalMarkupPrice ?? 0);
        pv.DiscountGrandTotal += Number(itemTotal?.TotalDiscountPrice ?? 0) + Number(addersTotals?.TotalMarkupPrice ?? 0); 
        return pv;
    }, {
        TotalDays: 0,
        TotalMiles: 0,
        TotalPrice: 0,
        TotalMilesForAverage: 0,
        TotalMilesPriceForAverage: 0,
        TotalDiscountedMilesPriceForAverage: 0,
        TotalDaysForAverage: 0,
        TotalDaysPriceForAverage: 0,
        TotalDiscountedDaysPriceForAverage: 0,
        AveragePricePerMile: 0,
        AveragePricePerDay: 0,
        DiscountedAveragePricePerMile: 0,
        DiscountedAveragePricePerDay: 0,
        PricePerDay: 0,
        TotalDiscountPrice: 0,
        DiscountAveragePrice: 0,
        DiscountPricePerDay: 0,
        GrandTotal: 0,
        DiscountGrandTotal: 0
    });
    return totals;
}

export function getDivisionCalculation(bidQuoteItems: BidQuoteItem[], quoteFactors: QuoteFactors): DivisionCalculations {
    const serviceItems = bidQuoteItems.flatMap((bidQuoteItem: BidQuoteItem) => bidQuoteItem?.BidQuoteConstructionServices?.map((bqcs: BidQuoteService) => ({ Area: bidQuoteItem?.Area, ...bqcs })));
    let rateSum = 0;
    const totals: DivisionCalculations = serviceItems.reduce((pv: DivisionCalculations, cv) => {
        const serviceTotals = getServiceTotals(cv as BidQuoteService, quoteFactors, cv?.Area ?? "");
        const serviceRate = cv?.OverrideRate ? cv?.Rate ?? 0 : cv?.BidQuoteRate?.Rate ?? 0;
        rateSum += (serviceRate ?? 0);
        pv.Days += (cv?.Days ?? 0);
        pv.Mob += (cv?.Mob ?? 0);
        pv.MobWoPD += (cv?.MobWoPD ?? 0);
        pv.Setup += (cv?.Setup ?? 0);
        pv.Rest += (cv?.Rest ?? 0);
        pv.AverageRate = serviceItems.length !== 0 ? rateSum / serviceItems.length : 0;
        pv.Equipment += (cv?.Equipment ?? 0);
        pv.Mileage += (cv?.Mileage ?? 0);
        pv.MaterialItemsTotal += 0;
        pv.TotalDays += serviceTotals.TotalDays;
        pv.TotalPrice += (serviceTotals?.TotalDiscountPrice ?? 0);
        pv.TotalPriceWoMaterials += serviceTotals?.TotalDiscountPrice ?? 0;
        pv.TotalDiscountPrice += serviceTotals.TotalDiscountPrice ?? 0;
        pv.Locations += (serviceTotals?.Locations ?? 0);
        pv.TotalCost += (serviceTotals?.TotalCost ?? 0);
        pv.TotalCostWoMaterials += serviceTotals?.TotalCost ?? 0;
        return pv;
    }, {
        Days: 0,
        Mob: 0,
        MobWoPD: 0,
        Setup: 0,
        Rest: 0,
        TotalDays: 0,
        AverageRate: 0,
        MaterialItemsTotal: 0,
        TotalMiles: 0,
        TotalPrice: 0,
        TotalPriceWoMaterials: 0,
        TotalCost: 0,
        TotalCostWoMaterials: 0,
        Equipment: 0,
        Mileage: 0,
        Locations: 0,
        TotalDiscountPrice: 0,
    });

    const materialTotal = bidQuoteItems
        .flatMap(i => i.BidQuoteMaterials)
        .reduce((total, adderItem) => {
            const adderRate = adderItem?.OverrideRate ? adderItem?.Rate ?? 0 : adderItem?.BidQuoteBillable?.Cost ?? 0;
            return total + (Number(adderItem?.Quantity ?? 0) * Number(adderRate) * Number(adderItem?.Duration ?? 0) * (1 + (adderItem?.Markup ?? 0)))
        }, 0)   
        

    const materials = bidQuoteItems.flatMap(i => i.BidQuoteMaterials?.filter(m => m.Type === "Material")).length;


    const materialTotalWoMaterialItems = bidQuoteItems
        .flatMap(i => i.BidQuoteMaterials?.filter(m => m.Type !== 'Material'))
        .reduce((total, adderItem) => {
            const adderRate = adderItem?.OverrideRate ? adderItem?.Rate ?? 0 : adderItem?.BidQuoteBillable?.Cost ?? 0;
            return total + (Number(adderItem?.Quantity ?? 0) * Number(adderRate) * Number(adderItem?.Duration ?? 0) * (1 + (adderItem?.Markup ?? 0)))
        }, 0)


    const materialCost = bidQuoteItems
        .flatMap(i => i.BidQuoteMaterials)
        .filter(i => i?.Type !== 'Equipment')
        .reduce((total, adderItem) => {
            const adderRate = adderItem?.OverrideRate ? adderItem?.Rate ?? 0 : adderItem?.BidQuoteBillable?.Cost ?? 0;
            return total + (Number(adderItem?.Quantity ?? 0) * Number(adderRate) * Number(adderItem?.Duration ?? 0))
        }, 0)



    totals.TotalPrice += materialTotal;
    totals.TotalPriceWoMaterials += materialTotalWoMaterialItems;
    totals.TotalDiscountPrice += materialTotal;
    totals.TotalCost += materialCost
    totals.MaterialItemsTotal += materials
    return totals;
}