import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { Grid, Box } from "@mui/material";
import { styled } from "@mui/system";
import { v4 } from 'uuid';
import { GetQuoteAddersQueryResult, GET_QUOTE_ADDERS } from "../../../../api/graphqlqueries/getquoteadders";
import { CREATE_MATERIAL_ITEM_MUTATION } from "../../../../api/graphqlqueries/materialserviceitemmutations";
import { Colors } from "../../../../constants/colors";
import { disableForStatus } from "../../../../stores/ReactiveVariables";
import { HighRidgeColorButton } from "../../../atoms/HighRidgeColorButton";
import { QuoteLineItemBox } from "../../../atoms/QuoteLineItemBox";
import { StyledHeader } from '../../../atoms/StyledHeader';
import LineItemLoading from '../../../molecules/LineItemLoading';
import { QuoteAddersTotals } from "../Totals/BidbookQuoteAddersTotals";
import { QuoteAdderItem } from "./BidbookQuoteAdders";
import { GET_QUOTE_ADDERS_ITEMS } from "../../../../api/graphqlqueries/getquoteaddersitems";

interface QuoteAdderLineItemsProps {
    id: string;
}

export const QuoteAdderLineItems = ({ id }: QuoteAdderLineItemsProps) => {
    const disabledForStatus = useReactiveVar(disableForStatus);
    const { data, error } = useQuery<GetQuoteAddersQueryResult>(GET_QUOTE_ADDERS, {
        variables: {
            BidQuoteId: id,
        },
        fetchPolicy: 'cache-and-network',
    });

    const [addMaterialItem] = useMutation(CREATE_MATERIAL_ITEM_MUTATION);

    if (error) {
        console.error(error);
    }
    if (!data) return <LineItemLoading />

    const saveNewMaterialItem = () => {
        const newItemId = v4();
        const addInfo = { 
            Id: newItemId, 
            BidQuoteId: id, 
            Ordinal: data?.BidQuoteMaterials?.length,
            LineBreakAfter: false,
            OverrideRate: false,
            AdderHasCost: false
        };
        addMaterialItem({
            variables: { object: addInfo },
            refetchQueries: [{
                query: GET_QUOTE_ADDERS,
                variables: { BidQuoteId: id }
            },
            {
                query: GET_QUOTE_ADDERS_ITEMS,
                variables: { BidQuoteId: id }
            }]
        });
    }

    const quoteItems = data.BidQuoteMaterials;
    return (

        <Grid item container direction="column">
            <StickyContainer>
                <Grid container direction="column"
                    sx={{
                        borderBottom: `2px solid ${Colors.gold}`,
                        padding: "6px 0",
                        margin: "0 0 4px"
                    }}
                >
                    <Grid item>
                        <StyledHeader>Adders Totals</StyledHeader>
                    </Grid>
                    <QuoteAddersTotals id={id} />
                </Grid>
            </StickyContainer>
            <Grid item container direction="column">
                {quoteItems.slice().sort((a, b) => a.Ordinal - b.Ordinal).map((si: any) => (
                    <QuoteLineItemBox
                        key={si.Id}
                        sx={{
                            margin: "4px 0 0",
                            "& + .MuiBox-root": {
                                borderTop: "none"
                            }
                        }}>
                        <QuoteAdderItem bidId={id} id={si.Id} />
                    </QuoteLineItemBox>
                ))}
            </Grid>
            <Grid item container direction="column" sx={{ marginTop: "2px" }}>
                <HighRidgeColorButton
                    type="button"
                    onClick={saveNewMaterialItem}
                    disabled={disabledForStatus}
                >
                    Add +
                </HighRidgeColorButton>
            </Grid>
        </Grid>
    )
}

const StickyContainer = styled(Box)({
    position: "sticky",
    top: "39px",
    width: "100%",
    backgroundColor: "#fff",
    zIndex: 3
})