import { Box, IconButton } from "@mui/material";
import { GridColDef } from '@mui/x-data-grid-pro';
import { HrDataGrid } from "../../molecules/HrDataGrid";
import React from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { gql, useMutation } from "@apollo/client";

interface JobsFileGridProps {
    data?: any;
    id?: any;
}

const DELETE_QUOTE_ATTACHMENT = gql`
mutation DeleteAttachment($id: uniqueidentifier = "") {
    update_QuoteAttachments(where: {Id: {_eq: $id}}, _set: {IsActive: false}) {
      returning {
        Id
      }
    }
  } 
`

const GET_FILES_QUERY = gql`
query GetFiles($KickoffPacketId: uniqueidentifier = "") {
  QuoteAttachments(where: {KickoffPacketId: {_eq: $KickoffPacketId}, IsActive: {_eq: true}}) {
    Id
    Filename
    Size
    CreateDate
    CreateUser {
      Id
      FirstName
      LastName
    }
  }
}
`;

export const JobsFileGrid: React.FC<JobsFileGridProps> = ({ data }) => {

        const [deleteAttachment, { loading: deleting }] = useMutation(DELETE_QUOTE_ATTACHMENT);

        const deleteFile = (id: string) => {
            if (!deleting) {
                if (window.confirm("Are you sure you want to delete this attachment?")) {
                    deleteAttachment({
                        variables: {
                            id: id
                        },
                        refetchQueries: [GET_FILES_QUERY]
                    })
                }
            }
        }

        const columns: GridColDef[] = [
            {
                field: "Filename",
                headerName: "File Name",
                width: 250,
                headerClassName: "billables-grid-header",
                editable: false,
            },
            {
                field: "Size",
                headerName: "Size",
                width: 250,
                valueGetter: (params) => `${(params.row.Size / 1000).toFixed(2)} KB`,
                editable: false,
                headerClassName: "billables-grid-header",
            },
            {
                field: "CreateDate",
                headerName: "Date Created",
                width: 175,
                editable: false,
                headerClassName: "billables-grid-header",
                valueFormatter: (params) => new Date(params.value as string).toLocaleDateString()
            },
            {
                field: "createdBy",
                headerName: "Created By",
                width: 200,
                headerClassName: "billables-grid-header",
                valueGetter: (params) => params.row?.CreateUser?.FirstName ? `${params.row?.CreateUser?.FirstName} ${params.row?.CreateUser?.LastName}` : `${params.row?.CreatedBy}`
            },
            {
                field: 'Delete',
                headerName: 'Delete',
                sortable: false,
                width: 100,
                disableColumnMenu: true,
                renderCell: (params) => {
                    return (<IconButton size="small" onClick={() => deleteFile(params.row.Id)}>
                        <DeleteIcon />
                    </IconButton>
                    )
                },
                align: 'center',
                headerAlign: 'center'
            }
        ];

        return (
            <Box sx={styles.container}>
                <HrDataGrid
                    rows={data}
                    columns={columns}
                    loading={!data}
                    getRowId={(row) => row.Id}
                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    disableRowSelectionOnClick
                />
            </Box>
        );
    };




const styles = {
    container: {
        height: "60vh",
        padding: "0",
        margin: "10px 0px",
        border: "none",
        borderRadius: "0 0 5px 5px",
    }
};