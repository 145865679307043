import { BidQuoteRate } from "../../api/graphqlqueries/bidquoterates";
import { BidQuoteFactors } from "../../api/graphqlqueries/quotefactorsquery";
import { getMaterialCalculations, BidQuoteMaterial, BidQuoteService, QuoteFactors, ServiceCalculations, MaterialCalculations, sumMaterialCalculations, getServiceTotals } from "./adders";

export interface SurveySegmentCalculations {
    TotalPriceWoMaterials: number;
    Survey: number;
    TotalPrice: number;
    TotalMarkupPrice?: number;
    TotalDiscountPrice: number;
    TotalDays: number;
    MilesPerDay: number;
    PricePerMile: number;
    DiscountPricePerMile: number;
    DiscountPricePerDay: number;
    AveragePerMile: number;
    TotalAddersPrice?: number;
    PricePerDay: number;
    GrandTotal?: number;
    TotalCost?: number;
    TotalCostWoMaterials?: number;
}

export interface SegmentCalculations {
    Locations?: number;
    SegmentTotal: number;
    SegmentMarkupTotal?: number;
}



export interface BidQuoteItem {
    Id?: string;
    Segment?: string;
    Area?: string;
    Miles?: number;
    AverageMiles?: number;
    Mob?: number;
    MobWoPD?: number;
    Setup?: number;
    Boat?: number;
    CrewType?: string,
    CrewQuantity?: number,
    Rate?: number;
    OverrideRate?: boolean;
    BidQuoteRate?: BidQuoteRate;
    Discount?: number;
    Type?: string;
    BidQuoteMaterials?: BidQuoteMaterial[];
    BidQuoteConstructionServices?: BidQuoteService[];
    PerDay?: boolean;
}

export function getSurveySegmentCalculations(bidQuoteItem: BidQuoteItem, bidQuoteFactors: BidQuoteFactors | undefined): SurveySegmentCalculations {
    const materialsCalculations: MaterialCalculations = sumMaterialCalculations(bidQuoteItem?.BidQuoteMaterials ?? []);
    const { TimecardMileageRate, PerDiemCost, SurveyFieldHours, SurveyMileage } = bidQuoteFactors ?? { TimecardMileageRate: 0, PerDiemCost: 0, SurveyFieldHours: 0, SurveyMileage: 0};
    const survey = Number(bidQuoteItem?.AverageMiles) !== 0 ? Number(bidQuoteItem?.Miles) / Number(bidQuoteItem?.AverageMiles) : 0;
    const totalDays = survey + Number(bidQuoteItem?.Setup) + Number(bidQuoteItem?.Mob) + Number(bidQuoteItem?.Boat);
    const surveyRate = bidQuoteItem?.OverrideRate ? Number(bidQuoteItem?.Rate) : Number(bidQuoteItem?.BidQuoteRate?.Rate ?? 0);
    const totalPrice = surveyRate * totalDays * (bidQuoteFactors?.SurveyFieldHours ?? 0);
    const totalDaysWoPD = totalDays - (bidQuoteItem?.MobWoPD ?? 0);
    const totalCost = (
        Number(bidQuoteItem?.BidQuoteRate?.Cost ?? 0) * 1.225 * totalDays * SurveyFieldHours)
        + (Number(PerDiemCost ?? 0) * (bidQuoteItem?.CrewQuantity ?? 0) * totalDaysWoPD)
        + ((TimecardMileageRate ?? 0) * (SurveyMileage ?? 0));
    const totalDiscountPrice = totalPrice * (1 - (bidQuoteItem?.Discount ?? 0)); 
    return {
        Survey: survey,
        TotalPrice: totalPrice + Number(materialsCalculations?.TotalPrice ?? 0),
        TotalPriceWoMaterials: totalDiscountPrice,
        TotalDiscountPrice: totalDiscountPrice,
        TotalMarkupPrice: totalPrice + Number(materialsCalculations?.TotalMarkupPrice ?? 0),
        TotalAddersPrice: materialsCalculations?.TotalPrice,
        TotalDays: totalDays,
        MilesPerDay: totalDays !== 0 ? (bidQuoteItem?.Miles ?? 0) / totalDays : 0,
        PricePerMile: bidQuoteItem?.Miles !== 0 && bidQuoteItem?.Miles !== undefined ? (materialsCalculations?.TotalPrice ?? 0) / bidQuoteItem?.Miles : 0,
        DiscountPricePerMile: bidQuoteItem?.Miles !== 0 && bidQuoteItem?.Miles !== undefined ? (materialsCalculations?.TotalPrice ?? 0) / bidQuoteItem?.Miles : 0,
        DiscountPricePerDay: 0,
        AveragePerMile: 0,
        GrandTotal: totalDiscountPrice + (materialsCalculations?.TotalMarkupPrice ?? 0),
        TotalCost: totalCost
    } as SurveySegmentCalculations;
}

export function getSurveySegmentPricingCalculations(bidQuoteItem: BidQuoteItem | undefined, totalMiles: number, quoteFactors: QuoteFactors): SurveySegmentCalculations {
    const totalAddersPrice: number | undefined = bidQuoteItem?.BidQuoteMaterials?.reduce((pv: number, cv: BidQuoteMaterial) => {
        const addersCalculations: MaterialCalculations = getMaterialCalculations(cv);
        return pv + (addersCalculations.TotalMarkupPrice ?? 0);
    }, 0);
    const survey = Number(bidQuoteItem?.AverageMiles) !== 0 ? Number(bidQuoteItem?.Miles) / Number(bidQuoteItem?.AverageMiles) : 0;
    const totalDays = survey + Number(bidQuoteItem?.Setup) + Number(bidQuoteItem?.Mob) + Number(bidQuoteItem?.Boat);
    const surveyRate = bidQuoteItem?.OverrideRate ? Number(bidQuoteItem?.Rate) : Number(bidQuoteItem?.BidQuoteRate?.Rate ?? 0);
    const totalPrice = surveyRate * totalDays * quoteFactors.SurveyFieldHours;
    const totalDiscountPrice = totalPrice * (1 - (bidQuoteItem?.Discount ?? 0));
    return {
        Survey: survey,
        TotalPrice: totalPrice,
        TotalDiscountPrice: totalDiscountPrice,
        TotalDays: totalDays,
        MilesPerDay: totalDays !== 0 ? (bidQuoteItem?.Miles ?? 0) / totalDays : 0,
        PricePerMile: bidQuoteItem?.Miles !== 0 && bidQuoteItem?.Miles !== undefined ? (totalPrice ?? 0) / bidQuoteItem?.Miles : 0,
        PricePerDay: totalDays !== 0 ? totalPrice / totalDays : 0,
        DiscountPricePerMile: bidQuoteItem?.Miles !== 0 && bidQuoteItem?.Miles !== undefined ? (totalDiscountPrice ?? 0) / bidQuoteItem?.Miles : 0,
        DiscountPricePerDay: totalDays !== 0 ? totalDiscountPrice / totalDays : 0,
        AveragePerMile: totalMiles !== 0 ? totalDiscountPrice / totalMiles : 0,
        TotalAddersPrice: totalAddersPrice
    } as SurveySegmentCalculations;
}

export function getSegmentCalculations(bidQuoteItem: BidQuoteItem, quoteFactors: QuoteFactors): SegmentCalculations {
    const service = bidQuoteItem?.BidQuoteConstructionServices?.reduce((pv: SegmentCalculations, cv: BidQuoteService) => {
        const service: ServiceCalculations = getServiceTotals(cv, quoteFactors, bidQuoteItem?.Area ?? "");
        if (pv.Locations !== undefined) pv.Locations += service.Locations ?? 0;
        pv.SegmentTotal += service.TotalPrice;
        return pv;
    }, { Locations: 0, SegmentTotal: 0 });

    const material = bidQuoteItem?.BidQuoteMaterials?.reduce((pv: SegmentCalculations, cv: BidQuoteMaterial) => {
        const material: MaterialCalculations = getMaterialCalculations(cv);
        pv.SegmentTotal += material.TotalPrice;
        pv.SegmentMarkupTotal! += material.TotalPrice * (1 + cv.Markup);
        return pv;
    }, { SegmentTotal: 0, SegmentMarkupTotal: 0 });
    return {
        Locations: service?.Locations,
        SegmentTotal: (service?.SegmentTotal ?? 0) + (material?.SegmentTotal ?? 0),
        SegmentMarkupTotal: (service?.SegmentTotal ?? 0) + (material?.SegmentMarkupTotal ?? 0)
    } as SegmentCalculations
}

export function calculateSurveySegments(bidQuoteItem: BidQuoteItem, bidQuoteFactors: BidQuoteFactors | undefined): SurveySegmentCalculations {
    const materialsCalculations: MaterialCalculations = sumMaterialCalculations(bidQuoteItem?.BidQuoteMaterials ?? []);
    const { TimecardMileageRate, PerDiemCost, SurveyFieldHours, SurveyMileage } = bidQuoteFactors ?? { TimecardMileageRate: 0, PerDiemCost: 0, SurveyFieldHours: 0, SurveyMileage: 0};
    const survey = Number(bidQuoteItem?.AverageMiles) !== 0 ? Number(bidQuoteItem?.Miles) / Number(bidQuoteItem?.AverageMiles) : 0;
    const totalDays = survey + Number(bidQuoteItem?.Setup) + Number(bidQuoteItem?.Mob) + Number(bidQuoteItem?.Boat);
    const surveyRate = bidQuoteItem?.OverrideRate ? Number(bidQuoteItem?.Rate) : Number(bidQuoteItem?.BidQuoteRate?.Rate ?? 0);
    const totalPrice = surveyRate * totalDays * (bidQuoteFactors?.SurveyFieldHours ?? 0);
    const totalDaysWoPD = totalDays - (bidQuoteItem?.MobWoPD ?? 0);
    const totalCost = (
        Number(bidQuoteItem?.BidQuoteRate?.Cost ?? 0) * 1.225 * totalDays * SurveyFieldHours)
        + (Number(PerDiemCost ?? 0) * (bidQuoteItem?.CrewQuantity ?? 0) * totalDaysWoPD)
        + ((TimecardMileageRate ?? 0) * (SurveyMileage ?? 0));
    const totalAddersPrice: number | undefined = bidQuoteItem?.BidQuoteMaterials?.reduce((pv: number, cv: BidQuoteMaterial) => {
        const addersCalculations: MaterialCalculations = getMaterialCalculations(cv);
        return pv + (addersCalculations.TotalMarkupPrice ?? 0);
    }, 0);
    const totalDiscountPrice = totalPrice * (1 - (bidQuoteItem?.Discount ?? 0)); 
    return {
        Survey: survey,
        TotalPrice: totalPrice + Number(materialsCalculations?.TotalPrice ?? 0),
        TotalPriceWoMaterials: totalDiscountPrice,
        TotalDiscountPrice: totalDiscountPrice,
        TotalMarkupPrice: totalPrice + Number(materialsCalculations?.TotalMarkupPrice ?? 0),
        TotalAddersPrice: totalAddersPrice,
        TotalDays: totalDays,
        MilesPerDay: totalDays !== 0 ? (bidQuoteItem?.Miles ?? 0) / totalDays : 0,
        PricePerMile: bidQuoteItem?.Miles !== 0 && bidQuoteItem?.Miles !== undefined ? (materialsCalculations?.TotalPrice ?? 0) / bidQuoteItem?.Miles : 0,
        DiscountPricePerMile: bidQuoteItem?.Miles !== 0 && bidQuoteItem?.Miles !== undefined ? (materialsCalculations?.TotalPrice ?? 0) / bidQuoteItem?.Miles : 0,
        DiscountPricePerDay: 0,
        AveragePerMile: 0,
        GrandTotal: totalDiscountPrice + (materialsCalculations?.TotalMarkupPrice ?? 0),
        TotalCost: totalCost
    } as SurveySegmentCalculations;
}