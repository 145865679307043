import {
    Navigate,
    Outlet,
} from "react-router-dom";
import { useStore } from "../../stores/StoreContext";
import { observer } from "mobx-react-lite";

interface Props {
    role: number;
}

function PrivateRouteComp({
    role,
}: Props) {
    const {
        userStore: { isLoggedIn, appUser },
    } = useStore();
    const isAuthed = isLoggedIn && appUser?.roles?.some(e => e.name === "BidBook Admin")
    return (
                isAuthed ?
                <Outlet />
                :
                <Navigate to="/login" />
    );
};

export const PrivateRoute = observer(PrivateRouteComp);
export default PrivateRoute;
