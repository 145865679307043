import { useQuery, useReactiveVar } from "@apollo/client";
import { Check } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import { GridColDef, GridFooter, useGridApiRef } from '@mui/x-data-grid-pro';
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { BidQuoteListQueryResult, BID_QUOTE_LIST_QUERY } from "../../../api/graphqlqueries/bidbooklistquery";
import { HrDataGrid } from "../../molecules/HrDataGrid";
import { addBusinessDays } from "../../../utils/BusinessDays";
import moment from "moment";
import { bidBookDataGridState } from "../../../stores/ReactiveVariables";
import 'moment-timezone';
import { orderBy } from "lodash"
import { BidbookPriceColumn } from "./BidbookPriceColumn";


const getDate = (dateString: string) => {

    if (dateString) {
        const [year, month, day] = dateString.split('-');
        return new Date(Number(year), Number(month) - 1, Number(day));
    }
    return new Date();
}

const convertDate = (dateString: string) => {
    var m = moment.utc(dateString);
    m.tz('America/Chicago');
    return m.local().toDate();
}


export const BidBookDataGrid: React.FC = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { loading, data } = useQuery<BidQuoteListQueryResult>(BID_QUOTE_LIST_QUERY, { fetchPolicy: 'cache-and-network' });

    const apiRef = useGridApiRef();

    const dataGridState = useReactiveVar(bidBookDataGridState);

    useEffect(() => {
        try {
            if (dataGridState) {
                apiRef.current.restoreState(JSON.parse(dataGridState))
                apiRef.current.hideFilterPanel();
            }
        } catch (e) {
            console.log(e);
        }
    }, []);

    const columns: GridColDef[] = [
        {
            field: "JobName",
            headerName: "Project Name",
            width: 350,
            valueGetter: (params) => {
                const { JobNumber, Customer, BidDescription, BidQuoteExternalRevisions } = params.row;
                return `${Customer.ShortCode ? Customer.ShortCode : Customer.Name}_${JobNumber}_${BidDescription}_Rev_${BidQuoteExternalRevisions[0]?.ExternalRevision ?? 0}`
            },
            headerClassName: "billables-grid-header",
        },
        {
            field: "CustomerName",
            headerName: "Customer",
            width: 250,
            valueGetter: (params) => params.row.Customer?.Name ?? 'No Customer',
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "JobNumber",
            headerName: "Quote Number",
            width: 125,
            editable: false,
            headerClassName: "billables-grid-header",
            align: "center",
            headerAlign: "center"
        },
        {
            field: "totalPrice",
            headerName: "Total Price",
            width: 135,
            editable: false,
            headerClassName: "billables-grid-header",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return <BidbookPriceColumn Id={params.row.Id} />
            }
        },
        {
            field: "jobsCreated",
            headerName: "Job(s) Created",
            width: 130,
            editable: false,
            headerClassName: "billables-grid-header",
            renderCell: (params) => {
                const bqiJobsList = params.row.BidQuoteItems?.filter((bqi: any) => bqi.JobSegment !== null);
                return bqiJobsList.length > 0 ? <Check /> : "";
            },
            align: "center",
            headerAlign: "center"
        },
        {
            field: "CreateDate",
            headerName: "Date Created",
            width: 115,
            editable: false,
            headerClassName: "billables-grid-header",
            valueFormatter: (params) => convertDate(params.value as string).toLocaleDateString()
        },
        {
            field: "DueDate",
            headerName: "Due Date",
            width: 110,
            editable: false,
            headerClassName: "billables-grid-header",
            valueFormatter: (params) => getDate(params.value as string).toLocaleDateString()
        },
        {
            field: "projectManager",
            headerName: "Project Manager",
            width: 150,
            headerClassName: "billables-grid-header",
            valueGetter: (params) => {
                if (!params.row?.Id) return ""
                return `${params.row?.ProjectManager?.FirstName ? params.row?.ProjectManager?.FirstName : ""} ${params.row?.ProjectManager?.LastName ? params.row?.ProjectManager?.LastName : ""}`
            }
        },
        {
            field: "bidBookLead",
            headerName: "Bid Book Lead",
            width: 150,
            headerClassName: "billables-grid-header",
            valueGetter: (params) => {
                if (!params.row?.Id) return ""
                return `${params.row?.BidBookUser?.FirstName ? params.row?.BidBookUser?.FirstName : ""} ${params.row?.BidBookUser?.LastName ? params.row?.BidBookUser?.LastName : ""}`
            }
        },
        {
            field: "createdBy",
            headerName: "Created By",
            width: 150,
            headerClassName: "billables-grid-header",
            valueGetter: (params) => `${params.row?.CreateUser?.FirstName} ${params.row?.CreateUser?.LastName}`
        },
        {
            field: "Division",
            headerName: "Division(s)",
            align: "center",
            width: 150,
            editable: false,
            headerClassName: "billables-grid-header",
            renderCell: (params) => {
                let divisions = "";
                if (params?.row.Survey?.aggregate?.count > 0) divisions += "S";
                if (params?.row.Construction?.aggregate?.count > 0) divisions += "C";
                if (params?.row.Technician?.aggregate?.count > 0) divisions += "T";
                if (params?.row.Inspection?.aggregate?.count > 0) divisions += "I";
                return divisions;
            },
            valueGetter: (params) => {
                let divisions = "";
                if (params?.row.Survey?.aggregate?.count > 0) divisions += "S";
                if (params?.row.Construction?.aggregate?.count > 0) divisions += "C";
                if (params?.row.Technician?.aggregate?.count > 0) divisions += "T";
                if (params?.row.Inspection?.aggregate?.count > 0) divisions += "I";
                return divisions;
            }
        }
    ];
    const viewStatus = params.status;
    const warningStatus = (viewStatus === "Draft" || viewStatus === "Pending" || viewStatus === "Approved");
    const rows = orderBy(!data ? [] : data?.BidQuotes?.filter((bq: any) => bq?.BidQuoteStatus[0]?.Status === viewStatus), ['DueDate'], ['asc']);

    return (
        <Box sx={styles.container}>
            <HrDataGrid
                apiRef={apiRef}
                rows={rows}
                columns={columns}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                disableRowSelectionOnClick
                loading={loading}
                getRowId={(row) => row.Id}
                onRowDoubleClick={(row) => navigate(`/Bidbook/details/${row.id}`)}
                initialState={{
                    ...apiRef.current.state
                }}
                columnVisibilityModel={{
                    projectManager: viewStatus === "Awarded" || viewStatus === "Archived",
                    jobsCreated: viewStatus === "Awarded" || viewStatus === "Archived",
                }}
                components={{ Footer: warningStatus ? CustomGridFooter : GridFooter }}
                onFilterModelChange={() => {
                    const state = apiRef.current.exportState();
                    bidBookDataGridState(JSON.stringify(state));
                }}
                onSortModelChange={() => {
                    const state = apiRef.current.exportState();
                    bidBookDataGridState(JSON.stringify(state));
                }}
                getRowClassName={(params) => {
                    let bidDueDateStatus: "Caution" | "Imminent" | "Overdue" | "" = "";
                    const currentDateTime: number = new Date().getTime();
                    const dueDate: Date = new Date(`${params?.row?.DueDate} 17:00`);
                    const dueDateTime: number = dueDate.getTime();
                    const overdueCautionDateTime: number = addBusinessDays(dueDate, -5).getTime();
                    const overdueImminentDateTime: number = addBusinessDays(dueDate, -1).getTime();
                    if (warningStatus) {
                        if (currentDateTime >= overdueCautionDateTime && currentDateTime < overdueImminentDateTime) {
                            bidDueDateStatus = "Caution";
                        } else if (currentDateTime >= overdueImminentDateTime && currentDateTime < dueDateTime) {
                            bidDueDateStatus = "Imminent";
                        } else if (currentDateTime >= dueDateTime) {
                            bidDueDateStatus = "Overdue";
                        }
                    }
                    return `super-app-theme--dueDate${bidDueDateStatus}`;
                }}
                sx={{
                    " .MuiDataGrid-filterIcon": {
                        color: "#fff"
                    }
                }}
            />
        </Box>
    );
};

const CustomGridFooter = () => {
    return (
        <Grid container>
            <Grid container sx={styles.warningKey} columnSpacing={1} item xs={4}>
                <Grid item sx={styles.overDueKeyItem}>Overdue</Grid>
                <Grid item sx={styles.imminentKeyItem}>&lt; 1 Day</Grid>
                <Grid item sx={styles.cautionKeyItem}>&lt; 5 Days</Grid>
            </Grid>
            <Grid item xs={8}>
                <GridFooter />
            </Grid>
        </Grid>
    )
}

const warningKeyItem = {
    fontSize: "12px",
    padding: "8px",
}

const styles = {
    container: {
        height: "70vh",
        padding: "0",
        margin: "6px 0px",
        border: "none",
        borderRadius: "0 0 5px 5px",
        "& .super-app-theme--dueDateCaution": {
            backgroundColor: "#FFFCC9"
        },
        "& .super-app-theme--dueDateImminent": {
            backgroundColor: "#FCD299"
        },
        "& .super-app-theme--dueDateOverdue": {
            backgroundColor: "#FF8886"
        }
    },
    warningKey: {
        alignItems: "center",
        paddingLeft: "16px",
    },
    overDueKeyItem: {
        ...warningKeyItem,
        backgroundColor: "#FF8886",
        borderRadius: "5px 0 0 5px"
    },
    imminentKeyItem: {
        ...warningKeyItem,
        backgroundColor: "#FCD299"
    },
    cautionKeyItem: {
        ...warningKeyItem,
        backgroundColor: "#FFFCC9",
        borderRadius: "0 5px 5px 0"
    }
};