import { useQuery, useReactiveVar } from '@apollo/client';
import { Delete } from '@mui/icons-material';
import { Autocomplete, IconButton, InputAdornment, MenuItem, Tooltip, Box } from "@mui/material";
import { useFormik } from "formik";
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { v4 } from 'uuid';
import * as yup from 'yup';
import { BidQuoteRate, GetBidQuoteRatesQueryResult, GET_BIDQUOTE_RATES_QUERY } from '../../../../api/graphqlqueries/bidquoterates';
import { GET_QUOTE_FACTORS_QUERY } from '../../../../api/graphqlqueries/quotefactorsquery';
import { disableForStatus } from '../../../../stores/ReactiveVariables';
import { BidQuoteService, calculateService, QuoteFactors, ServiceCalculations, getServiceTotals } from "../../../../utils/calculations/adders";
import debounce from '../../../../utils/debounce';
import HrCheckBox from "../../../atoms/HrCheckBox";
import { HrCurrencyInput } from "../../../atoms/HrCurrencyInput";
import { HrDecimalInput } from "../../../atoms/HrDecimalInput";
import HrTextInput from "../../../atoms/HrTextInput";
import { QuoteLineItemFormGrid } from "../../../atoms/QuoteLineItemFormGrid";
import { DeleteWarning } from "../../../molecules/DeleteWarning";

interface QuoteConstructionService {
    Average?: number;
    BidQuoteItemId?: string;
    Days?: number;
    Discount?: number;
    Equipment?: number;
    Id?: string;
    Locations?: number;
    Mileage?: number;
    Mob?: number;
    MobWoPD?: number;
    PerDiem?: boolean;
    Rate?: number;
    OverrideRate?: boolean;
    BidQuoteRate?: BidQuoteRate;
    BidQuoteRateId?: string | null;
    Personnel?: number;
    Rest?: number;
    Setup?: number;
    Unit?: string;
    LineBreakAfter?: boolean;
}

interface QuoteConstructionServiceFormProps {
    item?: QuoteConstructionService;
    area: string;
    onSave: (item: QuoteConstructionService) => void;
    onDelete: (itemId: string) => void;
}

const QuoteConstructionServiceSchema = yup.object().shape({
    Average: yup.number(),
    Days: yup.number(),
    Discount: yup.number(),
    Equipment: yup.number(),
    Locations: yup.number(),
    Mileage: yup.number(),
    Mob: yup.number(),
    MobWoPD: yup.number(),
    PerDiem: yup.bool(),
    Rate: yup.number(),
    OverrideRate: yup.boolean(),
    BidQuoteRateId: yup.string().nullable(),
    Personnel: yup.number(),
    Rest: yup.number(),
    Setup: yup.number(),
    Unit: yup.string(),
    LineBreakAfter: yup.boolean(),
});

const width = {
    small: "8ch",
    medium: "12ch",
    large: "18ch",
    xLarge: "24ch"
}

export const QuoteConstructionServiceForm = ({ item: quoteItem, onSave, onDelete, area }: QuoteConstructionServiceFormProps) => {
    const params = useParams<{ id: string }>();
    const disabledForStatus = useReactiveVar(disableForStatus);
    const [selectedService, setSelectedService] = useState(null as BidQuoteRate | null);
    const { data } = useQuery<GetBidQuoteRatesQueryResult>(GET_BIDQUOTE_RATES_QUERY, {
        variables: {
            BidQuoteId: params.id,
        }
    });
    const { data: factorData } = useQuery(GET_QUOTE_FACTORS_QUERY, {
        variables: {
            Id: params.id
        }
    });

    const Id = quoteItem?.Id ?? v4();
    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: QuoteConstructionServiceSchema,
        initialValues: {
            Average: quoteItem?.Average ?? 0,
            Days: quoteItem?.Days ?? 0,
            Equipment: quoteItem?.Equipment ?? 0,
            Locations: quoteItem?.Locations ?? 0,
            Mileage: quoteItem?.Mileage ?? 0,
            Mob: quoteItem?.Mob ?? 0,
            MobWoPD: quoteItem?.MobWoPD ?? 0,
            PerDiem: quoteItem?.PerDiem ?? true,
            Rate: quoteItem?.Rate ?? 0,
            OverrideRate: quoteItem?.OverrideRate ?? false,
            BidQuoteRateId: quoteItem?.BidQuoteRateId ?? null,
            Personnel: quoteItem?.Personnel ? Number(quoteItem?.Personnel).toFixed(2) : 0,
            Rest: quoteItem?.Rest ?? 0,
            Setup: quoteItem?.Setup ?? 0,
            Unit: quoteItem?.Unit ?? '',
            LineBreakAfter: quoteItem?.LineBreakAfter ?? false,
        },
        onSubmit: (values) => {
            const dataSubmit = QuoteConstructionServiceSchema.cast(values);

            onSave({ Id, ...dataSubmit });
            formik.setSubmitting(false);
        }
    });

    const quoteFactors = factorData?.BidQuotes_by_pk;
    const { FieldHours } = quoteFactors;

    useEffect(() => {
        setSelectedService(s => data?.BidQuoteRates.find(r => r.Id === quoteItem?.BidQuoteRateId) ?? null);
    }, [data, setSelectedService, FieldHours, quoteItem]);

    const handleDelete = () => {
        onDelete(Id);
    }

    const updateData = (data: any) => {
        onSave({ Id, ...data });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        try {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value };
            const values = QuoteConstructionServiceSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.handleChange(e);
    }

    // eslint-disable-next-line
    const debouncer = useCallback(debounce((values: any) => updateData(values), 1000), []);


    const handleCheckChange = (e: ChangeEvent<HTMLInputElement>) => {
        try {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.checked };
            const values = QuoteConstructionServiceSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.setFieldValue(e.target.name, e.target.checked);
    }

    const handleOverrideChange = () => {
        try {
            const updatedValues = { ...formik.values, OverrideRate: !formik.values.OverrideRate };
            const values = QuoteConstructionServiceSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.setFieldValue('OverrideRate', !formik.values.OverrideRate);
    }

    const handleTypeSelect = (event: React.ChangeEvent<{}>, value: BidQuoteRate | null) => {
        setSelectedService(value);
        formik.setFieldValue("BidQuoteRateId", value?.Id ?? null);
        try {
            const updatedValues = { ...formik.values, BidQuoteRateId: value?.Id ?? null };
            const values = QuoteConstructionServiceSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
    };
    const { Locations, Average, Days, Mob, MobWoPD, Setup, Rest } = formik.values;
    const filteredData = data?.BidQuoteRates.filter(r => r.DailyHours === null || r.DailyHours === FieldHours || r.ServiceType === "Office");
    const formValues = { ...formik.values, BidQuoteRate: selectedService };
    const serviceCalculations: ServiceCalculations = getServiceTotals(formValues as BidQuoteService, quoteFactors as QuoteFactors, area);
    return (
        <QuoteLineItemFormGrid>
            <Autocomplete
                options={filteredData ? filteredData?.sort((a: any, b: any) => a.Name?.localeCompare(b.Name)) : []}
                value={selectedService ?? null}
                disabled={disabledForStatus}
                renderInput={(params: any) =>
                    <HrTextInput
                        {...params}
                        name="Type"
                        label="Type"
                        width={width.xLarge}
                    />
                }
                onChange={handleTypeSelect}
                getOptionLabel={(option: any) => option.Name}

            />

            <HrTextInput
                name='Locations'
                label='Location'
                width={width.small}
                onChange={handleChange}
                value={Locations}
                errors={formik.errors.Locations}
            />

            <HrTextInput
                name='Average'
                label='Average'
                width={width.small}
                onChange={handleChange}
                value={Average}
                errors={formik.errors.Average}
            />
            <HrTextInput
                name='Days'
                label='Days'
                width={width.small}
                onChange={handleChange}
                value={Days}
                errors={formik.errors.Days}
            />
            <HrTextInput
                name='Mob'
                label='Mob Days'
                width={width.small}
                onChange={handleChange}
                value={Mob}
                errors={formik.errors.Mob}
            />
            <HrTextInput
                name='MobWoPD'
                label='Less Per Diem'
                width={width.medium}
                onChange={handleChange}
                value={MobWoPD}
                errors={formik.errors.MobWoPD}
            />
            <HrTextInput
                name='Setup'
                label='Setup Days'
                width={width.small}
                onChange={handleChange}
                value={Setup}
                errors={formik.errors.Setup}
            />
            <Tooltip title="Rest days do not factor into the total price for the service." placement="top">
                <Box>
                    <HrTextInput
                        name='Rest'
                        label='Rest Days'
                        width={width.small}
                        onChange={handleChange}
                        value={Rest}
                        errors={formik.errors.Rest}
                    />
                </Box>
            </Tooltip>

            <HrTextInput
                name='Total'
                label='Total Days'
                width={width.small}
                disabled
                value={serviceCalculations.TotalDays}
            />
            <HrDecimalInput
                name='Hours'
                label='Daily Hours'
                width={width.small}
                disabled
                value={quoteFactors?.FieldHours}
            />
            <Tooltip title="This value is only used to calculate the Per Diem total. It is not used to calculate the base total for the service." placement="top">
                <Box>
                    <HrDecimalInput
                        name='Personnel'
                        label='Personnel'
                        value={formik.values.Personnel}
                        onChange={handleChange}
                        width={width.medium}
                        errors={formik.errors.Personnel}
                    />
                </Box>
            </Tooltip>
            <HrTextInput
                select
                name='Unit'
                label='Unit Type'
                width={width.medium}
                onChange={handleChange}
                value={formik.values.Unit}
                errors={formik.errors.Unit}
            >
                <MenuItem value="" />
                <MenuItem value="Day">Day</MenuItem>
                <MenuItem value="Hour">Hour</MenuItem>
            </HrTextInput>
            <HrCurrencyInput
                name='Rate'
                label='Rate'
                width={width.medium}
                onChange={selectedService ? handleChange : undefined}
                value={formik.values.OverrideRate ? formik.values.Rate : selectedService?.Rate ?? ''}
                errors={formik.errors.Rate}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <HrCheckBox
                                name="OverrideRate"
                                checked={formik.values.OverrideRate}
                                onChange={handleOverrideChange}
                                sx={{
                                    m: "0 -8px 0 0",
                                    "& .MuiCheckbox-root": {
                                        p: 0
                                    }
                                }}
                            />
                        </InputAdornment>
                    )
                }}
                InputLabelProps={{ shrink: true }}
                disabled={!formik.values.OverrideRate}
            />
            <HrCurrencyInput
                name='Equipment'
                label='Equipment'
                width={width.small}
                onChange={handleChange}
                value={formik.values.Equipment}
                errors={formik.errors.Equipment}
            />

            <HrCurrencyInput
                name='PerDiemTotal'
                label='Per Diem Total'
                width={"14ch"}
                value={formik.values.PerDiem ? serviceCalculations.TotalPerDiem?.toString() : "0"}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <HrCheckBox
                                name="PerDiem"
                                checked={formik.values.PerDiem}
                                onChange={handleCheckChange}
                                sx={{
                                    m: "0 -8px 0 0",
                                    "& .MuiCheckbox-root": {
                                        p: 0
                                    }
                                }}
                            />
                        </InputAdornment>
                    )
                }}
                disabled
            />
            <HrTextInput
                name='Mileage'
                label='Mileage'
                onChange={handleChange}
                value={formik.values.Mileage}
                width={width.small}
                errors={formik.errors.Mileage}
            //variant="standard"
            />
            <HrCheckBox
                name="LineBreakAfter"
                checked={formik.values.LineBreakAfter}
                onChange={handleCheckChange}
                label="Line Break After"
            />
            <HrCurrencyInput
                label='Total Price'
                width={width.medium}
                value={serviceCalculations.TotalPrice.toString()}
                disabled
            />
            <HrCurrencyInput
                label='Unit Price'
                width={width.medium}
                value={serviceCalculations.UnitPrice.toString()}
                disabled
            />
            <DeleteWarning
                onClick={handleDelete}
                heading={"Delete Service Item"}
                disabled={disabledForStatus}
            >
                <IconButton
                    color="error"
                >
                    <Delete />
                </IconButton>
            </DeleteWarning>
            {/* <IconButton
                color="error"
                onClick={handleDelete}
            >
                <Delete />
            </IconButton> */}
        </QuoteLineItemFormGrid>
    );
};