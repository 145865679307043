import { Box } from "@mui/material";
import React from "react";
import { TableauViz } from "./TableauViz";

interface DaysPastSubmissionProps {
}

export const DaysPastSubmission: React.FC<DaysPastSubmissionProps> = (props) => {
    return (
        <Box sx={{
            height:"100vh",
            width:"100%"
        }}>
            <TableauViz 
                src={"https://prod-useast-a.online.tableau.com/#/site/highridge/views/BIDANALYTICS/DaysPastSubmissionbyClientQuoteName?:iid=4"} 
                />
        </Box>
        
    )
};