import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, Dialog, DialogContent } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import { FormGrid } from "../../atoms/FormGrid";
import { HrDataGrid } from "../../molecules/HrDataGrid";
import { DefaultsForm } from "./DefaultsForm";

const GET_DEFAULT_VALUES = gql`
  query GetDefaultValues {
    BidQuoteDefaults {
      Id
      Key
      Value
    }
  }
`;

const UPDATE_DEFAULT_VALUES_MUTATION = gql`
  mutation UpdateDefaultValues($Id: uniqueidentifier = "", $_set: BidQuoteDefaults_set_input = {}) {
    update_BidQuoteDefaults_by_pk(pk_columns: {Id: $Id}, _set: $_set) {
      Id
      Value
      Key
    }
  }
`;

export const DefaultsDataGrid: React.FC<{}> = (props) => {
    const [selectedFactor, setSelectedFactor] = useState('');
    const { data, loading, error } = useQuery(GET_DEFAULT_VALUES);
    const [updateFactor] = useMutation(UPDATE_DEFAULT_VALUES_MUTATION);

    const handleSave = (object: any) => {

        updateFactor({
            variables: {
                Id: object.Id,
                _set: object
            },
            optimisticResponse: {
                update_FactorRates_by_pk: {
                    __typename: 'FactorRates',
                    ...object
                }
            }
        });
        setSelectedFactor('');
    }
    const rows = data?.BidQuoteDefaults ?? [];
    const selectedFactorObject = rows.find((i: any) => i.Id === selectedFactor);
    const columns: GridColDef[] = [
        {
            field: "Key",
            headerName: "Name",
            width: 250,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "Value",
            headerName: "Value",
            width: 150,
            editable: false,
            headerClassName: "billables-grid-header",
        }, 
    ] 

    if (error) {
        console.error(error);
    }
    return (
        <>
            <HrDataGrid
                loading={loading}
                rows={rows}
                columns={columns}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                disableRowSelectionOnClick
                onRowDoubleClick={(model, details) => {
                    setSelectedFactor(model.id as string);
                }}
                initialState={{
                    sorting: {
                      sortModel: [{ field: 'Key', sort: 'asc' }],
                    },
                }}
            />
                <Dialog
                    fullWidth
                    maxWidth="xl"
                    open={selectedFactor !== ''}
                    onClose={() => setSelectedFactor('')}
                >
                    <DialogContent>

                        <Button
                            onClick={() => {
                                setSelectedFactor('');
                            }}
                            variant="contained"
                            color="primary"
                            className="modal-exit"
                        >
                            x
                        </Button>
                        <FormGrid>
                            <DefaultsForm item={selectedFactorObject} onSave={handleSave} onCancel={() => setSelectedFactor('')} />
                        </FormGrid>
                    </DialogContent>
                </Dialog>
        </>
    );
}