import { makeAutoObservable, runInAction } from "mobx";
import Agent from "../api/agent";
import { Billable } from "../models/billable";

export default class BillablesStore {
    billables: Billable[] | [] = [];
    selectedBillable: Billable | undefined = undefined;
    editOrCreateMode: boolean = false;
    agent: Agent;
    billableType: string = "";
    constructor(myAgent: Agent) {
        makeAutoObservable(this);
        this.agent = myAgent;
    }

    setSelectedBillable = (id: string | undefined) => {
            runInAction(() => {
                this.selectedBillable = this.billables.find((x) => x.id === id);
            });
    };

    setBillableType = (type: string = "") => {
        runInAction(() => {
            this.billableType = type;
        });
    };

    setEditOrCreateMode = (state: boolean) => {
        runInAction(() => {
            this.editOrCreateMode = state;
        });
    };

    setBillables = (values: Billable[]) => {
        runInAction(() => {
            this.billables = values;
        });
    };
    getBillables = async () => {
        try {
            const billablesList: Billable[] = await this.agent.Billables.list();
            this.setBillables(billablesList);
        } catch (error) {
            console.log(error);
        }
    };

    createBillable = async (billable: Billable) => {
        try {
            await this.agent.Billables.create(billable);
            this.getBillables();
        } catch (error) {
            console.log(error);
        }
    };

    updateBillable = async (billable: Billable) => {
        try {
            await this.agent.Billables.update(billable);
            this.getBillables();
        } catch (error) {
            console.log(error);
        }
    };

    deleteBillable = async (id: string) => {
        try {
            await this.agent.Billables.delete(id);
            this.getBillables();
        } catch (error) {
            console.log(error);
        }
    };
}
