import { action, makeAutoObservable, runInAction } from "mobx";

export default class ModalStore {
    crewModalIsOpen: boolean = false;
    billablesModalIsOpen: boolean = false;
    clientsModalIsOpen: boolean = false;
    employeeModalIsOpen: boolean = false;
    jobsModalIsOpen: boolean = false;
    expenseModalIsOpen: boolean = false;
    receiptModalIsOpen: boolean = false;
    clientLogoModalIsOpen: boolean = false;
    billablesModalType: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    openEmployeeModal = () => {
        this.employeeModalIsOpen = true;
    };
    @action
    closeEmployeeModal = () => {
        this.employeeModalIsOpen = false;
    };

    @action
    openCrewModal = () => {
            this.crewModalIsOpen = true;
    }

    @action
    closeCrewModal = () => {
        this.crewModalIsOpen = false;
    };

    get crewModalState() {
        return this.crewModalIsOpen;
    }

    openBillablesModal = (type: string = "") => {
        runInAction(() => {
            this.billablesModalIsOpen = true;
            this.billablesModalType = type === "Equipment" ? 0 : 1;
        });
    };

    closeBillablesModal = () => {
        runInAction(() => {
            this.billablesModalIsOpen = false;
        });
    };

    get billablesModalState() {
        return this.billablesModalIsOpen;
    }

    openJobsModal = () => {
        runInAction(() => {
            this.jobsModalIsOpen = true;
        });
    };

    closeJobsModal = () => {
        runInAction(() => {
            this.jobsModalIsOpen = false;
        });
    };

    get jobsModalState() {
        return this.jobsModalIsOpen;
    }

    openClientsModal = () => {
        runInAction(() => {
            this.clientsModalIsOpen = true;
        });
    };

    closeClientsModal = () => {
        runInAction(() => {
            this.clientsModalIsOpen = false;
        });
    };

    @action
    openClientLogoModal = () => {
        this.clientLogoModalIsOpen = true;
    }

    @action
    closeClientLogoModal = () => {
        this.clientLogoModalIsOpen = false;
    }

    get ClientsModalState() {
        return this.clientsModalIsOpen;
    }
} 