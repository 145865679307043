import { gql, useApolloClient, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { Add } from "@mui/icons-material";
import { Button, Dialog, DialogContent } from "@mui/material";
import { GridColDef, GridToolbarContainer } from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import { addMessage, appUserVar } from "../../../../stores/ReactiveVariables";
import { FormGrid } from "../../../atoms/FormGrid";
import RemoveButton from "../../../atoms/RemoveButton";
import { HrDataGrid } from "../../../molecules/HrDataGrid";
import { UserRolesForm } from "./UserRolesForm";
import CloseIcon from '@mui/icons-material/Close';
import { v4 } from 'uuid';

const GET_USER_ROLES_QUERY = gql`
query GetUserRoles {
    AppUserRole(where: {UserHighridgeRole: {AppSection: {_eq: 7}}}) {
      RolesId
      UsersId
      User {
        Id
        FirstName
        LastName
      }
      UserHighridgeRole {
        Id
        Name
      }
    }
  }
  
`;

const GET_USERS_QUERY = gql`
query GetBidbookUsers {
    AspNetUsers {
      FirstName
      Id
      LastName
      UserRole(where: {UserHighridgeRole: {AppSection: {_eq: 7}}}) {
        RolesId
        UsersId
        UserHighridgeRole {
          Id
          Name
        }
      }
    }
  } 
`

const DELETE_USER_ROLE_MUTATION = gql`
mutation DeleteUserRole($RolesId: uniqueidentifier = "", $UsersId: String = "") {
    delete_AppUserRole(where: {RolesId: {_eq: $RolesId}, UsersId: {_eq: $UsersId}}) {
        affected_rows
    }
}  
`;

const CREATE_USER_ROLE_MUTATION = gql`
mutation CreateUserRole($RolesId: uniqueidentifier = "", $UsersId: String = "") {
    insert_AppUserRole(objects: {RolesId: $RolesId, UsersId: $UsersId}) {
      returning {
        RolesId
        UsersId
      }
    }
  }  
`;

export const UserRolesDataGrid: React.FC<{}> = (props) => {
    const user = useReactiveVar(appUserVar);
    const [selectedUserRole, setSelectedUserRole] = useState(false);
    const { data, loading, error } = useQuery(GET_USER_ROLES_QUERY);
    const [createUserRole] = useMutation(CREATE_USER_ROLE_MUTATION);
    const [deleteUserRole] = useMutation(DELETE_USER_ROLE_MUTATION);

    const deleteUserRoleHandler = (userId: string, rolesId: string) => {
        deleteUserRole({
            variables: {
                UsersId: userId,
                RolesId: rolesId
            },
            refetchQueries: [GET_USER_ROLES_QUERY, GET_USERS_QUERY]
        });
    }

    const handleSave = (object: any) => {
        createUserRole({
            variables: object,
            refetchQueries: [GET_USER_ROLES_QUERY, GET_USERS_QUERY]
        }).then(()=>{
            addMessage("User Role Added", { severity: 'success' });
            setSelectedUserRole(false);
        })
        .catch((error)=>{
            addMessage("Error Adding Role to User", { severity: 'error' });
        })
    }
    const rows = data?.AppUserRole ?? [];
    const columns: GridColDef[] = [
        {
            field: "removeUserRole",
            headerName: " ",
            width: 50,
            renderCell: (rows) => {
                const userId = rows.row?.UsersId;
                const rolesId = rows.row?.RolesId;
                return (
                    <RemoveButton
                        title="UserRole"
                        onClick={() => deleteUserRoleHandler(userId, rolesId)}
                    />
                );
            },
            headerClassName: "billables-grid-header",
        },
        {
            field: "User",
            headerName: "User",
            width: 250,
            editable: false,
            headerClassName: "billables-grid-header",
            valueGetter: (params) => {
                return `${params.row?.User?.FirstName} ${params.row?.User?.LastName}`
            }
        },
        {
            field: "Role",
            headerName: "Role",
            width: 800,
            editable: false,
            headerClassName: "billables-grid-header",
            valueGetter: (params) => {
                return `${params.row?.UserHighridgeRole?.Name}`
            }
        }
    ]

    if (error) {
        console.error(error);
    }
    return (
        <>
            <HrDataGrid
                loading={loading}
                rows={rows}
                columns={columns}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                disableRowSelectionOnClick
                getRowId={(row) => v4()}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'User', sort: 'asc' }],
                    },
                    columns: {
                        columnVisibilityModel: {
                            removeDefaultTerm: user?.roles?.some(e => e.name === "BidBook Admin") ?? false
                        }
                    }
                }}
                components={{
                    Toolbar: () => <GridToolbarContainer>
                        <Button color="primary" startIcon={<Add />} onClick={() => setSelectedUserRole(true)}>
                            Add User Role
                        </Button>
                    </GridToolbarContainer>
                }}


            />
            <Dialog
                open={selectedUserRole}
                onClose={() => setSelectedUserRole(true)}
                maxWidth="sm"
                fullWidth
            >
                <DialogContent>
                    <Button
                        onClick={() => {
                            setSelectedUserRole(false);
                        }}
                        variant="contained"
                        color="primary"
                        className="modal-exit"
                    >
                        <CloseIcon />
                    </Button>
                    <FormGrid>
                        <UserRolesForm onSave={handleSave} onCancel={() => setSelectedUserRole(false)} />
                    </FormGrid>
                </DialogContent>
            </Dialog>
        </>
    );
}