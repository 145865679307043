import { Box, Button, Grid } from "@mui/material";
import { useFormik } from "formik";
import React from 'react';
import { v4 } from 'uuid';
import * as yup from 'yup';
import { HighRidgeColorButton } from "../../atoms/HighRidgeColorButton";
import HrTextArea from '../../atoms/HrTextArea';
import HrTextInput from "../../atoms/HrTextInput";

interface DefaultsItem {
    Id: string;
    Key: string;
    Value: string;
}
interface DefaultsFormProps {
    item?: DefaultsItem;
    onSave: (item: DefaultsItem) => void;
    onCancel?: () => void;
}


const DefaultsItemSchema = yup.object().shape({
    Key: yup.string().required(),
    Value: yup.string().required()
});


export const DefaultsForm: React.FC<DefaultsFormProps> = ({ item, onSave, onCancel }) => {
    const Id = item?.Id ?? v4();
    const formik = useFormik({
        validationSchema: DefaultsItemSchema,
        initialValues: {
            Key: item?.Key ?? '',
            Value: item?.Value ?? '',
        },
        onSubmit: (values) => {
            onSave({ Id, ...values});
            formik.setSubmitting(false);
        }
    });
    return (<React.Fragment>
        <Box>
            <HrTextInput
                name='Key'
                label='Key'
                onChange={formik.handleChange}
                value={formik.values.Key}
                errors={formik.errors.Key}
                disabled
                fullWidth
            />
        </Box>
        <Box>
            <HrTextArea
                name='Value'
                label='Value'
                onChange={formik.handleChange}
                value={formik.values.Value}
                errors={formik.errors.Value}
                fullWidth
            />
        </Box>
        <Grid container justifyContent="space-between">
            <HighRidgeColorButton
                type="button"
                onClick={formik.submitForm}
            >Save Item</HighRidgeColorButton>
            {
                onCancel &&
                <Button
                    color="secondary"
                    type="button"
                    onClick={onCancel}
                >
                    Cancel
                </Button>

            }
        </Grid>
    </React.Fragment>);
}