import { Grid, Divider } from "@mui/material"
import HrTextInput from "../../../../atoms/HrTextInput";
import HrTextArea from "../../../../atoms/HrTextArea";
import { HrRadioGroup } from "../../../../atoms/HrRadioGroup";
import { HrRadioButton } from "../../../../atoms/HrRadioButton";
import { useFormik } from "formik";
import { gql, useMutation } from "@apollo/client";
import * as yup from "yup";
import { useCallback } from "react";
import debounce from "../../../../../utils/debounce";

interface CurrentSourcesFormProps {
    currentSources: CurrentSourcesSection;
}

interface CurrentSourcesSection {
    Id: string;
    CustomerRectifiers: boolean;
    CustomerBonds: boolean;
    ForeignRectifiers: boolean;
    ForeignContactList: boolean;
    InterruptionCycle: string;
    CurrentSourcesNotes: string;
}

const UPDATE_KICKOFF_JOB_PLAN_MUTATION = gql`
  mutation UpdateKickoffJobPlan($Id: uniqueidentifier = "", $_set: KickoffPacket_set_input = {}) {
    update_KickoffPacket_by_pk(pk_columns: {Id: $Id}, _set: $_set) {
      Id
      CustomerRectifiers
      CustomerBonds
      ForeignRectifiers
      ForeignContactList
      InterruptionCycle
      CurrentSourcesNotes
      __typename
    }
  }
`;

const kickoffJobPlanSchema = yup.object({
    CustomerRectifiers: yup.boolean().nullable(),
    CustomerBonds: yup.boolean().nullable(),
    ForeignRectifiers: yup.boolean().nullable(),
    ForeignContactList: yup.boolean().nullable(),
    InterruptionCycle: yup.string().nullable(),
    CurrentSourcesNotes: yup.string().nullable()
});

export const CurrentSourcesForm: React.FC<CurrentSourcesFormProps> = ({ currentSources }: CurrentSourcesFormProps) => {

    const [updateKickoffJobPlan] = useMutation(UPDATE_KICKOFF_JOB_PLAN_MUTATION);
    const formik = useFormik({
        initialValues: {
            CustomerRectifiers: currentSources.CustomerRectifiers,
            CustomerBonds: currentSources.CustomerBonds,
            ForeignRectifiers: currentSources.ForeignRectifiers,
            ForeignContactList: currentSources.ForeignContactList,
            InterruptionCycle: currentSources.InterruptionCycle,
            CurrentSourcesNotes: currentSources.CurrentSourcesNotes
        },
        validationSchema: kickoffJobPlanSchema,
        onSubmit: values => {

        }
    });

    const { CustomerRectifiers, CustomerBonds, ForeignRectifiers, ForeignContactList, InterruptionCycle, CurrentSourcesNotes } = formik.values;
    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const set = { ...formik.values, [event.target.name]: event.target.value };
        debouncer(set);
        formik.handleChange(event);
    };

    const updateData = (set: any) => {
        const castSet = kickoffJobPlanSchema.cast(set);
        updateKickoffJobPlan({ variables: { Id: currentSources.Id, _set: castSet } });
    };  

    const debouncer = useCallback(debounce((values: any) => updateData(values), 1000), []);
    return (
        <Grid item xs={12} container alignItems="center">
            <HrRadioGroup name="CustomerRectifiers" label="Customer Rectifiers" value={CustomerRectifiers} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Divider sx={{ width: "100%" }} />

            <HrRadioGroup name="CustomerBonds" label="Customer Bonds" value={CustomerBonds} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Divider sx={{ width: "100%" }} />

            <HrRadioGroup name="ForeignRectifiers" label="Foreign Rectifiers" value={ForeignRectifiers} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Divider sx={{ width: "100%" }} />

            <HrRadioGroup name="ForeignContactList" label="Foreign Contact List" value={ForeignContactList} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Grid item xs={12} sx={{ mt: .5 }}>
                <HrTextInput name="InterruptionCycle" label="Interruption Cycle" value={InterruptionCycle} onChange={handleChange} fullWidth />
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
                <HrTextArea
                    label="Notes"
                    value={CurrentSourcesNotes}
                    name="CurrentSourcesNotes"
                    onChange={handleChange}
                    fullWidth
                    multiline
                    minRows={3}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'rgba(0, 0, 0, 0.12)'
                            }
                        }
                    }}
                />
            </Grid>
        </Grid>
    )
}