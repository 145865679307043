import { Grid } from "@mui/material";
import { Colors } from "../../../../constants/colors";
import { StyledHeader } from "../../../atoms/StyledHeader";

interface KickoffPacketSectionProps {
    children: React.ReactNode;
    heading: string;
}

export const KickoffPacketSection: React.FC<KickoffPacketSectionProps> = ({ children, heading }: KickoffPacketSectionProps) => {
    return (
        <Grid
            item
            xs={12}
            sx={{
                //borderBottom: `1px solid ${Colors.unselectedGray}`,
            }}
            pb={1.5}
        >
            <StyledHeader sx={{
                borderBottom: `2px solid ${Colors.gold}`,
                //borderTop:`2px solid ${Colors.gold}`,
                padding: "6px 0 6px",
                margin: "0 0 9px",
                fontSize:"20px",
                fontWeight:"500"
            }}>
                {heading}
            </StyledHeader>
            {children}
        </Grid>
    )
};
