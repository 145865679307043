import { styled, Tab, Tabs } from "@mui/material"
import { Colors } from "../../constants/colors"

export const HrSubTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
      backgroundColor: Colors.goldLighter,
    },  
})

export const HrSubTab = styled(Tab)({
    fontFamily: "montserrat",
    "&.Mui-selected" : {
        color: Colors.deepSeaGreen
    }
})