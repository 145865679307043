import { gql, useMutation, useQuery } from '@apollo/client';
import { Box, IconButton, Grid, Chip, Stack, Typography } from '@mui/material';
import { v4 } from "uuid";
import { useFormik } from 'formik';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HrButton from '../../../atoms/HrButton';
import HrTextArea from '../../../atoms/HrTextArea';
import HrTextInput from '../../../atoms/HrTextInput';
import { addMessage } from "../../../../stores/ReactiveVariables";
import * as yup from "yup";
import debounce from '../../../../utils/debounce';

interface ProjectDescriptionFormProps {
    id: string;
    data: any;
    onSaveDescription: any;
    getCoverLetter: () => void;
}

const validationSchema = yup.object().shape({
    CoverLetterProjectDescription: yup.string()
});

const GET_COVER_LETTER_WORK_ITEMS = gql`
    query GetCoverLetterWorkItems($Id: uniqueidentifier = "") {
        CoverLetterWorkItems(where: {BidQuoteId: {_eq: $Id}}, order_by: {Ordinal: asc}) {
            Description
            Id
            Ordinal
        }
    }
`

const INSERT_COVER_LETTER_WORK_ITEM = gql`
    mutation InsertCoverLetterWorkItems($object: CoverLetterWorkItems_insert_input = {}) {
        insert_CoverLetterWorkItems_one(object: $object) {
            BidQuoteId
            Description
            Id
            Ordinal
        }
    }
`

const DELETE_COVER_LETTER_WORK_ITEM = gql`
    mutation DeleteCoverLetterWorkItem($Id: uniqueidentifier = "", $BidQuoteId: uniqueidentifier = "", $Ordinal: Int = 0) {
        delete_CoverLetterWorkItems_by_pk(Id: $Id) {
            Id
            __typename
        }
        update_CoverLetterWorkItems(where: {BidQuoteId: {_eq: $BidQuoteId}, Ordinal: {_gt: $Ordinal}}, _inc: {Ordinal: -1}) {
            returning {
                Id
                Ordinal
            }
        }
    } 
`

export const ProjectDescriptionForm: React.FC<ProjectDescriptionFormProps> = (props: ProjectDescriptionFormProps) => {
    const { id, onSaveDescription, data, getCoverLetter } = props;

    const { data: workItemData, loading, error } = useQuery(GET_COVER_LETTER_WORK_ITEMS, { variables: { Id: id } });

    const [scopeOfWorkItem, setScopeOfWorkItem] = useState("");

    const quoteData = data?.BidQuotes_by_pk ?? [];
    
    const workItems = workItemData?.CoverLetterWorkItems ?? [];

    const [insertCoverLetterWorkItem] = useMutation(INSERT_COVER_LETTER_WORK_ITEM); 
    const [deleteCoverLetterWorkItem] = useMutation(DELETE_COVER_LETTER_WORK_ITEM);

    const onDelete = (item: any) => {
        deleteCoverLetterWorkItem({
            variables: { Id: item.Id.toLowerCase(), BidQuoteId: id, Ordinal: item.Ordinal },
            refetchQueries: [GET_COVER_LETTER_WORK_ITEMS],
            onCompleted: () => {
                addMessage('Work Item Deleted', { severity: 'success' });
                getCoverLetter();
            },
            onError: () => {
                addMessage('Error Deleting Work Item', { severity: 'error' });
            }
        });
    }

    const onSaveItem = (value: any) => {
        insertCoverLetterWorkItem({
            variables: { 
                object: { 
                    Id: v4(), 
                    BidQuoteId: id, 
                    Description: value,
                    Ordinal: workItems.length
                } 
            },
            refetchQueries: [GET_COVER_LETTER_WORK_ITEMS],
            onCompleted: () => {
                setScopeOfWorkItem("");
                addMessage('Work Item Added', { severity: 'success' });
                getCoverLetter();
            },
            onError: () => {
                addMessage('Error Adding Work Item', { severity: 'error' });
            }
        });
    }

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            CoverLetterProjectDescription: quoteData?.CoverLetterProjectDescription ?? ""
        },
        onSubmit: (values: any) => {
            const castValues = validationSchema.cast(values);
            onSaveDescription(castValues);
        }
    })

    const saveNewScopeOfWorkItem = () => {
        formik.setSubmitting(false);
        onSaveItem(scopeOfWorkItem);
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        try {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value};
            const values = validationSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.handleChange(e);
    };

    const debouncer = useCallback(debounce((values: any) => onSaveDescription(values), 2000), []);

    return (
        <Box sx={{ width: '100%' }}>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={1}>
                    <Grid item xs={12} container spacing={1}>
                        <Grid item xs={6} lg={12} xl={6}>
                            <HrTextArea
                                name="CoverLetterProjectDescription"
                                label="Project Description Location"
                                value={formik.values.CoverLetterProjectDescription}
                                onChange={handleChange}
                                fullWidth
                                multiline
                                minRows={3}
                            />
                        </Grid>
                        <Grid item xs={6} lg={12} xl={6} container spacing={1}>
                            <Grid item xs={11}>
                                <HrTextInput
                                    label="Work Item"
                                    name="scopeOfWork"
                                    value={scopeOfWorkItem}
                                    onChange={(e) => setScopeOfWorkItem(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton 
                                    onClick={saveNewScopeOfWorkItem}
                                    disabled={scopeOfWorkItem === ""}>
                                    <AddCircleIcon color="primary" />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{
                                    pl:2,
                                    mb: 1
                                }}>
                                    <Typography variant="body2" color="text.secondary">
                                        Scope of Work Items
                                    </Typography>
                                </Box>          
                                <Box>
                                    {
                                        workItems.map((workItem: any) => {
                                            return (
                                                <Chip label={workItem.Description} onDelete={() => onDelete(workItem)} sx={{
                                                    mb: .5,
                                                    mr: .5
                                                }}/>
                                            )
                                        })
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};
