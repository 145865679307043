import { gql, useApolloClient, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { Add } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, Grid } from "@mui/material";
import { GridColDef, GridToolbarContainer } from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { appUserVar } from "../../../../stores/ReactiveVariables";
import { FormGrid } from "../../../atoms/FormGrid";
import RemoveButton from "../../../atoms/RemoveButton";
import { HrDataGrid } from "../../../molecules/HrDataGrid";
import { ServiceCategoryForm } from "./BidbookServiceCategoryForm";
import { addMessage } from "../../../../stores/ReactiveVariables";

const GET_BIDQUOTE_SERVICE_CATEGORIES_QUERY = gql`
    query GetBidQuoteServiceCategories($BidQuoteId: uniqueidentifier = "") {
        BidQuoteServiceCategories(where: {BidQuoteId: {_eq: $BidQuoteId}}) {
            Id
            Name
        }
    }
`;

const DELETE_BIDQUOTE_SERVICE_CATEGORY_MUTATION = gql`
    mutation DeleteBidQuoteServiceCategory($Id: uniqueidentifier = "") {
        delete_BidQuoteServiceCategories_by_pk(Id: $Id) {
            Id
        }
    }
`;

const CREATE_BIDQUOTE_SERVICE_CATEGORY_MUTATION = gql`
    mutation InsertBidQuoteServiceCategory($object: BidQuoteServiceCategories_insert_input = {}) {
        insert_BidQuoteServiceCategories_one(object: $object) {
            Id
            BidQuoteId
            Name
            LastUpdated
        }
    }
`;

const UPDATE_BIDQUOTE_SERVICE_CATEGORY_MUTATION = gql`
    mutation UpdateBidQuoteServiceCategory($Id: uniqueidentifier = "", $object: BidQuoteServiceCategories_set_input = {}) {
        update_BidQuoteServiceCategories_by_pk(pk_columns: { Id: $Id }, _set: $object) {
            Id
            BidQuoteId
            Name
            LastUpdated
        }
    } 
`;

export const BidbookServiceCategories: React.FC<{ id: string }> = ({ id }) => {
    const user = useReactiveVar(appUserVar);
    const [selectedServiceCategory, setSelectedServiceCategory] = useState('');
    const { data, loading, error } = useQuery(GET_BIDQUOTE_SERVICE_CATEGORIES_QUERY, { variables: { BidQuoteId: id }});
    const [createServiceCategory] = useMutation(CREATE_BIDQUOTE_SERVICE_CATEGORY_MUTATION);
    const [updateServiceCategory] = useMutation(UPDATE_BIDQUOTE_SERVICE_CATEGORY_MUTATION);
    const [deleteServiceCategory] = useMutation(DELETE_BIDQUOTE_SERVICE_CATEGORY_MUTATION);
    const client = useApolloClient();
    const deleteServiceCategoryHandler = (Id: string) => {
        deleteServiceCategory({
            variables: {
                Id
            },
            optimisticResponse: {
                delete_BidQuoteServiceCategories_by_pk: {
                    Id,
                    __typename: "BidQuoteServiceCategories"
                }
            },
            update(cache, { data }) {
                cache.evict({
                    id: `BidQuoteServiceCategories:${Id}`
                });
                cache.gc();
            },
            onError(error) {
                console.error(error);
                client.refetchQueries({
                    include: "active"
                });
                addMessage(`Error Deleting Service Category`, { severity: 'error' });
            },
            onCompleted: (data) => {
                if (data)
                    addMessage(`Service Category Deleted`, { severity: 'success' });
            }
        });
    }

    const handleSave = (object: any) => {
        object.BidQuoteId = id;
        object.LastUpdated = new Date();
        if (selectedServiceCategory === 'new') {
            createServiceCategory({
                variables: {
                    object
                },
                optimisticResponse: {
                    insert_BidQuoteServiceCategories_one: {
                        __typename: 'BidQuoteServiceCategories',
                        ...object
                    }
                },
                update: (cache, { data }) => {
                    cache.updateQuery({
                        query: GET_BIDQUOTE_SERVICE_CATEGORIES_QUERY,
                        variables: { BidQuoteId: id }
                    },
                    (cacheData: any) => ({
                        BidQuoteServiceCategories: [...cacheData.BidQuoteServiceCategories, data.insert_BidQuoteServiceCategories_one]
                    }))
                },
                onCompleted: (data) => {
                    if (data)
                        addMessage(`Service Category Created`, { severity: 'success' });
                }
            })
        } else {
            updateServiceCategory({
                variables: {
                    Id: object.Id,
                    object
                },
                optimisticResponse: {
                    update_BidQuoteServiceCategories_by_pk: {
                        __typename: 'BidQuoteServiceCategories',
                        ...object
                    }
                },
                onCompleted: (data) => {
                    if (data)
                        addMessage(`Service Category Updated`, { severity: 'success' });
                }
            });
        }
        setSelectedServiceCategory('');
    }

    const rows = data?.BidQuoteServiceCategories ?? [];
    const selectedServiceCategoryObject = rows.find((i: any) => i.Id === selectedServiceCategory);
    const columns: GridColDef[] = [
        {
            field: "removeServiceCategory",
            headerName: " ",
            width: 50,
            renderCell: (rows) => {
                const serviceCategoryId = rows.id;
                return (
                    <RemoveButton
                        title="Service Category"
                        onClick={() => deleteServiceCategoryHandler(serviceCategoryId as string)}
                    />
                );
            },
            headerClassName: "billables-grid-header",
        },
        // {
        //     field: "id",
        //     headerName: "ID",
        //     width: 100,
        //     headerClassName: "billables-grid-header",
        // },
        {
            field: "Name",
            headerName: "Item",
            width: 250,
            editable: false,
            headerClassName: "billables-grid-header",
        }
    ] 

    if (error) {
        console.error(error);
    }
    return (
        <Box sx={{ height: '70vh' }}>
            <HrDataGrid
                loading={loading}
                rows={rows}
                columns={columns}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                disableRowSelectionOnClick
                onRowDoubleClick={(model, details) => {
                    setSelectedServiceCategory(model.id as string);
                }}
                components={{
                    Toolbar: () => <GridToolbarContainer>
                    <Button color="primary" startIcon={<Add />} onClick={() => setSelectedServiceCategory('new')}>
                      Add Service Category
                    </Button>
                  </GridToolbarContainer>
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            removeServiceCategory: user?.roles?.some(e => e.name === "BidBook Admin") ?? false
                        }
                    }
                }}
            />
                <Dialog
                    open={selectedServiceCategory !== ''}
                    onClose={() => setSelectedServiceCategory('')}
                >
                    <DialogContent>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Button
                                    onClick={() => {
                                        setSelectedServiceCategory('');
                                    }}
                                    variant="contained"
                                    color="primary"
                                    className="modal-exit"
                                >
                                    <CloseIcon />
                                </Button>
                            </Grid>
                        </Grid>
                        <FormGrid>
                            <ServiceCategoryForm item={selectedServiceCategoryObject} onSave={handleSave} />
                        </FormGrid>
                    </DialogContent>
                </Dialog>
        </Box>
    );
}