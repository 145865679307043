import { Grid, Divider } from "@mui/material"
import HrTextArea from "../../../../atoms/HrTextArea";
import { HrRadioGroup } from "../../../../atoms/HrRadioGroup";
import { HrRadioButton } from "../../../../atoms/HrRadioButton";
import { useFormik } from "formik";
import { gql, useMutation } from "@apollo/client";
import * as yup from "yup";
import { useCallback } from "react";
import debounce from "../../../../../utils/debounce";

interface AdditionalDocumentsFormProps {
    additionalDocuments: AdditionalDocumentsSection;
}

interface AdditionalDocumentsSection {
    Id: string;
    SSDLocations: boolean;
    KMZProvided: boolean;
    AdditionalDocumentDATFiles: boolean;
    AGOLWebMaps: boolean;
    ExposuresIncluded: boolean;
    AdditionalDocumentsNotes: string;
}

const UPDATE_KICKOFF_ADDITIONAL_DOCUMENTS_MUTATION = gql`
  mutation UpdateKickoffAdditionalDocuments($Id: uniqueidentifier = "", $_set: KickoffPacket_set_input = {}) {
    update_KickoffPacket_by_pk(pk_columns: {Id: $Id}, _set: $_set) {
      Id
      SSDLocations
      KMZProvided
      AdditionalDocumentDATFiles
      AGOLWebMaps
      ExposuresIncluded
      AdditionalDocumentsNotes
      __typename
    }
  }
`;

const kickoffAdditionalDocumentsSchema = yup.object({
    SSDLocations: yup.boolean().nullable(),
    KMZProvided: yup.boolean().nullable(),
    AdditionalDocumentDATFiles: yup.boolean().nullable(),
    AGOLWebMaps: yup.boolean().nullable(),
    ExposuresIncluded: yup.boolean().nullable(),
    AdditionalDocumentsNotes: yup.string().nullable()
});

export const AdditionalDocumentsForm: React.FC<AdditionalDocumentsFormProps> = ({ additionalDocuments }: AdditionalDocumentsFormProps) => {

    const [updateKickoffAdditionalDocuments] = useMutation(UPDATE_KICKOFF_ADDITIONAL_DOCUMENTS_MUTATION);
    const formik = useFormik({
        initialValues: {
            SSDLocations: additionalDocuments.SSDLocations,
            KMZProvided: additionalDocuments.KMZProvided,
            AdditionalDocumentDATFiles: additionalDocuments.AdditionalDocumentDATFiles,
            AGOLWebMaps: additionalDocuments.AGOLWebMaps,
            ExposuresIncluded: additionalDocuments.ExposuresIncluded,
            AdditionalDocumentsNotes: additionalDocuments.AdditionalDocumentsNotes
        },
        onSubmit: values => {

        }
    });

    const updateData = (set: any) => {
        const castSet = kickoffAdditionalDocumentsSchema.cast(set);
        updateKickoffAdditionalDocuments({
            variables: {
                Id: additionalDocuments.Id,
                _set: castSet
            }
        });
    };

    const debouncer = useCallback(debounce((values: any) => updateData(values), 1000), []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const set = {
            ...formik.values,
            [event.target.name]: event.target.value
        };
        debouncer(set);
        formik.handleChange(event);
    };

    const { SSDLocations, KMZProvided, AdditionalDocumentDATFiles, AGOLWebMaps, ExposuresIncluded, AdditionalDocumentsNotes } = formik.values;
    return (
        <Grid item xs={12} container alignItems="center">


            <HrRadioGroup name="SSDLocations" label="SSD Locations" value={SSDLocations} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Divider sx={{ width: "100%" }} />

            <HrRadioGroup name="KMZProvided" label="KMZ Provided" value={KMZProvided} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Divider sx={{ width: "100%" }} />

            <HrRadioGroup name="AdditionalDocumentDATFiles" label="DAT Files" value={AdditionalDocumentDATFiles} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Divider sx={{ width: "100%" }} />

            <HrRadioGroup name="ExposuresIncluded" label="Exposures Included" value={ExposuresIncluded} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Divider sx={{ width: "100%" }} />

            <HrRadioGroup name="AGOLWebMaps" label="AGOL Web Maps" value={AGOLWebMaps} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Grid item xs={12} sx={{ mt: 1 }}>
                <HrTextArea
                    label="Notes"
                    name="AdditionalDocumentsNotes"
                    value={AdditionalDocumentsNotes}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    minRows={3}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'rgba(0, 0, 0, 0.12)'
                            }
                        }
                    }}
                />
            </Grid>
        </Grid>
    )
}