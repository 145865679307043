import { gql, useMutation, useQuery } from "@apollo/client";
import { DataGridPro, GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid-pro";
import { useCallback, useEffect, useState } from "react";
import HrButton from "../../../atoms/HrButton";
import HrTextInput from "../../../atoms/HrTextInput";


interface ProjectBreakdownProps {
    id: string;
    getCoverLetter: () => void;
}

interface ProjectBreakdownQuery {
    BidQuotes_by_pk: ProjectBreakdownBidQuote;
};

interface ProjectBreakdownBidQuote
{
    CoverLetterBreakdownHeaderOne: string;
    CoverLetterBreakdownHeaderTwo: string;
    Id: string;
    BidQuoteItems: ProjectBreakdownBidQuoteItems[];
};

interface ProjectBreakdownBidQuoteItems
{
    Id: string;
    ProjectBreakdownItemOne: string;
    ProjectBreakdownItemTwo: string;
    Segment: string;
    Miles: number;
};

const PROJECT_BREAKDOWN_QUERY_QUERY = gql`
  query ProjectBreakdownQuery($Id: uniqueidentifier = "") {
    BidQuotes_by_pk(Id: $Id) {
      CoverLetterBreakdownHeaderOne
      CoverLetterBreakdownHeaderTwo
      Id
      BidQuoteItems {
        Id
        ProjectBreakdownItemOne
        ProjectBreakdownItemTwo
        Segment
        Miles
      }
    }
  }
`;

const UPDATE_PROJECT_BREAKDOWN_MUTATION = gql`
  mutation UpdateProjectBreakdown($Id: uniqueidentifier = "", $_set: BidQuotes_set_input = {}, $objects: [BidQuoteItems_insert_input!] = {}) {
    update_BidQuotes_by_pk(pk_columns: {Id: $Id}, _set: $_set) {
      CoverLetterBreakdownHeaderOne
      CoverLetterBreakdownHeaderTwo
      Id
    }
    insert_BidQuoteItems(objects: $objects, if_matched: {match_columns: Id, update_columns: [ProjectBreakdownItemOne, ProjectBreakdownItemTwo]} ) {
      returning {
        Id
        ProjectBreakdownItemOne
        ProjectBreakdownItemTwo
        Segment
        Miles
      }
    }
  }
`;

export const ProjectBreakdown = ({id, getCoverLetter}: ProjectBreakdownProps) => {

    const { data, loading, error } = useQuery<ProjectBreakdownQuery>(PROJECT_BREAKDOWN_QUERY_QUERY, { variables: { Id: id } });
    const [saveProjectBreakdown, {loading: saveLoading, error: saveError }] = useMutation(UPDATE_PROJECT_BREAKDOWN_MUTATION);
    const [header, setHeader] = useState({ CoverLetterBreakdownHeaderOne: "", CoverLetterBreakdownHeaderTwo: "" });
    const [rows, setRows] = useState<ProjectBreakdownBidQuoteItems[]>([]);
    
    useEffect(() => {
        if (data && data.BidQuotes_by_pk) {
            setHeader({
                CoverLetterBreakdownHeaderOne: data.BidQuotes_by_pk.CoverLetterBreakdownHeaderOne,
                CoverLetterBreakdownHeaderTwo: data.BidQuotes_by_pk.CoverLetterBreakdownHeaderTwo,
            });
            setRows(data.BidQuotes_by_pk.BidQuoteItems.map(item => ({
                Id: item.Id,
                ProjectBreakdownItemOne: item.ProjectBreakdownItemOne,
                ProjectBreakdownItemTwo: item.ProjectBreakdownItemTwo,
                Segment: item.Segment,
                Miles: item.Miles,
            })));
        }
    }, [data]);


    const saveBreakdownData = () => {
        const variables = {
            Id: id,
            _set: {
                CoverLetterBreakdownHeaderOne: header.CoverLetterBreakdownHeaderOne,
                CoverLetterBreakdownHeaderTwo: header.CoverLetterBreakdownHeaderTwo,
            },
            objects: rows,
        }
        saveProjectBreakdown({ variables }).then(()=>{
            getCoverLetter();
        })
    }
    const processRowUpdate = useCallback(
        (newRow: any) => {
            setRows(rows => {
                return rows.map(row => {
                    if (row.Id === newRow.Id) {
                        return { 
                            Id: newRow.Id,
                            ProjectBreakdownItemOne: newRow.ProjectBreakdownItemOne,
                            ProjectBreakdownItemTwo: newRow.ProjectBreakdownItemTwo,
                            Segment: newRow.Segment,
                            Miles: newRow.Miles,
                        };
                    }
                    return row;
                });
            });
            return newRow;
        },
        [setRows]
    );
    
    const handleHeaderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHeader({
            ...header,
            [event.target.name]: event.target.value,
        });
    };

    const columns: GridColDef[] = [
        {
            field: 'ProjectBreakdownItemOne',
            width: 150,
            type: 'text',
            renderHeader: (params: GridColumnHeaderParams) => (
                <HrTextInput
                    id="CoverLetterBreakdownHeaderOne"
                    name="CoverLetterBreakdownHeaderOne"
                    label={undefined}
                    value={header.CoverLetterBreakdownHeaderOne}
                    onChange={handleHeaderChange}
                />
            ),
            editable: true,
        },
        {
            field: 'Segment',
            width: 300,
            type: 'text',
        },
        {
            field: 'ProjectBreakdownItemTwo',
            width: 150,
            type: 'text',
            renderHeader: (params: GridColumnHeaderParams) => (
                <HrTextInput
                    id="CoverLetterBreakdownHeaderTwo"
                    name="CoverLetterBreakdownHeaderTwo"
                    label={undefined}
                    value={header.CoverLetterBreakdownHeaderTwo}
                    onChange={handleHeaderChange}
                />
            ),
            editable: true,
        },
        {
            field: 'Miles',
            width: 100,
            type: 'number',
        },
    ];

    if (loading) return <div>Loading...</div>;
    if (error) {
        console.error(error);
    }
    if (saveError) {
        console.error(saveError);
    }
  
    return (
        <div>
            <HrButton
                onClick={saveBreakdownData}
                disabled={saveLoading}
                sx={{m:"10px"}}
            >Save Breakdown</HrButton>
            <DataGridPro
                rows={rows}
                columns={columns}
                getRowId={(row) => row.Id}
                processRowUpdate={processRowUpdate}
                initialState={{
                    pagination: { paginationModel: { pageSize: 10 } }
                }}
                pageSizeOptions={[5, 10, 20, 50]}
                autoHeight={true}
            />
        </div>
    )
}
