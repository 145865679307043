import { gql, useMutation, useQuery } from "@apollo/client";
import { Box } from "@mui/material"
import { ProjectDescriptionForm } from "./ProjectDescriptionForm";
import { addMessage } from "../../../../stores/ReactiveVariables";

interface ProjectDescriptionProps {
    id: string;
    getCoverLetter: () => void;
}

const GET_COVER_LETTER_SCOPE_OF_WORK = gql`
query GetCoverLetterProjectDescription($Id: uniqueidentifier = "") {
    BidQuotes_by_pk(Id: $Id) {
      CoverLetterProjectDescription
    }
  }
`;

const UPDATE_COVER_LETTER_PROJECT_DESCRIPTION = gql`
    mutation MyMutation($Id: uniqueidentifier = "", $object: BidQuotes_set_input = {}) {
        update_BidQuotes_by_pk(pk_columns: {Id: $Id}, _set: $object) {
            CoverLetterProjectDescription
        }
    }
`

export const ProjectDescription: React.FC<ProjectDescriptionProps> = (props: ProjectDescriptionProps) => {
    const { id, getCoverLetter } = props;

    const { data, loading, error } = useQuery(GET_COVER_LETTER_SCOPE_OF_WORK, { variables: { Id: id, BidQuoteId: { BidQuoteId: id } } });

    const [updateCoverLetterDescription] = useMutation(UPDATE_COVER_LETTER_PROJECT_DESCRIPTION);

    const onSaveDescription = (values: any) => {
        updateCoverLetterDescription({
            variables: { Id: id, object: values },
            onCompleted: () => {
                addMessage('Project Description Saved', { severity: 'success' });
                getCoverLetter();
            },
            onError: () => {
                addMessage('Error Saving Project Description', { severity: 'error' });
            }
        });
    }

    return (
        <Box sx={{ width: '100%' }}>
            <ProjectDescriptionForm id={id} data={data} onSaveDescription={onSaveDescription} getCoverLetter={getCoverLetter}/>
        </Box>
    )
}