import { IconButton } from "@mui/material";
import { DeleteForeverOutlined, Restore } from "@mui/icons-material";
import React from "react";

interface ToggleActiveProps {
    isActive: boolean;
    onClick: () => void;
}

const ToggleActiveButton: React.FC<ToggleActiveProps> = ({ isActive, onClick }) => {
    return (
        <IconButton
            onClick={onClick}
            size="small"
            style={{ backgroundColor: "#EB7F7F", borderRadius: "5px" }}
        >
            {
                isActive ?
                <DeleteForeverOutlined style={{ color: "#B72A2A" }} />
                :
                <Restore style={{ color: "#B72A2A" }} />
            }
        </IconButton>

    );
};

export default ToggleActiveButton;
