import { useQuery, useMutation, gql } from "@apollo/client";
import { Grid, MenuItem, FormGroup, FormControlLabel, Checkbox } from "@mui/material"
import HrTextInput from "../../atoms/HrTextInput"
import { StyledHeader } from "../../atoms/StyledHeader"
import { GET_REPORT_SETTINGS_QUERY, GetQuoteSettingsResult } from "../../../api/graphqlqueries/getreportsettings";
import * as yup from 'yup';
import { useCallback, useEffect, useState } from "react";
import debounce from "../../../utils/debounce";

const reportSettingsSchema = yup.object().shape({
    ReportDisplay: yup.string(),
    ReportUnits: yup.string(),
    ReportIncludeMileage: yup.boolean(),
    ReportIncludePerDiem: yup.boolean(),
    ReportIncludeMobilization: yup.boolean(),
    ReportIncludeMaterials: yup.boolean(),
    ReportIncludeRateSheet: yup.boolean(),
});

interface ReportSettingsProps {
    id: string;
}

const UPDATE_REPORT_SETTINGS_MUTATION = gql`
mutation UpdateBidQuoteReportSettings($Id: uniqueidentifier = "", $object: BidQuotes_set_input = {}) {
    update_BidQuotes_by_pk(pk_columns: {Id: $Id}, _set: $object) {
        __typename
        Id
        ReportDisplay
        ReportIncludeMileage
        ReportIncludePerDiem
        ReportUnits
        ReportIncludeMobilization
        ReportIncludeMaterials
        ReportIncludeRateSheet
        ReportIncludeDiscount
    }
  }
`;

interface GetBidStatusQueryResult {
    BidQuoteStatus: BidQuoteStatus[];
    BidQuotes: BidQuote[];
}

interface BidQuoteStatus {
    BidQuoteId: string;
    CreateDate: Date;
    Id: string;
    Notes: string;
    Status: "Draft" | "Pending" | "Approved" | "Awarded" | "Active" | "DNR" | "Deleted";
    UserId: string;
}

interface BidQuote {
    Id: string;
    JobId: string;
    BidDescription: string;
    JobCity: string;
    JobState: string;
    Zip: string;
    CustomerId: string;
    TimeAndMaterials: boolean;
    JobNumber: number;
}

interface BidQuoteConstructionService {
    Id: string;
    Discount: number;
}

interface BidQuoteItem {
    Id: string;
    BidQuoteConstructionServices: BidQuoteConstructionService[];
    Discount: number;
}

interface GetQuoteDiscountsResult {
    BidQuoteItems: BidQuoteItem[];
}

const GET_BID_STATUS_QUERY = gql`
query GetBidStatus($BidQuoteId: uniqueidentifier = "") {
  BidQuoteStatus(order_by: {CreateDate: desc}, where: {BidQuoteId: {_eq: $BidQuoteId}}) {
    BidQuoteId
    CreateDate
    Id
    Notes
    Status
    UserId
  }
  BidQuotes(where: {Id: {_eq: $BidQuoteId}}) {
    Id
    JobId
    JobNumber
    BidDescription
    JobCity
    JobState
    Zip
    CustomerId
    TimeAndMaterials
  }
}
`;

const GET_BID_DISCOUNTS_QUERY = gql`query GetQuoteDiscounts($BidQuoteId: uniqueidentifier = "") {
  BidQuoteItems(where: {BidQuoteId: {_eq: $BidQuoteId}}) {
    Id
    BidQuoteConstructionServices {
      Id
      Discount
    }
    Discount
  }
}
`;



export const QuoteReportSettings: React.FC<ReportSettingsProps> = ({ id }) => {
    const [reportSettings, setReportSettings] = useState(null as any);
    const [displayDiscount, setDisplayDiscount] = useState<boolean>(false);
    useQuery<GetQuoteSettingsResult>(GET_REPORT_SETTINGS_QUERY, {
        variables: {
            Id: id
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            setReportSettings(data?.BidQuotes_by_pk);
        }
    });

    useQuery<GetQuoteDiscountsResult>(GET_BID_DISCOUNTS_QUERY, {
        variables: {
            BidQuoteId: id
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            data.BidQuoteItems.forEach((item) => {
                if (item.Discount > 0) {
                    setDisplayDiscount(true);
                }
                item.BidQuoteConstructionServices.forEach((service) => {
                    if (service.Discount > 0) {
                        setDisplayDiscount(true);
                    }
                });
            });
        }
    });


    const { data: statusData } = useQuery<GetBidStatusQueryResult>(GET_BID_STATUS_QUERY, {
        variables: {
            BidQuoteId: id!,
        },
        fetchPolicy: 'cache-and-network'
    });

    const [updateReportSettings] = useMutation(UPDATE_REPORT_SETTINGS_MUTATION);
    const reportSettingsOnChange = (e: any) => {
        setReportSettings({ ...reportSettings, [e.target.name]: e.target.value });
    };
    const reportSettingsCheckboxChange = (e: any) => {
        setReportSettings({ ...reportSettings, [e.target.name]: e.target.checked });
    }

    const debouncer = useCallback(debounce((bidId: string, values: any) => updateSettings(bidId, values), 1000), []);

    useEffect(() => {
        debouncer(id, reportSettings);
    }, [reportSettings, id, debouncer]);


    const updateSettings = (bidId: string, reportSettingValues: any) => {
        if (reportSettingValues === null || JSON.stringify(reportSettingValues) === JSON.stringify(reportSettings)) return;
        try {
            const values = reportSettingsSchema.cast(reportSettingValues);
            const { __typename, ...otherValues } = values;
            updateReportSettings({
                variables: {
                    Id: bidId,
                    object: otherValues
                }
            })
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <Grid container direction="row" spacing={1}>
            <Grid item xs={12}>
                <StyledHeader>Report Settings</StyledHeader>
            </Grid>
            <Grid item xs={6}>
                <HrTextInput
                    select
                    name="ReportUnits"
                    label="Units"
                    value={reportSettings?.ReportUnits ?? ""}
                    fullWidth
                    onChange={reportSettingsOnChange}
                    overrideStatusDisable={true}
                >
                    <MenuItem value="Average">Average</MenuItem>
                    <MenuItem value="Actual">Actual</MenuItem>
                </HrTextInput>
            </Grid>
            <Grid item xs={6}>
                <HrTextInput
                    select
                    label="Display"
                    name="ReportDisplay"
                    value={reportSettings?.ReportDisplay ?? ""}
                    fullWidth
                    onChange={reportSettingsOnChange}
                    overrideStatusDisable={true}
                >
                    <MenuItem value="Summary">Summary</MenuItem>
                    <MenuItem value="Detail">Detail</MenuItem>
                </HrTextInput>
            </Grid>
            <Grid item xs={6}>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={reportSettings?.ReportIncludePerDiem ?? true} onChange={reportSettingsCheckboxChange} />}
                        name="ReportIncludePerDiem"
                        label="Include Per Diem in Line Item"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={reportSettings?.ReportIncludeMileage ?? true} onChange={reportSettingsCheckboxChange} />}
                        name="ReportIncludeMileage"
                        label="Include Mileage in Line Item"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={reportSettings?.ReportIncludeMobilization ?? true} onChange={reportSettingsCheckboxChange} />}
                        name="ReportIncludeMobilization"
                        label="Include Mobilization in Line Item"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={reportSettings?.ReportIncludeMaterials ?? true} onChange={reportSettingsCheckboxChange} />}
                        name="ReportIncludeMaterials"
                        label="Display All Materials"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={reportSettings?.ReportIncludeRateSheet ?? true} onChange={reportSettingsCheckboxChange} />}
                        name="ReportIncludeRateSheet"
                        label="Attach Rate Sheet"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={reportSettings?.ReportIncludeDiscount ?? false} onChange={reportSettingsCheckboxChange} />}
                        name="ReportIncludeDiscount"
                        label="Display Discount"
                        disabled={displayDiscount === false}
                    />
                </FormGroup>
            </Grid>
        </Grid>
    )
}