import { useQuery } from "@apollo/client";
import { CircularProgress, Grid } from "@mui/material";
import { GetQuoteSummaryQueryResults, GET_QUOTE_SUMMARY_QUERY } from '../../../api/graphqlqueries/quotesummaryquery';
import { EditQuoteForm } from "./BidbookEditQuoteForm";
import { BidbookQuoteStatus } from "./BidbookQuoteStatus";
import { QuoteSummaryUpdateDetails } from "./QuoteSummaryUpdateDetails";
import { QuoteSummaryTotals } from "./Totals/BidbookQuoteSummaryTotals";

interface QuoteSummaryProps {
    id: string;
}

export const QuoteSummary = ({ id }: QuoteSummaryProps) => {
    const { loading, data, error } = useQuery<GetQuoteSummaryQueryResults>(GET_QUOTE_SUMMARY_QUERY, {
        variables: {
            Id: id
        },
        fetchPolicy: 'cache-and-network',
    });

    if (error) {
        console.error(error);
    }


    if (loading || !data) {
        return (<CircularProgress />)
    }

    const bidQuote = data?.BidQuotes_by_pk;
    return (
        <Grid container direction="row" spacing={1}>
            <Grid item container xs={12}>
                <QuoteSummaryUpdateDetails />
            </Grid>
            <Grid item container direction="row" spacing={1} xs={12}>
                <QuoteSummaryTotals />
            </Grid>
            <Grid item container>
                <EditQuoteForm quote={bidQuote} />
            </Grid>
            <Grid item container direction="column">
                <BidbookQuoteStatus />
            </Grid>
        </Grid>
    )
}