import { makeAutoObservable, reaction, action } from "mobx";

export default class CommonStore {
    token: string | null = window.localStorage.getItem("jwt");
    appLoaded = false;
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
        reaction(
            () => this.token,
            (token) => {
                if (token) {
                    window.localStorage.setItem("jwt", token);
                } else {
                    window.localStorage.removeItem("jwt");
                }
            }
        );
    }

    @action
    setLoading = (isLoading : boolean) => {
        this.loading = isLoading;      
    }

    setToken = (token: string | null) => {
        this.token = token;
    };

    @action
    setAppLoaded = () => {
        this.appLoaded = true;
    };

    formatDateTimesToStrings = (date: Date) => {
        let dateValue = date.toString().split('T')[0]
        let formattedDate = dateValue.split('-')[1] + "-"+dateValue.split('-')[2]+"-"+dateValue.split('-')[0]
        let timeValue = date.toString().split('T')[1]
        let hourUnknownValue = timeValue.split(":")[0] as unknown
        let hourNumberValue = hourUnknownValue as number
        
        let formattedTime = (hourNumberValue - 12) > 0 
        ? (hourNumberValue - 12).toString() + ":" + timeValue.split(":")[1] + ' PM'
        : hourNumberValue + ":" + timeValue.split(":")[1] + " AM"
        const formattedDateTimeValue = formattedDate + " at " + formattedTime
        return formattedDateTimeValue 
    }

    formatDatesToStrings = (date: Date) => {
        let dateValue = date.toString().split('T')[0]
        let formattedDate = dateValue.split('-')[1] + "-"+dateValue.split('-')[2]+"-"+dateValue.split('-')[0]
        return formattedDate
    }
}
