import { useMutation, useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import { useParams } from 'react-router';
import { GET_CONSTRUCTION_SERVICE_QUERY, UPDATE_CONSTRUCTION_SERVICE_MUTATION } from '../../../../api/graphqlqueries/constructionserviceitem';
import { GetQuoteStatus, GET_QUOTE_STATUS_QUERY } from "../../../../api/graphqlqueries/getstatus";
import { GetQuoteFactorsQueryResult, GET_QUOTE_FACTORS_QUERY } from '../../../../api/graphqlqueries/quotefactorsquery';
import LineItemLoading from '../../../molecules/LineItemLoading';
import { TypeBox } from "../../../molecules/TypeBox";
import { QuoteServiceRateForm } from './BidbookQuoteServiceRateForm';

interface MaterialItemProps {
    bidItemId?: string;
    id?: string;
    area: string;
    onSave?: () => void;
}


export const QuoteServiceRate = ({ id, bidItemId, onSave, area }: MaterialItemProps) => {
    const { data, error } = useQuery(GET_CONSTRUCTION_SERVICE_QUERY, {
        variables: {
            Id: id
        }
    });

    const params = useParams<{ id: string }>();
    const { data: factorData } = useQuery<GetQuoteFactorsQueryResult>(GET_QUOTE_FACTORS_QUERY, {
        variables: {
            Id: params.id!
        }
    });

    const { data: statusData } = useQuery<GetQuoteStatus>(GET_QUOTE_STATUS_QUERY, {
        variables: {
            BidQuoteId: params.id!
        },
        fetchPolicy: 'cache-and-network'
    });

    const [updateMaterialItem] = useMutation(UPDATE_CONSTRUCTION_SERVICE_MUTATION);
    const updateNewMaterialItem = (values: any) => {

        updateMaterialItem({ variables: { Id: values.Id, object: values } });
    }

    if (error) {
        console.error(error);
    }
    if (!data || !factorData) return <LineItemLoading sx={{ backgroundColor: "rgba(0,0,0,0.2)" }} />;
    const quoteItem = data.BidQuoteConstructionServices_by_pk;
    const styles = {
        materialItemGrid: {
            paddingLeft: "1.5em",
            flexWrap: "nowrap !important",
            marginTop: "2px",
        }
    }
    return (
        <Grid item container sx={styles.materialItemGrid}>
            <TypeBox type={"Service"} />
            <QuoteServiceRateForm area={area} item={quoteItem} factorData={factorData.BidQuotes_by_pk} onSave={updateNewMaterialItem} status={statusData?.BidQuoteStatus[0].Status ?? ""} />
        </Grid>
    );

};