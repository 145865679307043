import { GridColDef } from '@mui/x-data-grid-pro';
import React, { useEffect } from 'react';
import { useStore } from "../../../stores/StoreContext";
import { observer } from "mobx-react-lite";
import RemoveButton from "../../atoms/RemoveButton";
import { HrDataGrid } from '../../molecules/HrDataGrid';

interface Props {
    rowData?: any;
}

export const CrewsDataGrid: React.FC<Props> = observer(() => {
    const { crewStore, modalStore, userStore } = useStore();
    const { openCrewModal } = modalStore;
    const { setSelectedCrew, mapCrewMembersToNestedAppUserDropdown,
        deleteCrew

    } = crewStore;
    useEffect(() => {

        async function loadCrews() {
            await crewStore.getCrewsAsync();
        }

        loadCrews().then();

        //eslint-disable-next-line
    }, [])

    useEffect(() => {

        if (crewStore.crews) {
        }
    }, [crewStore])

    const columns: GridColDef[] = [
        {
            field: 'deleteCrews',
            headerName: ' ',
            width: 50,
            renderCell: (rows) => {
                const crewId = rows.id;
                return (
                    <RemoveButton
                        title={''}
                        onClick={() => {
                            deleteCrew(crewId as string).then();
                        }}

                    />
                )

            }
        },
        {
            field: 'id',
            headerName: 'ID',
            width: 100,
            headerClassName: 'billables-grid-header'
        },
        {
            field: 'name',
            headerName: 'Crew Name',
            width: 200,
            editable: false,
            headerClassName: 'billables-grid-header'
        },
        {
            field: 'appUsers',
            headerName: 'Personnel',
            width: 450,
            editable: false,
            headerClassName: 'billables-grid-header'
        },

    ];

    const rows = crewStore.crews.map(crew => {
        let users: string[] = crew.appUsers.map(user => `${user.firstName} ${user.lastName}`)
        return {
            id: crew.id,
            name: crew.name,
            appUsers: users.join(', ')
        }
    })

    return (
        <HrDataGrid
            rows={rows}
            getRowId={undefined}
            columns={columns}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            disableRowSelectionOnClick
            onRowDoubleClick={model => {
                setSelectedCrew(model.id as string);
                crewStore.resetCrewDropDownList().then(() => {
                    crewStore.removeExistingCrewMembersFromCrewDropdownList(model.id as string);
                });
                mapCrewMembersToNestedAppUserDropdown(model.id as string);
                openCrewModal();
            }}
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        deleteCrews: userStore.appUser?.roles?.some(e => e.name === "BidBook Admin") ?? false
                    }
                }
            }}
        />
    )
});