import { useQuery } from "@apollo/client";
import { GetQuoteAddersItemsQueryResult, GET_QUOTE_ADDERS_ITEMS } from "../../../../api/graphqlqueries/getquoteaddersitems";
import { HrTextInput } from "../../../atoms/HrTextInput";
import { HrPercentInput } from "../../../atoms/HrPercentInput";
import { HrCurrencyInput } from "../../../atoms/HrCurrencyInput";
import { QuoteLineItemFormGrid } from "../../../atoms/QuoteLineItemFormGrid";

type Adder = GetQuoteAddersItemsQueryResult["BidQuoteMaterials"][0];
interface QuoteAddersTotalsProps{
    id : string,
    type? : string
}

export const GetQuoteAddersTotals = (bidQuoteId : string, groupWithType? : string) => {
    const { data } = useQuery<GetQuoteAddersItemsQueryResult>(GET_QUOTE_ADDERS_ITEMS, {
        variables: {
            BidQuoteId: bidQuoteId,
        },
        fetchPolicy: 'cache-and-network', 
    });
    const initialTotals = {quantity : 0, length : 0, avgDuration : 0, duration : 0, total : 0, totalWithTax : 0, totalWithMarkup: 0, tax : 0, avgTax : 0, markupTotalWithTax: 0, cost: 0 }

    const filterMaterials = (item: Adder) => {
        if (groupWithType) {
            return item.GroupWith === groupWithType;
        }
        return item.GroupWith === "" || item.GroupWith === "Quote";
    }
    const materials = data?.BidQuoteMaterials?.filter(filterMaterials) ?? [];
    const totals = materials.reduce((total : typeof initialTotals, item : any) => {
        const itemTotal = (item.Quantity * item.Rate * item.Duration);
        const taxTotal = itemTotal * (1 + item.Tax);
        const markupTotal = itemTotal * (1 + item.Markup);
        const taxMarkupTotal = markupTotal * (1 + item.Tax);
        total.duration += item.Duration;
        total.quantity += item.Quantity;
        total.length += 1;
        total.avgDuration = total.quantity !== 0 ? (total.duration/total.quantity) : 0;
        total.tax += item.Tax;
        total.avgTax = (data?.BidQuoteMaterials?.length ?? 0) !== 0 ? total.tax / data?.BidQuoteMaterials?.length! : 0; 
        total.total += itemTotal;
        total.totalWithTax += taxTotal;
        total.totalWithMarkup += markupTotal;
        total.markupTotalWithTax += taxMarkupTotal;
        total.cost += item.AdderHasCost ? itemTotal : 0;
        return total;
    }, initialTotals);
    return totals;
}

export const QuoteAddersTotals = (props : QuoteAddersTotalsProps) => {
    const { id } = props;
    const quoteTotals = GetQuoteAddersTotals(id);
    const surveyTotals = GetQuoteAddersTotals(id, "Survey");
    const constructionTotals = GetQuoteAddersTotals(id, "Construction");
    const technicianTotals = GetQuoteAddersTotals(id, "Technician");
    const inspectorTotals = GetQuoteAddersTotals(id, "Inspection");
    const lumpsumTotals = GetQuoteAddersTotals(id, "Lump Sum");
    const totalQuantity = quoteTotals.quantity + surveyTotals.quantity + constructionTotals.quantity + technicianTotals.quantity + inspectorTotals.quantity + lumpsumTotals.quantity;
    const totalDuration = quoteTotals.duration + surveyTotals.duration + constructionTotals.duration + technicianTotals.duration + inspectorTotals.duration + lumpsumTotals.duration;
    const totalLength = quoteTotals.length + surveyTotals.length + constructionTotals.length + technicianTotals.length + inspectorTotals.length + lumpsumTotals.length;
    const totals = {
        quantity: totalQuantity,
        avgQuantity: totalLength !== 0 ? totalQuantity / totalLength : 0,
        duration: totalDuration,
        avgDuration: totalQuantity !== 0 ? totalDuration / totalQuantity : 0,
        total: quoteTotals.total + surveyTotals.total + constructionTotals.total + technicianTotals.total + inspectorTotals.total + lumpsumTotals.total,
        totalWithTax: quoteTotals.totalWithTax + surveyTotals.totalWithTax + constructionTotals.totalWithTax + technicianTotals.totalWithTax + inspectorTotals.totalWithTax + lumpsumTotals.totalWithTax,
        avgTax: totalLength !== 0 ? (quoteTotals.tax + surveyTotals.tax + constructionTotals.tax + technicianTotals.tax + inspectorTotals.tax + lumpsumTotals.tax) / totalLength : 0,
    }
    const width = {
        small:"8ch",
        medium:"12ch",
        large:"18ch",
        xl:"24ch",
    }
    return(
        <QuoteLineItemFormGrid>
            <HrTextInput
                label='Quantity'
                width={width.medium}
                value={totals?.quantity}
                InputLabelProps={{ shrink : true}}
                disabled
            />
            <HrTextInput
                label='Avg Quantity'
                width={width.medium}
                value={totals?.avgQuantity}
                InputLabelProps={{ shrink : true}}
                disabled
            />
            <HrTextInput
                label='Duration'
                width={width.medium}
                value={totals?.duration}
                InputLabelProps={{ shrink : true}}
                disabled
            />
            <HrTextInput
                label='Avg Duration'
                width={width.medium}
                value={totals?.avgDuration?.toFixed(2)}
                InputLabelProps={{ shrink : true}}
                disabled
            />
            <HrPercentInput
                label='Avg Tax Rate'
                width={width.medium}
                value={totals?.avgTax?.toFixed(4).toString()}
                InputLabelProps={{ shrink : true}}
                disabled
            />
            <HrCurrencyInput
                label='Total Price'
                width={width.medium}
                value={totals?.total?.toString()}
                InputLabelProps={{ shrink : true}}
                disabled
            />
            <HrCurrencyInput
                label='Total Tax Price'
                width={width.medium}
                value={totals?.totalWithTax?.toString()}
                InputLabelProps={{ shrink : true}}
                disabled
            />
        </QuoteLineItemFormGrid>
    )
}