import React from 'react';
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { Grid } from "@mui/material";
import LineItemLoading from '../../../molecules/LineItemLoading';
import { TypeBox } from "../../../molecules/TypeBox";
import { DELETE_CONSTRUCTION_SERVICE_MUTATION, GetConstructionServiceQueryResult, GET_CONSTRUCTION_SERVICE_QUERY, UPDATE_CONSTRUCTION_SERVICE_MUTATION } from '../../../../api/graphqlqueries/constructionserviceitem';
import { QuoteConstructionServiceForm } from './BidbookQuoteConstructionServiceForm';

interface MaterialItemProps {
    bidItemId?: string;
    id?: string;
    area: string;
    onSave?: () => void;
}


export const QuoteConstructionServiceItem = ({ id, bidItemId, onSave, area }: MaterialItemProps) => {
    const { data, error } = useQuery<GetConstructionServiceQueryResult>(GET_CONSTRUCTION_SERVICE_QUERY, {
        variables: {
            Id: id
        }
    });
    const client = useApolloClient();
    const [updateMaterialItem] = useMutation(UPDATE_CONSTRUCTION_SERVICE_MUTATION);
    const updateNewMaterialItem = (values: any) => {
        
        updateMaterialItem({ variables: { Id: values.Id, object: values}});
    }

    const [deleteMaterialItem] = useMutation(DELETE_CONSTRUCTION_SERVICE_MUTATION);
    const quoteItem = data?.BidQuoteConstructionServices_by_pk;
    const deleteMaterialItemHandler = (Id: string) => {
        
        deleteMaterialItem({
            variables: { Id, BidQuoteItemId: bidItemId, Ordinal: quoteItem?.Ordinal ?? 0 },
            optimisticResponse: {
                delete_BidQuoteConstructionServices_by_pk: {
                    Id,
                    __typename: "BidQuoteConstructionServices"
                }
            },
            update(cache, { data }) {
                cache.evict({
                    id: `BidQuoteConstructionServices:${Id}`
                });
                cache.gc();
            },
            onError(error) {
                console.error(error);
                client.refetchQueries({
                    include: "active"
                });
            }
        });
    }

    if (error) {
        console.error(error);
    }
    if (!data) return <LineItemLoading sx={{backgroundColor:"rgba(0,0,0,0.2)"}}/>;
  
    const styles = {
        materialItemGrid : {
            paddingLeft:"1.5em",
            flexWrap:"nowrap !important", 
            marginTop:"2px", 
        }
    }
    return (
        <Grid item container sx={styles.materialItemGrid}>
            <TypeBox type={"Service"}/>
            <QuoteConstructionServiceForm area={area} item={quoteItem} onSave={updateNewMaterialItem} onDelete={deleteMaterialItemHandler} />
        </Grid>     
    );
    
};