import React from "react";
import Footer from "../organisms/Footer";
import RegisterForm from "../organisms/RegisterForm";

export default function RegisterPage() {
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100vh",
            width:"100%",
            background: 'no-repeat bottom/120% url("/images/mountain-logo-large.png")',
        }}>
            <div style={{ marginTop: 90, }}>
                <RegisterForm />
            </div>
            <div style={{
                borderWidth: 1,
                marginBottom: 50,
            }}>
                <Footer />
            </div>
        </div>
    );
}
