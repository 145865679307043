import { useQuery, useReactiveVar } from '@apollo/client';
import { Delete } from '@mui/icons-material';
import { Autocomplete, IconButton, InputAdornment, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { v4 } from 'uuid';
import * as yup from 'yup';
import { BidQuoteBillable, GetBidquoteBillablesQueryResult, GET_BIDQUOTE_BILLABLES_QUERY } from '../../../../api/graphqlqueries/bidquotebillables';
import debounce from '../../../../utils/debounce';
import { HrCurrencyInput } from "../../../atoms/HrCurrencyInput";
import { HrPercentInput } from "../../../atoms/HrPercentInput";
import HrTextInput from "../../../atoms/HrTextInput";
import { QuoteLineItemFormGrid } from "../../../atoms/QuoteLineItemFormGrid";
import { getMaterialCalculations, BidQuoteMaterial, MaterialCalculations } from "../../../../utils/calculations/adders";
import { DeleteWarning } from "../../../molecules/DeleteWarning";
import HrCheckBox from '../../../atoms/HrCheckBox';
import { disableForStatus } from '../../../../stores/ReactiveVariables';


interface QuoteMaterialItem {
    Id?: string;
    Quantity?: number;
    Cost?: number;
    Markup?: number;
    Type?: string;
    Unit?: string;
    Duration?: number;
    Rate?: number;
    OverrideRate?: boolean;
    BidQuoteBillableId?: string | null;
    LineBreakAfter?: boolean;
}
interface QuoteMaterialItemFormProps {
    item?: QuoteMaterialItem;
    type: string;
    onSave: (item: QuoteMaterialItem) => void;
    onDelete: (itemId: string) => void;
}

const quoteMaterialItemSchema = yup.object().shape({
    Markup: yup.number(),
    Quantity: yup.number(),
    Rate: yup.number(),
    Unit: yup.string(),
    Duration: yup.number(),
    BidQuoteBillabileId: yup.string().nullable(),
    OverrideRate: yup.boolean(),
    LineBreakAfter: yup.boolean(),
});

const width = {
    small: "10ch",
    medium: "14ch",
    large: "20ch",
    xLarge: "24ch"
}

export const QuoteMaterialItemForm = ({ item: materialItem, onSave, onDelete, type }: QuoteMaterialItemFormProps) => {
    const params = useParams<{ id: string }>();
    const disabledForStatus = useReactiveVar(disableForStatus);
    const [selectedBillable, setSelectedBillable] = useState(null as BidQuoteBillable | null);

    const Id = materialItem?.Id ?? v4();
    const { data } = useQuery<GetBidquoteBillablesQueryResult>(GET_BIDQUOTE_BILLABLES_QUERY, {
        variables: {
            BidQuoteId: params.id,
        }
    });
    const formik = useFormik({
        validationSchema: quoteMaterialItemSchema,
        initialValues: {
            Quantity: materialItem?.Quantity ?? 0,
            Rate: materialItem?.Rate ?? 0,
            Markup: materialItem?.Markup ?? 0,
            Unit: materialItem?.Unit ?? '',
            Duration: materialItem?.Duration ?? 0,
            BidQuoteBillableId: materialItem?.BidQuoteBillableId ?? null,
            OverrideRate: materialItem?.OverrideRate ?? false,
            LineBreakAfter: materialItem?.LineBreakAfter ?? false,
        },
        onSubmit: (values) => {
            const data = quoteMaterialItemSchema.cast(values);

            onSave({ Id, Type: type, ...data });
            formik.setSubmitting(false);
        }
    });

    useEffect(() => {
        setSelectedBillable(t => data?.BidQuoteBillables.find(b => b.Id === materialItem?.BidQuoteBillableId) ?? null);
    }, [data, setSelectedBillable, materialItem])

    const handleDelete = () => {
        onDelete(Id);
    }

    const updateData = (data: any) => {
        onSave({ Id, Type: type, ...data });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (formik.values[e.target.name as keyof typeof formik.values]?.toString() === e.target.value) return;
        try {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value };
            const values = quoteMaterialItemSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.setFieldValue(e.target.name, e.target.value);
    }

    const handleOverrideChange = () => {
        try {
            const updatedValues = { ...formik.values, OverrideRate: !formik.values.OverrideRate };
            const values = quoteMaterialItemSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.setFieldValue('OverrideRate', !formik.values.OverrideRate);
    }

    // eslint-disable-next-line
    const debouncer = useCallback(debounce((values: any) => updateData(values), 1000), []);

    const handleBillableSelect = (event: React.ChangeEvent<{}>, value: BidQuoteBillable | null) => {
        setSelectedBillable(value);
        formik.setFieldValue('BidQuoteBillableId', value?.Id ?? null);
        if (formik.values.BidQuoteBillableId === value?.Id) return;
        try {
            const values = quoteMaterialItemSchema.cast(formik.values);
            const updatedValues = { ...values, BidQuoteBillableId: value?.Id ?? null };
            debouncer(updatedValues);
        } catch (error) {
            console.error(error);
        }
    };

    const materialCalculations: MaterialCalculations = getMaterialCalculations(materialItem as BidQuoteMaterial);

    const setUnit = (value: string) => {
        formik.setFieldValue("Unit", value);
        if (value === "Each") {
            formik.setFieldValue("Duration", 1);
        }
        if (formik.isValid) {
            const values = quoteMaterialItemSchema.cast(formik.values);
            const updatedValues = { ...values, Unit: value, Duration: value === "Each" ? 1 : values.Duration };
            debouncer(updatedValues);
        }
    }

    const handlePercentChange = (e: ChangeEvent<HTMLInputElement>) => {
        const cleanNumberString = e.target.value.replace('%', '');
        const numberValue = parseFloat(cleanNumberString);
        if (isNaN(numberValue)) return;
        try {
            const updatedValues = { ...formik.values, [e.target.name]: numberValue / 100 };
            const values = quoteMaterialItemSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.setFieldValue(e.target.name, numberValue / 100);
    }

    const handleCheckChange = (e: ChangeEvent<HTMLInputElement>) => {
        try {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.checked };
            const values = quoteMaterialItemSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.setFieldValue(e.target.name, e.target.checked);
    }
    const filteredItems = data?.BidQuoteBillables.filter(b => b.Type === type);

    return (
        <QuoteLineItemFormGrid>
            <Autocomplete
                options={filteredItems ? filteredItems.sort((a: any, b: any) => a.Name?.localeCompare(b.Name)) : []}
                value={selectedBillable ?? null}
                disabled={disabledForStatus}
                renderInput={(params: any) =>
                    <HrTextInput
                        {...params}
                        name='Product'
                        label={type === 'Material' ? 'Product' : 'Equipment'}
                        width={width.xLarge}
                    />
                }
                onChange={handleBillableSelect}
                getOptionLabel={(option: BidQuoteBillable) => option.Name}

            />

            <HrTextInput
                name='Quantity'
                label='Quantity'
                width={width.small}
                onChange={handleChange}
                value={formik.values.Quantity}
                errors={formik.errors.Quantity}
            />

            <HrCurrencyInput
                name='Rate'
                label='Rate'
                width={width.medium}
                onChange={selectedBillable ? handleChange : undefined}
                value={formik.values.OverrideRate ? formik.values.Rate : selectedBillable?.Cost ?? 0}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <HrCheckBox
                                name="OverrideRate"
                                checked={formik.values.OverrideRate}
                                onChange={handleOverrideChange}
                                sx={{
                                    m: "0 -8px 0 0",
                                    "& .MuiCheckbox-root": {
                                        p: 0
                                    }
                                }}
                            />
                        </InputAdornment>
                    )
                }}
                InputLabelProps={{ shrink: true }}
                errors={formik.errors.Rate}
                disabled={!formik.values.OverrideRate}
            />

            <HrPercentInput
                label='Markup'
                name='Markup'
                width={width.small}
                onChange={handlePercentChange}
                value={formik.values.Markup}
                errors={formik.errors.Markup}
            />
            <HrTextInput
                select
                name='Unit'
                label='PER'
                width={width.small}
                onChange={(e) => setUnit(e.target.value)}
                value={formik.values.Unit}
                errors={formik.errors.Unit}
            >
                <MenuItem value="" />
                <MenuItem value="Day">Day</MenuItem>
                <MenuItem value="Miles">Miles</MenuItem>
                <MenuItem value="Each">Each</MenuItem>
                <MenuItem value="Feet">Feet</MenuItem>
                <MenuItem value="Sets">Sets</MenuItem>
                <MenuItem value="Hours">Hours</MenuItem>
            </HrTextInput>
            <HrTextInput
                name='Duration'
                label='Duration'
                width={width.small}
                onChange={handleChange}
                value={formik.values.Duration}
                errors={formik.errors.Duration}
            />
            <HrCheckBox
                name="LineBreakAfter"
                checked={formik.values.LineBreakAfter}
                onChange={handleCheckChange}
                label='Line Break After'
            />
            <HrCurrencyInput
                label='Total'
                width={width.medium}
                value={materialCalculations.TotalPrice.toString()}
                disabled
            />
            <HrCurrencyInput
                label='Markup Total'
                width={width.medium}
                value={materialCalculations?.TotalMarkupPrice?.toString()}
                disabled
            />
            <DeleteWarning
                onClick={handleDelete}
                heading={`Delete ${type} Item`}
                disabled={disabledForStatus}
            >
                <IconButton
                    color="error"
                >
                    <Delete />
                </IconButton>
            </DeleteWarning>
            {/* <IconButton
                color="error"
                onClick={handleDelete}
            >
                <Delete />
            </IconButton> */}
        </QuoteLineItemFormGrid>
    );
};