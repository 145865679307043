import { MenuItem } from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, useCallback } from 'react';
import { v4 } from 'uuid';
import * as yup from 'yup';
import debounce from '../../../../utils/debounce';
import { HrCurrencyInput } from '../../../atoms/HrCurrencyInput';
import { HrPercentInput } from '../../../atoms/HrPercentInput';
import HrTextInput from "../../../atoms/HrTextInput";
import { QuoteLineItemFormGrid } from '../../../atoms/QuoteLineItemFormGrid';
import { BidQuoteStatus } from "../../../../api/graphqlqueries/getstatus";
import { getAdderCalculations, BidQuoteMaterial, MaterialCalculations } from "../../../../utils/calculations/adders";
import HrCheckBox from "../../../atoms/HrCheckBox";

interface QuoteAdderItem {
    Id?: string;
    Description?: string;
    Quantity?: number;
    Type?: string;
    Unit?: string;
    Markup?: number;
    Duration?: number;
    Rate?: number;
    Tax?: number;
    GroupWith?: string;
    status?: BidQuoteStatus | null;
    AdderHasCost?: boolean;
}

interface QuoteMaterialItemFormProps {
    item?: QuoteAdderItem;
    onSave: (item: QuoteAdderItem) => void;
    total: number //write interface for this
    status: string;
}

const quoteAdderSchema = yup.object().shape({
    Quantity: yup.number(),
    Rate: yup.number(),
    Unit: yup.string(),
    Markup: yup.number(),
    Duration: yup.number(),
    Description: yup.string(),
    Type: yup.string(),
    Tax: yup.number(),
    GroupWidth: yup.string(),
    AdderHasCost: yup.boolean(),
});

const width = {
    small: "8ch",
    medium: "12ch",
    large: "18ch",
    xLarge: "24ch"
}

const styles = {
    menuItem: {
        display: "flex!important"
    }
}

export const QuoteAddersRateForm = ({ item: quoteAdderItem, onSave, total, status }: QuoteMaterialItemFormProps) => {
    const Id = quoteAdderItem?.Id ?? v4();
    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: quoteAdderSchema,
        initialValues: {
            Quantity: quoteAdderItem?.Quantity ?? 0,
            Rate: quoteAdderItem?.Rate ?? 0,
            Unit: quoteAdderItem?.Unit ?? '',
            Markup: quoteAdderItem?.Markup ?? 0,
            Duration: quoteAdderItem?.Duration ?? 0,
            Description: quoteAdderItem?.Description ?? '',
            Type: quoteAdderItem?.Type ?? '',
            Tax: quoteAdderItem?.Tax ?? 0,
            GroupWith: quoteAdderItem?.GroupWith ?? '',
            AdderHasCost: quoteAdderItem?.AdderHasCost ?? false,
        },
        onSubmit: (values) => {
            const data = quoteAdderSchema.cast(values);
            onSave({ Id, ...data });
            formik.setSubmitting(false);
        }
    });


    const updateData = (data: any) => {
        onSave({ Id, ...data });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (formik.values[e.target.name as keyof typeof formik.values]?.toString() === e.target.value) return;
        try {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value};
            const values = quoteAdderSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.handleChange(e);
    }

    const handlePercentChange = (e: ChangeEvent<HTMLInputElement>) => {
        const cleanNumberString = e.target.value.replace('%', '');
        const numberValue = parseFloat(cleanNumberString);
        if (isNaN(numberValue)) return;
        try {
            const updatedValues = { ...formik.values, [e.target.name]: numberValue / 100 };
            const values = quoteAdderSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.setFieldValue(e.target.name, numberValue / 100);
    }

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        try {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.checked};
            const values = quoteAdderSchema.cast(updatedValues);
            debouncer(values);
            formik.setFieldValue(e.target.name, e.target.checked);
        } catch (error) {
            console.error(error);
        }
    }



    // eslint-disable-next-line

    // eslint-disable-next-line
    const debouncer = useCallback(debounce((values: any) => updateData(values), 1000), []);

    const addersCalculations: MaterialCalculations = getAdderCalculations(quoteAdderItem as BidQuoteMaterial);

    return (
        <QuoteLineItemFormGrid>
            <HrTextInput
                name='Description'
                label='Adder Detail'
                width={width.xLarge}
                onChange={handleChange}
                value={formik.values.Description}
                errors={formik.errors.Description}
                disabled
            />
            <HrTextInput
                select
                name='Type'
                label='Type'
                width={width.large}
                onChange={handleChange}
                value={formik.values.Type}
                errors={formik.errors.Type}
                disabled
            >
                <MenuItem sx={styles.menuItem} value=""></MenuItem>
                <MenuItem sx={styles.menuItem} value="Equipment">Equipment</MenuItem>
                <MenuItem sx={styles.menuItem} value="Material">Materials</MenuItem>
                <MenuItem sx={styles.menuItem} value="Service">Service</MenuItem>
            </HrTextInput>

            <HrCurrencyInput
                name='Rate'
                label='Rate'
                width={width.medium}
                onChange={handleChange}
                value={formik.values.Rate.toString()}
                errors={formik.errors.Rate}
                disabled
            />
            <HrPercentInput
                label='Markup'
                name='Markup'
                width={width.small}
                onChange={handlePercentChange}
                value={formik.values.Markup}
                errors={formik.errors.Markup}
                disabled={status !== "Draft" && status !== "Pending"}
            />
            <HrPercentInput
                label='Tax'
                name='Tax'
                width={width.small}
                onChange={handlePercentChange}
                value={formik.values.Tax}
                errors={formik.errors.Tax}
                disabled
            />
            <HrCurrencyInput
                name='Total'
                label='Total'
                width={width.medium}
                value={addersCalculations?.TotalPrice?.toFixed(2)}
                disabled
            />
            <HrCurrencyInput
                name='Total'
                label='Markup Total'
                width={width.medium}
                value={addersCalculations.TotalMarkupPrice?.toFixed(2)}
                disabled
            />
            <HrCurrencyInput
                label='Sales Tax Price'
                width={width.medium}
                value={addersCalculations.TotalMarkupPriceSalesTax?.toString()}
                disabled
            />
            <HrCheckBox
                name='AdderHasCost'
                label='Adder Has Cost'
                onChange={handleCheckboxChange}
                checked={formik.values.AdderHasCost}
            />
        </QuoteLineItemFormGrid>
    );
}