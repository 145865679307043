import { useQuery } from "@apollo/client";
import { GetConstructionItemsQueryResult, GET_CONSTRUCTION_ITEMS_QUERY } from "../../../../api/graphqlqueries/getbidquoteconstitems";
import { GetQuoteFactorsQueryResult, GET_QUOTE_FACTORS_QUERY } from "../../../../api/graphqlqueries/quotefactorsquery";
import { HrCurrencyInput } from "../../../atoms/HrCurrencyInput";
import { HrTextInput } from "../../../atoms/HrTextInput";
import { QuoteLineItemFormGrid } from "../../../atoms/QuoteLineItemFormGrid";
import { DivisionCalculations, getDivisionCalculation } from "../../../../utils/calculations/divisions";
import { QuoteFactors } from "../../../../utils/calculations/adders";

export interface ItemTotals {
    days: number;
    mob: number;
    mobWoPd: number;
    setup: number;
    rest: number;
    totalDays: number;
    avgRate: number;
    equipment: number;
    mileage: number;
    totalPrice: number;
}

interface QuoteItemTotalsProps {
    type: string
    id: string
}

export const QuoteItemTotals = (props: QuoteItemTotalsProps) => {
    const { id, type } = props;
    const width = {
        small: "8ch",
        medium: "12ch",
        large: "18ch",
        xl: "24ch",
    }
    const totals: DivisionCalculations = GetQuoteItemTotals(id, type);
    return (
        <QuoteLineItemFormGrid>
            <HrTextInput
                label='Days'
                width={width.medium}
                value={totals?.Days}
                InputLabelProps={{ shrink: true }}
                disabled
            />
            <HrTextInput
                label='Mob'
                width={width.medium}
                value={totals?.Mob}
                InputLabelProps={{ shrink: true }}
                disabled
            />
            <HrTextInput
                label='Less Per Diem'
                width={width.medium}
                value={totals?.MobWoPD}
                InputLabelProps={{ shrink: true }}
                disabled
            />
            <HrTextInput
                label='Setup'
                width={width.medium}
                value={totals?.Setup}
                InputLabelProps={{ shrink: true }}
                disabled
            />
            <HrTextInput
                label='Rest'
                width={width.medium}
                value={totals?.Rest}
                InputLabelProps={{ shrink: true }}
                disabled
            />
            <HrTextInput
                label='Total Days'
                width={width.medium}
                value={totals?.TotalDays}
                InputLabelProps={{ shrink: true }}
                disabled
            />
            <HrCurrencyInput
                label='Avg Rate'
                width={width.medium}
                value={totals?.AverageRate.toFixed(2).toString()}
                InputLabelProps={{ shrink: true }}
                disabled
            />
            <HrTextInput
                label='Total Mileage'
                width={width.medium}
                value={totals?.Mileage}
                InputLabelProps={{ shrink: true }}
                disabled
            />
            <HrCurrencyInput
                label='Total Price'
                width={width.medium}
                value={totals?.TotalPrice.toString()}
                InputLabelProps={{ shrink: true }}
                disabled
            />
        </QuoteLineItemFormGrid>
    )
}

export const GetQuoteItemTotals = (bidQuoteId: string, type: string) => {
    const { data: factorData } = useQuery<GetQuoteFactorsQueryResult>(GET_QUOTE_FACTORS_QUERY, {
        variables: {
            Id: bidQuoteId
        }
    });

    const { data } = useQuery<GetConstructionItemsQueryResult>(GET_CONSTRUCTION_ITEMS_QUERY, {
        variables: {
            BidQuoteId: bidQuoteId,
            Type: type
        },
        fetchPolicy: 'cache-and-network',
    });
    const defaultValues = {
        Days: 0,
        Mob: 0,
        MobWoPD: 0,
        Setup: 0,
        Rest: 0,
        TotalDays: 0,
        AverageRate: 0,
        MaterialItemsTotal: 0,
        TotalMiles: 0,
        TotalPrice: 0,
        TotalPriceWoMaterials: 0,
        Equipment: 0,
        Mileage: 0,
        Locations: 0,
        TotalCost: 0,
        TotalCostWoMaterials: 0,
        TotalDiscountPrice: 0,
    };
    if (!data || !factorData) return defaultValues;

    const totals = getDivisionCalculation(data?.BidQuoteItems, factorData?.BidQuotes_by_pk as QuoteFactors);
    return totals;
}