import { gql } from '@apollo/client';

export interface GetQuoteAddersQueryResult {
  BidQuoteMaterials: BidQuoteMaterial[];
}

interface BidQuoteMaterial {
  BidQuoteId: string;
  Id: string;
  GroupWith: string;
  Ordinal: number;
}

export const GET_QUOTE_ADDERS = gql`
  query GetQuoteAdders($BidQuoteId: uniqueidentifier = "") {
    BidQuoteMaterials(where: {BidQuoteId: {_eq: $BidQuoteId}}) {
      BidQuoteId
      Id
      GroupWith
      Ordinal
    }
  }
`;