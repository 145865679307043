import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GetBidbookUsersQueryResult, GET_BIDBOOK_USERS_QUERY } from "../../../../../api/graphqlqueries/getbidbookusersquery";
import { GetClientRepsQueryResult, GET_CLIENT_REPS_QUERY } from "../../../../../api/graphqlqueries/getclientrepsquery";
import { KickoffPacketSection } from "../KickoffPacketSection";
import { ProjectContactsForm } from "./ProjectContactsForm";
import { KICKOFF_PACKET_CONTACTS_QUERY, KickoffPacketContactsQueryResults, KickoffPacketCustomerContact, UPDATE_KICKOFF_CONTACTS_MUTATION, UPDATE_KICKOFF_CUSTOMER_CONTACTS_MUTATION } from "./ProjectContactsQueries";
import LineItemLoading from "../../../../molecules/LineItemLoading";

interface ProjectContactsProps{
    id: string;
}

export const ProjectContacts: React.FC<ProjectContactsProps> = ({ id }: ProjectContactsProps) => {
    const { data: userData } = useQuery<GetBidbookUsersQueryResult>(GET_BIDBOOK_USERS_QUERY);
    const { data: kickoffData } = useQuery<KickoffPacketContactsQueryResults>(KICKOFF_PACKET_CONTACTS_QUERY, {
        variables: {
            Id: id
        },
        onCompleted: (data) => {
            getClientRep({ variables: { CustomerId: data?.KickoffPacket_by_pk?.CustomerId } });
        }
    });
    const [getClientRep, { data: clientRepData }] = useLazyQuery<GetClientRepsQueryResult>(GET_CLIENT_REPS_QUERY, {
        fetchPolicy: 'cache-and-network',
    });

    const [updateKickoffContacts] = useMutation(UPDATE_KICKOFF_CONTACTS_MUTATION);
    const [updateKickoffCustomerContacts] = useMutation(UPDATE_KICKOFF_CUSTOMER_CONTACTS_MUTATION);

    if (!userData || !kickoffData || !clientRepData) {
        return <KickoffPacketSection heading="Project Contacts"><LineItemLoading /></KickoffPacketSection>
    }

    const lastNameSortFunc = (a: any, b: any) => {
        const nameA = `${a?.LastName}, ${a?.FirstName}`;
        const nameB = `${b?.LastName}, ${b?.FirstName}`;
        return nameA.localeCompare(nameB);
    }

    const handleKickoffChanges = (change: { key: string, value: string }) => {
        updateKickoffContacts({
            variables: {
                Id: id,
                _set: {
                    [change.key]: change.value
                }
            }
        });
    }

    const handleContactChanges = (contacts: KickoffPacketCustomerContact[]) => {
        const mappedContacts = contacts.map((contact, index) => {
            const { CustomerContact, ...strippedContact } = contact;
            return {
                ...strippedContact,
                KickoffPacketId: id,
            }
        });
        updateKickoffCustomerContacts({
            variables: {
                objects: mappedContacts
            },
            refetchQueries: [{ query: KICKOFF_PACKET_CONTACTS_QUERY, variables: { Id: id } }]
        });
    }

    const sortedCustomerContactList: any = clientRepData?.CustomerContacts ? [...clientRepData?.CustomerContacts].sort(lastNameSortFunc) : [];
    const sortedUserList = userData?.AspNetUsers ? [...userData?.AspNetUsers].sort(lastNameSortFunc) : [];
    return(
        <KickoffPacketSection heading="Project Contacts">
            <ProjectContactsForm
                users={sortedUserList}
                clientContacts={sortedCustomerContactList}
                kickoffPacket={kickoffData.KickoffPacket_by_pk}
                onChangePacket={handleKickoffChanges}
                onChangeCustomerContacts={handleContactChanges}
                id={id}
            />
        </KickoffPacketSection>
    ) 
}