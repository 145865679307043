//SECTION CHECKBOX

import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { addMessage, resetMessage } from "../../../../../stores/ReactiveVariables";
import * as yup from 'yup';
import { AccordionCheckbox } from "../AccordionCheckbox";

const GET_COVER_LETTER_PURPOSE_SECTION = gql`
    query GetCoverLetterPurpose($Id: uniqueidentifier = "") {
        BidQuotes_by_pk(Id: $Id) {
            CoverLetterPurposeSection
        }
    }
`;

const UPDATE_COVER_LETTER_PURPOSE_SECTION = gql`
    mutation UpdateCoverLetterPurposeSection($Id: uniqueidentifier = "", $CoverLetterPurposeSection: Boolean = false) {
        update_BidQuotes_by_pk(pk_columns: {Id: $Id}, _set: {CoverLetterPurposeSection: $CoverLetterPurposeSection}) {
            __typename
            Id
        }
    }  
`;

interface PurposeCheckboxProps{
    id: string;
    getCoverLetter: () => void;
}

const validationSchema = yup.object().shape({
    CoverLetterPurposeSection: yup.boolean(),
});

export const PurposeCheckbox: React.FC<PurposeCheckboxProps> = ({ id, getCoverLetter }: PurposeCheckboxProps) => {    
    const { data, loading, error } = useQuery(GET_COVER_LETTER_PURPOSE_SECTION, { variables: { Id: id } });
    const [updateCoverLetterExecutionPlan] = useMutation(UPDATE_COVER_LETTER_PURPOSE_SECTION);

    const formData = data?.BidQuotes_by_pk;
    
    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            CoverLetterPurposeSection: formData?.CoverLetterPurposeSection ?? false,
        },
        onSubmit: (values: any) => {
            updateCoverLetterExecutionPlan({
                variables: { Id: id, CoverLetterPurposeSection: values.CoverLetterPurposeSection },
                onCompleted: (data: any) => {
                    resetMessage();
                    const confirmMessage = values.CoverLetterPurposeSection === true ? "Purpose Added To Cover Letter" : "Purpose Removed From Cover Letter";
                    addMessage(confirmMessage, { severity: 'success' });
                    getCoverLetter();
                },
                onError: () => {
                    addMessage('Error Saving/Removing Purpose', { severity: 'error' });
                }
            });
        }
    });
    return (
        <AccordionCheckbox 
            getCoverLetter={getCoverLetter}
            name="CoverLetterPurposeSection"
            checked={formik.values.CoverLetterPurposeSection}
            onCheckboxClick={()=> formik.setFieldValue('CoverLetterPurposeSection', !formik.values.CoverLetterPurposeSection)}
            onSave={formik.submitForm}
            loading={loading}
        />
    )
}
