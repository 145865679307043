import { action, computed, makeAutoObservable, observable, runInAction } from "mobx";
import Agent from '../api/agent';
import { CrewDto } from '../Dtos/crewDto';
import { AppUser } from "../models/appUser";
import { v4 as uuid } from 'uuid';
import { Crew } from "../models/crew";

interface NestedAppUser {
    myKey: string,
    appUser: AppUser
}

interface CrewGridRows {
    id: string,
    appUsers: string,
}

export default class CrewStore {
    crews: Crew[] = [];
    crewDtos: CrewDto[] = [];
    agent: Agent;
    // Will set to undefined on create new crew
    selectedCrew: Crew | undefined = undefined;
    isUpdate: boolean = false;
    // crews: any[] = observable.array();
    crewName: string[] = [''];
    availableCrewMembers: Crew[] = [];

    @observable
    crewGridRows: CrewGridRows[] = [];


    // Number of dropdown boxes in crew.
    // Will be empty when creating new crew.
    // Will equal the number of members of existing crew.
    nestedAppUsers: NestedAppUser[] = [];


    // List of available app users not currently selected for crew.
    crewDropDownList: AppUser[] = [];

    // List of all appUsers. Not changing.
    allAppUsers: AppUser[] = [];

    constructor(myAgent: Agent) {
        makeAutoObservable(this);
        this.agent = myAgent;
    }


    @action
    setSelectedCrew = (id: string | undefined) => {
        if (!id) {
            this.selectedCrew = undefined;
        }
        this.selectedCrew = this.crews.find(c => c.id === id);
    }

    // @action
    // populateCrewAppUserString = ()=>{
    //     while(this.crewAppUserStrings.length>0){
    //         this.crewAppUserStrings.pop();
    //     }
    //
    //    this.crews.AppUsers.forEach(user=>{
    //        this.crewAppUserStrings.push(user.userName!);
    //    })
    //
    // }

    @action
    setIsUpdate = (val: boolean) => {
        this.isUpdate = val;
    }

    @action
    clearNestedAppUserDropdown = () => {
        while (this.nestedAppUsers.length > 0) {
            this.nestedAppUsers.pop();
        }
    }

    @action
    mapCrewMembersToNestedAppUserDropdown = (crewId: string | undefined) => {
        // Remove elements without create new array
        while (this.nestedAppUsers.length > 0) {
            this.nestedAppUsers.pop();
        }
        let crew = this.crews.find(c => c.id === crewId);

        // back out quickly, checking bad path, not happy path
        if (crew) {

            crew.appUsers.forEach(user => {
                let myUuid = uuid();
                this.nestedAppUsers.push(
                    {
                        myKey: myUuid,
                        appUser: {
                            id: user.id,
                            firstName: user.firstName,
                            lastName: user.lastName
                        }
                    })
                // this.crewDropDownList = this.crewDropDownList.filter(c=>c.id!==user.id);
            })
        }
    }

    @action
    addNestedAppUserDropdown = () => {
        let myUuid = uuid();
        this.nestedAppUsers.push(
            {
                myKey: myUuid,
                appUser: {
                    id: '',
                    firstName: '',
                    lastName: ''
                }
            })
    }

    @action
    addMemberToAddedCrew = async (id: string | null, myUuid: string) => {
        if (!id) return
        // this is sus, feels like it can be turned into a computed value
        // or all of this logic consolidated into 1 function that doesnt
        // require finding/filtering/pushing
        // check if member is in box. If so, remove it
        let objInd = this.nestedAppUsers.findIndex((obj => obj.myKey === myUuid))
        let oldMember = this.nestedAppUsers[objInd].appUser;
        oldMember.id && this.crewDropDownList.push(oldMember);
        let newMember = this.crewDropDownList.find(c => c.id === id);
        if (!newMember) return
        let objIndex = this.nestedAppUsers.findIndex((obj => obj.myKey === myUuid));
        this.nestedAppUsers[objIndex].appUser = newMember;
        this.crewDropDownList = this.crewDropDownList.filter(c => c.id !== id)
    };
    // use splice for delete
    @action
    removeMemberFromAddedCrew = async (myUuid: string) => {
        let newMember = this.nestedAppUsers.find(m => m.myKey === myUuid);
        if (newMember && newMember.appUser.id) {
            this.crewDropDownList.push(newMember.appUser);
        }
        if (newMember) {
            this.nestedAppUsers = this.nestedAppUsers.filter(m => m.myKey !== myUuid);
        }
    }

    @action
    handleChangeCrewName = (crewName: string) => {
        this.crewName[0] = crewName;
        if (this.selectedCrew) this.selectedCrew.name = crewName;
    }

    @action
    resetCrewDropDownList = async () => {

        let allUsers: AppUser[] = [];

        try {
            allUsers = await this.agent.AppUsers.list();
        } catch (e) {
            console.log(e)
        }
        runInAction(() => {
            this.crewDropDownList = allUsers;
        })
    }

    @action
    removeExistingCrewMembersFromCrewDropdownList = (crewId: string) => {

        let crew = this.crews.find(c => c.id === crewId);
        if (!crew) return

        // computed
        crew.appUsers.forEach(user => {
            let removeIndex = this.crewDropDownList.map(c => c.id).indexOf(user.id);
            runInAction(() =>
                this.crewDropDownList.splice(removeIndex, 1));
        })
    }

    @action
    getCrewsAsync = async () => {
        try {
            const crewList = await this.agent.Crews.list();

            runInAction(() => {
                this.crews = observable(crewList);
            })
        } catch (error: any) {
            console.log('error: ', error)
            throw error;
        }
    }

    @action
    getUsersAsync = async () => {
        try {
            const userList = await this.agent.AppUsers.list();

            // look at example above
            // while (this.crewDropDownList.length > 0) {
            // this.crewDropDownList.pop();

            runInAction(() => {
                this.crewDropDownList = observable(userList);
            })

            runInAction(() => {
                this.allAppUsers = observable(userList);
            })

        } catch (error: any) {
            console.log(error);
            throw error;
        }
    }

    @computed
    get validCrewOptions() {
        // let selectedIds = this.allAppUsers.map(x => x.id)
        let selectedIds = this.nestedAppUsers.map(x => x.appUser.id)
        return this.allAppUsers.filter(x => !selectedIds.includes(x.id))
    }

    @action
    createCrew = async () => {
        let userIds: string[] = [];
        this.nestedAppUsers.forEach(item => {
            if (item.appUser.id !== '' && item.appUser.id !== undefined) {
                userIds.push(item.appUser.id!)
            }
        })
        try {
            let newCrew: CrewDto = {
                id: uuid(),
                name: this.crewName[0],
                userIds: userIds
            }
            await this.agent.Crews.create(newCrew)
        } catch (error) {
            console.log(error)
            throw error;
        }
    }

    @action
    upDateCrew = async () => {
        let userIds: string[] = [];
        this.nestedAppUsers.forEach(item => {
            if (item.appUser.id !== '' && item.appUser.id !== undefined) {
                userIds.push(item.appUser.id!)
            }
        })
        try {
            let upDatedCrew: CrewDto = {
                id: this.selectedCrew!.id,
                name: this.selectedCrew?.name!,
                userIds: userIds
            }
            await this.agent.Crews.update(upDatedCrew, this.selectedCrew!.id)
        } catch (error) {
            console.log(error);
            throw error;
        }

    }

    deleteCrew = async (id: string) => {
        try {
            await this.agent.Crews.delete(id);
            await this.getCrewsAsync();
        } catch (error) {
            console.log(error)
            throw error;
        }
    }

    @computed
    get gridRows() {
        let rows: Array<object> = [];

        let testCrewList: Crew[] = [];

        this.crews.forEach(crew => {
            testCrewList.push(crew);
        })
        this.crews.forEach(crew => {
            let users: string[] = [];

            crew.appUsers.forEach(user => {
                users.push(user.userName!)
            })

            rows.push({
                id: crew.id,
                name: crew.name,
                appUsers: users.join(', ')
            })
        });
        return rows;
    }

    @computed
    get getAddedCrews() {
        let nestedUsers: NestedAppUser[] = [];
        this.nestedAppUsers.forEach(crew => {
            nestedUsers.push(crew)
        })
        return nestedUsers;
    }

    // for debugging

}