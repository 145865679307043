import { makeAutoObservable, runInAction, action, observable } from 'mobx';
import { Agent } from '../api/agent';
import { Customer } from '../models/customer';
import { Job } from '../models/job';
import { AppUser } from '../models/appUser';

export default class JobStore {
    jobRegistry: Job[] | [] = [];
    agent: Agent;
    checkedJobs: Job[] | undefined = undefined;
    selectedJob: Job | undefined = undefined;
    editCreateOrView: string = 'view';
    isEditing = false;
    isLoading = false;
    reqNotes?: boolean;
    customerComboOptions: Customer[] | [] = [];
    jobFilterName : string | undefined;
    employeeComboOptions: AppUser[] = [];

    constructor(myAgent: Agent) {
        makeAutoObservable(this);
        this.agent = myAgent;
    }

    setCustomerComboOptions = (value: Customer[]) => {
        runInAction(() => {
            this.customerComboOptions = value;
        });
    };

    getCustomerComboOptions = async () => {
        try {
            const customers: Customer[] = await this.agent.Customers.list();
            runInAction(() => {
                this.setCustomerComboOptions(customers);
            });
        } catch (error) {
            console.log(error);
        }
    };

    setSelectedJob = (id: string | undefined) => {
        runInAction(() => {
            this.selectedJob = this.jobRegistry.find((x) => x.id === id);
        });
    };

    setEditOrCreateMode = (state: string) => {
        runInAction(() => {
            this.editCreateOrView = state;
        });
    };

    @action
    setJobs = (value: Job[]) => {
        this.jobRegistry = value;
    };

    loadJobs = async () => {
        try {
            const jobs: Job[] = await this.agent.Jobs.list();
            runInAction(() => {
                this.setJobs(jobs);
            });
        } catch (error) {
            console.log(error);
        }
    };

    createJob = async (job: Job) => {
        this.isLoading = true;
        try {
            await this.agent.Jobs.create(job);
            runInAction(() => {
                //this.selectedJob = job;
                this.isEditing = false;
                this.isLoading = false;
                this.loadJobs();
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.isLoading = false;
            });
            throw error;
        }
    };

    updateJob = async (job: Job) => {
        this.isLoading = true;
        try {
            await this.agent.Jobs.update(job);
            runInAction(() => {
                //this.selectedJob = job;
                this.loadJobs();
                this.isEditing = false;
                this.isLoading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.isLoading = false;
            });
            throw error;
        }
    };

    deleteJob = async (id: string) => {
        try {
            await this.agent.Jobs.delete(id);
            runInAction(() => {
                this.loadJobs();
                this.isLoading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.isLoading = false;
            });
        }
    };

    getEmployeeComboOptions = async () => {
        try {
            const empList = await this.agent.AppUsers.list();
            runInAction(() => {
                this.employeeComboOptions = observable(empList);
            });
        } catch (error) {
            console.log(error);
        }
    };

    setJobFilter = (jobName: string | undefined) => {
        runInAction(()=>{
            this.jobFilterName = jobName
        })
    }
}
