import { gql, useMutation, useQuery } from "@apollo/client";
import { Box } from "@mui/material"
import { addMessage } from "../../../../stores/ReactiveVariables";
import { ProjectScheduleForm } from "./ProjectScheduleForm";

interface ProjectScheduleProps{
    id: string;
    getCoverLetter: () => void;
}

const GET_COVER_LETTER_PROJECT_SCHEDULE_QUERY = gql`
    query CoverLetterSchedule($Id: uniqueidentifier = "") {
        BidQuotes_by_pk(Id: $Id) {
            CoverLetterStartDate
            CoverLetterCompletionDate
        }
    }  
`;

const UPDATE_COVER_LETTER_PROJECT_SCHEDULE_QUERY = gql`
    mutation UpdateCoverLetterSchedule($Id: uniqueidentifier = "", $object: BidQuotes_set_input = {CoverLetterStartDate: "", CoverLetterCompletionDate: ""}) {
        update_BidQuotes_by_pk(pk_columns: {Id: $Id}, _set: $object) {
            CoverLetterStartDate
            CoverLetterCompletionDate
        }
    }
`

export const ProjectSchedule: React.FC<ProjectScheduleProps> = (props: ProjectScheduleProps) => {
    const { id, getCoverLetter } = props;

    const { data, loading, error } = useQuery(GET_COVER_LETTER_PROJECT_SCHEDULE_QUERY, { variables: { Id: id } });

    const [updateCoverLetterSchedule] = useMutation(UPDATE_COVER_LETTER_PROJECT_SCHEDULE_QUERY);

    const formData = data?.BidQuotes_by_pk ?? [];

    const onSave = (values: any) => {
        updateCoverLetterSchedule({ 
            variables: { Id: id, object: values },
            onCompleted: () => {
                addMessage('Project Schedule Updated', { severity: 'success' });
                getCoverLetter();
            },
            onError: () => {
                addMessage('Error Updating Project Schedule', { severity: 'error' });
            }
        });
    }

    return(
        <Box sx={{ width: '100%' }}>
            <ProjectScheduleForm id={id} data={formData} onSave={onSave}/>
        </Box>
    )
}