
import { gql } from "@apollo/client/core";

export const GET_JOB_PUNCHES_QUERY = gql`
query JobListData {
    Jobs {
      Punches_aggregate {
        aggregate {
          sum {
            UnitsCompleted
            DistanceCompleted
          }
        }
      }
      Id
      KickoffPacket {
        Id
        BidQuoteId
      }
    }
  }  
`

interface PunchesAggregate {
    sum: {
        DistanceCompleted: number;
        UnitsCompleted: number;
    }
}

export interface Job {
    Id: string;
    ExpectedMiles: number;
    ExpectedUnits: number;
    Punches_aggregate: {
        aggregate: PunchesAggregate;
    };
    KickoffPacket: {
        BidQuoteId: string;
    }
}

export interface JobQuery {
    Jobs: Job[];
}