import { gql, useMutation, useQuery } from "@apollo/client";
import { KickoffPacketSection } from "./KickoffPacketSection";
import { ProjectDetailsForm } from "./ProjectDetailsForm";
import * as uuid from 'uuid';
import LineItemLoading from "../../../molecules/LineItemLoading";

interface ProjectDetailsProps {
    jobId?: string;
    kickoffId?: string;
}

const KICKOFF_PACKET_DETAILS_QUERY = gql`
query GetKickoffPacketProjectDetail($KickoffPacketId: uniqueidentifier = "", $JobId: uniqueidentifier = "") {
    KickoffPacket(where: {Id: {_eq: $KickoffPacketId}}) {
      Id
      BidQuoteId
      BidQuote {
        Id
        CustomerId
        Customer {
          Id
          Name
          CustomerContacts {
            Id
            FirstName
            LastName
          }
        }
        JobNumber
        BidDescription
        TimeAndMaterials
      }
      AFE
      ProjectManagerId
      ProjectMobDate
      ProjectName
      Descriptor
      ProjectKickoffDate
      KickoffLocation
      JobSegments {
        Id
        BidQuoteItem {
            Id
            Segment
            Ordinal
            Type
            Miles
        }
      }
      Customer {
        Id
        Name
      }
      KickoffPacketProjectTypes {
        Id
        KickoffPacketId
        ServiceCategoryId
        ServiceCategory {
          Name
          Id
        }
      }
    }
    Jobs(where: {Id: {_eq: $JobId}}) {
        EndDate
    }
  }
`;


const UPDATE_KICKOFF_PACKET_MUTATION = gql`
mutation UpdateKickoffPacket($Id: uniqueidentifier = "", $object: KickoffPacket_set_input = {}) {
    update_KickoffPacket_by_pk(pk_columns: {Id: $Id}, _set: $object) {
        __typename
        Id
        BidQuoteId
        AFE
        ProjectManagerId
        ProjectMobDate
        ProjectName
        Descriptor
        ProjectKickoffDate
        KickoffLocation
        BidQuote {
            Id
            Customer {
                Id
                Name
                CustomerContacts {
                    Id
                    FirstName
                    LastName
                    Title
                }
            }
        }
    }
}
`;

const ADD_PROJECT_TYPE = gql`
mutation InsertKickoffPacketProjectType($object: KickoffPacketProjectType_insert_input = {}) {
    insert_KickoffPacketProjectType_one(object: $object) {
        __typename
        Id
    }
}
`

const DELETE_PROJECT_TYPE = gql`
mutation DeleteKickoffPacketProjectType($Id: uniqueidentifier = "") {
    delete_KickoffPacketProjectType_by_pk(Id: $Id) {
      Id
      KickoffPacketId
    }
  }
`

const UPDATE_JOB_MUTATION = gql`
mutation UpdateJob($Id: uniqueidentifier = "", $object: Jobs_set_input = {}) {
    update_Jobs(where: {Id: {_eq: $Id}}, _set: $object) {
      affected_rows
    }
  }  
`;

export const ProjectDetails: React.FC<ProjectDetailsProps> = ({ jobId, kickoffId }: ProjectDetailsProps) => {

    const { data: kickoffPacketData, error: kickoffError } = useQuery(KICKOFF_PACKET_DETAILS_QUERY, {
        variables: {
            KickoffPacketId: kickoffId ?? "",
            JobId: jobId ?? ""
        },
        fetchPolicy: 'cache-and-network'
    });

    const [updateKickoffPacket] = useMutation(UPDATE_KICKOFF_PACKET_MUTATION);
    const [updateJob] = useMutation(UPDATE_JOB_MUTATION);

    const [addProjectTypeItem] = useMutation(ADD_PROJECT_TYPE, {
        refetchQueries: [KICKOFF_PACKET_DETAILS_QUERY]
    });

    const [deleteProjectTypeItem] = useMutation(DELETE_PROJECT_TYPE, {
        refetchQueries: [KICKOFF_PACKET_DETAILS_QUERY]
    });

    if (kickoffError) console.log(kickoffError);
    
    const kickoffData = kickoffPacketData?.KickoffPacket[0];
    const bidData = kickoffData?.BidQuote;
    const jobData = kickoffPacketData?.Jobs[0];

    /*
                AFE: kickoffPacketData?.AFE ?? "",
            ProjectMobDate: kickoffPacketData?.ProjectMobDate ? moment.tz(kickoffPacketData?.ProjectMobDate, "UTC") : null,
            ProjectName: kickoffPacketData?.ProjectName ?? "",
            Descriptor: kickoffPacketData?.Descriptor ?? "",
            ProjectKickoffDate: kickoffPacketData?.ProjectKickoffDate ? moment.tz(kickoffPacketData?.ProjectKickoffDate, "UTC") : null,
            KickoffLocation: kickoffPacketData?.KickoffLocation ?? "",
            CustomerId: bidQuoteData?.Customer?.Id?.toLowerCase() ?? null,
            ProjectManagerId: kickoffPacketData?.ProjectManagerId?.toLowerCase() ?? null,
    */

    const onSave = (values: any) => {
        updateKickoffPacket({
            variables: {
                Id: kickoffId,  
                object: {
                    AFE: values.AFE,
                    ProjectMobDate: values.ProjectMobDate,
                    ProjectName: values.ProjectName,
                    Descriptor: values.Descriptor,
                    ProjectKickoffDate: values.ProjectKickoffDate,
                    KickoffLocation: values.KickoffLocation,
                    ProjectManagerId: values.ProjectManagerId,
                }
            }
        });
        updateJob({
            variables: {
                Id: jobId,  
                object: {
                    EndDate: values.ProjectEndDate
                }
            }
        });
    }



    const addProjectType = (id: string) => {
        addProjectTypeItem({
            variables: {
                object: {
                    Id: uuid.v4(),
                    KickoffPacketId: kickoffId,
                    ServiceCategoryId: id
                }
            }
        })
    }

    const deleteProjectType = (id: string) => {
        deleteProjectTypeItem({
            variables: {
                Id: id
            }
        })
    }

    return (
        <KickoffPacketSection heading="Project Details">
            {
                kickoffData && bidData ?
                <ProjectDetailsForm 
                    jobId={jobId}
                    kickoffId={kickoffId} 
                    bidQuoteData={bidData} 
                    kickoffPacketData={kickoffData} 
                    jobData={jobData}
                    onSave={onSave} 
                    addProjectType={addProjectType}
                    deleteProjectType={deleteProjectType}
                />
                :
                <LineItemLoading />
            }
        </KickoffPacketSection>
    )
}