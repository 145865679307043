import React from 'react'
import { Box, Typography } from '@mui/material'
import { CloudUpload } from '@mui/icons-material'
import { Colors } from '../../constants/colors'

export type FileUploadProps = {
    imageButton?: boolean
    accept: string
    hoverLabel?: string
    dropLabel?: string
    width?: string
    height?: string
    backgroundColor?: string
    color?: string
    labelFontSize?: string
    iconFontSize?: string;
    image?: {
        url: string
        imageStyle?: {
            width?: string
            height?: string
        }
    }
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    onDrop: (event: React.DragEvent<HTMLElement>) => void
}

const styles = {
    root: {
        cursor: 'pointer' as any,
        textAlign: 'center' as any,
        display: 'flex',
        '&:hover p,&:hover svg,& img': {
            opacity: 1,
        },
        '& p, svg': {
            opacity: 0.4,
        },
        '&:hover img': {
            opacity: 0.3,
        },
    },
    noMouseEvent: {
        pointerEvents: 'none' as any,
    },
    iconText: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column' as any,
        alignItems: 'center',
        position: 'absolute' as any,
    },
    hidden: {
        display: 'none',
    },
    onDragOver: {
        '& img': {
            opacity: 0.3,
        },
        '& p, svg': {
            opacity: 1,
        },
    },
}

export const FileUpload: React.FC<FileUploadProps> = ({
    accept,
    hoverLabel = 'Click or drag to upload file',
    dropLabel = 'Drop file here',
    width = '600px',
    height = '100px',
    backgroundColor = '#fff',
    color = Colors.deepSeaGreen,
    labelFontSize = "16px",
    iconFontSize = "24px",
    onChange,
    onDrop,
}) => {
    const [labelText, setLabelText] = React.useState<string>(hoverLabel)
    const [isDragOver, setIsDragOver] = React.useState<boolean>(false)
    const [isMouseOver, setIsMouseOver] = React.useState<boolean>(false)
    const stopDefaults = (e: React.DragEvent) => {
        e.stopPropagation()
        e.preventDefault()
    }
    const dragEvents = {
        onMouseEnter: () => {
            setIsMouseOver(true)
        },
        onMouseLeave: () => {
            setIsMouseOver(false)
        },
        onDragEnter: (e: React.DragEvent) => {
            stopDefaults(e)
            setIsDragOver(true)
            setLabelText(dropLabel)
        },
        onDragLeave: (e: React.DragEvent) => {
            stopDefaults(e)
            setIsDragOver(false)
            setLabelText(hoverLabel)
        },
        onDragOver: stopDefaults,
        onDrop: (e: React.DragEvent<HTMLElement>) => {
            stopDefaults(e)
            setLabelText(hoverLabel)
            setIsDragOver(false)
            onDrop(e)
        },
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event)
    }

    const getLabelStyle = () => {
        if (isDragOver) {
            return { ...styles.root, ...styles.onDragOver }
        }
        return styles.root;
    }


    return (
        <>
            <input
                onChange={handleChange}
                accept={accept}
                style={styles.hidden}
                id="file-upload"
                type="file"
                multiple
            />

            <label
                htmlFor="file-upload"
                {...dragEvents}
                style={getLabelStyle()}
            >
                <Box
                    width={width}
                    height={height}
                    bgcolor={backgroundColor}
                    style={styles.noMouseEvent}
                    sx={{
                        borderRadius:"6px",
                    }}
                >
                    <>
                        <Box
                            height={height}
                            width={width}
                            style={styles.iconText}
                        >
                            <CloudUpload fontSize="large" sx={{
                                color: color,
                                fontSize: iconFontSize
                            }}/>
                            <Typography sx={{
                                color: color,
                                fontSize: labelFontSize
                            }}>{labelText}</Typography>
                        </Box>
                    </>
                </Box>
            </label>
        </>
    )
}
