import { gql, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import ExpandIcon from '@mui/icons-material/Expand';
import { ButtonGroup, Grid, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import React, { useState } from 'react';
import { v4 } from 'uuid';
import { GET_MATERIAL_SERVICE_QUERY } from '../../../../api/graphqlqueries/getmaterialservicequery';
import { GET_QUOTE_ITEMS_QUERY } from "../../../../api/graphqlqueries/getquoteitems";
import { GetSurveyItemQueryReturn, GET_SURVEY_ITEM_QUERY } from "../../../../api/graphqlqueries/getsurveyquoteitem";
import { CREATE_MATERIAL_ITEM_MUTATION } from '../../../../api/graphqlqueries/materialserviceitemmutations';
import { DELETE_QUOTE_ITEM_MUTATION, UPDATE_QUOTE_ITEM_MUTATION } from "../../../../api/graphqlqueries/quoteitemmutations";
import { Colors } from '../../../../constants/colors';
import { disableForStatus } from "../../../../stores/ReactiveVariables";
import { MaterialIcon } from '../../../atoms/MaterialIcon';
import { QuoteLineItemBox } from "../../../atoms/QuoteLineItemBox";
import { ServicesIcon } from '../../../atoms/ServicesIcon';
import LineItemLoading from "../../../molecules/LineItemLoading";
import { QuoteMaterialItem } from './BidbookQuoteMaterialItem';
import { BidbookQuoteSurveyItemForm } from './BidbookQuoteSurveyItemForm';
 
interface QuoteSurveyItemProps { 
    type: string;
    bidId: string;
    id?: string;
    onSave?: () => void; 
}

const StyledIconButton = styled(IconButton)({
    backgroundColor: `${Colors.deepSeaGreen}!important`,
    color: "#fff!important",
    padding:"8px!important",
    borderRadius: 0, 
    "&:hover":{
        backgroundColor: "rgb(23, 53, 53)",
        boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)"
    },
    "&:first-of-type": {
        borderTopLeftRadius : "4px",
        borderBottomLeftRadius : "4px"
    },
    "&:last-child": {
        borderTopRightRadius : "4px",
        borderBottomRightRadius : "4px"
    }
});
 
export const QuoteSurveyItem: React.FC<QuoteSurveyItemProps> = (props: QuoteSurveyItemProps) => {
    const disabledForStatus = useReactiveVar(disableForStatus);
    const { id, bidId } = props;
    const [accordionOpen, setAccordionOpen] = useState(true);
    const [addMaterialItem] = useMutation(CREATE_MATERIAL_ITEM_MUTATION);
    const [updateItem] = useMutation(UPDATE_QUOTE_ITEM_MUTATION);
    const [deleteItem] = useMutation(DELETE_QUOTE_ITEM_MUTATION);
    const { data, error } = useQuery<GetSurveyItemQueryReturn>(GET_SURVEY_ITEM_QUERY, {
        variables: {
            Id: id
        },
        fetchPolicy: 'cache-and-network'
    });
    const quoteItem = data?.BidQuoteItems_by_pk;

    const deleteItemHandler = (Id: string) => {
        deleteItem({
            variables: { Id, BidQuoteId: bidId, Ordinal: quoteItem?.Ordinal ?? 0},
            refetchQueries: [GET_QUOTE_ITEMS_QUERY]
        })
    }

    const openAccordion = ()=> {
        setAccordionOpen(!accordionOpen);
    }

    const saveNewMaterialItem = (type: any) => {
        const newItemId = v4();

        const addInfo = {
            Id: newItemId.toUpperCase(),
            Markup: type === 'Material' ? 0.21 : 0, // HCS-528
            BidQuoteItemId: id,
            Type: type,
            Ordinal: data?.BidQuoteItems_by_pk?.BidQuoteMaterials?.length ?? 0,
            LineBreakAfter: false,
            OverrideRate: false,
            AdderHasCost: false
        };

        const addInfoReturn = {
            __typename: "BidQuoteMaterials",
            ...addInfo,
            BidQuoteId: null,
            Description: null,
            Rate: null,
            OverrideRate: false,
            BidQuoteBillableId: null,
            BidQuoteBillable: null,
            Quantity: null,
            Unit: null,
            Duration: null,
            Tax: null,
            GroupWith: null,
            AdderHasCost: null,
            LineBreakAfter: false,
            Ordinal: data?.BidQuoteItems_by_pk?.BidQuoteMaterials?.length ?? 0
        };

        addMaterialItem({
            variables: { object: addInfo },
            //refetchQueries: [GET_SURVEY_ITEM_QUERY]
            optimisticResponse: {
                insert_BidQuoteMaterials_one: addInfoReturn
            },
            update: (cache, { data }) => {
                const newBidQuoteRef = cache.writeFragment({
                    fragment: gql`
                        fragment NewMaterial on BidQuoteMaterials {
                            Id
                            BidQuoteItemId
                            BidQuoteId
                            Description
                            Rate
                            Markup
                            Quantity
                            Unit
                            Type
                            Duration
                            Tax
                            GroupWith
                            Ordinal
                            OverrideRate
                            BidQuoteBillableId
                            BidQuoteBillable
                            AdderHasCost
                            LineBreakAfter
                        }
                    `,
                    data: data.insert_BidQuoteMaterials_one,
                });
                cache.modify({
                    id: cache.identify(quoteItem as any),
                    fields: {
                        BidQuoteMaterials(existingItems)
                        {
                            return [...existingItems, newBidQuoteRef];
                        }
                    }

                });

                cache.writeQuery({
                    query: GET_MATERIAL_SERVICE_QUERY,
                    variables: { Id: data.insert_BidQuoteMaterials_one.Id },
                    data: { BidQuoteMaterials: [data.insert_BidQuoteMaterials_one] }
                });
            },
        });
    }

    const updateItemHandler = (values: any) => {
        updateItem({ 
            variables: { Id: values.Id, Item: values},
        });
    }

    if (error) {
        console.error(error);
    }
    if (!data) return <LineItemLoading />;

    const styles = {
        lineItemGrid : {
            gap:"2px", 
            alignItems:'center', 
            flexWrap:"nowrap!important"
        },
        accordionIconContainer : {
            alignSelf: 'center', 
            cursor:'pointer',
            '& .MuiSvgIcon-root':{
                color:'#fff', 
                backgroundColor:"rgba(0,0,0,0.6)", 
                borderRadius:1, 
                fontSize:"24px" 
            }
        }
    }

    return(
            <QuoteLineItemBox>
                <Grid item container sx={styles.lineItemGrid}>
                    <Grid item sx={{flexGrow:"2"}}>
                        <BidbookQuoteSurveyItemForm item={quoteItem} onSave={updateItemHandler} onDelete={deleteItemHandler} />
                    </Grid>
                    <Grid item>
                        <ButtonGroup sx={{m:"2px"}}>
                            <Tooltip describeChild title="Show/Hide Line Item" placement="top">
                                <StyledIconButton
                                    onClick={()=>openAccordion()}  
                                ><ExpandIcon/></StyledIconButton>
                            </Tooltip>
                            <Tooltip describeChild title="Add Material" placement="top">
                                <StyledIconButton
                                    onClick={()=>saveNewMaterialItem('Material')}  
                                    disabled={disabledForStatus}
                                ><MaterialIcon/></StyledIconButton>
                            </Tooltip>
                            <Tooltip describeChild title="Add Equipment" placement="top">
                                <StyledIconButton
                                    onClick={()=>saveNewMaterialItem('Equipment')}
                                    disabled={disabledForStatus}
                                ><ServicesIcon/></StyledIconButton>
                            </Tooltip>
                        </ButtonGroup>
                    </Grid>
                </Grid> 
                {
                    accordionOpen && quoteItem?.BidQuoteMaterials?.map((bqm: any) => {
                        return <QuoteMaterialItem bidItemId={id} type={bqm.Type} id={bqm.Id} key={bqm.Id}/>
                    })
                }
            </QuoteLineItemBox> 
    )
}
