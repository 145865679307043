import React, { useEffect } from 'react'
import { useStore } from "../../../stores/StoreContext";
import { observer } from "mobx-react-lite";
import CrewForm from "./CrewForm";
import { ContentHeader } from '../ContentHeader';
import { CrewsDataGrid } from './CrewsDataGrid';
import { Box, Dialog, DialogContent } from '@mui/material';

interface Props {
    props?: any;
}

export const CrewPageContent: React.FC<Props> = observer(() => {
    const {
        crewStore: {
            clearNestedAppUserDropdown,
            getUsersAsync,
            setSelectedCrew,
            setIsUpdate
        },
        modalStore: {
            openCrewModal,
            crewModalIsOpen,
            closeCrewModal,
        }
    } = useStore();

    const handleAddCrew = async () => {
        setIsUpdate(false);
        setSelectedCrew(undefined);
        clearNestedAppUserDropdown();
        await getUsersAsync();
        openCrewModal();
    }

    useEffect(() => {
        getUsersAsync();
    });

    return (
        <Box sx={{ height: '100%' }}>
            <ContentHeader
                title="Crews"
                onClick={handleAddCrew}
            />
            <CrewsDataGrid />
            <Dialog
                open={crewModalIsOpen}
                onClose={closeCrewModal}
            >
                <DialogContent>
                    <CrewForm />
                </DialogContent>
            </Dialog>
        </Box>
    )
});
