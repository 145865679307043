import { Box } from "@mui/material";
import { useState, useEffect } from 'react';
import { Colors } from "../../../../constants/colors";
import { HrCircularProgress } from "../../../atoms/HrCircularProgress";
import ErrorIcon from '@mui/icons-material/Error';
import { addMessage } from "../../../../stores/ReactiveVariables";
import axios from "axios";
import HrButton from "../../../atoms/HrButton";
import { KickoffNotificationCrewModal } from "../KickoffNotificationCrewModal";


interface KickoffPacketReportProps {
    id: string;
}


export const KickoffReport: React.FC<KickoffPacketReportProps> = ({ id }) => {
    const [pdfSrc, setPdfSrc] = useState("");
    const [pdfStatus, setPdfStatus] = useState("");
    const [downloadProgress, setDownLoadProgress] = useState(0);
    const [crewModalOpen, setCrewModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (id) {
            axios.get(`/report/${id}/kickoffpacket`, {
                onDownloadProgress: (progressEvent => {
                    const currentProgressValue = (progressEvent.progress ?? 0);
                    setDownLoadProgress(currentProgressValue * 100)
                }),
                headers: {
                    'Content-Type': 'application/pdf'
                },
                responseType: 'blob', // important
            }).then((response) => {

                const data = window.URL.createObjectURL(response.data)
                setPdfSrc(data);
            }).catch((error) => {
                setDownLoadProgress(0);
                addMessage("Error Loading Report", { severity: 'error' });
                setPdfStatus("error");
            })
        }
    }, [setDownLoadProgress, setPdfSrc, setPdfStatus, id]);

    const handleDownload = () => {
        const link = document.createElement("a");
        link.download = `$kickoff.pdf`;
        link.href = pdfSrc;
        link.click();
        link.remove();
    }

    return (
        <Box sx={{ display: "block", position: "relative" }}>
            {!pdfSrc && <PdfStatusDisplay progress={downloadProgress} status={pdfStatus} />}
            {pdfSrc && <HrButton style={{ margin: 10 }} onClick={() => setCrewModalOpen(true)}>Notify Crew Members</HrButton>}
            {pdfSrc && <HrButton style={{ float: 'right', margin: 10 }} onClick={handleDownload}>Download</HrButton>}
            <iframe src={pdfSrc} title={"Kickoff Packet"} width="100%" height="1000" />
            <KickoffNotificationCrewModal
                modalOpen={crewModalOpen}
                closeModal={() => setCrewModalOpen(false)}
                id={id}
            />
        </Box>
    )
}

const PdfStatusDisplay = (props: { status: string, progress: number }) => {
    return (
        <Box sx={{
            paddingTop: "3%",
            textAlign: "center"
        }}>
            {
                props.status === "error"
                    ? <ErrorIcon
                        sx={{
                            fontSize: "42px",
                            color: Colors.errorRed
                        }}
                    />
                    : <HrCircularProgress
                        value={props.progress}
                        size={75}
                    />
            }

        </Box>
    )
} 