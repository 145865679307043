import React from "react";
import { useFormik } from "formik";
import HrTextInput from "../../atoms/HrTextInput";
import HrButton from "../../atoms/HrButton";
import * as yup from "yup";
import { Add } from "@mui/icons-material";
import { Button, Box, Grid } from "@mui/material";
import { useStore } from "../../../stores/StoreContext";
import { observer } from "mobx-react-lite";
import ComboBox from "../../atoms/ComboBox";
import RemoveButton from "../../atoms/RemoveButton";
import { FormGrid } from "../../atoms/FormGrid";
import { StyledHeader } from "../../atoms/StyledHeader";
import { styled } from "@mui/system";

interface Props {
    props?: any;
}

const validationSchema = yup.object({
    crewName: yup.string().required("Crew name is required"),
});

const CrewForm: React.FC<Props> = () => {
    const { crewStore, modalStore, notificationStore, commonStore } = useStore();
    const { setLoading } = commonStore;
    const { displayNotification } = notificationStore;
    const { selectedCrew } = crewStore;
    const formik = useFormik({
        initialValues: {
            name: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values: any) => {
            alert(JSON.stringify(values, null, 2));
        },
    });


    const handleAddDropDown = () => {
        crewStore.addNestedAppUserDropdown();
    };

    const handleAddCrewMember = () => {
        handleAddDropDown();
    };

    const handleSelectCrewMember = (key: any) => (event: any, value: any) => {

        if (value) {
            crewStore.addMemberToAddedCrew(value.id, key).then();
        } else {
            crewStore.addMemberToAddedCrew("", key).then();
        }
    };
    const handleRemoveMember = (key: any) => () => {
        crewStore.removeMemberFromAddedCrew(key).then();
    };
    const handleCrewNameChange = (event: any) => {
        crewStore.handleChangeCrewName(event.target.value);
    };
    const handleAddCrew = async () => {
        setLoading(true)
        if (!crewStore.isUpdate) {
            crewStore.createCrew()
                .then(() => {
                    crewStore.setSelectedCrew(undefined);
                    displayNotification("Crew Created", "success");
                    crewStore.getCrewsAsync().then(() => {
                        // crewStore.setCrewGridRows();
                    });
                }).catch(error => {
                    displayNotification("Error Creating Crew", "error");
                }).finally(() => {
                    modalStore.closeCrewModal();
                    setLoading(false);
                })

        } else {
            crewStore.upDateCrew()
                .then(() => {
                    crewStore.setSelectedCrew(undefined);
                    crewStore.getCrewsAsync();
                    displayNotification("Crew Updated", "success");
                })
                .catch(error => {
                    displayNotification("Error Updating Crew", "error");
                })
                .finally(() => {
                    modalStore.closeCrewModal();
                    setLoading(false);
                })
        }
    }
    return (
        <Box sx={{ width: '100%' }} >
            <StyledHeader sx={{textAlign:"center"}}>Add Crew</StyledHeader>
            <form onSubmit={formik.handleSubmit}>
                <FormGrid>
                    <HrTextInput
                        label="Crew Name"
                        name={"crewName"}
                        id={"crewName"}
                        placeholder="Crew Name"
                        value={selectedCrew?.name}
                        onChange={handleCrewNameChange}
                    />

                    {crewStore.nestedAppUsers.map((item) => (
                        <Grid container key={item.myKey} direction="row" item>
                            <Grid item xs={11}>
                                <ComboBox
                                    label="Crew Member"
                                    options={crewStore.crewDropDownList}
                                    value={item.appUser}
                                    onChange={handleSelectCrewMember(item.myKey)}
                                    fullWidth
                                />

                            </Grid>
                            <Grid item xs={1} sx={{ p: 0.5 }}>
                                <RemoveButton
                                    onClick={handleRemoveMember(item.myKey)}
                                />
                            </Grid>
                        </Grid>
                    ))}
                    <StyledButton onClick={handleAddCrewMember} variant="outlined" color="primary"><Add
                        color="primary" />
                        Add Crew Member
                    </StyledButton>

                    <HrButton
                        title="Submit"
                        onClick={handleAddCrew}
                    />
                </FormGrid>
            </form>
        </Box >
    );
};

const StyledButton = styled(Button)({
    border: '1px solid rgba(33,77,77,1)',
    height: '32px',
    margin: '9px 8px 9px 0'
});

export default observer(CrewForm);
