import { useFormik } from "formik";
import { ChangeEvent, useCallback } from "react";
import { v4 } from 'uuid';
import * as yup from 'yup';
import { BidQuoteItem, getSurveySegmentPricingCalculations, SurveySegmentCalculations } from "../../../../utils/calculations/segments";
import debounce from "../../../../utils/debounce";
import { HrCurrencyInput } from "../../../atoms/HrCurrencyInput";
import { HrPercentInput } from "../../../atoms/HrPercentInput";
import HrTextInput from "../../../atoms/HrTextInput";
import { QuoteLineItemFormGrid } from "../../../atoms/QuoteLineItemFormGrid";
import { PricingTotals } from "../Totals/BidbookQuotePricingTotals";
import { BidQuoteStatus } from "../../../../api/graphqlqueries/getstatus";
import { Colors } from "../../../../constants/colors";
import { BidQuoteRate } from "../../../../api/graphqlqueries/bidquoterates";
import { InputAdornment } from "@mui/material";
import HrCheckBox from "../../../atoms/HrCheckBox";
import { BidQuoteFactors } from "../../../../api/graphqlqueries/quotefactorsquery";

interface QuoteSurveyItem {
    Id?: string;
    Segment?: string;
    Area?: string;
    Miles?: number;
    AverageMiles?: number;
    Mob?: number;
    MobWoPD?: number;
    Setup?: number;
    Boat?: number;
    CrewType?: string,
    CrewQuantity?: number,
    Rate?: number;
    OverrideRate?: boolean;
    BidQuoteRate?: BidQuoteRate | null;
    BidQuoteRateId?: string | null;
    Discount?: number;
    Type?: string;
    BidQuoteMaterials?: any[];
    PerDay?: boolean;
    status?: BidQuoteStatus | null;
}
interface QuoteSurveyItemFormProps {
    item?: QuoteSurveyItem;
    onSave: (item: QuoteSurveyItem) => void
    totals: PricingTotals
    status: string;
    quoteFactors: BidQuoteFactors;
}

const quoteSurveyItemSchema = yup.object().shape({
    Segment: yup.string().required(),
    Area: yup.string(),
    Miles: yup.number(),
    AverageMiles: yup.number(),
    Mob: yup.number(),
    MobWoPD: yup.number(),
    Setup: yup.number(),
    Boat: yup.number(),
    CrewType: yup.string(),
    CrewQuantity: yup.number(),
    Rate: yup.number(),
    Discount: yup.number(),
    PerDay: yup.bool(),
});


export const BidbookQuoteSurveyRateForm = ({ item: quoteItem, onSave, totals, status, quoteFactors }: QuoteSurveyItemFormProps) => {
    const Id = quoteItem?.Id ?? v4();
    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: quoteSurveyItemSchema,
        initialValues: {
            Segment: quoteItem?.Segment ?? '',
            Area: quoteItem?.Area ?? '',
            Miles: quoteItem?.Miles ?? 0,
            AverageMiles: quoteItem?.AverageMiles ?? 0,
            Mob: quoteItem?.Mob ?? 0,
            MobWoPD: quoteItem?.MobWoPD ?? 0,
            Setup: quoteItem?.Setup ?? 0,
            Boat: quoteItem?.Boat ?? 0,
            CrewType: quoteItem?.CrewType ?? '',
            CrewQuantity: quoteItem?.CrewQuantity ?? 0,
            Rate: quoteItem?.Rate ?? 0,
            OverrideRate: quoteItem?.OverrideRate ?? false,
            Discount: quoteItem?.Discount ?? 0,
            PerDay: quoteItem?.PerDay ?? false,
        },
        onSubmit: (values) => {
            const data = quoteSurveyItemSchema.cast(values);
            onSave({ Id, Type: 'Survey', ...data });
            formik.setSubmitting(false);
        }
    });

    const updateData = (data: any) => {
        onSave({ Id, Type: 'Survey', ...data });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (formik.values[e.target.name as keyof typeof formik.values]?.toString() === e.target.value) return;
        try {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value};
            const values = quoteSurveyItemSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.handleChange(e);
    }

    const handlePercentChange = (e: ChangeEvent<HTMLInputElement>) => {
        const cleanNumberString = e.target.value.replace('%', '');
        const numberValue = parseFloat(cleanNumberString);
        if (isNaN(numberValue)) return;
        try {
            const updatedValues = { ...formik.values, [e.target.name]: numberValue / 100 };
            const values = quoteSurveyItemSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.setFieldValue(e.target.name, numberValue / 100);
    }


    const handleOverrideChange = () => {
        if (status === 'Draft' || 'Pending')
        {
        try {
            const updatedValues = { ...formik.values, OverrideRate: !formik.values.OverrideRate};
            const values = quoteSurveyItemSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.setFieldValue('OverrideRate', !formik.values.OverrideRate);
        }
    }

    // eslint-disable-next-line
    const debouncer = useCallback(debounce((values: any) => updateData(values), 1000), []);


    const width = {
        small: "10ch",
        medium: "14ch",
        large: "20ch",
        xl: "26ch",
    }
    const milesNum = Number(formik.values.Miles);

    const segmentCalculations: SurveySegmentCalculations = getSurveySegmentPricingCalculations(quoteItem as BidQuoteItem, milesNum, quoteFactors);
    
    const inputProps = {
        sx: {
            fontSize: "14px"
        }
    }
    return (
        <QuoteLineItemFormGrid>
            <HrTextInput
                name='Segment'
                label='Segment'
                onChange={handleChange}
                value={formik.values.Segment}
                width={width.xl}
                errors={formik.errors.Segment}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                disabled
            />
            <HrTextInput
                name='TotalMiles'
                label='Total Miles'
                value={milesNum}
                width={width.small}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                disabled
            />
            <HrTextInput
                name='TotalDays'
                label='Total Days'
                value={segmentCalculations.TotalDays.toFixed(2)}
                width={width.small}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                disabled
            />
            <HrTextInput
                name='MilesPerDay'
                label='Miles/Day'
                value={segmentCalculations.MilesPerDay.toFixed(2)}
                width={width.small}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                disabled
            />
            <HrCurrencyInput
                name='Rate'
                label='Rate'
                onChange={quoteItem?.BidQuoteRate ? handleChange : undefined}
                value={formik.values.OverrideRate ? formik.values.Rate : quoteItem?.BidQuoteRate?.Rate ?? ''}
                width={width.medium}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={ status === "Draft" ? {
                    endAdornment: 
                    (
                        <InputAdornment position="end">
                            <HrCheckBox
                                name="OverrideRate"
                                checked={formik.values.OverrideRate}
                                onChange={handleOverrideChange}
                                sx={{
                                    m: "0 -8px 0 0",
                                    "& .MuiCheckbox-root": {
                                        p: 0
                                    }
                                }}
                            />
                        </InputAdornment>
                        
                    )}
                    : undefined
                }
                errors={formik.errors.Rate}
                disabled={!formik.values.OverrideRate || (status !== "Draft")}
            />
            <HrCurrencyInput
                name='TotalPrice'
                label='Total Price'
                value={segmentCalculations.TotalPrice.toFixed(2)}
                width={width.medium}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                disabled
            />
            <HrCurrencyInput
                name='PricePerMile'
                label='Price/Mile'
                value={quoteItem?.PerDay ? 'N/A' : segmentCalculations.PricePerMile.toFixed(2)}
                width={width.medium}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                disabled
            />
            <HrPercentInput
                name='Discount'
                label='Discount'
                onChange={handlePercentChange}
                value={formik.values.Discount.toString()}
                width={width.small}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                errors={formik.errors.Discount}
                disabled={status !== "Draft" && status !== "Pending"}
            />
            <HrCurrencyInput
                name='DiscountTotal'
                label='Total (Discounted)'
                value={segmentCalculations?.TotalDiscountPrice?.toFixed(2)}
                width={width.medium}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                sx={{
                    "& .MuiInputBase-root": {
                        backgroundColor: quoteItem?.Discount !== 0 ? `${Colors.discountYellow}!important` : "inherit"
                    }
                }}
                disabled
            />
            <HrCurrencyInput
                name='DiscPricePerMile'
                label='Price/Mile (Discounted)'
                value={quoteItem?.PerDay ? 'N/A' : segmentCalculations?.DiscountPricePerMile.toFixed(2)}
                width={width.medium}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                sx={{
                    "& .MuiInputBase-root": {
                        backgroundColor: quoteItem?.Discount !== 0 ? `${Colors.discountYellow}!important` : "inherit"
                    }
                }}
                disabled
            />
            <HrCurrencyInput
                name='PricePerDay'
                label='Price/Day (Discounted)'
                value={segmentCalculations.DiscountPricePerDay.toFixed(2)}
                width={width.medium}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                sx={{
                    "& .MuiInputBase-root": {
                        backgroundColor: quoteItem?.Discount !== 0 ? `${Colors.discountYellow}!important` : "inherit"
                    }
                }}
                disabled
            />
            <HrCurrencyInput
                name='Adders'
                label='Adders'
                value={segmentCalculations?.TotalAddersPrice?.toFixed(2).toString()}
                width={width.small}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                disabled
            />
            <HrCurrencyInput
                name='AvgPricePerMile'
                label='Avg/Mile'
                value={segmentCalculations.AveragePerMile.toFixed(2)}
                width={width.medium}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                sx={{
                    "& .MuiInputBase-root": {
                        backgroundColor: quoteItem?.Discount !== 0 ? `${Colors.discountYellow}!important` : "inherit"
                    }
                }}
                disabled
            />
        </QuoteLineItemFormGrid>
    );
}