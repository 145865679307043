//SECTION CHECKBOX

import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import * as yup from 'yup';
import { addMessage, resetMessage } from "../../../../../stores/ReactiveVariables";
import { AccordionCheckbox } from "../AccordionCheckbox";

const GET_COVER_LETTER_PROJECT_SCHEDULE_SECTION = gql`
    query GetCoverLetterProjectSchedule($Id: uniqueidentifier = "") {
        BidQuotes_by_pk(Id: $Id) {
            CoverLetterProjectScheduleSection
        }
    }  
`;

const UPDATE_COVER_LETTER_PROJECT_SCHEDULE_SECTION = gql`
    mutation UpdateCoverLetterProjectScheduleSection($Id: uniqueidentifier = "", $CoverLetterProjectScheduleSection: Boolean = false) {
        update_BidQuotes_by_pk(pk_columns: {Id: $Id}, _set: {CoverLetterProjectScheduleSection: $CoverLetterProjectScheduleSection}) {
            __typename
            Id
        }
    }  
`;

interface ProjectScheduleCheckboxProps{
    id: string;
    getCoverLetter: () => void;
}

const validationSchema = yup.object().shape({
    CoverLetterProjectScheduleSection: yup.boolean(),
});

export const ProjectScheduleCheckbox: React.FC<ProjectScheduleCheckboxProps> = ({ id, getCoverLetter }: ProjectScheduleCheckboxProps) => {    
    const { data, loading, error } = useQuery(GET_COVER_LETTER_PROJECT_SCHEDULE_SECTION, { variables: { Id: id } });
    const [updateCoverLetterExecutionPlan] = useMutation(UPDATE_COVER_LETTER_PROJECT_SCHEDULE_SECTION);

    const formData = data?.BidQuotes_by_pk;
    
    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            CoverLetterProjectScheduleSection: formData?.CoverLetterProjectScheduleSection ?? false,
        },
        onSubmit: (values: any) => {
            updateCoverLetterExecutionPlan({
                variables: { Id: id, CoverLetterProjectScheduleSection: values.CoverLetterProjectScheduleSection },
                onCompleted: (data: any) => {
                    resetMessage();
                    const confirmMessage = values.CoverLetterProjectScheduleSection === true ? "Project Schedule Added To Cover Letter" : "Project Schedule Removed From Cover Letter";
                    addMessage(confirmMessage, { severity: 'success' });
                    getCoverLetter();
                },
                onError: () => {
                    addMessage('Error Saving/Removing Project Schedule', { severity: 'error' });
                }
            });
        }
    });
    return (
        <AccordionCheckbox 
            getCoverLetter={getCoverLetter}
            name="CoverLetterProjectScheduleSection"
            checked={formik.values.CoverLetterProjectScheduleSection}
            onCheckboxClick={()=> formik.setFieldValue('CoverLetterProjectScheduleSection', !formik.values.CoverLetterProjectScheduleSection)}
            onSave={formik.submitForm}
            loading={loading}
        />
    )
}
