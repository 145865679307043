export function getDateString(date: Date | undefined | null)
{
  if (!date) return null;
  const dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];

  return dateString;
}

export function getDateTimeString(date: Date | undefined | null)
{
  if (!date) return null;
  const dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ))
                    .toISOString()
  return dateString;
}

export function addDays(date: Date | undefined | null, days: number) {
  if (!date) return null;
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function getDate(dateString: string) {
  if (dateString) {
      const [year, month, day] = dateString.split('-');
      return new Date(Number(year), Number(month) - 1, Number(day));
  }
  return new Date();
}