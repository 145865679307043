import { gql } from "@apollo/client";

export const GET_REPORT_SETTINGS_QUERY = gql`
    query GetReportSettings($Id: uniqueidentifier = "") {
        BidQuotes_by_pk(Id: $Id) {
            Id
            ReportDisplay
            ReportUnits
            ReportIncludeMileage
            ReportIncludePerDiem
            ReportIncludeMobilization
            ReportIncludeMaterials
            ReportIncludeRateSheet
            ReportIncludeDiscount
        }
    }
`
export interface QuoteReportSettings {
    ReportDisplay: string;
    ReportUnits: string;
    ReportIncludeMileage: boolean;
    ReportIncludePerDiem: boolean;
    ReportIncludeMobilization: boolean;
    ReportIncludeMaterials: boolean;
    ReportIncludeRateSheet: boolean;
    ReportIncludeDiscount: boolean;
}

export interface GetQuoteSettingsResult {
    BidQuotes_by_pk: QuoteReportSettings;
}
