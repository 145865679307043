import React from 'react';
import * as yup from 'yup';
import { useFormik } from "formik";
import { v4 } from 'uuid';
import { HighRidgeColorButton } from "../../atoms/HighRidgeColorButton"; 
import { Box, Button, Grid } from "@mui/material";
import HrTextInput from "../../atoms/HrTextInput";

interface CustomerContactItem {
    Id: string;
    FirstName: string;
    LastName: string;
    Email: string;
    Phone: string;
    Title: string;
}
interface CustomerContactFormProps {
    item?: CustomerContactItem;
    onSave: (item: CustomerContactItem) => void;
    onCancel?: () => void;
}


const customerContactItemSchema = yup.object().shape({
    FirstName: yup.string().required(),
    LastName: yup.string().required(),
    Email: yup.string().email().required(),
    Phone: yup.string(),
    Title: yup.string(),
});


export const CustomerContactItemForm: React.FC<CustomerContactFormProps> = ({ item, onSave, onCancel }) => {
    const Id = item?.Id ?? v4();
    const formik = useFormik({
        validationSchema: customerContactItemSchema,
        initialValues: {
            FirstName: item?.FirstName ?? '',
            LastName: item?.LastName ?? '',
            Email: item?.Email ?? '',
            Phone: item?.Phone ?? '',
            Title: item?.Title ?? '',
        },
        onSubmit: (values) => {
            onSave({ Id, ...values});
            formik.setSubmitting(false);
        }
    });
    return (<React.Fragment>
        <Box>
            <HrTextInput
                name='FirstName'
                label='First Name'
                onChange={formik.handleChange}
                value={formik.values.FirstName}
                errors={formik.errors.FirstName}
                fullWidth
            />
        </Box>
        <Box>
            <HrTextInput
                name='LastName'
                label='Last Name'
                onChange={formik.handleChange}
                value={formik.values.LastName}
                errors={formik.errors.LastName}
                fullWidth
            />
        </Box>
        <Box>
            <HrTextInput
                name='Title'
                label='Title'
                onChange={formik.handleChange}
                value={formik.values.Title}
                fullWidth
            />
        </Box>
        <Box>
            <HrTextInput
                name='Email'
                label='Email'
                onChange={formik.handleChange}
                value={formik.values.Email}
                errors={formik.errors.Email}
                fullWidth
            />
        </Box>
        <Box>
            <HrTextInput
                name='Phone'
                label='Phone'
                onChange={formik.handleChange}
                value={formik.values.Phone}
                fullWidth
            />
        </Box>
        <Grid container justifyContent="space-between">
            <HighRidgeColorButton
                type="button"
                onClick={formik.submitForm}
            >Save Item</HighRidgeColorButton>
            {
                onCancel &&
                <Button
                    color="secondary"
                    type="button"
                    onClick={onCancel}
                >
                    Cancel
                </Button>

            }
        </Grid>
    </React.Fragment>);
}