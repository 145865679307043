import React from 'react';
import * as yup from 'yup';
import { useFormik } from "formik";
import { HighRidgeColorButton } from "../../../atoms/HighRidgeColorButton";
import { Autocomplete, Box, Button, Grid } from "@mui/material";
import HrTextInput from "../../../atoms/HrTextInput";
import { gql, useQuery, useLazyQuery } from '@apollo/client';

interface UserRolesItem {
    UsersId: string;
    RolesId: string;
}

interface UserRolesFormProps {
    onSave: (item: UserRolesItem) => void;
    onCancel?: () => void;
}


const UserRolesItemSchema = yup.object().shape({
    UsersId: yup.string().required(),
    RolesId: yup.string().required()
});

const USER_QUERY = gql`
query UsersQuery {
    AspNetUsers {
        Id
        FirstName
        LastName
    }
}`

const ROLE_QUERY = gql`
query RolesQuery {
    HighridgeRoles(where: {AppSection: {_eq: 7}}) {
        Id
        Name
    }
}`

const USER_ROLES_QUERY = gql`
query UserRolesQuery($RolesId: uniqueidentifier = "", $UserId: String = "") {
    AppUserRole(where: {RolesId: {_eq: $RolesId}, UsersId: {_eq: $UserId}}) {
        RolesId
        UsersId
    }
}  
`;

export const UserRolesForm: React.FC<UserRolesFormProps> = ({ onSave, onCancel }) => {
    const { data: userData, error: userError, loading: userLoading } = useQuery(USER_QUERY);
    const { data: roleData, error: roleError, loading: roleLoading } = useQuery(ROLE_QUERY);
    const [checkUserRole] = useLazyQuery(USER_ROLES_QUERY);

    const formik = useFormik({
        validationSchema: UserRolesItemSchema,
        initialValues: {
            UsersId: '',
            RolesId: ''
        },
        onSubmit: (values) => {
            checkUserRole({ 
                variables: { 
                    RolesId: values.RolesId, UserId: values.UsersId 
                },
                onCompleted: (data) => {
                    if(data?.AppUserRole?.length > 0){
                        formik.setErrors({ RolesId: 'User already has this role' });
                    } else {
                        onSave({ UsersId: values.UsersId, RolesId: values.RolesId })
                        formik.setSubmitting(false);
                    }
                }
            });
        },
    });

    const handleUserChange = (event: any, value: any) => {
        formik.setFieldValue('UsersId', value?.Id ?? null);
    };

    const handleRoleChange = (event: any, value: any) => {
        formik.setFieldValue('RolesId', value?.Id ?? null);
    };

    return (<React.Fragment>
        <Box>
            <Autocomplete
                id="user"
                options={userData?.AspNetUsers ?? []}
                value={userData?.AspNetUsers?.find((user: any) =>  user.Id === formik.values.UsersId)}
                getOptionLabel={(option: any) => option ? option?.FirstName + ' ' + option?.LastName : "" }
                onChange={handleUserChange}
                fullWidth
                renderInput={(params) => (
                    <HrTextInput {...params} label="User" errors={formik.errors.UsersId} />
                )}
            />
        </Box>
        <Box>
            <Autocomplete
                id="role"
                options={roleData?.HighridgeRoles ?? []}
                value={roleData?.HighridgeRoles?.find((role: any) =>  role.Id === formik.values.RolesId)}
                getOptionLabel={(option: any) => option?.Name ?? '' }
                onChange={handleRoleChange}
                fullWidth
                renderInput={(params) => (
                    <HrTextInput {...params} label="Role" errors={formik.errors.RolesId} />
                )}
            />
        </Box>
        <Grid container justifyContent="space-between">
            <HighRidgeColorButton
                type="button"
                onClick={formik.submitForm}
            >Save Item</HighRidgeColorButton>
            {
                onCancel &&
                <Button
                    color="secondary"
                    type="button"
                    onClick={onCancel}
                >
                    Cancel
                </Button>

            }
        </Grid>
    </React.Fragment>);
}