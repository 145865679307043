//CoverLetterAccordion

import { useState } from "react";
import { CoverLetterAccordionPanel } from "./CoverLetterAccordionPanel";
import { ExecutionPlan } from "./ExecutionPlan";
import { ExecutionPlanCheckbox } from "./Checkboxes/ExecutionPlanCheckbox";
import { FrontPage } from "./FrontPage";
import { FrontPageCheckbox } from "./Checkboxes/FrontPageCheckbox";
import { ProjectBreakdown } from "./ProjectBreakdown";
import { ProjectBreakdownCheckbox } from "./Checkboxes/ProjectBreakdownCheckbox";
import { ProjectDescription } from "./ProjectDescription";
import { PaymentTerms } from "./PaymentTerms";
import { ProjectDescriptionCheckbox } from "./Checkboxes/ProjectDescriptionCheckbox";
import { ProjectSchedule } from "./ProjectSchedule";
import { ProjectScheduleCheckbox } from "./Checkboxes/ProjectScheduleCheckbox";
import { Purpose } from "./Purpose";
import { PurposeCheckbox } from "./Checkboxes/PurposeCheckbox";
import { ReportingPlanCheckbox } from "./Checkboxes/ReportingPlanCheckbox";
import { PaymentTermsCheckbox } from "./Checkboxes/PaymentTermsCheckbox";
import { ProjectAssumptionsCheckbox } from "./Checkboxes/ProjectAssumptionsCheckbox";

interface CoverLetterAccordionProps {
    id: string;
    getCoverLetter: () => void;
}

export const CoverLetterAccordion = ({ id, getCoverLetter }: CoverLetterAccordionProps) => {
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };
    return (
        <>
            <CoverLetterAccordionPanel
                panel="panel1"
                activePanel={expanded}
                form={<FrontPage id={id} getCoverLetter={getCoverLetter} />}
                panelLabel="Cover Page"
                handleChange={handleChange}
                checkbox={<FrontPageCheckbox id={id} getCoverLetter={getCoverLetter} />}
            />
            <CoverLetterAccordionPanel
                panel="panel2"
                activePanel={expanded}
                form={<Purpose id={id} getCoverLetter={getCoverLetter} />}
                panelLabel="Purpose"
                handleChange={handleChange}
                checkbox={<PurposeCheckbox id={id} getCoverLetter={getCoverLetter} />}
            />
            <CoverLetterAccordionPanel
                panel="panel3"
                activePanel={expanded}
                form={<ProjectDescription id={id} getCoverLetter={getCoverLetter} />}
                panelLabel="Project Description"
                handleChange={handleChange}
                checkbox={<ProjectDescriptionCheckbox id={id} getCoverLetter={getCoverLetter} />}
            />
            <CoverLetterAccordionPanel
                panel="panel4"
                activePanel={expanded}
                form={<ExecutionPlan id={id} getCoverLetter={getCoverLetter} />}
                panelLabel="Execution Plan"
                handleChange={handleChange}
                checkbox={<ExecutionPlanCheckbox id={id} getCoverLetter={getCoverLetter} />}
            />
            <CoverLetterAccordionPanel
                panel="panel5"
                activePanel={expanded}
                form={<ProjectBreakdown id={id} getCoverLetter={getCoverLetter} />}
                panelLabel="Project Breakdown"
                handleChange={handleChange}
                checkbox={<ProjectBreakdownCheckbox id={id} getCoverLetter={getCoverLetter} />}
            />
            <CoverLetterAccordionPanel
                panel="panel6"
                activePanel={expanded}
                panelLabel="Reporting Plan"
                handleChange={handleChange}
                checkbox={<ReportingPlanCheckbox id={id} getCoverLetter={getCoverLetter} />}
            />
            <CoverLetterAccordionPanel
                panel="panel7"
                activePanel={expanded}
                form={<ProjectSchedule id={id} getCoverLetter={getCoverLetter} />}
                panelLabel="Project Schedule"
                handleChange={handleChange}
                checkbox={<ProjectScheduleCheckbox id={id} getCoverLetter={getCoverLetter} />}
            />
            <CoverLetterAccordionPanel
                panel="panel8"
                activePanel={expanded}
                panelLabel="Payment Terms"
                form={<PaymentTerms id={id} getCoverLetter={getCoverLetter} />}
                handleChange={handleChange}
                checkbox={<PaymentTermsCheckbox id={id} getCoverLetter={getCoverLetter} />}
            />
            <CoverLetterAccordionPanel
                panel="panel9"
                activePanel={expanded}
                panelLabel="Project Assumptions"
                handleChange={handleChange}
                checkbox={<ProjectAssumptionsCheckbox id={id} getCoverLetter={getCoverLetter} />}
            />
        </>
    )
}
