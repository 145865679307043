import { gql } from '@apollo/client';

export const UPDATE_BID_QUOTE_INSTRUCTIONS_MUTATION = gql`
  mutation UpdateBidQuoteInstructions($Id: uniqueidentifier = "", $Quote: BidQuotes_set_input = {}) {
    update_BidQuotes_by_pk(pk_columns: {Id: $Id}, _set: $Quote) {
      __typename
      SpecialInstructions
      SurveyComments
      TechnicianComments
      ConstructionComments
      InspectionComments
      Id
    }
  }
`;