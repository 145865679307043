import { gql } from "@apollo/client";

export interface GetDefaultTermsQueryResult {
  DefaultTerms: DefaultTerm[];
}

interface DefaultTerm {
  Id: string;
  Name: string;
  Term: string;
}

export const GET_DEFAULT_TERMS_QUERY = gql`
  query GetDefaultTerms {
    DefaultTerms {
      Id
      Name
      Term
    }
  }
`;

export const DELETE_DEFAULT_TERM_MUTATION = gql`
mutation DeleteDefaultTerm($Id: uniqueidentifier = "") {
  delete_DefaultTerms_by_pk(Id: $Id) {
    Id
  }
}
`;

export const CREATE_DEFAULT_TERM_MUTATION = gql`
mutation CreateDefaultTerm($object: DefaultTerms_insert_input = {}) {
  insert_DefaultTerms_one(object: $object) {
    __typename
    Id
    Name
    Term
  }
}
`;

export const UPDATE_DEFAULT_TERM_MUTATION = gql`
  mutation UpdateDefaultTerm($Id: uniqueidentifier = "", $object: DefaultTerms_set_input = {}) {
    update_DefaultTerms_by_pk(pk_columns: {Id: $Id}, _set: $object) {
      __typename
      Id
      Name
      Term
    }
  }
`;