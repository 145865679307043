import { gql } from "@apollo/client/core";
import { BidQuoteRate } from "./getquotereportquery";

export interface GetConstructionServiceQueryResult {
  BidQuoteConstructionServices_by_pk: BidQuoteConstructionService;
}

interface BidQuoteConstructionService {
  Average: number;
  BidQuoteItemId: string;
  BidQuoteRate: BidQuoteRate;
  BidQuoteRateId: string;
  Days: number;
  Discount: number;
  Equipment: number;
  Id: string;
  Locations: number;
  Mileage: number;
  Mob: number;
  MobWoPD: number;
  PerDiem: boolean;
  Rate: number;
  OverrideRate: boolean;
  Personnel: number;
  Rest: number;
  Setup: number;
  Unit: string;
  Ordinal: number;
  LineBreakAfter: boolean;
}

export const GET_CONSTRUCTION_SERVICE_QUERY = gql`
query GetConstructionService($Id: uniqueidentifier = "") {
  BidQuoteConstructionServices_by_pk(Id: $Id) {
    Average
    BidQuoteItemId
    BidQuoteRate {
      Id
      Name
      Rate
      ServiceType
    }
    BidQuoteRateId
    Days
    Discount
    Equipment
    Id
    Locations
    Mileage
    Mob
    MobWoPD
    PerDiem
    Rate
    OverrideRate
    Personnel
    Rest
    Setup
    Unit
    Ordinal
    LineBreakAfter
  }
}
`;


export const CREATE_CONSTRUCTION_SERVICE_MUTATION = gql`
mutation CreateConstructionService($object: BidQuoteConstructionServices_insert_input = {}) {
  insert_BidQuoteConstructionServices_one(object: $object) {
    __typename
    Average
    BidQuoteItemId
    BidQuoteRate {
      Id
      Name
      Rate
    }
    BidQuoteRateId
    Days
    Discount
    Equipment
    Locations
    Id
    Mileage
    Mob
    MobWoPD
    PerDiem
    Personnel
    Rate
    OverrideRate
    Rest
    Setup
    Unit
    Ordinal
    LineBreakAfter
  }
}
`;

export const UPDATE_CONSTRUCTION_SERVICE_MUTATION = gql`
mutation UpdateConstructionService($object: BidQuoteConstructionServices_set_input = {}, $Id: uniqueidentifier = "") {
  update_BidQuoteConstructionServices_by_pk(pk_columns: {Id: $Id}, _set: $object) {
    __typename
    Average
    Days
    BidQuoteItemId
    BidQuoteRate {
      Id
      Name
      Rate
    }
    BidQuoteRateId
    Discount
    Equipment
    Locations
    Id
    Mileage
    Mob
    MobWoPD
    PerDiem
    Personnel
    Rate
    OverrideRate
    Rest
    Setup
    Unit
    Ordinal
    LineBreakAfter
  }
}
`;

export const DELETE_CONSTRUCTION_SERVICE_MUTATION = gql`
mutation DeleteConstructionService($Id: uniqueidentifier = "", $BidQuoteItemId: uniqueidentifier = "", $Ordinal: Int = 0) {
  delete_BidQuoteConstructionServices_by_pk(Id: $Id) {
    Id
  }
  update_BidQuoteConstructionServices(where: {BidQuoteItemId: {_eq: $BidQuoteItemId}, Ordinal: {_gt: $Ordinal}}, _inc: {Ordinal: -1}) {
    returning {
      Id
      Ordinal
    }
  }
}
`;