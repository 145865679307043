import { Box, Grid } from "@mui/material";
import dollarUS from "../../../../utils/CurrencyFormat";
import percentFormat from "../../../../utils/PercentFormat";
import { StyledHeader } from "../../../atoms/StyledHeader";
import { RateHeading } from "../../../molecules/BidbookRateHeading";
import { GetQuoteAddersTotals } from "../Totals/BidbookQuoteAddersTotals";
import { GetQuoteItemTotals } from "../Totals/BidbookQuoteItemTotals";
import { GetQuotePricingTotals } from "../Totals/BidbookQuotePricingTotals";
import { QuoteAddersRates } from "./BidbookQuoteAddersRates";
import { QuoteRates } from "./BidbookQuoteRates";
import { QuoteSurveyRates } from "./BidbookQuoteSurveyRates";
import { GetQuoteSurveyTotals } from "../Totals/BidbookQuoteSurveyTotals";
import { Colors } from "../../../../constants/colors";


interface QuotePricingProps {
    id: string,
}

export const QuotePricingItems = ({ id }: QuotePricingProps) => {
    const lineItemTypes = ["Survey", "SurveyAdders", "Construction", "ConstructionAdders", "Technician", "TechnicianAdders", "Inspection", "InspectionAdders", "Adders", "Lump Sum", undefined];

    const surveyAdders = GetQuoteAddersTotals(id, "Survey");
    const constructionAdders = GetQuoteAddersTotals(id, "Construction");
    const technicianAdders = GetQuoteAddersTotals(id, "Technician");
    const inspectionAdders = GetQuoteAddersTotals(id, "Inspection");
    const lumpSumAdders = GetQuoteAddersTotals(id, "Lump Sum");
    const ungroupedAdders = GetQuoteAddersTotals(id);

    const surveyTotals = GetQuoteSurveyTotals(id);
    const constructionTotals = GetQuoteItemTotals(id, "Construction");
    const technicianTotals = GetQuoteItemTotals(id, "Technician");
    const inspectionTotals = GetQuoteItemTotals(id, "Inspection");


    const totals = {
        survey: surveyTotals?.GrandTotal ?? 0,
        construction: constructionTotals.TotalDiscountPrice ?? 0,
        technician: technicianTotals.TotalDiscountPrice ?? 0,
        inspection: inspectionTotals.TotalDiscountPrice ?? 0
    };

    const totalsWoMaterials = {
        survey: surveyTotals?.TotalPriceWoMaterials ?? 0,
        construction: constructionTotals.TotalPriceWoMaterials ?? 0,
        technician: technicianTotals.TotalPriceWoMaterials ?? 0,
        inspection: inspectionTotals.TotalPriceWoMaterials ?? 0

    };

    const addersTotalsByType = {
        survey: surveyAdders?.markupTotalWithTax ?? 0,
        construction: constructionAdders?.markupTotalWithTax ?? 0,
        technician: technicianAdders?.markupTotalWithTax ?? 0,
        inspection: inspectionAdders?.markupTotalWithTax ?? 0,
        ungrouped: ungroupedAdders?.markupTotalWithTax ?? 0,
        lumpsum: lumpSumAdders?.markupTotalWithTax ?? 0
    };

    const costs = {
        survey: GetQuoteSurveyTotals(id)?.TotalCost ?? 0,
        construction: constructionTotals?.TotalCost ?? 0,
        technician: technicianTotals?.TotalCost ?? 0,
        inspection: inspectionTotals?.TotalCost ?? 0
    };

    const costsWoMaterials = {
        survey: GetQuoteSurveyTotals(id)?.TotalCostWoMaterials ?? 0,
        construction: constructionTotals?.TotalCostWoMaterials ?? 0,
        technician: technicianTotals?.TotalCostWoMaterials ?? 0,
        inspection: inspectionTotals?.TotalCostWoMaterials ?? 0
    }

    const addersCostByType = {
        survey: surveyAdders?.cost ?? 0,
        construction: constructionAdders?.cost ?? 0,
        technician: technicianAdders?.cost ?? 0,
        inspection: inspectionAdders?.cost ?? 0,
        lumpsum: lumpSumAdders?.cost ?? 0,
        ungrouped: ungroupedAdders?.cost ?? 0
    };

    const getTotalsKey = (type: string | undefined) => {
        switch (type) {
            case 'Survey':
                return 'survey';
            case 'Construction':
                return 'construction';
            case 'Inspection':
                return 'inspection';
            case 'Technician':
                return 'technician';
            case 'Lump Sum':
                return 'lumpsum';
            case 'Quote':
            case '':
            default:
                return 'ungrouped';
        }
    }
    
    const addersCost = addersCostByType.survey + addersCostByType.construction + addersCostByType.technician + addersCostByType.inspection + addersCostByType.ungrouped + addersCostByType.lumpsum;
    const totalCost = (costs.survey ?? 0) + (costs.construction ?? 0) + (costs.technician ?? 0) + (costs.inspection ?? 0) + addersCost;
    const addersTotal = addersTotalsByType.survey + addersTotalsByType.construction + addersTotalsByType.technician + addersTotalsByType.inspection + addersTotalsByType.ungrouped + addersTotalsByType.lumpsum;
    const bidQuoteTotal = totals.survey + totals.construction + totals.technician + totals.inspection + addersTotal;
    const bidQuoteTotalWoMaterials = totalsWoMaterials.survey + totalsWoMaterials.construction + totalsWoMaterials.technician + totalsWoMaterials.inspection;
    const totalCostWoMaterials = (costsWoMaterials.survey ?? 0) + (costsWoMaterials.construction ?? 0) + (costsWoMaterials.technician ?? 0) + (costsWoMaterials.inspection ?? 0);

    const pricingItem = (type: string, bidQuoteId: string) => {
        switch (type) {
            case 'Survey':
                return <QuoteSurveyRates key={type} bidQuoteId={bidQuoteId} total={totals.survey} />
            case 'SurveyAdders':
                return <QuoteAddersRates bidQuoteId={bidQuoteId} total={addersTotalsByType.survey} type="Survey" />
            case 'Construction':
                return <QuoteRates key={type} bidQuoteId={bidQuoteId} type={type} total={totals.construction} />;
            case 'ConstructionAdders':
                return <QuoteAddersRates bidQuoteId={bidQuoteId} total={addersTotalsByType.construction} type="Construction" />
            case 'Technician':
                return <QuoteRates key={type} bidQuoteId={bidQuoteId} type={type}  total={totals.technician} />;
            case 'TechnicianAdders':
                return <QuoteAddersRates bidQuoteId={bidQuoteId} total={addersTotalsByType.technician} type="Technician"  />
            case 'Inspection':
                return <QuoteRates key={type} bidQuoteId={bidQuoteId} type={type} total={totals.inspection} />;
            case 'InspectionAdders':
                return <QuoteAddersRates bidQuoteId={bidQuoteId} total={addersTotalsByType.inspection} type="Inspection" />    
            case 'Adders':
            case 'Lump Sum':
            case '':
            default:
                return <QuoteAddersRates key={type ?? 'Quote'} type={type} bidQuoteId={bidQuoteId} total={addersTotalsByType[getTotalsKey(type)]} />
        }
    }
    const expectedProfitMargin = (bidQuoteTotal - totalCost) / bidQuoteTotal;
    const expectedProfitMarginWoMaterials = (bidQuoteTotalWoMaterials - totalCostWoMaterials) / bidQuoteTotalWoMaterials;
    return (
        <Box>
            {lineItemTypes.map((type) => {
                return pricingItem(type!, id)
            })}
            <RateHeading>
                <Grid item xs={6}>
                    <StyledHeader sx={{margin:0}}>Total Bid Pricing</StyledHeader>
                </Grid>
                <Grid item xs={6}>
                    <StyledHeader sx={{margin:0, textAlign:"right"}}>{dollarUS.format(bidQuoteTotal)}</StyledHeader>
                </Grid>
                <Grid item xs={6}>
                    <StyledHeader sx={{margin:0}}>Expected Profit Margin</StyledHeader>
                </Grid>
                <Grid item xs={6}>
                    <StyledHeader 
                        sx={{
                            margin:0, 
                            textAlign:"right",
                            ...(expectedProfitMargin < .45 && {
                                color: `${Colors.lightWarningRed}!important`
                            }),
                            ...(expectedProfitMargin >= .45 && expectedProfitMargin < .5 && {
                                color: `${Colors.gold}!important`
                            }),
                            ...(expectedProfitMargin >= .5 && {
                                color: `${Colors.lightSuccessGreen}!important`
                            })
                        }}>
                        {percentFormat.format(expectedProfitMargin)}
                    </StyledHeader>
                </Grid>
                { expectedProfitMargin !== expectedProfitMarginWoMaterials &&
                <><Grid item xs={6}>
                        <StyledHeader sx={{ margin: 0 }}>Profit Margin W/O Materials</StyledHeader>
                    </Grid><Grid item xs={6}>
                            <StyledHeader
                                sx={{
                                    margin: 0,
                                    textAlign: "right",
                                    ...(expectedProfitMarginWoMaterials < .45 && {
                                        color: `${Colors.lightWarningRed}!important`
                                    }),
                                    ...(expectedProfitMarginWoMaterials >= .45 && expectedProfitMarginWoMaterials < .5 && {
                                        color: `${Colors.gold}!important`
                                    }),
                                    ...(expectedProfitMarginWoMaterials >= .5 && {
                                        color: `${Colors.lightSuccessGreen}!important`
                                    })
                                }}>
                                {percentFormat.format(expectedProfitMarginWoMaterials)}
                            </StyledHeader>
                        </Grid></>
                }
            </RateHeading>
        </Box>
    )
}