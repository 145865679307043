import { gql, useLazyQuery, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { PersonAdd } from '@mui/icons-material';
import { Autocomplete, Box, Button, Dialog, DialogContent, Grid, IconButton, InputAdornment, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { CompanyListQueryResult, COMPANY_LIST_QUERY } from '../../../api/graphqlqueries/companylistquery';
import { getVariablesEdit, UPDATE_BID_QUOTE_MUTATION } from '../../../api/graphqlqueries/createquotemutation';
import { GetBidbookUsersQueryResult, GET_BIDBOOK_USERS_QUERY } from '../../../api/graphqlqueries/getbidbookusersquery';
import { GetClientRepsQueryResult, CustomerContact, GET_CLIENT_REPS_QUERY } from '../../../api/graphqlqueries/getclientrepsquery';
import { Colors } from '../../../constants/colors';
import { addMessage, appUserVar, disableForStatus } from "../../../stores/ReactiveVariables";
import { FormGrid } from '../../atoms/FormGrid';
import HrButton from '../../atoms/HrButton';
import HrCheckBox from '../../atoms/HrCheckBox';
import { HrCurrencyInput } from '../../atoms/HrCurrencyInput';
import HrTextInput from '../../atoms/HrTextInput';
import { StyledHeader } from '../../atoms/StyledHeader';
import { CustomerContactItem } from '../Clients/CustomerContactItem';
import { stateList } from "../../../utils/stateList";
import axios from "axios";
import { HrSelect } from "../../atoms/HrSelect";

const validationSchema = yup.object().shape({
    CustomerId: yup.string().required('Client Name Required'),
    ClientScopeOfWorkReviewed: yup.boolean(),
    CustomerRepId: yup.string(),
    JobName: yup.string(),
    JobCity: yup.string(),
    JobState: yup.string(),
    Zip: yup.string(),
    BidDescription: yup.string().required('Job Name Required'),
    ReceivedDate: yup.date(),
    DocReceivedDate: yup.date().nullable(),
    DueDate: yup.date(),
    CoverLetter: yup.bool(),
    Budgetary: yup.bool(),
    KmzReview: yup.bool(),
    TimeAndMaterials: yup.bool(),
    GpsPointsReview: yup.bool(),
    ProjectAreaReview: yup.bool(),
    SpecialEquipmentNeeded: yup.bool(),
    BusinessLeadId: yup.string().required('Business Lead Required'),
    AnalystId: yup.string(),
    BidBookId: yup.string(),
    FieldHours: yup.number(),
    SurveyFieldHours: yup.number(),
    MileageRate: yup.number(),
    OfficeHours: yup.number(),
    PerDiemRate: yup.number(),
    DaysValidAfterSubmit: yup.number(),
    ReviewedByDirectorOfOperations: yup.bool(),
    ReviewedByVPOfOperations: yup.bool(),
    ProjectManagerUserId: yup.string().nullable(),
    SurveyMileage: yup.number()
});

interface IBidQuote {
    Id: string;
    ClientScopeOfWorkReviewed: boolean;
    CustomerId: string;
    CustomerContactId: string;
    JobCity: string;
    JobState: string;
    Zip: string;
    BidDescription: string;
    ReceivedDate: string;
    DocumentReceivedDate: string;
    DueDate: string;
    CoverLetter: boolean;
    Budgetary: boolean;
    KmzReview: boolean;
    TimeAndMaterials: boolean;
    GpsPointsReview: boolean;
    ProjectAreaReview: boolean;
    SpecialEquipmentNeeded: boolean;
    BusinessLeadUserId: string;
    BidBookUserId: string;
    BidQuoteAnalystUserId: string;
    FieldHours: number;
    SurveyFieldHours: number;
    MileageRate: number;
    OfficeHours: number;
    PerDiemRate: number;
    PreparedByUserId: string;
    PreparedByTitle: string;
    DaysValidAfterSubmit: number;
    ReviewedByDirectorOfOperations: boolean;
    ReviewedByVPOfOperations: boolean;
    ProjectManagerUserId?: string;
    ExpectedYear: number;
    ExpectedQuarter: string;
    SurveyMileage: number;
}
interface IEditQuoteProps {
    quote: IBidQuote;
}

const UPDATE_RATE_HOURS_MUTATION = gql`
  mutation UpdateRateHours($BidQuoteId: String = "", $NewHours: Int = 10) {
    UpdateHours(BidQuoteId: $BidQuoteId, NewHours: $NewHours)
  }
`;

const GET_BIDBOOK_STATUS_QUERY = gql`
query BidQuoteStatus($Id: uniqueidentifier = "") {
  BidQuoteStatus(where: {BidQuoteId: {_eq: $Id}}, limit: 1, order_by: {CreateDate: desc}) {
      Id
      Status
  }
}
`

export const EditQuoteForm: React.FC<IEditQuoteProps> = ({ quote }) => {
    const disabledForStatus = useReactiveVar(disableForStatus);
    const user = useReactiveVar(appUserVar);
    const { data: companyData } = useQuery<CompanyListQueryResult>(COMPANY_LIST_QUERY, {
        fetchPolicy: 'cache-and-network'
    });
    const { data: userData } = useQuery<GetBidbookUsersQueryResult>(GET_BIDBOOK_USERS_QUERY);
    const [getClientRep, { data: clientRepData }] = useLazyQuery<GetClientRepsQueryResult>(GET_CLIENT_REPS_QUERY, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            setCustomerContact(data?.CustomerContacts?.find((cr: any) => cr.Id === formik.values.CustomerRepId) ?? null);
        }
    });

    const { data: statusData } = useQuery(GET_BIDBOOK_STATUS_QUERY, {
        variables: {
            Id: quote.Id
        }
    });

    const status = statusData?.BidQuoteStatus[0]?.Status ?? null;

    const [modalOpen, setModalOpen] = useState(false);
    const [yearOptions, setYearOptions] = useState<Array<number>>([]);
    const [editQuote, { loading: saveLoading }] = useMutation(UPDATE_BID_QUOTE_MUTATION);
    const [updateRateHours, { loading: updateLoading }] = useMutation(UPDATE_RATE_HOURS_MUTATION);
    const closeModal = () => setModalOpen(false);
    const closeModalAndRefreshList = (values: any) => {
        setModalOpen(false);
        values.Id = values?.Id.toUpperCase();
        formik.setFieldValue("CustomerRepId", values?.Id ?? '');
        setCustomerContact(values);
    }

    useEffect(() => {
        if (quote?.CustomerId) {
            getClientRep({
                variables: {
                    CustomerId: quote.CustomerId,
                }
            });
        }
    }, [quote, getClientRep]);

    useEffect(() => {
        const year = new Date().getFullYear();
        const years = [];
        for (let i = year; i < year + 10; i++) {
            years.push(i);
        }
        setYearOptions(years);
    }, []);

    const getDate = (dateString: string) => {
        if (dateString) {
            const [year, month, day] = dateString.split('-');
            return new Date(Number(year), Number(month) - 1, Number(day));
        }
        return new Date();
    }
    const formik = useFormik({
        validationSchema: validationSchema,
        enableReinitialize: true,
        initialValues: {
            ClientScopeOfWorkReviewed: quote?.ClientScopeOfWorkReviewed ?? false,
            CustomerId: quote?.CustomerId ?? '',
            CustomerRepId: quote?.CustomerContactId ?? '',
            JobCity: quote?.JobCity ?? '',
            JobState: quote?.JobState ?? '',
            BidDescription: quote?.BidDescription ?? '',
            ReceivedDate: getDate(quote?.ReceivedDate),
            DocReceivedDate: quote?.DocumentReceivedDate ? getDate(quote?.DocumentReceivedDate) : null,
            DueDate: getDate(quote?.DueDate),
            CoverLetter: quote?.CoverLetter ?? false,
            Budgetary: quote?.Budgetary ?? false,
            KmzReview: quote?.KmzReview ?? false,
            TimeAndMaterials: quote?.TimeAndMaterials ?? false,
            GpsPointsReview: quote?.GpsPointsReview ?? false,
            ProjectAreaReview: quote?.ProjectAreaReview ?? false,
            SpecialEquipmentNeeded: quote?.SpecialEquipmentNeeded ?? false,
            BusinessLeadId: quote?.BusinessLeadUserId ?? '',
            AnalystId: quote?.BidQuoteAnalystUserId ?? '',
            BidBookId: quote?.BidBookUserId ?? '',
            FieldHours: quote?.FieldHours ?? 0,
            SurveyFieldHours: quote?.SurveyFieldHours ?? 0,
            MileageRate: quote?.MileageRate ?? 0,
            OfficeHours: quote?.OfficeHours ?? 0,
            PerDiemRate: quote?.PerDiemRate ?? 0,
            PreparedByUserId: quote?.PreparedByUserId ?? '',
            PreparedByTitle: quote?.PreparedByTitle ?? '',
            DaysValidAfterSubmit: quote?.DaysValidAfterSubmit ?? 0,
            Zip: quote?.Zip ?? '',
            ReviewedByDirectorOfOperations: quote?.ReviewedByDirectorOfOperations ?? false,
            ReviewedByVPOfOperations: quote?.ReviewedByVPOfOperations ?? false,
            ProjectManagerUserId: quote?.ProjectManagerUserId ?? null,
            ExpectedYear: quote?.ExpectedYear ?? null,
            ExpectedQuarter: quote?.ExpectedQuarter ?? '',
            SurveyMileage: quote?.SurveyMileage ?? 0,
        },
        onSubmit: (values) => {
            const vars = getVariablesEdit(validationSchema.cast(values), user?.id ?? '');
            editQuote({
                variables: {
                    Id: quote.Id,
                    object: vars,
                },
                onCompleted: (data) => {
                    if (data)
                        addMessage('Bid successfully saved', { severity: 'success' });
                    if (projectManagerChanged)
                        axios.post(`/bidbook/${quote.Id}/SendProjectManagerEmail`)
                            .then(() => {
                                addMessage('User notified of project manager assignment.', { severity: 'success' });
                            })
                            .catch(() => {
                                addMessage('Error notifying user of project manager assignment.', { severity: 'error' });
                            });
                    setProjectManagerChanged(false);
                }
            }).then(data => {
                if (quote.FieldHours !== values.FieldHours) {
                    return updateRateHours({
                        variables: {
                            BidQuoteId: quote.Id,
                            NewHours: Number(values.FieldHours)
                        },
                        onCompleted: (data) => {
                            if (data)
                                addMessage('Bid hours updated', { severity: 'success' });
                        },
                        refetchQueries: 'active',
                    })
                }
                if (quote.SurveyFieldHours !== values.SurveyFieldHours) {
                    return updateRateHours({
                        variables: {
                            BidQuoteId: quote.Id,
                            NewHours: Number(values.SurveyFieldHours)
                        },
                        onCompleted: (data) => {
                            if (data)
                                addMessage("Survey hours updated", { severity: 'success' })
                        },
                        refetchQueries: 'active',
                    })
                }
            });
        }
    });

    const handleStateSelect = (event: React.ChangeEvent<{}>, value: string | null) => {
        formik.setFieldValue("JobState", value ?? "");
    };
    const [customerContact, setCustomerContact] = useState<CustomerContact | null>(clientRepData?.CustomerContacts?.find((cr: any) => cr.Id === formik.values.CustomerRepId) ?? null);
    const companySelected = companyData?.Customers?.find((c: any) => c.Id === formik.values.CustomerId) ?? null;
    const businessLead = userData?.AspNetUsers?.find((u: any) => u.Id === formik.values.BusinessLeadId) ?? null;
    const analyst = userData?.AspNetUsers?.find((u: any) => u.Id === formik.values.AnalystId) ?? null;
    const bidBookLead = userData?.AspNetUsers?.find((u: any) => u.Id === formik.values.BidBookId) ?? null;
    const preparedByUser = userData?.AspNetUsers?.find((u: any) => u.Id === formik.values.PreparedByUserId) ?? null;
    const projectManagerUser = userData?.AspNetUsers?.find((u: any) => u.Id === formik.values.ProjectManagerUserId) ?? null;
    const [projectManagerChanged, setProjectManagerChanged] = useState(false);
    const lastNameSortFunc = (a: any, b: any) => {
        const nameA = `${a?.LastName}, ${a?.FirstName}`;
        const nameB = `${b?.LastName}, ${b?.FirstName}`;
        return nameA.localeCompare(nameB);
    }

    const sortedCustomerContactList: any = clientRepData?.CustomerContacts ? [...clientRepData?.CustomerContacts].sort(lastNameSortFunc) : [];
    const sortedUserList = userData?.AspNetUsers ? [...userData?.AspNetUsers].sort(lastNameSortFunc) : [];

    const handleClientSelect = (event: React.ChangeEvent<{}>, value: any) => {
        formik.setFieldValue("CustomerId", value?.Id ?? '');
        if (value?.Id) {
            getClientRep({
                variables: {
                    CustomerId: value?.Id,
                }
            });
        }
        if (formik.values.CustomerRepId) {
            formik.setFieldValue("CustomerRepId", '');
        }
    };

    const handleClientRepSelect = (event: React.ChangeEvent<{}>, value: any) => {
        formik.setFieldValue("CustomerRepId", value?.Id ?? '');
        setCustomerContact(clientRepData?.CustomerContacts?.find((cr: any) => cr.Id === value?.Id) ?? null);
    };

    const bidBookAnalystList = sortedUserList.filter((user) => user.UserRole.some((role: any) => role.UserHighridgeRole.Name === "BidBook Analyst"));
    const bidBookLeadList = sortedUserList.filter((user) => user.UserRole.some((role: any) => role.UserHighridgeRole.Name === "BidBook Lead"));
    const businessDevelopmentUserList = sortedUserList.filter((user) => user.UserRole.some((role: any) => role.UserHighridgeRole.Name === "Business Development Viewer"));

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <StyledHeader>Bid Information</StyledHeader>
                <form className='ui form' onSubmit={formik.handleSubmit}>
                    <Grid container direction="column" spacing={1} sx={{ paddingTop: 1 }}>
                        <Grid container item xs={12} spacing={1}>
                            <Grid item xs={8}>
                                <Autocomplete
                                    id="customer"
                                    options={companyData?.Customers ? [...companyData?.Customers].sort((a: any, b: any) => a.Name?.localeCompare(b.Name)) : []}
                                    value={companySelected}
                                    getOptionLabel={(option: any) => option.Name}
                                    onChange={handleClientSelect}
                                    fullWidth
                                    disabled={disabledForStatus}
                                    renderInput={(params) => (
                                        <HrTextInput {...params} label="Client" errors={formik.errors.CustomerId} />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <HrTextInput
                                    label='Client Short Name'
                                    value={companySelected?.ShortCode ?? ''}
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Autocomplete
                                id="customerRep"
                                options={formik.values.CustomerId ? sortedCustomerContactList : []}
                                value={customerContact}
                                getOptionLabel={(option: any) => `${option.LastName}, ${option.FirstName} - ${option.Email} - ${option.Phone}`}
                                onChange={handleClientRepSelect}
                                fullWidth
                                disabled={disabledForStatus}
                                renderInput={(params) => (
                                    <HrTextInput
                                        {...params}
                                        label="Client Rep"
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        aria-label="Add Customer Rep"
                                                        onClick={() => setModalOpen(true)}
                                                    >
                                                        <PersonAdd sx={{ color: Colors.deepSeaGreen }} />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                )}
                            />

                        </Grid>
                        <Grid container item xs={12}>
                            <HrTextInput
                                name='BidDescription'
                                label='Project Alias'
                                onChange={formik.handleChange}
                                value={formik.values.BidDescription}
                                errors={formik.errors.BidDescription}
                                fullWidth
                            />
                        </Grid>
                        <Grid item container xs={12} spacing={1}>
                            <Grid item xs>
                                <HrTextInput
                                    name='JobCity'
                                    label='Job City'
                                    onChange={formik.handleChange}
                                    value={formik.values.JobCity}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs>
                                <Autocomplete
                                    id="jobState"
                                    options={stateList}
                                    value={formik.values.JobState}
                                    onChange={handleStateSelect}
                                    fullWidth
                                    disabled={disabledForStatus}
                                    renderInput={(params) => (
                                        <HrTextInput {...params} label="Job State" errors={formik.errors.JobState} />
                                    )}
                                />
                            </Grid>
                            <Grid item xs>
                                <HrTextInput
                                    name='Zip'
                                    label='Zip Code'
                                    onChange={formik.handleChange}
                                    value={formik.values.Zip}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} spacing={1}>
                            <Grid item xs={6}>
                                <DatePicker
                                    value={formik.values.DueDate}
                                    disabled={disabledForStatus}
                                    onChange={(data) => formik.setFieldValue('DueDate', data)}
                                    renderInput={(props) => <HrTextInput {...props} name="DueDate" label="Quote Due Date" fullWidth />}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePicker
                                    value={formik.values.ReceivedDate}
                                    disabled={disabledForStatus}
                                    onChange={(data) => formik.setFieldValue('ReceivedDate', data)}
                                    renderInput={(props) => <HrTextInput {...props} name="ReceivedDate" label="Quote Received Date" fullWidth />}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <HrSelect
                                    label="Exp Year Completed"
                                    name="ExpectedYear"
                                    value={formik.values.ExpectedYear}
                                    onChange={formik.handleChange}
                                    variant="standard"
                                >
                                    {
                                        yearOptions.map((year) => {
                                            return <MenuItem value={year}>{year}</MenuItem>
                                        })
                                    }
                                </HrSelect>
                            </Grid>
                            <Grid item xs={6}>
                                <HrSelect
                                    label="Exp Qtr Completed"
                                    name="ExpectedQuarter"
                                    value={formik.values.ExpectedQuarter}
                                    onChange={formik.handleChange}
                                    variant="standard"
                                >
                                    {
                                        ["Q1", "Q2", "Q3", "Q4"].map((year) => {
                                            return <MenuItem value={year}>{year}</MenuItem>
                                        })
                                    }
                                </HrSelect>
                            </Grid>

                        </Grid>
                        <Grid item container xs={12}>
                            <Grid item>
                                <HrCheckBox
                                    name="Budgetary"
                                    label="Needed For Budgetary"
                                    checked={formik.values.Budgetary}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item>
                                <HrCheckBox
                                    name="CoverLetter"
                                    label="Cover Letter"
                                    checked={formik.values.CoverLetter}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item>
                                <HrCheckBox
                                    name="TimeAndMaterials"
                                    label="Time and Materials"
                                    checked={formik.values.TimeAndMaterials}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item>
                                <HrCheckBox
                                    name="KmzReview"
                                    label="KMZ Reviewed"
                                    checked={formik.values.KmzReview}
                                    onChange={formik.handleChange}
                                />
                            </Grid>

                            <Grid item>
                                <HrCheckBox
                                    name="GpsPointsReview"
                                    label="GPS Points Reviewed"
                                    checked={formik.values.GpsPointsReview}
                                    onChange={formik.handleChange}
                                />
                            </Grid>

                            <Grid item>
                                <HrCheckBox
                                    name="ProjectAreaReview"
                                    label="Project Area Reviewed (Standard Lodging Prices Concerned)"
                                    checked={formik.values.ProjectAreaReview}
                                    onChange={formik.handleChange}
                                />
                            </Grid>

                            <Grid item>
                                <HrCheckBox
                                    name="SpecialEquipmentNeeded"
                                    label="Special Equipment Needed"
                                    checked={formik.values.SpecialEquipmentNeeded}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item>
                                <HrCheckBox
                                    name="ClientScopeOfWorkReviewed"
                                    label="Client Scope Of Work Reviewed"
                                    checked={formik.values.ClientScopeOfWorkReviewed}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item>
                                <HrCheckBox
                                    name="ReviewedByDirectorOfOperations"
                                    label="Reviewed By Director Of Operations"
                                    checked={formik.values.ReviewedByDirectorOfOperations}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item>
                                <HrCheckBox
                                    name="ReviewedByVPOfOperations"
                                    label="Reviewed By VP of Operations"
                                    checked={formik.values.ReviewedByVPOfOperations}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                id="analyst"
                                options={bidBookAnalystList}
                                value={analyst}
                                getOptionLabel={(option: any) => `${option.LastName}, ${option.FirstName}`}
                                onChange={(event, value) => formik.setFieldValue("AnalystId", value?.Id ?? '')}
                                disabled={disabledForStatus}
                                renderInput={(params) => (
                                    <HrTextInput {...params} label="Bid Book Analyst" variant="outlined" fullWidth />
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                id="businessUser"
                                options={businessDevelopmentUserList}
                                value={businessLead as any}
                                disableClearable
                                getOptionLabel={(option: any) => `${option.LastName}, ${option.FirstName}`}
                                onChange={(event, value) => formik.setFieldValue("BusinessLeadId", value?.Id ?? '')}
                                disabled={disabledForStatus}
                                renderInput={(params) => (
                                    <HrTextInput {...params} label="Business Development Lead" variant="outlined" fullWidth errors={formik.errors.BusinessLeadId} />
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                id="bidbookUser"
                                options={bidBookLeadList}
                                value={bidBookLead}
                                getOptionLabel={(option: any) => `${option.LastName}, ${option.FirstName}`}
                                onChange={(event, value) => formik.setFieldValue("BidBookId", value?.Id ?? '')}
                                disabled={disabledForStatus}
                                renderInput={(params) => (
                                    <HrTextInput {...params} label="Bid Book Lead" variant="outlined" fullWidth />
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                id="preparedByUser"
                                options={sortedUserList}
                                value={preparedByUser}
                                getOptionLabel={(option: any) => `${option.LastName}, ${option.FirstName}`}
                                onChange={(event, value) => formik.setFieldValue("PreparedByUserId", value?.Id ?? '')}
                                disabled={disabledForStatus}
                                renderInput={(params) => (
                                    <HrTextInput {...params} label="Prepared By User" variant="outlined" fullWidth />
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <HrTextInput
                                name="PreparedByTitle"
                                label="Prepared By Title"
                                value={formik.values.PreparedByTitle}
                                onChange={formik.handleChange}
                                fullWidth
                            />
                        </Grid>
                        {
                            (status === "Awarded" || status === "Archived" || status === "Scheduled") && (
                                <Grid item>
                                    <Autocomplete
                                        id="projectManagerUser"
                                        options={sortedUserList}
                                        value={projectManagerUser}
                                        getOptionLabel={(option: any) => `${option.LastName}, ${option.FirstName}`}
                                        onChange={(event, value) => {
                                            setProjectManagerChanged(true);
                                            return formik.setFieldValue("ProjectManagerUserId", value?.Id ?? '');
                                        }}
                                        disabled={status === "Archived"}
                                        renderInput={(params) => (
                                            <HrTextInput {...params} label="Project Manager" variant="outlined" fullWidth disabled={status === "Archived"} overrideStatusDisable={true} />
                                        )}
                                    />
                                </Grid>
                            )
                        }


                        <Grid item container spacing={1}>
                            <Grid item xs={4}>
                                <HrCurrencyInput
                                    name="PerDiemRate"
                                    label="Per Diem Rate"
                                    value={formik.values.PerDiemRate}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <HrTextInput
                                    name="MileageRate"
                                    label="Mileage Rate"
                                    value={formik.values.MileageRate}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <HrTextInput
                                    name="SurveyMileage"
                                    label="Survey Mileage"
                                    value={formik.values.SurveyMileage}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <HrTextInput
                                    name="OfficeHours"
                                    label="Office Hours"
                                    value={formik.values.OfficeHours}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <HrTextInput
                                    name="FieldHours"
                                    label="CTI Field Hours"
                                    value={formik.values.FieldHours}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <HrTextInput
                                    name="SurveyFieldHours"
                                    label="Survey Field Hours"
                                    value={formik.values.SurveyFieldHours}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <HrTextInput
                                    name="DaysValidAfterSubmit"
                                    label="Days Valid"
                                    value={formik.values.DaysValidAfterSubmit}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <HrButton
                                type='submit'
                                disabled={(saveLoading || !formik.isValid || disabledForStatus) && projectManagerChanged === false}
                            >
                                {!saveLoading && !updateLoading ? 'Save Quote' : 'Saving...'}
                            </HrButton>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            <Dialog
                open={modalOpen}
                onClose={closeModal}
            >
                <DialogContent>
                    <Button
                        onClick={() => {
                            setModalOpen(false);
                        }}
                        variant="contained"
                        color="primary"
                        className="modal-exit"
                    >
                        x
                    </Button>
                    <FormGrid>
                        <CustomerContactItem
                            customerId={formik.values.CustomerId}
                            onCancel={closeModal}
                            onSave={closeModalAndRefreshList}
                        />
                    </FormGrid>
                </DialogContent>
            </Dialog>
        </>
    );
};