import { useApolloClient, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { Add } from "@mui/icons-material";
import { Button, Dialog, DialogContent } from "@mui/material";
import { GridColDef, GridToolbarContainer } from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import { CREATE_SERVICE_RATE_MUTATION, DELETE_SERVICE_RATE_MUTATION, GetServiceRatesQueryResults, GET_SERVICE_RATES_QUERY, UPDATE_SERVICE_RATE_MUTATION } from "../../../api/graphqlqueries/servicerates";
import { appUserVar } from "../../../stores/ReactiveVariables";
import dollarUS from '../../../utils/CurrencyFormat';
import { FormGrid } from "../../atoms/FormGrid";
import RemoveButton from "../../atoms/RemoveButton";
import { HrDataGrid } from "../../molecules/HrDataGrid";
import { ServicesForm } from "./ServicesForm";

export const ServicesDataGrid: React.FC<{}> = (props) => {
    const user = useReactiveVar(appUserVar);
    const [selectedService, setSelectedService] = useState('');
    const { data, loading, error } = useQuery<GetServiceRatesQueryResults>(GET_SERVICE_RATES_QUERY);
    const [createService] = useMutation(CREATE_SERVICE_RATE_MUTATION);
    const [updateService] = useMutation(UPDATE_SERVICE_RATE_MUTATION);
    const [deleteService] = useMutation(DELETE_SERVICE_RATE_MUTATION);
    const client = useApolloClient();
    const deleteServiceHandler = (Id: string) => {
        deleteService({
            variables: {
                Id
            },
            optimisticResponse: {
                delete_ServiceRates_by_pk: {
                    Id,
                    __typename: "ServiceRates"
                }
            },
            update(cache, { data }) {
                cache.evict({
                    id: `ServiceRates:${Id}`
                });
                cache.gc();
            },
            onError(error) {
                console.error(error);
                client.refetchQueries({
                    include: "active"
                });
            }
        });
    }

    const handleSave = (object: any) => {
        if (selectedService === 'new') {
            createService({
                variables: {
                    object
                },
                optimisticResponse: {
                    insert_ServiceRates_one: {
                        __typename: 'ServiceRates',
                        ...object
                    }
                },
                update: (cache, { data }) => {
                    cache.updateQuery({
                        query: GET_SERVICE_RATES_QUERY
                    },
                    (cacheData: any) => ({
                        ServiceRates: [...cacheData.ServiceRates, data.insert_ServiceRates_one]
                    }))
                }
            })
        } else {
            updateService({
                variables: {
                    Id: object.Id,
                    object
                },
                optimisticResponse: {
                    update_ServiceRates_by_pk: {
                        __typename: 'ServiceRates',
                        ...object
                    }
                }
            });
        }
        setSelectedService('');
    }
    const rows = data?.ServiceRates ?? [];
    const selectedServiceObject = rows.find((i: any) => i.Id === selectedService);
    const columns: GridColDef[] = [
        {
            field: "removeService",
            headerName: " ",
            width: 50,
            renderCell: (rows) => {
                const billableId = rows.id;
                return (
                    <RemoveButton
                        title="Billables"
                        onClick={() => deleteServiceHandler(billableId as string)}
                    />
                );
            },
            headerClassName: "billables-grid-header",
        },
        // {
        //     field: "id",
        //     headerName: "ID",
        //     width: 100,
        //     headerClassName: "billables-grid-header",
        // },
        {
            field: "Name",
            headerName: "Service Item",
            width: 250,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "ServiceType",
            headerName: "Service Type",
            width: 120,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "DailyHours",
            headerName: "Hours per Day",
            width: 150,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "Rate",
            headerName: "Rate",
            width: 150,
            editable: false,
            headerClassName: "billables-grid-header",
            valueFormatter: (params) => dollarUS.format(params.value)
        },
        {
            field: "Cost",
            headerName: "Hourly Cost",
            width: 150,
            editable: false,
            headerClassName: "billables-grid-header",
            valueFormatter: (params) => dollarUS.format(params.value)
        }, 
    ] 

    if (error) {
        console.error(error);
    }
    return (
        <>
            <HrDataGrid
                loading={loading}
                rows={rows}
                columns={columns}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                disableRowSelectionOnClick
                onRowDoubleClick={(model, details) => {
                    setSelectedService(model.id as string);
                }}
                components={{
                    Toolbar: () => <GridToolbarContainer>
                    <Button color="primary" startIcon={<Add />} onClick={() => setSelectedService('new')}>
                      Add Service
                    </Button>
                  </GridToolbarContainer>
                }}
                initialState={{
                    sorting: {
                      sortModel: [{ field: 'Name', sort: 'asc' }],
                    },
                    columns: {
                        columnVisibilityModel: {
                            removeService: user?.roles?.some(e => e.name === "BidBook Admin") ?? false
                        }
                    }
                }}
            />
                <Dialog
                    open={selectedService !== ''}
                    onClose={() => setSelectedService('')}
                >
                    <DialogContent>

                        <Button
                            onClick={() => {
                                setSelectedService('');
                            }}
                            variant="contained"
                            color="primary"
                            className="modal-exit"
                        >
                            x
                        </Button>
                        <FormGrid>
                            <ServicesForm item={selectedServiceObject} onSave={handleSave} onCancel={() => setSelectedService('')} />
                        </FormGrid>
                    </DialogContent>
                </Dialog>
        </>
    );
}