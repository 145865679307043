import { GridColDef, GridToolbarContainer } from "@mui/x-data-grid-pro";
import { observer } from "mobx-react-lite";
import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { useStore } from "../../../stores/StoreContext";
import RemoveButton from "../../atoms/RemoveButton";
import { HrDataGrid } from "../../molecules/HrDataGrid";
import dollarUS from '../../../utils/CurrencyFormat';
export interface BillablesDataGridProps {
    type: string;
}

export const BillablesDataGrid: React.FC<BillablesDataGridProps> = observer(
    ({ type }) => {
        const { billablesStore, modalStore, userStore } = useStore();
        const {
            billables,
            setSelectedBillable,
            deleteBillable,
            setEditOrCreateMode
        } = billablesStore;

        const { openBillablesModal } = modalStore;
        const rows = billables.filter(b => b.type === (type === "Equipment" ? 0 : 1)).map((billable) => {
            return {
                id: billable.id,
                billableItem: billable.name,
                dailyRate: billable.cost,
                type: billable.type,
            };
        });
        const addService = () =>{
            setEditOrCreateMode(true);
            openBillablesModal(type);
        }
        const columns: GridColDef[] = [
            {
                field: "removeBillable",
                headerName: " ",
                width: 50,
                renderCell: (rows) => {
                    const billableId = rows.id;
                    return (
                        <RemoveButton
                            title="Billables"
                            onClick={() => deleteBillable(billableId as string)}
                        />
                    );
                },
                headerClassName: "billables-grid-header",
            },
            // {
            //     field: "id",
            //     headerName: "ID",
            //     width: 100,
            //     headerClassName: "billables-grid-header",
            // },
            {
                field: "billableItem",
                headerName: "Billable Item",
                width: 250,
                editable: false,
                headerClassName: "billables-grid-header",
            },
            {
                field: "type",
                headerName: "Billable Type",
                width: 250,
                editable: false,
                headerClassName: "billables-grid-header",
				valueGetter: (params) => {
					if (!params.value) {
					  return params.value;
					}
					return params.value == 0 ? "Equipment" : "Material";
				}
            },
            {
                field: "dailyRate",
                headerName: "Daily Rate",
                width: 150,
                editable: false,
                headerClassName: "billables-grid-header",
                valueFormatter: (params) => dollarUS.format(params.value)
            },
            
        ];

        return (
            <HrDataGrid
                rows={rows}
                getRowId={undefined}
                columns={columns}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                disableRowSelectionOnClick
                onRowDoubleClick={(model, details) => {
                    setSelectedBillable(model.id as string);
                    openBillablesModal(type);
                }}
                components={{
                    Toolbar: () => <GridToolbarContainer>
                    <Button color="primary" startIcon={<Add />} onClick={addService}>
                      Add {type}
                    </Button>
                  </GridToolbarContainer>
                }}
                initialState={{
                    sorting: {
                      sortModel: [{ field: 'billableItem', sort: 'asc' }],
                    },
                    columns: {
                        columnVisibilityModel: {
                            removeBillable: userStore?.appUser?.roles?.some(e => e.name === "BidBook Admin") ?? false
                        }
                    }
                }}
            />
        );
    }
);