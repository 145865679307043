import React from 'react';
import * as yup from 'yup';
import { useFormik } from "formik";
import { v4 } from 'uuid';
import { HighRidgeColorButton } from "../../atoms/HighRidgeColorButton"; 
import { Box, Button, Grid } from "@mui/material";
import HrTextInput from "../../atoms/HrTextInput";
import HrTextArea from '../../atoms/HrTextArea';

interface DefaultTermsItem {
    Id: string;
    Name: string;
    Term: string;
}
interface DefaultTermsFormProps {
    item?: DefaultTermsItem;
    onSave: (item: DefaultTermsItem) => void;
    onCancel?: () => void;
}


const DefaultTermsItemSchema = yup.object().shape({
    Name: yup.string().required(),
    Term: yup.string().required()
});


export const DefaultTermsForm: React.FC<DefaultTermsFormProps> = ({ item, onSave, onCancel }) => {
    const Id = item?.Id ?? v4();
    const formik = useFormik({
        validationSchema: DefaultTermsItemSchema,
        initialValues: {
            Name: item?.Name ?? '',
            Term: item?.Term ?? '',
        },
        onSubmit: (values) => {
            onSave({ Id, ...values});
            formik.setSubmitting(false);
        }
    });
    return (<React.Fragment>
        <Box>
            <HrTextInput
                name='Name'
                label='Name'
                onChange={formik.handleChange}
                value={formik.values.Name}
                errors={formik.errors.Name}
                fullWidth
            />
        </Box>
        <Box>
            <HrTextArea
                name='Term'
                label='Term'
                onChange={formik.handleChange}
                minRows={5}
                value={formik.values.Term}
                errors={formik.errors.Term}
                fullWidth
                multiline
            />
        </Box>
        <Grid container justifyContent="space-between">
            <HighRidgeColorButton
                type="button"
                onClick={formik.submitForm}
            >Save Item</HighRidgeColorButton>
            {
                onCancel &&
                <Button
                    color="secondary"
                    type="button"
                    onClick={onCancel}
                >
                    Cancel
                </Button>

            }
        </Grid>
    </React.Fragment>);
}