import { useApolloClient, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { Add } from "@mui/icons-material";
import { Button, Dialog, DialogContent } from "@mui/material";
import { GridColDef, GridToolbarContainer } from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import { CREATE_DEFAULT_TERM_MUTATION, DELETE_DEFAULT_TERM_MUTATION, GetDefaultTermsQueryResult, GET_DEFAULT_TERMS_QUERY, UPDATE_DEFAULT_TERM_MUTATION } from "../../../api/graphqlqueries/defaultterms";
import { appUserVar } from "../../../stores/ReactiveVariables";
import { FormGrid } from "../../atoms/FormGrid";
import RemoveButton from "../../atoms/RemoveButton";
import { HrDataGrid } from "../../molecules/HrDataGrid";
import { DefaultTermsForm } from "./DefaultTermsForm";

export const DefaultTermsDataGrid: React.FC<{}> = (props) => {
    const user = useReactiveVar(appUserVar);
    const [selectedDefaultTerm, setSelectedDefaultTerm] = useState('');
    const { data, loading, error } = useQuery<GetDefaultTermsQueryResult>(GET_DEFAULT_TERMS_QUERY);
    const [createDefaultTerm] = useMutation(CREATE_DEFAULT_TERM_MUTATION);
    const [updateDefaultTerm] = useMutation(UPDATE_DEFAULT_TERM_MUTATION);
    const [deleteDefaultTerm] = useMutation(DELETE_DEFAULT_TERM_MUTATION);
    const client = useApolloClient();
    const deleteDefaultTermHandler = (Id: string) => {
        deleteDefaultTerm({
            variables: {
                Id
            },
            optimisticResponse: {
                delete_DefaultTerms_by_pk: {
                    Id,
                    __typename: "DefaultTerms"
                }
            },
            update(cache, { data }) {
                cache.evict({
                    id: `DefaultTerms:${Id}`
                });
                cache.gc();
            },
            onError(error) {
                console.error(error);
                client.refetchQueries({
                    include: "active"
                });
            }
        });
    }

    const handleSave = (object: any) => {
        if (selectedDefaultTerm === 'new') {
            createDefaultTerm({
                variables: {
                    object
                },
                optimisticResponse: {
                    insert_DefaultTerms_one: {
                        __typename: 'DefaultTerms',
                        ...object
                    }
                },
                update: (cache, { data }) => {
                    cache.updateQuery({
                        query: GET_DEFAULT_TERMS_QUERY
                    },
                    (cacheData: any) => ({
                        DefaultTerms: [...cacheData.DefaultTerms, data.insert_DefaultTerms_one]
                    }))
                }
            })
        } else {
            updateDefaultTerm({
                variables: {
                    Id: object.Id,
                    object
                },
                optimisticResponse: {
                    update_DefaultTerms_by_pk: {
                        __typename: 'DefaultTerms',
                        ...object
                    }
                }
            });
        }
        setSelectedDefaultTerm('');
    }
    const rows = data?.DefaultTerms ?? [];
    const selectedDefaultTermObject = rows.find((i: any) => i.Id === selectedDefaultTerm);
    const columns: GridColDef[] = [
        {
            field: "removeDefaultTerm",
            headerName: " ",
            width: 50,
            renderCell: (rows) => {
                const billableId = rows.id;
                return (
                    <RemoveButton
                        title="Billables"
                        onClick={() => deleteDefaultTermHandler(billableId as string)}
                    />
                );
            },
            headerClassName: "billables-grid-header",
        },
        // {
        //     field: "id",
        //     headerName: "ID",
        //     width: 100,
        //     headerClassName: "billables-grid-header",
        // },
        {
            field: "Name",
            headerName: "Name",
            width: 250,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "Term",
            headerName: "Term",
            width: 800,
            editable: false,
            headerClassName: "billables-grid-header",
        }, 
    ] 

    if (error) {
        console.error(error);
    }
    return (
        <>
            <HrDataGrid
                loading={loading}
                rows={rows}
                columns={columns}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                disableRowSelectionOnClick
                onRowDoubleClick={(model, details) => {
                    setSelectedDefaultTerm(model.id as string);
                }}
                initialState={{
                    sorting: {
                      sortModel: [{ field: 'Name', sort: 'asc' }],
                    },
                    columns: {
                        columnVisibilityModel: {
                            removeDefaultTerm: user?.roles?.some(e => e.name === "BidBook Admin") ?? false
                        }
                    }
                }}
                components={{
                    Toolbar: () => <GridToolbarContainer>
                    <Button color="primary" startIcon={<Add />} onClick={() => setSelectedDefaultTerm('new')}>
                      Add Default Term
                    </Button>
                  </GridToolbarContainer>
                }}
                
                
            />
            <Dialog
                open={selectedDefaultTerm !== ''}
                onClose={() => setSelectedDefaultTerm('')}
                maxWidth="xl"
                fullWidth
            >
                <DialogContent>

                    <Button
                        onClick={() => {
                            setSelectedDefaultTerm('');
                        }}
                        variant="contained"
                        color="primary"
                        className="modal-exit"
                    >
                        x
                    </Button>
                    <FormGrid>
                        <DefaultTermsForm item={selectedDefaultTermObject} onSave={handleSave} onCancel={() => setSelectedDefaultTerm('')} />
                    </FormGrid>
                </DialogContent>
            </Dialog>
        </>
    );
}