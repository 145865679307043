import { useMutation, useQuery } from "@apollo/client";
import { Box, Grid } from '@mui/material';
import React from 'react';
import { GetMaterialServiceQueryResults, GET_MATERIAL_SERVICE_QUERY } from "../../../../api/graphqlqueries/getmaterialservicequery";
import { GetQuoteStatus, GET_QUOTE_STATUS_QUERY } from "../../../../api/graphqlqueries/getstatus";
import { UPDATE_MATERIAL_ITEM_MUTATION } from "../../../../api/graphqlqueries/materialserviceitemmutations";
import { QuoteLineItemBox } from '../../../atoms/QuoteLineItemBox';
import LineItemLoading from "../../../molecules/LineItemLoading";
import { QuoteAddersRateForm } from './BidbookQuoteAddersRateForm';

interface QuoteAddersProps { 
    bidQuoteId: string;
    id?: string;
    onSave?: () => void; 
    type?: string;
    total : number
}
 
export const QuoteAddersRate: React.FC<QuoteAddersProps> = (props: QuoteAddersProps) => {
    const { id, bidQuoteId, total } = props; 

    const { data, error } = useQuery<GetMaterialServiceQueryResults>(GET_MATERIAL_SERVICE_QUERY, {
        variables: {
            Id: id
        },
        fetchPolicy: 'cache-and-network'
    });

    const { data: statusData, error: status } = useQuery<GetQuoteStatus>(GET_QUOTE_STATUS_QUERY, {
        variables: {
            BidQuoteId: bidQuoteId
        },
        fetchPolicy: 'cache-and-network'
    });

    if (error) {
        console.error(error);
    }
    const [updateServiceItem] = useMutation(UPDATE_MATERIAL_ITEM_MUTATION);

    const updateNewServiceItem = (formValues: any) => {
        const values = { BidQuoteId: bidQuoteId, ...formValues };
        updateServiceItem({ variables: { Id: values.Id, object: values}});
    }

    if (error) {
        console.error(error);
    }
    if (!data) return <LineItemLoading />;
    const styles = {
        lineItemGrid : {
            gap:"2px", 
            alignItems:'center', 
            flexWrap:"nowrap!important"
        },
        accordionIconContainer : {
            alignSelf: 'center', 
            cursor:'pointer',
            '& .MuiSvgIcon-root':{
                color:'#fff', 
                backgroundColor:"rgba(0,0,0,0.6)", 
                borderRadius:1, 
                fontSize:"24px" 
            }
        }
    }
    const quoteItem = data.BidQuoteMaterials[0];
    return(
            <QuoteLineItemBox>
                <Grid item container sx={styles.lineItemGrid}>
                    <Box sx={{flexGrow:"2"}}>
                        <QuoteAddersRateForm item={quoteItem} onSave={updateNewServiceItem} total={total} status={statusData?.BidQuoteStatus[0].Status ?? ""} />
                    </Box>
                </Grid> 
            </QuoteLineItemBox> 
    )
}