import { Box } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { v4 } from 'uuid';
import * as yup from 'yup';
import { FormGrid } from '../../../atoms/FormGrid';
import HrButton from '../../../atoms/HrButton';
import { HrCurrencyInput } from '../../../atoms/HrCurrencyInput';
import HrTextInput from '../../../atoms/HrTextInput';
import { StyledHeader } from '../../../atoms/StyledHeader';

interface BillablesItem {
    Id: string;
    Name: string;
    Cost: number;
    Type: string;
}
interface BillablesFormProps {
    type: string;
    item?: BillablesItem;
    onSave: (item: BillablesItem) => void;
    onCancel?: () => void;
}

const validationSchema = yup.object().shape({
    Name: yup.string().required('Billable Name is required'),
    Cost: yup.number().required('Billable Cost is required'),
});

export const BidbookBillablesForm: React.FC<BillablesFormProps> = ({ type, item, onSave, onCancel }) => {

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            Name: item?.Name ?? '',
            Cost: item?.Cost ?? 0,
        },
        onSubmit: (values: any) => {
            const castValues = validationSchema.cast(values);
            onSave({ Id: item?.Id ?? v4(), Name: castValues.Name!, Type: type, Cost: castValues.Cost! })
        }
    })
    return (
        <Box sx={{ width: '100%' }}>
            <StyledHeader sx={{textAlign:"center"}}>Edit Billable</StyledHeader>
            <form onSubmit={formik.handleSubmit}>
                <FormGrid>
                    <HrTextInput
                        name='Name'
                        label='Name'
                        onChange={formik.handleChange}
                        value={formik.values.Name}
                        errors={formik.errors.Name}
                    />
                    <HrCurrencyInput
                        name='Cost'
                        label='Cost'
                        onChange={formik.handleChange}
                        value={formik.values.Cost.toString()}
                        errors={formik.errors.Cost}
                    />
                    <HrButton
                        type='submit'
                        title='Submit'
                    />
                    <HrButton
                        title='Close'
                        onClick={onCancel}
                    />
                </FormGrid>
            </form>
        </Box>
    );
};
