import { Grid, Autocomplete, Chip } from "@mui/material";
import { useQuery, gql } from "@apollo/client";
import HrTextArea from "../../../atoms/HrTextArea";
import HrTextInput from "../../../atoms/HrTextInput";
import { useFormik } from "formik";
import debounce from '../../../../utils/debounce';
import * as yup from "yup";
import { ChangeEvent, useCallback } from "react";

interface StaffingDetailsFormProps {
    id?: string;
    addCrewMember: any;
    deleteCrewMember: any;
    crewMembers: any;
    onSave: (data: any) => void;
    data: any;
}

const GET_EMPLOYEES_QUERY = gql`
query GetEmployees {
    AspNetUsers(where: {IsActive: {_eq: true}}) {
        FirstName
        LastName
        Id
    }
}  
`

const kickoffJobStaffingDetailsSchema = yup.object({
    CrewNotes: yup.string().nullable(),
});

export const StaffingDetailsForm: React.FC<StaffingDetailsFormProps> = ({ id, addCrewMember, deleteCrewMember, crewMembers, onSave, data }: StaffingDetailsFormProps) => {
    const { data: employeeData, error } = useQuery(GET_EMPLOYEES_QUERY);

    const debouncer = useCallback(debounce((values: any) => updateData(values), 1000), []);

    const updateData = (data: any) => {
        onSave(data);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        try {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value };
            const values = kickoffJobStaffingDetailsSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.handleChange(e);
    }

    const formik = useFormik({
        initialValues: {
            CrewNotes: data?.CrewNotes ?? ""
        },
        validationSchema: kickoffJobStaffingDetailsSchema,
        enableReinitialize: true,
        onSubmit: values => {

        }
    });

    if (!data) return null;
    if (error) console.log(error);

    const employees = employeeData?.AspNetUsers;

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Autocomplete
                        id="staffingDetails"
                        options={employees?.filter((employee: any) => crewMembers?.some((crew: any) => crew?.CrewMemberId === employee?.Id) === false) ?? []}
                        value={crewMembers ?? []}
                        multiple
                        getOptionLabel={(option: any) => `${option?.LastName}, ${option?.FirstName}`}
                        fullWidth
                        renderInput={(params) => (
                            <HrTextInput
                                {...params}
                                label="Add Staff"
                            />
                        )}
                        onChange={(event, newValue, reason, detail) => {
                            if (reason === "removeOption") {
                                deleteCrewMember({
                                    CrewMemberId: detail?.option?.Id?.toLowerCase(),
                                    Ordinal: detail?.option?.Ordinal,
                                });
                            } else {
                                addCrewMember(detail?.option?.Id);
                            }
                        }}
                        renderTags={(value: readonly string[], getTagProps) =>
                            value.map((option: any, index: number) => {
                                return <Chip
                                    label={`${option?.AspNetUser?.LastName}, ${option?.AspNetUser?.FirstName}`}
                                    {...getTagProps({ index })}
                                />
                            })
                        }
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'rgba(0, 0, 0, 0.12)'
                                }
                            }
                        }}
                        disableClearable={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <HrTextArea
                        label="Crew Notes"
                        value={formik.values.CrewNotes}
                        name="CrewNotes"
                        onChange={handleChange}
                        fullWidth
                        multiline
                        minRows={3}
                        //InputLabelProps={{ shrink: true }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'rgba(0, 0, 0, 0.12)'
                                }
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </form>
    )
}