import { Box, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useRef, useState } from "react";
import { useStore } from "../../../stores/StoreContext";
import { FormGrid } from "../../atoms/FormGrid";
import HrButton from "../../atoms/HrButton";
import { CircularProgress } from "@mui/material";
import { addMessage } from "../../../stores/ReactiveVariables";

export const ClientsLogoForm: React.FC = observer((props) => {
    const { clientStore } = useStore();
    const { selectedClient, getClients, setSelectedClient } = clientStore;
    const [logoSrc, setLogoSrc] = useState('');
    const [uploadingFile, setUploadingFile] = useState(false);
    const [ready, setReady] = useState(false);
    const agent = clientStore.agent;

    const initialRef: any = null;
    const inputFileRef = useRef(initialRef);

    const getLogo = useCallback(async () => {
        if (selectedClient?.companyLogo) {
            await agent.Logo.get(selectedClient!.id).then((response) => {
                const url = URL.createObjectURL(new Blob([response]));
                setLogoSrc(url);
                setReady(true);
            })
                .catch((error) => {
                    setReady(true);
                })
        } else {
            setReady(true);
        }
    }, [selectedClient, agent.Logo]);

    useEffect(() => {
        getLogo();
        return () => {
            setReady(false);
            setUploadingFile(false);
        }
    }, [getLogo]);

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("HELLO")
        if (!event.target.files) return;
        const id = selectedClient!.id;
        if (event.target.files.length > 0) {
            setUploadingFile(true);

            const formData = new FormData();
            for (const file of event.target.files) {
                formData.append('file', file);
            }

            await agent.Logo.post(selectedClient!.id, formData).then((response) => {
                if (response.status === 200) {
                    getClients().then(() => {
                        setSelectedClient(id);
                        getLogo();
                    });
                    alert("Logo Saved.");
                    setUploadingFile(false);
                }
            })
                .catch((error) => {
                    setUploadingFile(false);
                    addMessage(`Logo upload failed`, { severity: 'error' });
                })

        }
    }

    const onBtnClick = () => {
        if (!inputFileRef.current) return;
        inputFileRef.current.click();
    };

    return (
        <Box sx={{ width: '100%' }}>
            <h2>Client Logo</h2>
            <FormGrid>
                <Grid item>
                    {logoSrc && ready && <img style={{ width: '100%' }} src={logoSrc} alt="Company Logo Placeholder" />}
                    {!ready && <CircularProgress size={60} />}
                </Grid>
                <Grid item>
                    <label htmlFor="contained-button-file">
                        <input
                            style={{ display: 'none' }}
                            accept="image/*"
                            ref={inputFileRef}
                            type="file"
                            onChange={handleFileUpload}
                        />
                        <HrButton onClick={onBtnClick} variant="contained" disabled={uploadingFile} component="span">
                            {uploadingFile ? 'Uploading File...' : 'Upload Logo'}
                        </HrButton>
                    </label>
                </Grid>
            </FormGrid>
        </Box>
    )
});