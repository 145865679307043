import { observer } from "mobx-react-lite";
import React from "react";
import { Grid } from "@mui/material";
import { JobSummaryForm } from "./JobSummaryForm";
import { gql, useMutation, useQuery } from "@apollo/client";
import { addMessage } from "../../../stores/ReactiveVariables";

interface JobSummaryProps {
  id?: string;
}

export const GET_JOB_QUERY = gql`
query GetJob($Id: uniqueidentifier = "") {
    Jobs(where: {Id: {_eq: $Id}}) {
      Id
      City
      State
      Zip
      ExpectedMiles
      ExpectedUnits
      ReqDistanceCompleted
      ReqPerDiem
      ReqUnitsCompleted
      ReqSegments
      Status
      CustomerId
      ProjectManagerId
      Customer {
        Id
        Name
        ShortCode
      }
      Name
      isMileageBillable
      KickoffPacket {
        Id
        KickoffPacketProjectTypes {
          Id
          ServiceCategory {
            Id
            Name
          }
        }
      }
      AppUserJobs {
        __typename
        JobsId
        AppUsersId
        AspNetUser {
          Id
          FirstName
          LastName
        }
      }
      JobSegment {
        BidQuoteId
        BidQuoteItemId
        Id
        JobId
        BidQuote {
          Id
          JobNumber
          BidDescription
          BidQuoteExternalRevisions {
            Id
            ExternalRevision
          }
          BidQuoteStatus {
            Id
            Status
            CreateDate
          }
          Customer {
            Id
            Name
            ShortCode
          }
        }
        BidQuoteItem {
            Id
            Segment
            Type
        }
      }
    }
  }
`;

const UPDATE_JOB_MUTATION = gql`
mutation UpdateJob($Id: uniqueidentifier = "", $object: Jobs_set_input = {}) {
  update_Jobs(where: {Id: {_eq: $Id}}, _set: $object) {
    returning {
      Id
    }
  }
}
`;


export const JobSummary: React.FC<JobSummaryProps> = observer(({ id }: JobSummaryProps) => {

  const { data, error } = useQuery(GET_JOB_QUERY, {
    variables: {
      Id: id
    },
    fetchPolicy: "cache-and-network"
  });

  const [updateJob, { error: updateJobError }] = useMutation(UPDATE_JOB_MUTATION);

  const saveJob = (values: any) => {
    updateJob({
      variables: {
        Id: id,
        object: values
      },
      onCompleted: () => {
        addMessage("Job Saved", { severity: 'success' });
      },
      onError: () => {
        addMessage("Error Saving Job", { severity: 'error' });
      }
    })
  }

  const job = data?.Jobs[0] ?? [];

  return (
    <Grid container>
      <Grid item xs={12}>
        <JobSummaryForm id={id} job={job ?? []} onSave={saveJob} />
      </Grid>
      {/* <Grid item xs={6}>
            Job timeline
        </Grid> */}
    </Grid>
  );
});