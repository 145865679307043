import { CircularProgress, Grid } from "@mui/material";
import { addMessage } from "../../../stores/ReactiveVariables";
import HrTextInput from "../../atoms/HrTextInput";
import { StyledHeader } from '../../atoms/StyledHeader';
import { useEffect, useState } from "react";
import axios from "axios";

interface BidNotesProps {
    id: string;
    rows: number;
}

export const DynamicsBidNotes = ({ id, rows }: BidNotesProps) => {
    const [bidNotes, setBidNotes] = useState<string>("");
    const [notesLoading, setNotesLoading] = useState<boolean>(false)



    useEffect(() => {
        setNotesLoading(true);
        axios({
            url: `/dynamics/${id}/notes`, //your url
            method: 'GET',
        }).then((response: any) => {
            setBidNotes(response?.data?.value?.newdyn_biddetailnotes);
        }).catch((error: any) => {
            addMessage('Error Loading Bid Notes From Dynamics', { severity: 'error' });
        }).finally(()=>{
            setNotesLoading(false);
        })
    }, []);

    if (notesLoading) {
        return (<CircularProgress />)
    }

    return (
        <Grid container spacing={1} mb={.5} direction="column">
            <Grid item>
                <StyledHeader>Dynamics Bid Notes</StyledHeader>
            </Grid>
            <Grid item>
                <HrTextInput
                    id="standard-multiline-static"
                    name="comment"
                    label="Dynamics Bid Notes"
                    fullWidth
                    multiline
                    minRows={rows}
                    placeholder="Dynamics Bid Notes"
                    value={bidNotes}
                    disabled
                />
            </Grid>
        </Grid>
    )
}