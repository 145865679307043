import { useMutation, useQuery } from "@apollo/client";
import ExpandIcon from '@mui/icons-material/Expand';
import { Box, ButtonGroup, Grid, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { GetSurveyItemQueryReturn, GET_SURVEY_ITEM_QUERY } from "../../../../api/graphqlqueries/getsurveyquoteitem";
import { UPDATE_QUOTE_ITEM_MUTATION } from "../../../../api/graphqlqueries/quoteitemmutations";
import { QuoteLineItemBox } from '../../../atoms/QuoteLineItemBox';
import { StyledIconButton } from "../../../atoms/StyledIconButton";
import { GetQuoteStatus, GET_QUOTE_STATUS_QUERY } from "../../../../api/graphqlqueries/getstatus";
import LineItemLoading from "../../../molecules/LineItemLoading";
import { GetQuotePricingTotals, PricingTotals } from "../Totals/BidbookQuotePricingTotals";
import { QuoteMaterialRate } from './BidbookQuoteMaterialRate';
import { BidbookQuoteSurveyRateForm } from './BidbookQuoteSurveyRateForm';
import { GET_QUOTE_FACTORS_QUERY } from "../../../../api/graphqlqueries/quotefactorsquery";

interface QuoteSurveyItemProps {
    bidQuoteId: string;
    id?: string;
    onSave?: () => void;
}

export const QuoteSurveyRate: React.FC<QuoteSurveyItemProps> = (props: QuoteSurveyItemProps) => {
    const { id, bidQuoteId } = props;
    const [accordionOpen, setAccordionOpen] = useState(true);
    const [updateItem] = useMutation(UPDATE_QUOTE_ITEM_MUTATION);
    const { data, error } = useQuery<GetSurveyItemQueryReturn>(GET_SURVEY_ITEM_QUERY, {
        variables: {
            Id: id
        },
        fetchPolicy: 'cache-and-network'
    });

    const { data: statusData, error: status } = useQuery<GetQuoteStatus>(GET_QUOTE_STATUS_QUERY, {
        variables: {
            BidQuoteId: bidQuoteId
        },
        fetchPolicy: 'cache-and-network'
    });

    const pricingTotals: PricingTotals = GetQuotePricingTotals(bidQuoteId);

    const openAccordion = () => {
        setAccordionOpen(!accordionOpen);
    }


    const updateItemHandler = (values: any) => {
        updateItem({ variables: { Id: values.Id, Item: values } });
    }
    
    const { data: factorData } = useQuery(GET_QUOTE_FACTORS_QUERY, {
        variables: {
            Id: bidQuoteId
        }
    });

    const quoteFactors = factorData?.BidQuotes_by_pk;

    if (error) {
        console.error(error);
    }
    if (!data) return <LineItemLoading />;
    const styles = {
        lineItemGrid: {
            gap: "2px",
            alignItems: 'top',
            flexWrap: "nowrap!important"
        },
        accordionIconContainer: {
            alignSelf: 'center',
            cursor: 'pointer',
            '& .MuiSvgIcon-root': {
                color: '#fff',
                backgroundColor: "rgba(0,0,0,0.6)",
                borderRadius: 1,
                fontSize: "24px"
            }
        }
    }
    const quoteItem = data.BidQuoteItems_by_pk;
    return (
        <QuoteLineItemBox>
            <Grid item container sx={styles.lineItemGrid}>
                <Box sx={{ flexGrow: "2" }}>
                    <BidbookQuoteSurveyRateForm item={quoteItem} onSave={updateItemHandler} totals={pricingTotals} status={statusData?.BidQuoteStatus[0].Status ?? ""} quoteFactors={quoteFactors}/>
                </Box>
                <Grid>
                    <ButtonGroup sx={{ m: "2px" }}>
                        <Tooltip describeChild title="Show/Hide Line Item" placement="left">
                            <StyledIconButton
                                onClick={() => openAccordion()}

                            ><ExpandIcon /></StyledIconButton>
                        </Tooltip>
                    </ButtonGroup>
                </Grid>
            </Grid>
            {
                accordionOpen && quoteItem.BidQuoteMaterials?.slice().sort((a: any, b: any) => a.Ordinal - b.Ordinal).map((bqm: any) => 
                    <QuoteMaterialRate bidItemId={id} type={bqm.Type} id={bqm.Id} key={bqm.Id} />
                )
            }
        </QuoteLineItemBox>
    )
}