import { gql } from "@apollo/client";

export interface GetLastModifiedQueryResult {
    BidQuoteStatus: BidQuoteStatus[];
    BidComment: BidQuoteComment[];
}

interface BidQuoteStatus {
    Id: string;
    CreateDate: Date;
    Comment: string;
    Status: string;
    User: AppUser;
}

interface AppUser {
    Id: string;
    FirstName: string;
    LastName: string;
}

interface BidQuoteComment {
    Id: string;
    Comment: string;
    CreateDate: Date;
    User: AppUser;
}

export const GET_LAST_MODIFIED = gql`
query GetLastModified($BidQuoteId: uniqueidentifier = "") {
    BidQuoteStatus(where: {BidQuoteId: {_eq: $BidQuoteId}}, limit: 1, order_by: {CreateDate: desc}) {
        Id
        CreateDate
        Comment : Notes
        Status
        User : AspNetUser {
            Id
            FirstName
            LastName
        }
    }
    BidComment(where: {BidQuoteId: {_eq: $BidQuoteId}}, limit: 1, order_by: {CreateDate: desc}) {
        Id
        Comment
        CreateDate
        User : CreateUser {
            Id
            FirstName
            LastName
        }
    }
}
`