import { styled } from "@mui/system";
import { ButtonGroup } from "@mui/material";

export const VerticalButtonGroup = styled(ButtonGroup)(({ theme }) => ({
    "& .MuiButtonBase-root": {
        borderRadius : "0",
        "&:first-of-type": {
            borderTopLeftRadius : "4px",
            borderTopRightRadius : "4px",
            borderBottomLeftRadius : "0px!important"
        },
        "&:last-child": {
            borderBottomLeftRadius : "4px",
            borderBottomRightRadius : "4px",
            borderTopRightRadius : "0px!important",
        }
    },
}))