import React, { useState } from 'react';
import { gql, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { Grid, Box, ButtonGroup, Tooltip, IconButton } from '@mui/material';
import LineItemLoading from "../../../molecules/LineItemLoading";
import { QuoteLineItemBox } from "../../../atoms/QuoteLineItemBox";
import { styled } from '@mui/system';
import { QuoteConstructionItemForm } from "./BidbookQuoteConstructionItemForm";  
import { ServicesIcon } from '../../../atoms/ServicesIcon';
import { Colors } from '../../../../constants/colors';
import { GET_QUOTE_ITEMS_QUERY } from "../../../../api/graphqlqueries/getquoteitems";
import { DELETE_QUOTE_ITEM_MUTATION, UPDATE_QUOTE_ITEM_MUTATION } from "../../../../api/graphqlqueries/quoteitemmutations";
import { GetConstructionItemQueryResult, GET_CONSTRUCTION_ITEM_QUERY } from "../../../../api/graphqlqueries/getconstquoteitem";
import { v4 } from 'uuid';
import { CREATE_CONSTRUCTION_SERVICE_MUTATION, GET_CONSTRUCTION_SERVICE_QUERY } from '../../../../api/graphqlqueries/constructionserviceitem';
import { QuoteConstructionServiceItem } from './BidbookQuoteConstructionServiceItem';
import { GET_MATERIAL_SERVICE_QUERY } from '../../../../api/graphqlqueries/getmaterialservicequery';
import { CREATE_MATERIAL_ITEM_MUTATION } from '../../../../api/graphqlqueries/materialserviceitemmutations';
import { QuoteMaterialItem } from '../Survey/BidbookQuoteMaterialItem';
import { MaterialIcon } from '../../../atoms/MaterialIcon';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ExpandIcon from '@mui/icons-material/Expand';
import { disableForStatus } from '../../../../stores/ReactiveVariables';

interface QuoteSurveyItemProps { 
    type: string;
    bidId: string;
    id?: string;
    onSave?: () => void; 
}

const StyledIconButton = styled(IconButton)({
    backgroundColor: `${Colors.deepSeaGreen}!important`,
    color: "#fff!important",
    padding:"8px!important",
    borderRadius: 0, 
    "&:hover":{
        backgroundColor: "rgb(23, 53, 53)",
        boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)"
    },
    "&:first-of-type": {
        borderTopLeftRadius : "4px",
        borderBottomLeftRadius : "4px"
    },
    "&:last-child": {
        borderTopRightRadius : "4px",
        borderBottomRightRadius : "4px"
    }
});

const GET_BIDQUOTE_ITEMS_BY_TYPE = gql`
query GetBidQuoteItemsByType($BidQuoteId: uniqueidentifier = "", $Type: String = "") {
    BidQuoteItems(where: {BidQuoteId: {_eq: $BidQuoteId}, Type: {_eq: $Type}, ParentBidQuoteItemId: {_is_null: true}}) {
      Id
      Segment
      ParentBidQuoteItemId
    }
}`;
 
export const QuoteConstructionItem: React.FC<QuoteSurveyItemProps> = (props: QuoteSurveyItemProps) => {
    const { id, bidId, type } = props;
    const [accordionOpen, setAccordionOpen] = useState(true);
    const [addConstructionService] = useMutation(CREATE_CONSTRUCTION_SERVICE_MUTATION);
    const [addMaterialItem] = useMutation(CREATE_MATERIAL_ITEM_MUTATION);
    const [updateItem] = useMutation(UPDATE_QUOTE_ITEM_MUTATION);
    const [deleteItem] = useMutation(DELETE_QUOTE_ITEM_MUTATION);
    const disabledForStatus = useReactiveVar(disableForStatus);
    const { data, error } = useQuery<GetConstructionItemQueryResult>(GET_CONSTRUCTION_ITEM_QUERY, {
        variables: {
            Id: id
        },
        fetchPolicy: 'cache-and-network'
    });

    const quoteItem = data?.BidQuoteItems_by_pk;
    const deleteItemHandler = (Id: string) => {
        deleteItem({
            variables: { Id, BidQuoteId: bidId, Ordinal: quoteItem?.Ordinal ?? 0 },
            refetchQueries: [GET_QUOTE_ITEMS_QUERY]
        })
    }

    const openAccordion = ()=> {
        setAccordionOpen(!accordionOpen);
    }

    const saveNewMaterialItem = (type: string) => () => {
        const newItemId = v4();
        const addInfo = { 
            Id: newItemId.toUpperCase(),
            Markup: type === 'Material' ? 0.21 : 0, // HCS-528
            BidQuoteItemId: id,
            Type: type,
            Ordinal: data?.BidQuoteItems_by_pk?.BidQuoteMaterials?.length ?? 0,
            LineBreakAfter: false,
            OverrideRate: false,
            AdderHasCost: false
        };
        const addInfoReturn = {
            __typename: "BidQuoteMaterials",
            ...addInfo,
            BidQuoteId: null,
            Description: null,
            Rate: null,
            BidQuoteBillable: null,
            BidQuoteBillableId: null,
            Quantity: null,
            Unit: null,
            Duration: null,
            Tax: null,
            GroupWith: null,
            OverrideRate: false,
            LineBreakAfter: false,
            AdderHasCost: null,
            Ordinal: data?.BidQuoteItems_by_pk?.BidQuoteMaterials?.length ?? 0
        };
        addMaterialItem({
            variables: { object: addInfo },
            optimisticResponse: {
                insert_BidQuoteMaterials_one: addInfoReturn
            },
            update: (cache, { data }) => {
                const newBidQuoteRef = cache.writeFragment({
                    fragment: gql`
                        fragment NewMaterial on BidQuoteMaterials {
                            Id
                            BidQuoteItemId
                            BidQuoteId
                            Description
                            Rate
                            Markup
                            Quantity
                            Unit
                            Type
                            Duration
                            Tax
                            GroupWith
                            LineBreakAfter
                        }
                    `,
                    data: data.insert_BidQuoteMaterials_one,
                });
                cache.modify({
                    id: cache.identify(quoteItem as any),
                    fields: {
                        BidQuoteMaterials(existingItems)
                        {
                            return [newBidQuoteRef, ...existingItems];
                        }
                    }

                });

                cache.writeQuery({
                    query: GET_MATERIAL_SERVICE_QUERY,
                    variables: { Id: data.insert_BidQuoteMaterials_one.Id },
                    data: { BidQuoteMaterials: [data.insert_BidQuoteMaterials_one] }
                });
            },
        });
    }

    const saveNewConstructionService = () => {
        const newItemId = v4();
        const addInfo = {
            Id: newItemId.toUpperCase(), 
            BidQuoteItemId: id,
            Ordinal: data?.BidQuoteItems_by_pk?.BidQuoteConstructionServices?.length ?? 0,
            LineBreakAfter: false,
            OverrideRate: false
        };
        const addInfoReturn = {
            __typename: "BidQuoteConstructionServices",
            ...addInfo,
            Type: null,
            Locations: null,
            Average: null,
            Days: null,
            Mob: null,
            MobWoPD: null,
            Setup: null,
            Rest: null,
            Personnel: null,
            Unit: null,
            Rate: null,
            BidQuoteRate: null,
            BidQuoteRateId: null,
            PerDiem: null,
            Equipment: null,
            Mileage: null,
            Discount: null,
            OverrideRate: false,
            LineBreakAfter: false,
            Ordinal: data?.BidQuoteItems_by_pk?.BidQuoteConstructionServices?.length ?? 0
        };
        addConstructionService({
            variables: { object: addInfo },
            optimisticResponse: {
                insert_BidQuoteConstructionServices_one: addInfoReturn
            },
            update: (cache, { data }) => {
                const newBidQuoteRef = cache.writeFragment({
                    fragment: gql`
                        fragment NewConstructionService on BidQuoteConstructionServices {
                            Average
                            Days
                            BidQuoteItemId
                            Discount
                            Equipment
                            Locations
                            Id
                            Mileage
                            Mob
                            MobWoPD
                            PerDiem
                            Personnel
                            Rate
                            Rest
                            Setup
                            Unit
                            LineBreakAfter
                        }
                    `,
                    data: data.insert_BidQuoteConstructionServices_one,
                });
                cache.modify({
                    id: cache.identify(quoteItem as any),
                    fields: {
                        BidQuoteConstructionServices(existingItems)
                        {
                            return [newBidQuoteRef, ...existingItems];
                        }
                    }

                });

                cache.writeQuery({
                    query: GET_CONSTRUCTION_SERVICE_QUERY,
                    variables: { Id: data.insert_BidQuoteConstructionServices_one.Id },
                    data: { BidQuoteConstructionServices_by_pk: data.insert_BidQuoteConstructionServices_one }
                });
            },
        });
    }

    const updateItemHandler = (values: any) => {
        updateItem({ 
            variables: { Id: values.Id, Item: values},
        });
    }

    if (error) {
        console.error(error);
    }
    if (!data) return <LineItemLoading />;

    const styles = {
        lineItemGrid : {
            gap:"2px", 
            alignItems:'center', 
            flexWrap:"nowrap!important"
        },
        accordionIconContainer : {
            alignSelf: 'center', 
            cursor:'pointer',
            '& .MuiSvgIcon-root':{
                color:'#fff', 
                backgroundColor:"rgba(0,0,0,0.6)", 
                borderRadius:1, 
                fontSize:"24px" 
            }
        }
    }

    return(
            <QuoteLineItemBox>
                <Grid item container sx={styles.lineItemGrid}>
                    <Box sx={{flexGrow:"2"}}>
                        <QuoteConstructionItemForm item={quoteItem} onSave={updateItemHandler} onDelete={deleteItemHandler} type={type} />
                    </Box>
                    <Box>
                        <ButtonGroup sx={{m:"2px"}}>
                            <Tooltip describeChild title="Show/Hide Line Item" placement="top">
                                <StyledIconButton
                                    onClick={()=>openAccordion()}  

                                ><ExpandIcon/></StyledIconButton>
                            </Tooltip>
                            <Tooltip describeChild title="Add Material" placement="top">
                                <StyledIconButton
                                    onClick={saveNewMaterialItem("Material")}
                                    disabled={disabledForStatus}
                                ><MaterialIcon/></StyledIconButton>
                            </Tooltip>
                            <Tooltip describeChild title="Add Equipment" placement="top">
                                <StyledIconButton
                                    onClick={saveNewMaterialItem("Equipment")}
                                    disabled={disabledForStatus}
                                ><ServicesIcon/></StyledIconButton>
                            </Tooltip>
                            <Tooltip describeChild title="Add Service" placement="top">
                                <StyledIconButton
                                    onClick={saveNewConstructionService}
                                    disabled={disabledForStatus}
                                ><EngineeringIcon/></StyledIconButton>
                            </Tooltip>
                        </ButtonGroup>
                    </Box>
                </Grid> 
                {
                    accordionOpen && quoteItem?.BidQuoteConstructionServices?.slice().sort((a, b) => a.Ordinal - b.Ordinal).map((bqm: any) => {
                        return <QuoteConstructionServiceItem bidItemId={id} area={bqm.Area} id={bqm.Id} key={bqm.Id}/>
                    })
                }
                                {
                    accordionOpen && quoteItem?.BidQuoteMaterials?.slice().sort((a, b) => a.Ordinal - b.Ordinal).map((bqm: any) => {
                        return <QuoteMaterialItem type={bqm.Type} bidItemId={id} id={bqm.Id} key={bqm.Id}/>
                    })
                }
            </QuoteLineItemBox> 
    )
}
