import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/StoreContext";
import { useReactiveVar } from '@apollo/client';
import { snackMessages } from '../../stores/ReactiveVariables';
import { SnackMessage } from '../../stores/SnackMessage';


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export const Notification: React.FunctionComponent = observer(() => {
    const messages = useReactiveVar(snackMessages);
    const { notificationStore } = useStore();
    const { message, severity, open, hideNotification } = notificationStore;
    const handleHideMessage = (snackMes: SnackMessage) => (event?: any, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        const newMessages = snackMessages().map(m => m.id === snackMes.id ? undefined : m).filter(m => m !== undefined) as SnackMessage[];
        snackMessages(newMessages);
    }

    const isOpen = (snackMes: SnackMessage) => {
        return messages.includes(snackMes);
    }
    return(
        <>
        {
            message &&
            <Snackbar open={open} autoHideDuration={3000} onClose={hideNotification}>
                <Alert onClose={hideNotification} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        }
        {
            messages[0] && 
            <Snackbar key={messages[0].id} open={isOpen(messages[0])} autoHideDuration={messages[0].timeOut ?? 5000} onClose={handleHideMessage(messages[0])}>
                <Alert onClose={handleHideMessage(messages[0])} severity={messages[0].severity} sx={{ width: '100%' }}>
                    { messages[0].message }
                </Alert>
            </Snackbar>
        }
        </>
    )
}) 