import { JobPlanSubSection } from "./JobPlanSubSection"; 
import { KickoffPacketSection } from "../KickoffPacketSection";
import { CurrentSourcesForm } from "./CurrentSourcesForm";
import { AdditionalDocumentsForm } from "./AdditionalDocumentsForm";
import { DataCollectionForm } from "./DataCollectionForm";
import { CompletionReportingForm } from "./CompletionReportingForm";
import { FinalDeliverablesForm } from "./FinalDeliverablesForm";
import { gql, useQuery } from "@apollo/client";

interface JobPlanProps{
    id?: string;
}

const GET_KICKOFF_JOB_PLAN_QUERY = gql`
query GetKickoffJobPlan($Id: uniqueidentifier = "") {
    KickoffPacket_by_pk(Id: $Id) {
      Id
      CustomerRectifiers
      CustomerBonds
      ForeignRectifiers
      ForeignContactList
      InterruptionCycle
      CurrentSourcesNotes
      SSDLocations
      KMZProvided
      AdditionalDocumentDATFiles
      AGOLWebMaps
      ExposuresIncluded
      AdditionalDocumentsNotes
      SharePointLocation
      SVYFiles
      TrimbleFiles
      Waveforms
      UDLFiles
      DailyReport
      DataCollectionDATFiles
      DataCollectionNotes
      CleanSVYFiles
      CurrentSourceLog
      SurveyReport
      WaveformReport
      WorkPermits
      CompletionReportingNotes
      FinalReport
      PDFGraph
      PCSExport
      RawSVYFiles
      FinalUDLFiles
      FinalDATFiles
      FinalWaveform
      FinalCurrentSourceLog
      FinalWorkPermits
      FinalDeliverablesNotes
    }
  }
`;

export const JobPlan: React.FC<JobPlanProps> = ({ id }: JobPlanProps) => {

    const { data, error } = useQuery(GET_KICKOFF_JOB_PLAN_QUERY, {
        variables: {
            Id: id
        }
    });

    if (error) console.log(error);
    if (!data) return null;
    const kickoffPacket = data.KickoffPacket_by_pk;
    return(
        <KickoffPacketSection heading="Job Plan">
            <JobPlanSubSection subheading="Current Sources" subsectionNote="See Detailed Interruption Plan in the Provided Documentation">
                <CurrentSourcesForm currentSources={kickoffPacket}/>
            </JobPlanSubSection>
            <JobPlanSubSection subheading="Additional Documents" subsectionNote="See Additional Documents in the Kickoff Packet">
                <AdditionalDocumentsForm additionalDocuments={kickoffPacket}/>
            </JobPlanSubSection>
            <JobPlanSubSection subheading="Data Collection" subsectionNote="Data is to be Uploaded to the Designated Dropbox Nightly, and Reviewed the Following Day">
                <DataCollectionForm dataCollection={kickoffPacket} />
            </JobPlanSubSection>
            <JobPlanSubSection subheading="Completion Reporting" subsectionNote="Reports and Files to be Finalized 12 Days After the Completion of the Segment">
                <CompletionReportingForm completionReporting={kickoffPacket}/>
            </JobPlanSubSection>
            <JobPlanSubSection subheading="Final Deliverables" subsectionNote="Reports and Files Shall Be Submitted within 30 Days After Completion of the Segment">
                <FinalDeliverablesForm finalDeliverables={kickoffPacket} />
            </JobPlanSubSection>
        </KickoffPacketSection>
    ) 
}