import { v4 } from "uuid";
import { Box, Grid, Stack, Chip, Button, IconButton } from '@mui/material';
import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useFormik } from 'formik';
import HrTextInput from '../../../atoms/HrTextInput';
import * as yup from "yup";
import { HrDecimalInput } from '../../../atoms/HrDecimalInput';


interface PurposeFormProps {
    id: string;
    data: any;
    onSave: any;
    onDelete: any;
}

const validationSchema = yup.object().shape({
    Description: yup.string().required(),
    Miles: yup.number().required()
});

export const PurposeForm: React.FC<PurposeFormProps> = (props: PurposeFormProps) => {
    const { data, onDelete, onSave } = props;

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            Description : "",
            Miles: 0
        },
        onSubmit: (values: any) => {
            formik.setValues({
                Description : "",
                Miles: 0
            });
            const castValues = validationSchema.cast(values);
            onSave({ Id: v4(), ...castValues });
        }
    })


    return (
        <Box sx={{ width: '100%' }}>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={1}>
                    <Grid item xs={8}>
                        <HrTextInput
                            label="Work Item"
                            name="Description"
                            value={formik.values.Description}
                            onChange={formik.handleChange}
                            errors={formik.errors.Description}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <HrDecimalInput
                            label="Miles"
                            name="Miles"
                            value={formik.values.Miles}
                            errors={formik.errors.Miles}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton type='submit'>
                            <AddCircleIcon color="primary" />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Box>
                            {
                                data.map((item: any) => {
                                    return (
                                        <Chip sx={{ mr: .5, mb: .5 }} label={`${item.Description} (${item.Miles} miles)`} onDelete={() => onDelete(item)} />
                                    )
                                })
                            }
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};
