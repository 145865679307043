import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, useCallback } from 'react';
import * as yup from 'yup';
import HrTextInput from '../../../atoms/HrTextInput';
import debounce from '../../../../utils/debounce';

interface ProjectScheduleFormProps {
    id: string;
    data: any;
    onSave: any;
}

const validationSchema = yup.object().shape({
    CoverLetterStartDate: yup.string(),
    CoverLetterCompletionDate: yup.string(),
});


export const ProjectScheduleForm: React.FC<ProjectScheduleFormProps> = (props: ProjectScheduleFormProps) => {
    const { id, data, onSave } = props;

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            CoverLetterStartDate: data?.CoverLetterStartDate ?? "",
            CoverLetterCompletionDate: data?.CoverLetterCompletionDate ?? "",
        },
        onSubmit: (values: any) => {
            const castValues = validationSchema.cast(values);
            onSave(castValues);
        }
    });


    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        try {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value};
            const values = validationSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.handleChange(e);
    };

    const debouncer = useCallback(debounce((values: any) => onSave(values), 2000), []);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
                <Grid item xs={6} lg={12} xl={6}>
                    <HrTextInput
                        label="Project Start Date"
                        name="CoverLetterStartDate"
                        value={formik.values.CoverLetterStartDate}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6} lg={12} xl={6}>
                    <HrTextInput
                        label="Project Completion Date"
                        name="CoverLetterCompletionDate"
                        value={formik.values.CoverLetterCompletionDate}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </form>
    );
};
