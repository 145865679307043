import { useFormik } from "formik";
import React, { useState } from "react";
import HrButton from "../../atoms/HrButton";
import HrTextInput from "../../atoms/HrTextInput";
import HrCheckBox from "../../atoms/HrCheckBox";
import { useQuery, useMutation, gql, useLazyQuery } from "@apollo/client";
import { FormControlLabel, Box, Radio, RadioGroup, FormControl, Grid, Chip, Typography } from "@mui/material";
import { Autocomplete } from "@mui/material";
import * as Yup from "yup";
import { Colors } from "../../../constants/colors";
import { JobStatuses } from "../../../models/job";
import { GET_QUOTE_NAMES } from '../../../api/graphqlqueries/getjobquote';
import { addMessage } from "../../../stores/ReactiveVariables";
import * as uuid from 'uuid';
import { GET_JOB_QUERY } from "./JobSummary";
import { useStore } from "../../../stores/StoreContext";
import Timeline from "@mui/lab/Timeline";
import { StyledHeader } from "../../atoms/StyledHeader";
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { orderBy } from "lodash"

interface JobsTimelineProps {
    timeLineEvents?: ProjectManagerHistoryTimeline[];
}


interface ProjectManagerHistoryTimeline {
    ProjectManager: string;
    ChangedBy: string;
    Timestamp: string;
    SortKey: number;
}

export const JobsTimeline: React.FC<JobsTimelineProps> = ({ timeLineEvents }: JobsTimelineProps) => {
    return (
        <>
        
        {timeLineEvents && timeLineEvents.length > 0 &&
                <Box sx={{ width: "600px" }} paddingLeft={5}>
                    <Grid item>
                        <StyledHeader>Timeline</StyledHeader>
                    </Grid>
                    <Grid item sx={{ maxHeight: "650px", overflowY: "scroll" }}>
                        <Timeline sx={{ margin: 0, padding: 0 }}>
                            {timeLineEvents?.map((item: any, i: number, row: any) => {
                                const lastItem = i + 1 !== row.length;
                                return (
                                    <HrTimeLineEvent key={item.Id} item={item} lastItem={lastItem} />
                                )
                            })}
                        </Timeline>
                    </Grid>
                </Box>
            }
        </>
    )

}


const HrTimeLineEvent = (props: { item: ProjectManagerHistoryTimeline, lastItem: boolean }) => {
    const { item, lastItem } = props;
    const CreateDate = new Date(item.Timestamp);
    return (
        <TimelineItem>
            <TimelineOppositeContent color="text.secondary" sx={styles.oppositeContent} >
                {CreateDate?.toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}<br />
                {CreateDate?.toLocaleTimeString('en-us')}
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot color="secondary">
                    <SyncAltIcon sx={styles.timelineIcon} />
                </TimelineDot>
                {lastItem ? <TimelineConnector /> : null}
            </TimelineSeparator>
            <TimelineContent sx={styles.contentBox}>
                <Grid container>
                    <Grid item xs={11}>
                        <Typography paragraph sx={styles.statusContent}>
                            Project Manager Changed To "{item.ProjectManager}"
                        </Typography>
                        <Typography sx={styles.contentByUser}>By {item.ChangedBy}</Typography>
                    </Grid>
                </Grid>
            </TimelineContent>
        </TimelineItem>
    )
}


const styles = {
    oppositeContent: {
        fontSize: "13px",
        p: "8px"
    },
    contentBox: {
        flexGrow: "4"
    },
    contentByUser: {
        fontSize: "13px",
        color: Colors.darkSeaGreen,
        fontWeight: "600"
    },
    content: {
        margin: 0,
        fontSize: "14px",
        lineHeight: "1.1em"
    },
    iconButton: {
        marginTop: 0
    },
    statusContent: {
        fontSize: "13px",
        color: Colors.gold,
        fontWeight: "600",
        margin: 0
    },
    timelineIcon: {
        fontSize: "14px"
    }
}