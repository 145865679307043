import { Divider, Grid } from "@mui/material";
import React from 'react';
import { useParams } from "react-router";
import { HrCurrencyInput } from "../../../atoms/HrCurrencyInput";
import { HrPercentInput } from "../../../atoms/HrPercentInput";
import { StyledHeader } from '../../../atoms/StyledHeader';
import { GetQuoteAddersTotals } from "./BidbookQuoteAddersTotals";
import { GetQuoteItemTotals } from "./BidbookQuoteItemTotals";
import { GetQuoteSurveyTotals } from "./BidbookQuoteSurveyTotals";
import { Colors } from "../../../../constants/colors";
import HrTextInput from "../../../atoms/HrTextInput";

export const QuoteSummaryTotals: React.FC = () => {
  const params = useParams<{ id: string }>();
  const id = params.id ?? "";

  let { GrandTotal: surveyTotals, TotalCost: surveyCost, TotalPriceWoMaterials: surveyPriceWoMaterials, TotalCostWoMaterials: surveyCostWoMaterials, AverageMiles: averageMilesPerDay, Miles: miles, Days: days } = GetQuoteSurveyTotals(id) ?? { GrandTotal: 0, TotalCost: 0, TotalPriceWoMaterials: 0, TotalCostWoMaterials: 0, AverageMiles: 0, Miles: 0, Days: 0 };
  const { TotalPrice: constructionTotals, TotalCost: constructionCost, TotalPriceWoMaterials: constructionPriceWoMaterials, TotalCostWoMaterials: constructionCostWoMaterials } = GetQuoteItemTotals(id, "Construction") ?? { TotalPrice: 0, TotalCost: 0, TotalPriceWoMaterials: 0, TotalCostWoMaterials: 0 };
  const { TotalPrice: technicianTotals, TotalCost: technicianCost, TotalPriceWoMaterials: technicianPriceWoMaterials, TotalCostWoMaterials: technicianCostWoMaterials } = GetQuoteItemTotals(id, "Technician") ?? { TotalPrice: 0, TotalCost: 0, TotalPriceWoMaterials: 0, TotalCostWoMaterials: 0 };
  const { TotalPrice: inspectionTotals, TotalCost: inspectionCost, TotalPriceWoMaterials: inspectionPriceWoMaterials, TotalCostWoMaterials: inpsectionCostWoMaterials } = GetQuoteItemTotals(id, "Inspection") ?? { TotalPrice: 0, TotalCost: 0, TotalPriceWoMaterials: 0, TotalCostWoMaterials: 0 };

  const adderCalcs = {
    survey: GetQuoteAddersTotals(id, 'Survey'),
    construction: GetQuoteAddersTotals(id, 'Construction'),
    technician: GetQuoteAddersTotals(id, 'Technician'),
    inspection: GetQuoteAddersTotals(id, 'Inspection'),
    lumpSum: GetQuoteAddersTotals(id, 'Lump Sum'),
    ungrouped: GetQuoteAddersTotals(id)
  };

  const addersTotalsByType = {
    survey: adderCalcs.survey?.markupTotalWithTax ?? 0,
    construction: adderCalcs.construction?.markupTotalWithTax ?? 0,
    technician: adderCalcs.technician?.markupTotalWithTax ?? 0,
    inspection: adderCalcs.inspection?.markupTotalWithTax ?? 0,
    lumpSum: adderCalcs.lumpSum?.markupTotalWithTax ?? 0
  };

  const addersCostByType = {
    survey: adderCalcs.survey?.cost ?? 0,
    construction: adderCalcs.construction?.cost ?? 0,
    technician: adderCalcs.technician?.cost ?? 0,
    inspection: adderCalcs.inspection?.cost ?? 0,
    lumpSum: adderCalcs.lumpSum?.cost ?? 0
  };

  const ungroupedAddersTotals = adderCalcs.ungrouped?.markupTotalWithTax ?? 0;
  const ungroupedAddersCost = adderCalcs.ungrouped?.cost ?? 0;

  const addersTotals = ungroupedAddersTotals + addersTotalsByType.survey + addersTotalsByType.construction + addersTotalsByType.technician + addersTotalsByType.inspection + addersTotalsByType.lumpSum;
  const addersCosts = ungroupedAddersCost + addersCostByType.survey + addersCostByType.construction + addersCostByType.technician + addersCostByType.inspection + addersCostByType.lumpSum;
  const quoteTotals: number = surveyTotals + constructionTotals + technicianTotals + inspectionTotals + addersTotals;
  const quoteTotalsWoMaterials: number = (surveyPriceWoMaterials + constructionPriceWoMaterials + technicianPriceWoMaterials + inspectionPriceWoMaterials);
  const quoteTotalCost: number = (surveyCost + constructionCost + technicianCost + inspectionCost + addersCosts);
  const quoteTotalCostWoMaterials: number = (surveyCostWoMaterials + constructionCostWoMaterials + technicianCostWoMaterials + inpsectionCostWoMaterials);
  const expectedProfitMargin: number = (quoteTotals !== 0 ? (quoteTotals - quoteTotalCost) / quoteTotals : 0);
  const expectedProfitMarginWoMaterials: number = (quoteTotalsWoMaterials !== 0 ? (quoteTotalsWoMaterials - quoteTotalCostWoMaterials) / quoteTotalsWoMaterials : 0);

  return (
    <Grid item container spacing={1} columns={10}>
      <Grid item xs={12}>
        <StyledHeader>Bid Totals</StyledHeader>
      </Grid>
      <Grid item xs={2}>
        <HrCurrencyInput
          name='surveyTotal'
          label='Survey Total'
          value={surveyTotals?.toFixed(2).toString()}
          disabled
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      {
        surveyTotals > 0 && (
          <Grid item xs={2}>
            <HrTextInput
              name='AverageMilesPerDay'
              label='Avg Miles Per Day'
              value={days !== 0 ? (miles / days).toFixed(2) : 0}
              disabled
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        )
      }
      {
        surveyTotals > 0 && (
          <Grid item xs={2}>
            <HrCurrencyInput
              name='AverageDiscountedPricePerDay'
              label='Avg Price Per Mile'
              value={averageMilesPerDay != 0 && days != 0 ? `${((surveyTotals / averageMilesPerDay) / days).toFixed(2)}` : '0'}
              disabled
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        )
      }
      <Grid item xs={2}>
        <HrCurrencyInput
          name='constructionTotal'
          label='Construction Total'
          value={constructionTotals?.toString()}
          InputLabelProps={{ shrink: true }}
          disabled
        />
      </Grid>
      <Grid item xs={2}>
        <HrCurrencyInput
          name='technicianTotal'
          label='Technician Total'
          value={technicianTotals?.toString()}
          InputLabelProps={{ shrink: true }}
          disabled
        />
      </Grid>
      <Grid item xs={2}>
        <HrCurrencyInput
          name='inspectorTotal'
          label='Inspection Total'
          value={inspectionTotals?.toString()}
          InputLabelProps={{ shrink: true }}
          disabled
        />
      </Grid>
      <Grid item xs={2}>
        <HrCurrencyInput
          name='addersTotal'
          label='Adders Total'
          value={addersTotals?.toString()}
          disabled
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Divider flexItem />
      <Grid item xs={3}>
        <HrCurrencyInput
          name='bidTotal'
          label='Bid Total'
          value={quoteTotals?.toString()}
          disabled
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      {
        expectedProfitMargin !== expectedProfitMarginWoMaterials &&
        <Grid item xs={3} sx={{ marginLeft: "auto" }}>
          <HrPercentInput
            name='expectedProfitMarginNoMaterials'
            label='Profit Margin W/O Materials'
            value={expectedProfitMarginWoMaterials}
            decimalScale={1}
            disabled
            InputLabelProps={{ shrink: true }}
            sx={{
              "& .MuiInputBase-root": {
                ...(expectedProfitMarginWoMaterials < .45 && {
                  backgroundColor: `${Colors.lightWarningRed}!important`
                }),
                ...(expectedProfitMarginWoMaterials >= .45 && expectedProfitMargin < .5 && {
                  backgroundColor: `${Colors.lightCautionYellow}!important`
                }),
                ...(expectedProfitMarginWoMaterials >= .5 && {
                  backgroundColor: `${Colors.lightSuccessGreen}!important`
                })
              }
            }}
          />
        </Grid>
      }
      <Grid item xs={3} sx={{ marginLeft: "auto" }}>
        <HrPercentInput
          name='expectedProfitMargin'
          label='Expected Profit Margin'
          value={expectedProfitMargin}
          decimalScale={1}
          disabled
          InputLabelProps={{ shrink: true }}
          sx={{
            "& .MuiInputBase-root": {
              ...(expectedProfitMargin < .45 && {
                backgroundColor: `${Colors.lightWarningRed}!important`
              }),
              ...(expectedProfitMargin >= .45 && expectedProfitMargin < .5 && {
                backgroundColor: `${Colors.lightCautionYellow}!important`
              }),
              ...(expectedProfitMargin >= .5 && {
                backgroundColor: `${Colors.lightSuccessGreen}!important`
              })
            }
          }}
        />
      </Grid>
    </Grid>
  )
}