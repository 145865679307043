
import { Grid, Autocomplete, InputAdornment, IconButton, Dialog, DialogContent, Button } from "@mui/material";
import HrTextInput from "../../../../atoms/HrTextInput";
import { AppUser } from "../../../../../api/graphqlqueries/getbidbookusersquery";
import { KickoffPacketContacts, KickoffPacketCustomerContact } from "./ProjectContactsQueries";
import { CustomerContact } from "../../../../../api/graphqlqueries/getclientrepsquery";
import { useFormik } from "formik";
import { v4 } from "uuid";
import HrButton from "../../../../atoms/HrButton";
import { useCallback, useState } from "react";
import debounce from "../../../../../utils/debounce";
import { PersonAdd } from "@mui/icons-material";
import { CustomerContactItem } from "../../../Clients/CustomerContactItem";
import { FormGrid } from "../../../../atoms/FormGrid";
import { Colors } from "../../../../../constants/colors";

interface ProjectContactsFormProps{
    users: AppUser[];
    clientContacts: CustomerContact[];
    id: string;
    kickoffPacket: KickoffPacketContacts;
    onChangePacket: (packet: { key: string, value: string}) => void;
    onChangeCustomerContacts: (contacts: KickoffPacketCustomerContact[]) => void;
}

export const ProjectContactsForm: React.FC<ProjectContactsFormProps> = ({ id, kickoffPacket, users, clientContacts, onChangePacket, onChangeCustomerContacts }: ProjectContactsFormProps) => {

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            HighridgeProjectManager: users.find(u => u.Id === kickoffPacket.HighridgeProjectManagerId),
            ProjectManager: clientContacts.find(c => c.Id === kickoffPacket.ProjectManagerId),
            HighridgeDataAnalyst: users.find(u => u.Id === kickoffPacket.HighridgeDataAnalystId),
            HighridgeFieldPointOfContact: users.find(u => u.Id === kickoffPacket.HighridgeFieldPointOfContactId),
            HighridgeInventorySupervisor: users.find(u => u.Id === kickoffPacket.HighridgeInventorySupervisorId),
            HighridgeSafetyLead: users.find(u => u.Id === kickoffPacket.HighridgeSafetyLeadId),
            ClientContacts: kickoffPacket.KickoffPacketCustomerContacts
                .map(c => ({
                    Id: c.Id,
                    Title: c.Title,
                    Ordinal: c.Ordinal,
                    CustomerContactId: c.CustomerContactId,
                    CustomerContact: clientContacts.find(cc => cc.Id === c.CustomerContactId) ?? null
                }))
        },
        onSubmit: (values) => {
            console.log(values);
        }

    })

    const { HighridgeProjectManager, ProjectManager, HighridgeDataAnalyst, HighridgeFieldPointOfContact, HighridgeInventorySupervisor, HighridgeSafetyLead, ClientContacts } = formik.values;

    const [modalOpen, setModalOpen] = useState(false);
    const [customerContactId, setCustomerContactId] = useState('');

    const openModal = (id: string) => {
        setModalOpen(true);
        setCustomerContactId(id);
    };

    const closeModal = () => setModalOpen(false);
    const closeModalAndRefreshList = (values: any) => {
        const mappedContacts = ClientContacts.map(c => {
            if(c.Id === customerContactId){
                return { ...c, Title: values.Title ? values.Title : "", CustomerContactId: values.Id, CustomerContact: values };
            }
            return c;
        });
        formik.setFieldValue("ClientContacts", mappedContacts);
        debouncedUpdate(mappedContacts);
        setModalOpen(false);
    };

    const handleUserSelect = (name: string) => (event: any, value: any, reason: string, details: any) => {
        onChangePacket({ key: name, value: value.Id });
    };

    const getOptionUser = (option: AppUser) => `${option.LastName}, ${option.FirstName}`;
    const getClientOption = (option: CustomerContact) => `${option.LastName}, ${option.FirstName}`;
    const renderClientContact = (option: KickoffPacketCustomerContact) => <Grid key={option.Id} item container xs={12} spacing={1} alignContent="start">
        <Grid item xs={12} sm={4}>
            <HrTextInput
                id={`ClientContactTitle-${option.Id}`}
                label="Title"
                value={option.Title}
                onChange={handleTitleChange(option.Id)}
            />
        </Grid>
        <Grid item xs={12} sm={8}>
            <Autocomplete
                id={`ClientContactId-${option.Id}`}
                options={clientContacts}
                value={option.CustomerContact}
                getOptionLabel={getClientOption}
                onChange={handleClientContactSelect(option.Id)}
                fullWidth
                renderInput={(params) => (
                    <HrTextInput
                        {...params}
                        label="Client Contact"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton
                                        aria-label="Add Customer Rep"
                                        onClick={() => openModal(option.Id)}
                                    >
                                        <PersonAdd sx={{ color: Colors.deepSeaGreen }} />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                )}
            />
        </Grid>
    </Grid>;


    const handleTitleChange = (id: string) => (event: any) => {
        const newContacts = ClientContacts.map(c => {
            if(c.Id === id){
                return { ...c, Title: event.target.value };
            }
            return c;
        });
        formik.setFieldValue("ClientContacts", newContacts);
        debouncedUpdate(newContacts);
    }

    const handleClientContactSelect = (id: string) => (event: any, value: CustomerContact | null) => {
        const newContacts = ClientContacts.map(c => {
            if(c.Id === id && value !== null){
                return { ...c, Title: value.Title ? value.Title : c.Title, CustomerContactId: value.Id, CustomerContact: value };
            }
            return c;
        });
        formik.setFieldValue("ClientContacts", newContacts);
        debouncedUpdate(newContacts);
    }

    const updateData = (values: KickoffPacketCustomerContact[]) => {
        const updatedValues = values.sort((a, b) => a.Ordinal - b.Ordinal).filter(c => c.CustomerContactId).map((c, i) => ({ ...c, Ordinal: i }));
        onChangeCustomerContacts(updatedValues);
    }

    const debouncedUpdate = useCallback(debounce((values: any) => updateData(values), 500), []);

    const addClientContact = () => {
        const newContact: KickoffPacketCustomerContact = {
            Id: v4(),
            Title: "",
            Ordinal: ClientContacts.length,
            CustomerContactId: "",
            CustomerContact: null
        }
        const newContacts = [...ClientContacts, newContact];
        formik.setFieldValue("ClientContacts", newContacts);
    }

    return(
        <form>
            <Grid container spacing={1}>
                <Grid item container xs={12} sm={4} spacing={1}>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="HighridgeProjectManagerId"
                            options={users}
                            value={HighridgeProjectManager}
                            getOptionLabel={getOptionUser}
                            isOptionEqualToValue={(option, value) => option.Id === value.Id}
                            onChange={handleUserSelect("HighridgeProjectManagerId")}
                            fullWidth
                            renderInput={(params) => (
                                <HrTextInput {...params} label="Highridge Project Manager" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="HighridgeFieldPointOfContactId"
                            options={users}
                            value={HighridgeFieldPointOfContact}
                            getOptionLabel={getOptionUser}
                            onChange={handleUserSelect("HighridgeFieldPointOfContactId")}
                            fullWidth
                            renderInput={(params) => (
                                <HrTextInput {...params} label="Highridge Field POC" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="HighridgeDataAnalystId"
                            options={users}
                            value={HighridgeDataAnalyst}
                            getOptionLabel={getOptionUser}
                            onChange={handleUserSelect("HighridgeDataAnalystId")}
                            fullWidth
                            renderInput={(params) => (
                                <HrTextInput {...params} label="Data Analyst" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="HighridgeSafetyLeadId"
                            options={users}
                            value={HighridgeSafetyLead}
                            getOptionLabel={getOptionUser}
                            onChange={handleUserSelect("HighridgeSafetyLeadId")}
                            fullWidth
                            renderInput={(params) => (
                                <HrTextInput {...params} label="Safety Lead" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="HighridgeInventorySupervisorId"
                            options={users}
                            value={HighridgeInventorySupervisor}
                            getOptionLabel={getOptionUser}
                            onChange={handleUserSelect("HighridgeInventorySupervisorId")}
                            fullWidth
                            renderInput={(params) => (
                                <HrTextInput {...params} label="Inventory Supervisor" />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} sm={8} spacing={1} alignContent="start">
                    <Grid item xs={12}>
                        <Autocomplete
                            id="ProjectManagerId"
                            options={clientContacts}
                            value={ProjectManager}
                            getOptionLabel={getClientOption}
                            onChange={handleUserSelect("ProjectManagerId")}
                            fullWidth
                            renderInput={(params) => (
                                <HrTextInput {...params} label="Client Project Manager" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <HrButton fullWidth onClick={addClientContact}>Add Client Contact</HrButton>
                    </Grid>
                    <Grid item container xs={12} spacing={1}>
                        {
                            ClientContacts.map(cc => renderClientContact(cc))
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                open={modalOpen}
                onClose={closeModal}
            >
                <DialogContent>
                    <Button
                        onClick={() => {
                            setModalOpen(false);
                        }}
                        variant="contained"
                        color="primary"
                        className="modal-exit"
                    >
                        x
                    </Button>
                    <FormGrid>
                        <CustomerContactItem
                            customerId={kickoffPacket.CustomerId}
                            onCancel={closeModal}
                            onSave={closeModalAndRefreshList}
                        />
                    </FormGrid>
                </DialogContent>
            </Dialog>
        </form>
    ) 
}