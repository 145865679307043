import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStore } from "../../../stores/StoreContext";
import { ClientsDataGrid } from './ClientsDataGrid';
import { ContentHeader } from '../ContentHeader';
import { Dialog, Box, DialogContent } from '@mui/material';
import { ClientsForm } from './ClientsForm';
import { ClientsLogoForm } from './ClientLogoForm';


interface Props {
    props?: any;
}

export const ClientsPageContent: React.FC<Props> = observer((props: Props) => {
    const {clientStore, modalStore} = useStore()
    const {getClients, setSelectedClient} = clientStore
    const {clientsModalIsOpen, closeClientsModal, openClientsModal, clientLogoModalIsOpen, closeClientLogoModal } = modalStore;

    useEffect(() => {
        getClients();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <Box sx={{ height: '100%'}}>
        <ContentHeader
            title='Clients'
            onClick={() => {
                setSelectedClient(undefined);
                openClientsModal()
            }}
        />
        <ClientsDataGrid/>
        <Dialog
            open={clientsModalIsOpen}
            onClose={() => closeClientsModal()}
        >
            <DialogContent>
                <ClientsForm/>
            </DialogContent>
        </Dialog>
        <Dialog
            open={clientLogoModalIsOpen}
            onClose={() => closeClientLogoModal()}
        >
            <DialogContent>
                <ClientsLogoForm/>
            </DialogContent>
        </Dialog>
    </Box>
    )
});