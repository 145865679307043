
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, useCallback } from 'react';
import * as yup from 'yup';
import HrTextArea from '../../../atoms/HrTextArea';
import debounce from '../../../../utils/debounce';
import HrTextInput from '../../../atoms/HrTextInput';

interface ExecutionPlanFormProps {
    id: string;
    data: ExecutionPlanFormData;
    onSave: (values: ExecutionPlanFormData) => void;
}

export interface ExecutionPlanFormData{
    CoverLetterFieldStaff?: string;
    CoverLetterSupportStaff?: string;
    CoverLetterProjectName?: string;
}

const validationSchema = yup.object().shape({
    CoverLetterFieldStaff: yup.string(),
    CoverLetterSupportStaff: yup.string(),
    CoverLetterProjectName: yup.string()
});


export const ExecutionPlanForm: React.FC<ExecutionPlanFormProps> = ({ id, data, onSave }: ExecutionPlanFormProps) => {
    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            CoverLetterFieldStaff: data?.CoverLetterFieldStaff ?? "",
            CoverLetterSupportStaff: data?.CoverLetterSupportStaff ?? "",
            CoverLetterProjectName: data?.CoverLetterProjectName ?? ""
        },
        onSubmit: (values: any) => {
            const castValues : ExecutionPlanFormData = validationSchema.cast(values) as ExecutionPlanFormData;
            onSave(castValues);
        }
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        try {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value};
            const values = validationSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.handleChange(e);
    };

    const debouncer = useCallback(debounce((values: any) => onSave(values), 2000), []);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <HrTextInput
                        label="Cover Letter Project Name"
                        name="CoverLetterProjectName"
                        value={formik.values.CoverLetterProjectName}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6} lg={12} xl={6}>
                    <HrTextArea
                        label="Estimated Field Staff Description"
                        name="CoverLetterFieldStaff"
                        value={formik.values.CoverLetterFieldStaff}
                        onChange={handleChange}
                        multiline
                        minRows={3}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6} lg={12} xl={6}>
                    <HrTextArea
                        label="Estimated Support Staff Description"
                        name="CoverLetterSupportStaff"
                        value={formik.values.CoverLetterSupportStaff}
                        onChange={handleChange}
                        multiline
                        minRows={3}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </form>
    );
};
