import { gql } from '@apollo/client';

export interface BidQuoteStatus {
 Status: string;
}


export interface GetQuoteStatus {
    BidQuoteStatus: BidQuoteStatus[];
}

export const GET_QUOTE_STATUS_QUERY = gql`
query GetStatus($BidQuoteId: uniqueidentifier = "") {
    BidQuoteStatus(where: {BidQuoteId: {_eq: $BidQuoteId}}, limit: 1, order_by: {CreateDate: desc}) {
        Status
    }
}
`;

