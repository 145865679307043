
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { addMessage } from "../../../../stores/ReactiveVariables";
import { Box, CircularProgress, Grid } from "@mui/material";
import { CoverLetterAccordion } from "./CoverLetterAccordion";

interface BidbookCoverLetterProps {
    id: string;
}

export const BidbookCoverLetter: React.FC<BidbookCoverLetterProps> = (props) => {
    const { id } = props;
    const [coverLetterSrc, setCoverLetterSrc] = useState<string>("");

    const getCoverLetter = useCallback(() => {
        axios.get(`/report/${id}/coverletter`, {
            headers: {
                'Content-Type': 'application/pdf'
            },
            responseType: 'blob', // important
        }).then((response) => {
            const data = window.URL.createObjectURL(response.data)
            setCoverLetterSrc(data);
        }).catch((error) => {
            addMessage("Error Loading Cover Letter", { severity: 'error' });
        });
    }, [id, setCoverLetterSrc]);

    useEffect(() => {
        getCoverLetter();    
    }, [getCoverLetter]);

    return (
        <Box>
            <Grid container spacing={{ xs: 1, xl: 2 }} direction={{md: "row-reverse"}}>
                <Grid item sm={12} lg={4}> 
                    <CoverLetterAccordion id={id} getCoverLetter={getCoverLetter} />
                </Grid>
                <Grid item sm={12} lg={8}>
                    <Box sx={{ display: "block", position: "relative", width:"100%" }}>
                        {!coverLetterSrc && <CircularProgress size={"80px"} sx={{ position:"absolute", transform:"translateX(-50%)", left:"50%", mt:"25%"}} />}
                        {coverLetterSrc !== "" && (
                            <iframe id="iframe" src={coverLetterSrc} title={"Cover Letter"} width="100%" height="1000" />
                        )}
                        
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}