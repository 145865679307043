//COVERLETTERACCORDIONPANEL

import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";
import HrCheckBox from "../../../atoms/HrCheckBox";
import { Colors } from "../../../../constants/colors";
import { SyntheticEvent } from "react";

interface CoverLetterAccordionProps {
    activePanel: string | false;
    panel: string;
    handleChange: any;
    panelLabel: string;
    form?: any;
    checkbox?: any;
}

export const CoverLetterAccordionPanel = (props: CoverLetterAccordionProps) => {
    const { activePanel, panel, handleChange, panelLabel, checkbox } = props;
    return (
        <Accordion
            disableGutters={true}
            expanded={activePanel === panel}
            onChange={handleChange(panel)}
            sx={{ backgroundColor: Colors.deepSeaGreen }}
        >
            <AccordionSummary
                expandIcon={props.form && <ExpandMoreOutlined sx={{ color: Colors.white }} />}
                aria-controls={`${panel}a-content`}
                id={`${panel}a-header`}
            >
                <Grid container alignItems="center">
                    <Grid item>
                        {props.checkbox}
                    </Grid>
                    <Grid item>
                        <Typography
                            color={Colors.white}
                            variant="body2"
                        >
                            {panelLabel}
                        </Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            {
                props.form && (
                    <AccordionDetails
                        sx={{ backgroundColor: Colors.white, paddingTop:2 }}
                    >
                        {props.form}
                    </AccordionDetails>
                )
            }
        </Accordion>
    )
}
