import { Grid, Autocomplete, Chip, Button, Dialog, DialogContent, IconButton, InputAdornment } from "@mui/material";
import { useFormik } from "formik";
import HrTextInput from "../../../atoms/HrTextInput";
import * as yup from 'yup';
import { ChangeEvent, useCallback, useState } from "react";
import debounce from '../../../../utils/debounce';
import HrDateTimePicker from "../../../atoms/HrDateTimePicker";
import moment from "moment";
import { gql, useQuery } from "@apollo/client";
import { FormGrid } from "../../../atoms/FormGrid";
import { CustomerContactItem } from "../../Clients/CustomerContactItem";
import { PersonAdd } from "@mui/icons-material";
import { Colors } from "../../../../constants/colors";

interface ProjectDetailsFormProps {
    jobId?: string;
    kickoffId?: string;
    bidQuoteData: any;
    kickoffPacketData: any;
    onSave: any;
    addProjectType: any;
    deleteProjectType: any;
    jobData: any;
}

const projectDetailsSchema = yup.object().shape({
    Descriptor: yup.string(),
    AFE: yup.string(),
    ProjectMobDate: yup.date().nullable(),
    ProjectKickoffDate: yup.date().nullable(),
    KickoffLocation: yup.string(),
    ProjectTypeId: yup.string().nullable(),
    ProjectManagerId: yup.string().nullable(),
    ProjectEndDate: yup.date().nullable()
});

const GET_SERVICE_CATEGORIES_QUERY = gql`
query GetServiceCategories($BidQuoteId: uniqueidentifier = "") {
    BidQuoteServiceCategories(where: {BidQuoteId: {_eq: $BidQuoteId}}) {
        Id
        Name
    }
}  
`



export const ProjectDetailsForm: React.FC<ProjectDetailsFormProps> = ({ jobId, kickoffId, kickoffPacketData, jobData, bidQuoteData, onSave, addProjectType, deleteProjectType }: ProjectDetailsFormProps) => {
    const [modalOpen, setModalOpen] = useState(false);
    const { data: projectTypeData } = useQuery(GET_SERVICE_CATEGORIES_QUERY, {
        variables: {
            BidQuoteId: bidQuoteData?.Id
        },
        fetchPolicy: 'cache-and-network'
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: projectDetailsSchema,
        initialValues: {
            AFE: kickoffPacketData?.AFE ?? "",
            ProjectMobDate: kickoffPacketData?.ProjectMobDate ? moment.tz(kickoffPacketData?.ProjectMobDate, "UTC") : null,
            ProjectName: kickoffPacketData?.ProjectName ?? "",
            Descriptor: kickoffPacketData?.Descriptor ?? "",
            ProjectKickoffDate: kickoffPacketData?.ProjectKickoffDate ? moment.tz(kickoffPacketData?.ProjectKickoffDate, "UTC") : null,
            KickoffLocation: kickoffPacketData?.KickoffLocation ?? "",
            CustomerId: bidQuoteData?.Customer?.Id?.toLowerCase() ?? null,
            ProjectManagerId: kickoffPacketData?.ProjectManagerId?.toLowerCase() ?? null,
            ProjectEndDate: jobData?.EndDate && Date.parse(jobData?.EndDate) > -62135575764000 ? jobData?.EndDate : null
        },
        onSubmit: (values) => {
            const castValues = projectDetailsSchema.cast(values);
            onSave(castValues);
        }
    });

    const toggleModal = () => setModalOpen(t => !t);
    const closeModalAndRefreshList = (values: any) => {
        setModalOpen(false);
        handleProjectManagerSelect(values);
    }

    const handleProjectManagerSelect = (values: any) => {
        const updatedValues = { ...formik.values, ProjectManagerId: values?.Id?.toLowerCase() ?? null };
        const castVals = projectDetailsSchema.cast(updatedValues);
        debouncer(castVals);
        formik.setFieldValue("ProjectManagerId", values?.Id?.toLowerCase() ?? null);
    }

    const projectLength = kickoffPacketData?.JobSegments.reduce((total: number, currentItem: any) => {
        return total += currentItem?.BidQuoteItem.Miles ?? 0;
    }, 0) ?? 0;

    const debouncer = useCallback(debounce((values: any) => onSave(values), 2000), []);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        try {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value };
            const values = projectDetailsSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.handleChange(e);
    };

    const handleDatePicker = (name: string) => (date: moment.Moment | null) => {
        const updatedValues = { ...formik.values, [name]: date?.utc(true).toISOString() ?? null };
        debouncer(updatedValues);
        formik.setFieldValue(name, date);
    }

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <HrTextInput
                        label="Client Name"
                        value={bidQuoteData?.Customer?.Name ?? ""}
                        name="CustomerName"
                        fullWidth
                        disabled
                        InputLabelProps={{ shrink: true }}
                    />

                </Grid>
                <Grid item xs={6}>
                    <HrTextInput
                        label="Contract Type"
                        value={bidQuoteData?.TimeAndMaterials !== true ? "Bid" : "Time and Materials"}
                        name="ContractType"
                        fullWidth
                        disabled
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <HrTextInput
                        label="Project Name"
                        value={formik.values.ProjectName}
                        fullWidth
                        disabled
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <HrTextInput
                        label="Project Length"
                        value={projectLength ?? 0}
                        fullWidth
                        disabled
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <HrTextInput
                        label="Descriptor"
                        value={formik.values.Descriptor}
                        name="Descriptor"
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <HrDateTimePicker
                        name="ProjectMobDate"
                        onChange={(date: any) => formik.setFieldValue('ProjectMobDate', date)}
                        onAccept={handleDatePicker('ProjectMobDate')}
                        value={formik.values.ProjectMobDate}
                        label="Project MOB Date"
                        fullWidth={true}
                    />
                </Grid>
                <Grid item xs={6}>
                    <HrTextInput
                        label="Project AFE/PO"
                        value={formik.values.AFE}
                        name="AFE"
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <HrDateTimePicker
                        name="ProjectKickoffDate"
                        onChange={(date: any) => formik.setFieldValue('ProjectKickoffDate', date)}
                        onAccept={handleDatePicker('ProjectKickoffDate')}
                        value={formik.values.ProjectKickoffDate}
                        label="Project Kickoff Date/Time"
                        fullWidth={true}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        id="clientProjectManager"
                        options={bidQuoteData?.Customer?.CustomerContacts ?? []}
                        value={bidQuoteData?.Customer?.CustomerContacts.find((cc: any) => cc.Id.toLowerCase() === formik.values.ProjectManagerId) ?? ""}
                        getOptionLabel={(option: any) => option ? `${option.LastName}, ${option.FirstName}` : ""}
                        onChange={(e, v) => handleProjectManagerSelect(v)}
                        fullWidth
                        renderInput={(params) => (
                            <HrTextInput
                                {...params}
                                label="Client Project Manager"
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                aria-label="Add Customer Rep"
                                                onClick={() => setModalOpen(true)}
                                            >
                                                <PersonAdd sx={{ color: Colors.deepSeaGreen }} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <HrDateTimePicker
                        name="ProjectEndDate"
                        onChange={(date: any) => formik.setFieldValue('ProjectEndDate', date)}
                        onAccept={handleDatePicker('ProjectEndDate')}
                        value={formik.values.ProjectEndDate}
                        label="Project End Date/Time"
                        fullWidth={true}
                    />
                </Grid>
                <Grid item xs={6}>
                    <HrTextInput
                        label="Project Kickoff Location"
                        value={formik.values.KickoffLocation}
                        name="KickoffLocation"
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        id="projectType"
                        options={projectTypeData?.BidQuoteServiceCategories.filter((bqcs: any)=>{
                            return kickoffPacketData?.KickoffPacketProjectTypes.some((projectType: any) => {
                                return projectType.ServiceCategoryId === bqcs.Id
                            }) === false;
                        }) ?? []}
                        value={kickoffPacketData?.KickoffPacketProjectTypes ?? []}
                        multiple
                        getOptionLabel={(option: any) => option.Name}
                        onChange={(event, newValue, reason, detail) => {
                            if (reason === "removeOption") {
                                deleteProjectType(detail?.option?.Id);
                            } else {
                                addProjectType(detail?.option?.Id);
                            }
                        }}
                        fullWidth
                        renderInput={(params) => (
                            <HrTextInput {...params} label="Project Type" />
                        )}
                        renderTags={(value: readonly string[], getTagProps) =>
                            value.map((option: any, index: number) => {
                                return <Chip 
                                        label={option?.ServiceCategory?.Name} 
                                        {...getTagProps({ index })} 
                                    />
                            })
                        }
                        disableClearable={true}
                    />
                </Grid>
                <Grid item xs={6}>
                    <HrTextInput
                        label="Quote Number"
                        value={bidQuoteData?.JobNumber ?? ""}
                        name="QuoteNumber"
                        fullWidth
                        disabled
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Grid>
            <Dialog
                open={modalOpen}
                onClose={toggleModal}
            >
                <DialogContent>
                    <Button
                        onClick={() => {
                            setModalOpen(false);
                        }}
                        variant="contained"
                        color="primary"
                        className="modal-exit"
                    >
                        x
                    </Button>
                    <FormGrid>
                        <CustomerContactItem
                            customerId={bidQuoteData.CustomerId}
                            onCancel={toggleModal}
                            onSave={closeModalAndRefreshList}
                        />
                    </FormGrid>
                </DialogContent>
            </Dialog>
        </form>
    )
}