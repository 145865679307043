import { useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import { GetQuoteItemsQueryResult, GET_QUOTE_ITEMS_QUERY } from "../../../../api/graphqlqueries/getquoteitems";
import dollarUS from "../../../../utils/CurrencyFormat";
import { StyledHeader } from "../../../atoms/StyledHeader";
import { BidbookDiscountAllLinesButton } from "../../../molecules/BidbookDiscountAllLinesButton";
import { RateHeading } from "../../../molecules/BidbookRateHeading";
import LineItemLoading from "../../../molecules/LineItemLoading";
import { QuoteAddersRates } from "./BidbookQuoteAddersRates";
import { QuoteRate } from "./BidbookQuoteRate";

interface QuoteLineItemsProps {
    bidQuoteId: string;
    type : string;
    total : any;
}

export const QuoteRates = ({ bidQuoteId, type, total }: QuoteLineItemsProps) => {
    const { loading, data, error } = useQuery<GetQuoteItemsQueryResult>(GET_QUOTE_ITEMS_QUERY, {
        variables: {
            BidQuoteId: bidQuoteId,
            Type: type
        },
        fetchPolicy: 'cache-and-network',
    });



    if (error) {
        console.error(error);
    }
    if (loading && !data) return <LineItemLoading />

    const quoteItems = data?.BidQuoteItems ?? [];
    if(quoteItems?.length === 0){
        return null
    }
    
    const itemsList = quoteItems.filter((qi: any) => qi.Type === type).sort((a: any, b: any) => a.Ordinal - b.Ordinal)

    return (
        <Grid item container>
            <RateHeading>
                <Grid item alignSelf="flex-end">
                    <StyledHeader>{type}</StyledHeader>
                </Grid>
                <Grid item>
                    <BidbookDiscountAllLinesButton type={type} bidQuoteId={bidQuoteId} />
                </Grid>
                <Grid item alignSelf="flex-end" sx={{marginLeft:"auto"}}>
                    <StyledHeader>{dollarUS.format(total)}</StyledHeader>
                </Grid>
            </RateHeading>
            <Grid item container>
                { itemsList?.sort((a, b) => a.Ordinal - b.Ordinal).map((si: any) => <QuoteRate bidQuoteId={bidQuoteId} key={si.Id  ?? 'new'} id={si.Id} type={type} total={total}/>)}
            </Grid>
        </Grid>
    )
}