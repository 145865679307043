import { gql } from "@apollo/client/core";


export interface GetQuoteFactorsQueryResult {
  BidQuotes_by_pk: BidQuoteFactors;
}

export interface BidQuoteFactors {
  Id: string;
  OfficeHours: number;
  FieldHours: number;
  SurveyFieldHours: number;
  MileageRate: number;
  PerDiemRate: number;
  PerDiemCost?: number;
  TimecardMileageRate?: number;
  SurveyMileage?: number;
}

export const GET_QUOTE_FACTORS_QUERY = gql`
  query GetQuoteDefaults($Id: uniqueidentifier = "") {
    BidQuotes_by_pk(Id: $Id) {
      Id
      OfficeHours
      FieldHours
      SurveyFieldHours
      MileageRate
      PerDiemRate
      PerDiemCost
      TimecardMileageRate
      SurveyMileage
    }
  }
`;