import React, { useEffect, useState } from 'react';
import { Box } from "@mui/material";
import axios from "axios";

export const TableauViz = (props) => {
    const [token, setToken] = useState("");
    useEffect(() => {
        axios.get(`/report/tableautoken`).then((response) => {
            setToken(response.data.token);
        })
    }, []);
    return (
        <Box>
            <tableau-viz id="tableauViz"
                src={props.src}
                token={token}
                toolbar="top" 
                height="80vh"
                width="100%"
                hide-tabs>
            </tableau-viz>
        </Box>
    );
}
