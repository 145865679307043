import { gql } from "@apollo/client/core";

export interface GetQuoteSummaryQueryResults {
  BidQuotes_by_pk: BidQuote;
}

interface BidQuote {
  Id: string;
  ClientScopeOfWorkReviewed: boolean;
  CreateDate: Date;
  CreateUser: AppUser;
  BidQuoteStatus: BidQuoteStatus[];
  BidQuoteExternalRevisions: BidQuoteExternalRevision[];
  Customer: Customer;
  JobNumber: number;
  BidBookUserId: string;
  BidDescription: string;
  Budgetary: boolean;
  BusinessLeadUserId: string;
  BidQuoteAnalystUserId: string;
  CoverLetter: boolean;
  KmzReview: boolean;
  TimeAndMaterials: boolean;
  GpsPointsReview: boolean;
  ProjectAreaReview: boolean;
  SpecialEquipmentNeeded: boolean;
  CustomerContactId: string;
  CustomerId: string;
  DeletedUserId: string;
  DidNotReceive: boolean;
  DocumentReceivedDate: string;
  DueDate: string;
  JobCity: string;
  JobState: string;
  Zip: string;
  ReceivedDate: string;
  SpecialInstructions: string;
  UpdateDate: Date;
  UpdateUserId: string;
  OfficeHours: number;
  FieldHours: number;
  SurveyFieldHours: number;
  MileageRate: number;
  PerDiemRate: number;
  PreparedByTitle: string;
  PreparedByUserId: string;
  DaysValidAfterSubmit: number;
  ReviewedByDirectorOfOperations: boolean;
  ReviewedByVPOfOperations: boolean;
  ProjectManagerUserId: string;
  ExpectedQuarter: string;
  ExpectedYear: number;
  SurveyMileage: number;
}

interface AppUser {
  Id: string;
  FirstName: string;
  LastName: string;
}

interface BidQuoteStatus {
  Id: string;
  Status: string;
}

interface BidQuoteExternalRevision {
  ExternalRevision: number;
  Id: string;
  CreateDate: Date;
  CreateUserId: string;
}

interface Customer {
  Id: string;
  Name: string;
  ShortCode: string;
}

export const GET_QUOTE_SUMMARY_QUERY = gql`
  query GetQuoteSummary($Id: uniqueidentifier = "") {
    BidQuotes_by_pk(Id: $Id) {
      Id
      ClientScopeOfWorkReviewed
      CreateDate
      CreateUser {
        Id
        FirstName
        LastName
      }
      BidQuoteStatus(limit: 1, order_by: {CreateDate: desc}) {
        Id
        Status
      }
      BidQuoteExternalRevisions(limit: 1, order_by: {CreateDate: desc}) {
          ExternalRevision
          Id
          CreateDate
          CreateUserId
        }
      Customer {
        Id
        Name
        ShortCode
      }
      BidBookUserId
      BidDescription
      Budgetary
      BusinessLeadUserId
      BidQuoteAnalystUserId
      CoverLetter
      KmzReview
      TimeAndMaterials
      GpsPointsReview
      ProjectAreaReview
      SpecialEquipmentNeeded
      CustomerContactId
      CustomerId
      DocumentReceivedDate
      DueDate
      JobCity
      JobNumber
      JobState
      Zip
      ReceivedDate
      SpecialInstructions
      UpdateDate
      UpdateUserId
      OfficeHours
      FieldHours
      SurveyFieldHours
      MileageRate
      PerDiemRate
      PreparedByTitle
      PreparedByUserId
      DaysValidAfterSubmit
      ReviewedByDirectorOfOperations
      ReviewedByVPOfOperations
      ProjectManagerUserId
      ExpectedQuarter
      ExpectedYear
      SurveyMileage
    }
  }
`;