import { useFormik } from "formik";
import React, { useState } from "react";
import HrButton from "../../atoms/HrButton";
import HrTextInput from "../../atoms/HrTextInput";
import HrCheckBox from "../../atoms/HrCheckBox";
import { useQuery, useMutation, gql, useLazyQuery } from "@apollo/client";
import { FormControlLabel, Box, Radio, RadioGroup, FormControl, Grid, Chip, Typography } from "@mui/material";
import { Autocomplete } from "@mui/material";
import * as Yup from "yup";
import { Colors } from "../../../constants/colors";
import { JobStatuses } from "../../../models/job";
import { GET_QUOTE_NAMES } from '../../../api/graphqlqueries/getjobquote';
import { addMessage } from "../../../stores/ReactiveVariables";
import * as uuid from 'uuid';
import { GET_JOB_QUERY } from "./JobSummary";
import { useStore } from "../../../stores/StoreContext";
import Timeline from "@mui/lab/Timeline";
import { StyledHeader } from "../../atoms/StyledHeader";
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { orderBy } from "lodash"
import { JobsTimeline } from "./JobsTimeline";

interface JobSummaryFormProps {
    id?: string;
    job: Job;
    onSave: (values: any) => void;
}

interface BidQuoteExternalRevision {
    Id: string;
    ExternalRevision: number;
}

interface BidQuoteStatus {
    Id: string;
    Status: string;
    CreateDate: Date;
}

interface Quote {
    Id: string;
    BidDescription: string;
    BidQuoteExternalRevisions: BidQuoteExternalRevision[];
    JobNumber: number;
    BidQuoteStatus: BidQuoteStatus[];
    QuoteCustomer: Customer;
}

interface Job {
    Id: string;
    AppUserJobs: AppUser[];
    City: string;
    State: string;
    Zip: string;
    Customer: Customer;
    ProjectManager: AppUser;
    ProjectManagerId: string;
    CustomerId: string;
    ExpectedMiles: number;
    ExpectedUnits: number;
    JobSegment: JobSegment[];
    KickoffPacket: KickoffPacket;
    Name: string;
    ReqDistanceCompleted: boolean;
    ReqUnitsCompleted: boolean;
    ReqPerDiem: boolean;
    ReqSegments: boolean;
    isMileageBillable: boolean;
    Status: string;
}

interface KickoffPacket {
    Id: string;
    KickoffPacketProjectTypes: KickoffPacketProjectType[];
}

interface KickoffPacketProjectType {
    Id: string;
    ServiceCategory: ServiceCategory;
}

interface ServiceCategory {
    Id: string;
    Name: string;
}

interface JobSegment {
    Id: string;
    BidQuoteId: string;
    BidQuoteItemId: string;
    JobId: string;
    BidQuote: Quote;
}

interface Customer {
    Id: string;
    Name: string;
    ShortCode: string;
}

interface AppUser {
    Id: string;
    JobsId: string;
}

interface BidQuote {
    Id: string;
    JobId: string;
    BidDescription: string;
    JobCity: string;
    JobState: string;
    Zip: string;
    CustomerId: string;
    TimeAndMaterials: boolean;
    JobNumber: number;
}


interface ProjectManagerHistoryTimeline {
    ProjectManager: string;
    ChangedBy: string;
    Timestamp: string;
    SortKey: number;
}


interface ProjectManagerHistory {
    Id: string;
    ProjectManagerId: string;
    ChangedById: string;
    Timestamp: string;
    JobId: string;
}

const GET_USERS = gql`
query GetUsers {
  AspNetUsers(where: {IsActive: {_eq: true}}) {
    Id
    FirstName
    LastName
  }
}
`

const ADD_JOB_USER = gql`
mutation InsertJobUser($object: [AppUserJobs_insert_input!] = {}) {
  insert_AppUserJobs(objects: $object) {
    returning {
      AppUsersId
      JobsId
    }
  }
}
`

const DELETE_JOB_USER = gql`
mutation DeleteJobUser($UserId: String = "", $JobId: uniqueidentifier = "") {
  delete_AppUserJobs(where: {AppUsersId: {_eq: $UserId}, JobsId: {_eq: $JobId}}) {
    affected_rows
  }
}
`

export const INSERT_JOB_SEGMENT_MUTATION = gql`
mutation InsertJobSegment($object: JobSegments_insert_input = {}) {
    insert_JobSegments_one(object: $object) {
        Id
    }
}  
`;

const ADD_PROJECT_HISTORY = gql`
mutation InsertJobProjectManagerHistories($object: JobProjectManagerHistories_insert_input = {}) {
    insert_JobProjectManagerHistories_one(object: $object) {
        Id
        ProjectManagerId
        ChangedById
        Timestamp
        JobId
    }
}
`;

const GET_PROJECT_HISTORY = gql`
query GetJobProjectManagerHistories($JobId: uniqueidentifier = "") {
    JobProjectManagerHistories(where: {JobId: {_eq: $JobId}}) {
        Id
        ProjectManagerId
        ChangedById
        Timestamp
        JobId
    }
}  
`

const GET_JOB_SEGMENT_QUERY = gql`
query GetJobSegments($JobId: uniqueidentifier = "") {
    JobSegments(where: {JobId: {_eq: $JobId}}) {
        Id
    }
}  
`

const DELETE_JOB_SEGMENTS_MUTATION = gql`
mutation DeleteJobSegments($JobId: uniqueidentifier = "") {
    delete_JobSegments(where: {JobId: {_eq: $JobId}}) {
        affected_rows
    }
}`;

const DELETE_JOB_SEGMENT_MUTATION = gql`
mutation DeleteJobSegment($Id: uniqueidentifier = "") {
    delete_JobSegments(where: {Id: {_eq: $Id}}) {
      affected_rows
    }
}`;

const GET_BID_QUOTE_ITEMS_QUERY = gql`
query GetBidQuoteItemsQuery($BidQuoteId: uniqueidentifier = "") {
    BidQuoteItems(where: {BidQuoteId: {_eq: $BidQuoteId}}) {
        Id
        Segment
    }
}
`;

export const JobSummaryForm: React.FC<JobSummaryFormProps> = ({ id, job, onSave }: JobSummaryFormProps) => {
    const { userStore } = useStore();

    const { data: nameData } = useQuery(GET_QUOTE_NAMES, { fetchPolicy: 'cache-and-network' });
    const [bidQuoteItemOptions, setBidQuoteItemOptions] = useState<Array<any>>([]);
    const [timeLineEvents, setTimeLineEvents] = useState<Array<ProjectManagerHistoryTimeline>>([]);

    const jobsBidQuoteId: string = job?.JobSegment ? (job?.JobSegment[0]?.BidQuoteId ?? "") : "";

    const jobSegments = job?.JobSegment ?? [];

    const { data: users } = useQuery(GET_USERS, { fetchPolicy: 'cache-and-network' });

    const { data: bidQuoteItemsDataOnLoad } = useQuery(GET_BID_QUOTE_ITEMS_QUERY, {
        variables: {
            BidQuoteId: jobsBidQuoteId
        },
        onCompleted: (data) => {
            setBidQuoteItemOptions(data?.BidQuoteItems);
        }
    });

    const [addJobUser] = useMutation(ADD_JOB_USER);

    const [deleteJobUser] = useMutation(DELETE_JOB_USER);

    const [insertJobSegment] = useMutation(INSERT_JOB_SEGMENT_MUTATION);

    const [getQuoteJobSegments] = useLazyQuery(GET_JOB_SEGMENT_QUERY);

    const [deleteQuoteJobSegments] = useMutation(DELETE_JOB_SEGMENTS_MUTATION);

    const [deleteQuoteJobSegment] = useMutation(DELETE_JOB_SEGMENT_MUTATION);

    const [addProjectHistory] = useMutation(ADD_PROJECT_HISTORY);
    useQuery(GET_PROJECT_HISTORY, {
        variables: {
            JobId: job?.Id
        },
        onCompleted: (data) => {
            const timeLineEventsList: ProjectManagerHistoryTimeline[] = [];
            data.JobProjectManagerHistories.forEach((element: ProjectManagerHistory) => {
                const timelineItem: ProjectManagerHistoryTimeline = {
                    ProjectManager: element.ProjectManagerId,
                    ChangedBy: element.ChangedById,
                    Timestamp: element.Timestamp,
                    SortKey: new Date(element.Timestamp).getTime()
                }

                const projectManager = users?.AspNetUsers?.find((user: AppUser) => {
                    return user?.Id === element.ProjectManagerId
                })
                if (projectManager) {
                    timelineItem.ProjectManager = `${projectManager.FirstName} ${projectManager.LastName}`;
                }

                const changedBy = users?.AspNetUsers?.find((user: AppUser) => {
                    return user?.Id === element.ChangedById
                })
                if (changedBy) {
                    timelineItem.ChangedBy = `${changedBy.FirstName} ${changedBy.LastName}`;
                }
                timeLineEventsList.push(timelineItem);
            });
            setTimeLineEvents(orderBy(timeLineEventsList, ['SortKey'], ['desc']));
        }
    });

    const [getBidQuoteItemsQuery, { data: bidQuoteItemsData }] = useLazyQuery(GET_BID_QUOTE_ITEMS_QUERY);

    const awardedAndActiveQuoteList: Quote[] = nameData?.BidQuotes?.filter((bq: Quote) => {
        return (bq?.BidQuoteStatus[0]?.Status === "Active" || bq?.BidQuoteStatus[0]?.Status === "Awarded" || bq?.BidQuoteStatus[0]?.Status === "Archived" || bq?.BidQuoteStatus[0]?.Status === "Archived" || bq?.BidQuoteStatus[0]?.Status === "Scheduled");
    });

    let quoteList = [] as Quote[];
    if (awardedAndActiveQuoteList)
        quoteList = job?.JobSegment && job?.JobSegment[0]?.BidQuote ? [...awardedAndActiveQuoteList, job?.JobSegment[0]?.BidQuote] : awardedAndActiveQuoteList;

    const validationSchema = Yup.object({
        Name: Yup.string().required("The job name is required"),
        Customer: Yup.object().required("A valid customer is required"),
        City: Yup.string().required("A valid city is required"),
        State: Yup.string().required("A valid state is required"),
        Zip: Yup.string().required("A valid ZIP Code is required"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: job?.Id,
            Name: job?.Name,
            ReqDistanceCompleted: job?.ReqDistanceCompleted,
            ReqUnitsCompleted: job?.ReqUnitsCompleted,
            ReqPerDiem: job?.ReqPerDiem ?? false,
            ReqSegments: job?.ReqSegments ?? false,
            Customer: job?.Customer,
            ProjectManagerId: job?.ProjectManagerId,
            appUsers: job?.AppUserJobs,
            City: job?.City,
            State: job?.State,
            Status: job?.Status ?? "",
            Zip: job?.Zip,
            IsMileageBillable: job?.isMileageBillable ?? false,
            ExpectedUnits: job?.ExpectedUnits ?? 0,
            ExpectedMiles: job?.ExpectedMiles ?? 0,
            QuoteId: jobsBidQuoteId ?? "",
            QuoteSegments: jobSegments?.filter((segment: JobSegment) => {
                return segment.BidQuoteItemId !== null;
            }) ?? []
        },
        onSubmit: () => {
            var update = {
                Name: formik.values.Name,
                ReqDistanceCompleted: formik.values.ReqDistanceCompleted,
                ReqUnitsCompleted: formik.values.ReqUnitsCompleted,
                ReqPerDiem: formik.values.ReqPerDiem,
                ReqSegments: formik.values.ReqSegments,
                City: formik.values.City,
                State: formik.values.State,
                Status: formik.values.Status,
                ProjectManagerId: formik.values.ProjectManagerId,
                Zip: formik.values.Zip,
                isMileageBillable: formik.values.IsMileageBillable,
                ExpectedUnits: formik.values.ExpectedUnits,
                ExpectedMiles: formik.values.ExpectedMiles
            }

            if (formik.values.ProjectManagerId !== job.ProjectManagerId) {
                addProjectHistory({
                    variables: {
                        object: {
                            Id: uuid.v4(),
                            ProjectManagerId: formik.values.ProjectManagerId,
                            ChangedById: userStore?.appUser?.id,
                            Timestamp: new Date().toISOString(),
                            JobId: job?.Id,
                        }
                    }
                })
                if (userStore?.appUser?.id) {
                    const changedBy = users?.AspNetUsers?.find((user: AppUser) => {
                        return user?.Id === formik.values.ProjectManagerId
                    })
                    timeLineEvents.unshift({
                        ProjectManager: `${changedBy?.FirstName} ${changedBy?.LastName}`,
                        ChangedBy: `${userStore?.appUser?.firstName} ${userStore?.appUser?.lastName}`,
                        Timestamp: new Date().toISOString(),
                        SortKey: new Date().getTime()
                    });
                    setTimeLineEvents(timeLineEvents);
                }
                
            }
            onSave(update);
        },
        validationSchema: validationSchema,
    });

    const saveQuoteChange = (quoteId: string) => {
        getQuoteJobSegments({
            variables: {
                JobId: id
            },
            onCompleted: (value: any) => {
                if (value?.JobSegments.length > 0) {
                    deleteQuoteJobSegments({
                        variables: {
                            JobId: id
                        },
                        refetchQueries: [GET_JOB_QUERY],
                        onCompleted: (value: any) => {
                            if (quoteId !== undefined && quoteId !== "") {
                                insertJobSegment({
                                    variables: {
                                        object: {
                                            BidQuoteId: quoteId,
                                            JobId: id,
                                            Id: uuid.v4(),
                                            Ordinal: 0
                                        }
                                    },
                                    refetchQueries: [GET_JOB_QUERY],
                                    onCompleted: () => {
                                        addMessage("Job Quote Updated", { severity: 'success' });
                                        getBidQuoteItemsQuery({
                                            variables: {
                                                BidQuoteId: quoteId
                                            },
                                            onCompleted: (data) => {
                                                setBidQuoteItemOptions(data?.BidQuoteItems);
                                            }
                                        });
                                    },
                                    onError: () => {
                                        addMessage("Error Updating Job Quote", { severity: 'error' });
                                    }
                                });
                            } else {
                                addMessage("Job Quote Updated", { severity: 'success' });
                            }
                        },
                        onError: () => {
                            addMessage("Error Updating Job Quote", { severity: 'error' });
                        }
                    })
                } else {
                    insertJobSegment({
                        variables: {
                            object: {
                                BidQuoteId: quoteId,
                                JobId: id,
                                Id: uuid.v4(),
                                Ordinal: 0
                            }
                        },
                        refetchQueries: [GET_JOB_QUERY],
                        onCompleted: () => {
                            addMessage("Job Quote Updated", { severity: 'success' });
                            getBidQuoteItemsQuery({
                                variables: {
                                    BidQuoteId: quoteId
                                },
                                onCompleted: (data) => {
                                    setBidQuoteItemOptions(data?.BidQuoteItems);
                                }
                            });
                        },
                        onError: () => {
                            addMessage("Error Updating Job Quote", { severity: 'error' });
                        }
                    });
                }
            }
        });
    }

    const handleQuoteChange = (event: any, newValue: any) => {
        if (formik.values.QuoteId !== "") {
            const confirm = window.confirm("Are you sure you want to change the quote for this job? Doing so will remove all segments associated with this job.");
            if (confirm) {
                formik.setFieldValue("QuoteId", newValue?.Id ?? "");
                saveQuoteChange(newValue?.Id ?? "");
            }
        } else {
            formik.setFieldValue("QuoteId", newValue?.Id ?? "");
            saveQuoteChange(newValue?.Id ?? "");
        }
    };

    return (
        <Grid container direction="row" sx={{ width: "100%" }}>
            <Box sx={{ width: "400px" }}>
                <form className="ui form" onSubmit={formik.handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <HrTextInput
                                id="name"
                                name="Name"
                                label="Project Alias"
                                onChange={formik.handleChange}
                                value={formik.values.Name}
                                errors={formik.errors.Name}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="projectType"
                                options={[]}
                                value={job?.KickoffPacket?.KickoffPacketProjectTypes ?? []}
                                multiple
                                getOptionLabel={(option: any) => option.Name}
                                fullWidth
                                renderInput={(params) => (
                                    <HrTextInput {...params} label="Project Type" />
                                )}
                                renderTags={(value: any, getTagProps) =>
                                    value.map((option: any, index: number) => {
                                        return <Chip
                                            label={option?.ServiceCategory?.Name}
                                            //{...getTagProps({ index })}
                                            key={index}
                                            sx={{
                                                '&.MuiChip-root': {
                                                    opacity: "1"
                                                },
                                            }}
                                        />
                                    })
                                }
                                disableClearable={true}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <HrTextInput
                                id="city"
                                name="City"
                                label="City"
                                onChange={formik.handleChange}
                                value={formik.values.City}
                                errors={formik.errors.City}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <HrTextInput
                                id="state"
                                name="State"
                                label="State"
                                onChange={formik.handleChange}
                                value={formik.values.State}
                                errors={formik.errors.State}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <HrTextInput
                                id="zip"
                                name="Zip"
                                label="ZIP Code"
                                onChange={formik.handleChange}
                                value={formik.values.Zip}
                                errors={formik.errors.Zip}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl>
                                <RadioGroup>
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                onChange={() => {
                                                    formik.setFieldValue("ReqDistanceCompleted", false);
                                                    formik.setFieldValue("ReqUnitsCompleted", true);
                                                }}
                                                style={{
                                                    color: Colors.deepSeaGreen,
                                                }}
                                                checked={
                                                    formik.values.ReqUnitsCompleted
                                                        ? formik.values.ReqUnitsCompleted
                                                        : false
                                                }
                                            />
                                        }
                                        label="Require Units Complete"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                onChange={() => {
                                                    formik.setFieldValue("ReqDistanceCompleted", true);
                                                    formik.setFieldValue("ReqUnitsCompleted", false);
                                                }}
                                                style={{
                                                    color: Colors.deepSeaGreen,
                                                }}
                                                checked={
                                                    formik.values.ReqDistanceCompleted
                                                        ? formik.values.ReqDistanceCompleted
                                                        : false
                                                }
                                            />
                                        }
                                        label="Require Production Miles"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <HrTextInput
                                id="expectedMiles"
                                name="ExpectedMiles"
                                label="Expected Miles"
                                onChange={formik.handleChange}
                                value={formik.values.ExpectedMiles}
                                errors={formik.errors.ExpectedMiles}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <HrTextInput
                                id="expectedUnits"
                                name="ExpectedUnits"
                                label="Expected Units"
                                onChange={formik.handleChange}
                                value={formik.values.ExpectedUnits}
                                errors={formik.errors.ExpectedUnits}
                                fullWidth
                            />
                        </Grid>
                        {formik.values.ReqDistanceCompleted && (
                            <Grid item xs={6}>
                                <HrCheckBox
                                    id="reqSegments"
                                    onChange={formik.handleChange}
                                    name="ReqSegments"
                                    label="Require Segments"
                                    checked={formik.values.ReqSegments}
                                />
                            </Grid>
                        )}
                        <Grid item xs={6}>
                            <HrCheckBox
                                id="reqPerDiem"
                                onChange={formik.handleChange}
                                name="ReqPerDiem"
                                color="primary"
                                label="Require PerDiem"
                                checked={formik.values.ReqPerDiem}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <HrCheckBox
                                id="isMileageBillable"
                                onChange={formik.handleChange}
                                name="IsMileageBillable"
                                color="primary"
                                label="Is Mileage Billable For This Job? "
                                checked={formik.values.IsMileageBillable}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <HrTextInput
                                id="customer"
                                name="Customer"
                                label="Customer"
                                disabled={true}
                                value={formik.values.Customer?.Name}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="projectManagerId"
                                options={users?.AspNetUsers ?? []}
                                value={users?.AspNetUsers?.find((user: AppUser) => {
                                    return user?.Id === formik.values.ProjectManagerId
                                }) ?? null}
                                getOptionLabel={(option: any) => {
                                    return option?.FirstName + " " + option?.LastName
                                }}
                                onChange={(event, value) => {
                                    formik.setFieldValue("ProjectManagerId", value.Id);
                                }}
                                renderInput={(params) => (
                                    <HrTextInput {...params} label="Project Manager" variant="outlined" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="appUsers"
                                multiple
                                filterSelectedOptions
                                options={users?.AspNetUsers ?? []}
                                value={formik.values.appUsers ?? []}
                                getOptionLabel={(option: any) => {
                                    return option?.FirstName + " " + option?.LastName
                                }}
                                onChange={(event, newValue) => {
                                    const newUser: any = newValue.at(-1);
                                    if (newUser?.Id) {
                                        addJobUser({
                                            variables: {
                                                object: {
                                                    AppUsersId: newUser?.Id,
                                                    JobsId: id
                                                }
                                            },
                                            onCompleted: (value: any) => {
                                                formik.setFieldValue("appUsers", [...formik.values.appUsers, value?.insert_AppUserJobs?.returning[0]]);
                                            },
                                            onError: () => {
                                                addMessage("Error Adding User to Job", { severity: 'error' });
                                            }
                                        });
                                    }
                                }}
                                renderInput={(params) => (
                                    <HrTextInput {...params} label="Employees" variant="outlined" />
                                )}
                                renderTags={(value: any, getTagProps) => {
                                    return value.map((option: any, index: number) => {
                                        var user = users?.AspNetUsers?.find((user: any) => user?.Id.toLowerCase() === option?.AppUsersId?.toLowerCase());
                                        return (
                                            <Chip
                                                label={`${user?.FirstName} ${user?.LastName}`}
                                                {...getTagProps({ index })}
                                                key={index}
                                                onDelete={(value) => {
                                                    deleteJobUser({
                                                        variables: {
                                                            UserId: option?.AppUsersId,
                                                            JobId: id
                                                        },
                                                        onCompleted: (value: any) => {
                                                            formik.setFieldValue("appUsers", formik.values.appUsers.filter((user: any) => {
                                                                return user?.AppUsersId?.toLowerCase() !== option?.AppUsersId?.toLowerCase();
                                                            }));
                                                        },
                                                        onError: () => {
                                                            addMessage("Error Adding User to Job", { severity: 'error' });
                                                        }
                                                    });
                                                }}
                                            />
                                        )
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="quoteId"
                                value={quoteList?.find((quote: Quote) => {
                                    return quote?.Id?.toLowerCase() === formik.values.QuoteId.toLowerCase()
                                }) ?? null}
                                options={quoteList ?? []}
                                getOptionLabel={(option: any) => {
                                    return `${option?.Customer?.ShortCode ? option?.Customer?.ShortCode : option?.Customer?.Name}_${option?.JobNumber}_${option?.BidDescription}_Rev_${option?.BidQuoteExternalRevisions ? (option?.BidQuoteExternalRevisions[0]?.ExternalRevision ?? 0) : ""}`;
                                }}
                                onChange={handleQuoteChange}
                                renderInput={(params) => (
                                    <HrTextInput {...params} errors={formik.errors.QuoteId} label="Quote" variant="outlined" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="quoteSegments"
                                options={bidQuoteItemOptions?.filter(bqi => {
                                    return !jobSegments?.some((segment: JobSegment) => {
                                        return segment.BidQuoteItemId === bqi.Id;
                                    });
                                }) ?? []}
                                value={formik.values.QuoteSegments}
                                multiple
                                getOptionLabel={(option: any) => {
                                    return option.Segment;
                                }}
                                fullWidth
                                renderInput={(params) => (
                                    <HrTextInput {...params} label="Quote Segments" variant="outlined" />
                                )}
                                renderTags={(value: any, getTagProps) =>
                                    value.map((option: any, index: number) => {
                                        return <Chip
                                            label={`${option?.BidQuoteItem?.Segment} - ${option?.BidQuoteItem?.Type}`}
                                            {...getTagProps({ index })}
                                            key={index}
                                            sx={{
                                                '&.MuiChip-root': {
                                                    opacity: "1"
                                                },
                                            }}
                                            onDelete={(event) => {
                                                deleteQuoteJobSegment({
                                                    variables: {
                                                        Id: option?.Id
                                                    },
                                                    refetchQueries: [GET_JOB_QUERY],
                                                    onCompleted: (value: any) => {
                                                        addMessage("Job Quote Updated", { severity: 'success' });
                                                    },
                                                    onError: () => {
                                                        addMessage("Error Updating Job Quote", { severity: 'error' });
                                                    }
                                                })
                                            }}
                                        />
                                    })
                                }
                                disableClearable={true}
                                disabled={formik.values.QuoteId === "" || formik.values.QuoteId === null}
                                filterSelectedOptions
                                onChange={(event, newValue) => {
                                    const bidQuoteItemId = newValue.at(-1).Id;
                                    insertJobSegment({
                                        variables: {
                                            object: {
                                                BidQuoteId: formik.values.QuoteId,
                                                JobId: id,
                                                Id: uuid.v4(),
                                                Ordinal: 0,
                                                BidQuoteItemId: bidQuoteItemId
                                            }
                                        },
                                        refetchQueries: [GET_JOB_QUERY],
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="Status"
                                value={formik.values.Status}
                                options={JobStatuses ?? []}
                                onChange={(event, newValue) => {
                                    formik.setFieldValue("Status", newValue);
                                }}
                                renderInput={(params) => (
                                    <HrTextInput {...params} errors={formik.errors.Status} label="Job Status" variant="outlined" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <HrButton type="submit" title="Submit" />
                        </Grid>
                    </Grid>
                </form>
            </Box>
            <JobsTimeline timeLineEvents={timeLineEvents} />
        </Grid>
    );
};