import { gql, useMutation, useQuery } from "@apollo/client";
import { Box } from "@mui/material"
import { addMessage } from "../../../../stores/ReactiveVariables";
import { ExecutionPlanForm, ExecutionPlanFormData } from "./ExecutionPlanForm";

interface ExecutionPlanProps {
    id: string;
    getCoverLetter: () => void;
}

interface ExecutionPlanQuery {
    BidQuotes_by_pk: ExecutionPlanFormData;
}



const GET_COVER_EXECUTION_PLAN_QUERY = gql`
    query CoverLetterExecutionPlanQuery($Id: uniqueidentifier = "") {
        BidQuotes_by_pk(Id: $Id) {
            CoverLetterFieldStaff
            CoverLetterSupportStaff
            CoverLetterProjectName
        }
    }  
`;

const UPDATE_COVER_EXECUTION_PLAN = gql`
    mutation UpdateCoverLetterExecutionPlan($Id: uniqueidentifier = "", $object: BidQuotes_set_input = {}) {
        update_BidQuotes_by_pk(pk_columns: {Id: $Id}, _set: $object) {
            CoverLetterFieldStaff
            CoverLetterSupportStaff
            CoverLetterProjectName
        }
    }
`

export const ExecutionPlan: React.FC<ExecutionPlanProps> = ({ id, getCoverLetter }: ExecutionPlanProps) => {

    const { data, error } = useQuery<ExecutionPlanQuery>(GET_COVER_EXECUTION_PLAN_QUERY, { variables: { Id: id } });

    const [updateCoverLetterSections] = useMutation(UPDATE_COVER_EXECUTION_PLAN);

    const formData: ExecutionPlanFormData = data?.BidQuotes_by_pk as ExecutionPlanFormData;

    if (error) console.log(error);
    
    const onSave = (values: ExecutionPlanFormData) => {
        updateCoverLetterSections({
            variables: { Id: id, object: values },
            onCompleted: () => {
                addMessage('Execution Plan Saved', { severity: 'success' });
                getCoverLetter();
            },
            onError: () => {
                addMessage('Error Saving Execution Plan', { severity: 'error' });
            }
        });
    }

    return (
        <Box sx={{ width: '100%' }}>
            <ExecutionPlanForm id={id} data={formData} onSave={onSave} />
        </Box>
    )
}