import { GridColDef } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { orderBy } from "lodash"

import { useStore } from '../../../stores/StoreContext';
import RemoveButton from '../../atoms/RemoveButton';
import ViewButton from '../../atoms/ViewButton';
import { HrDataGrid } from '../../molecules/HrDataGrid';


export const ClientsDataGrid: React.FC = observer(() => {
    const { clientStore, modalStore, userStore } = useStore();
    const { clients, setSelectedClient, deleteClient } = clientStore;
    const { openClientsModal, openClientLogoModal } = modalStore;
    const navigate = useNavigate();
    const rows = orderBy(clients.map(client => {
        return {
            id: client.id,
            name: client.name,
            address: client.address,
            city: client.city,
            state: client.state,
            zip: client.zip,
            phone: client.phone,
            email: client.email,
            primaryContact: client.primaryContact
        }
    }), ['name'], ['asc']);

    const columns: GridColDef[] = [
        {
            field: 'removeBillable',
            headerName: ' ',
            width: 50,
            renderCell: (rows) => {
                const clientId = rows.id;
                return (
                    <RemoveButton
                        onClick={() => {
                            deleteClient(clientId as string)
                        }}
                    />
                )
            },
            headerClassName: 'billables-grid-header'
        },
        {
            field: 'editContacts',
            headerName: 'Contacts',
            width: 100,
            renderCell: (rows) => {
                const clientId = rows.id;
                return (
                    <ViewButton
                        onClick={() => {
                            navigate(`/Clients/${clientId}/Contacts`)
                        }}
                    />
                )
            },
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            headerClassName: 'billables-grid-header'
        },
        {
            field: 'viewLogo',
            headerName: 'Logo',
            width: 100,
            renderCell: (rows) => {
                const clientId = rows.id as string;
                return (
                    <ViewButton
                        onClick={() => {
                            setSelectedClient(clientId);
                            openClientLogoModal();
                        }}
                    />
                )
            },
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            headerClassName: 'billables-grid-header'
        },
        {
            field: 'id',
            headerName: 'ID',
            width: 100,
            type: 'string',
            headerClassName: 'billables-grid-header'
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 250,
            type: 'string',
            editable: false,
            headerClassName: 'billables-grid-header'
        },
        {
            field: 'address',
            headerName: 'Address',
            width: 150,
            type: 'string',
            editable: false,
            headerClassName: 'billables-grid-header'
        },
        {
            field: 'city',
            headerName: 'City',
            width: 150,
            type: 'string',
            editable: false,
            headerClassName: 'billables-grid-header'
        },
        {
            field: 'state',
            headerName: 'State',
            width: 150,
            type: 'string',
            editable: false,
            headerClassName: 'billables-grid-header'
        },
        {
            field: 'zip',
            headerName: 'Zip Code',
            width: 150,
            editable: false,
            headerClassName: 'billables-grid-header'
        },
        {
            field: 'phone',
            headerName: 'Phone Number',
            width: 150,
            type: 'string',
            editable: false,
            headerClassName: 'billables-grid-header'
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 150,
            type: 'string',
            editable: false,
            headerClassName: 'billables-grid-header'
        },
        {
            field: 'primaryContact',
            headerName: 'Primary Contact',
            width: 150,
            type: 'string',
            editable: false,
            headerClassName: 'billables-grid-header'
        },

    ];
    return (
        <HrDataGrid
            rows={rows}
            getRowId={undefined}
            columns={columns}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            onRowDoubleClick={model => {
                setSelectedClient(model.id as string);
                openClientsModal();
            }}
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        removeBillable: userStore.appUser?.roles?.some(e => e.name === "BidBook Admin") ?? false,
                        editContacts: userStore.appUser?.roles?.some(e => e.name === "BidBook Admin") ?? false,
                        id: false
                    }
                }
            }}
            disableRowSelectionOnClick
        />
    )
});