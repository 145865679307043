import { gql } from "@apollo/client";


export interface GetBidQuoteRatesQueryResult {
  BidQuoteRates: BidQuoteRate[];
}

export interface BidQuoteRate {
  Id: string;
  Name: string;
  BidQuoteId: string;
  DailyHours: number;
  ServiceType: string;
  Rate: number;
  Cost: number;
}

export const GET_BIDQUOTE_RATES_QUERY = gql`
  query GetBidQuoteRates ($BidQuoteId: uniqueidentifier = "") {
    BidQuoteRates(where: {BidQuoteId: {_eq: $BidQuoteId}}){
      Id
      Name
      BidQuoteId
      DailyHours
      ServiceType
      Rate
      Cost
      ReportAdditionalItem
    }
  }
`;

export const DELETE_BIDQUOTE_RATE_MUTATION = gql`
mutation DeleteBidQuoteRate($Id: uniqueidentifier = "") {
  delete_BidQuoteRates_by_pk(Id: $Id) {
    Id
  }
}
`;

export const CREATE_BIDQUOTE_RATE_MUTATION = gql`
mutation CreateBidQuoteRate($object: BidQuoteRates_insert_input = {}) {
  insert_BidQuoteRates_one(object: $object) {
    __typename
    Id
    BidQuoteId
    Name
    DailyHours
    Rate
    ServiceType
    Cost
    ReportAdditionalItem
  }
}
`;

export const UPDATE_BIDQUOTE_RATE_MUTATION = gql`
  mutation UpdateBidQuoteRate($Id: uniqueidentifier = "", $object: BidQuoteRates_set_input = {}) {
    update_BidQuoteRates_by_pk(pk_columns: {Id: $Id}, _set: $object) {
      __typename
      Id
      BidQuoteId
      Name
      DailyHours
      Rate
      ServiceType
      Cost
    }
  }
`;