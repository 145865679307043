import { gql, useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CircularProgress, IconButton } from "@mui/material";
import { v4 } from "uuid";
import { GET_CONSTRUCTION_SERVICE_QUERY } from "../../../../api/graphqlqueries/constructionserviceitem";
import { GET_MATERIAL_SERVICE_QUERY } from "../../../../api/graphqlqueries/getmaterialservicequery";
import { GET_QUOTE_ITEMS_QUERY } from "../../../../api/graphqlqueries/getquoteitems";
import { appUserVar, disableForStatus } from "../../../../stores/ReactiveVariables";
interface ICopyProps {
	id: string;
}

const GET_CONSTRUCTION_ITEM_QUERY = gql`
query GetConstructionItem($Id: uniqueidentifier = "") {
	BidQuoteItems_by_pk(Id: $Id) {
	  BidQuoteMaterials {
		Id
		BidQuoteItemId
		BidQuoteId
		Description
		Rate
		Markup
		Quantity
		Unit
		Type
		Duration
		Ordinal
		LineBreakAfter
		BidQuoteBillableId
		OverrideRate
	  }
	  BidQuoteConstructionServices {
		Average
		BidQuoteItemId
		Days
		Discount
		Equipment
		Id
		Locations
		Mileage
		Mob
		MobWoPD
		PerDiem
		Rate
		Personnel
		Rest
		Setup
		Unit
		Ordinal
		LineBreakAfter
		BidQuoteRateId
		OverrideRate
	  }
	  BidQuoteId
	  Type
	  Area
	  AverageMiles
	  Boat
	  CrewType
	  CrewQuantity
	  Discount
	  Id
	  Miles
	  Mob
	  MobWoPD
	  Rate
	  Segment
	  Setup
	  Ordinal
	  LineBreakAfter
	}
  }  
`;

const COPY_CONSTRUCTION_LINE_ITEM_MUTATION = gql`
  mutation CopyConstructionLineItem($Item: BidQuoteItems_insert_input = {}, $OrdinalStart: Int = "", $Type: String = "", $BidQuoteId: uniqueidentifier = "") {
    update_BidQuoteItems(where: {Ordinal: {_gt: $OrdinalStart}, Type: {_eq: $Type}, BidQuoteId: {_eq: $BidQuoteId}}, _inc: {Ordinal: 1}) {
        returning {
            Ordinal
            Id
        }
    }
    insert_BidQuoteItems_one(object: $Item) {
      __typename
      Area
      AverageMiles
      BidQuoteId
      Boat
      CreateDate
      CreateUserId
      CrewQuantity
      CrewType
      Discount
      Id
      Location
      Miles
      Mob
      MobWoPD
      BidQuoteMaterials {
        __typename
        BidQuoteId
        Description
        Duration
        GroupWith
        Id
        Markup
        Quantity
        Rate
        Tax
        Type
        Unit
        Ordinal
        LineBreakAfter
		OverrideRate
      }
      Rate
      Segment
      Setup
      Type
      Ordinal
      LineBreakAfter
	  PerDay
    }
  }
`;

const COPY_CONSTRUCTION_LINE_ITEM_MATERIALS_MUTATION = gql`
  mutation CopyConstructionLineItemMaterials($objects: [BidQuoteMaterials_insert_input!] = {}) {
    insert_BidQuoteMaterials(objects: $objects) {
      returning {
        BidQuoteId
        BidQuoteItemId
        Description
        Duration
        GroupWith
        Id
        Markup
        Quantity
        Rate
        Tax
        Type
        Unit
        Ordinal
        LineBreakAfter
		OverrideRate
		BidQuoteBillableId
		AdderHasCost
      }
    }
  }
`;


const COPY_CONSTRUCTION_LINE_ITEM_SERVICES_MUTATION = gql`
  mutation CopyConstructionLineItemServices($objects: [BidQuoteConstructionServices_insert_input!] = {}) {
    insert_BidQuoteConstructionServices(objects: $objects) {
      returning {
        Average
        BidQuoteItemId
        Days
        Discount
        Equipment
        Id
        Locations
        Mileage
        Mob
        MobWoPD
        PerDiem
        Unit
        Setup
        Rest
        Rate
        Personnel
        Ordinal
        LineBreakAfter
		OverrideRate
		BidQuoteRateId
      }
    }
  }
`;

export const ConstructionItemCopyButton: React.FC<ICopyProps> = ({ id }) => {
	const user = useReactiveVar(appUserVar);
	const disabledForStatus = useReactiveVar(disableForStatus);
	const [fetchItem] = useLazyQuery(GET_CONSTRUCTION_ITEM_QUERY, {
		variables: {
			Id: id,
		}
	});
	const [copyLine, { loading: loadLine }] = useMutation(COPY_CONSTRUCTION_LINE_ITEM_MUTATION);
	const [copyMaterials, { loading: loadMaterials }] = useMutation(COPY_CONSTRUCTION_LINE_ITEM_MATERIALS_MUTATION);
	const [copyServices, { loading: loadServices }] = useMutation(COPY_CONSTRUCTION_LINE_ITEM_SERVICES_MUTATION);
	const loading = loadLine || loadMaterials || loadServices;
	const copyItem = () => {
		fetchItem().then(result => {
			copyItemWithData(result.data);
		});
	}
	const copyItemWithData = async (data: any) => {
		const item = data?.BidQuoteItems_by_pk;
		if (!item) return;
		const { BidQuoteMaterials, BidQuoteConstructionServices, __typename: typeName, ...construction } = item;
		construction.Id = v4().toUpperCase();
		construction.CreateUserId = user.id;
		construction.Segment = "";
		construction.Ordinal += 1;
		construction.PerDay = false;
		construction.OverrideRate = false;
		const quoteMaterials = BidQuoteMaterials.map((bqm: any) => {
			const { __typename, ...material } = bqm;
			material.BidQuoteItemId = construction.Id;
			material.AdderHasCost = false;
			return { ...material, Id: v4() };
		});

		const quoteServices = BidQuoteConstructionServices.map((bqcs: any) => {
			const { __typename, ...service } = bqcs;
			service.BidQuoteItemId = construction.Id;
			return { ...service, Id: v4() };
		})
		await copyLine({
			variables: {
				Item: construction,
				OrdinalStart: construction.Ordinal - 1,
				BidQuoteId: construction.BidQuoteId,
			},
			update: (cache, { data }) => {
				cache.writeQuery({
					query: GET_CONSTRUCTION_ITEM_QUERY,
					variables: { Id: construction.Id },
					data: {
						BidQuoteItems_by_pk: {
							...construction,
							__typename: typeName,
							BidQuoteMaterials: [],
							BidQuoteConstructionServices: []
						}
					}
				});
			},
			refetchQueries: [GET_QUOTE_ITEMS_QUERY],
		});

		if (quoteMaterials.length > 0) {
			await copyMaterials({
				variables: {
					objects: quoteMaterials,
				},
				update: (cache, { data }) => {
					data.insert_BidQuoteMaterials.returning.forEach((bqm: any) => {
						cache.writeQuery({
							query: GET_MATERIAL_SERVICE_QUERY,
							variables: { Id: bqm.Id },
							data: { BidQuoteMaterials: [bqm] }
						});

						const newBidQuoteRef = cache.writeFragment({
							fragment: gql`
                                fragment NewMaterial on BidQuoteMaterials {
                                    Id
                                    BidQuoteItemId
                                    BidQuoteId
                                    Description
                                    Rate
                                    Markup
                                    Quantity
                                    Unit
                                    Type
                                    Duration
                                }
                            `,
							data: bqm,
						});
						cache.modify({
							id: `BidQuoteItems:${construction.Id}`,
							fields: {
								BidQuoteMaterials(existingItems) {
									return [...existingItems, newBidQuoteRef];
								}
							}

						});
					});
				}
			})
		}

		if (quoteServices.length > 0) {
			await copyServices({
				variables: {
					objects: quoteServices,
				},
				update: (cache, { data }) => {
					data.insert_BidQuoteConstructionServices.returning.forEach((bqm: any) => {
						cache.writeQuery({
							query: GET_CONSTRUCTION_SERVICE_QUERY,
							variables: { Id: bqm.Id },
							data: { BidQuoteConstructionServices_by_pk: bqm }
						});

						const newBidQuoteRef = cache.writeFragment({
							fragment: gql`
                                fragment NewService on BidQuoteConstructionServices {
                                    Average
                                    BidQuoteItemId
                                    Days
                                    Discount
                                    Equipment
                                    Id
                                    Locations
                                    Mileage
                                    Mob
                                    MobWoPD
                                    PerDiem
                                    Unit
                                    Setup
                                    Rest
                                    Rate
                                    Personnel
                                    Ordinal
                                }
                            `,
							data: bqm,
						});
						cache.modify({
							id: `BidQuoteItems:${construction.Id}`,
							fields: {
								BidQuoteConstructionServices(existingItems) {
									return [...existingItems, newBidQuoteRef];
								}
							}
						});
					})
				}
			})
		}
	}

	return (
		<IconButton
			color="success"
			onClick={copyItem}
			disabled={disabledForStatus}
		>
			{loading ? <CircularProgress /> : <ContentCopyIcon />}
		</IconButton>
	)
}
