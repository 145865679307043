import { Box, IconButton, List, ListItem, Modal, Typography } from "@mui/material";
import { KickoffPacketSection } from "./KickoffPacketSection";
import { gql, useMutation, useQuery } from "@apollo/client";
import HrButton from "../../../atoms/HrButton";
import { useState } from "react";
import { SegmentItems } from "../../Bidbook/SegmentItems";
import { v4 } from "uuid";
import { Delete } from "@mui/icons-material";

interface ProjectSegmentsProps{
    id: string;
    jobId: string;
    bidQuoteId: string;
}

interface ProjectSegmentsItem {
    Id: string;
    BidQuoteItemId: string;
    KickoffPacketId: string;
    JobId: string;
    BidQuoteItem?: {
        Id: string;
        Segment: string;
        Ordinal: number;
        Type: string;
    },
    Ordinal: number;
}

interface GetKickoffJobSegmentsQueryResult {
    JobSegments: ProjectSegmentsItem[];
}

const GET_KICKOFF_SEGMENTS_QUERY = gql`
    query GetKickoffJobSegments($KickoffId: uniqueidentifier = "") {
        JobSegments(where: {KickoffPacketId: {_eq: $KickoffId}}, order_by: {Ordinal: asc}) {
            Id
            JobId
            BidQuoteItemId
            KickoffPacketId
            BidQuoteItem {
                Id
                Segment
                Ordinal
                Type
            }
            Ordinal
        }
    }
`;

const ADD_JOB_SEGMENTS_MUTATION = gql`
  mutation AddJobSegments($objects: [JobSegments_insert_input!] = {}) {
    insert_JobSegments(objects: $objects, if_matched: {match_columns: Id, update_columns: [BidQuoteItemId, Ordinal, JobId, KickoffPacketId]}) {
      returning {
        BidQuoteItemId
        Id
        JobId
        KickoffPacketId
        Ordinal
      }
    }
  }
`;

const DELETE_JOB_SEGMENTS_MUTATION = gql`
  mutation DeleteJobSegments($SegmentId: uniqueidentifier = "", $KickoffId: uniqueidentifier = "", $Ordinal: Int = 10) {
    delete_JobSegments(where: {Id: {_eq: $SegmentId}}) {
      affected_rows
    }
    update_JobSegments(where: {KickoffPacketId: {_eq: $KickoffId}, Ordinal: {_gt: $Ordinal}}, _inc: {Ordinal: -1}) {
      returning {
        Id
        Ordinal
      }
    }
  }
`;

export const ProjectSegments: React.FC<ProjectSegmentsProps> = ({ id, jobId, bidQuoteId }: ProjectSegmentsProps) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [addJobSegments] = useMutation(ADD_JOB_SEGMENTS_MUTATION);
    const [deleteJobSegments] = useMutation(DELETE_JOB_SEGMENTS_MUTATION);
    const { data } = useQuery<GetKickoffJobSegmentsQueryResult>(GET_KICKOFF_SEGMENTS_QUERY, {
        variables: {
            KickoffId: id
        }
    });

    const toggleModal = () => {
        setModalOpen(t => !t);
    }

    if (!data) return null;
    const segmentsOld = data?.JobSegments.map((seg: any) => {
        const { __typename, BidQuoteItem, ...rest } = seg;
        return rest;
    });
    const handleSegments = (segments: any[]) => {
        const newSegments = segments.map((s: any, i) => {
            return {
                Id: v4(),
                JobId: jobId,
                BidQuoteItemId: s.Id,
                Ordinal: i,
                KickoffPacketId: id,
            }
        });
        const jobSegments = [...segmentsOld, ...newSegments].map((s, i) => {
            return {
                ...s,
                Ordinal: i
            }
        });
        addJobSegments({
            variables: {
                objects: jobSegments
            },
            refetchQueries: [{ query: GET_KICKOFF_SEGMENTS_QUERY, variables: { KickoffId: id }}]
        }).finally(() => toggleModal());
    }

    const handleDelete = (segment: ProjectSegmentsItem) => () => {
        const { Id, Ordinal } = segment;
        const variables = {
            SegmentId: Id,
            KickoffId: id,
            Ordinal
        }
        deleteJobSegments({
            variables,
            refetchQueries: [{ query: GET_KICKOFF_SEGMENTS_QUERY, variables: { KickoffId: id }}]
        });
    }

    return(
        <KickoffPacketSection heading="Project Segments">
            <HrButton onClick={toggleModal}>Add Segment</HrButton>
            <List>
                {
                    data?.JobSegments?.map((jobSegment: any)=>(
                        <ListItem divider key={jobSegment?.Id} secondaryAction={
                            <IconButton onClick={handleDelete(jobSegment)} edge="end" aria-label="delete">
                              <Delete />
                            </IconButton>
                          }>
                            <Typography variant="body1">{jobSegment?.BidQuoteItem?.Segment}</Typography>
                        </ListItem>
                    ))
                }
            </List>
            <Modal
                open={modalOpen}
                onClose={toggleModal}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                >
                <Box sx={{ ...style }}>
                    <SegmentItems id={bidQuoteId} onSave={handleSegments} />
                    <HrButton onClick={toggleModal}>Cancel</HrButton>
                </Box>
            </Modal>
        </KickoffPacketSection>
    ) 
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 4,
    px: 14,
    pb: 12,
};