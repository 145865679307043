import { useQuery } from "@apollo/client";
import { Autocomplete, InputAdornment, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from "react-router";
import { v4 } from 'uuid';
import * as yup from 'yup';
import { BidQuoteBillable, GetBidquoteBillablesQueryResult, GET_BIDQUOTE_BILLABLES_QUERY } from "../../../../api/graphqlqueries/bidquotebillables";
import debounce from '../../../../utils/debounce';
import { HrCurrencyInput } from "../../../atoms/HrCurrencyInput";
import { HrPercentInput } from "../../../atoms/HrPercentInput";
import HrTextInput from "../../../atoms/HrTextInput";
import { QuoteLineItemFormGrid } from "../../../atoms/QuoteLineItemFormGrid";
import { BidQuoteStatus } from "../../../../api/graphqlqueries/getquotename";
import { getMaterialCalculations, BidQuoteMaterial, MaterialCalculations } from "../../../../utils/calculations/adders";
import HrCheckBox from "../../../atoms/HrCheckBox";

interface QuoteMaterialItem {
    Id?: string;
    Quantity?: number;
    Cost?: number;
    Markup?: number;
    Type?: string;
    Unit?: string;
    Duration?: number;
    Rate?: number;
    OverrideRate?: boolean;
    BidQuoteBillableId?: string | null;
    status?: BidQuoteStatus | null;
}
interface QuoteMaterialRateFormProps {
    item?: QuoteMaterialItem;
    type: string;
    onSave: (item: QuoteMaterialItem) => void;
    status: string;
}

const quoteMaterialItemSchema = yup.object().shape({
    Markup: yup.number(),
    Quantity: yup.number(),
    Rate: yup.number(),
    Unit: yup.string(),
    Duration: yup.number(),
    BidQuoteBillabileId: yup.string().nullable(),
});

const width = {
    small: "10ch",
    medium: "14ch",
    large: "20ch",
    xLarge: "26ch"
}

export const QuoteMaterialRateForm = ({ item: materialItem, onSave, type, status }: QuoteMaterialRateFormProps) => {
    const Id = materialItem?.Id ?? v4();
    const params = useParams<{ id: string }>();
    const [selectedBillable, setSelectedBillable] = useState(null as BidQuoteBillable | null);
    const { data } = useQuery<GetBidquoteBillablesQueryResult>(GET_BIDQUOTE_BILLABLES_QUERY, {
        variables: {
            BidQuoteId: params.id,
        }
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: quoteMaterialItemSchema,
        initialValues: {
            Quantity: materialItem?.Quantity ?? 0,
            Rate: materialItem?.Rate ?? 0,
            OverrideRate: materialItem?.OverrideRate ?? false,
            BidQuoteBillableId: materialItem?.BidQuoteBillableId ?? null,
            Markup: materialItem?.Markup ?? 0,
            Unit: materialItem?.Unit ?? '',
            Duration: materialItem?.Duration ?? 0,
        },
        onSubmit: (values) => {
            const data = quoteMaterialItemSchema.cast(values);

            onSave({ Id, Type: type, ...data });
            formik.setSubmitting(false);
        }
    });

    useEffect(() => {
        setSelectedBillable(t => data?.BidQuoteBillables.find(b => b.Id === materialItem?.BidQuoteBillableId) ?? null);
    }, [data, setSelectedBillable, materialItem])

    const updateData = (data: any) => {
        onSave({ Id, Type: type, ...data });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (formik.values[e.target.name as keyof typeof formik.values]?.toString() === e.target.value) return;
        try {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value};
            const values = quoteMaterialItemSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.handleChange(e);
    }

    const handlePercentChange = (e: ChangeEvent<HTMLInputElement>) => {
        const cleanNumberString = e.target.value.replace('%', '');
        const numberValue = parseFloat(cleanNumberString);
        if (isNaN(numberValue)) return;
        try {
            const updatedValues = { ...formik.values, [e.target.name]: numberValue / 100 };
            const values = quoteMaterialItemSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.setFieldValue(e.target.name, numberValue / 100);
    }

    const handleOverrideChange = () => {
        if(status === 'Draft' || 'Pending')
        {
            try {
                const updatedValues = { ...formik.values, OverrideRate: !formik.values.OverrideRate};
                const values = quoteMaterialItemSchema.cast(updatedValues);
                debouncer(values);
            } catch (error) {
                console.error(error);
            }
            formik.setFieldValue('OverrideRate', !formik.values.OverrideRate);
        }
    }

    // eslint-disable-next-line
    const debouncer = useCallback(debounce((values: any) => updateData(values), 1000), []);

    const filteredItems = data?.BidQuoteBillables.filter(b => b.Type === type);

    const materialCalculations: MaterialCalculations = getMaterialCalculations(materialItem as BidQuoteMaterial);

    return (
        <QuoteLineItemFormGrid>
            <Autocomplete
                options={filteredItems ? filteredItems.sort((a: any, b: any) => a.Name?.localeCompare(b.Name)) : []}
                value={selectedBillable ?? null}
                renderInput={(params: any) =>
                    <HrTextInput
                        {...params}
                        name='Product'
                        label={type === 'Material' ? 'Product' : 'Company'}
                        width={width.xLarge}
                    />
                }
                getOptionLabel={(option) => option.Name}
                disabled
            />

            <HrTextInput
                name='Quantity'
                label='Quantity'
                width={width.small}
                onChange={handleChange}
                value={formik.values.Quantity}
                errors={formik.errors.Quantity}
                disabled
            />
  
            <HrCurrencyInput
                name='Rate'
                label='Rate'
                width={width.medium}
                onChange={selectedBillable ? handleChange : undefined}
                value={formik.values.OverrideRate ? formik.values.Rate : selectedBillable?.Cost ?? ''}
                errors={formik.errors.Rate}
                InputProps={ status === "Draft" ? {
                    endAdornment: 
                    (
                        <InputAdornment position="end">
                            <HrCheckBox
                                name="OverrideRate"
                                checked={formik.values.OverrideRate}
                                onChange={handleOverrideChange}
                                sx={{
                                    m: "0 -8px 0 0",
                                    "& .MuiCheckbox-root": {
                                        p: 0
                                    }
                                }}
                            />
                        </InputAdornment>
                        )
                } : undefined}
                InputLabelProps={{ shrink: true }}
                disabled={!formik.values.OverrideRate}
            />

            <HrPercentInput
                label='Markup'
                name='Markup'
                width={width.small}
                onChange={handlePercentChange}
                value={formik.values.Markup}
                errors={formik.errors.Markup}
                disabled={status !== 'Draft' && status !== 'Pending'}
            />

            <HrTextInput
                select
                name='Unit'
                label='PER'
                width={width.small}
                onChange={handleChange}
                value={formik.values.Unit}
                errors={formik.errors.Unit}
                disabled
            >
                <MenuItem value="" />
                <MenuItem value="Day">Day</MenuItem>
                <MenuItem value="Miles">Miles</MenuItem>
                <MenuItem value="Each">Each</MenuItem>
                <MenuItem value="Feet">Feet</MenuItem>
                <MenuItem value="Sets">Sets</MenuItem>
                <MenuItem value="Hours">Hours</MenuItem>
            </HrTextInput>
            <HrTextInput
                name='Duration'
                label='Duration'
                width={width.small}
                onChange={handleChange}
                value={formik.values.Duration}
                errors={formik.errors.Duration}
                disabled
            />
            <HrCurrencyInput
                label='Total'
                width={width.medium}
                value={materialCalculations.TotalPrice.toString()}
                disabled
            />
            <HrCurrencyInput
                label='Markup Total'
                width={width.medium}
                value={materialCalculations?.TotalMarkupPrice?.toString()}
                disabled
            />
        </QuoteLineItemFormGrid>
    );
};