export function addBusinessDays(datStartDate: Date, lngNumberOfWorkingDays: number) {
    const workingDays: number = 5;
    const nonWorkingDays: number = 2;
    const startDay: number = datStartDate.getDay(); // 0=Sunday ... 6=Saturday
    let offset: number;
    let modifier = 0;

    var newDate = new Date(datStartDate)
    if (lngNumberOfWorkingDays >= 0) {
        offset = startDay - 1;
        if (startDay === 6) {
            offset -= 6;
            modifier = 1;
        }
    } else {
        offset = startDay - 5;
        if (startDay === 0) {
            offset++;
            modifier = 1;
        }
    }
    newDate.setTime(datStartDate.getTime() + ((~~((lngNumberOfWorkingDays + offset) / workingDays) * nonWorkingDays) + lngNumberOfWorkingDays + modifier)*86400000);
    return newDate;
}