import { Box } from "@mui/material";
import { useEffect } from "react";
import { Route, Routes } from "react-router";
import { useStore } from "../../stores/StoreContext";
import PrivateRoute from "../molecules/PrivateRoute";
import { BidbookPageContent } from "../organisms/Bidbook/BidbookPageContent";
import { DashboardContent } from "../organisms/Dashboards/DashboardContent";
import { BillablesPageContent } from "../organisms/Billables/BillablesPageContent";
import { ClientsPageContent } from "../organisms/Clients/ClientsPageContent";
import { ClientsContactPageContent } from "../organisms/Clients/CustomerContactsPageContent";
import { CrewPageContent } from "../organisms/Crews/CrewPageContent";
import { EmployeePageContent } from "../organisms/Employees/EmployeePageContent";
import { JobCostsPageContent } from "../organisms/JobCosts/JobCostsPageContent";
import { JobsPageContent } from "../organisms/Jobs/JobsPageContent";
import { NavBar } from "../organisms/Navbar";

export const AppPage: React.FC = () => {
    return <Box sx={{ display: "inline-flex", width: '100vw' }}>
        <NavBar />
        <Box sx={{ width: "calc(100vw - 216px)", height: '90vh', p: 1, marginLeft:"200px", boxSizing:"border-box" }}>
            <Routes>

                <Route path={"Admin"} element={<PrivateRoute role={1} />} >
                    <Route index element={<BillablesPageContent />} />
                </Route>
                <Route path={"Clients"} element={<PrivateRoute role={1} />} >
                    <Route path=':customerId/Contacts' element={<ClientsContactPageContent />} />
                    <Route index element={<ClientsPageContent />} />
                </Route>
                <Route path={"Crews"} element={<PrivateRoute role={1} />} >
                    <Route index element={<CrewPageContent />} />
                </Route>
                {/* <Route path={"Employees"} element={<PrivateRoute role={3} />} >
                    <Route index element={<EmployeePageContent />} />
                </Route> */}
                <Route path={"Jobs"} element={<PrivateRoute role={0} />} >
                    <Route index path={'*'} element={<JobsPageContent />} />
                </Route>
                <Route path={"/JobCosts"} element={<PrivateRoute role={2} />} >
                    <Route index element={<JobCostsPageContent />} />
                </Route>
                <Route path={'/Bidbook/*'} element={<PrivateRoute role={2} />} >
                    <Route path={'*'} element={<BidbookPageContent />} />
                </Route>
                <Route path={'/Dashboard/*'} element={<PrivateRoute role={2} />} >
                    <Route path={'*'} element={<DashboardContent />} />
                </Route>
            </Routes>
        </Box>
    </Box>
}