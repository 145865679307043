import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
    Button, Grid,
    GridProps, IconButton, InputAdornment, TextField
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useStore } from "../../stores/StoreContext";
import { StyledHeader } from "../atoms/StyledHeader";

interface ISignUpForm {
    firstName: string;
    lastName: string;
    password: string;
    confirmPassword: string;
    email: string;
    quickbooksName: string;
}

interface IFormStatus {
    message: string;
    type: string;
}

interface IFormStatusProps {
    [key: string]: IFormStatus;
}

const formStatusProps: IFormStatusProps = {
    success: {
        message: 'Signed up successfully.',
        type: 'success',
    },
    duplicate: {
        message: 'Email-id already exist. Please use different email-id.',
        type: 'error',
    },
    error: {
        message: 'Something went wrong. Please try again.',
        type: 'error',
    },
    incorrectDomain: {
        message:
            'Invalid email provided. Only employees of Highridge Corrosion Services may register',
        type: 'error',
    },
};

const RegisterForm: React.FunctionComponent = () => {
    const [displayFormStatus, setDisplayFormStatus] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [formStatus, setFormStatus] = useState<IFormStatus>({
        message: '',
        type: '',
    });
    const navigate = useNavigate();
    const toggleShowPassword = () => setShowPassword(!showPassword);
    const toggleShowPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);
    
    const { userStore } = useStore();

    const createNewUser = async (data: ISignUpForm, resetForm: Function) => {
        try {
            // API call integration will be here. Handle success / error response accordingly.

            let transformedData = {
                firstName: data.firstName,
                lastName: data.lastName,
                userName: data.email,
                email: data.email,
                quickbooksName: data.quickbooksName,
                password: data.password
            };
            const newLocation = await userStore.register(transformedData, false);
            if (newLocation) navigate(newLocation);
            if (data) {
                setFormStatus(formStatusProps.success);
                resetForm({});
            }
        } catch (error: any) {
            const response = error.response;
            if (
                response.data === 'user already exist' &&
                response.status === 400
            ) {
                setFormStatus(formStatusProps.duplicate);
            } else {
                setFormStatus(formStatusProps.error);
            }
        } finally {
            setDisplayFormStatus(true);
        }
    };

    return (
        <div style={{
            maxWidth: "450px",
            display: "block",
            margin: "0 auto",
        }}>
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    password: '',
                    confirmPassword: '',
                    email: '',
                    quickbooksName: ''
                }}
                onSubmit={(values: ISignUpForm, actions) => {
                    let allowedEmails = ["hrcorrosion.com", "seqtek.com"]
                    let emailValue = values.email?.split('@')[1];
                    if (emailValue == null || (emailValue != null && allowedEmails.indexOf(emailValue) < 0)) {
                        setFormStatus(formStatusProps.incorrectDomain);
                        setDisplayFormStatus(true);
                        actions.resetForm();
                        return;
                    } else {
                        createNewUser(values, actions.resetForm);
                        setTimeout(() => {
                            actions.setSubmitting(false);
                        }, 500);
                    }
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .email()
                        .required('Enter valid email-id'),
                    firstName: Yup.string().required('Please enter first name'),
                    lastName: Yup.string().required('Please enter last name'),
                    confirmPassword: Yup.string()
                        .required('Required')
                        .test(
                            'password-match',
                            'Password must match',
                            function (value) {
                                return this.parent.password === value;
                            }
                        ),
                })}
            >
                {(props: FormikProps<ISignUpForm>) => {
                    const {
                        values,
                        touched,
                        errors,
                        handleBlur,
                        handleChange,
                        isSubmitting,
                    } = props;
                    return (
                        <Form>
                            <StyledHeader sx={{textAlign:"center", marginBottom: ".5em"}}>Sign up</StyledHeader>
                            <Grid
                                container
                                justifyContent='space-around'
                                direction='row'
                                spacing={"4px"}
                                sx={{
                                    maxWidth: '450px',
                                    display: 'block',
                                    margin: '0 auto',
                                }}
                            >
                                <GridItemField>
                                    <TextField
                                        name='firstName'
                                        id='firstName'
                                        label='First Name'
                                        value={values.firstName}
                                        type='text'
                                        helperText={
                                            errors.firstName &&
                                                touched.firstName
                                                ? errors.firstName
                                                : 'Enter your first name.'
                                        }
                                        error={
                                            !!(
                                                errors.firstName &&
                                                touched.firstName
                                            )
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        sx={{width:"100%"}}
                                    />
                                </GridItemField>
                                <GridItemField>
                                    <TextField
                                        name='lastName'
                                        id='lasstName'
                                        label='Last Name'
                                        value={values.lastName}
                                        type='text'
                                        helperText={
                                            errors.lastName && touched.lastName
                                                ? errors.lastName
                                                : 'Enter your last name.'
                                        }
                                        error={
                                            !!(
                                                errors.lastName &&
                                                touched.lastName
                                            )
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        sx={{width:"100%"}}
                                    />
                                </GridItemField>
                                <GridItemField>
                                    <TextField
                                        name='password'
                                        id='password'
                                        label='Password'
                                        value={values.password}
                                        type={showPassword ? 'text' : 'password'}
                                        helperText={
                                            errors.password && touched.password
                                                ? 'Please valid password. One uppercase, one lowercase, one special character and no spaces'
                                                : 'One uppercase, one lowercase, one special character and no spaces'
                                        }
                                        error={
                                            !!(
                                                errors.password &&
                                                touched.password
                                            )
                                        }
                                        sx={{width:"100%"}}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputProps={{ 
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onMouseDown={toggleShowPassword}
                                                  onMouseUp={toggleShowPassword}
                                                >
                                                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                </IconButton>
                                              </InputAdornment>
                                            )
                                          }}
                                    />
                                </GridItemField>
                                <GridItemField>
                                    <TextField
                                        name='confirmPassword'
                                        id='confirmPassword'
                                        label='Confirm password'
                                        value={values.confirmPassword}
                                        type={showPasswordConfirm ? 'text' : 'password'}
                                        sx={{width:"100%"}}
                                        helperText={
                                            errors.confirmPassword &&
                                                touched.confirmPassword
                                                ? errors.confirmPassword
                                                : 'Re-enter password to confirm'
                                        }
                                        error={
                                            !!(
                                                errors.confirmPassword &&
                                                touched.confirmPassword
                                            )
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputProps={{ 
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onMouseDown={toggleShowPasswordConfirm}
                                                  onMouseUp={toggleShowPasswordConfirm}
                                                >
                                                  {showPasswordConfirm ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                </IconButton>
                                              </InputAdornment>
                                            )
                                          }}
                                    />
                                </GridItemField>
                                <GridItemField>
                                    <TextField
                                        name='email'
                                        id='email'
                                        label='Email-id'
                                        value={values.email}
                                        sx={{width:"100%"}}
                                        type='email'
                                        // helperText={
                                        //     errors.email && touched.email
                                        //         ? errors.email
                                        //         : 'Enter email-id'
                                        // }
                                        error={
                                            !!(errors.email && touched.email)
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </GridItemField>
                                <GridItemField>
                                    <Button
                                        type='submit'
                                        variant='contained'
                                        color='secondary'
                                        disabled={isSubmitting}
                                        sx={{width:"100%", marginBottom:".5em"}}
                                    >
                                        Submit
                                    </Button>
                                    {displayFormStatus && (
                                        <div className='formStatus'>
                                            {formStatus.type === 'error' ? (
                                                <p
                                                    style={{ color: 'red' }}
                                                >
                                                    {formStatus.message}
                                                </p>
                                            ) : formStatus.type ===
                                                'success' ? (
                                                <p
                                                    style={{ color: 'green'}}
                                                >
                                                    {formStatus.message}
                                                </p>
                                            ) : null}
                                        </div>
                                    )}
                                </GridItemField>
                                <GridItemField sx={{marginBottom:".5em!important"}}>
                                    <NavLink
                                        to='/login'
                                        style={{
                                            marginLeft: '5em',
                                            color: 'black',
                                        }}
                                    >
                                        Already have an account?
                                    </NavLink>
                                </GridItemField>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

const GridItemField = (props: GridProps) =>
    <Grid
    item
    lg={10}
    md={10}
    sm={10}
    xs={10}
    sx={{
        ...props.sx,
        maxWidth: '450px',
        display: 'block',
        margin: '0 auto',
    }}
>
    { props.children}
</Grid>

export default RegisterForm;
