import { Grid, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { ClientPpe } from "./ClientPpe";
import { GeneralSafety } from "./GeneralSafety";
import { GeneralSurveyQuestions } from "./GeneralSurveyQuestions/GeneralSurveyQuestions";
import { JobPlan } from "./JobPlan/JobPlan";
import { ProjectContacts } from "./ProjectContacts/ProjectContacts";
import { ProjectDetails } from "./ProjectDetails";
import { ProjectExecution } from "./ProjectExecution";
import { ProjectScope } from "./ProjectScope";
import { ProjectSegments } from "./ProjectSegments";
import { SpecificSafety } from "./SpecificSafety";
import { StaffingDetails } from "./StaffingDetails";
import { KickoffNotificationCrewModal } from "../KickoffNotificationCrewModal";
import { useState } from "react";
import HrButton from "../../../atoms/HrButton";


interface KickoffPacketProps {
    kickoffPacketId: string;
    bidQuoteId: string;
    customerId: string;
}

export const KickoffPacket: React.FC<KickoffPacketProps> = ({ kickoffPacketId, bidQuoteId, customerId }) => {
    const { id } = useParams<{id: string}>();
    const [crewModalOpen, setCrewModalOpen] = useState<boolean>(false);

    return(
        <Box sx={{
            width: "100%",
            background: 'no-repeat bottom/120% url("/images/mountain-logo-large.png")',
            backgroundAttachment: "fixed",
        }}>
            <HrButton style={{ margin: 10 }} onClick={() => setCrewModalOpen(true)}>Notify Crew Members</HrButton>
            <Grid container sx={{
                maxWidth: "800px",
            }}>
                <ProjectDetails jobId={id} kickoffId={kickoffPacketId}/>
                <StaffingDetails id={kickoffPacketId}/>
                <ProjectExecution id={kickoffPacketId}/>
                <ProjectContacts id={kickoffPacketId}/>
                <ProjectSegments id={kickoffPacketId} bidQuoteId={bidQuoteId} jobId={id!} />
                <ProjectScope id={kickoffPacketId}/>
                <JobPlan id={kickoffPacketId}/>
                <GeneralSafety id={kickoffPacketId}/>
                <SpecificSafety id={kickoffPacketId}/>
                <GeneralSurveyQuestions id={kickoffPacketId}/>
                <ClientPpe customerId={customerId} />
            </Grid>
            <KickoffNotificationCrewModal
                modalOpen={crewModalOpen}
                closeModal={() => setCrewModalOpen(false)}
                id={kickoffPacketId}
            />
        </Box>
    ) 
}