import { gql, useMutation, useQuery } from '@apollo/client';
import { Autocomplete, Box, FormControl, FormControlLabel, Modal, Radio, RadioGroup } from "@mui/material";
import { useFormik } from "formik";
import { v4 } from 'uuid';
import * as Yup from 'yup';
import { CompanyListQueryResult, COMPANY_LIST_QUERY } from '../../../api/graphqlqueries/companylistquery';
import { Colors } from "../../../constants/colors";
import { FormGrid } from "../../atoms/FormGrid";
import HrButton from "../../atoms/HrButton";
import HrCheckBox from "../../atoms/HrCheckBox";
import HrTextInput from "../../atoms/HrTextInput";
import { StyledHeader } from "../../atoms/StyledHeader";
import React from 'react';
import { SegmentItems } from './SegmentItems';
import { CreateJobSegmentsList } from './BidbookCreateJobsSegmentsList';
import { addMessage } from '../../../stores/ReactiveVariables';

interface CreateJobsModalProps {
    bidQuote: BidQuote
    handleClose: () => void;
    id: string;
    quoteAssociatedWithJob?: boolean;
}

interface BidQuote {
    Id: string;
    JobId: string;
    BidDescription: string;
    JobCity: string;
    JobState: string;
    Zip: string;
    CustomerId: string;
    TimeAndMaterials: boolean;
    JobNumber: number;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 4,
    px: 14,
    pb: 12,
};

const DELETE_JOB_SEGMENT_MUTATION = gql`
  mutation DeleteJobSegments($Id: uniqueidentifier = "") {
      delete_JobSegments(where: {JobId: {_eq: $Id}}) {
        affected_rows
      }
    }  
  `

const GET_QUOTE_JOB_ITEMS_QUERY = gql`
    query GetBidQuoteJobItems($Id: uniqueidentifier = "") {
      BidQuoteItems(where: {BidQuoteId: {_eq: $Id}}) {
        Id
        JobSegment {
          Id
          Job {
            Id
            CreatedAt
            Name
            Status
          }
          BidQuoteItem {
            Id
            Segment
            CreateDate
            Type
          }
        }
      }
    }
  `;

const CREATE_JOB_AND_KICKOFF_MUTATION = gql`
  mutation CreateJobAndKickoff($job: Jobs_insert_input = {}, $kickoff: KickoffPacket_insert_input = {}, $segments: [JobSegments_insert_input!] = {}) {
    insert_Jobs_one(object: $job) {
      City
      CustomerId
      DistanceCompleted
      ExpectedMiles
      ExpectedUnits
      Id
      Name
      ReqDistanceCompleted
      ReqPerDiem
      ReqSegments
      ReqUnitsCompleted
      SegmentCode
      State
      Status
      UnitsCompleted
      Zip
      isMileageBillable
      __typename
    }
    insert_KickoffPacket_one(object: $kickoff) {
      BidQuoteId
      CustomerId
      JobId
      Id
      __typename
    }
    insert_JobSegments(objects: $segments) {
      returning {
        BidQuoteItemId
        Id
        JobId
        KickoffPacketId
        Ordinal
        __typename
      }
    }
  }
`;

const GET_JOB_QUERY = gql`
query GetJob($Id: uniqueidentifier = "") {
    Jobs(where: {Id: {_eq: $Id}}) {
      Id
      City
      State
      Zip
      ExpectedMiles
      ExpectedUnits
      ReqDistanceCompleted
      ReqPerDiem
      ReqUnitsCompleted
      ReqSegments
      Status
      CustomerId
      Customer {
        Id
        Name
        ShortCode
      }
      Name
      isMileageBillable
      KickoffPacket {
        Id
        KickoffPacketProjectTypes {
          Id
          ServiceCategory {
            Id
            Name
          }
        }
      }
      AppUserJobs {
        __typename
        JobsId
        AppUsersId
        AspNetUser {
          Id
          FirstName
          LastName
        }
      }
      JobSegment {
        BidQuoteId
        BidQuoteItemId
        Id
        JobId
        BidQuote {
          Id
          JobNumber
          BidDescription
          BidQuoteExternalRevisions {
            Id
            ExternalRevision
          }
          BidQuoteStatus {
            Id
            Status
            CreateDate
          }
          Customer {
            Id
            Name
            ShortCode
          }
        }
      }
    }
  }
`;

export const CreateJobsModal: React.FC<CreateJobsModalProps> = ({ bidQuote, handleClose, id, quoteAssociatedWithJob = false }) => {
    const { data: companyData } = useQuery<CompanyListQueryResult>(COMPANY_LIST_QUERY, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            const company = data?.Customers.find(
                (c) => c.Id === formik.values.CustomerId
            )
            formik.setFieldValue('Name', `${company?.ShortCode ? company?.ShortCode : company?.Name}_${bidQuote.JobNumber}_${bidQuote.BidDescription}`)
        }
    });
    const [createJob] = useMutation(CREATE_JOB_AND_KICKOFF_MUTATION);
    const [deleteJob] = useMutation(DELETE_JOB_SEGMENT_MUTATION);
    const [open, setOpen] = React.useState(false);
    const [creating, setCreating] = React.useState(false);
    const [segments, setSegments] = React.useState<{ Id: string, Segment: string, Ordinal: number, Type: string }[]>([]);
    const toggleOpen = () => {
        setOpen(t => !t);
    }

    const handleSegments = (segments: any[]) => {
        setSegments(segments);
        setOpen(false);
    }

    const validationSchema = Yup.object({
        Name: Yup.string().required("The job name is required"),
        CustomerId: Yup.string().required("A valid customer is required"),
        City: Yup.string().required("A valid city is required"),
        State: Yup.string().required("A valid state is required"),
        Zip: Yup.string().required("A valid ZIP Code is required"),
        ReqDistanceCompleted: Yup.bool().required(),
        ReqUnitsCompleted: Yup.bool().required(),
        ReqPerDiem: Yup.bool().required(),
        ReqSegments: Yup.bool().required(),
        isMileageBillable: Yup.bool().required(),
    });

    const formik = useFormik({
        initialValues: {
            Id: v4(),
            Name: '',
            CustomerId: bidQuote?.CustomerId ?? '',
            City: bidQuote?.JobCity ?? '',
            State: bidQuote?.JobState ?? '',
            Zip: bidQuote?.Zip ?? '',
            ReqDistanceCompleted: false,
            ReqUnitsCompleted: false,
            ReqPerDiem: false,
            ReqSegments: false,
            isMileageBillable: false,
        },
        onSubmit: (values) => {
            handleSave();
        },
        validationSchema: validationSchema,
    });

    const handleSave = () => {
        setCreating(true)
        const kickoffId = v4();
        const job = {
            Id: v4(),
            Name: formik.values.Name,
            CustomerId: formik.values.CustomerId,
            City: formik.values.City,
            State: formik.values.State,
            Zip: formik.values.Zip,
            ReqDistanceCompleted: formik.values.ReqDistanceCompleted,
            ReqUnitsCompleted: formik.values.ReqUnitsCompleted,
            ReqPerDiem: formik.values.ReqPerDiem,
            ReqSegments: formik.values.ReqSegments,
            isMileageBillable: formik.values.isMileageBillable,
            Status: 'Planning',
            ExpectedUnits: 0,
            ExpectedMiles: 0,
            IsDeleted: false,
            DeletedAt: new Date(),
            CreatedAt: new Date()
        }
        const jobSegments = segments.map((s, i) => {
            return {
                Id: v4(),
                JobId: job.Id,
                BidQuoteItemId: s.Id,
                Ordinal: i,
                KickoffPacketId: kickoffId,
                BidQuoteId: bidQuote.Id,
            }
        });
        const kickoffPacket = {
            Id: kickoffId,
            JobId: job.Id,
            CustomerId: formik.values.CustomerId,
            BidQuoteId: bidQuote.Id,
            ProjectName: formik.values.Name,
        };
        const variables = {
            job,
            kickoff: kickoffPacket,
            segments: jobSegments,
        };
        if (quoteAssociatedWithJob) {
            deleteJob({
                variables: { Id: formik.values.Id },
                refetchQueries: [GET_JOB_QUERY]
            })
        }
        createJob({
            variables,
            refetchQueries: [GET_QUOTE_JOB_ITEMS_QUERY]
        }).then(() => {
            addMessage('Job created successfully', { severity: 'success', timeOut: 5000 });
            setCreating(false)
            handleClose();
        }).catch((error) => {
            console.error(error);
            setCreating(false)
            handleClose();
        })
    }
    const companyComboBoxList = companyData?.Customers ?? [];
    return (
        <Box sx={{ width: '100%' }}>
            <StyledHeader sx={{ textAlign: "Center" }}>Create Job</StyledHeader>
            <form className="ui form" onSubmit={formik.handleSubmit}>
                <FormGrid>
                    <HrTextInput
                        id="name"
                        name="Name"
                        label="Project Alias"
                        onChange={formik.handleChange}
                        value={formik.values.Name}
                        errors={formik.errors.Name}
                        disabled
                    />
                    <HrTextInput
                        id="city"
                        name="City"
                        label="City"
                        onChange={formik.handleChange}
                        value={formik.values.City}
                        errors={formik.errors.City}
                        disabled
                    />
                    <HrTextInput
                        id="state"
                        name="State"
                        label="State"
                        onChange={formik.handleChange}
                        value={formik.values.State}
                        errors={formik.errors.State}
                        disabled
                    />
                    <HrTextInput
                        id="zip"
                        name="Zip"
                        label="ZIP Code"
                        onChange={formik.handleChange}
                        value={formik.values.Zip}
                        errors={formik.errors.Zip}
                        disabled
                    />
                    <FormControl>
                        <RadioGroup>
                            <FormControlLabel
                                control={
                                    <Radio
                                        onChange={() => {
                                            formik.setFieldValue("ReqDistanceCompleted", false);
                                            formik.setFieldValue("ReqUnitsCompleted", true);
                                        }}
                                        style={{
                                            color: Colors.deepSeaGreen,
                                        }}
                                        checked={
                                            formik.values.ReqUnitsCompleted
                                                ? formik.values.ReqUnitsCompleted
                                                : false
                                        }
                                    />
                                }
                                label="Require Units Complete"
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        onChange={() => {
                                            formik.setFieldValue("ReqDistanceCompleted", true);
                                            formik.setFieldValue("ReqUnitsCompleted", false);
                                        }}
                                        style={{
                                            color: Colors.deepSeaGreen,
                                        }}
                                        checked={
                                            formik.values.ReqDistanceCompleted
                                                ? formik.values.ReqDistanceCompleted
                                                : false
                                        }
                                    />
                                }
                                label="Require Production Miles"
                            />
                        </RadioGroup>
                    </FormControl>
                    {formik.values.ReqDistanceCompleted && (
                        <HrCheckBox
                            id="reqSegments"
                            onChange={formik.handleChange}
                            name="ReqSegments"
                            label="Require Segments"
                            overrideStatusDisable={true}
                            checked={formik.values.ReqSegments}
                        />
                    )}
                    <HrCheckBox
                        id="reqPerDiem"
                        onChange={formik.handleChange}
                        name="ReqPerDiem"
                        color="primary"
                        label="Require PerDiem"
                        overrideStatusDisable={true}
                        checked={formik.values.ReqPerDiem}
                    />
                    <HrCheckBox
                        id="isMileageBillable"
                        onChange={formik.handleChange}
                        name="isMileageBillable"
                        color="primary"
                        label="Is Mileage Billable For This Job?"
                        overrideStatusDisable={true}
                        checked={formik.values.isMileageBillable}
                    />
                    <Autocomplete
                        id="customer"
                        value={companyComboBoxList.find(
                            (c) => c.Id === formik.values.CustomerId
                        )}
                        options={companyComboBoxList}
                        getOptionLabel={(option) => option.Name}
                        onChange={(event, value) => {
                            formik.setFieldValue("CustomerId", value?.Id ?? '');
                        }}
                        renderInput={(params) => (
                            <HrTextInput {...params} errors={formik.errors.CustomerId} label="Customer" variant="outlined" />
                        )}
                        disabled
                    />
                    <HrButton onClick={toggleOpen}>Choose Segments</HrButton>
                    <CreateJobSegmentsList segments={segments} />
                    <HrButton disabled={creating} onClick={handleSave} title="Create" />
                </FormGrid>
            </form>

            <Modal
                open={open}
                onClose={toggleOpen}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style }}>
                    <SegmentItems id={bidQuote?.Id ?? ""} onSave={handleSegments} />
                    <HrButton onClick={toggleOpen}>Cancel</HrButton>
                </Box>
            </Modal>
        </Box>);
}