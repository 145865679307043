import { gql } from "@apollo/client";

export interface GetBidquoteBillablesQueryResult {
  BidQuoteBillables: BidQuoteBillable[]
}

export interface BidQuoteBillable {
  Id: string;
  Name: string;
  BidQuoteId: string;
  Type: string;
  Cost: number;
  LastUpdated: string;
}
export const GET_BIDQUOTE_BILLABLES_QUERY = gql`
  query GetBidQuoteBillables ($BidQuoteId: uniqueidentifier = "") {
    BidQuoteBillables(where: {BidQuoteId: {_eq: $BidQuoteId}}){
      Id
      Name
      BidQuoteId
      Type
      Cost
      ReportAdditionalItem
      LastUpdated
    }
  }
`;

export const DELETE_BIDQUOTE_BILLABLE_MUTATION = gql`
mutation DeleteBidQuoteBillable($Id: uniqueidentifier = "") {
  delete_BidQuoteBillables_by_pk(Id: $Id) {
    Id
  }
}
`;

export const CREATE_BIDQUOTE_BILLABLE_MUTATION = gql`
mutation CreateBidQuoteBillable($object: BidQuoteBillables_insert_input = {}) {
  insert_BidQuoteBillables_one(object: $object) {
    __typename
    Id
    Name
    BidQuoteId
    Type
    Cost
    LastUpdated
	ReportAdditionalItem
  }
}
`;

export const UPDATE_BIDQUOTE_BILLABLE_MUTATION = gql`
  mutation UpdateBidQuoteBillable($Id: uniqueidentifier = "", $object: BidQuoteBillables_set_input = {}) {
    update_BidQuoteBillables_by_pk(pk_columns: {Id: $Id}, _set: $object) {
      __typename
      Id
      Name
      BidQuoteId
      Type
      Cost
      LastUpdated
    }
  }
`;