import { Add } from "@mui/icons-material";
import {
  Autocomplete, BaseTextFieldProps, Box,
  Button,
  Container,
  TextField
} from "@mui/material";
import { styled } from "@mui/system";
import { observer } from "mobx-react-lite";
import moment from "moment";
import * as React from "react";
import { useEffect } from "react";
import { Colors } from "../../constants/colors";
import { AppUser } from "../../models/appUser";
import { useStore } from "../../stores/StoreContext";

export interface ContentHeaderProps {
  title: string;
  onClick?: any;
  onClickClearFilter?: any;
  addButtonText?: string;
  hideAddButton?: boolean;
}

export const ContentHeader: React.FC<ContentHeaderProps> = observer(
  (props: ContentHeaderProps) => {
    const { commonStore, userStore, jobStore } = useStore();
    const { loadJobs } = jobStore;


    useEffect(() => {
      loadJobs();
    }, [loadJobs]);


    return (
      <FlexContainer>

        <Box display="inline-flex" flexDirection={"row"}>
            <StyledH1>{props.title}</StyledH1>
        </Box>

        <Box display="inline-flex">
          { (props.hideAddButton || userStore.appUser?.roles?.some(e => e.name === "BidBook Admin")) &&
            <StyledButton
              onClick={props.onClick}
              variant="outlined"
              color="primary"
            >
              {
                props.addButtonText ? props.addButtonText : <><Add color="primary" />
                  Add</>
              }
            </StyledButton>
          }
        </Box>
      </FlexContainer>
    );
  }
);


const FlexContainer = styled(Container)({
  display: "inline-flex",
  padding: "0",
  justifyContent: "space-between",
  paddingLeft: 20,
  paddingRight: 20,
  "& .MuiOutlinedInput-input": {
    padding: "0",
    height: "32px",
    margin: "0",
  },
  "& .MuiFormControl-root": {
    //margin: "9px 0",
    width: "320px"
  }
});

const StyledH1 = styled('h1')({
  color: "rgba(33,77,77,1)",
  padding: "0",
  margin: "9px 25px",
  fontFamily: "Montserrat, Arial",
  fontSize: "32px",
  fontWeight: "700",
});

const StyledButton = styled(Button)({
    border: "1px solid rgba(33,77,77,1)",
    height: "32px",
    margin: "9px 8px 9px 0",
});