import { useQuery } from "@apollo/client";
import { GetQuoteItemsQueryResult, GET_QUOTE_ITEMS_QUERY } from "../../../../api/graphqlqueries/getquoteitems";
import { GetQuoteFactorsQueryResult, GET_QUOTE_FACTORS_QUERY } from "../../../../api/graphqlqueries/quotefactorsquery";
import { getSurveyDivisionCalculation } from "../../../../utils/calculations/divisions";
import { HrTextInput } from "../../../atoms/HrTextInput";
import { QuoteLineItemFormGrid } from "../../../atoms/QuoteLineItemFormGrid";


export const GetQuoteSurveyTotals = (id: string) => {
    const { data, error } = useQuery<GetQuoteItemsQueryResult>(GET_QUOTE_ITEMS_QUERY, {
        variables: {
            BidQuoteId: id,
            Type: "Survey"
        },
        fetchPolicy: 'cache-and-network',
    });
    const { data: factorData } = useQuery<GetQuoteFactorsQueryResult>(GET_QUOTE_FACTORS_QUERY, {
        variables: {
            Id: id
        }
    });
    if (error)
        console.error(error);

    if (!data || !factorData) return undefined;
    const totals = getSurveyDivisionCalculation(data?.BidQuoteItems, factorData?.BidQuotes_by_pk);
    return totals;
}

export const QuoteSurveyTotals = (props: any) => {
    const { id } = props;
    const width = {
        small: "8ch",
        medium: "12ch",
        large: "18ch",
        xl: "24ch",
    }
    const inputProps = {
        sx: {
            fontSize: "14px"
        }
    }
    const totals = GetQuoteSurveyTotals(id);
    return (
        <QuoteLineItemFormGrid sx={{ flexWrap: "nowrap" }}>
            <HrTextInput
                label='Miles'
                width={width.small}
                value={totals?.Miles}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                disabled
            />
            <HrTextInput
                label='Average'
                width={width.small}
                value={totals?.AverageMiles.toFixed(2)}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                disabled
            />
            <HrTextInput
                label='Survey'
                width={width.small}
                value={totals?.Survey.toFixed(2)}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                disabled
            />
            <HrTextInput
                label='Mob'
                width={width.small}
                value={totals?.Mob}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                disabled
            />
            <HrTextInput
                label='Less Per Diem'
                width={width.medium}
                value={totals?.MobWoPD}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                disabled
            />
            <HrTextInput
                label='Setup'
                width={width.small}
                value={totals?.Setup.toFixed(2)}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                disabled
            />
            <HrTextInput
                label='Boat'
                width={width.small}
                value={totals?.Boat}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                sx={{ mr: 2 }}
                disabled
            />
            {/* <HrCurrencyInput
                name='total'
                label='Total'
                value={totals?.itemsTotal?.toString()}
                InputLabelProps={{ shrink: true }}
                width={width.small}
                disabled
            />
            <HrCurrencyInput
                name='markupTotal'
                label='Markup Total'
                value={totals?.itemsMarkupTotal?.toString()}
                InputLabelProps={{ shrink: true }}
                width={width.small}
                disabled
            /> */}
            {/* <HrTextInput
                label='Total Average Miles'
                width={width.small}
                value={totals?.averageMiles}
                InputLabelProps={{ shrink: true }}
                disabled
            /> */}
            <HrTextInput
                label='Total Days'
                width={width.small}
                value={totals?.Days.toFixed(2)}
                InputLabelProps={{ shrink: true, ...inputProps }}
                InputProps={inputProps}
                disabled
            />
        </QuoteLineItemFormGrid>
    )
}