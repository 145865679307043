import { Store } from "./store"
import React, { createContext, useContext } from "react";
import { Agent } from "../api/agent";

const myAgent = new Agent();
export const storeTest = new Store(myAgent);
storeTest.userStore.setStoreReference(storeTest);
myAgent.setStore(storeTest);
const StoreContext = createContext(storeTest);

export const StoreProvider : React.FC<{ children: any }> = ({children}) => <StoreContext.Provider value={storeTest} >
    { children }
</StoreContext.Provider>

export const store = storeTest;

export function useStore() {
    return useContext(StoreContext);
}