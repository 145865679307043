import { TabContext, TabPanel } from '@mui/lab';
import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Colors } from '../../../constants/colors';
import { HrSubTab, HrSubTabs } from '../../atoms/HrSubTabs';
import { JobSummary } from "./JobSummary";
import { KickoffPacket } from "./KickoffPacket/KickoffPacket";
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { KickoffReport } from './KickoffPacket/KickoffReport';
import { JobsFileGrid } from './JobsFileGrid';
import { useStore } from "../../../stores/StoreContext";
import { FileUpload } from "../../molecules/FileUpload";
import HrButton from '../../atoms/HrButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { v4 } from 'uuid';

interface KickoffPacket {
    Id: string;
    BidQuoteId: string;
    CustomerId: string;
}

interface KickoffPacketData {
    KickoffPacket: KickoffPacket[];
}

const KICKOFF_PACKET_ID_QUERY = gql`
query GetKickoffPacketId($JobId: uniqueidentifier = "") {
    KickoffPacket(where: {JobId: {_eq: $JobId}}) {
        Id
        BidQuoteId
        CustomerId
    }
}
`

const GET_FILES_QUERY = gql`
query GetFiles($KickoffPacketId: uniqueidentifier = "") {
  QuoteAttachments(where: {KickoffPacketId: {_eq: $KickoffPacketId}, IsActive: {_eq: true}}) {
    Id
    Filename
    Size
    CreateDate
    CreateUser {
      Id
      FirstName
      LastName
    }
  }
}
`;

const CREATE_KICKOFF_MUTATION = gql`
mutation CreateKickoffPacket($object: KickoffPacket_insert_input = {}) {
    insert_KickoffPacket_one(object: $object) {
        Id
    }
}`;

const GET_KICKOFF_SEGMENT_QUERY = gql`
query GetJobSegments($JobId: uniqueidentifier = "") {
    JobSegments(where: {JobId: {_eq: $JobId}}) {
      Id
      BidQuoteId
      Job {
        Name
        CustomerId
      }
    }
  }  
`;

interface GetFilesQueryResult {
    QuoteAttachments: QuoteAttachment[];
}

interface QuoteAttachment {
    Id: string;
    Filename: string;
    Size: number;
    CreateDate: Date;
    CreateUser: AppUser;
}

interface AppUser {
    FirstName: string;
    LastName: string;
}

interface FileUpload {
    Filename: string;
    Size?: number;
    CreateDate?: Date;
    CreatedBy?: string;
    FilePath?: string;
    Id: string;
    SharepointDocument?: boolean
}

export const JobsDetails: React.FC = () => {
    const [value, setValue] = useState('0');
    const [uploadingFile, setUploadingFile] = useState(false);
    const [fileList, setFileList] = useState<FileUpload[]>([]);
    const { userStore } = useStore();
    const agent = userStore.agent;

    const params = useParams<{ id: string, subtab: string }>();

    const { data, error, loading } = useQuery<KickoffPacketData>(KICKOFF_PACKET_ID_QUERY, {
        variables: {
            JobId: params.id
        },
        fetchPolicy: 'cache-and-network',
    });

    const [getJobSegments] = useLazyQuery(GET_KICKOFF_SEGMENT_QUERY, {
        fetchPolicy: 'cache-and-network',
    });

    const handleTabChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    const { data: fileData, refetch } = useQuery<GetFilesQueryResult>(GET_FILES_QUERY, {
        variables: {
            KickoffPacketId: data?.KickoffPacket[0]?.Id ?? "",
        },
        onCompleted: (data) => {
            setFileList([...(data?.QuoteAttachments ?? [])])
        },
        fetchPolicy: 'cache-and-network'
    });

    const [insertKickoffPacket] = useMutation(CREATE_KICKOFF_MUTATION);

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        setUploadingFile(true);
        const formData = new FormData();
        for (const file of event.target.files) {
            formData.append('files', file);
        }
        const response = await agent.Attachments.post('kickoff', params.id!, formData);
        if (response.status === 200) {
            refetch();
            alert("Files Saved.");
        }
        setUploadingFile(false);
    }

    const handleFileDrop = async (e: React.DragEvent<HTMLElement>) => {
        setUploadingFile(true);
        const formData = new FormData();
        for (const file of e?.dataTransfer?.files ?? []) {
            formData.append('files', file);
        }
        if ((e?.dataTransfer?.files ?? []).length > 0) {
            const response = await agent.Attachments.post('kickoff', params.id!, formData);
            if (response.status === 200) {
                refetch();
                alert("Files Saved.");
            }
        }
        setUploadingFile(false);
    }

    useEffect(() => {
        switch (params.subtab) {
            case "Report":
                setValue('2');
                break;
            case "Kickoff":
                setValue('1');
                break;
            case "Attachments":
                setValue('3');
                break;
            default:
                setValue('0');
                break;
        }
    }, [params.subtab])

    if (error) {
        console.error(error);
    }

    const kickoffData = data?.KickoffPacket[0];

    const createKickoffPacket = () => {
        getJobSegments({
            variables: {
                JobId: params.id
            },
            onCompleted: (data) => {
                const jobSegments = data?.JobSegments ?? [];
                if(jobSegments.length > 0){
                    const kickoffPacket = {
                        Id: v4(),
                        JobId: params.id,
                        CustomerId: jobSegments[0]?.Job?.CustomerId,
                        BidQuoteId: jobSegments[0]?.BidQuoteId,
                        ProjectName: jobSegments[0]?.Job?.Name,
                    };
                    insertKickoffPacket({
                        variables: {
                            object: kickoffPacket
                        },
                        refetchQueries: [KICKOFF_PACKET_ID_QUERY]
                    })              
                }
            }
        })
    }

    return (
        <Box sx={styles.container}>
            <TabContext value={value}>
                <HrSubTabs value={value} onChange={handleTabChange} aria-label="Bidbook Tabs" variant="scrollable">
                    <HrSubTab label="Summary" value="0" />
                    <HrSubTab label="Kickoff" value="1" />
                    <HrSubTab label="Report" value="2" />
                    <HrSubTab label="Attachments" value="3" />
                </HrSubTabs>
                <TabPanel value="0">
                    <JobSummary id={params.id} />
                </TabPanel>
                <TabPanel value="1">
                    {
                        kickoffData ?
                            <KickoffPacket
                                kickoffPacketId={kickoffData.Id}
                                bidQuoteId={kickoffData.BidQuoteId}
                                customerId={kickoffData.CustomerId}
                            />
                            :
                            <HrButton onClick={createKickoffPacket}>Generate Kickoff Packet</HrButton>
                    }
                </TabPanel>
                <TabPanel value="2">
                    {
                        kickoffData ?
                            <KickoffReport id={kickoffData.Id} />
                            :
                            <Box sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                width: "80%",
                                backgroundColor: Colors.unselectedGray,
                                borderRadius: "6px",
                                maxWidth: "800px"
                            }}>
                                <PictureAsPdfIcon sx={{
                                    fontSize: "8em",
                                    color: Colors.deepSeaGreen,
                                    pt: "45%",
                                    pb: "45%"
                                }}/>
                            </Box>
                    }
                </TabPanel>
                <TabPanel value="3">
                    <Grid container>
                        <Grid item xs={12}>
                            <FileUpload
                                onChange={handleFileUpload}
                                accept="*"
                                onDrop={handleFileDrop}
                                backgroundColor={Colors.deepSeaGreen}
                                color={Colors.white}
                                width="280px"
                                height="64px"
                                labelFontSize="16px"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <JobsFileGrid
                                data={fileList}
                            />
                        </Grid>
                    </Grid>
                </TabPanel>
            </TabContext>
        </Box>
    );
};


const styles = {
    container: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "left",
        alignItems: "left",
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "left",
        alignItems: "left",
    },
    errorLabels: {
        color: Colors.errorRed
    },
    headerStyle: {
        fontSize: '2em',
        fontWeight: 'bold',
        color: Colors.deepSeaGreen
    },
    autoComplete: {
        marginTop: ".3em",
        marginBottom: ".3em",
        width: 340,
        color: Colors.deepSeaGreen,
        display: "flex",
        flex: 1,
    },
    button: {
        color: "#fff",
        backgroundColor: Colors.deepSeaGreen,
        fontFamily: "Montserrat",
        textTransform: "uppercase",
        fontWeight: "400",
        "&:hover": {
            backgroundColor: Colors.darkSeaGreen
        }
    }
};
