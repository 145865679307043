import { useMutation, useQuery } from "@apollo/client";
import ExpandIcon from '@mui/icons-material/Expand';
import { Box, ButtonGroup, Grid, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { GET_CONSTRUCTION_ITEM_QUERY } from "../../../../api/graphqlqueries/getconstquoteitem";
import { UPDATE_QUOTE_ITEM_MUTATION } from "../../../../api/graphqlqueries/quoteitemmutations";
import { QuoteLineItemBox } from '../../../atoms/QuoteLineItemBox';
import { StyledIconButton } from "../../../atoms/StyledIconButton";
import LineItemLoading from "../../../molecules/LineItemLoading";
import { GetQuoteItemTotals } from "../Totals/BidbookQuoteItemTotals";
import { QuoteMaterialRate } from "./BidbookQuoteMaterialRate";
import { QuoteRateForm } from './BidbookQuoteRateForm';
import { QuoteServiceRate } from './BidbookQuoteServiceRate';

interface QuoteItemProps {
    bidQuoteId: string;
    id: string;
    onSave?: () => void;
    type: string;
    total: number;
}

export const QuoteRate: React.FC<QuoteItemProps> = (props: QuoteItemProps) => {
    const { id, bidQuoteId, type } = props;
    const [accordionOpen, setAccordionOpen] = useState(true);
    const [updateItem] = useMutation(UPDATE_QUOTE_ITEM_MUTATION);

    const { data, error } = useQuery(GET_CONSTRUCTION_ITEM_QUERY, {
        variables: {
            Id: id
        },
        fetchPolicy: 'cache-and-network'
    });

    const constructionTotal = GetQuoteItemTotals(bidQuoteId, "Construction");

    const openAccordion = () => {
        setAccordionOpen(!accordionOpen);
    }


    const updateItemHandler = (values: any) => {
        updateItem({ variables: { Id: values.Id, Item: values } });
    }

    if (error) {
        console.error(error);
    }
    if (!data) return <LineItemLoading />;
    const styles = {
        lineItemGrid: {
            gap: "2px",
            alignItems: 'center',
            flexWrap: "nowrap!important"
        },
        accordionIconContainer: {
            alignSelf: 'center',
            cursor: 'pointer',
            '& .MuiSvgIcon-root': {
                color: '#fff',
                backgroundColor: "rgba(0,0,0,0.6)",
                borderRadius: 1,
                fontSize: "24px"
            }
        }
    }
    const quoteItem = data?.BidQuoteItems_by_pk;
    return (
        <QuoteLineItemBox>
            <Grid item container sx={styles.lineItemGrid}>
                <Box sx={{ flexGrow: "2" }}>
                    <QuoteRateForm item={quoteItem} onSave={updateItemHandler} total={constructionTotal} type={type} />
                </Box>
                <Grid>
                    <ButtonGroup sx={{ m: "2px" }}>
                        <Tooltip describeChild title="Show/Hide Line Item" placement="left">
                            <StyledIconButton
                                onClick={() => openAccordion()}

                            ><ExpandIcon /></StyledIconButton>
                        </Tooltip>
                    </ButtonGroup>
                </Grid>
            </Grid>
            {
                accordionOpen && quoteItem.BidQuoteConstructionServices?.map((bqm: any) => {
                    return <QuoteServiceRate bidItemId={id} area={bqm.Area} id={bqm.Id} key={bqm.Id} />
                })
            }
            {
                accordionOpen && quoteItem.BidQuoteMaterials?.map((bqm: any) =>
                    <QuoteMaterialRate bidItemId={id} type={bqm.Type} id={bqm.Id} key={bqm.Id} />
                )
            }
        </QuoteLineItemBox>
    )
}