import { gql } from "@apollo/client/core";

export interface GetCustomerContactsQueryResult {
  CustomerContacts: CustomerContact[];
}

interface CustomerContact {
  Email: string;
  FirstName: string;
  Id: string;
  LastName: string;
  Phone: string;
  Title: string;
  IsActive: boolean;
  CustomerId: string;
}

export const GET_CUSTOMER_CONTACTS_QUERY = gql`
  query GetCustomerContacts($CustomerId: uniqueidentifier = "CustomerId") {
    CustomerContacts(where: {CustomerId: {_eq: $CustomerId}}) {
      Email
      FirstName
      Id
      LastName
      Phone
      Title
      IsActive
      CustomerId
    }
  }
`;