import { lineHeight, styled } from '@mui/system';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Colors } from "../../constants/colors";
import { Tooltip } from "@mui/material";
import "../../App.css";
import { FormikErrors } from 'formik';
import { useReactiveVar } from '@apollo/client';
import { disableForStatus } from '../../stores/ReactiveVariables';


export type HrTextInputProps = {
    width?: string | number;
    errors?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
    highlightOnFocus?: boolean;
    overrideStatusDisable?: boolean;
} & TextFieldProps;


export const TextInputWithWidth = ({ variant, width, errors, size, highlightOnFocus, overrideStatusDisable, ...otherProps }: HrTextInputProps) => {
    let myError = '';

    if (errors && typeof errors === 'string') {
        myError = errors;
    }
    const shouldDisableForStatus = useReactiveVar(disableForStatus);
    return <Tooltip title={myError}>
        <TextField
            {...otherProps}
            size={size ?? 'small'}
            variant={variant ?? "outlined"}
            error={otherProps.error || Boolean(myError)}
            onFocus={event => {
                if (highlightOnFocus === true) {
                    event.target.select();
                }
            }}
            //autoFocus={true}
            sx={{
                '.MuiInputBase-root': {
                    fontWeight: 500,
                    ".MuiInputBase-input": {
                        fontSize: "1rem!important",
                    },
                    'legend': {
                        fontSize: ".95rem"
                    }
                },
                '.MuiInputLabel-shrink ': {
                    transform: "translate(14px, -11px) scale(.75)!important",
                    fontSize: "1.3rem",
                    //fontWeight:500,
                }
            }}
            disabled={(shouldDisableForStatus && !overrideStatusDisable) || otherProps.disabled}
        />
    </Tooltip>;
}

export const HrTextInput = styled(TextInputWithWidth)(({ width }) => ({
    "& label.Mui-focused": {
        color: Colors.darkSeaGreen,
    },
    "& label": {
        color: Colors.darkSeaGreen,
        fontFamily: "Montserrat"
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: Colors.darkSeaGreen,
    },
    "& .MuiOutlinedInput-root": {
        fontFamily: "Montserrat",
        "& fieldset": {
            borderColor: Colors.darkSeaGreen,
        },
        "&:hover fieldset": {
            borderColor: Colors.darkSeaGreen,
        },
        "&.Mui-focused fieldset": {
            borderColor: Colors.darkSeaGreen,
        },
        "&.Mui-error fieldset": {
            borderColor: Colors.errorRed,
        }
    },
    "& .MuiInputBase-root.Mui-disabled": {
        borderColor: Colors.darkSeaGreen,
        backgroundColor: Colors.unselectedGray,
        color: Colors.black,
        webkitTextFillColor: Colors.black,
        "&.Mui-error": {
            "&:hover fieldset": {
                borderColor: Colors.errorRed,
            },
            "& fieldset": {
                borderColor: Colors.errorRed,
            },
        },
        "& fieldset": {
            borderColor: Colors.darkSeaGreen,
        },
        "&:hover fieldset": {
            borderColor: Colors.darkSeaGreen,
        },
        "&.Mui-focused fieldset": {
            borderColor: Colors.darkSeaGreen,
        },

    },
    "& label.Mui-disabled": {
        color: "black",
    },
    width: width
}));

export default HrTextInput;