import { v4 } from "uuid";
import { Box, Grid, Chip, IconButton } from '@mui/material';
import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useFormik } from 'formik';
import * as yup from "yup";
import HrTextArea from "../../../atoms/HrTextArea";


interface PaymentTermsFormProps {
    id: string;
    data: any;
    onSave: any;
    onDelete: any;
}

const validationSchema = yup.object().shape({
    Description: yup.string().required()
});

export const PaymentTermsForm: React.FC<PaymentTermsFormProps> = (props: PaymentTermsFormProps) => {
    const { data, onDelete, onSave } = props;

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            Description : ""
        },
        onSubmit: (values: any) => {
            formik.setValues({
                Description : ""
            });
            const castValues = validationSchema.cast(values); 
            onSave({ Id: v4(), ...castValues });
        }
    })


    return (
        <Box sx={{ width: '100%' }}>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={1}>
                    <Grid item xs={11}>
                        <HrTextArea
                            label="Payment Term"
                            name="Description"
                            value={formik.values.Description}
                            onChange={formik.handleChange}
                            errors={formik.errors.Description}
                            fullWidth
                            multiline
                            minRows={2}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton type='submit'>
                            <AddCircleIcon color="primary" />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Box>
                            {
                                data.map((item: any) => {
                                    return (
                                        <Chip sx={{ mr: .5, mb: .5 }} label={`${item.Description}`} onDelete={() => onDelete(item)} />
                                    )
                                })
                            }
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};
