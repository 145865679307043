import { useApolloClient, useMutation, useQuery, useReactiveVar, gql } from "@apollo/client";
import { Add } from "@mui/icons-material";
import { Box, Button, CircularProgress, Dialog, DialogContent } from "@mui/material";
import { GridColDef, GridToolbarContainer } from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import { CREATE_BIDQUOTE_BILLABLE_MUTATION, DELETE_BIDQUOTE_BILLABLE_MUTATION, GetBidquoteBillablesQueryResult, GET_BIDQUOTE_BILLABLES_QUERY, UPDATE_BIDQUOTE_BILLABLE_MUTATION } from "../../../../api/graphqlqueries/bidquotebillables";
import { appUserVar, resetMessage } from "../../../../stores/ReactiveVariables";
import dollarUS from '../../../../utils/CurrencyFormat';
import { FormGrid } from "../../../atoms/FormGrid";
import RemoveButton from "../../../atoms/RemoveButton";
import { HrDataGrid } from "../../../molecules/HrDataGrid";
import { BidbookBillablesForm } from "./BidbookBillablesForm";
import { addMessage } from "../../../../stores/ReactiveVariables";
import HrCheckBox from "../../../atoms/HrCheckBox";

const UPDATE_BILLABLE_ADDITIONAL_ITEM = gql`
mutation UpdatBidQuoteBillableAdditionalItem($Id: uniqueidentifier = "", $ReportAdditionalItem: Boolean = false) {
    update_BidQuoteBillables_by_pk(pk_columns: {Id: $Id}, _set: {ReportAdditionalItem: $ReportAdditionalItem}) {
        Id
    }
}
`;

export const BidbookBillables: React.FC<{ id: string, type: string }> = ({ id, type }) => {
    const user = useReactiveVar(appUserVar);
    const [selectedBillable, setSelectedBillable] = useState('');
    const [checkboxLoading, setCheckboxLoading] = useState<string[]>([]);
    const { data, loading, error } = useQuery<GetBidquoteBillablesQueryResult>(GET_BIDQUOTE_BILLABLES_QUERY, { variables: { BidQuoteId: id } });
    const [createBillable] = useMutation(CREATE_BIDQUOTE_BILLABLE_MUTATION);
    const [updateBillable] = useMutation(UPDATE_BIDQUOTE_BILLABLE_MUTATION);
    const [deleteBillable] = useMutation(DELETE_BIDQUOTE_BILLABLE_MUTATION);
    const [updateAdditionalItem] = useMutation(UPDATE_BILLABLE_ADDITIONAL_ITEM);
    const client = useApolloClient();
    const deleteBillableHandler = (Id: string) => {
        deleteBillable({
            variables: {
                Id
            },
            optimisticResponse: {
                delete_BidQuoteBillables_by_pk: {
                    Id,
                    __typename: "BidQuoteBillables"
                }
            },
            update(cache, { data }) {
                cache.evict({
                    id: `BidQuoteBillables:${Id}`
                });
                cache.gc();
            },
            onError(error) {
                console.error(error);
                client.refetchQueries({
                    include: "active"
                });
                addMessage(`Error Deleting ${type}`, { severity: 'error' });
            },
            onCompleted: (data) => {
                if (data)
                    addMessage(`${type} Deleted`, { severity: 'success' });
            }
        });
    }

    const handleSave = (object: any) => {
        object.BidQuoteId = id;
        object.LastUpdated = new Date();
		object.ReportAdditionalItem = false;
        if (selectedBillable === 'new') {
            createBillable({
                variables: {
                    object
                },
                optimisticResponse: {
                    insert_BidQuoteBillables_one: {
                        __typename: 'BidQuoteBillables',
                        ...object
                    }
                },
                update: (cache, { data }) => {
                    cache.updateQuery({
                        query: GET_BIDQUOTE_BILLABLES_QUERY,
                        variables: { BidQuoteId: id }
                    },
                        (cacheData: any) => ({
                            BidQuoteBillables: [...cacheData.BidQuoteBillables, data.insert_BidQuoteBillables_one]
                        }))
                },
                onCompleted: (data) => {
                    if (data)
                        addMessage(`${type} Created`, { severity: 'success' });
                }
            })
        } else {
            updateBillable({
                variables: {
                    Id: object.Id,
                    object
                },
                optimisticResponse: {
                    update_BidQuoteBillables_by_pk: {
                        __typename: 'BidQuoteBillables',
                        ...object
                    }
                },
                onCompleted: (data) => {
                    if (data)
                        addMessage(`${type} Updated`, { severity: 'success' });
                }
            });
        }
        setSelectedBillable('');
    }
    const handleAdditionalItemCheckbox = (e: any) => {
        const rowId = e.target.name;
        resetMessage();
        setCheckboxLoading([...checkboxLoading, rowId]);
        updateAdditionalItem({
            variables: {
                Id: rowId.toLowerCase(),
                ReportAdditionalItem: e.target.checked
            },
            refetchQueries: [GET_BIDQUOTE_BILLABLES_QUERY],
            awaitRefetchQueries: true,
            onCompleted: () => {
                setCheckboxLoading(checkboxLoading.filter(id => rowId === id));
                addMessage("Equipment Item Added to Additional Items", { severity: 'success' })
            }
        })
    }
    const rowsUnfiltered = data?.BidQuoteBillables ?? [];
    const rows = rowsUnfiltered.filter(r => r.Type === type);
    const selectedBillableObject = rows.find((i: any) => i.Id === selectedBillable);
    const columns: GridColDef[] = [
        {
            field: "removeBillable",
            headerName: " ",
            width: 50,
            renderCell: (rows) => {
                const billableId = rows.id;
                return (
                    <RemoveButton
                        title="Billables"
                        onClick={() => deleteBillableHandler(billableId as string)}
                    />
                );
            },
            headerClassName: "billables-grid-header",
        },
        // {
        //     field: "id",
        //     headerName: "ID",
        //     width: 100,
        //     headerClassName: "billables-grid-header",
        // },
        {
            field: "Name",
            headerName: "Item",
            width: 250,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "ReportAdditionalItem",
            headerName: "Add'l Item",
            width: 120,
            editable: false,
            headerClassName: "billables-grid-header",
            headerAlign: 'center',
            align: 'center',
            description: 'Include Item in Report Additional Rates Section',
            renderCell: (rows) => {
                if (checkboxLoading.includes(rows.id as string)) {
                    return <CircularProgress size={25} />;
                } else {
                    return <HrCheckBox
                        name={`${rows.id}`}
                        checked={rows.value}
                        onChange={handleAdditionalItemCheckbox}
                        sx={{
                            margin: 0
                        }}
                    />
                }
            },
        },
        {
            field: "Cost",
            headerName: "Cost",
            width: 150,
            editable: false,
            headerClassName: "billables-grid-header",
            valueFormatter: (params) => dollarUS.format(params.value)
        },
    ]

    if (error) {
        console.error(error);
    }
    return (
        <Box sx={{ height: '70vh' }}>
            <HrDataGrid
                loading={loading}
                rows={rows}
                columns={columns}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                disableRowSelectionOnClick
                onRowDoubleClick={(model, details) => {
                    setSelectedBillable(model.id as string);
                }}
                components={{
                    Toolbar: () => <GridToolbarContainer>
                        <Button color="primary" startIcon={<Add />} onClick={() => setSelectedBillable('new')}>
                            Add {type}
                        </Button>
                    </GridToolbarContainer>
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            removeBillable: user?.roles?.some(e => e.name === "BidBook Admin") ?? false
                        }
                    }
                }}
            />
            <Dialog
                open={selectedBillable !== ''}
                onClose={() => setSelectedBillable('')}
            >
                <DialogContent>

                    <Button
                        onClick={() => {
                            setSelectedBillable('');
                        }}
                        variant="contained"
                        color="primary"
                        className="modal-exit"
                    >
                        x
                    </Button>
                    <FormGrid>
                        <BidbookBillablesForm item={selectedBillableObject} type={type} onSave={handleSave} onCancel={() => setSelectedBillable('')} />
                    </FormGrid>
                </DialogContent>
            </Dialog>
        </Box>
    );
}