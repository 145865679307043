import { gql } from "@apollo/client/core";

export interface CompanyListQueryResult {
  Customers: Customer[];
}

export interface Customer {
  Name: string;
  ShortCode: string;
  Id: string;
}

export const COMPANY_LIST_QUERY = gql`
  query BidQuoteCompanyList {
    Customers {
      Name
      ShortCode
      Id
    }
  }
`;