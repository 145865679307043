
import { useMutation, useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { GetMaterialServiceQueryResults, GET_MATERIAL_SERVICE_QUERY } from "../../../../api/graphqlqueries/getmaterialservicequery";
import { GET_QUOTE_ADDERS } from "../../../../api/graphqlqueries/getquoteadders";
import { DELETE_ADDER_ITEM_MUTATION, UPDATE_MATERIAL_ITEM_MUTATION } from "../../../../api/graphqlqueries/materialserviceitemmutations";
import { QuoteAdderForm } from "./BidbookQuoteAdderForm";
interface ServiceItemProps {
    bidId: string;
    id?: string;
    onSave?: () => void;
}


export const QuoteAdderItem = ({ id, bidId, onSave }: ServiceItemProps) => {

    const { data, error } = useQuery<GetMaterialServiceQueryResults>(GET_MATERIAL_SERVICE_QUERY, {
        variables: {
            Id: id
        },
        fetchPolicy: 'cache-and-network'
    });

    if (error) {
        console.error(error);
    }
    const [updateServiceItem] = useMutation(UPDATE_MATERIAL_ITEM_MUTATION);
    const [deleteMaterialItem] = useMutation(DELETE_ADDER_ITEM_MUTATION);
    const deleteAdderHandler = (Id: string) => {
        deleteMaterialItem({
            variables: { Id, BidQuoteId: bidId },
            refetchQueries: [{
                query: GET_QUOTE_ADDERS,
                variables: {
                    BidQuoteId: bidId
                }
            }]
        });
    }
    const updateNewServiceItem = (formValues: any) => {
        const values = { BidQuoteId: bidId, ...formValues };
        updateServiceItem({ variables: { Id: values.Id, object: values}});
    }

    if (!data) return <CircularProgress />;
    const quoteItem = data.BidQuoteMaterials[0];
    return (<QuoteAdderForm item={quoteItem} onSave={updateNewServiceItem} onDelete={deleteAdderHandler} /> );
}