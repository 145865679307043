import { gql } from "@apollo/client/core";

interface User{
    Id : string;
    FirstName : string;
    LastName : string;
}

interface Revision{
    AspNetUser : User;
}

interface ExternalRevision extends Revision{
    ExternalRevision : string;
}

interface InternalRevision extends Revision{
    InternalRevision : string;
}

export interface QuoteRevisionDetails{
    BidQuoteInternalRevisions : InternalRevision[];
    BidQuoteExternalRevisions : ExternalRevision[];
}


export const GET_QUOTE_REVISION_DETAILS_QUERY = gql`
    query GetQuoteRevisions($BidQuoteId: uniqueidentifier = "") {
        BidQuoteInternalRevisions(where: {BidQuoteId: {_eq: $BidQuoteId}}, limit: 1, order_by: {CreateDate: desc}) {
            InternalRevision
            AspNetUser {
                Id
                FirstName
                LastName
            }
        }
        BidQuoteExternalRevisions(where: {BidQuoteId: {_eq: $BidQuoteId}}, limit: 1, order_by: {CreateDate: desc}) {
            ExternalRevision
            AspNetUser {
                Id
                FirstName
                LastName
            }
        }
    }`