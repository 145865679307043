import { Button } from "@mui/material";
import React from "react";

interface ViewButtonProps {
    onClick: (event: any) => void;
    title?: string;
}

const ViewButton = ({onClick, title}: ViewButtonProps) => {
    return (
        <Button
            onClick={onClick}
            variant="contained"
            color="primary"
            size="small"
        >
            { title ?? 'View' } 
        </Button>
    );
};

export default ViewButton;
