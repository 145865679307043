import { useQuery } from "@apollo/client";
import { GeneralSurveyQuestionsForm } from "./GeneralSurveyQuestionsForm";
import { GetGeneralSurveyQuestionsQueryResults, GET_GENERAL_SURVEY_QUESTIONS_QUERY } from "./GeneralSurveyQuestionsQueries";
import { KickoffPacketSection } from "../KickoffPacketSection";
import LineItemLoading from "../../../../molecules/LineItemLoading";

interface GeneralSurveyQuestionsProps{
    id?: string;
}

export const GeneralSurveyQuestions: React.FC<GeneralSurveyQuestionsProps> = ({ id }: GeneralSurveyQuestionsProps) => {
    const { data, error } = useQuery<GetGeneralSurveyQuestionsQueryResults>(GET_GENERAL_SURVEY_QUESTIONS_QUERY, { variables: { Id: id } });

    if (error) console.log(error);
    if (!data) return <KickoffPacketSection heading="General Survey Questions">
        <LineItemLoading />
    </KickoffPacketSection>;
    return(
        <KickoffPacketSection heading="General Survey Questions">
            <GeneralSurveyQuestionsForm  generalSurveyQuestions={data.KickoffPacket_by_pk}/>
        </KickoffPacketSection>
    ) 
}