import { Box, Modal, Typography, Grid, Button, Checkbox, FormControlLabel } from "@mui/material";
import { useState } from "react";
import { StyledHeader } from "../atoms/StyledHeader";
import { useReactiveVar } from "@apollo/client";
import { showWarning } from '../../stores/ReactiveVariables'; 
import React from "react";


interface ActionWarningProps {
    children: React.ReactNode;
    onClick: () => void;
    heading: string;
    disabled?: boolean;
}

export const DeleteWarning = (props: ActionWarningProps) => {
    const { heading, onClick, disabled } = props;
    const [modalOpen, setModalOpen] = useState(false);
    const showUserWarning: boolean = useReactiveVar(showWarning);

    const handleClick = ((e: any) => {
        if (!disabled) {
            if (showUserWarning === true) {
                setModalOpen(true);
                e.stopPropagation();
            } else {
                onClick();
            }
        }
    })
    const confirmClick = () => {
        setModalOpen(false);
        onClick();
    }
    const cancelClick = () => {
        setModalOpen(false);
    }
    const handleChange = () => {
        showWarning(!showUserWarning);
    }

    let childElement = React.Children.only(props.children);
    if (disabled && React.isValidElement(childElement)) {
        childElement = React.cloneElement(childElement, {
            // @ts-ignore
            disabled: true,
        });
    }

    return (
        <>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styles.modal}>
                    <StyledHeader sx={{ textAlign: "center" }}>
                        {heading}
                    </StyledHeader>
                    <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                        Are you sure you want to do this? It cannot be undone.
                    </Typography>
                    <Grid container sx={{ mt: .5, mb: .5 }} spacing={1}>
                        <Grid item>
                            <Button onClick={confirmClick}>
                                Ok
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={cancelClick}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                    <Box sx={styles.checkboxContainer}>
                        <FormControlLabel control={<Checkbox checked={showUserWarning ? false : true} onChange={handleChange} sx={styles.checkbox} />} label="Do not show me this again." />
                        <Typography sx={{ fontSize: "11px" }}>
                            Refresh the page to reset this setting.
                        </Typography>
                    </Box>
                </Box>
            </Modal>
            <Box onClick={(e) => handleClick(e)}>
                {childElement}
            </Box>
        </>
    )
}

const styles = {
    modal: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    },
    checkboxContainer: {
        "& .MuiFormControlLabel-label": {
            fontSize: 13,
            fontWeight: 500
        }
    },
    checkbox: {
        '& .MuiSvgIcon-root': { fontSize: 21 }
    }
}