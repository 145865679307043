import { makeVar } from "@apollo/client";
import { v4 } from "uuid";
import { AppUser } from "../models/appUser";
import { SnackMessage } from "./SnackMessage";

export const appUserVar = makeVar({} as AppUser);

export const snackMessages = makeVar([] as SnackMessage[]);

export const showWarning = makeVar<boolean>(true);

export const disableForStatus = makeVar<boolean>(false);

export const bidBookDataGridState = makeVar<string>("");
export const jobDataGridState = makeVar<string>("");

export const addMessage = (message: string, options?: { severity?: "success" | "error" | "warning" | "info", timeOut?: number }) => {
    const messages = snackMessages();
    const newMessage: SnackMessage = {
        id: v4(),
        message,
        severity: options?.severity ? options.severity : "info",
        timeOut: options?.timeOut ?? 5000
    }
    snackMessages([...messages, newMessage]);
}

export const resetMessage = () => {
    snackMessages([]);
}