//SECTION CHECKBOX

import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { Colors } from "../../../../constants/colors";
import HrCheckBox, { HrCheckBoxProps } from "../../../atoms/HrCheckBox";
import * as yup from 'yup';
import { addMessage, resetMessage } from "../../../../stores/ReactiveVariables";
import { CircularProgress } from "@mui/material";

interface AccordionCheckboxProps {
    getCoverLetter: () => void;
    name: string;
    checked: boolean;
    onCheckboxClick: any;
    onSave: any;
    loading: boolean;
}

export const AccordionCheckbox: React.FC<AccordionCheckboxProps> = ({ getCoverLetter, checked, onCheckboxClick, onSave, name, loading }: AccordionCheckboxProps) => {
    const handleCheckboxClick = (event: any) => {
        event.stopPropagation();
        onCheckboxClick();
    };

    return (
        <>
            {loading && (
                <CircularProgress sx={{ color: "#fff", mr: 1.25 }} size={20}/>
            )}
            {!loading && (
                    <HrCheckBox
                        id={name}
                        onCheckboxClick={handleCheckboxClick}
                        name={name}
                        color={Colors.white}
                        checked={checked}
                        onChange={onSave}
                        sx={{
                            mr: 0,
                            ".MuiCheckbox-root":{
                                pt: "1px", pb:"1px" 
                            } 
                        }}
                    />
                )
            }
        </>
    )
}
