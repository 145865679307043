import { DiscountOutlined } from "@mui/icons-material";
import * as yup from 'yup';
import { CircularProgress, Dialog, DialogActions, DialogContent } from "@mui/material";
import { ChangeEvent, useState } from "react";
import HrButton from "../atoms/HrButton";
import { useFormik } from "formik";
import { gql, useMutation, useReactiveVar } from "@apollo/client";
import { HrPercentInput } from "../atoms/HrPercentInput";
import { disableForStatus } from "../../stores/ReactiveVariables";

interface BidbookDiscountAllLinesButtonProps {
    type: string;
    bidQuoteId: string;
}

const discountSchema = yup.object().shape({
    discount: yup.number().required(),
})

const UPDATE_LINE_DISCOUNT_MUTATION = gql`
  mutation UpdateLineDiscount($Discount: Float = 0, $BidQuoteId: uniqueidentifier = "", $Type: String = "") {
    update_BidQuoteItems(where: {BidQuoteId: {_eq: $BidQuoteId}, Type: {_eq: $Type}}, _set: {Discount: $Discount}) {
      returning {
        __typename
        Id
        Discount
      }
    }
  }
`;

const UPDATE_LINE_DISCOUNT_SERVICES_MUTATION = gql`
  mutation UpdateLineDiscountServices($BidQuoteId: uniqueidentifier = "", $Discount: Float = 0, $Type: String = "") {
    update_BidQuoteConstructionServices(where: {BidQuoteItem: {BidQuoteId: {_eq: $BidQuoteId}, Type: {_eq: $Type}}}, _set: {Discount: $Discount}) {
      returning {
        __typename
        Id
        Discount
      }
    }
  }
`;

export const BidbookDiscountAllLinesButton: React.FC<BidbookDiscountAllLinesButtonProps> = ({ type, bidQuoteId }) => {
    const disabledForStatus = useReactiveVar(disableForStatus);
    const [open, setOpen] = useState(false);
    const [setDiscountSurvey, { loading: loadingSurvey, reset: resetSurvey }] = useMutation(UPDATE_LINE_DISCOUNT_MUTATION);
    const [setDiscountOthers, { loading: loadingOther, reset: resetOther }] = useMutation(UPDATE_LINE_DISCOUNT_SERVICES_MUTATION);
    const loading = loadingSurvey || loadingOther;
    const formik = useFormik({
        initialValues: {
            discount: 0
        },
        validationSchema: discountSchema,
        onSubmit: (values) =>  {
            const dataVals = discountSchema.cast(values);
            if (dataVals.discount !== undefined)
            {
                const variables = {
                    Discount: dataVals.discount,
                    BidQuoteId: bidQuoteId,
                    Type: type
                };
                if (type === 'Survey')
                {
                    setDiscountSurvey({
                        variables,
                        onCompleted:() => {
                            resetSurvey();
                        }
                    });
                }
                else
                {
                    setDiscountOthers({
                        variables,
                        onCompleted:() => {
                            resetOther();
                        }
                    })
                }
                setOpen(false);
                formik.resetForm()
            }
            
        }
    })
    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        formik.resetForm();
        setOpen(false);
    }

    const handlePercentChange = (e: ChangeEvent<HTMLInputElement>) => {
        const cleanNumberString = e.target.value.replace('%', '');
        const numberValue = parseFloat(cleanNumberString);
        if (isNaN(numberValue)) return;
        try {
            formik.setFieldValue("discount", numberValue / 100);
        } catch (error) {
            console.error(error);
        }
    }

    return <>
        <HrButton
            onClick={handleOpen}
            disabled={disabledForStatus}
        >{loading ? <CircularProgress size={10} /> : <DiscountOutlined/> }</HrButton>
        <Dialog
            open={open}
            onClose={handleClose}
        >
            { loading ? <CircularProgress /> : 
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <HrPercentInput
                        name="discount"
                        label="Discount"
                        value={formik.values.discount}
                        errors={formik.errors.discount}
                        onChange={handlePercentChange}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <HrButton
                        type="submit"
                    >
                        Set All {type} Line Item Discount
                    </HrButton>
                </DialogActions>
            </form>
            }
        </Dialog>

    </>
}