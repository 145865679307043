import { gql } from "@apollo/client/core";

export interface BidQuoteListQueryResult {
  BidQuotes: BidQuote[];
}

interface BidQuote {
  Id: string;
  CreateDate: Date;
  JobNumber: number;
  BidDescription: string;
  CreateUser: AppUser;
  BidQuoteStatus: BidQuoteStatus[];
  BidQuoteExternalRevisions: BidQuoteExternalRevision;
  Customer: Customer;
  Survey: aggregate;
  Construction: aggregate;
  Technician: aggregate;
  Inspection: aggregate;
  BidQuoteItems: BidQuoteItem[];
}

interface BidQuoteItem {
  Id: string;
  JobSegment: JobSegment;
}

interface JobSegment {
  Id: string;
  JobId: string;
  Job: Job;
}

interface Job {
  Id: string;
  Name: string;
}

interface aggregate {
  count: number;
}

interface AppUser {
  Id: string;
  FirstName: string;
  LastName: string;
}

interface BidQuoteStatus {
  Id: string;
  Status: string;
  UserId: string;
  CreateDate: Date;
  BidQuoteId: string;
}

interface BidQuoteExternalRevision {
  ExternalRevision: number;
  Id: string;
  CreateDate: Date;
  CreateUserId: string;
}

interface Customer {
  Id: string;
  Name: string;
  ShortCode: string;
}

export const BID_QUOTE_LIST_QUERY = gql`
query BidQuoteList {
  BidQuotes {
    Id
    CreateDate
    DueDate
    JobNumber
    BidDescription
    BidQuoteItems {
        Id
        JobSegment {
          Id
          JobId
          Job {
            Name
          }
        }
    }
    CreateUser {
      Id
      FirstName
      LastName
    }
    BidQuoteStatus(limit: 1, order_by: {CreateDate: desc}) {
      Id
      Status
      UserId
      CreateDate
      BidQuoteId
    }
    BidQuoteExternalRevisions(limit: 1, order_by: {CreateDate: desc}) {
      ExternalRevision
      Id
      CreateDate
      CreateUserId
    }
    Survey: BidQuoteItems_aggregate(where: {Type: {_eq: "Survey"}}) {
      aggregate {
        count
      }
    }
    Construction: BidQuoteItems_aggregate(where: {Type: {_eq: "Construction"}}) {
      aggregate {
        count
      }
    }
    Inspection: BidQuoteItems_aggregate(where: {Type: {_eq: "Inspection"}}) {
      aggregate {
        count
      }
    }
    Technician: BidQuoteItems_aggregate(where: {Type: {_eq: "Technician"}}) {
      aggregate {
        count
      }
    }
    Customer {
      Id
      Name
      ShortCode
    }
    BidBookUser {
      Id
      FirstName
      LastName
    }
    ProjectManager {
      Id
      FirstName
      LastName
    }
  }
}
`;
