import { gql } from "@apollo/client/core";

export interface GetFilesQueryResult {
  QuoteAttachments: QuoteAttachment[];
}

interface QuoteAttachment {
  Id: string;
  Filename: string;
  Size: number;
  CreateDate: Date;
  CreateUser: AppUser;
}

interface AppUser {
  FirstName: string;
  LastName: string;
}

export const GET_FILES_QUERY = gql`
query GetFiles($BidQuoteId: uniqueidentifier = "") {
  QuoteAttachments(where: {BidQuoteId: {_eq: $BidQuoteId}, IsActive: {_eq: true}}) {
    Id
    Filename
    Size
    CreateDate
    CreateUser {
      Id
      FirstName
      LastName
    }
  }
}
`;
