import { Grid, RadioGroup, FormLabel, InputProps } from "@mui/material"

type HrRadioGroupProps = {
    label: string;
    children: React.ReactNode;
    labelGridItemWidth?: number;
    radioGroupGridItemWidth?: number;
} & InputProps;

export const HrRadioGroup: React.FC<HrRadioGroupProps> = (props: HrRadioGroupProps) => {
    return(
        <Grid container alignItems="center">
            <Grid item xs={ props.labelGridItemWidth ? props.labelGridItemWidth : 3 }>
                <FormLabel sx={{
                    fontSize: "13px",
                    display: "inline-block"
                }}>{props.label}</FormLabel>
            </Grid>
            <Grid item xs={ props.radioGroupGridItemWidth ? props.radioGroupGridItemWidth : 2 }>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={props.value}
                    onChange={props.onChange}
                    name={props.name}
                    row
                >
                    { props.children }
                </RadioGroup>
            </Grid>
        </Grid>
    )
};