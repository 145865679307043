import { Grid, Autocomplete, IconButton, InputAdornment } from "@mui/material";
import HrTextInput from "../../../atoms/HrTextInput";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { Colors } from "../../../../constants/colors";

interface GeneralSafetyFormProps{
    generalSafetyAdminList: string[];
    onAccept: (newItem: string) => void;
}

export const GeneralSafetyForm: React.FC<GeneralSafetyFormProps> = ({ generalSafetyAdminList, onAccept }: GeneralSafetyFormProps) => {

    const [newItem, setNewItem] = useState<string>('');

    const handleChange = (event: any, value: string | null) => {
        setNewItem(value ?? '');
    }

    const handleSave = () => {
        if (newItem)
        {
            onAccept(newItem);
            setNewItem('');
        }
    }

    return(
        <Grid item xs={12}>
            <Autocomplete
                id="generalSafety"
                options={generalSafetyAdminList}
                value={newItem}
                onChange={handleChange}
                fullWidth
                renderInput={(params) => (
                    <HrTextInput
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton
                                        aria-label="Add General Safety Topic"
                                        onClick={handleSave}
                                    >
                                        <Add sx={{ color: Colors.deepSeaGreen }} />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                )}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'rgba(0, 0, 0, 0.12)'
                        }
                    }
                }}
            />
        </Grid>
    ) 
}