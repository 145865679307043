import { gql } from "@apollo/client/core";

export const CREATE_MATERIAL_ITEM_MUTATION = gql`
  mutation CreateMaterialItem($object: BidQuoteMaterials_insert_input = {}) {
    insert_BidQuoteMaterials_one(object: $object) {
      __typename
      Id
      BidQuoteItemId
      BidQuoteId
      Description
      Rate
      OverrideRate
      BidQuoteBillable {
        Id
        Name
        Cost
      }
      BidQuoteBillableId
      Markup
      Quantity
      Unit
      Type
      Duration
      Tax
      GroupWith
      Ordinal
      AdderHasCost
      LineBreakAfter
    }
  }
`;

export const UPDATE_MATERIAL_ITEM_MUTATION = gql`
  mutation UpdateMaterialItem($Id: uniqueidentifier = "", $object: BidQuoteMaterials_set_input = {}) {
    update_BidQuoteMaterials_by_pk(pk_columns: {Id: $Id}, _set: $object) {
      __typename
      Id
      BidQuoteItemId
      BidQuoteId
      Description
      Rate
      OverrideRate
      BidQuoteBillable {
        Id
        Name
        Cost
      }
      BidQuoteBillableId
      Markup
      Quantity
      Unit
      Type
      Duration
      Tax
      GroupWith
      Ordinal
      LineBreakAfter
      AdderHasCost
    }
  }
`;

export const DELETE_MATERIAL_ITEM_MUTATION = gql`
  mutation DeleteMaterialItem($Id: uniqueidentifier = "", $BidQuoteItemId: uniqueidentifier = "", $Ordinal: Int = 0) {
    delete_BidQuoteMaterials_by_pk(Id: $Id) {
      Id
    }
    update_BidQuoteMaterials(where: {Ordinal: {_gt: $Ordinal}, BidQuoteItemId: {_eq: $BidQuoteItemId}}, _inc: {Ordinal: -1}) {
      returning {
        Id
        Ordinal
      }
    }
  }
`;

export const DELETE_ADDER_ITEM_MUTATION = gql`
  mutation DeleteMaterialItem($Id: uniqueidentifier = "", $BidQuoteId: uniqueidentifier = "", $Ordinal: Int = 0) {
    delete_BidQuoteMaterials_by_pk(Id: $Id) {
      Id
    }
    update_BidQuoteMaterials(where: {Ordinal: {_gt: $Ordinal}, BidQuoteId: {_eq: $BidQuoteId}}, _inc: {Ordinal: -1}) {
      returning {
        Id
        Ordinal
      }
    }
  }
`;