import * as React from 'react';
import Box from '@mui/material/Box';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { v4 } from 'uuid';
import { Chip, Grid, IconButton } from '@mui/material';
import HrTextInput from '../../atoms/HrTextInput';

interface PPEFormProps {
  id: any;
  data: any;
  onSave: any;
  onDelete: any;
}

const validationSchema = yup.object().shape({
  PPE: yup.string().required(),
  Ordinal: yup.number().required()
});

export const ClientPPEForm: React.FC<PPEFormProps> = (props: PPEFormProps) => {
    const { data, onDelete, onSave } = props;

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            PPE: "",
            Ordinal: 0
        },
        onSubmit: (values) => {
            formik.setValues({
                PPE: "",
                Ordinal: 0
            });
            const castValues = validationSchema.cast(values);
            onSave({Id: v4(), ...castValues});
        }
    })
    return (
        <Box sx={{ width: '100%' }}>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={1}>
                    <Grid item xs={8}>
                        <HrTextInput
                            label="PPE"
                            name="PPE"
                            value={formik.values.PPE}
                            onChange={formik.handleChange}
                            errors={formik.errors.PPE}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton type='submit'>
                            <AddCircleIcon color="primary" />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Box>
                            {
                                data.map((item: any) => {
                                    return (
                                        <Chip sx={{ mr: .5, mb: .5 }} label={`${item.PPE}`} onDelete={() => onDelete(item)} />
                                    )
                                })
                            }
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}
