import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { ArrowDownward, ArrowUpward, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { useState } from "react";
import { v4 } from 'uuid';
import { GetQuoteItemsQueryResult, GET_QUOTE_ITEMS_QUERY } from "../../../api/graphqlqueries/getquoteitems";
import { ADD_QUOTE_ITEM_MUTATION, UPDATE_ORDINALS_MUTATION } from "../../../api/graphqlqueries/quoteitemmutations";
import { appUserVar, disableForStatus } from "../../../stores/ReactiveVariables";
import { HighRidgeColorButton } from "../../atoms/HighRidgeColorButton";
import { StyledIconButton } from "../../atoms/StyledIconButton";
import { VerticalButtonGroup } from "../../atoms/VerticalButtonGroup";
import LineItemLoading from '../../molecules/LineItemLoading';
import { QuoteReportComments } from './BidBookQuoteReportComments';
import { QuoteConstructionItem } from "./Construction/BidbookQuoteConstructionItem";
import { QuoteSurveyItem } from "./Survey/BidbookQuoteSurveyItem";
import { QuoteLineItemTotals } from "./Totals/BidbookQuoteLineItemTotals";
import { GET_BIDQUOTE_ITEMS_BY_TYPE } from "../../../api/graphqlqueries/getparentbidbookitems";
import { DynamicsBidNotes } from "./DynamicsBidNotes";
import { StyledHeader } from "../../atoms/StyledHeader";

interface QuoteLineItemsProps {
    id: string;
    type: string;
}

export const QuoteLineItems = ({ id, type }: QuoteLineItemsProps) => {
    const user = useReactiveVar(appUserVar);
    const disabledForStatus = useReactiveVar(disableForStatus);
    const [addingNew, setAddingNew] = useState(false);
    const { data, error } = useQuery<GetQuoteItemsQueryResult>(GET_QUOTE_ITEMS_QUERY, {
        variables: {
            BidQuoteId: id,
            Type: type
        },
        fetchPolicy: 'cache-and-network',
    });

    const [addItem] = useMutation(ADD_QUOTE_ITEM_MUTATION);

    const [updateOrdinals] = useMutation(UPDATE_ORDINALS_MUTATION);

    if (error) {
        console.error(error);
    }
    if (!data) return <LineItemLoading />

    const quoteItems = data.BidQuoteItems;
    const itemsList = quoteItems.filter((qi: any) => qi.Type === type).sort((a, b) => a.Ordinal - b.Ordinal);
    const handleNew = () => {
        setAddingNew(true);
        const userId = user?.id ?? '';
        const newItemId = v4();
        const addInfo = { BidQuoteId: id, CreateUserId: userId, Id: newItemId, Type: type, Ordinal: itemsList.length, PerDay: false, OverrideRate: false, LineBreakAfter: false };
        addItem({
            variables: { object: addInfo },
            refetchQueries: [GET_QUOTE_ITEMS_QUERY, { query: GET_BIDQUOTE_ITEMS_BY_TYPE, variables: { BidQuoteId: id, Type: type } }],
            onCompleted: (data) => {
                setAddingNew(false);
            }
        });
    }

    const handleUpArrow = (index: number) => () => {
        const items = itemsList.map(bqi => ({ Id: bqi.Id }));
        const tempItem = items[index];
        items[index] = items[index - 1];
        items[index - 1] = tempItem;
        const newOrderItems = items.map((bqi, ind) => ({ Id: bqi.Id, Ordinal: ind }));
        updateOrdinals({
            variables: { objects: newOrderItems },
            optimisticResponse: {
                insert_BidQuoteItems: {
                    returning: newOrderItems.map(noi => ({ Id: noi.Id, Ordinal: noi.Ordinal, __typename: "BidQuoteItems" }))
                }
            }
        })
    }

    const handleDownArrow = (index: number) => () => {
        const items = itemsList.map(bqi => ({ Id: bqi.Id }));
        const tempItem = items[index];
        items[index] = items[index + 1];
        items[index + 1] = tempItem;
        const newOrderItems = items.map((bqi, ind) => ({ Id: bqi.Id, Ordinal: ind }));
        updateOrdinals({
            variables: { objects: newOrderItems },
            optimisticResponse: {
                insert_BidQuoteItems: {
                    returning: newOrderItems.map(noi => ({ Id: noi.Id, Ordinal: noi.Ordinal, __typename: "BidQuoteItems" }))
                }
            }
        })
    }

    const getLineItemType = (type: string, bidId: string, id?: string) => {
        switch (type) {
            case 'Survey':
                return <QuoteSurveyItem type={type} bidId={bidId} key={id ?? 'new'} id={id} />;
            case 'Construction':
                return <QuoteConstructionItem type={type} bidId={bidId} key={id} id={id} />;
            case 'Inspection':
                return <QuoteConstructionItem type={type} bidId={bidId} key={id} id={id} />;
            case 'Technician':
                return <QuoteConstructionItem type={type} bidId={bidId} key={id} id={id} />;
        }
    }

    const getLineItem = (type: string, bidId: string, id: string, index: number) => {
        return <Grid container key={id} spacing={.5} wrap="nowrap" sx={{}}>
            <Grid item>
                <VerticalButtonGroup orientation="vertical" sx={{
                    pt: "6px"
                }}>
                    <StyledIconButton
                        onClick={handleUpArrow(index)}
                        disabled={index === 0 || disabledForStatus}
                    >
                        <ArrowUpward />
                    </StyledIconButton>
                    <StyledIconButton
                        onClick={handleDownArrow(index)}
                        disabled={index === itemsList.length - 1 || disabledForStatus}
                        sx={{}}
                    >
                        <ArrowDownward />
                    </StyledIconButton>
                </VerticalButtonGroup>
            </Grid>
            <Grid item xs={11}>
                {getLineItemType(type, bidId, id)}
            </Grid>
        </Grid>
    }

    return (
        <Grid item container>
            <StickyContainer>
                <Accordion
                    disableGutters
                    sx={{ mb: 1 }}
                >
                    <NoMarginAccordionSummary
                        expandIcon={<ExpandMore />}
                        style={{ margin: 0 }}
                    >
                        <StyledHeader>Notes and Comments</StyledHeader>
                    </NoMarginAccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <QuoteReportComments id={id} type={`${type}Comments`} title={`${type} Comments`} rows={2} />
                            </Grid>
                            <Grid item xs={6}>
                                <DynamicsBidNotes id={id} rows={2} />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <QuoteLineItemTotals type={type} id={id} />
            </StickyContainer>
            <Grid item container direction="column" mt={.5}>
                {itemsList.map((si: any, ind) => getLineItem(type, id, si.Id, ind))}
            </Grid>
            <Grid item container direction="column" sx={{ marginTop: "2px" }}>
                <HighRidgeColorButton
                    type="button"
                    onClick={handleNew}
                    disabled={addingNew || disabledForStatus}
                >
                    {
                        addingNew ? <CircularProgress /> : `Add new ${type} Item`
                    }
                </HighRidgeColorButton>
            </Grid>
        </Grid>
    )
}

const StickyContainer = styled(Box)({
    position: "sticky",
    top: "39px",
    width: "100%",
    backgroundColor: "#fff",
    zIndex: 3
});

const NoMarginAccordionSummary = styled(AccordionSummary)({
    '& .MuiAccordionSummary-content': {
        margin: 0
    }
});