import { GridColDef, useGridApiRef } from "@mui/x-data-grid-pro";
import { NavLink, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStore } from "../../../stores/StoreContext";
import RemoveButton from "../../atoms/RemoveButton";
import { HrDataGrid } from "../../molecules/HrDataGrid";
import { gql, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { GET_JOB_PUNCHES_QUERY, JobQuery, Job } from '../../../api/graphqlqueries/jobpunchesquery';
import ArticleIcon from '@mui/icons-material/Article';
import { addMessage, jobDataGridState } from "../../../stores/ReactiveVariables";
import moment from "moment";
import { orderBy } from "lodash"

export interface JobsDataGridProps {
    props?: any;
}

interface Jobs {
    [propName: string]: Job;
}

const GET_JOBS = gql`
query GetJobs {
    Jobs(where: {IsDeleted: {_eq: false}}) {
      Id
      Name
      ReqDistanceCompleted
      ReqUnitsCompleted
      ReqPerDiem
      Customer {
        Name
        Id
      }
      Status
      City
      State
      Zip
      Punches_aggregate {
        aggregate {
          sum {
            Mileage
          }
        }
      }
      JobSegment {
        Id
        JobId
        BidQuoteItemId
        BidQuoteId
        KickoffPacketId
      }
      KickoffPacket {
        BidQuoteId
        Id
        KickoffPacketProjectTypes {
          Id
          ServiceCategory {
            Id
            Name
          }
        }
      }
      ExpectedMiles
    }
  }   
`

const DELETE_JOB_MUTATION = gql`
mutation DeleteJob($Id: uniqueidentifier = "", $DeletedAt: datetime2 = "") {
    update_Jobs(where: {Id: {_eq: $Id}}, _set: {IsDeleted: true, DeletedAt: $DeletedAt}) {
      affected_rows
    }
}  
`;

const convertDate = (dateString: string) => {
    var m = moment.utc(dateString);
    m.tz('America/Chicago');
    return m.local().toDate();
}

export const JobsDataGrid: React.FC<JobsDataGridProps> = observer(() => {
    const { data } = useQuery<JobQuery>(GET_JOB_PUNCHES_QUERY, { fetchPolicy: 'cache-and-network' });
    const { data: jobData } = useQuery(GET_JOBS, { fetchPolicy: 'cache-and-network' });

    const [deleteJob] = useMutation(DELETE_JOB_MUTATION);

    const jobsObj: Jobs = {};
    const apiRef = useGridApiRef();

    const dataGridState = useReactiveVar(jobDataGridState);

    useEffect(() => {
        try {
            if (dataGridState) {
                apiRef.current.restoreState(JSON.parse(dataGridState))
                apiRef.current.hideFilterPanel();
            }
        } catch (e) {
            console.log(e);
        }
    }, []);

    data?.Jobs?.forEach((job: Job) => {
        jobsObj[job.Id.toLowerCase() as keyof typeof jobsObj] = job;
    });

    let rows = jobData?.Jobs?.map((job: any) => {
        const punchData = jobsObj[job.Id]?.Punches_aggregate?.aggregate?.sum;
        const milesCompletedPercentage = Number(job.ExpectedMiles) !== 0 ? Number(punchData?.DistanceCompleted) / Number(job.ExpectedMiles) : 0;
        const unitsCompletedPercentage = Number(job.expectedUnits) !== 0 ? Number(punchData?.UnitsCompleted) / Number(job.expectedUnits) : 0;
        const quoteId = job.JobSegment?.[0]?.BidQuoteId;
        const jobTypesString = job.KickoffPacket?.KickoffPacketProjectTypes?.map((projectType: any) => projectType.ServiceCategory?.Name).join(", ");
        return {
            id: job.Id,
            name: job.Name,
            sortKey: job.Name.toLowerCase().replace(/[^a-zA-Z0-9]+/g, ""),
            reqMilesCompleted: job.ReqDistanceCompleted,
            reqUnitsCompleted: job.ReqUnitsCompleted,
            reqPerDiem: job.ReqPerDiem,
            customer: job.Customer?.Name,
            status: job.Status,
            city: job.City ? job.City : "No City Value",
            state: job.State ? job.State : "No State Value",
            zip: job.Zip ? job.Zip : "No ZIP Code",
            unitsCompletedPercentage: unitsCompletedPercentage !== 0 ? `${(unitsCompletedPercentage * 100).toFixed()}%` : "",
            milesCompletedPercentage: milesCompletedPercentage !== 0 ? `${(milesCompletedPercentage * 100).toFixed()}%` : "",
            quoteId: quoteId,
            jobTypes: jobTypesString
        }
    }) ?? [];

    rows = orderBy(rows, ['sortKey'], ['asc']);

    const navigate = useNavigate();

    const columns: GridColDef<any>[] = [
        {
            field: "deleteJob",
            headerName: " ",
            width: 50,
            renderCell: (rows) => {
                const jobId = rows.id;
                return (
                    <RemoveButton
                        title="Job"
                        onClick={() => {
                            deleteJob({
                                variables: {
                                    Id: jobId,
                                    DeletedAt: convertDate(new Date().toString())
                                },
                                onCompleted: (data) => {
                                    if (data) {
                                        addMessage(`Job deleted successfully`, { severity: 'success' });
                                    }
                                },
                                onError: () => {
                                    addMessage(`Error deleting job`, { severity: 'error' });
                                },
                                refetchQueries: [GET_JOBS]
                            });
                        }}
                    />
                );
            },
            headerClassName: "billables-grid-header",
        },
        {
            field: "name",
            headerName: "Project Alias",
            type: "string",
            width: 250,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "customer",
            headerName: "Customer",
            type: "string",
            width: 250,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "quote",
            headerName: "Quote",
            type: "string",
            width: 80,
            headerClassName: "billables-grid-header",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                if (params.row?.quoteId) {
                    return <NavLink to={`/Bidbook/details/${params.row?.quoteId}`}><ArticleIcon color="primary" /></NavLink>;
                } else {
                    return null
                }
            }
        },
        {
            field: "status",
            headerName: "Status",
            type: "string",
            width: 150,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "jobTypes",
            headerName: "Job Types",
            type: "string",
            width: 300,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "city",
            headerName: "City",
            type: "string",
            width: 150,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "state",
            headerName: "State",
            type: "string",
            width: 150,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "zip",
            headerName: "ZIP Code",
            type: "string",
            width: 150,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "reqNotes",
            headerName: "Require Notes",
            type: "boolean",
            width: 200,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "reqDistanceCompleted",
            headerName: "Require Distance Completed",
            type: "boolean",
            width: 300,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "reqUnitsCompleted",
            headerName: "Require Units Completed",
            type: "boolean",
            width: 300,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "milesCompletedPercentage",
            headerName: "Miles % Complete",
            type: "number",
            width: 150,
            editable: false,
            headerClassName: "billables-grid-header",
            align: "center"
        },
        {
            field: "unitsCompletedPercentage",
            headerName: "Units % Complete",
            type: "number",
            width: 150,
            editable: false,
            headerClassName: "billables-grid-header",
            align: "center"
        },
        {
            field: "reqPerDiem",
            headerName: "Require PerDiem",
            type: "boolean",
            width: 200,
            editable: false,
            headerClassName: "billables-grid-header",
        },
    ];


    return (
        <HrDataGrid
            apiRef={apiRef}
            rows={rows}
            getRowId={undefined}
            columns={columns}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            onRowDoubleClick={(row) => navigate(`/Jobs/${row.id}`)}
            onFilterModelChange={() => {
                const state = apiRef.current.exportState();
                jobDataGridState(JSON.stringify(state));
            }}
            onSortModelChange={() => {
                const state = apiRef.current.exportState();
                jobDataGridState(JSON.stringify(state));
            }}
            initialState={{
                ...apiRef.current.state,
                columns: {
                    columnVisibilityModel: {
                        firstName: false,
                    }
                }
            }}
        />
    );
});
