import React from "react";
import { Box } from '@mui/material';
import { ContentHeader } from "../ContentHeader";
import { styled } from "@mui/system";
import { Tabs } from "@mui/material";
import TabNavButton from "../../atoms/TabNavButton";
import { Route, Routes } from "react-router";
import { BidTracker } from  "./BidTracker";
import { DaysPastSubmission } from "./DaysPastSubmission";


interface DashboardContentProps {

}

function a11yProps(page: string) {
    return {
      value: page,
      id: `nav-tab-${page}`,
      'aria-controls': `nav-tabpanel-${page}`,
    };
  }

export const DashboardContent: React.FC<DashboardContentProps> = (props) => {

    return (
        <Box>
            <ContentHeader title="Dashboard" hideAddButton={true}/>
            <TabsBar aria-label="simple tabs example">
                <TabNavButton label="Bid Tracker" to="/Dashboard/BidTracker" {...a11yProps('/Dashboard/BidTracker')} />
                <TabNavButton label="Days Past Submission" to="/Dashboard/DaysPastSubmission" {...a11yProps('/Dashboard/DaysPastSubmission')} />
            </TabsBar>
            <Routes>
                <Route path={"/BidTracker"} element={<BidTracker />} />
                <Route path={"/DaysPastSubmission"} element={<DaysPastSubmission />} />
            </Routes>
        </Box>
    );
};

const TabsBar = styled(Tabs)({
    borderBottom: '1px solid #E9C246',
    minHeight: '20px !important',
});