//SECTION CHECKBOX

import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import * as yup from 'yup';
import { addMessage, resetMessage } from "../../../../../stores/ReactiveVariables";
import { AccordionCheckbox } from "../AccordionCheckbox";

const GET_COVER_LETTER_PAYMENT_TERMS_SECTION = gql`
    query GetCoverLetterPaymentTerms($Id: uniqueidentifier = "") {
        BidQuotes_by_pk(Id: $Id) {
            CoverLetterPaymentTerms
        }
    }  
`;

const UPDATE_COVER_LETTER_PAYMENT_TERMS_SECTION = gql`
    mutation UpdateCoverLetterPaymentTermsSection($Id: uniqueidentifier = "", $CoverLetterPaymentTerms: Boolean = false) {
        update_BidQuotes_by_pk(pk_columns: {Id: $Id}, _set: {CoverLetterPaymentTerms: $CoverLetterPaymentTerms}) {
            __typename
            Id
        }
    }
`;

interface PaymentTermsCheckboxProps {
    id: string;
    getCoverLetter: () => void;
}

const validationSchema = yup.object().shape({
    CoverLetterPaymentTerms: yup.boolean(),
});

export const PaymentTermsCheckbox: React.FC<PaymentTermsCheckboxProps> = ({ id, getCoverLetter }: PaymentTermsCheckboxProps) => {
    const { data, loading, error } = useQuery(GET_COVER_LETTER_PAYMENT_TERMS_SECTION, { variables: { Id: id } });
    const [updateCoverLetterExecutionPlan] = useMutation(UPDATE_COVER_LETTER_PAYMENT_TERMS_SECTION);

    const formData = data?.BidQuotes_by_pk;

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            CoverLetterPaymentTerms: formData?.CoverLetterPaymentTerms ?? false,
        },
        onSubmit: (values: any) => {
            updateCoverLetterExecutionPlan({
                variables: { Id: id, CoverLetterPaymentTerms: values.CoverLetterPaymentTerms },
                onCompleted: (data: any) => {
                    resetMessage();
                    const confirmMessage = values.CoverLetterPaymentTerms === true ? "Payment Terms Added To Cover Letter" : "Payment Terms Removed From Cover Letter";
                    addMessage(confirmMessage, { severity: 'success' });
                    getCoverLetter();
                },
                onError: () => {
                    addMessage('Error Saving/Removing Payment Terms', { severity: 'error' });
                }
            });
        }
    });
    return (
        <AccordionCheckbox
            getCoverLetter={getCoverLetter}
            name="CoverLetterPaymentTerms"
            checked={formik.values.CoverLetterPaymentTerms}
            onCheckboxClick={() => formik.setFieldValue('CoverLetterPaymentTerms', !formik.values.CoverLetterPaymentTerms)}
            onSave={formik.submitForm}
            loading={loading}
        />
    )
}

