import { useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import { GET_QUOTE_ITEMS_QUERY } from "../../../../api/graphqlqueries/getquoteitems";
import { StyledHeader } from "../../../atoms/StyledHeader";
import { BidbookDiscountAllLinesButton } from "../../../molecules/BidbookDiscountAllLinesButton";
import { RateHeading } from "../../../molecules/BidbookRateHeading";
import LineItemLoading from "../../../molecules/LineItemLoading";
import { QuoteSurveyPricingTotals } from "../Totals/QuoteSurveyPricingTotals";
import { QuoteAddersRates } from "./BidbookQuoteAddersRates";
import { QuoteSurveyRate } from "./BidbookQuoteSurveyRate";
import dollarUS from "../../../../utils/CurrencyFormat";
import { GET_QUOTE_FACTORS_QUERY } from "../../../../api/graphqlqueries/quotefactorsquery";

interface QuoteLineItemsProps {
    bidQuoteId: string;
    total: number;
}

export const QuoteSurveyRates = ({ bidQuoteId, total }: QuoteLineItemsProps) => {
    let type = "Survey";
    const { loading, data, error } = useQuery(GET_QUOTE_ITEMS_QUERY, {
        variables: {
            BidQuoteId: bidQuoteId,
            Type: type
        },
        fetchPolicy: 'cache-and-network',
    });

    const { data: factorData } = useQuery(GET_QUOTE_FACTORS_QUERY, {
        variables: {
            Id: bidQuoteId
        }
    });

    const quoteFactors = factorData?.BidQuotes_by_pk;

    if (error) {
        console.error(error);
    }
    if (loading && !data) return <LineItemLoading />

    const quoteItems = data.BidQuoteItems;

    if (quoteItems?.length === 0) {
        return null
    }

    const itemsList = quoteItems.filter((qi: any) => qi.Type === type).sort((a: any, b: any) => a.Ordinal - b.Ordinal)
    return (
        <Grid item container direction="column">
            <RateHeading>
                <Grid item alignSelf="flex-end">
                    <StyledHeader sx={{margin:0}}>Survey</StyledHeader>
                </Grid>
                <Grid item>
                    <BidbookDiscountAllLinesButton type="Survey" bidQuoteId={bidQuoteId} />
                </Grid>
                <Grid item>
                    <QuoteSurveyPricingTotals id={bidQuoteId} bidQuoteItems={itemsList} quoteFactors={quoteFactors}/>
                </Grid>
                <Grid item alignSelf="flex-end" sx={{marginLeft:"auto"}}>
                    <StyledHeader>{dollarUS.format(total)}</StyledHeader>
                </Grid> 
            </RateHeading>
            <Grid item container direction="column">
                {itemsList.map((si: any) => <QuoteSurveyRate bidQuoteId={bidQuoteId} key={si.Id ?? 'new'} id={si.Id} />)}
            </Grid>
        </Grid>
    )
}