import React from 'react';
import * as yup from 'yup';
import { useFormik } from "formik";
import { v4 } from 'uuid';
import { HighRidgeColorButton } from "../../atoms/HighRidgeColorButton"; 
import { Box, Button, Grid, MenuItem } from "@mui/material";
import HrTextInput from "../../atoms/HrTextInput";
import { HrCurrencyInput } from '../../atoms/HrCurrencyInput';

interface ServicesItem {
    Id: string;
    Name: string;
    Rate: number;
    Cost: number;
    DailyHours: number | null;
    ServiceType: string | null;
    BidQuoteId?: string;
}
interface ServicesFormProps {
    item?: ServicesItem;
    onSave: (item: ServicesItem) => void;
    onCancel?: () => void;
    forBidService?: boolean;
    bidQuoteId?: string;
}


const ServicesItemSchema = yup.object().shape({
    Name: yup.string().required(),
    Rate: yup.number().required(),
    Cost: yup.number(),
    DailyHours: yup.number().transform(value => isNaN(value) ? null : value).nullable(),
    ServiceType: yup.string()
});


export const ServicesForm: React.FC<ServicesFormProps> = ({ item, onSave, onCancel, forBidService, bidQuoteId }) => {
    const Id = item?.Id ?? v4();
    const formik = useFormik({
        validationSchema: ServicesItemSchema,
        initialValues: {
            Name: item?.Name ?? '',
            Rate: item?.Rate ?? 0,
            Cost: item?.Cost ?? 0,
            DailyHours: item?.DailyHours ?? null,
            ServiceType: item?.ServiceType ? item?.ServiceType : "Field"
        },
        onSubmit: (unCastValues) => {
            const values = ServicesItemSchema.cast(unCastValues);
            const { Name, Rate, DailyHours, ServiceType, Cost } = values;
            if (forBidService) {
                onSave({ Id, BidQuoteId: bidQuoteId, Name: Name!, Rate: Rate!, DailyHours: DailyHours!, ServiceType: ServiceType!, Cost: Cost! });
            } else {
                onSave({ Id, Name: Name!, Rate: Rate!, DailyHours: DailyHours!, ServiceType: ServiceType!, Cost: Cost! });
            }
            formik.setSubmitting(false);
        }
    });
    return (<React.Fragment>
        <Box>
            <HrTextInput
                name='Name'
                label='Name'
                onChange={formik.handleChange}
                value={formik.values.Name}
                errors={formik.errors.Name}
                fullWidth
            />
        </Box>
        <Box>
            <HrTextInput
                select
                name='ServiceType'
                label='Service Type'
                onChange={formik.handleChange}
                value={formik.values.ServiceType}
                errors={formik.errors.ServiceType}
                disabled={forBidService && !bidQuoteId}
                fullWidth
            >
                <MenuItem value="Field">Field</MenuItem>
                <MenuItem value="Office">Office</MenuItem>
            </HrTextInput>

        </Box>
        <Box>
            <HrTextInput
                select
                name='DailyHours'
                label='DailyHours'
                onChange={formik.handleChange}
                value={formik.values.DailyHours}
                errors={formik.errors.DailyHours}
                fullWidth
            >
                <MenuItem value={''}>- None -</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={12}>12</MenuItem>
            </HrTextInput>

        </Box>
        <Box>
            <HrCurrencyInput
                name='Rate'
                label='Rate'
                onChange={formik.handleChange}
                value={formik.values.Rate}
                errors={formik.errors.Rate}
                fullWidth
            />
        </Box>
        <Box>
            <HrCurrencyInput
                name='Cost'
                label='Hourly Cost'
                onChange={formik.handleChange}
                value={formik.values.Cost}
                errors={formik.errors.Cost}
                fullWidth
            />
        </Box>
        <Grid container justifyContent="space-between">
            <HighRidgeColorButton
                type="button"
                onClick={formik.submitForm}
            >Save Item</HighRidgeColorButton>
            {
                onCancel &&
                <Button
                    color="secondary"
                    type="button"
                    onClick={onCancel}
                >
                    Cancel
                </Button>

            }
        </Grid>
    </React.Fragment>);
}