import { gql } from "@apollo/client";

export interface GetServiceRatesQueryResults {
  ServiceRates: ServiceRate[];
}

interface ServiceRate {
  Id: string;
  Name: string;
  DailyHours: number | null;
  Rate: number;
  Cost: number;
  ServiceType: string | null;
}

export const GET_SERVICE_RATES_QUERY = gql`
  query GetServiceRates {
    ServiceRates {
      Id
      Name
      DailyHours
      ServiceType
      Rate
      Cost
    }
  }
`;

export const DELETE_SERVICE_RATE_MUTATION = gql`
mutation DeleteServiceRate($Id: uniqueidentifier = "") {
  delete_ServiceRates_by_pk(Id: $Id) {
    Id
  }
}
`;

export const CREATE_SERVICE_RATE_MUTATION = gql`
mutation CreateServiceRate($object: ServiceRates_insert_input = {}) {
  insert_ServiceRates_one(object: $object) {
    __typename
    Id
    Name
    DailyHours
    Rate
    Cost
    ServiceType
  }
}
`;

export const UPDATE_SERVICE_RATE_MUTATION = gql`
  mutation UpdateServiceRate($Id: uniqueidentifier = "", $object: ServiceRates_set_input = {}) {
    update_ServiceRates_by_pk(pk_columns: {Id: $Id}, _set: $object) {
      __typename
      Id
      Name
      DailyHours
      Rate
      Cost
      ServiceType
    }
  }
`;