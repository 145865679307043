import React from "react";
import Footer from "../organisms/Footer";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

export default function PrivacyPolicyPage() {
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100vh",
            background: 'no-repeat bottom/100% url("/images/mountain-logo-large.png")',
        }}>
            <div style={{ marginTop: '2rem'}}>
                <Button
                    sx={{ mr: 10 }}
                    component={Link}
                    to='/login'
                    size="large"
                    variant="contained"
                >
                    Login
                </Button>
                <Button
                    component={Link}
                    to='/register'
                    size="large"
                    variant="contained"
                >
                    Register
                </Button>

            </div>
            <div style={{
                maxWidth: "80%",
            }}>
                <h3>Privacy Policy</h3>
                <p>Highridge Corrosion Services built the HR Timesheets app as a Free app for internal use by employees of Highridge Corrosion Services. This SERVICE is provided by Highridge Corrosion Services at no cost and is intended for use as is.</p>
                <p>This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.</p>
                <p>If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
                <h3>Information Collection and Use</h3>
                <p>(i) Our app uses location services to gather location data upon clock in an out to validate relevant information regarding proximity to job sites etc.</p>
                <p>(ii)This information is gathered using location services provided by your mobile device, and is only collected while the application is running in the foreground (app is open on screen)</p>
                <p>(iii) This location information will be used by management at Highridge Corrosion Services (your employer) to validate proximity to job sites while on the clock</p>
                <p>(iv) This information will be kept on file indefinetely, saved alongside the other information gathered during app use to facility payroll and other services by HR</p>
                <p>(v) If you no longer wish to allow location services, you will no longer be able to use this application to capture your time for payroll. This service can be denied when you begin using the app and can be disabled through settings at any time.</p>
                <p>(vi) If you wish for all relevant location data to be removed, notify the HR department, and all information captured from you device will be removed upon termination of employment with Highridge Corrosion Services.</p>
                <h3>Security</h3>
                <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
                <h3>Children’s Privacy</h3>
                <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13 years of age. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>
                <h3>Changes to This Privacy Policy</h3>
                <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                <p>This policy is effective as of 2021-10-01</p>
                <h3>Contact Us</h3>
                <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at cpowell@hrcorrosion.com.</p>
            </div>
            <div style={{
                borderWidth: 1,
                marginBottom: 50,
            }}>
                <Footer />
            </div>
        </div>
    );
}