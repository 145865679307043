import { Grid } from "@mui/material";
import { Colors } from "../../constants/colors";

interface RateHeadingProps{
    children : React.ReactNode;
}

export const RateHeading = ({ children } : RateHeadingProps) => {
    return(
        <Grid item container alignItems="center" spacing={1} sx={{borderBottom:`3px solid ${Colors.deepSeaGreen}`, marginBottom : "4px", padding:"3px 0"}}>
            { children }
        </Grid>
    )
}