import { action, makeAutoObservable, runInAction } from "mobx";
import Agent from "../api/agent";
import { AppUser, UserFormValues } from "../models/appUser";

export default class EmployeeStore {

    employees: AppUser[] | [] = [];
    selectedEmployee: AppUser | undefined = undefined;
    editOrCreateMode: boolean = false;
    agent: Agent;

    constructor(myAgent: Agent) {
        makeAutoObservable(this);
        this.agent = myAgent;
    }

    @action
    setSelectedEmployee = (id: string | undefined) => {
        if (id === undefined) {
            this.selectedEmployee = undefined;
        } else {
            this.selectedEmployee = this.employees.find(x => x.id === id);
        }
    }

    @action
    setEditModeToTrue = () => {
        this.editOrCreateMode = true;
    }

    @action
    setEditModeToFalse = () => {
        this.editOrCreateMode = false;
    }

    setEmployees = (values: AppUser[]) => {
        runInAction(() => {
            this.employees = values;
        })
    }

    getEmployees = async () => {
        try {
            var employeesList = await this.agent.AppUsers.list();
            this.setEmployees(employeesList);
        } catch (error) {
            console.log(error);
        }
    }

    createEmployee = async (employee: UserFormValues) => {
        try {
            await this.agent.AppUsers.register(employee)
            await this.getEmployees();
        } catch (error) {
            console.log(error)
        }
    }

    updateEmployee = async (employee: UserFormValues, id: string) => {
        try {
            await this.agent.AppUsers.update(employee, id)
            await this.getEmployees();
        } catch (error) {
            console.log(error)
        }
    }


    deleteEmployee = async (id: string) => {
        try {
            await this.agent.AppUsers.changeIsActive(id)
            await this.getEmployees();
        } catch (error) {
            console.log(error)
        }
    }

}