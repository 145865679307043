import { gql, useMutation, useQuery } from "@apollo/client";
import { Box } from "@mui/material"
import { v4 } from "uuid";
import { addMessage } from "../../../../stores/ReactiveVariables";
import { PaymentTermsForm } from "./PaymentTermsForm";

interface PaymentTermsProps {
    id: string;
    getCoverLetter: () => void;
}

interface PaymentTermsItem {
    Description: string,
    Id: string,
    Miles: number,
    Ordinal: number
}

const GET_COVER_LETTER_PAYMENT_TERMS = gql`
query GetCoverLetterPaymentTerms($BidQuoteId: uniqueidentifier = "") {
    CoverLetterPaymentTerms(where: {BidQuoteId: {_eq: $BidQuoteId}}) {
        Id
        BidQuoteId
        Description
    }
}
`;

const INSERT_COVER_LETTER_PAYMENT_TERM = gql`
mutation InsertCoverLetterPaymentTerm($object: CoverLetterPaymentTerms_insert_input = {}) {
    insert_CoverLetterPaymentTerms_one(object: $object) {
        BidQuoteId
        Description
        Id
    }
}
`

const DELETE_COVER_LETTER_PAYMENT_TERM = gql`
mutation DeleteCoverLetterPaymentTerm($Id: uniqueidentifier = "") {
    delete_CoverLetterPaymentTerms_by_pk(Id: $Id) {
        Id
    }
}  
`



export const PaymentTerms: React.FC<PaymentTermsProps> = (props: PaymentTermsProps) => {
    const { id, getCoverLetter } = props;

    const { data, error } = useQuery(GET_COVER_LETTER_PAYMENT_TERMS, { variables: { BidQuoteId: id } });

    const [insertCoverLetterPaymentTerm] = useMutation(INSERT_COVER_LETTER_PAYMENT_TERM);

    const [deleteCoverLetterPaymentTerm] = useMutation(DELETE_COVER_LETTER_PAYMENT_TERM);

    const formData = data?.CoverLetterPaymentTerms ?? [];
    if (error) console.log(error);
    const onSave = (values: any) => {
        insertCoverLetterPaymentTerm({
            variables: {
                object: {
                    Id: v4(),
                    BidQuoteId: id,
                    Description: values.Description
                }
            },
            refetchQueries: [GET_COVER_LETTER_PAYMENT_TERMS],
            onCompleted: () => {
                getCoverLetter();
                addMessage('Payment Term Added', { severity: 'success' });
            }
        });
    }

    const onDelete = (item: PaymentTermsItem) => {
        deleteCoverLetterPaymentTerm({
            variables: { Id: item.Id.toLowerCase() },
            refetchQueries: [GET_COVER_LETTER_PAYMENT_TERMS],
            onCompleted: () => {
                getCoverLetter();
                addMessage('Payment Term Deleted', { severity: 'success' });
            }
        });
    }

    return (
        <Box sx={{ width: '100%' }}>
            <PaymentTermsForm id={id} data={formData} onSave={onSave} onDelete={(item: PaymentTermsItem) => onDelete(item)} />
        </Box>
    )
}