import React from 'react';
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { Grid } from "@mui/material";
import LineItemLoading from '../../../molecules/LineItemLoading';
import { DELETE_MATERIAL_ITEM_MUTATION, UPDATE_MATERIAL_ITEM_MUTATION } from "../../../../api/graphqlqueries/materialserviceitemmutations";
import { GetMaterialServiceQueryResults, GET_MATERIAL_SERVICE_QUERY } from "../../../../api/graphqlqueries/getmaterialservicequery";
import { QuoteMaterialItemForm } from "./BidbookQuoteMaterialForm";
import { TypeBox } from "../../../molecules/TypeBox";

interface MaterialItemProps {
    bidItemId?: string;
    id?: string;
    type: string;
    onSave?: () => void;
}


export const QuoteMaterialItem = ({ id, bidItemId, onSave, type }: MaterialItemProps) => {


    const { data, error } = useQuery<GetMaterialServiceQueryResults>(GET_MATERIAL_SERVICE_QUERY, {
        variables: {
            Id: id
        }
    });
    const client = useApolloClient();
    const [updateMaterialItem] = useMutation(UPDATE_MATERIAL_ITEM_MUTATION);
    const updateNewMaterialItem = (values: any) => {
        
        updateMaterialItem({ variables: { Id: values.Id, object: values}});
    }

    const [deleteMaterialItem] = useMutation(DELETE_MATERIAL_ITEM_MUTATION);
    const materialItem = data?.BidQuoteMaterials[0];
    const deleteMaterialItemHandler = (Id: string) => {
        
        deleteMaterialItem({
            variables: { Id, BidQuoteItemId: bidItemId, Ordinal: materialItem?.Ordinal ?? 0  },
            optimisticResponse: {
                delete_BidQuoteMaterials_by_pk: {
                    Id,
                    __typename: "BidQuoteMaterials"
                },
                update_BidQuoteMaterials: {
                    returning: [],
                    __typename: "BidQuoteMaterials_mutation_response"
                }
            },
            update(cache, { data }) {
                cache.evict({
                    id: `BidQuoteMaterials:${Id}`
                });
                cache.gc();
            },
            onError(error) {
                console.error(error);
                client.refetchQueries({
                    include: "active"
                });
            }
        });
    }

    if (error) {
        console.error(error);
    }
    if (!materialItem) return <LineItemLoading sx={{backgroundColor:"rgba(0,0,0,0.2)"}}/>;

    const styles = {
        materialItemGrid : {
            paddingLeft:"1.5em",
            flexWrap:"nowrap !important", 
            marginTop:"2px", 
        }
    }
    return (
        <Grid item container sx={styles.materialItemGrid}>
            <TypeBox type={type}/>
            <QuoteMaterialItemForm type={type} item={materialItem} onSave={updateNewMaterialItem} onDelete={deleteMaterialItemHandler} />
        </Grid>     
    )
    
};