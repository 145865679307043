import { Box } from "@mui/material";
import { GET_REPORT_SETTINGS_QUERY, GetQuoteSettingsResult } from "../../../api/graphqlqueries/getreportsettings";
import { useState, useEffect } from 'react';
import { Colors } from "../../../constants/colors";
import { HrCircularProgress } from "../../atoms/HrCircularProgress";
import ErrorIcon from '@mui/icons-material/Error';
import { addMessage } from "../../../stores/ReactiveVariables";
import axios from "axios";
import { useQuery } from "@apollo/client";
import HrButton from "../../atoms/HrButton";


interface QuoteSummaryProps {
    id: string;
    jobName: string;
}


export const BidbookBidReport: React.FC<QuoteSummaryProps> = ({ id, jobName }) => {
    const [pdfSrc, setPdfSrc] = useState("");
    const [pdfStatus, setPdfStatus] = useState("");
    const [downloadProgress, setDownLoadProgress] = useState(0);

    const { data } = useQuery<GetQuoteSettingsResult>(GET_REPORT_SETTINGS_QUERY, {
        variables: {
            Id: id,
        },
        fetchPolicy: 'cache-and-network',
    });

    const settings = data?.BidQuotes_by_pk;

    useEffect(() => {
        if (data) {
            axios.get(`/report/${id}/bidreport`, {
                onDownloadProgress: (progressEvent => {
                    const currentProgressValue = (progressEvent.progress ?? 0);
                    setDownLoadProgress(currentProgressValue * 100)
                }),
                headers: {
                    'Content-Type': 'application/pdf'
                },
                responseType: 'blob', // important
            }).then((response) => {

                const data = window.URL.createObjectURL(response.data)
                setPdfSrc(data);
            }).catch((error) => {
                setDownLoadProgress(0);
                addMessage("Error Loading Report", { severity: 'error' });
                setPdfStatus("error");
            })
        }
    }, [data, setDownLoadProgress, setPdfSrc, setPdfStatus, settings, id]);

    const handleDownload = () => {
        const link = document.createElement("a");
        link.download = `${jobName}.pdf`;
        link.href = pdfSrc;
        link.click();
        link.remove();
    }

    return (
        <Box sx={{ display: "block", position: "relative" }}>
            {!pdfSrc && <PdfStatusDisplay progress={downloadProgress} status={pdfStatus} />}
            {pdfSrc && <HrButton style={{ float: 'right', margin: 10 }} onClick={handleDownload}>Download</HrButton>}
            <iframe src={pdfSrc} title={"Bid Report"} width="100%" height="1000" />
        </Box>
    )
}

const PdfStatusDisplay = (props: { status: string, progress: number }) => {
    return (
        <Box sx={{
            paddingTop: "3%",
            textAlign: "center"
        }}>
            {
                props.status === "error"
                    ? <ErrorIcon
                        sx={{
                            fontSize: "42px",
                            color: Colors.errorRed
                        }}
                    />
                    : <HrCircularProgress
                        value={props.progress}
                        size={75}
                    />
            }

        </Box>
    )
} 