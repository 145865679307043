import { useMutation, useQuery } from "@apollo/client";
import { GridColDef } from "@mui/x-data-grid-pro";
import { HrDataGrid } from "../../molecules/HrDataGrid";
import React from "react";
import { GetCustomerContactsQueryResult, GET_CUSTOMER_CONTACTS_QUERY } from "../../../api/graphqlqueries/getcustomercontactsquery";
import { UPDATE_CUSTOMER_CONTACT_MUTATION } from "../../../api/graphqlqueries/updatecustomercontactsmutation";
import ToggleActiveButton from "../../atoms/ToggleActiveButton";

export interface CustomerContactDataGridProps {
    customerId?: string;
    editSelect: (contactId: string) => void;
}

export const CustomerContactDataGrid: React.FC<CustomerContactDataGridProps> = ({ customerId, editSelect }) => {
    const queryOptions = {
        variables: {
            CustomerId: customerId,
        },
    };
    const { loading, data, error } = useQuery<GetCustomerContactsQueryResult>(GET_CUSTOMER_CONTACTS_QUERY, { fetchPolicy: 'cache-and-network', ...queryOptions } );
    const [updateContact] = useMutation(UPDATE_CUSTOMER_CONTACT_MUTATION);
    const columns: GridColDef[] = [
        {
            field: 'removeBillable',
            headerName: 'Toggle',
            width: 50,
            renderCell: (rows) => {return(
            <ToggleActiveButton
                isActive={rows.row.IsActive}
                onClick={()=> {
                    const { __typename, ...object } = rows.row;
                    updateContact({ variables: {
                        Id: rows.id as string,
                        object: { ...object, IsActive: !rows.row.IsActive}
                    }});
                }}
            />)},
            disableColumnMenu: true,
            sortable: false,
            headerClassName: 'billables-grid-header'
        },
        {
            field: "IsActive",
            headerName: "Active",
            type: 'boolean',
            width: 80,
            headerClassName: "billables-grid-header",
            align: 'center',
            sortable: true,
            disableColumnMenu: true,
        },
        {
            field: "FirstName",
            headerName: "First Name",
            width: 180,
            headerClassName: "billables-grid-header",
        },
        {
            field: "LastName",
            headerName: "Last Name",
            width: 180,
            headerClassName: "billables-grid-header",
        },
        {
            field: "Title",
            headerName: "Title",
            width: 200,
            headerClassName: "billables-grid-header",
        },
        {
            field: "Email",
            headerName: "Email",
            width: 200,
            headerClassName: "billables-grid-header",
        },
        {
            field: "Phone",
            headerName: "Phone",
            width: 200,
            headerClassName: "billables-grid-header",
        }
    ];
    if (error) {
        console.error(error);
    }
    return (
        <HrDataGrid
            rows={(loading || !data) ? [] : data.CustomerContacts}
            columns={columns}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            disableRowSelectionOnClick
            loading={loading}
            onRowDoubleClick={(row) => editSelect(row.id as string)}
        />
    );
};