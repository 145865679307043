import { gql, useApolloClient, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { Add, ThumbDown, ThumbUp } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, Grid } from "@mui/material";
import { GridColDef, GridToolbarContainer } from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import { CREATE_BIDQUOTE_BILLABLE_MUTATION, DELETE_BIDQUOTE_BILLABLE_MUTATION, GetBidquoteBillablesQueryResult, GET_BIDQUOTE_BILLABLES_QUERY, UPDATE_BIDQUOTE_BILLABLE_MUTATION } from "../../../../api/graphqlqueries/bidquotebillables";
import { appUserVar } from "../../../../stores/ReactiveVariables";
import dollarUS from '../../../../utils/CurrencyFormat';
import CloseIcon from '@mui/icons-material/Close';
import RemoveButton from "../../../atoms/RemoveButton";
import { HrDataGrid } from "../../../molecules/HrDataGrid";
import * as XLSX from 'xlsx';
import { addMessage } from "../../../../stores/ReactiveVariables";
import { StyledHeader } from "../../../atoms/StyledHeader";
import { FormGrid } from "../../../atoms/FormGrid";
import { BidbookBillablesForm } from "./BidbookBillablesForm";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import * as uuid from 'uuid';

const INSERT_MATERIALS_LIST_MUTATION = gql`
  mutation InsertMaterialsList($objects: [BidQuoteBillables_insert_input!] = {}) {
    insert_BidQuoteBillables(objects: $objects) {
      returning {
        Id
        Type
      }
    }
  }
`;

export const BidbookMaterialsRates: React.FC<{ id: string }> = ({ id }) => {
    const user = useReactiveVar(appUserVar);
    const [selectedBillable, setSelectedBillable] = useState<string>('');
    const [uploadingFile, setUploadingFile] = useState<boolean>(false);
    const [materialList, setMaterialList] = useState<unknown[]>([] as unknown[]);
    const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
    const { data, loading, error } = useQuery<GetBidquoteBillablesQueryResult>(GET_BIDQUOTE_BILLABLES_QUERY, { variables: { BidQuoteId: id }});
    const [createBillable] = useMutation(CREATE_BIDQUOTE_BILLABLE_MUTATION);
    const [updateBillable] = useMutation(UPDATE_BIDQUOTE_BILLABLE_MUTATION);
    const [deleteBillable] = useMutation(DELETE_BIDQUOTE_BILLABLE_MUTATION);
    const [createMaterialBillables] = useMutation(INSERT_MATERIALS_LIST_MUTATION);
    const client = useApolloClient();
    const deleteBillableHandler = (Id: string) => {
        deleteBillable({
            variables: {
                Id
            },
            optimisticResponse: {
                delete_BidQuoteBillables_by_pk: {
                    Id,
                    __typename: "BidQuoteBillables"
                }
            },
            update(cache, { data }) {
                cache.evict({
                    id: `BidQuoteBillables:${Id}`
                });
                cache.gc();
            },
            onError(error) {
                console.error(error);
                client.refetchQueries({
                    include: "active"
                });
                addMessage(`Error Deleting Material`, { severity: 'error' });
            },
            onCompleted: (data) => {
                if (data)
                    addMessage(`Material Deleted`, { severity: 'success' });
            }
        });
    }

    const handleSave = (object: any) => {
        object.BidQuoteId = id;
        object.LastUpdated = new Date();
		    object.ReportAdditionalItem = false;
        if (selectedBillable === 'new') {
            createBillable({
                variables: {
                    object
                },
                optimisticResponse: {
                    insert_BidQuoteBillables_one: {
                        __typename: 'BidQuoteBillables',
                        ...object
                    }
                },
                update: (cache, { data }) => {
                    cache.updateQuery({
                        query: GET_BIDQUOTE_BILLABLES_QUERY,
                        variables: { BidQuoteId: id }
                    },
                    (cacheData: any) => ({
                        BidQuoteBillables: [...cacheData.BidQuoteBillables, data.insert_BidQuoteBillables_one]
                    }))
                },
                onCompleted: (data) => {
                    if (data)
                        addMessage(`Material Created`, { severity: 'success' });
                }
            })
        } else {
            updateBillable({
                variables: {
                    Id: object.Id,
                    object
                },
                optimisticResponse: {
                    update_BidQuoteBillables_by_pk: {
                        __typename: 'BidQuoteBillables',
                        ...object
                    }
                },
                onCompleted: (data) => {
                    if (data)
                        addMessage(`Material Updated`, { severity: 'success' });
                }
            });
        }
        setSelectedBillable('');
    }

    const convertMaterials = (material: any) => {
        return {
            Cost: material.unitPrice,
            Name: material.description,
            BidQuoteId: id,
            Type: "Material",
            LastUpdated: new Date(),
            Id: uuid.v4(),
            ReportAdditionalItem: false
        }
    }

    const saveMaterialList = () => {
        createMaterialBillables({
            variables: { 
                objects: materialList.map(convertMaterials)
            },
            refetchQueries: [{
                query: GET_BIDQUOTE_BILLABLES_QUERY,
                variables: {
                  BidQuoteId: id
                }
            }],
            onCompleted: () =>{
                addMessage("Material Rates Successfully Added", { severity: 'success' });
            },
            onError: () => {
                addMessage("Error Saving Spreadsheet", { severity: 'error' });
            }
        }).finally(()=>{
            setConfirmModalOpen(false);
        })
    };

    const rowsUnfiltered = data?.BidQuoteBillables ?? [];
    const rows = rowsUnfiltered.filter(r => r.Type === "Material");
    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.currentTarget.files) return;
        setUploadingFile(true);
        const file = event.currentTarget.files[0];
        if (file.name.endsWith('.xlsx')) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target?.result) {
                    const workbook = XLSX.read(e.target.result, { type: 'binary' });
                    const sheet = workbook.Sheets[workbook.SheetNames[0]];
                    const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                    let parsedData;
                    try{
                        parsedData = parseData(data as string[][]);
                    } catch(error) {
                        addMessage("Error Uploading Spreadsheet: Incorrect Data Format.", { severity: 'error' });
                        throw new Error('Error parsing data');
                    } finally {
                        setUploadingFile(false);
                    }
                    setMaterialList(parsedData);
                    setConfirmModalOpen(true);
                }
            }
            reader.readAsBinaryString(file);
        } else {
            setUploadingFile(false);
            addMessage("Error Uploading Spreadsheet: Not .xlsx file.", { severity: 'error' });
            throw new Error('Incorrect upload format');
        }
    }

    const parseData = (data: string[][]) => {
        let startingRow = 18;
        const items = [];
        try{
            while (data[startingRow][3] && startingRow < data.length)
            {
                const item = {
                    id: startingRow,
                    description: data[startingRow][3],
                    quantity: data[startingRow][1],
                    unit: data[startingRow][2],
                    unitPrice: data[startingRow][8],
                };
                if (!data[startingRow + 1][2] && data[startingRow + 1][3]) 
                {
                    startingRow++;
                    item.description += ' ' + data[startingRow][3];
                }
                startingRow++;
                items.push(item);
            }
        } catch(error) {
            throw new Error(`Error parsing data: ${error as string}`);
        }
        return items;
    }
    const columns: GridColDef[] = [
        {
            field: "removeBillable",
            headerName: " ",
            width: 50,
            renderCell: (rows) => {
                const billableId = rows.id;
                return (
                    <RemoveButton
                        title="Billables"
                        onClick={() => deleteBillableHandler(billableId as string)}
                    />
                );
            },
            headerClassName: "billables-grid-header",
        },
        {
            field: "Name",
            headerName: "Item",
            width: 650,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "Cost",
            headerName: "Cost",
            width: 150,
            editable: false,
            headerClassName: "billables-grid-header",
            valueFormatter: (params) => dollarUS.format(params.value)
        }, 
    ] 

    const materialConfirmColumns: GridColDef[] = [
        {
            field: "description",
            headerName: "Item",
            width: 300,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "quantity",
            headerName: "Qty",
            width: 70,
            editable: false,
            headerClassName: "billables-grid-header",
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: "unit",
            headerName: "Unit",
            width: 70,
            editable: false,
            headerClassName: "billables-grid-header",
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: "unitPrice",
            headerName: "Unit Price",
            width: 100,
            editable: false,
            headerClassName: "billables-grid-header",
            headerAlign: 'right',
            align: 'right'
        },
    ] 

    const selectedBillableObject = rows.find((i: any) => i.Id === selectedBillable);

    if (error) {
        console.error(error);
    }
    return (
        <Box sx={{ height: '70vh' }}>
            <HrDataGrid
                loading={loading}
                rows={rows}
                columns={columns}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                disableRowSelectionOnClick
                onRowDoubleClick={(model, details) => {
                    setSelectedBillable(model.id as string);
                }}
                components={{
                    Toolbar: () => (
                        <GridToolbarContainer>
                            <label htmlFor="contained-button-file">
                                <input
                                    style={{ display: 'none' }}
                                    accept="*"
                                    id="contained-button-file"
                                    type="file"
                                    onChange={handleFileUpload}
                                />
                                <Button color="primary" startIcon={<FileUploadIcon />} disabled={uploadingFile} component="span">
                                    Upload Material List
                                </Button>
                            </label>
                            <Button color="primary" startIcon={<Add />} onClick={() => setSelectedBillable('new')}>
                                Add Single Material
                            </Button>
                        </GridToolbarContainer>
                    )
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            removeBillable: user?.roles?.some(e => e.name === "BidBook Admin") ?? false
                        }
                    }
                }}
            />
                <Dialog
                    open={confirmModalOpen === true}
                    fullWidth={true}
                >
                    <DialogContent>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <StyledHeader>Confirm Material Items?</StyledHeader>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={() => {
                                        setConfirmModalOpen(false)
                                    }}
                                    variant="text"
                                    //color="secondary"
                                    className="modal-exit"
                                >
                                    <CloseIcon />
                                </Button>
                            </Grid>
                        </Grid>
                        <HrDataGrid
                            loading={loading}
                            rows={materialList}
                            columns={materialConfirmColumns}
                            hideFooter={true}
                            disableRowSelectionOnClick
                            getRowId={(row) => row.id}
                            autoHeight
                        />
                        <Grid container justifyContent="center" spacing={1} sx={{
                            marginTop:"6px",
                        }}>
                            <Grid item>
                                <Button
                                    onClick={() => {
                                        saveMaterialList();
                                    }}
                                    variant="contained"
                                    color="success"
                                    className="modal-exit"
                                >
                                    <ThumbUp />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={() => {
                                        setConfirmModalOpen(false);
                                        setMaterialList([]);
                                    }}
                                    variant="contained"
                                    color="error"
                                    className="modal-exit"
                                >
                                    <ThumbDown />
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={selectedBillable !== ''}
                    onClose={() => setSelectedBillable('')}
                >
                    <DialogContent>

                        <Button
                            onClick={() => {
                                setSelectedBillable('');
                            }}
                            variant="contained"
                            color="primary"
                            className="modal-exit"
                        >
                            x
                        </Button>
                        <FormGrid>
                            <BidbookBillablesForm item={selectedBillableObject} type={"Material"} onSave={handleSave} onCancel={() => setSelectedBillable('')} />
                        </FormGrid>
                    </DialogContent>
                </Dialog>
        </Box>
    );
}