import { makeAutoObservable, runInAction } from "mobx";
import Agent from "../api/agent";
import { Customer } from "../models/customer";

export default class ClientStore {
    clients: Customer[] | [] = [];
    selectedClient: Customer | undefined = undefined;
    editOrCreateMode: boolean = false;
    agent: Agent;
    
    constructor(myAgent: Agent) {
        makeAutoObservable(this);
        this.agent = myAgent;
    }

    setSelectedClient = (id: string | undefined) => {
            runInAction(() => {
                this.selectedClient = this.clients.find(x => x.id === id);
            })
    }

    setEditOrCreateMode = (state: boolean) => {
        runInAction(() => {
            this.editOrCreateMode = state;
        });
    };

    setClients = (values: Customer[]) => {
        runInAction(() => {
            this.clients = values;
        });
    };

    getClients = async () => {
        try {
            const clientList = await this.agent.Customers.list();
            this.setClients(clientList);
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    updateClient = async (client: Customer) => {
        try{
            await this.agent.Customers.update(client)
            this.getClients();
        }catch(error){
            console.log(error)
            throw error;
        }
    }

    deleteClient = async (id: string) => {
        try{
            await this.agent.Customers.delete(id);
            this.getClients();
        }catch(error){
            console.log(error)
            throw error;
        }
    }

    createClient = async (client: Customer) => {
        try{
            await this.agent.Customers.create(client);
            this.getClients();
        }catch(error){
            console.log(error);
            throw error;
        }
    }






}