import { FormControlLabel, Radio } from "@mui/material"

interface HrRadioButtonProps {
    label: string;
    value: string | number | boolean;
}

export const HrRadioButton: React.FC<HrRadioButtonProps> = (props: HrRadioButtonProps) => {
    return (
        <FormControlLabel value={props.value} control={<Radio size="small" />} label={props.label} />
    )
}