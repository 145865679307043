import { gql } from "@apollo/client/core";

export interface GetSingleContactQueryResult {
  CustomerContacts_by_pk: CustomerContact;
}

interface CustomerContact {
  CustomerId: string;
  Email: string;
  FirstName: string;
  Id: string;
  LastName: string;
  IsActive: boolean;
  Phone: string;
  Title: string;
}
export const GET_SINGLE_CONTACT_QUERY = gql`
  query GetSingleContact($Id: uniqueidentifier = "") {
    CustomerContacts_by_pk(Id: $Id) {
      CustomerId
      Email
      FirstName
      Id
      LastName
      IsActive
      Phone
      Title
    }
  }
`;