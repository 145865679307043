import React from "react";
import Footer from "../organisms/Footer";
import LoginForm from "../organisms/LoginForm";

export default function LoginPage() {

    return (
        <div style={{
            display: "grid",
            gridTemplateRows: "10% 70% 20%",
            alignItems: "center",
            height: "100vh",
            width:"100%",
            boxSizing:"border-box",
            background: 'no-repeat bottom/120% url("/images/mountain-logo-large.png")',
        }}>
            <div style={{gridRow:"2/span 1"}}>
                <LoginForm />
            </div>
            <div style={{gridRow:"3/span 1"}}>
                <Footer />
            </div>
        </div>
    );
}
