import { gql } from "@apollo/client";

interface User {
    FirstName : string;
    LastName : string;
}

export interface BidQuoteEvent{
    Id : string;
    CreateDate : Date;
    Comment : string;
    Status? : string;
    User : User;
};



export interface BidTimeLineEvent{
    BidQuoteStatus : BidQuoteEvent[];
    BidComment : BidQuoteEvent[]; 
}

export const GET_BID_TIMELINE_EVENTS = gql`
query GetBidTimelineEvents($BidQuoteId: uniqueidentifier = "") {
    BidQuoteStatus(order_by: {CreateDate: desc}, where: {BidQuoteId: {_eq: $BidQuoteId}}) {
        Id
        CreateDate
        Comment : Notes
        Status
        User : AspNetUser {
            Id
            FirstName
            LastName
        }
    }
    BidComment(where: {BidQuoteId: {_eq: $BidQuoteId}}) {
        Id
        Comment
        CreateDate
        User : CreateUser {
            Id
            FirstName
            LastName
        }
    }
}
`