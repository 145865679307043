import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { ContentHeader } from "../ContentHeader";
import { Dialog, DialogContent, Tabs } from "@mui/material";
import TabNavButton from "../../atoms/TabNavButton";
import { JobsDataGrid } from "./JobsDataGrid";
import { Route, Routes, useNavigate } from "react-router";
import { KickoffPacketDataGrid } from "./KickoffPacketDataGrid";
import { styled } from "@mui/system";
import { useLocation } from "react-router";
import { JobsDetails } from "./JobsDetails";
import { JobsForm } from "./JobsForm";
import { useStore } from "../../../stores/StoreContext";

interface JobsPageProps {
    props?: any;
}

function a11yProps(page: string) {
    return {
      value: page,
      id: `nav-tab-${page}`,
      'aria-controls': `nav-tabpanel-${page}`,
    };
  }

export const JobsPageContent: React.FC<JobsPageProps> = observer((props) => {
    const {jobStore, modalStore} = useStore();
    const { loadJobs, setSelectedJob, setEditOrCreateMode, getCustomerComboOptions, getEmployeeComboOptions } = jobStore;
    const { closeJobsModal, jobsModalIsOpen, openJobsModal} = modalStore;

    const location = useLocation();
    const navigation = useNavigate();

    useEffect(() => {
        // eslint-disable-next-line
        loadJobs();
        // eslint-disable-next-line
        getCustomerComboOptions();

        getEmployeeComboOptions();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (location.pathname === '/Jobs')
        {
            navigation('/Jobs/All');
        }
    }, [location, navigation]);

    const getTabValue = () => {
        if (location.pathname.includes('/Jobs/All'))
        {
            return '/Jobs/All';
        }
        else if (location.pathname.includes('/Jobs/Kickoff/All'))
        {
            return '/Jobs/Kickoff/All';
        }
        else
        {
            return false;
        }
    }
    
    return (
        <JobsWrapper>
        <ContentHeader
            title="Jobs"
            onClick={() => {
                openJobsModal();
                setSelectedJob(undefined);
                setEditOrCreateMode("create");
            }}
        />
            <TabsBar value={getTabValue()} aria-label="simple tabs example">
                <TabNavButton label="Jobs" to="/Jobs/All" {...a11yProps('/Jobs/All')} />
                <TabNavButton label="Kickoff Packets" to="/Jobs/Kickoff/All" {...a11yProps('/Jobs/Kickoff/All')} />
            </TabsBar>
            <Routes>
                <Route path={"/All"} element={<JobsDataGrid />} />
                <Route path={"/Kickoff/All"} element={<KickoffPacketDataGrid />} />
                <Route path={"/:id"} element={<JobsDetails />} />
                <Route path={"/:id/:subtab"} element={<JobsDetails />} />
            </Routes>
            <Dialog
                open={jobsModalIsOpen}
                onClose={() => closeJobsModal()}
            >
                <DialogContent sx={{maxWidth:"500px"}}>
                    <JobsForm />
                </DialogContent>
            </Dialog>
        </JobsWrapper>
    );
});


const JobsWrapper = styled('div')({
    height: "100vh",
    width: "100%",
    alignItems: "center",
});

const TabsBar = styled(Tabs)({
    borderBottom: '1px solid #E9C246',
    minHeight: '20px !important',
});



// import { observer } from "mobx-react-lite";
// import React, { useEffect } from "react";
// import { useStore } from "../../../stores/StoreContext";
// import { ContentHeader } from "../ContentHeader";
// import { JobsForm } from "./JobsForm";
// import { JobsDataGrid } from "./JobsDataGrid";
// import { Box, Dialog, DialogContent } from "@mui/material";

// interface Props {
//     props?: any;
// }

// export const JobsPageContent: React.FC<Props> = observer(() => {
//     const {jobStore, modalStore, timeCardStore} = useStore();
//     const { loadJobs, setSelectedJob, setEditOrCreateMode, getCustomerComboOptions } = jobStore;
//     const {getEmployeeComboOptions} = timeCardStore;
//     const { closeJobsModal, jobsModalIsOpen, openJobsModal} = modalStore;

//     useEffect(() => {
//         // eslint-disable-next-line
//         loadJobs();
//         // eslint-disable-next-line
//         getCustomerComboOptions();

//         getEmployeeComboOptions();
//         // eslint-disable-next-line
//     }, []);

//     return (
//         <Box sx={{ height: '100%'}}>
//             <ContentHeader
//                 title="Jobs"
//                 onClick={() => {
//                     openJobsModal();
//                     setSelectedJob(undefined);
//                     setEditOrCreateMode("create");
//                 }}
//             />
            
//             <JobsDataGrid />
//             <Dialog
//                 open={jobsModalIsOpen}
//                 onClose={() => closeJobsModal()}
//             >
//                 <DialogContent sx={{maxWidth:"500px"}}>
//                     <JobsForm />
//                 </DialogContent>
//             </Dialog>
//         </Box>
//     );
// });
