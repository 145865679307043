import { TabContext, TabPanel } from "@mui/lab";
import React, { useState } from "react";
import { HrSubTab, HrSubTabs } from "../../../atoms/HrSubTabs";
import { BidbookBillables } from "./BidbookBillables";
import { BidbookMaterialsRates } from "./BidbookMaterialsRates";
import { BidbookServiceRates } from "./BidbookServiceRates";
import { BidbookServiceCategories } from "./BidbookServiceCategories";

export const BidbookRateSheet: React.FC<{ id: string}> = ({ id }) => {
    const [value, setValue] = useState('0');
    const handleTabChange = (event: any, newValue: any) => {
        setValue(newValue);
    };
    return <TabContext value={value}>
        <HrSubTabs onChange={handleTabChange} value={value}>
            <HrSubTab label="Materials" value="0" />
            <HrSubTab label="Equipment" value="1" />
            <HrSubTab label="Services" value="2" />
            <HrSubTab label="Service Categories" value="3" />
        </HrSubTabs>
        <TabPanel value="0" sx={{ height: '90%'}}>
            <BidbookMaterialsRates id={id} />
        </TabPanel>
        <TabPanel value="1" sx={{ height: '90%'}}>
            <BidbookBillables id={id} type="Equipment" />
        </TabPanel>
        <TabPanel value="2" sx={{ height: '90%'}}>
            <BidbookServiceRates id={id} />
        </TabPanel>
        <TabPanel value="3" sx={{ height: '90%'}}>
            <BidbookServiceCategories id={id} />
        </TabPanel>
    </TabContext>;
}