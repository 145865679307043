import React from "react";
import Logo from "../../images/hr-logo-nav.png";
import { Colors } from "../../constants/colors";
import { Link } from "react-router-dom";
import { useStore } from "../../stores/StoreContext";
import { styled } from "@mui/system";
import { NavButtonProtected } from "../atoms/NavButton";

export interface NavBarProps {

}

export const NavBar: React.FC<NavBarProps> = (props: NavBarProps) => {
    const { userStore } = useStore();
    const handleLogout = () => {
        userStore.logout();
    };    

    return (
        <NavContainer>
            <TopContainer>
                <NavLogo src={Logo} alt="logo" />
                <ButtonContainer>
                    <NavButtonProtected
                        roleLevel={0}
                        title="Jobs"
                    />
                    <NavButtonProtected
                        roleLevel={1}
                        title="Clients"
                    />
                    {/* <NavButtonProtected
                        roleLevel={3}
                        title="Employees"
                    /> */}
                    <NavButtonProtected
                        roleLevel={1}
                        title="Crews"
                    />
                    <NavButtonProtected
                        roleLevel={1}
                        title="Admin"
                    />
                    <NavButtonProtected
                        roleLevel={2}
                        title="Bid Book"
                    />
                    <NavButtonProtected
                        roleLevel={2}
                        title="Dashboard"
                    />
                    <NavButtonProtected
                        roleLevel={2}
                        title="Job Costs"
                    />
                </ButtonContainer>
            </TopContainer>
            <BottomContainer>
                <NavBottomItem to="/">
                    Settings
                </NavBottomItem>
                <NavBottomItem to="/">
                    Privacy
                </NavBottomItem>
                <NavBottomItemLast
                    onClick={handleLogout}
                    to="/"
                >
                    Logout
                </NavBottomItemLast>
            </BottomContainer>
        </NavContainer>
    );
};

const NavContainer = styled('div')({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100vh",
    width: "200px",
    alignItems: "center",
    backgroundColor: Colors.deepSeaGreen,
    position: "fixed",
    zIndex: 10
});

const ButtonContainer = styled('div')({
    display: "flex",
    flexDirection: "column",
    width: "100%"
});

const TopContainer = styled('div')({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    padding: "6px 0",
    backgroundColor: Colors.deepSeaGreen,
});

const BottomContainer = styled('div')({
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    backgroundColor: Colors.darkSeaGreen,
    height: 120,
    width: 200,
    textAlign: "center",
});

const NavLogo = styled('img')({
    marginTop: 12,
    marginBottom: 15,
});

const NavBottomItem = styled(Link)({
    fontSize: "14px",
    textDecoration: "none",
    color: Colors.white,
    marginTop: "3px",
    fontFamily: "Montserrat",
    padding: "9px 0"
});

const NavBottomItemLast = styled(Link)({
    fontSize: "14px",
    color: Colors.errorRed,
    textDecoration: "none",
    marginTop: "3px",
    padding: "9px 0",
    fontFamily: "Montserrat"
});