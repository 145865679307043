import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, useCallback } from 'react';
import * as yup from 'yup';
import HrTextArea from '../../../atoms/HrTextArea';
import debounce from '../../../../utils/debounce';

interface FrontPageFormProps {
    id: string;
    data: FrontPageFormData;
    onSave: (values: FrontPageFormData) => void;
}

export interface FrontPageFormData{
    CoverLetterFrontPageServices: string;
}

const validationSchema = yup.object().shape({
    CoverLetterFrontPageServices: yup.string()
});


export const FrontPageForm: React.FC<FrontPageFormProps> = ({ id, data, onSave }: FrontPageFormProps) => {
    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            CoverLetterFrontPageServices: data?.CoverLetterFrontPageServices ?? ""
        },
        onSubmit: (values: any) => {
            const castValues : FrontPageFormData = validationSchema.cast(values) as FrontPageFormData;
            onSave(castValues);
        }
    })

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        try {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value};
            const values = validationSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.handleChange(e);
    };

    const debouncer = useCallback(debounce((values: any) => onSave(values), 2000), []);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <HrTextArea
                        label="Cover Page Services"
                        name="CoverLetterFrontPageServices"
                        value={formik.values.CoverLetterFrontPageServices}
                        onChange={handleChange}
                        multiline
                        minRows={3}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </form>
    );
};
