import { gql } from "@apollo/client/core";

export const CREATE_CUSTOMER_CONTACT_MUTATION = gql`
  mutation CreateCustomerContact($object: CustomerContacts_insert_input = {}) {
    insert_CustomerContacts_one(object: $object) {
      CustomerId
      Email
      FirstName
      Id
      IsActive
      LastName
      Phone
      Title
    }
  }
`;