import {Grid } from "@mui/material";
import { useFormik } from "formik";
import { KickoffPacketListInput } from "./KickoffPacketListInput";
import * as Yup from 'yup';

interface ProjectScopeFormProps{
    id?: string;
    addItem: any;
}

export const ProjectScopeForm: React.FC<ProjectScopeFormProps> = ({ id, addItem }: ProjectScopeFormProps) => {
    const submitForm = (value: string) => {
        addItem(value);
    }
    return(
        <Grid item xs={12}>
            <KickoffPacketListInput 
                name="Scope" 
                handleSubmit={submitForm}
            />
        </Grid>
    ) 
}