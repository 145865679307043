import { gql, useQuery } from "@apollo/client";
import { StyledHeader } from "../../atoms/StyledHeader";
import { GridColDef } from "@mui/x-data-grid-pro";
import HrCheckBox from "../../atoms/HrCheckBox";
import { HrDataGrid } from "../../molecules/HrDataGrid";
import { useEffect, useState } from "react";
import LineItemLoading from "../../molecules/LineItemLoading";
import HrButton from "../../atoms/HrButton";
import { Colors } from "../../../constants/colors";

interface SegmentSelection {
    selected: boolean;
    segment: BidQuoteItem;
}

interface SegmentItemProps {
    id: string;
    onSave: (segments: BidQuoteItem[]) => void;
}

interface GetItemsResult {
    BidQuoteItems: BidQuoteItem[];
}

interface BidQuoteItem {
    Id: string;
    Segment: string;
    Ordinal: number;
    Type: string;
    ParentBidQuoteItemId?: string;
}

const GET_UNASSIGNED_ITEMS_QUERY = gql`
  query GetUnassignedItems($BidQuoteId: uniqueidentifier = "") {
    BidQuoteItems(where: {BidQuoteId: {_eq: $BidQuoteId}, _not: {JobSegment: {}}}, order_by: {Ordinal: asc}) {
      Id
      Segment
      Type
      Ordinal
      ParentBidQuoteItemId
    }
  }
`;

export const SegmentItems: React.FC<SegmentItemProps> = ({ id, onSave }) => {
    const { data, error, loading } = useQuery<GetItemsResult>(GET_UNASSIGNED_ITEMS_QUERY, {
        variables: {
            BidQuoteId: id
        },
        fetchPolicy: 'cache-and-network',
    });

    const [segmentSelections, setSegmentSelections] = useState<SegmentSelection[]>([]);
    useEffect(() => {
        if (data) {
            setSegmentSelections(data.BidQuoteItems.map((segment) => ({ selected: false, segment })));
        }
    }, [data])
    if (error) {
        console.error(error);
    }
    if (loading && !data) return <LineItemLoading />

    const rows = data?.BidQuoteItems ?? [];

    const handleSegmentCheckbox = (e: any) => {
        const { name, checked } = e.target;
        let childSegments = [];
        const segment = segmentSelections.find((segment) => segment.segment.Id === name);
        if (checked === true) {
            childSegments = data?.BidQuoteItems?.filter((bqi) => bqi?.ParentBidQuoteItemId === name).map((segment) => ({ selected: true, segment })) ?? [];
        } else {
            childSegments = data?.BidQuoteItems?.filter((bqi) => bqi?.ParentBidQuoteItemId === name).map((segment) => ({ selected: false, segment })) ?? [];
        }

        if (segment) {
            segment.selected = checked;
            setSegmentSelections([...childSegments, ...segmentSelections]);
        }
    }

    const handleSave = () => {
        const selectedSegments = segmentSelections.filter((segment) => segment.selected);
        onSave(selectedSegments.map((segment) => segment.segment));
    }

    const columns: GridColDef[] = [
        {
            field: "SegmentChoice",
            headerName: " ",
            width: 100,
            editable: false,
            headerClassName: "billables-grid-header",
            headerAlign: 'center',
            align: 'center',
            renderCell: (rows) => {
                return <HrCheckBox
                    name={`${rows.id}`}
                    checked={segmentSelections.find((segment) => segment.segment.Id === rows.id)?.selected ?? false}
                    overrideStatusDisable={true}
                    onChange={handleSegmentCheckbox}
                    disabled={rows?.row?.ParentBidQuoteItemId}
                    sx={{
                        margin: 0,
                        "& .Mui-disabled .MuiSvgIcon-root path": {
                            color: Colors.unselectedGray
                        }
                    }}
                />
            }
        },
        {
            field: "Segment",
            headerName: "Segment",
            width: 250,
            editable: false,
            headerClassName: "billables-grid-header",
        },
        {
            field: "Type",
            headerName: "Segment Type",
            width: 120,
            editable: false,
            headerClassName: "billables-grid-header",
        }
    ]
    return (
        <>
            <StyledHeader sx={{ textAlign: 'Center' }}> Quote Segments {rows.length === 0 && " - No segments available"}</StyledHeader>
            <HrDataGrid
                loading={loading}
                rows={rows}
                columns={columns}
                pageSizeOptions={[5, 10, 20, 50, 100]}
            />
            <HrButton sx={{ m: 1 }} onClick={handleSave}>Save</HrButton>
        </>
    )

}
