import React from "react";
import { styled } from "@mui/system";
import { Button, Box, ButtonProps } from "@mui/material";
import { Colors } from "../../constants/colors";
import { purple } from "@mui/material/colors";

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: Colors.deepSeaGreen,
    height: 42,
    "&:hover": {
        backgroundColor: Colors.lightSeaGreen,
    },
}));

const StyledBox = styled(Box)(({ theme }) => ({
    marginTop: '1.5em',
}));

export default function BidBookButton(props: ButtonProps) {

    return (
        <StyledBox>
            <ColorButton
                onClick={props.onClick}
                type={props.type}
                variant="contained"
                color="primary" 
            >
                {props.title}
            </ColorButton>
        </StyledBox>
    );
}

export const HighRidgeColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: Colors.deepSeaGreen,
    "&:hover": {
        backgroundColor: Colors.lightSeaGreen,
    },
}));