import { IconButton, styled } from "@mui/material";
import { Colors } from "../../constants/colors";

export const StyledIconButton = styled(IconButton)({
    backgroundColor: `${Colors.darkSeaGreen}!important`,
    color: "#fff!important",
    padding:"8px!important",
    borderRadius: 0, 
    "&:hover":{
        backgroundColor: "rgb(23, 53, 53)",
        boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)"
    },
    "&:first-of-type": {
        borderTopLeftRadius : "4px",
        borderBottomLeftRadius : "4px"
    },
    "&:last-child": {
        borderTopRightRadius : "4px",
        borderBottomRightRadius : "4px"
    },
    "&.Mui-disabled" : {
        //backgroundColor : `${Colors.lightSeaGreen}!important`,
        color: `#888!important`,
    }
});