import { Grid, Typography, debounce } from "@mui/material";
import HrTextInput from "../../../atoms/HrTextInput";
import { HrDecimalInput } from "../../../atoms/HrDecimalInput";
import { ChangeEvent, useCallback, } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

interface ProjectExecutionFormProps {
    id?: string;
    formData: any;
    segmentData: any;
    onSave: (object: any) => void;
}



export const ProjectExecutionForm: React.FC<ProjectExecutionFormProps> = ({ id, formData, segmentData, onSave }: ProjectExecutionFormProps) => {
    const projectExecutionData = segmentData?.reduce((total: any, curr: any) => {
        const item = curr?.BidQuoteItem;

        const ctiTotals = item?.BidQuoteConstructionServices?.reduce((total: any, curr: any) => {
            total.workDays += Number(curr?.Days ?? 0);
            total.setupBreakdownDays += Number(curr?.Setup ?? 0);
            total.mobDemobDays += Number(curr?.Mob ?? 0);
            return total;
        }, {
            mobDemobDays: 0,
            setupBreakdownDays: 0,
            workDays: 0,
            expectedDailyProduction: 0,
        });
        const totalSurveyWorkDays = Number(item?.AverageMiles ?? 0) !== 0 ? Number(item?.Miles ?? 0) / Number(item?.AverageMiles) : 0;
        const totalSurveyMobDemobDays = Number(item?.Mob ?? 0);
        total.miles += Number(item?.Miles);
        total.mobDemobDays += totalSurveyMobDemobDays + ctiTotals?.mobDemobDays;
        total.setupBreakdownDays += Number(item?.Setup ?? 0) + ctiTotals?.setupBreakdownDays;
        total.surveyWorkDays += totalSurveyWorkDays;
        total.ctiWorkDays += ctiTotals?.workDays;
        total.workDays += totalSurveyWorkDays + ctiTotals?.workDays;
        total.expectedDailyProduction = total.surveyWorkDays != 0 ? total.miles / total.surveyWorkDays : 0;
        return total;
    }, {
        miles: 0,
        mobDemobDays: 0,
        setupBreakdownDays: 0,
        surveyWorkDays: 0,
        ctiWorkDays: 0,
        workDays: 0,
        expectedDailyProduction: 0,
        allSurveyDays: 0
    });

    const validationSchema = Yup.object({
        Mob: Yup.number(),
        Setup: Yup.number(),
        WorkDays: Yup.number(),
        ExpectedDailyProduction: Yup.number()
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            Mob: formData.Mob ? formData.Mob : (projectExecutionData?.mobDemobDays ?? 0),
            Setup: formData.Setup ? formData.Setup : (projectExecutionData?.setupBreakdownDays ?? 0),
            WorkDays: formData.WorkDays ? formData.WorkDays : (projectExecutionData?.workDays ?? 0),
            ExpectedDailyProduction: formData.ExpectedDailyProduction ? formData.ExpectedDailyProduction : (projectExecutionData?.expectedDailyProduction ?? 0),
        },
        onSubmit: () => {

        },
        validationSchema: validationSchema,
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        try {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value };
            const values = validationSchema.cast(updatedValues);
            debouncer(values);
        } catch (error) {
            console.error(error);
        }
        formik.handleChange(e);
    }

    const debouncer = useCallback(debounce((object: any) => onSave(object), 1000), []);

    const subHeaderStyles = {
        display: "inline-block",
        mr: 1.5,
        fontSize: "15px",
        color: "#444"
    }

    return (
        <form>
            <Grid container spacing={1} alignItems="flex-end">
                <Grid container item xs={8} spacing={1}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            sx={subHeaderStyles}
                        >
                            BidQuote Projected Figures
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <HrTextInput
                            label="MOB/DEMOB Days"
                            value={projectExecutionData?.mobDemobDays ?? 0}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <HrTextInput
                            label="Setup/Breakdown Days"
                            value={projectExecutionData?.setupBreakdownDays ?? 0}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <HrTextInput
                            label="Survey Work Days"
                            value={projectExecutionData?.surveyWorkDays ?? 0}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <HrTextInput
                            label="CTI Work Days"
                            value={projectExecutionData?.ctiWorkDays ?? 0}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <HrTextInput
                            label="Total Work Days"
                            value={projectExecutionData?.workDays ?? 0}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <HrDecimalInput
                            label="Exp. Daily Production"
                            value={projectExecutionData?.expectedDailyProduction ?? 0}
                            fullWidth
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            sx={subHeaderStyles}
                        >
                            Kickoff Packet Estimated Figures
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <HrTextInput
                            label="MOB/DEMOB Days"
                            name="Mob"
                            value={formik.values.Mob}
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <HrTextInput
                            label="Setup/Breakdown Days"
                            name="Setup"
                            value={formik.values.Setup}
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <HrTextInput
                            label="Work Days"
                            name="WorkDays"
                            value={formik.values.WorkDays}
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <HrDecimalInput
                            label="Exp. Daily Production"
                            name="ExpectedDailyProduction"
                            value={formik.values.ExpectedDailyProduction}
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}