import { useReactiveVar } from "@apollo/client";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import React, { MouseEventHandler } from "react";
import { Colors } from "../../constants/colors";
import { disableForStatus } from "../../stores/ReactiveVariables";

export interface HrCheckBoxProps{
    checked? : boolean
    onChange? : (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
    onClick? : (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
    onCheckboxClick? : MouseEventHandler<HTMLButtonElement>
    name : string
    label? : string
    value? : boolean
    id? : string
    color? : string
    sx? : any
    disabled?: boolean
    defaultChecked?: boolean
    overrideStatusDisable?: boolean
}

export default function HrCheckBox(props: HrCheckBoxProps) {
    const shouldDisableForStatus = useReactiveVar(disableForStatus);
    const { disabled, overrideStatusDisable } = props;
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={props.checked}
                    onChange={props.onChange}
                    name={props.name}
                    style={{ color: props.color ? props.color : Colors.darkSeaGreen }}
                    onClick={props.onCheckboxClick}
                    disabled={(shouldDisableForStatus && !overrideStatusDisable) || disabled}
                />
            }
            labelPlacement='end'
            label={<Typography sx={{
                fontWeight: '500',
            }}>{props.label}</Typography>}
            sx={{
                fontWeight: '500!important',
                ...props.sx
            }}
        />
    );
}
