import { Grid, Divider } from "@mui/material"
import HrTextArea from "../../../../atoms/HrTextArea";
import { HrRadioGroup } from "../../../../atoms/HrRadioGroup";
import { HrRadioButton } from "../../../../atoms/HrRadioButton";
import { useFormik } from "formik";
import { gql, useMutation } from "@apollo/client";
import * as yup from "yup";
import { useCallback } from "react";
import debounce from "../../../../../utils/debounce";

interface FinalDeliverablesFormProps {
    finalDeliverables: FinalDeliverablesSection;
}

interface FinalDeliverablesSection {
    Id: string;
    FinalReport: boolean;
    PDFGraph: boolean;
    PCSExport: boolean;
    RawSVYFiles: boolean;
    FinalUDLFiles: boolean;
    FinalDATFiles: boolean;
    FinalWaveform: boolean;
    FinalCurrentSourceLog: boolean;
    FinalWorkPermits: boolean;
    FinalDeliverablesNotes: string;
}

const UPDATE_KICKOFF_FINAL_DELIVERABLES_MUTATION = gql`
    mutation UpdateKickoffFinalDeliverables($Id: uniqueidentifier = "", $_set: KickoffPacket_set_input = {}) {
        update_KickoffPacket_by_pk(pk_columns: {Id: $Id}, _set: $_set) {
            Id
            FinalReport
            PDFGraph
            PCSExport
            RawSVYFiles
            FinalUDLFiles
            FinalDATFiles
            FinalWaveform
            FinalCurrentSourceLog
            FinalWorkPermits
            FinalDeliverablesNotes
            __typename
        }
    }
`;

const kickoffFinalDeliverablesSchema = yup.object({
    FinalReport: yup.boolean().nullable(),
    PDFGraph: yup.boolean().nullable(),
    PCSExport: yup.boolean().nullable(),
    RawSVYFiles: yup.boolean().nullable(),
    FinalUDLFiles: yup.boolean().nullable(),
    FinalDATFiles: yup.boolean().nullable(),
    FinalWaveform: yup.boolean().nullable(),
    FinalCurrentSourceLog: yup.boolean().nullable(),
    FinalWorkPermits: yup.boolean().nullable(),
    FinalDeliverablesNotes: yup.string().nullable()
});



export const FinalDeliverablesForm: React.FC<FinalDeliverablesFormProps> = ({ finalDeliverables }: FinalDeliverablesFormProps) => {

    const [updateKickoffFinalDeliverables] = useMutation(UPDATE_KICKOFF_FINAL_DELIVERABLES_MUTATION);
    const formik = useFormik({
        initialValues: {
            FinalReport: finalDeliverables.FinalReport,
            PDFGraph: finalDeliverables.PDFGraph,
            PCSExport: finalDeliverables.PCSExport,
            RawSVYFiles: finalDeliverables.RawSVYFiles,
            FinalUDLFiles: finalDeliverables.FinalUDLFiles,
            FinalDATFiles: finalDeliverables.FinalDATFiles,
            FinalWaveform: finalDeliverables.FinalWaveform,
            FinalCurrentSourceLog: finalDeliverables.FinalCurrentSourceLog,
            FinalWorkPermits: finalDeliverables.FinalWorkPermits,
            FinalDeliverablesNotes: finalDeliverables.FinalDeliverablesNotes
        },
        validationSchema: kickoffFinalDeliverablesSchema,
        onSubmit: async (values) => {

        }
    });

    const updateData = (set: any) => {
        const castSet = kickoffFinalDeliverablesSchema.cast(set);
        updateKickoffFinalDeliverables({
            variables: {
                Id: finalDeliverables.Id,
                _set: castSet
            }
        });
    }

    const debouncedUpdateData = useCallback(debounce((values: any) => updateData(values), 500), []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const set = {
            ...formik.values,
            [event.target.name]: event.target.value
        };
        debouncedUpdateData(set);
        formik.handleChange(event);
    }

    const { FinalReport, PDFGraph, PCSExport, RawSVYFiles, FinalUDLFiles, FinalDATFiles, FinalWaveform, FinalCurrentSourceLog, FinalWorkPermits, FinalDeliverablesNotes } = formik.values;
    return (
        <Grid item xs={12} container alignItems="center">

            <HrRadioGroup name="FinalReport" label="Final Report" value={FinalReport} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Divider sx={{ width: "100%" }} />

            <HrRadioGroup name="PDFGraph" label="PDF Graph" value={PDFGraph} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Divider sx={{ width: "100%" }} />

            <HrRadioGroup name="PCSExport" label="PCS Export" value={PCSExport} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Divider sx={{ width: "100%" }} />

            <HrRadioGroup name="RawSVYFiles" label="RAW SVY Files" value={RawSVYFiles} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Divider sx={{ width: "100%" }} />

            <HrRadioGroup name="FinalUDLFiles" label="UDL1 Data Files" value={FinalUDLFiles} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Divider sx={{ width: "100%" }} />

            <HrRadioGroup name="FinalWaveform" label="Waveforms" value={FinalWaveform} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Divider sx={{ width: "100%" }} />

            <HrRadioGroup name="FinalDATFiles" label="DAT Files" value={FinalDATFiles} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Divider sx={{ width: "100%" }} />

            <HrRadioGroup name="FinalCurrentSourceLog" label="Current Source Log" value={FinalCurrentSourceLog} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Divider sx={{ width: "100%" }} />

            <HrRadioGroup name="FinalWorkPermits" label="Work Permits/JSA/ATW" value={FinalWorkPermits} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Grid item xs={12} sx={{ mt: 1 }}>
                <HrTextArea
                    label="Notes"
                    name="FinalDeliverablesNotes"
                    value={FinalDeliverablesNotes}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    minRows={3}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'rgba(0, 0, 0, 0.12)'
                            }
                        }
                    }}
                />
            </Grid>
        </Grid>
    )
}