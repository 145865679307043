import { AlertColor } from "@mui/material";
import { action, makeAutoObservable, runInAction } from "mobx";

export default class NotificationStore {
    severity: AlertColor | undefined;
    message: string = "";
    open: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setMessage = (message: string) => {
        runInAction(() => {
            this.message = message;
        })
    }

    @action
    setType = (severity: AlertColor) => {
        runInAction(() => {
            this.severity = severity;
        })
    }

    @action
    displayNotification = (message: string, severity: AlertColor) => {
        runInAction(() => {
            this.open = true;
            this.message = message;
            this.severity = severity;
        });
    };

    @action
    hideNotification = () => {
        runInAction(() => {
            this.open = false;
        });
    };
} 