import { Grid, Divider } from "@mui/material";
import HrTextInput from "../../../../atoms/HrTextInput";
import HrTextArea from "../../../../atoms/HrTextArea";
import { HrRadioGroup } from "../../../../atoms/HrRadioGroup";
import { HrRadioButton } from "../../../../atoms/HrRadioButton";
import { useFormik } from "formik";
import { gql, useMutation } from "@apollo/client";
import * as yup from "yup";
import { useCallback } from "react";
import debounce from "../../../../../utils/debounce";

interface DataCollectionFormProps {
    dataCollection: DataCollectionSection;
}

interface DataCollectionSection {
    Id: string;
    SharePointLocation: string;
    SVYFiles: boolean;
    TrimbleFiles: boolean;
    Waveforms: boolean;
    UDLFiles: boolean;
    DailyReport: boolean;
    DataCollectionDATFiles: boolean;
    DataCollectionNotes: string;
}

const UPDATE_KICKOFF_JOB_PLAN_MUTATION = gql`
    mutation UpdateKickoffJobPlan($Id: uniqueidentifier = "", $_set: KickoffPacket_set_input = {}) {
        update_KickoffPacket_by_pk(pk_columns: {Id: $Id}, _set: $_set) {
            Id
            SharePointLocation
            SVYFiles
            TrimbleFiles
            Waveforms
            UDLFiles
            DailyReport
            DataCollectionDATFiles
            DataCollectionNotes
            __typename
        }
    }
`;

const kickoffJobPlanSchema = yup.object({
    SharePointLocation: yup.string().nullable(),
    SVYFiles: yup.boolean().nullable(),
    TrimbleFiles: yup.boolean().nullable(),
    Waveforms: yup.boolean().nullable(),
    UDLFiles: yup.boolean().nullable(),
    DailyReport: yup.boolean().nullable(),
    DataCollectionDATFiles: yup.boolean().nullable(),
    DataCollectionNotes: yup.string().nullable()
});

export const DataCollectionForm: React.FC<DataCollectionFormProps> = ({ dataCollection }: DataCollectionFormProps) => {

    const [updateKickoffJobPlan] = useMutation(UPDATE_KICKOFF_JOB_PLAN_MUTATION);
    const formik = useFormik({
        initialValues: {
            SharePointLocation: dataCollection.SharePointLocation,
            SVYFiles: dataCollection.SVYFiles,
            TrimbleFiles: dataCollection.TrimbleFiles,
            Waveforms: dataCollection.Waveforms,
            UDLFiles: dataCollection.UDLFiles,
            DailyReport: dataCollection.DailyReport,
            DataCollectionDATFiles: dataCollection.DataCollectionDATFiles,
            DataCollectionNotes: dataCollection.DataCollectionNotes
        },
        validationSchema: kickoffJobPlanSchema,
        onSubmit: (values) => {

        }
    });

    const updateData = (set: any) => {
        const castSet = kickoffJobPlanSchema.cast(set);
        updateKickoffJobPlan({
            variables: {
                Id: dataCollection.Id,
                _set: castSet
            }
        });
    };

    const debouncedUpdateData = useCallback(debounce((values: any) => updateData(values), 500), []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const set = { ...formik.values, [name]: value };
        debouncedUpdateData(set);
        formik.handleChange(event);
    };

    const { SharePointLocation, SVYFiles, TrimbleFiles, Waveforms, UDLFiles, DailyReport, DataCollectionDATFiles, DataCollectionNotes } = formik.values;
    return (
        <Grid item xs={12} container alignItems="center">
            <Grid item xs={12}>
                <HrTextInput 
                    label="Sharepoint Location" 
                    name="SharePointLocation"
                    value={SharePointLocation}
                    onChange={handleChange}
                    sx={{
                        mt: .5,
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'rgba(0, 0, 0, 0.12)'
                            }
                        }
                    }}
                    fullWidth
                />
            </Grid>

            <HrRadioGroup name="SVYFiles" label="SVY Files (Daily))" value={SVYFiles} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Divider sx={{ width: "100%" }} />

            <HrRadioGroup name="TrimbleFiles" label="Trimble Files (Daily)" value={TrimbleFiles} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Divider sx={{ width: "100%" }} />

            <HrRadioGroup name="Waveforms" label="Waveforms (Daily)" value={Waveforms} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>
            
            <Divider sx={{ width: "100%" }} />

            <HrRadioGroup name="DataCollectionDATFiles" label="DAT Files (Daily)" value={DataCollectionDATFiles} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Divider sx={{ width: "100%" }} />
            <HrRadioGroup name="UDLFiles" label="UDL1 Data Files (Daily)" value={UDLFiles} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>

            <Divider sx={{ width: "100%" }} />
            <HrRadioGroup name="DailyReport" label="Daily Report" value={DailyReport} onChange={handleChange}>
                <HrRadioButton value={true} label="Y" />
                <HrRadioButton value={false} label="N" />
            </HrRadioGroup>
            <Grid item xs={12} sx={{ mt: 1 }}>
                <HrTextArea
                    label="Notes"
                    name="DataCollectionNotes"
                    value={DataCollectionNotes}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    minRows={3}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'rgba(0, 0, 0, 0.12)'
                            }
                        }
                    }}
                />
            </Grid>
        </Grid>
    )
}