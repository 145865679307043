import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useStore } from "../../../stores/StoreContext";
import { BillablesDataGrid } from "./BillablesDataGrid";
import { ContentHeader } from "../ContentHeader";
import { BillablesForm } from "./BillablesForm";
import { Box, Dialog, DialogContent } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { HrSubTab, HrSubTabs } from "../../atoms/HrSubTabs";
import { ServicesDataGrid } from "./ServicesDataGrid";
import { DefaultsDataGrid } from "./DefaultsDataGrid";
import { DefaultTermsDataGrid } from "./DefaultTermsDataGrid";
import { ServiceCategoriesDataGrid } from "./ServiceCategoriesDataGrid";
import { SafetyDataGrid } from "./SafetyDataGrid";
import { UserRolesDataGrid } from "../Bidbook/Users/UserRolesDataGrid";

interface Props {
    props?: any;
}

export const BillablesPageContent: React.FC<Props> = observer((props: Props) => {
    const { billablesStore, modalStore } = useStore();
    const {
        getBillables,
        setSelectedBillable,
    } = billablesStore;
    const {
        closeBillablesModal,
        billablesModalIsOpen,
    } = modalStore;

    const [tabValue, setTabValue] = useState("0");
    useEffect(() => {
        getBillables();
    });

    const handleClose = () => {
        setSelectedBillable(undefined);
        closeBillablesModal();
    }
    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };


    return (
    <Box sx={{ height: '100%'}}>
        <ContentHeader
            title="Admin"
            hideAddButton={true}
        />
        <TabContext value={tabValue}>
            <HrSubTabs onChange={handleTabChange} value={tabValue}>
                <HrSubTab label="Materials" value="0" />
                <HrSubTab label="Equipment" value="4" />
                <HrSubTab label="Services" value="1" />
                <HrSubTab label="Service Categories" value="5" />
                <HrSubTab label="Defaults" value="2" />
                <HrSubTab label="Terms" value="3" />
                <HrSubTab label="General Safety Topics" value="6" />
                <HrSubTab label="User Roles" value="7" />
            </HrSubTabs>
            <TabPanel value="0" sx={{ height: '90%'}}>
                <BillablesDataGrid type="Material" />
            </TabPanel>
            <TabPanel value="4" sx={{ height: '90%'}}>
                <BillablesDataGrid type="Equipment" />
            </TabPanel>
            <TabPanel value="1" sx={{ height: '90%'}}>
                <ServicesDataGrid />
            </TabPanel>
            <TabPanel value="2" sx={{ height: '90%'}}>
                <DefaultsDataGrid />
            </TabPanel>
            <TabPanel value="3" sx={{ height: '90%'}}>
                <DefaultTermsDataGrid />
            </TabPanel>
            <TabPanel value="5" sx={{ height: '90%'}}>
                <ServiceCategoriesDataGrid />
            </TabPanel>
            <TabPanel value="6" sx={{ height: '90%'}}>
                <SafetyDataGrid />
            </TabPanel>
            <TabPanel value="7" sx={{ height: '90%'}}>
                <UserRolesDataGrid />
            </TabPanel>
        </TabContext>
        <Dialog
            open={billablesModalIsOpen}
            onClose={handleClose}
        >
            <DialogContent>
                <BillablesForm />
            </DialogContent>
        </Dialog>
    </Box>
    );
});